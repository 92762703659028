import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { useAuth } from 'client/hooks/useAuth';
import useLocalStorage from 'client/hooks/useLocalStorage';

interface Props {
  path: string;
}

const Protected: React.FC<Props> = ({ children, path }) => {
  const auth = useAuth();
  const locationObject = useLocation();
  const [, setIntendedRedirect] = useLocalStorage('intendedRedirect');

  return (
    <Route
      path={path}
      render={({ location }) => {
        if (auth.user) {
          setIntendedRedirect(null);
          return children;
        }

        setIntendedRedirect(locationObject.pathname + locationObject.search + locationObject.hash);
        return <Redirect to={{ pathname: '/login', state: { intended: location } }} />;
      }}
    />
  );
};

export default Protected;
