import styled from 'styled-components';

export const PaperLinkContainer = styled.div<{ active: boolean }>`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 0 0.5px #00000014, 0 4px 8px #00000029;
  border-radius: 12px;
  transform: translate(-50%, -100%);
  transition: background-color 200ms ease-out;

  ${({ active }) =>
    active &&
    `
      &:hover {
        background-color: #ebebeb;
      }
  `}

  a {
    display: flex;
    flex-direction: row;
    cursor: ${({ active }) => (active ? 'pointer' : 'default')};
    color: ${({ active }) => (active ? '#2287ca' : '#8c8c8c')};
    text-decoration: none;
    font-size: 10px;
    padding: 6px 16px 6px 12px;

    &[href^="//"], &[target^="_blank"]
    {
      background: none;
    }
  }
`;

export const LinkIcon = styled.div<{ active: boolean }>`
  width: 12px;
  height: 12px;
  margin-right: 6px;

  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${({ active }) =>
    active ? 'url(/icons/icn_link_active.svg)' : 'url(/icons/icn_link_inactive.svg)'};
`;

export const EditLinkContainer = styled.div`
  position: absolute;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: #f5f6f7;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  border: 2px solid #279ae7;
  border-radius: 5px;
  transform: translate(-50%, -50%);

  &.sticker {
    transform: translate(-50%, 10%);
  }
`;

export const EditLinkInput = styled.input.attrs(() => ({
  type: 'text',
  placeholder: 'Enter link',
}))`
  font-size: 13px;
  line-height: 18px;
  height: 100%;
  width: 100%;
  display: block;
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: transparent;
`;

export const OkButton = styled.div`
  width: 50px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  line-height: 36px;
  font-size: 13px;
  color: #2287ca;
  font-weight: 600;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const EditLinkIcon = styled.div`
  width: 36px;
  height: 36px;
  margin: 0 6px;

  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/icn_link_focused.svg);
`;
