import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { SwipeEventData } from 'react-swipeable/src/types';
import styled from 'styled-components';

import { FileOrFileLink, isFileLink, QUERY_PICKER, QUERY_TEAM_FILES } from 'client/common/graphql';
import { getParticipantColor } from 'client/common/participant-names';
import { Hamburger } from 'client/components/AppControls/dropMenu-styled';
import { ModalBackDrop } from 'client/components/Common/modal-styled';
import { useAuth } from 'client/hooks/useAuth';
import { ContextMenu } from 'client/components/Common/ContextMenu';

import * as DashboardStyled from '../styled';

import * as Styled from './styled';

const THUMBNAIL_PLACEHOLDER = '/images/ill_thumbnail_placeholder.svg';

const categoriesStatic = [
  {
    id: 'recents',
    name: 'Recents',
    default: true,
  },
  {
    id: 'personal',
    name: 'Personal',
    default: true,
  },
  {
    id: 'sharedwithme',
    name: 'Shared With me',
    default: true,
  },
];

const CastUnderlay = styled.div`
  position: absolute;
  background: #279ae7 url(/icons/icn_open_in_room_white.svg) left 24px center no-repeat;
  background-size: 24px;
  top: 0;
  bottom: 0;
  width: 80px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 1em;
  right: auto;
  left: 0;
  justify-content: left;
`;

const ItemRow = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  height: 64px;
`;

const Item = styled.div`
  border: none;
  padding: 0 0 0 8px;
  position: absolute;
  left: -8px;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  max-width: none;
  transform: none;
  animation: none;
  transition: transform 150ms linear;
  border-radius: 8px;
  background: #fff;

  li:hover {
    background: transparent;
  }
`;

const ListItem: React.FC<{
  onSwiping: (e: SwipeEventData) => void;
  onSwiped: (e: SwipeEventData) => void;
  onCastClick: (e: React.MouseEvent) => void;
}> = ({ children, onCastClick, onSwiped, onSwiping }) => {
  const onSwipe = useSwipeable({ onSwiping, onSwiped });

  return (
    <ItemRow>
      <CastUnderlay onClick={onCastClick} />
      <Item {...onSwipe}>{children}</Item>
    </ItemRow>
  );
};

interface Props {
  setShowFeedbackModal?: (show: boolean) => void;
  setShowItemStack: (show: boolean) => void;
  setShowJoinDialog: (show: boolean) => void;
  showCastDialog: (e: React.MouseEvent, file: FileOrFileLink) => void;
}

const MobileDashboard: React.FC<Props> = ({
  setShowFeedbackModal,
  setShowItemStack,
  setShowJoinDialog,
  showCastDialog,
}) => {
  const [selectedCategory, setSelectedCategory] = useState('recents');

  const [showMenu, setShowMenu] = useState(false);
  const auth = useAuth();

  const { data, loading: loadingData } = useQuery(QUERY_PICKER, {
    variables: { limit: 5, sort: 'LASTMODIFIED_DESC', input: { sort: 'LASTMODIFIED_DESC' } },
  });
  const [getTeamFiles, teamFiles] = useLazyQuery(QUERY_TEAM_FILES);
  const emptyExperience = !loadingData && data?.recents.length === 0;

  const categories = [
    ...categoriesStatic,
    ...(data?.teams?.teams.map((t) => ({ id: t.id, name: t.name, default: false })) ?? []),
  ];
  const categoryItems = categories.map((category) => (
    <Styled.Category
      className={category.id === selectedCategory ? 'is-current' : ''}
      onClick={() => {
        setSelectedCategory(category.id);
        if (!category.default) {
          getTeamFiles({
            variables: { input: { sort: 'LASTMODIFIED_DESC', teamId: category.id } },
          });
        }
      }}
      key={category.id}
    >
      {category.name}
    </Styled.Category>
  ));

  let fileRawList: FileOrFileLink[];
  switch (selectedCategory) {
    case 'personal':
      fileRawList = data?.files;
      break;
    case 'sharedwithme':
      fileRawList = data?.sharedWithMe;
      break;
    case 'recents':
      fileRawList = data?.recents;
      break;
    default:
      fileRawList = teamFiles.data?.teamFiles;
      break;
  }

  const list = fileRawList?.map((fileOrFileLink) => {
    const SWIPE_BUTTON_WIDTH = 80;
    const file = isFileLink(fileOrFileLink) ? fileOrFileLink.file : fileOrFileLink;

    return (
      <ListItem
        key={fileOrFileLink.id}
        onSwiping={(e) => {
          e.event.stopPropagation();

          const currentTarget = e.event.currentTarget as HTMLElement;
          const current = getComputedStyle(currentTarget)
            .getPropertyValue('transform')
            .match(/(-?[\d.]+)/g);
          const x = current ? current[4] : 0;
          if (x > -SWIPE_BUTTON_WIDTH && e.dir === 'Left') {
            currentTarget.style.transform = `translateX(${Math.max(e.deltaX, 0)}px)`;
          } else if (x < SWIPE_BUTTON_WIDTH && e.dir === 'Right') {
            currentTarget.style.transform = `translateX(${Math.min(e.deltaX, SWIPE_BUTTON_WIDTH)}px)`;
          }
        }}
        onSwiped={(e) => {
          e.event.stopPropagation();

          if (e.absX < SWIPE_BUTTON_WIDTH * 0.8) {
            const currentTarget = e.event.currentTarget as HTMLElement;

            currentTarget.style.transform = 'translateX(0)';
          }
        }}
        onCastClick={(e) => showCastDialog(e, fileOrFileLink)}
      >
        <Link
          to={{
            pathname: fileOrFileLink.id !== 'load' ? `/dashboard/open/${file.id}` : '',
            state: { teamId: file?.team?.id },
          }}
        >
          <Styled.BoardItem>
            <Styled.BoardThumbnail
              style={{
                backgroundImage: `url(${file.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
              }}
            />
            <Styled.BoardInfo>
              <Styled.BoardLabel>
                {file.fileName || 'Unnamed Board'}
                {isFileLink(fileOrFileLink) && <DashboardStyled.SharedBoard />}
              </Styled.BoardLabel>
              <Styled.Recency>{dayjs(file.dateModified).fromNow()}</Styled.Recency>
            </Styled.BoardInfo>
            {file.team && (
              <Styled.RecentBoardTeamName color={getParticipantColor(file.team.name)}>
                {file.team.name}
              </Styled.RecentBoardTeamName>
            )}
          </Styled.BoardItem>
        </Link>
      </ListItem>
    );
  });

  return (
    <>
      <Styled.Container>
        <Styled.Header>
          {/* <Search fileSelected={selectFile}/> */}
          My Boards
        </Styled.Header>
        {!loadingData && !emptyExperience && (
          <>
            <Styled.Categories>{categoryItems}</Styled.Categories>
            <Styled.BoardList>{list}</Styled.BoardList>
          </>
        )}
        {emptyExperience && (
          <Styled.BoardList>
            <Styled.EmptyContainer>
              <img src="/images/ill_mobile_unsupported.svg" alt="You don't have any boards yet" />
              <span>Making new boards on mobile devices is not supported at this moment</span>
            </Styled.EmptyContainer>
          </Styled.BoardList>
        )}
        <Styled.MobileBar>
          <ContextMenu
            items={[
              [
                {
                  title: 'Give Feedback',
                  onClick: () => setShowFeedbackModal(true),
                },
                {
                  title: 'Help',
                  onClick: () => window.open('https://help.flatfrog.com/knowledge', '_blank'),
                },
              ],
              [
                {
                  title: (
                    <>Sign out {auth.user?.email && <Styled.EmailTrimmer>{auth.user?.email}</Styled.EmailTrimmer>}</>
                  ),
                  id: 'signout-button',
                  onClick: () => auth.signOut(),
                },
              ],
            ]}
          >
            <Hamburger className="mobile-bar" />
          </ContextMenu>
          <Styled.MobileJoinButton
            onClick={() => {
              setShowJoinDialog(true);
            }}
          >
            Join Room
          </Styled.MobileJoinButton>
          <Styled.ItemsButton
            onClick={() => {
              setShowItemStack(true);
            }}
          />
        </Styled.MobileBar>
      </Styled.Container>
      {showMenu && <ModalBackDrop onClick={() => setShowMenu(false)} className="active no-filter" />}
    </>
  );
};

export default MobileDashboard;
