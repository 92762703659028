import React from 'react';
import styled from 'styled-components';

import * as analytics from 'client/common/analytics';
import * as Modal from 'client/components/Common/modal-styled';

const ModalContainer = styled(Modal.BaseModalContainer)`
  width: fit-content;
  max-width: 96vw;
  padding: 40px 20px;
  color: #242424;

  h3 {
    font: 500 18px/36px Open Sans;
    margin-bottom: 0.5em;
    padding: 0;
    text-transform: capitalize;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

const Illustration = styled.img.attrs({
  src: '/images/ill_mobile_unsupported.svg',
  alt: ' ',
})`
  margin: 1em;
`;

const MobileBlock: React.FC = () => {
  analytics.viewPage('/unsupported-device');
  return (
    <>
      <Modal.ModalBackDrop className="active" />
      <ModalContainer className="active">
        <Illustration />
        <h3>We're currently not supporting mobile</h3>
        <p>Please come back later</p>
      </ModalContainer>
    </>
  );
};

export default MobileBlock;
