import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useAuth } from 'client/hooks/useAuth';

import { Container, Title, FieldForm, Buttons } from './modals-styled';

interface Props {
  onClose: () => void;
}

const ChangeNameModal: React.FC<Props> = ({ onClose }) => {
  const auth = useAuth();
  const nameInput = useRef<HTMLInputElement>();

  const handleChangeName = async (e: React.FormEvent) => {
    e.preventDefault();
    const name = nameInput.current?.value;

    if (name) {
      const result = await auth.changeDisplayName(name);
      if (result === true) {
        onClose();
      } else {
        console.log('Error updating the name', result);
      }
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Container>
        <button className="close" onClick={onClose} />
        <>
          <Title>Change Display Name</Title>
          <FieldForm>
            <form onSubmit={handleChangeName}>
              <label>Display Name</label>
              <input
                type="text"
                name="new-name"
                ref={nameInput}
                id="new-name-input"
                defaultValue={auth.user?.displayName}
                required
                autoFocus
                maxLength={60}
              />
              <Buttons>
                <button type="button" onClick={onClose} className="cancel">
                  Cancel
                </button>
                <button type="submit">Save</button>
              </Buttons>
            </form>
          </FieldForm>
        </>
      </Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default ChangeNameModal;
