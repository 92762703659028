import React from 'react';

import { useFetch } from 'client/hooks/useFetch';
import { DirectoryUser } from 'client/components/Dashboard/admin/types';
import List from 'client/components/Dashboard/admin/list/list';
import * as Styled from 'client/components/Dashboard/admin/integrations/teams/styled';
import * as Assets from 'client/components/Dashboard/admin/assets';
import User from 'client/components/Common/user';

const UserFullList: React.FC = () => {
  const users = useFetch<DirectoryUser[]>('i/teams/directory', [], (data) => data);

  return (
    <>
      <Styled.SectionHeader>
        <Styled.SectionHeaderTitle>Directory</Styled.SectionHeaderTitle>
        <Styled.SectionHeaderSubtitle>All users in your Microsoft 365 Directory</Styled.SectionHeaderSubtitle>
      </Styled.SectionHeader>
      <List
        items={users.data}
        itemKey={(user) => user.id}
        itemColumns={[
          {
            id: 'user',
            title: 'User',
            header: <Styled.FlexBox weight={6} />,
            content: (user) => (
              <Styled.FlexBox weight={6}>
                <User displayName={user.name} image={user.photo?.blobUrl} />
              </Styled.FlexBox>
            ),
            sort: (items, asc) => items.sort((a, b) => a.name.localeCompare(b.name) * (asc ? 1 : -1)),
          },
          {
            id: 'has-account',
            title: 'Has account',
            header: <Styled.FlexBox weight={2} />,
            content: (user) => (
              <Styled.FlexBox weight={2} style={{ fontSize: '19px' }}>
                {user.metadata ? '✓' : null}
              </Styled.FlexBox>
            ),
            sort: (items, asc) => items.sort((a, b) => (Number(!!a.metadata) - Number(!!b.metadata)) * (asc ? 1 : -1)),
          },
          {
            id: 'login-methods',
            title: 'Login methods',
            header: <Styled.FlexBox weight={4} />,
            content: (user) => (
              <Styled.FlexBox weight={4} style={{ fontSize: '13px', display: 'flex' }}>
                {user.metadata &&
                  user.metadata.linkedProviders.map((p) => {
                    switch (p.providerId) {
                      case 'microsoft.com':
                        return (
                          <>
                            <Assets.AdminIntegrationTeamsIcon style={{ width: '16px', marginRight: '8px' }} />
                            Microsoft 365
                          </>
                        );
                      default:
                        return null;
                    }
                  })}
              </Styled.FlexBox>
            ),
          },
        ]}
        loading={users.loading}
        itemNoun="users"
      />
    </>
  );
};

export default UserFullList;
