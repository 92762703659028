import { useLazyQuery, useSubscription } from '@apollo/client';
import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { QUERY_LINK, SUBSCRIPTION_FILES } from 'client/common/graphql';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';

import { GlobalStyle } from './styled';
import * as Styled from './styled';

const THUMBNAIL_PLACEHOLDER = '/images/ill_thumbnail_placeholder.svg';

interface Props {
  boardLink: string;
  link: string;
  name: string;
}

const MeetingSidePanel: React.FC<Props> = ({ boardLink, link, name }) => {
  const auth = useAuth();
  const [getLink, query] = useLazyQuery(QUERY_LINK, {
    variables: { linkId: boardLink },
    nextFetchPolicy: 'network-only',
  });

  const { graphqlWsConnectedTime } = useSelector((state) => ({
    graphqlWsConnectedTime: state.graphqlWsConnectedTime,
  }));

  const { data: subscriptionData } = useSubscription(SUBSCRIPTION_FILES, {
    variables: { websockettime: graphqlWsConnectedTime },
  });

  useEffect(() => {
    if (subscriptionData) {
      query.refetch();
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (!auth.loading) {
      if (auth.user) {
        getLink();
      }
    }
  }, [auth.user, auth.loading]);

  if (query?.loading || auth.loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      <GlobalStyle />
      <Styled.SidePanel>
        <Styled.BoardScreenshotSmall
          style={{
            backgroundImage: `url(${query.data?.link.file.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
          }}
        />
        <Styled.BoardTitleName>{query.data?.link.file.fileName ?? name}</Styled.BoardTitleName>
        <Styled.SideInfoBox>
          {!isMobileOnly && (
            <>
              <p>
                Share the Board within the meeting by tapping the Share button
                <img src="/icons/icn_teams_share.svg" alt="Share" /> above
              </p>
              <p>
                or join the{' '}
                <a href={link} target="_blank">
                  Board
                </a>{' '}
                in an external window
              </p>
            </>
          )}
          {isMobileOnly && (
            <p>
              Join the{' '}
              <a href={link} target="_blank">
                Board
              </a>{' '}
              in an external window
            </p>
          )}
        </Styled.SideInfoBox>
      </Styled.SidePanel>
    </>
  );
};

export default MeetingSidePanel;
