import React, { useEffect, useRef, useState } from 'react';
import { isMacOs, isIPad13 } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactTooltip from 'react-tooltip';
import ZCanvas from '@flatfrog/ffbec';
import { ZActions, ZShapes } from '@flatfrog/ffbec/js/zwasm';

import * as analytics from 'client/common/analytics';
import { useActions } from 'client/hooks/useActions';
import useEventListener from 'client/hooks/useEventListener';
import { useSelector } from 'client/hooks/useSelector';

import * as Styled from './zoom-styled';

export const ZOOM_IN_ACTION = 'ZOOM_IN';
export const ZOOM_OUT_ACTION = 'ZOOM_OUT';
export const ZOOM_PAN = 'ZOOM_PAN';
export const ZOOM_LEVELS = [1, 1.5, 2, 3, 4];

interface Props {
  hidden: boolean;
}

const ZoomControls: React.FC<Props> = ({ hidden }) => {
  const { currentZAction } = useSelector((state) => ({
    currentZAction: state.currentZAction,
  }));

  const { setZAction } = useActions();

  const [zCanvasReady, setZCanvasReady] = useState(false);
  const [isZoomed, setZoomed] = useState(false);
  const [selectedZoomLevel, setSelectedZoomLevel] = useState(0);
  const [customZoomLevel, setCustomZoomLevel] = useState(1.0);
  const currentAction = useRef(currentZAction);
  const prevAction = useRef(currentZAction);

  const reset = () => {
    setZoomed(false);
    setSelectedZoomLevel(0);
    setCustomZoomLevel(1);
    if (currentAction.current[0] === ZActions.PANZOOM) {
      setZAction(prevAction.current[0], prevAction.current[1], prevAction.current[2]);
    }
  };

  const handleZoomPanStopped = () => {
    const zoomLevel = ZCanvas.writingAreaZoomGet();
    setCustomZoomLevel(zoomLevel);

    const newSelectedIndex = ZOOM_LEVELS.findIndex((zoom) => zoom >= zoomLevel);
    setSelectedZoomLevel(Math.max(newSelectedIndex, -1));

    if (zoomLevel <= 1.0) {
      setZAction(prevAction.current[0], prevAction.current[1], prevAction.current[2]);
    }
  };

  const handleZoomPanStarted = () => {
    if (currentAction.current[0] !== ZActions.PANZOOM) {
      prevAction.current = currentAction.current;
    }
  };

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    if (zCanvasReady) {
      // Add if needed
      ZCanvas.addEventListener('WRITING_AREA_PANZOOM_STARTED', handleZoomPanStarted);
      ZCanvas.addEventListener('WRITING_AREA_PANZOOM_STOPPED', handleZoomPanStopped);
      ZCanvas.addEventListener('WRITING_AREA_PANZOOM_RESET', handleReset);
    }
  }, [zCanvasReady]);

  useEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [isZoomed]);

  useEffect(() => {
    setZoomed(selectedZoomLevel > 0);
  }, [selectedZoomLevel]);

  useEffect(() => {
    currentAction.current = currentZAction;
  }, [currentZAction]);

  // Hotkeys
  useHotkeys(
    'z',
    () => {
      if (selectedZoomLevel + 1 < ZOOM_LEVELS.length) {
        setSelectedZoomLevel(selectedZoomLevel + 1);
        setZAction(ZActions.PANZOOM, ZShapes.FREEFORM, null);
        ZCanvas.writingAreaZoomSet(ZOOM_LEVELS[selectedZoomLevel + 1]);
        analytics.zoomIn();
      }
    },
    {},
    [selectedZoomLevel]
  );

  useHotkeys(
    'alt+z',
    () => {
      if (selectedZoomLevel > 0) {
        setSelectedZoomLevel(selectedZoomLevel - 1);
        setZAction(ZActions.PANZOOM, ZShapes.FREEFORM, null);
        ZCanvas.writingAreaZoomSet(ZOOM_LEVELS[selectedZoomLevel - 1]);
        analytics.zoomOut();
      }
    },
    {},
    [selectedZoomLevel]
  );

  useHotkeys(
    'space',
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isZoomed && currentZAction[0] !== ZActions.PANZOOM) {
        setZAction(ZActions.PANZOOM, ZShapes.FREEFORM, null);
      }
    },
    { keydown: true, keyup: false },
    [isZoomed, currentZAction]
  );

  useHotkeys(
    'space',
    () => {
      if (isZoomed && currentZAction[0] === ZActions.PANZOOM) {
        setZAction(prevAction.current[0], prevAction.current[1], prevAction.current[2]);
      }
    },
    { keydown: false, keyup: true },
    [isZoomed, currentZAction]
  );

  useEventListener('zcanvasinitialized', async () => {
    await ZCanvas.initialized;
    setZCanvasReady(true);
  });

  const getCurrentZoomLevel = () => Math.round(customZoomLevel * 100);

  if (!zCanvasReady) {
    return null;
  }

  return (
    !isIPad13 && (
      <>
        <Styled.ZoomControls hidden={hidden}>
          {isZoomed && (
            <Styled.ZoomMenu>
              <Styled.PanTool
                data-tip={
                  isZoomed
                    ? `
            Pan
            <span class="shortcut">Hold Space</span>
          `
                    : ''
                }
                data-place="left"
                className={`${currentZAction[0] === ZActions.PANZOOM ? 'active' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.currentTarget.blur();
                  if (currentZAction[0] === ZActions.PANZOOM) {
                    setZAction(prevAction.current[0], prevAction.current[1], prevAction.current[2]);
                  } else {
                    setZAction(ZActions.PANZOOM, ZShapes.FREEFORM, true);
                  }
                }}
              />
            </Styled.ZoomMenu>
          )}
          <Styled.ZoomMenu>
            <Styled.ZoomIn
              data-tip={
                selectedZoomLevel + 1 === ZOOM_LEVELS.length
                  ? ''
                  : `
            Zoom In
            <span class="shortcut">Z</span>
          `
              }
              data-place="left"
              disabled={selectedZoomLevel + 1 === ZOOM_LEVELS.length}
              onClick={(e) => {
                e.stopPropagation();
                e.currentTarget.blur();
                if (!isZoomed) {
                  setZAction(ZActions.PANZOOM, ZShapes.FREEFORM, true);
                }
                setSelectedZoomLevel(selectedZoomLevel + 1);
                ZCanvas.writingAreaZoomSet(ZOOM_LEVELS[selectedZoomLevel + 1]);
                analytics.zoomIn();
              }}
            />
            <Styled.ZoomLevel
              onClick={(e) => {
                e.stopPropagation();
                e.currentTarget.blur();
                ZCanvas.writingAreaZoomReset();
                analytics.zoomReset();
              }}
              data-place="left"
              data-tip="Reset"
            >
              {getCurrentZoomLevel()}%
            </Styled.ZoomLevel>
            <Styled.ZoomOut
              data-tip={
                selectedZoomLevel === 0
                  ? ''
                  : `
            Zoom Out
            <span class="shortcut">${isMacOs ? '⌥Z' : 'Alt + Z'}</span>
          `
              }
              data-place="left"
              disabled={selectedZoomLevel === 0}
              onClick={(e) => {
                e.stopPropagation();
                e.currentTarget.blur();
                setSelectedZoomLevel(selectedZoomLevel - 1);
                ZCanvas.writingAreaZoomSet(ZOOM_LEVELS[selectedZoomLevel - 1]);
                analytics.zoomOut();
              }}
            />
          </Styled.ZoomMenu>
        </Styled.ZoomControls>
      </>
    )
  );
};

export default ZoomControls;
