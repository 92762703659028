import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  canonical?: string;
  description?: string;
}

const Seo: React.FC<Props> = ({ title, canonical, description }) => (
  <Helmet>
    <title>{title ?? 'FlatFrog Board'}</title>
    <link rel="canonical" href={`${window.INITIAL_STATE.appServerUrl}/${canonical ?? ''}`} />
    <meta
      name="description"
      content={
        description ??
        'Easy to Use Free Online Whiteboard for Interactive Displays. FlatFrog is a Market Leader of Digital Whiteboards and InGlass Display Technology used by Collabrative Teams Everywhere.'
      }
    />
  </Helmet>
);

export default Seo;
