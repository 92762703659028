import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import * as AuthStyled from 'client/components/Auth/styled';
import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useAuth } from 'client/hooks/useAuth';

import { Container, Title, FieldForm, Buttons } from './modals-styled';

interface Props {
  onClose: () => void;
}

const ChangePasswordModal: React.FC<Props> = ({ onClose }) => {
  const oldPassword = useRef<HTMLInputElement>();
  const newPassword = useRef<HTMLInputElement>();
  const [passwordHintOld, setPasswordHintOld] = useState(false);
  const [passwordHintNew, setPasswordHintNew] = useState(false);
  const [validationResponse, setValidationResponse] = useState(null);
  const [reauthenticate, setReauthenticate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const auth = useAuth();

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (reauthenticate) {
      const current = oldPassword.current?.value;
      const result = await auth.reauthenticateWithPassword(current);

      if (result !== true) {
        setValidationResponse(result);
        return;
      }
    }

    const newPass = newPassword.current?.value;

    if (newPass) {
      const result = await auth.changePassword(newPass);

      if (result.success === true) {
        setShowSuccess(true);
      } else if (!result.success && result.code === 'auth/requires-recent-login') {
        setReauthenticate(true);
      } else {
        setValidationResponse(result);
      }
    }
  };

  const success = (
    <>
      <Title>Password Changed Successfully</Title>
      <FieldForm>
        <Buttons>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </Buttons>
      </FieldForm>
    </>
  );

  const currentPasswordField = (
    <>
      <label className={validationResponse?.code === 'auth/wrong-password' ? 'error' : ''}>
        {validationResponse?.code === 'auth/wrong-password' ? 'Wrong password' : 'Current Password'}
      </label>
      <AuthStyled.PasswordFieldWrapper>
        <input
          className={validationResponse?.code === 'auth/wrong-password' ? 'error' : ''}
          type={passwordHintOld ? 'text' : 'password'}
          name="old-password"
          ref={oldPassword}
          id="old-password-input"
          onChange={() => {
            if (validationResponse?.code === 'auth/wrong-password') {
              setValidationResponse(null);
            }
          }}
          required
        />
        <AuthStyled.PasswordHintToggle
          className={passwordHintOld ? 'show' : ''}
          id="old-password-hint"
          onMouseDown={() => {
            setPasswordHintOld(true);
          }}
          onMouseUp={() => {
            setPasswordHintOld(false);
          }}
        />
      </AuthStyled.PasswordFieldWrapper>
    </>
  );

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Container>
        <button className="close" onClick={onClose} />
        {showSuccess ? (
          success
        ) : (
          <>
            <Title>Change Password</Title>
            <FieldForm>
              <form onSubmit={handleChangePassword}>
                {validationResponse?.code === 'auth/too-many-requests' && (
                  <div className="error">{validationResponse.message}</div>
                )}
                <label className={validationResponse?.code === 'auth/weak-password' ? 'error' : ''}>
                  {validationResponse?.code === 'auth/weak-password'
                    ? 'The new password must be at least 6 characters'
                    : 'Choose New Password'}
                </label>
                <AuthStyled.PasswordFieldWrapper>
                  <input
                    className={validationResponse?.code === 'auth/weak-password' ? 'error' : ''}
                    type={passwordHintNew ? 'text' : 'password'}
                    name="new-password"
                    ref={newPassword}
                    id="new-password-input"
                    onChange={() => {
                      if (validationResponse?.code === 'auth/weak-password') {
                        setValidationResponse(null);
                      }
                    }}
                    required
                    autoFocus
                  />
                  <AuthStyled.PasswordHintToggle
                    className={passwordHintNew ? 'show' : ''}
                    id="new-password-hint"
                    onMouseDown={() => {
                      setPasswordHintNew(true);
                    }}
                    onMouseUp={() => {
                      setPasswordHintNew(false);
                    }}
                  />
                </AuthStyled.PasswordFieldWrapper>
                {reauthenticate && currentPasswordField}
                <Buttons>
                  <button type="button" onClick={onClose} className="cancel">
                    Cancel
                  </button>
                  <button type="submit">Save</button>
                </Buttons>
              </form>
            </FieldForm>
          </>
        )}
      </Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default ChangePasswordModal;
