import { createAction, createReducer } from '@reduxjs/toolkit';

import { TextAlignments } from 'client/components/Common/text-alignments';

export type LastStickyNoteProps = {
  backgroundColor: string;
  textColor?: string;
  textSize?: number;
  textAlign?: TextAlignments;
  signature?: string;
};

export const setStickyNoteLastProps = createAction<LastStickyNoteProps>('setStickyNoteLastProps');

export default createReducer({} as LastStickyNoteProps, (builder) =>
  builder.addCase(setStickyNoteLastProps, (state, action) => ({ ...state, ...action.payload }))
);
