import { createAction, createReducer } from '@reduxjs/toolkit';

export const toggleSessionEndedDialog = createAction(
  'toggleSessionEndedDialog',
  (show: boolean, errorMessage?: string) => ({ payload: { show, errorMessage } })
);

export default createReducer({ show: false } as { show: boolean; errorMessage?: string }, (builder) =>
  builder.addCase(toggleSessionEndedDialog, (state, action) => action.payload)
);
