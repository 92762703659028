// TODO: There is still leap stuff here, let's clean up

import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';

export const log = (msg: string) => console.log(`[${dayjs().format('mm:ss.SSS')}] ${msg}`);

export const CONNECTION_STATUS = {
  waiting: 'waiting',
  connected: 'connected',
  failed: 'failed',
};

const SUPPORTED_FILE_TYPES =
  '^(image/png|image/jpg|image/jpeg|application/pdf|application/vnd.openxmlformats-officedocument.presentationml.presentation|application/vnd.ms-powerpoint|application/vnd.ms-excel|application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet)';
const SUPPORTED_FILE_TYPES_LIMITED = '^(image/png|image/jpg|image/jpeg|application/pdf)';

const SUPPORTED_FILE_EXTENSIONS = '.jpg,.jpeg,.png,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx';
const SUPPORTED_FILE_EXTENSIONS_LIMITED = '.jpg,.jpeg,.png,.pdf';

export const OFFICE_FILE_TYPES =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation|application/vnd.ms-powerpoint|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/vnd.ms-excel|application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const getSupportedFileTypes = (signedIn: boolean) => {
  if (signedIn) {
    return SUPPORTED_FILE_TYPES;
  }
  return SUPPORTED_FILE_TYPES_LIMITED;
};

export const getSupportedFileExtensions = (signedIn: boolean) => {
  if (signedIn) {
    return SUPPORTED_FILE_EXTENSIONS;
  }
  return SUPPORTED_FILE_EXTENSIONS_LIMITED;
};

export const CONNECTION_FAIL_REASON = {
  notFound: 'not_found',
  unknown: 'unknown',
};

export const sendErrorReport = (options: { error: Error; message?: string }) => {
  const { error, message } = options;
  // Report runtime exceptions from any GCP environment. The dev reports will
  // contain more debug info. But don't report from local environments.
  if (!window.INITIAL_STATE.gcpEnv) {
    return;
  }
  if (error) {
    Sentry.captureException(error);
  } else {
    Sentry.captureMessage(message);
  }
};

export const makeRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

export const MEDIUM_BREAK = 1024;
export const SMALL_BREAK = 680;

export const THUMBNAIL_SIZE = { width: 288, height: 162 };
export const THUMBNAIL_FREQ = 1000;
