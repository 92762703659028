import classNames from 'classnames';
import React, { useEffect, useState, useRef, Ref } from 'react';
import ZCanvas from '@flatfrog/ffbec';

import * as analytics from 'client/common/analytics';
import * as StyledSideBar from 'client/components/SideBar/styled';
import STICKERS from 'client/components/Whiteboard/StickersList';

import * as Styled from './styled';

interface Props {
  customStickerSelected: (file: File) => void;
  defaultStickerSelected: (fileName: string) => void;
  existingStickerSelected: (imageId: number) => void;
  setStickerTrayOpen: (open: boolean) => void;
  stickerTrayOpen: boolean;
  placement: 'vertical' | 'horizontal';
  room: boolean;
}

const StickerTray: React.FC<Props> = ({
  customStickerSelected,
  defaultStickerSelected,
  existingStickerSelected,
  setStickerTrayOpen,
  stickerTrayOpen,
  placement,
  room,
}) => {
  const toolTrayRef = useRef<HTMLDivElement>(null);
  const [translateIntoView, setTranslateIntoView] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (toolTrayRef.current) {
      const boundingRect = toolTrayRef.current.getBoundingClientRect();
      const x = boundingRect.right > window.innerWidth ? -70 - boundingRect.width : 0;
      const y = boundingRect.bottom > window.innerHeight ? window.innerHeight - boundingRect.bottom : 0;
      setTranslateIntoView({ x, y });
    } else {
      setTranslateIntoView({ x: 0, y: 0 });
    }
    setVisible(stickerTrayOpen);
  }, [stickerTrayOpen]);

  const renderStickerButtons = () => {
    const categories: Record<
      string,
      | {
          ref: Ref<HTMLDivElement>;
          stickers: { imageId: number; imageName: string; imageData: string }[];
          existingStickerImages: true;
        }
      | {
          ref: Ref<HTMLDivElement>;
          stickers: { fileName: string }[];
          existingStickerImages: false;
        }
    > = {};
    const stickers = [];

    const recentCategory = 'recents';
    if (ZCanvas.getZcoreCreated && ZCanvas.getZcoreCreated()) {
      const recents = ZCanvas.paper.getRecentlyUsedStickerImages(12);
      if (recents.length > 0) {
        const ref = React.createRef<HTMLDivElement>();
        categories[recentCategory] = { ref, stickers: [], existingStickerImages: true };
        recents.slice(0, 24).forEach((stickerImage) => {
          (
            categories[recentCategory].stickers as {
              imageId: number;
              imageName: string;
              imageData: string;
            }[]
          ).push(stickerImage);
        });
      }
    }

    STICKERS.forEach((sticker) => {
      // if (stickerSearchQuery && !sticker.fileName.includes(stickerSearchQuery)
      //     && !sticker.category.includes(stickerSearchQuery)) {
      //   return;
      // }

      if (!(sticker.category in categories)) {
        const ref = React.createRef<HTMLDivElement>();
        categories[sticker.category] = { ref, stickers: [], existingStickerImages: false };
      }

      (categories[sticker.category].stickers as { fileName: string }[]).push(sticker);
    });

    const inSessionCategory = 'currently used';
    if (ZCanvas.getZcoreCreated && ZCanvas.getZcoreCreated()) {
      const inSession = ZCanvas.paper.getUsedStickerImages();
      if (inSession.length > 0) {
        const ref = React.createRef<HTMLDivElement>();
        categories[inSessionCategory] = { ref, stickers: [], existingStickerImages: true };
        inSession.forEach((stickerImage) => {
          (
            categories[inSessionCategory].stickers as {
              imageId: number;
              imageName: string;
              imageData: string;
            }[]
          ).push(stickerImage);
        });
      }
    }

    for (const [categoryName, category] of Object.entries(categories)) {
      stickers.push(
        <Styled.StickerCategory key={categoryName}>
          <Styled.StickerCategoryHeader ref={category.ref}>{categoryName}</Styled.StickerCategoryHeader>
          {category.existingStickerImages === false &&
            category.stickers.map((sticker) => (
              <Styled.StickerButton
                key={sticker.fileName}
                style={{
                  backgroundImage: `url(/stickers/${sticker.fileName})`,
                }}
                onClick={(e) => {
                  if (!e.shiftKey) {
                    setStickerTrayOpen(false);
                  }
                  defaultStickerSelected(`/stickers/${sticker.fileName}`);
                  analytics.addSticker(sticker.fileName);
                }}
              />
            ))}
          {category.existingStickerImages &&
            category.stickers.map((stickerImage) => (
              <Styled.StickerButton
                key={stickerImage.imageName}
                onClick={(e) => {
                  if (!e.shiftKey) {
                    setStickerTrayOpen(false);
                  }
                  existingStickerSelected(stickerImage.imageId);

                  const defaultStickers = STICKERS.filter((s) => s.imageName === stickerImage.imageName);
                  if (defaultStickers.length > 0) {
                    analytics.addSticker(defaultStickers[0].fileName);
                  } else {
                    analytics.addSticker('custom');
                  }
                }}
              >
                <img
                  draggable="false"
                  src={URL.createObjectURL(new Blob([stickerImage.imageData], { type: 'image/png' }))}
                />
              </Styled.StickerButton>
            ))}
        </Styled.StickerCategory>
      );
    }

    return (
      <>
        <Styled.StickerScrollArea>{stickers}</Styled.StickerScrollArea>
        {/* Uncomment to get Category buttons, when we have more stickers */}
        {/* <Styled.StickerCategoryButtonsContainer>
          {Object.keys(categories).map(category => <Styled.StickerCategoryButton key={category} onClick={() => {
            categories[category].ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }}>
            {category}
          </Styled.StickerCategoryButton>)}
        </Styled.StickerCategoryButtonsContainer> */}
      </>
    );
  };

  if (!stickerTrayOpen) {
    return null;
  }
  return (
    <Styled.ToolTrayContainer
      className={classNames({ visible }, placement)}
      style={{ transform: `translate(${translateIntoView.x}px, ${translateIntoView.y}px)` }}
      ref={toolTrayRef}
      onClick={(e) => e.stopPropagation()}
    >
      <Styled.StickerTopBar>
        <span>Add Sticker</span>
        {/* Uncomment to get a search field, when we have more stickers */}
        {/* <Styled.StickerSearchField
            placeholder="Search Sticker"
            value={stickerSearchQuery}
            onChange={event => {
              setStickerSearchQuery(event.target.value);
            }}/> */}

        <StyledSideBar.CloseTray
          onClick={() => {
            setStickerTrayOpen(false);
          }}
        />
      </Styled.StickerTopBar>
      <Styled.VotingTip
        onClick={() => !room && window.open('https://help.flatfrog.com/en/knowledge/how-to-vote', '_blank')}
      >
        Tip: Stickers can be used for voting!
      </Styled.VotingTip>
      {renderStickerButtons()}
      {!room && (
        <Styled.AddCustomStickerButtonWrapper>
          <Styled.AddCustomStickerButton>
            Create Sticker
            <label>
              <input
                accept=".png,.jpeg,.jpg"
                type="file"
                multiple
                onChange={(e) => {
                  e.persist();
                  Array.from(e.target.files).forEach((file) => {
                    if (file.type.match('^image/(png|jpg|jpeg)')) {
                      customStickerSelected(file);
                      analytics.addSticker('custom');
                    }
                  });
                  setStickerTrayOpen(false);
                  e.target.value = null;
                }}
              />
            </label>
          </Styled.AddCustomStickerButton>
        </Styled.AddCustomStickerButtonWrapper>
      )}
    </Styled.ToolTrayContainer>
  );
};

export default StickerTray;
