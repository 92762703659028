import React from 'react';

import { Checkbox, Slider, Switch, SwitchContainer } from './toggleswitch-styled';

interface Props {
  id?: string;
  color?: string;
  translucent?: boolean;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  text?: string;
}

const ToggleSwitch: React.FC<Props> = ({ checked, color, disabled, id, onChange, text, translucent }) => (
  <SwitchContainer>
    <Switch id={id} color={color || '#63c199'} translucent={translucent}>
      <Checkbox disabled={disabled} onChange={onChange} checked={checked} />
      <Slider />
    </Switch>
    <span>{text}</span>
  </SwitchContainer>
);

export default ToggleSwitch;
