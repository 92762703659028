import styled from 'styled-components';

export const FadeContainer = styled.div`
  position: fixed;
  bottom: 0;
  height: 120px;
  left: 0;
  right: 0;
  background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffffcb 50%, #ffffff 100%);
  pointer-events: none;
  padding: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: transform 300ms ease-in-out;

  > * {
    pointer-events: all;
  }

  &.hidden {
    transform: translateY(100%);
  }
`;

export const Button = styled.div`
  width: 17vw;
  height: 17vw;
  max-width: 64px;
  max-height: 64px;

  &:nth-child(2) {
    margin-left: auto;
  }

  &:nth-last-child(2) {
    margin-right: auto;
  }

  &.disabled {
    opacity: 0.3;
  }
`;

export const BurgerButton = styled(Button)`
  background: url(icons/icn_burger_menu.svg) center center no-repeat;
`;

export const PrevButton = styled(Button)`
  background: url(icons/icn_page_back.svg) center center no-repeat;
  transform: rotate(90deg);
`;

export const NextButton = styled(Button)`
  background: url(icons/icn_page_back.svg) center center no-repeat;
  transform: rotate(-90deg);
`;

export const ItemsButton = styled(Button)`
  background: url(icons/icn_myitems.svg) center center no-repeat;
`;

export const Pages = styled.div`
  max-width: 88px;
  height: 17vw;
  max-height: 64px;
  align-items: center;
  display: flex;
  text-align: center;
  font: normal normal normal 13px/18px Open Sans;
  letter-spacing: 0;
  margin: 0 8px;
  color: #242424;
`;
