import FontFaceObserver from 'fontfaceobserver';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { TextAlignments } from 'client/components/Common/text-alignments';
import { useActions } from 'client/hooks/useActions';

import {
  ForegroundColors as TextBlockForegroundColors,
  BackgroundColors as TextBlockBackgroundColors,
  OutlineColors as TextBlockOutlineColors,
} from './colors';
import { TextBlockCanvas, TextBlockWrapper, EditInfo } from './styled';

// The default font size on the canvas. Divide by scale factor to get default font size on screen.
export const TEXT_BLOCK_DEFAULT_FONT_SIZE = 140;

export const getTextBlockTemplate = (content = '') => ({
  type: 'TextBlock',
  content,
  backgroundColor: TextBlockBackgroundColors[0],
  outlineColor: TextBlockOutlineColors[0],
  textColor: TextBlockForegroundColors[0],
  textSize: TEXT_BLOCK_DEFAULT_FONT_SIZE,
  textAlign: TextAlignments.Left,
});

const roundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  fill: boolean,
  stroke: boolean
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + (width - radius), y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + (height - radius));
  ctx.quadraticCurveTo(x + width, y + height, x + (width - radius), y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + (height - radius));
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};

interface Props {
  id?: string;
  content: string;
  link?: string;
  color: string;
  outlineColor: string;
  preview?: boolean;
  textColor: string;
  isSent?: boolean;
  textSize: number;
  textAlign: string;
  width?: number;
  height?: number;
  onAdjustCanvasSize?: (
    width: number,
    height: number,
    paddingX: number,
    paddingY: number,
    marginX: number,
    marginY: number,
    strokeWidth: number,
    fontScale: number
  ) => void;
  canvasRef?: MutableRefObject<HTMLCanvasElement>;
}

const TextBlock: React.FC<Props> = ({
  canvasRef,
  color,
  content,
  id,
  isSent,
  onAdjustCanvasSize,
  outlineColor,
  preview,
  textAlign,
  textColor,
  textSize: renderTextSize,
  width = 60,
  height = 20,
}) => {
  const { currentTextBlockEdit, assignItemCanvas } = useActions();

  const [edited, setEdited] = useState(false);

  const canvas = canvasRef ?? useRef<HTMLCanvasElement>();

  useEffect(() => {
    if (canvas.current) {
      width = width ?? width;
      height = height ?? height;
      setEdited(content.length > 0);
      assignItemCanvas(id, canvas.current);
      updateCanvas();
      const font = new FontFaceObserver('Open Sans', { weight: 600 });
      font.load().then(
        () => {
          updateCanvas();
        },
        () => console.warn('Failed to load Open Sans Semibold')
      );
      if (window.history && window.history.pushState) {
        window.addEventListener(
          'popstate',
          (e) => {
            e.stopPropagation();
            currentTextBlockEdit(null);
          },
          false
        );
      }
    }
  }, [canvas]);

  useEffect(() => {
    updateCanvas();
  });

  useEffect(() => {
    if (content.length > 0 && !edited) {
      setEdited(true);
    }
  }, [content]);

  const handleToggleEditor = () => {
    currentTextBlockEdit({ id });
    window.history.replaceState(null, document.title, window.location.href);
    window.history.pushState(null, document.title, window.location.href);
  };

  const updateCanvas = () => {
    if (canvas.current) {
      const ctx = canvas.current.getContext('2d');
      const usePadding = true;
      const strokeWidth = 9;
      const paddingX = usePadding ? 96 : 16;
      const paddingY = usePadding ? 72 : 16;
      const marginX = 96 - (paddingX - strokeWidth);
      const marginY = 72 - (paddingY - strokeWidth);
      const renderWidth = 3840 - 2 * paddingX;
      const renderHeight = 2160 - 2 * paddingY;
      let lineHeight = 1.15 * renderTextSize;
      let renderLines;
      if (content && content.length > 0) {
        const lines = content.split(/\r?\n/);
        const lineData: ({ chunk: string; whitespace: boolean } | { break: true })[] = [];
        lines.forEach((line, index, arr) => {
          const chunks = line.split(/(\s+)/);
          chunks.forEach((chunk) => {
            if (chunk.length > 0) {
              lineData.push({ chunk, whitespace: chunk[0].match(/\s/) !== null });
            }
          });
          if (index < arr.length - 1) {
            lineData.push({ break: true });
          }
        });

        // eslint-disable-next-line no-constant-condition
        while (true) {
          // Check if we will overflow and should try a smaller text size
          if (lines.length * lineHeight < renderHeight) {
            // The content may fit depending on the line lengths
            renderLines = [];
            let line: { width: number; chunks: string[] } = { width: 0, chunks: [] };
            // Measure all words and gaps
            ctx.font = `600 ${renderTextSize}px "Open Sans"`;
            const spaceWidth = ctx.measureText(' ').width;
            let hasBreaked = false;
            for (let l = 0; l < lineData.length; l += 1) {
              const item = { ...lineData[l] };
              if ('break' in item && item.break) {
                // Check if we will overflow and should try a smaller text size
                if ((renderLines.length + 2) * lineHeight > renderHeight) {
                  hasBreaked = true;
                  break;
                }
                // Push the line and start a new
                renderLines.push({ content: `${line.chunks.join('')}\n`, width: line.width });
                line = { width: 0, chunks: [] };
              } else if ('whitespace' in item && item.whitespace) {
                // Don't bother measuring whitespace, it is assumed to have fixed length
                line.width += item.chunk.length * spaceWidth;
                if (line.width > renderWidth) {
                  // If the whitespace overflows, cap the line width so alignment works well
                  line.width = renderWidth;
                }
                line.chunks.push(item.chunk);
              } else if ('whitespace' in item) {
                // A word, measure item
                const widths = item.chunk.split('').map((ch) => ctx.measureText(ch).width);
                let width2 = widths.reduce((a, b) => a + b, 0);
                while (line.width + width2 > renderWidth) {
                  // The word will not fit on the current line but must be moved to the next
                  // First check if we will overflow and should try a smaller text size
                  // Note that we need to fit two more lines
                  if ((renderLines.length + 2) * lineHeight > renderHeight) {
                    hasBreaked = true;
                    break;
                  }
                  if (line.width > 0) {
                    // Push the line and start a new
                    renderLines.push({ content: `${line.chunks.join('')}\n`, width: line.width });
                    line = { width: 0, chunks: [] };
                  }
                  // Start a new line with as much as possible of the word
                  // Figure out how many characters we can take
                  let charsCount = 0;
                  let reduceDone = false;
                  const charsWidth = widths.reduce((accumulated, charWidth) => {
                    if (!reduceDone) {
                      if (accumulated + charWidth <= renderWidth) {
                        charsCount += 1;
                        return accumulated + charWidth;
                      }
                      reduceDone = true;
                    }
                    return accumulated;
                  }, 0);
                  line.width += charsWidth;
                  line.chunks.push(item.chunk.substring(0, charsCount));
                  item.chunk = item.chunk.substring(charsCount);
                  widths.splice(0, charsCount);
                  width2 -= charsWidth;
                }
                if (hasBreaked) {
                  break;
                }
                // The word will fit on the current line, so add it
                line.width += width2;
                line.chunks.push(item.chunk);
              }
            }
            // Flush the line
            renderLines.push({ content: `${line.chunks.join('')}\n`, width: line.width });
            if (!hasBreaked) {
              // We fit everything in
              break;
            }
          }
          // Reduce the size and try again
          renderTextSize *= 0.9;
          lineHeight = 1.15 * renderTextSize;
        }
      }
      let textWidth: number;
      let textHeight;
      if (renderLines) {
        textWidth = Math.max(...renderLines.map((line) => line.width));
        textHeight = renderLines.length * lineHeight;
      } else {
        textWidth = 0;
        textHeight = lineHeight;
      }
      const w = 2 * paddingX + textWidth;
      const h = 2 * paddingY + textHeight;
      canvas.current.width = w;
      canvas.current.height = h;
      ctx.fillStyle = color;
      ctx.strokeStyle = outlineColor;
      ctx.lineWidth = strokeWidth;
      roundRect(ctx, strokeWidth / 2, strokeWidth / 2, w - strokeWidth, h - strokeWidth, 25, true, true);
      if (renderLines) {
        ctx.font = `600 ${renderTextSize}px "Open Sans"`;
        ctx.fillStyle = textColor;
        ctx.textBaseline = 'alphabetic';
        ctx.textAlign = 'left';
        const y = paddingY - (lineHeight - renderTextSize);
        renderLines.forEach((line, index) => {
          let x = paddingX;
          switch (textAlign) {
            case TextAlignments.Center:
              x += (textWidth - line.width) / 2;
              break;
            case TextAlignments.Right:
              x += textWidth - line.width;
              break;
            default:
              break;
          }
          ctx.fillText(line.content, x, y + (index + 1) * lineHeight);
        });
      }
      if (onAdjustCanvasSize) {
        onAdjustCanvasSize(
          w,
          h,
          paddingX - strokeWidth,
          paddingY - strokeWidth,
          marginX,
          marginY,
          strokeWidth,
          renderTextSize / renderTextSize
        );
      }
    }
  };

  let editInfo;

  if (!edited && !preview) {
    editInfo = <EditInfo>Click to edit</EditInfo>;
  }

  let textBlock;

  if (!preview) {
    textBlock = (
      <TextBlockCanvas
        id={`text-block-canvas-${id}`}
        fade={isSent}
        onClick={handleToggleEditor}
        ref={canvas}
        color={color}
      />
    );
  } else {
    textBlock = <TextBlockCanvas ref={canvas} color={color} />;
  }

  return (
    <TextBlockWrapper className={isMobile ? 'mobile' : ''} width={width} height={height}>
      {textBlock}
      {editInfo}
    </TextBlockWrapper>
  );
};

export default TextBlock;
