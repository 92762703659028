import { useMutation } from '@apollo/client';
import React, { useRef } from 'react';

import { MUTATION_SAVE_UNOWNED_FILE } from 'client/common/graphql';
import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';

interface Props {
  hide: () => void;
  onSuccess: () => void;
  loadedFileInfo?: { id?: string };
}

export const PersonalSaveDialog: React.FC<Props> = ({ hide, onSuccess, loadedFileInfo }) => {
  const saveFileNameText = useRef(null);
  const [saveUnownedFile] = useMutation(MUTATION_SAVE_UNOWNED_FILE);

  const save = async () => {
    const name = saveFileNameText?.current.value;

    if (loadedFileInfo?.id && name) {
      try {
        const response = await saveUnownedFile({
          variables: { fileId: loadedFileInfo.id, name },
        });

        if (response.data?.saveUnownedFile?.success) {
          onSuccess();
          hide();
        } else {
          const errorText = response.data?.saveUnownedFile?.error === 'NOT_ALLOWED' ? 'Not allowed' : 'Unknown error';

          console.log(`saveUnownedFile: ${errorText}`);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Dialog noButtons={true} onCancel={hide} cover>
      <DialogStyled.Title style={{ fontWeight: 600, marginBottom: '24px' }}>Save Board</DialogStyled.Title>
      <DialogStyled.FileNameField ref={saveFileNameText} style={{ width: '280px', marginBottom: '16px' }} />
      <DialogStyled.NextButton onClick={save} style={{ width: '100%', marginBottom: '8px' }}>
        Save
      </DialogStyled.NextButton>
      <DialogStyled.CancelButton onClick={hide} style={{ width: '100%' }}>
        Cancel
      </DialogStyled.CancelButton>
    </Dialog>
  );
};
