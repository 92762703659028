import styled from 'styled-components';

import { FloatingPanel } from 'client/components/Common/common-styles';
import { FlyOutContainer } from 'client/components/Common/flyout-styled';

export const ZoomControls = styled.div.attrs((props) => ({
  style: props.hidden ? { opacity: 0 } : {},
}))`
  position: absolute;
  bottom: 8px;
  right: 16px;
  user-select: none;
  display: flex;
  flex-direction: column;

  @media (max-height: 640px) {
    flex-direction: row;
  }

  align-items: flex-end;
  gap: 8px;
`;

export const ZoomMenu = styled.div`
  ${FloatingPanel};
  position: relative;
  border-radius: 5px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  @media (max-height: 640px) {
    flex-direction: row;
  }
  align-items: center;

  ${FlyOutContainer} {
    pointer-events: none;
    width: auto;
    left: initial;
    right: calc(100% + 18px);
    bottom: 50%;
    transform: translateY(50%);

    &:after,
    &:before {
      top: 50%;
      right: -17px;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #fff;
      border-width: 9px;
      margin-top: -9px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-left-color: #d2d2d2;
      border-width: 8px;
      margin-top: -8px;
    }

    > span {
      pointer-events: all;
      display: block;
      font-size: 14px;
      padding: 6px 24px;
      cursor: pointer;

      @media (hover) {
        &:hover {
          background: #f4f4f4;
        }
      }

      &.active {
        background: url(/icons/icn_menu_checked.svg) center left 8px no-repeat;
        cursor: default;
      }
    }
  }
`;

export const ZoomMenuButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  pointer-events: all;
  background-repeat: no-repeat;
  background-position: center center;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  @media (hover) {
    &:hover,
    &.active {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  -webkit-tap-highlight-color: #e6e6e6;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;

export const ZoomOut = styled(ZoomMenuButton)`
  background-image: url(/icons/icn_zoom_minus.svg);
`;

export const ZoomIn = styled(ZoomMenuButton)`
  background-image: url(/icons/icn_zoom_plus.svg);
`;

export const PanTool = styled(ZoomMenuButton)`
  background-image: url(/icons/icn_zoom_hand.svg);
`;

export const ZoomLevel = styled(ZoomMenuButton)`
  height: 24px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
