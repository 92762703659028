import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactTooltip from 'react-tooltip';
import slugid from 'slugid';
import ZCanvas from '@flatfrog/ffbec';
import ZImage from '@flatfrog/ffbec/js/zimage';
import { getNbrColorsForString } from '@flatfrog/ffbec/js/zutil';

import * as analytics from 'client/common/analytics';
import { MUTATION_CREATE_ITEM, MUTATION_DELETE_ITEM, MUTATION_UPDATE_ITEM, QUERY_ITEM } from 'client/common/graphql';
import { trashPaper } from 'client/common/helpers/ZCanvasHelpers';
import ComboButton from 'client/components/Common/combo-button';
import { TextAlignments } from 'client/components/Common/text-alignments';
import { packTextBlockMetadata } from 'client/components/Common/types';
import {
  BottomMenu,
  DeleteButton,
  EditLinkIcon,
  Editor,
  Header,
  LinkContainer,
  LinkTextField,
  StyleList,
  TopMenu,
} from 'client/components/StickyNoteEditor/styled';
import TextBlock, { TEXT_BLOCK_DEFAULT_FONT_SIZE } from 'client/components/TextBlock';
import { BackgroundColors, ForegroundColors, OutlineColors, Styles } from 'client/components/TextBlock/colors';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { TextBlockToEdit } from 'client/state/papers/currentTextBlockEdit';
import { LastTextBlockProps } from 'client/state/papers/lastTextBlockProps';
import { Item } from 'client/state/papers/localItems';
import { Session } from 'client/state/session/session';

import { AlignButton, AlignGroup, Container, StyleButton, TextArea } from './styled';

const MAX_RENDER_HEIGHT = 180.0;
const MAX_RENDER_WIDTH = 540.0;
const PLACEHOLDER = 'Add text';

const TextBlockEditorContainer = () => {
  const { currentTextBlock, lastTextBlockProps, localItems, session } = useSelector((state) => ({
    currentTextBlock: state.currentTextBlockEdit,
    lastTextBlockProps: state.lastTextBlockProps,
    localItems: state.localItems,
    session: state.session,
  }));

  return currentTextBlock ? (
    <TextBlockEditor
      currentTextBlock={currentTextBlock}
      lastTextBlockProps={lastTextBlockProps}
      localItems={localItems}
      session={session}
    />
  ) : null;
};

interface Props {
  currentTextBlock: TextBlockToEdit;
  lastTextBlockProps: LastTextBlockProps;
  localItems: Item[];
  session: Session;
}

const TextBlockEditor: React.FC<Props> = ({ currentTextBlock, lastTextBlockProps, localItems, session }) => {
  const {
    addLocalItem,
    currentTextBlockEdit,
    deleteItemCanvas,
    deleteLocalItem,
    setShowItemStack,
    setTextBlockLastProps,
    updateLocalItem,
    prepareAndPublishTextBlock,
    updateItemOnBoard,
  } = useActions();

  const [activeColor, setActiveColor] = useState('');
  const [activeOutlineColor, setActiveOutlineColor] = useState('');
  const [activeTextColor, setActiveTextColor] = useState('');
  const [currentContent, setCurrentContent] = useState('');
  const [currentLink, setCurrentLink] = useState('');
  const [textSize, setTextSize] = useState(TEXT_BLOCK_DEFAULT_FONT_SIZE);
  const [textAlign, setTextAlign] = useState(TextAlignments.Left);
  const [isDraft, setIsDraft] = useState(false);
  const [fontSize, setFontSize] = useState(TEXT_BLOCK_DEFAULT_FONT_SIZE);
  const [textAreaWidth, setTextAreaWidth] = useState(MAX_RENDER_WIDTH);
  const [textAreaHeight, setTextAreaHeight] = useState(MAX_RENDER_HEIGHT);
  const [textAreaRadius, setTextAreaRadius] = useState(25);
  const [paddingLeftRight, setPaddingLeftRight] = useState(0);
  const [paddingTopBottom, setPaddingTopBottom] = useState(0);
  const [marginLeftRight, setMarginLeftRight] = useState(0);
  const [marginTopBottom, setMarginTopBottom] = useState(0);
  const [borderWidth, setBorderWidth] = useState(6);
  const [paperId, setPaperId] = useState(null);
  const [action, setAction] = useState(null);
  const [buttonActions, setButtonActions] = useState([]);
  const [primaryButtonAction, setPrimaryButtonAction] = useState(0);
  const textBlockId = useRef<TextBlockToEdit>();
  const editor = useRef<HTMLDivElement>();
  const textArea = useRef<HTMLTextAreaElement>();
  const linkTextField = useRef<HTMLInputElement>();
  const auth = useAuth();
  const canvas = useRef<HTMLCanvasElement>();

  const [getItem, { data, previousData }] = useLazyQuery(QUERY_ITEM, { fetchPolicy: 'network-only' });
  const [updateItem] = useMutation(MUTATION_UPDATE_ITEM);
  const [createItem] = useMutation(MUTATION_CREATE_ITEM);
  const [deleteItem] = useMutation(MUTATION_DELETE_ITEM);

  const updateFromItem = (item: Item) => {
    setActiveColor(item.backgroundColor);
    setActiveOutlineColor(item.outlineColor);
    setActiveTextColor(item.textColor);
    setCurrentContent(item.content);
    setCurrentLink(item.link);
    setTextAlign(item.textAlign ? item.textAlign : TextAlignments.Left);
    setTextSize(item.textSize ? item.textSize : TEXT_BLOCK_DEFAULT_FONT_SIZE);
    setIsDraft(false);
    setPaperId(null);
    textArea.current.value = item.content;
    linkTextField.current.value = item.link;
  };

  useHotkeys(
    'esc',
    () => {
      setAction('close');
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], filter: () => !!currentTextBlock }
  );

  useEffect(() => {
    if (data?.item) {
      updateFromItem(data.item);
    } else if (!data?.item && previousData?.item) {
      // Item deleted from different device, close editor
      currentTextBlockEdit(null);
    }
  }, [data]);

  const getFormattedLink = () => {
    if (!currentLink || !currentLink.trim()) {
      return '';
    }
    const httpsLink = currentLink.startsWith('http') ? currentLink : `https://${currentLink}`;
    // Clean up url
    return encodeURI(decodeURI(httpsLink));
  };

  // Create server side item if logged in, otherwise a local item
  const saveItemState = () => {
    const input = {
      textColor: activeTextColor,
      backgroundColor: activeColor,
      outlineColor: activeOutlineColor,
      textSize,
      textAlign,
      content: currentContent,
      link: getFormattedLink(),
      type: 'TextBlock',
    };

    if (!auth.user) {
      addLocalItem({ __typename: 'TextBlock', id: slugid.nice(), ...input });
    } else {
      createItem({ variables: { input } });
    }
  };

  // Update item server side if logged in, otherwise a local item
  const updateItemState = () => {
    if ('id' in textBlockId.current) {
      const id = textBlockId.current.id;

      const input = {
        id,
        textColor: activeTextColor,
        backgroundColor: activeColor,
        outlineColor: activeOutlineColor,
        textSize,
        textAlign,
        content: currentContent,
        link: getFormattedLink(),
      };
      if (auth.user) {
        updateItem({ variables: { input } });
      } else {
        updateLocalItem({ __typename: 'TextBlock', ...input });
      }
    }
  };

  const reset = () => {
    setActiveColor(lastTextBlockProps.backgroundColor || BackgroundColors[0]);
    setActiveOutlineColor(lastTextBlockProps.outlineColor || OutlineColors[0]);
    setActiveTextColor(lastTextBlockProps.textColor || ForegroundColors[0]);
    setTextAlign(lastTextBlockProps.textAlign || TextAlignments.Left);
    setTextSize(lastTextBlockProps.textSize || TEXT_BLOCK_DEFAULT_FONT_SIZE);

    setIsDraft(true);
    setPaperId(null);
    setCurrentContent('');
    setCurrentLink('');
    textArea.current.value = '';
    linkTextField.current.value = '';
  };

  // Handle change of text block
  useEffect(() => {
    // Opening the editor
    if (textBlockId.current === null && currentTextBlock !== null) {
      editor.current.classList.add('active');
      setTimeout(() => textArea.current.focus(), 0);
    }
    // Opening a text block for editing
    if (textBlockId.current !== currentTextBlock && currentTextBlock !== null) {
      if ('paperId' in currentTextBlock) {
        // Editing a paper on the canvas
        setActiveColor(currentTextBlock.backgroundColor);
        setActiveOutlineColor(currentTextBlock.outlineColor);
        setActiveTextColor(currentTextBlock.textColor);
        setCurrentContent(currentTextBlock.content);
        setCurrentLink(currentTextBlock.link);
        setTextAlign(currentTextBlock.textAlign || TextAlignments.Left);
        setTextSize(currentTextBlock.textSize || TEXT_BLOCK_DEFAULT_FONT_SIZE);
        setPaperId(currentTextBlock.paperId);
        setIsDraft(false);
        textArea.current.value = currentTextBlock.content;
        linkTextField.current.value = currentTextBlock.link || '';
      } else if ('id' in currentTextBlock) {
        // Editing a My Items paper
        if (auth.user) {
          getItem({ variables: { id: currentTextBlock.id } });
        } else {
          const item = localItems.find((i) => i.id === currentTextBlock.id);
          updateFromItem(item);
        }
      } else {
        // Creating a new
        reset();
      }
    }
    // Closing the editor
    if (currentTextBlock === null) {
      editor.current.classList.remove('active');
      textArea.current.blur();
      linkTextField.current.blur();

      setTextBlockLastProps({
        textColor: activeTextColor,
        textAlign,
        textSize,
        backgroundColor: activeColor,
        outlineColor: activeOutlineColor,
      });
    }
    // Remember the current text block
    textBlockId.current = currentTextBlock;
  }, [currentTextBlock]);

  useEffect(() => {
    if (!action) {
      return;
    }

    // Link analytics
    if (action === 'save' || action === 'update' || action === 'add') {
      const link = getFormattedLink();
      if (paperId) {
        let metadata;
        try {
          metadata = JSON.parse(ZCanvas.paper.getMetadata(paperId));
        } catch (err) {
          console.log('paper without metadata');
        }
        if (metadata && metadata.Link) {
          if (metadata.Link && !link) {
            analytics.removeLinkFromItem();
          } else if (!metadata.Link && link) {
            analytics.addLinkToItem();
          } else if (metadata.Link !== link) {
            analytics.editItemLink();
          }
        } else if (link) {
          analytics.addLinkToItem();
        }
      } else if (link) {
        analytics.createItemWithLink();
      } else {
        analytics.createItemWithoutLink();
      }
    }

    if (action === 'save') {
      // If this is a paper item, i.e. it corresponds to a paper on the board,
      // it should be saved as a copy rather than saved itself
      if (paperId) {
        // Do the tilt animation (open the item stack and send the current paper there)
        // Halt the tilt animation before the new item appears
        setShowItemStack(true);
        editor.current.classList.add('tilt');
        setTimeout(() => {
          if (editor?.current) {
            editor.current.classList.remove('tilt');
          }
        }, 300);
        // Copy the item to server
        saveItemState();
        currentTextBlockEdit(null);
      } else if (isDraft) {
        setIsDraft(false); // hmm
        // Do the tilt animation (open the item stack and send the current paper there)
        // Reset the tilt animation after completion
        setShowItemStack(true);
        editor.current.classList.add('tilt');
        setTimeout(() => {
          if (editor?.current) {
            editor.current.classList.remove('tilt');
          }
        }, 600);
        saveItemState();
        setTextBlockLastProps({
          textColor: activeTextColor,
          textAlign,
          textSize,
          backgroundColor: activeColor,
          outlineColor: activeOutlineColor,
        });
        // When we save a draft item, we assume "bulk mode", i.e. we immediately create a new item
        currentTextBlockEdit({ new: true, saveExpected: true });
        textArea.current.focus();

        analytics.saveTextBlock();
      } else {
        // Save the text block
        updateItemState();
        // Close the editor
        currentTextBlockEdit(null);
      }
    } else if (action === 'update') {
      const metadata = packTextBlockMetadata({
        content: currentContent,
        textColor: activeTextColor,
        textSize,
        textAlign,
        color: activeColor,
        outlineColor: activeOutlineColor,
        link: getFormattedLink(),
      });
      const imageArrayBuffer = ZImage.canvasToPngBlob(canvas.current, getNbrColorsForString(currentContent));
      updateItemOnBoard({
        file: imageArrayBuffer,
        options: {
          color: activeColor,
          itemId: 'id' in textBlockId.current ? textBlockId.current.id : undefined,
          paperId,
          metadata,
          type: 'TextBlock',
        },
      });
      currentTextBlockEdit(null);
    } else if (action === 'add') {
      setIsDraft(false);
      editor.current.classList.add('zoom');
      setTimeout(() => {
        if (editor?.current) {
          editor.current.classList.remove('zoom');
        }
      }, 250);

      if (session) {
        const metadata = packTextBlockMetadata({
          content: currentContent,
          textColor: activeTextColor,
          textSize,
          textAlign,
          color: activeColor,
          outlineColor: activeOutlineColor,
          link: getFormattedLink(),
        });
        const imageArrayBuffer = ZImage.canvasToPngBlob(canvas.current, getNbrColorsForString(currentContent));
        prepareAndPublishTextBlock({
          file: imageArrayBuffer,
          options: {
            itemId: 'id' in textBlockId.current ? textBlockId.current.id : undefined,
            metadata,
            selectPaper: true,
          },
        });
      }
      if ('id' in textBlockId.current) {
        if (auth.user) {
          deleteItem({
            variables: {
              input: {
                id: textBlockId.current.id,
              },
            },
          });
        } else {
          deleteLocalItem(textBlockId.current.id);
        }
        deleteItemCanvas(textBlockId.current.id);
      }

      currentTextBlockEdit(null);
      setShowItemStack(false);

      analytics.addTextBlock();
    } else if (action === 'delete') {
      if (paperId) {
        // This item has an associated paper, that should be trashed (the item will be deleted as a result)
        trashPaper({ paperId, removePaper: true });
        ReactTooltip.hide();
      } else if ('id' in textBlockId.current) {
        if (auth.user) {
          deleteItem({
            variables: {
              input: {
                id: textBlockId.current.id,
              },
            },
          });
        } else {
          deleteLocalItem(textBlockId.current.id);
        }
        deleteItemCanvas(textBlockId.current.id);
      }
      currentTextBlockEdit(null);

      analytics.deleteFromEditor();
    } else if (action === 'close') {
      currentTextBlockEdit(null);
    }
    setAction(null);
  }, [
    action,
    paperId,
    currentContent,
    currentLink,
    activeColor,
    activeOutlineColor,
    activeTextColor,
    textSize,
    textAlign,
    session,
  ]);

  useEffect(() => {
    const buttonActionsList = [
      {
        label: isDraft ? 'Save for later' : paperId ? 'Copy to My Items' : 'Done',
        action: () => setAction('save'),
      },
    ];
    if (session?.id) {
      buttonActionsList.push({
        label: paperId ? 'Done' : 'Add to Board',
        action: paperId ? () => setAction('update') : () => setAction('add'),
      });
    }
    setPrimaryButtonAction('saveExpected' in currentTextBlock ? 0 : buttonActionsList.length - 1);
    setButtonActions(buttonActionsList);
  }, [currentTextBlock, session, paperId, isDraft]);

  useLayoutEffect(() => {
    if (document.activeElement !== linkTextField.current) {
      textArea.current.focus();
    }
  }, []);

  const textBlockEditorView = (
    <>
      <TopMenu>
        <AlignGroup>
          <AlignButton
            className={textAlign === TextAlignments.Left ? 'active l' : 'l'}
            onClick={() => {
              setTextAlign(TextAlignments.Left);
              textArea.current.focus();
            }}
          />
          <AlignButton
            className={textAlign === TextAlignments.Center ? 'active c' : 'c'}
            onClick={() => {
              setTextAlign(TextAlignments.Center);
              textArea.current.focus();
            }}
          />
          <AlignButton
            className={textAlign === TextAlignments.Right ? 'active r' : 'r'}
            onClick={() => {
              setTextAlign(TextAlignments.Right);
              textArea.current.focus();
            }}
          />
        </AlignGroup>
        <StyleList>
          {Styles.map((style) => (
            <StyleButton
              key={`${ForegroundColors[style.textColor]}-${BackgroundColors[style.color]}-${
                OutlineColors[style.outlineColor]
              }`}
              textColor={ForegroundColors[style.textColor]}
              color={BackgroundColors[style.color]}
              outlineColor={OutlineColors[style.outlineColor]}
              active={
                ForegroundColors[style.textColor] === activeTextColor &&
                BackgroundColors[style.color] === activeColor &&
                OutlineColors[style.outlineColor] === activeOutlineColor
              }
              onClick={() => {
                setActiveTextColor(ForegroundColors[style.textColor]);
                setActiveColor(BackgroundColors[style.color]);
                setActiveOutlineColor(OutlineColors[style.outlineColor]);
                textArea.current.focus();
              }}
            />
          ))}
        </StyleList>
      </TopMenu>
      <LinkContainer>
        <EditLinkIcon />
        <LinkTextField
          ref={(r) => {
            linkTextField.current = r;
          }}
          placeholder="Add a link"
          onChange={() => {
            setCurrentLink(linkTextField.current.value);
          }}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              (e.getModifierState('Control') || e.getModifierState('Meta')) &&
              buttonActions.length > 0
            ) {
              // Make the default action on Ctrl/Cmd+Enter rather than making a new line
              e.preventDefault();
              buttonActions[primaryButtonAction].action();
            } else if (e.key === 'Tab' || e.key === 'Enter') {
              e.preventDefault();
              textArea.current.focus();
            }
          }}
        ></LinkTextField>
      </LinkContainer>
      <TextArea
        style={{
          background: activeColor,
          fontSize,
          color: activeTextColor,
          textAlign,
          borderColor: activeOutlineColor,
          borderRadius: `${textAreaRadius}px`,
          width: `${textAreaWidth}px`,
          height: `${textAreaHeight}px`,
          padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
          margin: `${marginTopBottom}px ${marginLeftRight}px`,
          borderWidth: `${borderWidth}px`,
        }}
        ref={(r) => {
          textArea.current = r;
        }}
        onChange={() => {
          setCurrentContent(textArea.current.value);
        }}
        onKeyDown={(e) => {
          if (
            e.key === 'Enter' &&
            (e.getModifierState('Control') || e.getModifierState('Meta')) &&
            buttonActions.length > 0
          ) {
            // Make the default action on Ctrl/Cmd+Enter rather than making a new line
            e.preventDefault();
            buttonActions[primaryButtonAction].action();
          }
        }}
        placeholder={PLACEHOLDER}
        placeholderColor={`${activeTextColor}70`}
        tabIndex={-1}
      />
      <div style={{ display: 'none' }}>
        <TextBlock
          canvasRef={canvas}
          color={activeColor}
          outlineColor={activeOutlineColor}
          content={currentContent.length > 0 ? currentContent : PLACEHOLDER}
          link={getFormattedLink()}
          textColor={activeTextColor}
          textSize={textSize}
          textAlign={textAlign}
          width={MAX_RENDER_WIDTH}
          height={MAX_RENDER_HEIGHT}
          preview
          onAdjustCanvasSize={(width, height, paddingX, paddingY, marginX, marginY, strokeWidth, fontScale) => {
            const horzScale = MAX_RENDER_WIDTH / (width + 2 * marginX);
            const vertScale = MAX_RENDER_HEIGHT / (height + 2 * marginY);
            const renderScale = Math.min(horzScale, vertScale);
            setFontSize(renderScale * fontScale * textSize);
            setTextAreaWidth(Math.ceil(renderScale * width));
            setTextAreaHeight(Math.ceil(renderScale * height));
            setTextAreaRadius(Math.ceil(renderScale * 25));
            setPaddingLeftRight(renderScale * paddingX);
            setPaddingTopBottom(renderScale * paddingY);
            setMarginLeftRight(renderScale * marginX);
            setMarginTopBottom(renderScale * marginY);
            setBorderWidth(renderScale * strokeWidth);
          }}
        />
      </div>
      <BottomMenu>
        {!isDraft ? (
          <DeleteButton data-place="top" data-tip="Delete" data-effect="solid" onClick={() => setAction('delete')} />
        ) : null}
        {buttonActions.map((buttonAction, index) => (
          <ComboButton
            key={buttonAction.label}
            actions={[buttonAction]}
            open={false}
            style={{ marginLeft: '16px' }}
            gray={index !== primaryButtonAction}
          />
        ))}
      </BottomMenu>
    </>
  );

  return (
    <>
      <Editor
        className="active"
        ref={(r) => {
          editor.current = r;
        }}
      >
        <button className="close" onClick={() => setAction('close')} />
        <Header>{isDraft ? 'Add' : 'Edit'} Text</Header>
        <Container className="active">{textBlockEditorView}</Container>
      </Editor>
    </>
  );
};

export default TextBlockEditorContainer;
