import { createListenerMiddleware } from '@reduxjs/toolkit';

import { getFirebaseAuth } from 'client/common/firebase';
import {
  connectRoomSocket,
  joinTenant,
  Session,
  sessionEnd,
  signout,
  tenantJoined,
  tryConnectNew,
  updatePin,
} from 'client/state/actions';
import { AppDispatch, RootState } from 'client/store';

let ws: WebSocket;

const getIdToken = async () => {
  const user = getFirebaseAuth().currentUser;
  try {
    return user ? await user.getIdToken(true) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getLinkIdFromLink = (link: string) => {
  const parts = link.split('/');
  return parts[parts.length - 1];
};

const open = (dispatch: AppDispatch, session: Session, data: { link: string }) => {
  if (data.link) {
    const linkId = getLinkIdFromLink(data.link);

    // Kill any old session. Will also trigger the session ended dialog
    // w. custom msg
    const sessionExists = !!session.id;
    if (sessionExists) {
      dispatch(sessionEnd({ errorMessage: 'Opening board...' }));
    }

    // if session already exists, piggyback on reconnect behavior to make sessionsocket
    // reconnect the socket
    dispatch(tryConnectNew({ fileInfo: { linkId, link: true }, reconnecting: sessionExists }));
  }
};

type MessageData =
  | { type: 'open'; link: string }
  | { type: 'pin'; pin: { code: string; expiresAt: number } }
  | { type: 'joinTenant'; token: string; tenant: string; requestId: string }
  | { type: 'signout' };

const handleMessage = async (dispatch: AppDispatch, session: Session, data: MessageData) => {
  switch (data.type) {
    case 'open':
      open(dispatch, session, data);
      break;
    case 'pin':
      dispatch(updatePin(data.pin.code));
      break;
    case 'joinTenant':
      dispatch(joinTenant({ token: data.token, tenant: data.tenant, requestId: data.requestId }));
      break;
    case 'signout':
      dispatch(signout());

      window.location.replace('/room');
      break;
    default:
      console.log('unknown msg type', data);
      break;
  }
};

const connect = async (dispatch: AppDispatch, getState: () => RootState) => {
  console.log('connect roomsocket');
  if (ws) {
    return;
  }

  const { collaborationServerUrl } = getState();
  const wsUrl =
    collaborationServerUrl.toLowerCase().indexOf('https') === 0
      ? `wss${collaborationServerUrl.substring('https'.length)}`
      : `ws${collaborationServerUrl.substring('http'.length)}`;
  const token = await getIdToken();

  ws = new WebSocket(`${wsUrl}/roomconnection?token=${token}`);

  ws.onopen = () => {
    console.log('onopen');
  };

  ws.onerror = (e) => {
    console.log('onerror', e);
  };

  ws.onclose = (e) => {
    console.log('onclose', e);
    ws = null;

    setTimeout(() => {
      connect(dispatch, getState);
    }, 5000);
  };

  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);

    handleMessage(dispatch, getState().session, data);
  };
};

const middleware = createListenerMiddleware<RootState>();

middleware.startListening({
  actionCreator: connectRoomSocket,
  effect: (action, api) => {
    connect(api.dispatch as AppDispatch, api.getState);
  },
});

middleware.startListening({
  actionCreator: tenantJoined,
  effect: (action) => {
    ws.send(JSON.stringify({ type: 'tenantJoined', requestId: action.payload.requestId }));
  },
});

export default middleware.middleware;
