/* eslint-disable no-bitwise */
import React, { CSSProperties, MutableRefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isMacOs, isSafari } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import ZCanvas from '@flatfrog/ffbec';
import { ZPaperType } from '@flatfrog/ffbec/js/zwasm';

import * as analytics from 'client/common/analytics';
import { trashPapers, trashSelectedPapers } from 'client/common/helpers/ZCanvasHelpers';
import StickerTray from 'client/components/StickerTray';
import { BackgroundColors, findBackgroundColorByEnum } from 'client/components/StickyNote/colors';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import { copyPapers, cutPapers } from 'client/middleware/items';

import * as Styled from './itemmenu-styled';

interface Props {
  element: MutableRefObject<HTMLElement>;
  ready: boolean;
  paperIds: number[];
  isSelectionBased: boolean;
  hideMenu: () => void;
  setEditPaperLink: (id: number) => void;
}

const PaperMenu: React.FC<Props> = ({ element, ready, paperIds, isSelectionBased, hideMenu, setEditPaperLink }) => {
  if (!ready) {
    return null;
  }

  const { isMsTeamsApp, room, selectedPapers } = useSelector((state) => ({
    isMsTeamsApp: state.isMsTeamsApp,
    room: state.room,
    selectedPapers: state.selectedPapers,
  }));

  const { editPaper, prepareAndPublishSticker, setStickyNoteLastProps, duplicatePapers } = useActions();

  const menu = useRef<HTMLDivElement>();

  const commandPrefix = isMacOs ? '⌘' : 'Ctrl + ';
  const commandPrefixAlt = isMacOs ? '⌥' : 'Alt + ';

  const [layerable, setLayerable] = useState(null);
  const [editable, setEditable] = useState(null);
  const [colorable, setColorable] = useState<{ paperIds: number[]; color: string }>(null);
  const [pauseVoting, setPauseVoting] = useState(false);
  const [colorOpen, setColorOpen] = useState(false);
  const [stickerable, setStickerable] = useState<{ paperIds: number[] }>(null);
  const [stickerOpen, setStickerOpen] = useState<boolean>(false);
  const [upvotable, setUpvotable] = useState(null);
  const [downvotable, setDownvotable] = useState(null);
  const [linkable, setLinkable] = useState(null);
  const [canStackAndGrid, setCanStackAndGrid] = useState(null);
  const downvotableRef = useRef(null);
  useEffect(() => {
    downvotableRef.current = downvotable;
  }, [downvotable]);
  const [setAsBackgroundable, setSetAsBackgroundable] = useState(null);
  const [singlePaperOrStack, setSinglePaperOrStack] = useState(null);
  const [countable, setCountable] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [itemType, setItemType] = useState(null);

  const getStringForPaperType = (type: number) => {
    switch (type) {
      case ZPaperType.STICKY_NOTE:
        return 'Sticky Note';
      case ZPaperType.TEXTBOX:
        return 'Text';
      case ZPaperType.IMAGE:
        return 'Image';
      case ZPaperType.STICKER:
        return 'Sticker';
      default:
        return 'Item';
    }
  };

  useEffect(() => {
    try {
      if (!ZCanvas.isInitialized || ZCanvas.isPreloading) {
        return;
      }

      const info = ZCanvas.paper.getPaperMenuInfo(isSelectionBased ? undefined : paperIds);

      if (info.validPaperIds.length === 0) {
        console.warn('Closing paper menu as no valid paper IDs are selected.');
        hideMenu();
        return;
      }

      setCanStackAndGrid(info.canStackAndGrid);
      setLayerable({ paperIds: info.validPaperIds, layer: info.firstPaperLayerIndex });

      const firstStackBasePaperId = info.validPaperIds[info.firstStackBasePaperIndex];
      const firstStackBasePaperType = info.paperTypes.find((value, index) => info.paperIsStackBase[index]);

      setEditable(
        info.canEdit ? { paperId: firstStackBasePaperId, type: getStringForPaperType(firstStackBasePaperType) } : null
      );

      setColorable(
        info.canChangeColour
          ? {
              paperIds: info.validPaperIds.filter(
                (paperId, index) => info.paperTypes[index] === ZPaperType.STICKY_NOTE
              ),
              color: findBackgroundColorByEnum(info.paperColour).color,
            }
          : null
      );
      setLinkable(info.canEditLink ? { paperId: firstStackBasePaperId, link: info.firstStackBasePaperLink } : null);
      setStickerable(
        info.canAddSticker
          ? { paperIds: info.validPaperIds.filter((paperId, index) => info.paperIsStackBase[index]) }
          : null
      );
      setUpvotable(info.canVote ? { paperId: firstStackBasePaperId } : null);
      setDownvotable(info.canVote ? { paperId: firstStackBasePaperId, votes: info.firstStackBasePaperVotes } : null);
      setSetAsBackgroundable(info.canSetAsBackground ? { paperId: firstStackBasePaperId } : null);
      setSinglePaperOrStack(info.nbrStackBasePapers === 1 ? firstStackBasePaperType : null);
      setCountable(info.nbrStackBasePapers > 1 ? info.nbrStackBasePapers : null);
      setMenuPosition(info.position);

      // Itemtype
      if (info.nbrStackBasePapers === 1) {
        setItemType(getStringForPaperType(firstStackBasePaperType));
      } else {
        setItemType('Items');
      }
    } catch (e) {
      console.error(`PaperMenu: Exception occurred while updating: ${e?.message}\n${e?.stack}`);
    }
  }, [paperIds]);

  // Expanded
  const [expanded, setExpanded] = useState<boolean>(false);
  const expandedRef = useRef(null);
  useEffect(() => {
    expandedRef.current = expanded;
  }, [expanded]);

  // Translate into view
  const [translateIntoView, setTranslateIntoView] = useState({ x: 0, y: 0 });
  useEffect(() => {
    if (menu.current) {
      const boundingRect = menu.current.getBoundingClientRect();
      let right = menuPosition.x;
      if (expanded) {
        right += 220;
      } else if (colorOpen) {
        right += 170;
      } else {
        right += 40;
      }
      const bottom = menuPosition.y + boundingRect.height;
      const rightEdge = window.innerWidth - 15;
      const bottomEdge = window.innerHeight - 10;
      const x = right > rightEdge ? rightEdge - right : 0;
      const y = bottom > bottomEdge ? bottomEdge - bottom : 0;
      setTranslateIntoView({ x, y });
    } else {
      setTranslateIntoView({ x: 0, y: 0 });
    }
  }, [expanded, menuPosition, colorOpen]);

  // Transform position
  const canvas = element.current;
  const canvasRect = canvas.getBoundingClientRect();
  const transformedPosition = {
    x: canvasRect.x + canvasRect.width * (menuPosition.x / canvasRect.width),
    y: canvasRect.y + canvasRect.height * (menuPosition.y / canvasRect.height),
  };

  // Votes changed
  const handleVotesChangedEvent = () => {
    if (downvotableRef.current) {
      const { paperId } = downvotableRef.current;
      setDownvotable({ paperId, votes: ZCanvas.paper.getVotes(paperId) });
      setPauseVoting(false);
    }
  };
  useEffect(() => {
    ZCanvas.addEventListener('PAPER_VOTES_CHANGED', handleVotesChangedEvent);
  }, []);

  // Timeout for touch menus
  if (!isSelectionBased) {
    useEffect(() => {
      if (!expanded && !colorOpen && !stickerOpen) {
        const timer = setTimeout(() => {
          ReactTooltip.hide();
          hideMenu();
        }, 3000);
        return () => {
          clearTimeout(timer);
        };
      }
    }, [expanded, colorOpen, stickerOpen]);
  }

  /* UI Elements */

  let editMenuItem = null;
  if (editable) {
    editMenuItem = (
      <Styled.MenuItem
        className={expanded ? 'expanded' : ''}
        data-place="top"
        data-tip={!expanded ? `Edit ${editable.type}` : ''}
        data-effect="solid"
        onClick={() => {
          ReactTooltip.hide();
          editPaper(editable.paperId);
        }}
      >
        <Styled.EditIcon />
        {expanded ? 'Edit' : ''}
        {expanded && isSelectionBased && !room && <Styled.Shortcut>Enter</Styled.Shortcut>}
      </Styled.MenuItem>
    );
  }

  let voteUpMenuItem = null;
  if (upvotable) {
    voteUpMenuItem = (
      <Styled.MenuItem
        className={classNames({ expanded, disabled: pauseVoting })}
        data-place="top"
        data-tip={!expanded ? `Vote up ${itemType}` : ''}
        data-effect="solid"
        onClick={() => {
          ReactTooltip.hide();
          ZCanvas.paper.vote(upvotable.paperId, 1);
          setPauseVoting(true);
        }}
      >
        <Styled.VoteUpIcon />
        {expanded ? 'Upvote' : ''}
      </Styled.MenuItem>
    );
  }

  let voteDownMenuItem = null;
  if (downvotable) {
    voteDownMenuItem = (
      <Styled.MenuItem
        className={classNames({ expanded, disabled: pauseVoting || downvotable.votes <= 0 })}
        data-place="top"
        data-tip={!expanded ? `Vote down ${itemType}` : ''}
        data-effect="solid"
        onClick={() => {
          if (!pauseVoting) {
            ReactTooltip.hide();
            const previousVotes = ZCanvas.paper.getVotes(downvotable.paperId);
            if (previousVotes > 0) {
              ZCanvas.paper.vote(downvotable.paperId, -1);
              setPauseVoting(true);
            }
          }
        }}
      >
        <Styled.VoteDownIcon />
        {expanded ? 'Downvote' : ''}
      </Styled.MenuItem>
    );
  }

  const updatePaperColor = (paperId: number, color: string, FFZinkEnumMapping: number) => {
    const md = ZCanvas.paper.getMetadata(paperId);
    let metadata;
    try {
      metadata = JSON.parse(md);
    } catch (err) {
      console.log('paper without metadata');
    }
    if (metadata) {
      metadata.Color = color;
      ZCanvas.beginCommandGroup();
      ZCanvas.paper.setMetadata(paperId, JSON.stringify(metadata));
      ZCanvas.paper.setColour(paperId, FFZinkEnumMapping);
      ZCanvas.endCommandGroup();
    } else {
      ZCanvas.paper.setColour(paperId, FFZinkEnumMapping);
    }
    setStickyNoteLastProps({ backgroundColor: color });
  };

  let colorMenuItem = null;
  if (colorable) {
    const colorItems = BackgroundColors.map((c) => (
      <Styled.ColorItem
        key={`color-${c.color}`}
        style={{ '--color': c.color } as CSSProperties}
        onClick={() => {
          if ('paperIds' in colorable) {
            ZCanvas.beginCommandGroup();
            colorable.paperIds.forEach((id) => {
              updatePaperColor(id, c.color, c.FFZinkEnumMapping);
            });
            ZCanvas.endCommandGroup();
            setColorable({ paperIds: colorable.paperIds, color: c.color });
          }
        }}
      />
    ));
    colorMenuItem = (
      <Styled.MenuItem
        data-place="top"
        data-tip={!expanded ? 'Set Color' : ''}
        data-effect="solid"
        data-tip-disable={colorOpen}
        className={expanded && 'expanded'}
        onClick={() => {
          ReactTooltip.hide();
          setColorOpen(!colorOpen);
          setStickerOpen(false);
        }}
      >
        <Styled.ColorDropDownItem
          style={{ '--color': colorable.color } as CSSProperties}
          className={colorOpen && 'open'}
        >
          <Styled.DropDownMenuPositioner className="down">
            <Styled.DropDownMenu>{colorItems}</Styled.DropDownMenu>
          </Styled.DropDownMenuPositioner>
        </Styled.ColorDropDownItem>
        {expanded ? 'Change Color' : ''}
      </Styled.MenuItem>
    );
  }

  const getOpenStickerPosition = (paperId: number) => {
    const paperInfo = ZCanvas.paper.getInfo(paperId);
    const { corners } = paperInfo;
    const topleft = corners.reduce(
      (acc, curr) => ({ x: Math.min(acc.x, curr.x), y: Math.min(acc.y, curr.y) }),
      corners[0]
    );
    const bottomright = corners.reduce(
      (acc, curr) => ({ x: Math.max(acc.x, curr.x), y: Math.max(acc.y, curr.y) }),
      corners[0]
    );

    const dist = (p1: { x: number; y: number }, p2: { x: number; y: number }) =>
      Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

    const diagonal = dist(corners[0], corners[2]);
    const originalDiagonal = paperInfo.prs.scale * Math.sqrt(paperInfo.height ** 2 + paperInfo.width ** 2);
    const viewportScale = diagonal / originalDiagonal;
    const stickerSideLength = 1.2 * 80 * viewportScale;

    const pageId = ZCanvas.page.getCurrentId();
    const ids = ZCanvas.paper.getPaperIds(pageId);
    const stackedPapers = [];
    for (let i = ids.indexOf(paperId) + 1; i < ids.length; i += 1) {
      const stackParent = ZCanvas.paper.getStackedOnPaperId(ids[i]);
      if (stackParent && (stackParent === paperId || ids.includes(stackParent))) {
        stackedPapers.push(ids[i]);
      }
    }

    // Used for checking if a point is in the sticky note
    // https://en.wikipedia.org/wiki/Heron's_formula
    const triangleArea = (point: { x: number; y: number }) => {
      let area = 0;
      for (let i = 0; i < 4; i += 1) {
        const corner1 = corners[i];
        const corner2 = corners[(i + 1) % 4];
        const a = dist(corner1, point);
        const b = dist(corner2, point);
        const c = dist(corner1, corner2);
        const s = 0.5 * (a + b + c);
        area += Math.sqrt(s * (s - a) * (s - b) * (s - c));
      }
      return area;
    };

    const center = corners.reduce((acc, curr) => ({ x: acc.x + 0.25 * curr.x, y: acc.y + 0.25 * curr.y }), {
      x: 0,
      y: 0,
    });
    const area = triangleArea(center);

    for (
      let y = bottomright.y - 0.5 * stickerSideLength;
      y > topleft.y + 0.5 * stickerSideLength;
      y -= stickerSideLength
    ) {
      for (
        let x = topleft.x + 0.5 * stickerSideLength;
        x < bottomright.x - 0.5 * stickerSideLength;
        x += stickerSideLength
      ) {
        // Check if outside the sticky note (might be if it's rotated)
        if (triangleArea({ x, y }) < 1.01 * area) {
          // Check if there is an empty spot
          if (
            stackedPapers.filter((stackedId) => {
              const stackedCorners = ZCanvas.paper.getCorners(stackedId);
              if (!stackedCorners) {
                return false;
              }
              return (
                x > Math.min(...stackedCorners.map((c) => c.x)) &&
                x < Math.max(...stackedCorners.map((c) => c.x)) &&
                y > Math.min(...stackedCorners.map((c) => c.y)) &&
                y < Math.max(...stackedCorners.map((c) => c.y))
              );
            }).length === 0
          ) {
            return { x, y };
          }
        }
      }
    }

    // No empty spot, just place it in a corner
    return corners[0];
  };

  let stickerMenuItem = null;
  if (stickerable) {
    stickerMenuItem = (
      <Styled.MenuItem
        className={expanded ? 'expanded' : ''}
        data-place="top"
        data-tip={!expanded ? 'Add Sticker' : ''}
        data-effect="solid"
        data-tip-disable={stickerOpen}
        onClick={() => {
          ReactTooltip.hide();
          setStickerOpen(stickerOpen ? false : !!selectedPapers);
          setColorOpen(false);
        }}
      >
        <Styled.StickerIcon />
        {expanded ? 'Add Sticker' : ''}
        {stickerOpen && (
          <StickerTray
            stickerTrayOpen={true}
            setStickerTrayOpen={(open) => setStickerOpen(open ? !!selectedPapers : false)}
            defaultStickerSelected={(fileName) => {
              if (stickerable.paperIds.length === 1) {
                const paperId = stickerable.paperIds[0];
                prepareAndPublishSticker({
                  url: fileName,
                  options: {
                    position: getOpenStickerPosition(paperId),
                    addedInBackground: ZCanvas.paper.getPaperLayer(paperId) === 0,
                  },
                });
              } else {
                const multiStickerOptions = stickerable.paperIds.map((paperId) => ({
                  position: getOpenStickerPosition(paperId),
                  layer: ZCanvas.paper.getPaperLayer(paperId),
                }));
                prepareAndPublishSticker({ url: fileName, multiStickerOptions, options: {} });
              }
            }}
            customStickerSelected={(file) => {
              if (stickerable.paperIds.length === 1) {
                const paperId = stickerable.paperIds[0];
                prepareAndPublishSticker({
                  file,
                  options: {
                    position: getOpenStickerPosition(paperId),
                    addedInBackground: ZCanvas.paper.getPaperLayer(paperId) === 0,
                  },
                });
              } else {
                const multiStickerOptions = stickerable.paperIds.map((paperId) => ({
                  position: getOpenStickerPosition(paperId),
                  layer: ZCanvas.paper.getPaperLayer(paperId),
                }));
                prepareAndPublishSticker({ file, multiStickerOptions, options: {} });
              }
            }}
            existingStickerSelected={(imageId) => {
              if (stickerable.paperIds.length === 1) {
                const paperId = stickerable.paperIds[0];
                prepareAndPublishSticker({
                  imageId,
                  options: {
                    position: getOpenStickerPosition(paperId),
                    addedInBackground: ZCanvas.paper.getPaperLayer(paperId) === 0,
                  },
                });
              } else {
                const multiStickerOptions = stickerable.paperIds.map((paperId) => ({
                  position: getOpenStickerPosition(paperId),
                  layer: ZCanvas.paper.getPaperLayer(paperId),
                }));
                prepareAndPublishSticker({ imageId, multiStickerOptions, options: {} });
              }
            }}
            room={room}
            placement="horizontal"
          />
        )}
      </Styled.MenuItem>
    );
  }

  let linkMenuItem = null;
  if (linkable) {
    linkMenuItem = (
      <Styled.MenuItem
        className={expanded ? 'expanded' : ''}
        data-place="top"
        data-tip={!expanded ? `${linkable.link ? 'Edit' : 'Create'} Link` : ''}
        data-effect="solid"
        onClick={() => {
          ReactTooltip.hide();
          setExpanded(null);
          setEditPaperLink(linkable.paperId);
        }}
      >
        <Styled.CreateLinkIcon className={linkable.link ? 'active' : ''} />
        {expanded ? `${linkable.link ? 'Edit' : 'Create'} Link` : ''}
      </Styled.MenuItem>
    );
  }

  let setAsBackgroundMenuItem = null;
  if (setAsBackgroundable) {
    setAsBackgroundMenuItem = (
      <Styled.MenuItem
        className={expanded ? 'expanded' : ''}
        data-place="top"
        data-tip={!expanded ? 'Set as background' : ''}
        data-effect="solid"
        onClick={() => {
          ReactTooltip.hide();
          // TODO: We should probably report replace background if there is a background already (and change tip)
          ZCanvas.paper.setPaperAsBackground(setAsBackgroundable.paperId);
          analytics.setBackground(true);
        }}
      >
        <Styled.SetAsBackgroundIcon />
        {expanded ? 'Set as background' : ''}
      </Styled.MenuItem>
    );
  }

  let layerMenuItem = null;
  if (layerable) {
    const tooltip = `${layerable.layer === 0 ? 'Unlock' : 'Lock'} ${itemType}`;
    const buttontext = layerable.layer === 0 ? 'Unlock' : 'Lock';
    layerMenuItem = (
      <Styled.MenuItem
        className={expanded && 'expanded'}
        data-place="top"
        data-tip={!expanded ? tooltip : ''}
        data-effect="solid"
        onClick={() => {
          ReactTooltip.hide();
          if (layerable.layer === 0) {
            ZCanvas.paper.setPaperLayerMultiple(layerable.paperIds, 1);
            analytics.unlockItems(layerable.paperIds.length);
          } else {
            ZCanvas.paper.setPaperLayerMultiple(layerable.paperIds, 0);
            analytics.lockItems(layerable.paperIds.length);
          }
          ReactTooltip.hide();
          hideMenu();
        }}
      >
        <Styled.ChangeLayerIcon className={layerable.layer === 0 ? 'background' : 'foreground'} />
        {expanded ? buttontext : ''}
      </Styled.MenuItem>
    );
  }

  let countItem = null;
  if (countable) {
    countItem = (
      <Styled.CountItem className={expanded ? 'expanded' : 'collapsed'}>
        <span>{`${countable}${expanded ? ' items' : ''}`}</span>
      </Styled.CountItem>
    );
  }

  const moreOptionsMenuItem = (
    <Styled.MenuItem
      className={expanded ? 'expanded' : ''}
      data-place="top"
      data-tip={!expanded ? 'Open Menu' : ''}
      data-effect="solid"
      onClick={() => {
        ReactTooltip.hide();
        setColorOpen(false);
        setStickerOpen(false);
        setExpanded(expanded ? false : !!selectedPapers);
      }}
    >
      <Styled.MoreOptionsIcon />
      {expanded ? 'Close Menu' : ''}
      {countItem}
    </Styled.MenuItem>
  );

  const deleteMenuItem = (
    <Styled.MenuItem
      className={expanded ? 'expanded' : ''}
      data-place="top"
      data-tip={!expanded ? `Delete ${selectedPapers.length > 1 ? 'Items' : itemType}` : ''}
      data-effect="solid"
      onClick={() => {
        ReactTooltip.hide();
        if (isSelectionBased) {
          trashSelectedPapers();
          analytics.deleteFromMenu(selectedPapers.length);
        } else {
          trashPapers(paperIds);
          analytics.deleteFromMenu(paperIds.length);
        }
      }}
    >
      <Styled.DeleteIcon />
      {expanded ? 'Delete' : ''}
      {expanded && isSelectionBased && !room && <Styled.Shortcut>Backspace</Styled.Shortcut>}
    </Styled.MenuItem>
  );

  const stackAndGridMenuItem = (
    <>
      <Styled.Divider />
      <Styled.MenuItem
        className={expanded ? 'expanded' : ''}
        data-place="top"
        data-tip={!expanded ? `Grid ${selectedPapers.length > 1 ? 'Item' : itemType}s` : ''}
        data-effect="solid"
        onClick={() => {
          ReactTooltip.hide();
          ZCanvas.paper.arrangeSelectedPaperInGrid();
        }}
      >
        <Styled.GridIcon />
        {expanded ? 'Grid Items' : ''}
        {expanded && isSelectionBased && !room && <Styled.Shortcut>{`${commandPrefixAlt}G`}</Styled.Shortcut>}
      </Styled.MenuItem>
      <Styled.MenuItem
        className={expanded ? 'expanded' : ''}
        data-place="top"
        data-tip={!expanded ? `Stack ${selectedPapers.length > 1 ? 'Item' : itemType}s` : ''}
        data-effect="solid"
        onClick={() => {
          ReactTooltip.hide();
          ZCanvas.paper.arrangeSelectedPaperInStack();
        }}
      >
        <Styled.StackIcon />
        {expanded ? 'Stack Items' : ''}
        {expanded && isSelectionBased && !room && <Styled.Shortcut>{`${commandPrefixAlt}X`}</Styled.Shortcut>}
      </Styled.MenuItem>
      {expanded && <Styled.Divider />}
    </>
  );

  const oneItem = countable && !expanded && !colorMenuItem && !stickerMenuItem;

  ReactTooltip.rebuild();

  const platformHasClipboard = () => !window.ffbecandroidjni && !isSafari && !isMsTeamsApp;

  return (
    <Styled.MenuContainer
      ref={menu}
      style={
        {
          left: transformedPosition.x,
          top: transformedPosition.y,
          '--translateIntoViewX': `${translateIntoView.x}px`,
          '--translateIntoViewY': `${translateIntoView.y}px`,
        } as CSSProperties
      }
      className="show"
    >
      <Styled.MenuItemsWrapper
        className={`${expanded ? 'expanded' : ''} ${expanded && !stickerOpen && !colorOpen ? 'hideOverflow' : ''}`}
      >
        {moreOptionsMenuItem}
        {!oneItem && <Styled.Divider />}
        {voteUpMenuItem}
        {voteDownMenuItem}
        {colorMenuItem}
        {setAsBackgroundMenuItem}
        {!room && editMenuItem}
        {stickerMenuItem}
        {!room && canStackAndGrid && stackAndGridMenuItem}
        {!room && (expanded || linkable?.link) && linkMenuItem}
        {(singlePaperOrStack === 'TextBox' || expanded || (layerable && layerable.layer === 0)) && layerMenuItem}
        {expanded && !isSafari && !isMsTeamsApp && <Styled.Divider />}
        {expanded && !isSafari && !isMsTeamsApp && (
          <Styled.MenuItem
            className="expanded"
            onClick={async () => {
              setExpanded(null);
              await duplicatePapers(paperIds);
            }}
          >
            <Styled.DuplicateIcon />
            Duplicate
          </Styled.MenuItem>
        )}
        {expanded && platformHasClipboard() && (
          <Styled.MenuItem
            className="expanded"
            onClick={async () => {
              setExpanded(null);
              await cutPapers(paperIds);
            }}
          >
            <Styled.CutIcon />
            Cut
            {isSelectionBased && !room && <Styled.Shortcut>{`${commandPrefix}X`}</Styled.Shortcut>}
          </Styled.MenuItem>
        )}
        {expanded && platformHasClipboard() && (
          <Styled.MenuItem
            className="expanded"
            onClick={async () => {
              setExpanded(null);
              await copyPapers(paperIds);
            }}
          >
            <Styled.CopyIcon />
            Copy
            {isSelectionBased && !room && <Styled.Shortcut>{`${commandPrefix}C`}</Styled.Shortcut>}
          </Styled.MenuItem>
        )}
        {expanded && <Styled.Divider />}
        {expanded && deleteMenuItem}
      </Styled.MenuItemsWrapper>
    </Styled.MenuContainer>
  );
};

export default PaperMenu;
