import { ApolloClient, createHttpLink, from, gql, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { getFirebaseAuth } from 'client/common/firebase';
import { TextAlignments } from 'client/components/Common/text-alignments';

type Listener = (date: number) => void;

let listener: Listener | undefined;

export const setListener = (l: Listener) => {
  listener = l;
};

export const getAuthToken = async () => {
  const user = getFirebaseAuth().currentUser;
  return user?.getIdToken();
};

export const httpLink = createHttpLink({
  uri: `${window.INITIAL_STATE.collaborationServerUrl}/graphql`,
});

export const wsLink = new WebSocketLink({
  uri: `${window.INITIAL_STATE.collaborationServerUrl}/graphql`.replace('http', 'ws'),
  options: {
    lazy: false,
    connectionParams: async () => {
      const token = await getAuthToken();
      return {
        authToken: token,
      };
    },
    reconnect: true,
    connectionCallback: (e) => {
      if (window.INITIAL_STATE.gcpEnv !== 'production' && window.INITIAL_STATE.gcpEnv !== 'beta') {
        console.log('apollo-ws-link:connectioncallback');
        console.log(e);
      }
      if (!e && listener) {
        listener(Date.now());
      }
    },
  },
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, path } = error;
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
      console.log(error);
    });
  }

  if (networkError) {
    console.log(`[GraphQL network error]: ${networkError}`);
  }
});

export const resetWsClient = (reconnect: boolean) => {
  // WebSocketLink doesnt have an official way of passing new connection params.
  // The function passed when created a WebSocketLink is encapsulated in a promise
  // that is executed once and resolved when needed. This sets the params to a new
  // promise, forcing reexecution.

  // eslint-disable-next-line dot-notation
  wsLink['subscriptionClient'].connectionParams = async () => {
    const token = await getAuthToken();

    return { authToken: token };
  };
  try {
    // eslint-disable-next-line dot-notation
    wsLink['subscriptionClient'].close(!reconnect, false);
  } catch (e) {
    // ignore
  }
};

export const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink)
);

export const client = new ApolloClient({
  link: window.INITIAL_STATE.env === 'development' ? from([errorLink, splitLink]) : splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          items: {
            merge: false,
          },
        },
      },
    },
  }),
});

export const CACHE_ID_FILES = 'files';
export const CACHE_ID_RECENTS = 'recents';
export const CACHE_ID_SHARED_WITH_ME = 'sharedWithMe';
export const CACHE_ID_TEAMS = 'teams';
export const CACHE_ID_ALLOWED = 'allowedToCreateFile';
export const CACHE_ID_ITEMS = 'items';

export const MUTATION_CREATE_FILE = gql<
  {
    createFile: { uploadUri: string; file: { id: string; fileName: string } };
  },
  { fileName: string; empty?: boolean }
>`
  mutation CreateFile($fileName: String, $empty: Boolean) {
    createFile(fileName: $fileName, empty: $empty) {
      uploadUri
      file {
        id
        fileName
      }
    }
  }
`;

export const MUTATION_CREATE_FILE_FROM_SESSION = gql`
  mutation CreateFileFromSession($sessionId: String!, $fileName: String!) {
    createFileFromSession(sessionId: $sessionId, fileName: $fileName) {
      file {
        id
        fileName
      }
    }
  }
`;

export const MUTATION_DELETE_FILE = gql<
  { deleteFile: { success: boolean; fileId: string; error?: string } },
  { id: string }
>`
  mutation DeleteFile($id: String!) {
    deleteFile(id: $id) {
      success
      fileId
      error
    }
  }
`;

export const MUTATION_RENAME_FILE = gql<void, { id: string; fileName: string }>`
  mutation RenameFile($id: String!, $fileName: String!) {
    renameFile(id: $id, fileName: $fileName)
  }
`;

export const MUTATION_COPY_FILE = gql`
  mutation CopyFile($id: String!, $fileName: String!) {
    copyFile(id: $id, fileName: $fileName)
  }
`;

export const MUTATION_DUPLICATE_FILE = gql<void, { id: string }>`
  mutation DuplicateFile($id: String!) {
    duplicateFile(id: $id)
  }
`;

export const MUTATION_REMOVE_LINK = gql<void, { linkId: string }>`
  mutation RemoveLink($linkId: String!) {
    removeFromSharedWithMe(linkId: $linkId)
  }
`;

export const MUTATION_CREATE_BOARD_LINK = gql<
  {
    createFileLink: { id: string; uri: string; file: { id: string } };
  },
  { fileId: string }
>`
  mutation CreateFileLink($fileId: String!) {
    createFileLink(fileId: $fileId) {
      id
      uri
      file {
        id
      }
    }
  }
`;

export const MUTATION_SAVE_BOARD_LINK = gql<void, { linkId: string }>`
  mutation SaveBoardLink($linkId: String!) {
    addToSharedWithMe(linkId: $linkId)
  }
`;

export interface File {
  __typename: 'File';
  id: string;
  dateModified: number;
  fileName: string;
  thumbnailUri: string;
  team?: { name: string; id: string };
  user?: { uid: string; displayName: string; disabled: boolean };
}

export interface FileLink {
  __typename: 'FileLink';
  id: string;
  file: File;
}

export type FileOrFileLink = File | FileLink;

export const isFile = (f: FileOrFileLink): f is File => f.__typename === 'File';
export const isFileLink = (f: FileOrFileLink): f is FileLink => f.__typename === 'FileLink';

export const QUERY_DASHBOARD = gql<
  {
    recents: FileOrFileLink[];
  },
  { limit: number }
>`
  query Dashboard($limit: Int) {
    recents(limit: $limit) {
      ... on File {
        id
        fileName
        thumbnailUri
        team {
          name
          id
        }
        user {
          uid
          displayName
          disabled
        }
      }
      ... on FileLink {
        id
        file {
          team {
            name
            id
          }
          user {
            uid
            displayName
            disabled
          }
          id
          fileName
          thumbnailUri
        }
      }
    }
    sizeSharedWithMe
    sizeFiles
  }
`;

export const QUERY_FILE_LIMITS = gql<
  { allowedToCreateFile: { allowed: boolean; limit?: number }; sizeFiles: number },
  void
>`
  query Limits {
    allowedToCreateFile {
      allowed
      limit
    }
    sizeFiles
  }
`;

export const QUERY_MY_BOARDS = gql<
  {
    files: File[];
    sharedWithMe: (FileLink & { dateAccessed: number })[];
    allFiles: (File | (FileLink & { dateAccessed: number }))[];
    sizeSharedWithMe: number;
    sizeFiles: number;
  },
  { sort: string; input: { sort: string } }
>`
  query MyBoards($sort: ListItemSort, $input: InputFiles) {
    files(input: $input) {
      id
      fileName
      thumbnailUri
      dateModified
    }
    sharedWithMe(sort: $sort) {
      id
      dateAccessed
      file {
        user {
          uid
          displayName
          disabled
        }
        id
        fileName
        thumbnailUri
      }
    }
    allFiles(sort: $sort) {
      ... on File {
        id
        fileName
        thumbnailUri
        dateModified
      }
      ... on FileLink {
        id
        dateAccessed
        file {
          user {
            uid
            displayName
            disabled
          }
          id
          fileName
          thumbnailUri
          dateModified
        }
      }
    }
    sizeSharedWithMe
    sizeFiles
  }
`;

export const QUERY_FILE_INFO = gql<
  {
    file: File & {
      dateCreated: number;
      user: { room: boolean };
      team: { members: { uid: string; role: string; disabled: boolean }[] };
    };
  },
  { fileId: string }
>`
  query File($fileId: String) {
    file(id: $fileId) {
      id
      fileName
      dateModified
      dateCreated
      thumbnailUri
      user {
        displayName
        uid
        room
        disabled
      }
      team {
        name
        members {
          uid
          role
          disabled
        }
      }
    }
  }
`;

export const QUERY_LINK = gql<
  {
    link: {
      id: string;
      file: {
        fileName: string;
        id: string;
        dateModified: number;
        thumbnailUri: string;
        team: {
          id: string;
          name: string;
        };
      };
    };
  },
  { linkId: string }
>`
  query Link($linkId: String) {
    link(linkId: $linkId) {
      id
      file {
        fileName
        id
        dateModified
        thumbnailUri
        team {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_TEMPLATES = gql<
  {
    templates: {
      id: string;
      name: string;
      templates: {
        id: string;
        name: string;
        thumbnail: string;
      }[];
    }[];
  },
  { input: { role: string } }
>`
  query Templates($input: InputTemplates) {
    templates(input: $input) {
      id
      name
      templates {
        id
        name
        thumbnail
      }
    }
  }
`;

export const FRAGMENT_STICKY_NOTE = gql`
  fragment StickyNoteParts on StickyNote {
    id
    content
    backgroundColor
    textSize
    textAlign
    signature
    link
  }
`;

export const FRAGMENT_TEXT_BLOCK = gql`
  fragment TextBlockParts on TextBlock {
    id
    content
    textColor
    backgroundColor
    outlineColor
    textSize
    textAlign
    link
  }
`;

export const QUERY_MY_ITEMS = gql<
  {
    items: {
      __typename: 'TextBlock' | 'StickyNote';
      id: string;
      content: string;
      textColor: string;
      backgroundColor: string;
      outlineColor: string;
      textSize: number;
      textAlign: TextAlignments;
      signature: string;
      link: string;
    }[];
  },
  void
>`
  query MyItems {
    items {
      ... on StickyNote {
        ...StickyNoteParts
      }
      ... on TextBlock {
        ...TextBlockParts
      }
    }
  }
  ${FRAGMENT_STICKY_NOTE}
  ${FRAGMENT_TEXT_BLOCK}
`;

export const QUERY_ITEM = gql<
  {
    item: {
      __typename: 'TextBlock' | 'StickyNote';
      id: string;
      content: string;
      textColor: string;
      backgroundColor: string;
      outlineColor: string;
      textSize: number;
      textAlign: TextAlignments;
      signature: string;
      link: string;
    };
  },
  { id: string }
>`
  query Item($id: String) {
    item(id: $id) {
      ... on StickyNote {
        ...StickyNoteParts
      }
      ... on TextBlock {
        ...TextBlockParts
      }
    }
  }
  ${FRAGMENT_STICKY_NOTE}
  ${FRAGMENT_TEXT_BLOCK}
`;

export const MUTATION_DELETE_ITEM = gql<{ deleteItem: { success: boolean } }, { input: { id: string } }>`
  mutation DeleteItem($input: InputDeleteItem!) {
    deleteItem(input: $input) {
      success
    }
  }
`;

export const MUTATION_DELETE_ITEMS = gql<{ deleteItems: { success: boolean } }, { input: { ids: string[] } }>`
  mutation DeleteItems($input: InputDeleteItems!) {
    deleteItems(input: $input) {
      success
    }
  }
`;

export const MUTATION_UPDATE_ITEM = gql<
  {
    updateItem: {
      success: boolean;
      item: {
        id: string;
        content: string;
        textColor: string;
        backgroundColor: string;
        outlineColor: string;
        textSize: number;
        textAlign: string;
        signature: string;
        link: string;
      };
    };
  },
  {
    input: {
      id: string;
      content?: string;
      textColor?: string;
      backgroundColor?: string;
      outlineColor?: string;
      textSize?: number;
      textAlign?: string;
      signature?: string;
      link?: string;
    };
  }
>`
  mutation UpdateItem($input: InputUpdateItem!) {
    updateItem(input: $input) {
      success
      item {
        ... on StickyNote {
          ...StickyNoteParts
        }
        ... on TextBlock {
          ...TextBlockParts
        }
      }
    }
  }
  ${FRAGMENT_STICKY_NOTE}
  ${FRAGMENT_TEXT_BLOCK}
`;

export const MUTATION_CREATE_ITEM = gql<
  {
    createItem: {
      item: {
        id: string;
        content: string;
        textColor: string;
        backgroundColor: string;
        outlineColor: string;
        textSize: number;
        textAlign: string;
        signature: string;
        link: string;
      };
    };
  },
  {
    input: {
      id?: string;
      content: string;
      textColor: string;
      backgroundColor: string;
      outlineColor?: string;
      textSize: number;
      textAlign: string;
      signature?: string;
      link?: string;
    };
  }
>`
  mutation CreateItem($input: InputCreateItem!) {
    createItem(input: $input) {
      item {
        ... on StickyNote {
          ...StickyNoteParts
        }
        ... on TextBlock {
          ...TextBlockParts
        }
      }
    }
  }
  ${FRAGMENT_STICKY_NOTE}
  ${FRAGMENT_TEXT_BLOCK}
`;

export const MUTATION_CREATE_ITEMS = gql<
  {
    createItems: {
      item: {
        id: string;
        content: string;
        textColor: string;
        backgroundColor: string;
        outlineColor: string;
        textSize: number;
        textAlign: string;
        signature: string;
        link: string;
      };
    };
  },
  {
    input: {
      content: string;
      textColor: string;
      backgroundColor: string;
      outlineColor?: string;
      textSize: number;
      textAlign: string;
      link?: string;
    }[];
  }
>`
  mutation CreateItems($input: [InputCreateItem!]!) {
    createItems(input: $input) {
      item {
        ... on StickyNote {
          ...StickyNoteParts
        }
        ... on TextBlock {
          ...TextBlockParts
        }
      }
    }
  }
  ${FRAGMENT_STICKY_NOTE}
  ${FRAGMENT_TEXT_BLOCK}
`;

export const SUBSCRIPTION_ITEMS = gql`
  subscription itemsUpdated {
    itemsUpdated
  }
`;

export const SUBSCRIPTION_FILES = gql`
  subscription filesUpdated {
    filesUpdated
  }
`;

export const SUBSCRIPTION_TEAMS = gql`
  subscription teamsUpdated {
    teamsUpdated
  }
`;

export type Team = {
  id: string;
  name: string;
  description: string;
  members: {
    displayName: string;
    role: string;
    uid: string;
    email: string;
    disabled: boolean;
  }[];
};

export const QUERY_TEAMS = gql<
  {
    teams: {
      teams: Team[];
    };
    allowedToCreateTeam: {
      allowed: boolean;
      limit: number;
    };
  },
  void
>`
  query Teams {
    teams {
      teams {
        name
        id
        description

        members {
          displayName
          role
          uid
          email
          disabled
        }
      }
    }
    allowedToCreateTeam {
      allowed
      limit
    }
  }
`;

export const QUERY_TEAM_FILES = gql<
  {
    teamFiles: File[];
  },
  {
    input: {
      sort: string;
      teamId: string;
    };
  }
>`
  query TeamFiles($input: InputFiles) {
    teamFiles: files(input: $input) {
      id
      fileName
      thumbnailUri
      dateModified
    }
  }
`;

export const QUERY_TEAM_INVITES = gql<
  {
    teamInvites: {
      emails: string[];
    };
  },
  { input: { teamId: string } }
>`
  query TeamInvites($input: InputTeamInvites) {
    teamInvites(input: $input) {
      emails
    }
  }
`;

export const MUTATION_CREATE_TEAM = gql<
  {
    createTeam: {
      team: {
        id: string;
        name: string;
      };
      success: boolean;
    };
  },
  { input: { name: string; description: string } }
>`
  mutation CreateTeam($input: InputCreateTeam!) {
    createTeam(input: $input) {
      team {
        id
        name
      }
      success
    }
  }
`;

export const MUTATION_INVITE_TO_TEAM = gql<void, { input: { emails: string[]; teamId: string } }>`
  mutation InviteToTeam($input: InputInviteToTeam!) {
    inviteToTeam(input: $input) {
      usersAdded
      usersInvited
    }
  }
`;

export const MUTATION_DELETE_TEAM = gql<{ deleteTeam: { success: boolean } }, { input: { teamId: string } }>`
  mutation DeleteTeam($input: InputDeleteTeam!) {
    deleteTeam(input: $input) {
      success
    }
  }
`;

export const MUTATION_REMOVE_FROM_TEAM = gql<
  { removeUserFromTeam: { success: boolean } },
  { input: { teamId: string; userId: string } }
>`
  mutation RemoveUserFromTeam($input: InputRemoveUserFromTeam) {
    removeUserFromTeam(input: $input) {
      success
    }
  }
`;

export const MUTATION_REMOVE_INVITE_FROM_TEAM = gql<
  { removeInviteFromTeam: { success: boolean } },
  { input: { teamId: string; email: string } }
>`
  mutation RemoveUserInviteFromTeam($input: InputRemoveInviteFromTeam) {
    removeInviteFromTeam(input: $input) {
      success
    }
  }
`;

export const MUTATION_UPDATE_TEAM = gql<
  { updateTeam: { team: { id: string; name: string }; success: boolean } },
  { input: { teamId: string; name: string; description: string } }
>`
  mutation UpdateTeam($input: InputUpdateTeam!) {
    updateTeam(input: $input) {
      team {
        id
        name
      }
      success
    }
  }
`;

export const MUTATION_MOVE_FILE = gql<
  {
    moveFileToTeam: {
      success: boolean;
      error?: string;
    };
  },
  { input: { teamId: string; fileId: string } }
>`
  mutation MoveFileToTeam($input: InputMoveFile!) {
    moveFileToTeam(input: $input) {
      success
      error
    }
  }
`;

export const QUERY_PICKER = gql<
  {
    recents: FileOrFileLink[];
    teams: {
      teams: {
        name: string;
        id: string;
        description: string;
      }[];
    };
    files: File[];
    sharedWithMe: FileLink[];
    allowedToCreateFile: {
      allowed: boolean;
      limit?: number;
    };
    sizeFiles: number;
  },
  { limit: number; sort: string; input: { sort: string } }
>`
  query Picker($limit: Int, $sort: ListItemSort, $input: InputFiles) {
    recents(limit: $limit) {
      ... on File {
        id
        fileName
        thumbnailUri
        dateModified
        team {
          name
        }
        user {
          uid
          displayName
          disabled
        }
      }
      ... on FileLink {
        id
        file {
          user {
            uid
            displayName
            disabled
          }
          id
          fileName
          thumbnailUri
          dateModified
        }
      }
    }
    teams {
      teams {
        name
        id
        description
      }
    }
    files(input: $input) {
      id
      fileName
      thumbnailUri
      dateModified
      user {
        uid
        displayName
        disabled
      }
    }
    sharedWithMe(sort: $sort) {
      id
      dateAccessed
      file {
        user {
          displayName
          uid
          disabled
        }
        id
        fileName
        thumbnailUri
        dateModified
      }
    }
    allowedToCreateFile {
      allowed
      limit
    }
    sizeFiles
  }
`;

export const QUERY_SEARCH_BOARD = gql<
  {
    searchFiles: FileOrFileLink[];
  },
  { input: { query: string } }
>`
  query searchFiles($input: InputFileSearch) {
    searchFiles(input: $input) {
      ... on File {
        id
        fileName
        thumbnailUri
        dateModified
        team {
          name
        }
        user {
          uid
          displayName
          disabled
        }
      }
      ... on FileLink {
        id
        file {
          user {
            uid
            displayName
            disabled
          }
          id
          fileName
          thumbnailUri
          dateModified
        }
      }
    }
  }
`;

export const QUERY_USERS = gql`
  query Users {
    users {
      uid
      displayName
      disabled
    }
  }
`;

export const MUTATION_ASSIGN_OWNER = gql`
  mutation AssignOwnerToFile($fileId: String!, $userId: String!) {
    assignOwnerToFile(fileId: $fileId, userId: $userId) {
      success
      error
    }
  }
`;

export const MUTATION_SAVE_UNOWNED_FILE = gql<
  { saveUnownedFile: { success: boolean; error?: string } },
  { fileId: string; name: string }
>`
  mutation SaveUnownedFile($fileId: String!, $name: String!) {
    saveUnownedFile(fileId: $fileId, name: $name) {
      success
      error
    }
  }
`;

export const QUERY_SEARCH_USERS = gql<
  {
    searchUsers: {
      uid: string;
      displayName: string;
      disabled: boolean;
    }[];
  },
  { input: { query: string } }
>`
  query searchUsers($input: InputUserSearch) {
    searchUsers(input: $input) {
      uid
      displayName
      disabled
    }
  }
`;

export const MUTATION_OPEN_BOARD_IN_ROOM = gql<
  { openBoardInRoomClient: { success: boolean } },
  {
    input: {
      roomCode: string;
      boardLinkId: string;
    };
  }
>`
  mutation openBoardInRoomClient($input: InputOpenRoom) {
    openBoardInRoomClient(input: $input) {
      success
    }
  }
`;
