// source: collab_server.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var collab_common_pb = require('./collab_common_pb.js');
goog.object.extend(proto, collab_common_pb);
goog.exportSymbol('proto.protobuf.collab.server.AnnouncePage', null, global);
goog.exportSymbol('proto.protobuf.collab.server.AutoSaveStatus', null, global);
goog.exportSymbol('proto.protobuf.collab.server.BatchEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.server.BoardEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.server.BoardFile', null, global);
goog.exportSymbol('proto.protobuf.collab.server.ClientJoined', null, global);
goog.exportSymbol('proto.protobuf.collab.server.ClientLeft', null, global);
goog.exportSymbol('proto.protobuf.collab.server.End', null, global);
goog.exportSymbol('proto.protobuf.collab.server.ForceFollow', null, global);
goog.exportSymbol('proto.protobuf.collab.server.Image', null, global);
goog.exportSymbol('proto.protobuf.collab.server.Images', null, global);
goog.exportSymbol('proto.protobuf.collab.server.Message', null, global);
goog.exportSymbol('proto.protobuf.collab.server.Message.TypeCase', null, global);
goog.exportSymbol('proto.protobuf.collab.server.PinUpdated', null, global);
goog.exportSymbol('proto.protobuf.collab.server.Ping', null, global);
goog.exportSymbol('proto.protobuf.collab.server.PublishAck', null, global);
goog.exportSymbol('proto.protobuf.collab.server.PublishBlob', null, global);
goog.exportSymbol('proto.protobuf.collab.server.PublishBlob.TypeCase', null, global);
goog.exportSymbol('proto.protobuf.collab.server.RecallBlob', null, global);
goog.exportSymbol('proto.protobuf.collab.server.RequestPresenter', null, global);
goog.exportSymbol('proto.protobuf.collab.server.RewindEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.server.ScreenShareEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.server.SetReadOnly', null, global);
goog.exportSymbol('proto.protobuf.collab.server.Start', null, global);
goog.exportSymbol('proto.protobuf.collab.server.ThumbnailRequest', null, global);
goog.exportSymbol('proto.protobuf.collab.server.YieldPresenter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.Start = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.collab.server.Start.repeatedFields_, null);
};
goog.inherits(proto.protobuf.collab.server.Start, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.Start.displayName = 'proto.protobuf.collab.server.Start';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.End = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.End, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.End.displayName = 'proto.protobuf.collab.server.End';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.ClientJoined = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.ClientJoined, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.ClientJoined.displayName = 'proto.protobuf.collab.server.ClientJoined';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.ClientLeft = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.ClientLeft, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.ClientLeft.displayName = 'proto.protobuf.collab.server.ClientLeft';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.BoardEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.BoardEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.BoardEvent.displayName = 'proto.protobuf.collab.server.BoardEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.BatchEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.collab.server.BatchEvent.repeatedFields_, null);
};
goog.inherits(proto.protobuf.collab.server.BatchEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.BatchEvent.displayName = 'proto.protobuf.collab.server.BatchEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.RewindEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.RewindEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.RewindEvent.displayName = 'proto.protobuf.collab.server.RewindEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.Image.displayName = 'proto.protobuf.collab.server.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.Images = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.collab.server.Images.repeatedFields_, null);
};
goog.inherits(proto.protobuf.collab.server.Images, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.Images.displayName = 'proto.protobuf.collab.server.Images';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.BoardFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.BoardFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.BoardFile.displayName = 'proto.protobuf.collab.server.BoardFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.ScreenShareEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.ScreenShareEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.ScreenShareEvent.displayName = 'proto.protobuf.collab.server.ScreenShareEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.PinUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.PinUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.PinUpdated.displayName = 'proto.protobuf.collab.server.PinUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.PublishBlob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.protobuf.collab.server.PublishBlob.oneofGroups_);
};
goog.inherits(proto.protobuf.collab.server.PublishBlob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.PublishBlob.displayName = 'proto.protobuf.collab.server.PublishBlob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.PublishAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.PublishAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.PublishAck.displayName = 'proto.protobuf.collab.server.PublishAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.RecallBlob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.RecallBlob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.RecallBlob.displayName = 'proto.protobuf.collab.server.RecallBlob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.Ping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.Ping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.Ping.displayName = 'proto.protobuf.collab.server.Ping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.RequestPresenter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.RequestPresenter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.RequestPresenter.displayName = 'proto.protobuf.collab.server.RequestPresenter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.ForceFollow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.ForceFollow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.ForceFollow.displayName = 'proto.protobuf.collab.server.ForceFollow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.SetReadOnly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.SetReadOnly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.SetReadOnly.displayName = 'proto.protobuf.collab.server.SetReadOnly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.AnnouncePage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.AnnouncePage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.AnnouncePage.displayName = 'proto.protobuf.collab.server.AnnouncePage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.YieldPresenter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.YieldPresenter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.YieldPresenter.displayName = 'proto.protobuf.collab.server.YieldPresenter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.AutoSaveStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.AutoSaveStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.AutoSaveStatus.displayName = 'proto.protobuf.collab.server.AutoSaveStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.ThumbnailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.server.ThumbnailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.ThumbnailRequest.displayName = 'proto.protobuf.collab.server.ThumbnailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.server.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.protobuf.collab.server.Message.oneofGroups_);
};
goog.inherits(proto.protobuf.collab.server.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.server.Message.displayName = 'proto.protobuf.collab.server.Message';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.collab.server.Start.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.Start.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.Start.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.Start} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Start.toObject = function(includeInstance, msg) {
  var f, obj = {
    myself: (f = msg.getMyself()) && collab_common_pb.Client.toObject(includeInstance, f),
    participantList: jspb.Message.toObjectList(msg.getParticipantList(),
    collab_common_pb.Client.toObject, includeInstance),
    numInitialEvents: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numInitialImages: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.Start}
 */
proto.protobuf.collab.server.Start.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.Start;
  return proto.protobuf.collab.server.Start.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.Start} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.Start}
 */
proto.protobuf.collab.server.Start.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new collab_common_pb.Client;
      reader.readMessage(value,collab_common_pb.Client.deserializeBinaryFromReader);
      msg.setMyself(value);
      break;
    case 2:
      var value = new collab_common_pb.Client;
      reader.readMessage(value,collab_common_pb.Client.deserializeBinaryFromReader);
      msg.addParticipant(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumInitialEvents(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumInitialImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.Start.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.Start.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.Start} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Start.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMyself();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      collab_common_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getParticipantList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      collab_common_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getNumInitialEvents();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNumInitialImages();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional protobuf.collab.common.Client myself = 1;
 * @return {?proto.protobuf.collab.common.Client}
 */
proto.protobuf.collab.server.Start.prototype.getMyself = function() {
  return /** @type{?proto.protobuf.collab.common.Client} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.Client, 1));
};


/**
 * @param {?proto.protobuf.collab.common.Client|undefined} value
 * @return {!proto.protobuf.collab.server.Start} returns this
*/
proto.protobuf.collab.server.Start.prototype.setMyself = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Start} returns this
 */
proto.protobuf.collab.server.Start.prototype.clearMyself = function() {
  return this.setMyself(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Start.prototype.hasMyself = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated protobuf.collab.common.Client participant = 2;
 * @return {!Array<!proto.protobuf.collab.common.Client>}
 */
proto.protobuf.collab.server.Start.prototype.getParticipantList = function() {
  return /** @type{!Array<!proto.protobuf.collab.common.Client>} */ (
    jspb.Message.getRepeatedWrapperField(this, collab_common_pb.Client, 2));
};


/**
 * @param {!Array<!proto.protobuf.collab.common.Client>} value
 * @return {!proto.protobuf.collab.server.Start} returns this
*/
proto.protobuf.collab.server.Start.prototype.setParticipantList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.protobuf.collab.common.Client=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.common.Client}
 */
proto.protobuf.collab.server.Start.prototype.addParticipant = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.protobuf.collab.common.Client, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.server.Start} returns this
 */
proto.protobuf.collab.server.Start.prototype.clearParticipantList = function() {
  return this.setParticipantList([]);
};


/**
 * optional uint32 num_initial_events = 3;
 * @return {number}
 */
proto.protobuf.collab.server.Start.prototype.getNumInitialEvents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.Start} returns this
 */
proto.protobuf.collab.server.Start.prototype.setNumInitialEvents = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 num_initial_images = 4;
 * @return {number}
 */
proto.protobuf.collab.server.Start.prototype.getNumInitialImages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.Start} returns this
 */
proto.protobuf.collab.server.Start.prototype.setNumInitialImages = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.End.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.End.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.End} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.End.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.End}
 */
proto.protobuf.collab.server.End.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.End;
  return proto.protobuf.collab.server.End.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.End} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.End}
 */
proto.protobuf.collab.server.End.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.End.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.End.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.End} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.End.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.ClientJoined.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.ClientJoined.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.ClientJoined} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ClientJoined.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && collab_common_pb.Client.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.ClientJoined}
 */
proto.protobuf.collab.server.ClientJoined.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.ClientJoined;
  return proto.protobuf.collab.server.ClientJoined.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.ClientJoined} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.ClientJoined}
 */
proto.protobuf.collab.server.ClientJoined.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new collab_common_pb.Client;
      reader.readMessage(value,collab_common_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.ClientJoined.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.ClientJoined.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.ClientJoined} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ClientJoined.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      collab_common_pb.Client.serializeBinaryToWriter
    );
  }
};


/**
 * optional protobuf.collab.common.Client client = 1;
 * @return {?proto.protobuf.collab.common.Client}
 */
proto.protobuf.collab.server.ClientJoined.prototype.getClient = function() {
  return /** @type{?proto.protobuf.collab.common.Client} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.Client, 1));
};


/**
 * @param {?proto.protobuf.collab.common.Client|undefined} value
 * @return {!proto.protobuf.collab.server.ClientJoined} returns this
*/
proto.protobuf.collab.server.ClientJoined.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.ClientJoined} returns this
 */
proto.protobuf.collab.server.ClientJoined.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.ClientJoined.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.ClientLeft.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.ClientLeft.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.ClientLeft} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ClientLeft.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.ClientLeft}
 */
proto.protobuf.collab.server.ClientLeft.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.ClientLeft;
  return proto.protobuf.collab.server.ClientLeft.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.ClientLeft} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.ClientLeft}
 */
proto.protobuf.collab.server.ClientLeft.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.ClientLeft.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.ClientLeft.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.ClientLeft} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ClientLeft.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.ClientLeft.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.ClientLeft} returns this
 */
proto.protobuf.collab.server.ClientLeft.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.BoardEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.BoardEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.BoardEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.BoardEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64(),
    seq: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    prevActionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pb_continue: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.BoardEvent}
 */
proto.protobuf.collab.server.BoardEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.BoardEvent;
  return proto.protobuf.collab.server.BoardEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.BoardEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.BoardEvent}
 */
proto.protobuf.collab.server.BoardEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeq(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevActionId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContinue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.BoardEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.BoardEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.BoardEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.BoardEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSeq();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrevActionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContinue();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.BoardEvent} returns this
 */
proto.protobuf.collab.server.BoardEvent.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.server.BoardEvent} returns this
 */
proto.protobuf.collab.server.BoardEvent.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional int64 seq = 3;
 * @return {number}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.BoardEvent} returns this
 */
proto.protobuf.collab.server.BoardEvent.prototype.setSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string action_id = 4;
 * @return {string}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.BoardEvent} returns this
 */
proto.protobuf.collab.server.BoardEvent.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string prev_action_id = 5;
 * @return {string}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getPrevActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.BoardEvent} returns this
 */
proto.protobuf.collab.server.BoardEvent.prototype.setPrevActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool continue = 6;
 * @return {boolean}
 */
proto.protobuf.collab.server.BoardEvent.prototype.getContinue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.server.BoardEvent} returns this
 */
proto.protobuf.collab.server.BoardEvent.prototype.setContinue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.collab.server.BatchEvent.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.BatchEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.BatchEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.BatchEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.BatchEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    boardEventList: jspb.Message.toObjectList(msg.getBoardEventList(),
    proto.protobuf.collab.server.BoardEvent.toObject, includeInstance),
    seq: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.BatchEvent}
 */
proto.protobuf.collab.server.BatchEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.BatchEvent;
  return proto.protobuf.collab.server.BatchEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.BatchEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.BatchEvent}
 */
proto.protobuf.collab.server.BatchEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = new proto.protobuf.collab.server.BoardEvent;
      reader.readMessage(value,proto.protobuf.collab.server.BoardEvent.deserializeBinaryFromReader);
      msg.addBoardEvent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.BatchEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.BatchEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.BatchEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.BatchEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBoardEventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.protobuf.collab.server.BoardEvent.serializeBinaryToWriter
    );
  }
  f = message.getSeq();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.BatchEvent.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.BatchEvent} returns this
 */
proto.protobuf.collab.server.BatchEvent.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated BoardEvent board_event = 2;
 * @return {!Array<!proto.protobuf.collab.server.BoardEvent>}
 */
proto.protobuf.collab.server.BatchEvent.prototype.getBoardEventList = function() {
  return /** @type{!Array<!proto.protobuf.collab.server.BoardEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.collab.server.BoardEvent, 2));
};


/**
 * @param {!Array<!proto.protobuf.collab.server.BoardEvent>} value
 * @return {!proto.protobuf.collab.server.BatchEvent} returns this
*/
proto.protobuf.collab.server.BatchEvent.prototype.setBoardEventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.protobuf.collab.server.BoardEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.server.BoardEvent}
 */
proto.protobuf.collab.server.BatchEvent.prototype.addBoardEvent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.protobuf.collab.server.BoardEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.server.BatchEvent} returns this
 */
proto.protobuf.collab.server.BatchEvent.prototype.clearBoardEventList = function() {
  return this.setBoardEventList([]);
};


/**
 * optional int64 seq = 3;
 * @return {number}
 */
proto.protobuf.collab.server.BatchEvent.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.BatchEvent} returns this
 */
proto.protobuf.collab.server.BatchEvent.prototype.setSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.RewindEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.RewindEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.RewindEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.RewindEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seq: jspb.Message.getFieldWithDefault(msg, 2, 0),
    latestCorrectActionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preventRedo: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.RewindEvent}
 */
proto.protobuf.collab.server.RewindEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.RewindEvent;
  return proto.protobuf.collab.server.RewindEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.RewindEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.RewindEvent}
 */
proto.protobuf.collab.server.RewindEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeq(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestCorrectActionId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreventRedo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.RewindEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.RewindEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.RewindEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.RewindEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeq();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLatestCorrectActionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreventRedo();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.RewindEvent.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.RewindEvent} returns this
 */
proto.protobuf.collab.server.RewindEvent.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 seq = 2;
 * @return {number}
 */
proto.protobuf.collab.server.RewindEvent.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.RewindEvent} returns this
 */
proto.protobuf.collab.server.RewindEvent.prototype.setSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string latest_correct_action_id = 3;
 * @return {string}
 */
proto.protobuf.collab.server.RewindEvent.prototype.getLatestCorrectActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.RewindEvent} returns this
 */
proto.protobuf.collab.server.RewindEvent.prototype.setLatestCorrectActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool prevent_redo = 4;
 * @return {boolean}
 */
proto.protobuf.collab.server.RewindEvent.prototype.getPreventRedo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.server.RewindEvent} returns this
 */
proto.protobuf.collab.server.RewindEvent.prototype.setPreventRedo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64(),
    mime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paperId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pb_continue: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.Image}
 */
proto.protobuf.collab.server.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.Image;
  return proto.protobuf.collab.server.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.Image}
 */
proto.protobuf.collab.server.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaperId(value);
      break;
    case 6:
      var value = /** @type {!proto.protobuf.collab.common.ImageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContinue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaperId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getContinue();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.Image.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.Image} returns this
 */
proto.protobuf.collab.server.Image.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.server.Image.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.server.Image.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.Image.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.server.Image} returns this
 */
proto.protobuf.collab.server.Image.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string mime = 3;
 * @return {string}
 */
proto.protobuf.collab.server.Image.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.Image} returns this
 */
proto.protobuf.collab.server.Image.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.protobuf.collab.server.Image.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.Image} returns this
 */
proto.protobuf.collab.server.Image.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string paper_id = 5;
 * @return {string}
 */
proto.protobuf.collab.server.Image.prototype.getPaperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.Image} returns this
 */
proto.protobuf.collab.server.Image.prototype.setPaperId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional protobuf.collab.common.ImageType type = 6;
 * @return {!proto.protobuf.collab.common.ImageType}
 */
proto.protobuf.collab.server.Image.prototype.getType = function() {
  return /** @type {!proto.protobuf.collab.common.ImageType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.protobuf.collab.common.ImageType} value
 * @return {!proto.protobuf.collab.server.Image} returns this
 */
proto.protobuf.collab.server.Image.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool continue = 7;
 * @return {boolean}
 */
proto.protobuf.collab.server.Image.prototype.getContinue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.server.Image} returns this
 */
proto.protobuf.collab.server.Image.prototype.setContinue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.collab.server.Images.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.Images.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.Images.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.Images} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Images.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageList: jspb.Message.toObjectList(msg.getImageList(),
    proto.protobuf.collab.server.Image.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.Images}
 */
proto.protobuf.collab.server.Images.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.Images;
  return proto.protobuf.collab.server.Images.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.Images} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.Images}
 */
proto.protobuf.collab.server.Images.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = new proto.protobuf.collab.server.Image;
      reader.readMessage(value,proto.protobuf.collab.server.Image.deserializeBinaryFromReader);
      msg.addImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.Images.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.Images.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.Images} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Images.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.protobuf.collab.server.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.Images.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.Images} returns this
 */
proto.protobuf.collab.server.Images.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Image image = 2;
 * @return {!Array<!proto.protobuf.collab.server.Image>}
 */
proto.protobuf.collab.server.Images.prototype.getImageList = function() {
  return /** @type{!Array<!proto.protobuf.collab.server.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.collab.server.Image, 2));
};


/**
 * @param {!Array<!proto.protobuf.collab.server.Image>} value
 * @return {!proto.protobuf.collab.server.Images} returns this
*/
proto.protobuf.collab.server.Images.prototype.setImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.protobuf.collab.server.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.server.Image}
 */
proto.protobuf.collab.server.Images.prototype.addImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.protobuf.collab.server.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.server.Images} returns this
 */
proto.protobuf.collab.server.Images.prototype.clearImageList = function() {
  return this.setImageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.BoardFile.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.BoardFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.BoardFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.BoardFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.BoardFile}
 */
proto.protobuf.collab.server.BoardFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.BoardFile;
  return proto.protobuf.collab.server.BoardFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.BoardFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.BoardFile}
 */
proto.protobuf.collab.server.BoardFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.BoardFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.BoardFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.BoardFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.BoardFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.BoardFile.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.BoardFile} returns this
 */
proto.protobuf.collab.server.BoardFile.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.protobuf.collab.server.BoardFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.BoardFile} returns this
 */
proto.protobuf.collab.server.BoardFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.server.BoardFile.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.server.BoardFile.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.BoardFile.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.server.BoardFile} returns this
 */
proto.protobuf.collab.server.BoardFile.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.ScreenShareEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.ScreenShareEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ScreenShareEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.ScreenShareEvent}
 */
proto.protobuf.collab.server.ScreenShareEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.ScreenShareEvent;
  return proto.protobuf.collab.server.ScreenShareEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.ScreenShareEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.ScreenShareEvent}
 */
proto.protobuf.collab.server.ScreenShareEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.ScreenShareEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.ScreenShareEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ScreenShareEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.ScreenShareEvent} returns this
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.server.ScreenShareEvent} returns this
 */
proto.protobuf.collab.server.ScreenShareEvent.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.PinUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.PinUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.PinUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.PinUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.PinUpdated}
 */
proto.protobuf.collab.server.PinUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.PinUpdated;
  return proto.protobuf.collab.server.PinUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.PinUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.PinUpdated}
 */
proto.protobuf.collab.server.PinUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.PinUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.PinUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.PinUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.PinUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiresAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.PinUpdated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.PinUpdated} returns this
 */
proto.protobuf.collab.server.PinUpdated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.protobuf.collab.server.PinUpdated.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.PinUpdated} returns this
 */
proto.protobuf.collab.server.PinUpdated.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 expires_at = 3;
 * @return {number}
 */
proto.protobuf.collab.server.PinUpdated.prototype.getExpiresAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.PinUpdated} returns this
 */
proto.protobuf.collab.server.PinUpdated.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.protobuf.collab.server.PublishBlob.oneofGroups_ = [[3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.protobuf.collab.server.PublishBlob.TypeCase = {
  TYPE_NOT_SET: 0,
  IMAGE: 3,
  STICKY_NOTE: 4,
  STICKER: 5,
  BOARD_FILE: 6,
  TEXT_BLOCK: 7
};

/**
 * @return {proto.protobuf.collab.server.PublishBlob.TypeCase}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getTypeCase = function() {
  return /** @type {proto.protobuf.collab.server.PublishBlob.TypeCase} */(jspb.Message.computeOneofCase(this, proto.protobuf.collab.server.PublishBlob.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.PublishBlob.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.PublishBlob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.PublishBlob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.PublishBlob.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publishId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    image: (f = msg.getImage()) && collab_common_pb.Image.toObject(includeInstance, f),
    stickyNote: (f = msg.getStickyNote()) && collab_common_pb.StickyNote.toObject(includeInstance, f),
    sticker: (f = msg.getSticker()) && collab_common_pb.Sticker.toObject(includeInstance, f),
    boardFile: (f = msg.getBoardFile()) && collab_common_pb.BoardFile.toObject(includeInstance, f),
    textBlock: (f = msg.getTextBlock()) && collab_common_pb.TextBlock.toObject(includeInstance, f),
    imageData: msg.getImageData_asB64(),
    itemProperties: msg.getItemProperties_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.PublishBlob}
 */
proto.protobuf.collab.server.PublishBlob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.PublishBlob;
  return proto.protobuf.collab.server.PublishBlob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.PublishBlob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.PublishBlob}
 */
proto.protobuf.collab.server.PublishBlob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishId(value);
      break;
    case 3:
      var value = new collab_common_pb.Image;
      reader.readMessage(value,collab_common_pb.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 4:
      var value = new collab_common_pb.StickyNote;
      reader.readMessage(value,collab_common_pb.StickyNote.deserializeBinaryFromReader);
      msg.setStickyNote(value);
      break;
    case 5:
      var value = new collab_common_pb.Sticker;
      reader.readMessage(value,collab_common_pb.Sticker.deserializeBinaryFromReader);
      msg.setSticker(value);
      break;
    case 6:
      var value = new collab_common_pb.BoardFile;
      reader.readMessage(value,collab_common_pb.BoardFile.deserializeBinaryFromReader);
      msg.setBoardFile(value);
      break;
    case 7:
      var value = new collab_common_pb.TextBlock;
      reader.readMessage(value,collab_common_pb.TextBlock.deserializeBinaryFromReader);
      msg.setTextBlock(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImageData(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setItemProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.PublishBlob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.PublishBlob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.PublishBlob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.PublishBlob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublishId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      collab_common_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getStickyNote();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      collab_common_pb.StickyNote.serializeBinaryToWriter
    );
  }
  f = message.getSticker();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      collab_common_pb.Sticker.serializeBinaryToWriter
    );
  }
  f = message.getBoardFile();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      collab_common_pb.BoardFile.serializeBinaryToWriter
    );
  }
  f = message.getTextBlock();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      collab_common_pb.TextBlock.serializeBinaryToWriter
    );
  }
  f = message.getImageData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getItemProperties_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string publish_id = 2;
 * @return {string}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getPublishId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.setPublishId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional protobuf.collab.common.Image image = 3;
 * @return {?proto.protobuf.collab.common.Image}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getImage = function() {
  return /** @type{?proto.protobuf.collab.common.Image} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.Image, 3));
};


/**
 * @param {?proto.protobuf.collab.common.Image|undefined} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
*/
proto.protobuf.collab.server.PublishBlob.prototype.setImage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.protobuf.collab.server.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.PublishBlob.prototype.hasImage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional protobuf.collab.common.StickyNote sticky_note = 4;
 * @return {?proto.protobuf.collab.common.StickyNote}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getStickyNote = function() {
  return /** @type{?proto.protobuf.collab.common.StickyNote} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.StickyNote, 4));
};


/**
 * @param {?proto.protobuf.collab.common.StickyNote|undefined} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
*/
proto.protobuf.collab.server.PublishBlob.prototype.setStickyNote = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.protobuf.collab.server.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.clearStickyNote = function() {
  return this.setStickyNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.PublishBlob.prototype.hasStickyNote = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional protobuf.collab.common.Sticker sticker = 5;
 * @return {?proto.protobuf.collab.common.Sticker}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getSticker = function() {
  return /** @type{?proto.protobuf.collab.common.Sticker} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.Sticker, 5));
};


/**
 * @param {?proto.protobuf.collab.common.Sticker|undefined} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
*/
proto.protobuf.collab.server.PublishBlob.prototype.setSticker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.protobuf.collab.server.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.clearSticker = function() {
  return this.setSticker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.PublishBlob.prototype.hasSticker = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional protobuf.collab.common.BoardFile board_file = 6;
 * @return {?proto.protobuf.collab.common.BoardFile}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getBoardFile = function() {
  return /** @type{?proto.protobuf.collab.common.BoardFile} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.BoardFile, 6));
};


/**
 * @param {?proto.protobuf.collab.common.BoardFile|undefined} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
*/
proto.protobuf.collab.server.PublishBlob.prototype.setBoardFile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.protobuf.collab.server.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.clearBoardFile = function() {
  return this.setBoardFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.PublishBlob.prototype.hasBoardFile = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional protobuf.collab.common.TextBlock text_block = 7;
 * @return {?proto.protobuf.collab.common.TextBlock}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getTextBlock = function() {
  return /** @type{?proto.protobuf.collab.common.TextBlock} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.TextBlock, 7));
};


/**
 * @param {?proto.protobuf.collab.common.TextBlock|undefined} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
*/
proto.protobuf.collab.server.PublishBlob.prototype.setTextBlock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.protobuf.collab.server.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.clearTextBlock = function() {
  return this.setTextBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.PublishBlob.prototype.hasTextBlock = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes image_data = 8;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getImageData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes image_data = 8;
 * This is a type-conversion wrapper around `getImageData()`
 * @return {string}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getImageData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImageData()));
};


/**
 * optional bytes image_data = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImageData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getImageData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImageData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.setImageData = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional bytes item_properties = 9;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getItemProperties = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes item_properties = 9;
 * This is a type-conversion wrapper around `getItemProperties()`
 * @return {string}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getItemProperties_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getItemProperties()));
};


/**
 * optional bytes item_properties = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getItemProperties()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.PublishBlob.prototype.getItemProperties_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getItemProperties()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.server.PublishBlob} returns this
 */
proto.protobuf.collab.server.PublishBlob.prototype.setItemProperties = function(value) {
  return jspb.Message.setProto3BytesField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.PublishAck.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.PublishAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.PublishAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.PublishAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publishId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.PublishAck}
 */
proto.protobuf.collab.server.PublishAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.PublishAck;
  return proto.protobuf.collab.server.PublishAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.PublishAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.PublishAck}
 */
proto.protobuf.collab.server.PublishAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.PublishAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.PublishAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.PublishAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.PublishAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublishId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.PublishAck.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.PublishAck} returns this
 */
proto.protobuf.collab.server.PublishAck.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string publish_id = 2;
 * @return {string}
 */
proto.protobuf.collab.server.PublishAck.prototype.getPublishId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.PublishAck} returns this
 */
proto.protobuf.collab.server.PublishAck.prototype.setPublishId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.RecallBlob.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.RecallBlob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.RecallBlob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.RecallBlob.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publishId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.RecallBlob}
 */
proto.protobuf.collab.server.RecallBlob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.RecallBlob;
  return proto.protobuf.collab.server.RecallBlob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.RecallBlob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.RecallBlob}
 */
proto.protobuf.collab.server.RecallBlob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.RecallBlob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.RecallBlob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.RecallBlob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.RecallBlob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublishId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.RecallBlob.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.RecallBlob} returns this
 */
proto.protobuf.collab.server.RecallBlob.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string publish_id = 2;
 * @return {string}
 */
proto.protobuf.collab.server.RecallBlob.prototype.getPublishId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.RecallBlob} returns this
 */
proto.protobuf.collab.server.RecallBlob.prototype.setPublishId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.Ping.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.Ping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.Ping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Ping.toObject = function(includeInstance, msg) {
  var f, obj = {
    color: msg.getColor_asB64(),
    pageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    senderId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.Ping}
 */
proto.protobuf.collab.server.Ping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.Ping;
  return proto.protobuf.collab.server.Ping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.Ping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.Ping}
 */
proto.protobuf.collab.server.Ping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setColor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.Ping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.Ping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.Ping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Ping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColor_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bytes color = 1;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.server.Ping.prototype.getColor = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes color = 1;
 * This is a type-conversion wrapper around `getColor()`
 * @return {string}
 */
proto.protobuf.collab.server.Ping.prototype.getColor_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getColor()));
};


/**
 * optional bytes color = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getColor()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.Ping.prototype.getColor_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getColor()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.server.Ping} returns this
 */
proto.protobuf.collab.server.Ping.prototype.setColor = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string page_id = 2;
 * @return {string}
 */
proto.protobuf.collab.server.Ping.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.Ping} returns this
 */
proto.protobuf.collab.server.Ping.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float x = 3;
 * @return {number}
 */
proto.protobuf.collab.server.Ping.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.Ping} returns this
 */
proto.protobuf.collab.server.Ping.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float y = 4;
 * @return {number}
 */
proto.protobuf.collab.server.Ping.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.Ping} returns this
 */
proto.protobuf.collab.server.Ping.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string sender_id = 5;
 * @return {string}
 */
proto.protobuf.collab.server.Ping.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.Ping} returns this
 */
proto.protobuf.collab.server.Ping.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.RequestPresenter.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.RequestPresenter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.RequestPresenter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.RequestPresenter.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.RequestPresenter}
 */
proto.protobuf.collab.server.RequestPresenter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.RequestPresenter;
  return proto.protobuf.collab.server.RequestPresenter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.RequestPresenter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.RequestPresenter}
 */
proto.protobuf.collab.server.RequestPresenter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.RequestPresenter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.RequestPresenter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.RequestPresenter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.RequestPresenter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.RequestPresenter.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.RequestPresenter} returns this
 */
proto.protobuf.collab.server.RequestPresenter.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.ForceFollow.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.ForceFollow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.ForceFollow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ForceFollow.toObject = function(includeInstance, msg) {
  var f, obj = {
    on: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    senderId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.ForceFollow}
 */
proto.protobuf.collab.server.ForceFollow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.ForceFollow;
  return proto.protobuf.collab.server.ForceFollow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.ForceFollow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.ForceFollow}
 */
proto.protobuf.collab.server.ForceFollow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.ForceFollow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.ForceFollow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.ForceFollow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ForceFollow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOn();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool on = 1;
 * @return {boolean}
 */
proto.protobuf.collab.server.ForceFollow.prototype.getOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.server.ForceFollow} returns this
 */
proto.protobuf.collab.server.ForceFollow.prototype.setOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string sender_id = 2;
 * @return {string}
 */
proto.protobuf.collab.server.ForceFollow.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.ForceFollow} returns this
 */
proto.protobuf.collab.server.ForceFollow.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.SetReadOnly.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.SetReadOnly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.SetReadOnly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.SetReadOnly.toObject = function(includeInstance, msg) {
  var f, obj = {
    on: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    senderId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.SetReadOnly}
 */
proto.protobuf.collab.server.SetReadOnly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.SetReadOnly;
  return proto.protobuf.collab.server.SetReadOnly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.SetReadOnly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.SetReadOnly}
 */
proto.protobuf.collab.server.SetReadOnly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.SetReadOnly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.SetReadOnly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.SetReadOnly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.SetReadOnly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOn();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool on = 1;
 * @return {boolean}
 */
proto.protobuf.collab.server.SetReadOnly.prototype.getOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.server.SetReadOnly} returns this
 */
proto.protobuf.collab.server.SetReadOnly.prototype.setOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string sender_id = 2;
 * @return {string}
 */
proto.protobuf.collab.server.SetReadOnly.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.SetReadOnly} returns this
 */
proto.protobuf.collab.server.SetReadOnly.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.AnnouncePage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.AnnouncePage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.AnnouncePage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.AnnouncePage.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    senderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isPresenter: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.AnnouncePage}
 */
proto.protobuf.collab.server.AnnouncePage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.AnnouncePage;
  return proto.protobuf.collab.server.AnnouncePage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.AnnouncePage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.AnnouncePage}
 */
proto.protobuf.collab.server.AnnouncePage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPresenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.AnnouncePage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.AnnouncePage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.AnnouncePage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.AnnouncePage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsPresenter();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.AnnouncePage.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.AnnouncePage} returns this
 */
proto.protobuf.collab.server.AnnouncePage.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sender_id = 2;
 * @return {string}
 */
proto.protobuf.collab.server.AnnouncePage.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.AnnouncePage} returns this
 */
proto.protobuf.collab.server.AnnouncePage.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_presenter = 3;
 * @return {boolean}
 */
proto.protobuf.collab.server.AnnouncePage.prototype.getIsPresenter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.server.AnnouncePage} returns this
 */
proto.protobuf.collab.server.AnnouncePage.prototype.setIsPresenter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.YieldPresenter.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.YieldPresenter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.YieldPresenter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.YieldPresenter.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.YieldPresenter}
 */
proto.protobuf.collab.server.YieldPresenter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.YieldPresenter;
  return proto.protobuf.collab.server.YieldPresenter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.YieldPresenter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.YieldPresenter}
 */
proto.protobuf.collab.server.YieldPresenter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.YieldPresenter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.YieldPresenter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.YieldPresenter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.YieldPresenter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sender_id = 1;
 * @return {string}
 */
proto.protobuf.collab.server.YieldPresenter.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.YieldPresenter} returns this
 */
proto.protobuf.collab.server.YieldPresenter.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.AutoSaveStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.AutoSaveStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.AutoSaveStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    savedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fileId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.AutoSaveStatus}
 */
proto.protobuf.collab.server.AutoSaveStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.AutoSaveStatus;
  return proto.protobuf.collab.server.AutoSaveStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.AutoSaveStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.AutoSaveStatus}
 */
proto.protobuf.collab.server.AutoSaveStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSavedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.AutoSaveStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.AutoSaveStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.AutoSaveStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSavedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.server.AutoSaveStatus} returns this
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 saved_at = 2;
 * @return {number}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.getSavedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.server.AutoSaveStatus} returns this
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.setSavedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.AutoSaveStatus} returns this
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action_id = 4;
 * @return {string}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.AutoSaveStatus} returns this
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string file_id = 5;
 * @return {string}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.AutoSaveStatus} returns this
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string owner_id = 6;
 * @return {string}
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.server.AutoSaveStatus} returns this
 */
proto.protobuf.collab.server.AutoSaveStatus.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.ThumbnailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.ThumbnailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.ThumbnailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ThumbnailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.ThumbnailRequest}
 */
proto.protobuf.collab.server.ThumbnailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.ThumbnailRequest;
  return proto.protobuf.collab.server.ThumbnailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.ThumbnailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.ThumbnailRequest}
 */
proto.protobuf.collab.server.ThumbnailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.ThumbnailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.ThumbnailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.ThumbnailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.ThumbnailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.protobuf.collab.server.Message.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]];

/**
 * @enum {number}
 */
proto.protobuf.collab.server.Message.TypeCase = {
  TYPE_NOT_SET: 0,
  START: 1,
  END: 2,
  CLIENT_JOINED: 3,
  CLIENT_LEFT: 4,
  BOARD_EVENT: 5,
  BOARD_FILE: 6,
  SCREEN_SHARE_EVENT: 7,
  PIN_UPDATED: 8,
  PUBLISH_BLOB: 9,
  PUBLISH_ACK: 10,
  RECALL_BLOB: 11,
  PING: 12,
  IMAGE: 13,
  REQUEST_PRESENTER: 14,
  ANNOUNCE_PAGE: 15,
  REWIND_EVENT: 16,
  BATCH_EVENT: 17,
  IMAGES: 18,
  FORCE_FOLLOW: 19,
  SET_READ_ONLY: 20,
  YIELD_PRESENTER: 21,
  AUTO_SAVE_STATUS: 22,
  THUMBNAIL_REQUEST: 23
};

/**
 * @return {proto.protobuf.collab.server.Message.TypeCase}
 */
proto.protobuf.collab.server.Message.prototype.getTypeCase = function() {
  return /** @type {proto.protobuf.collab.server.Message.TypeCase} */(jspb.Message.computeOneofCase(this, proto.protobuf.collab.server.Message.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.server.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.server.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.server.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.protobuf.collab.server.Start.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.protobuf.collab.server.End.toObject(includeInstance, f),
    clientJoined: (f = msg.getClientJoined()) && proto.protobuf.collab.server.ClientJoined.toObject(includeInstance, f),
    clientLeft: (f = msg.getClientLeft()) && proto.protobuf.collab.server.ClientLeft.toObject(includeInstance, f),
    boardEvent: (f = msg.getBoardEvent()) && proto.protobuf.collab.server.BoardEvent.toObject(includeInstance, f),
    boardFile: (f = msg.getBoardFile()) && proto.protobuf.collab.server.BoardFile.toObject(includeInstance, f),
    screenShareEvent: (f = msg.getScreenShareEvent()) && proto.protobuf.collab.server.ScreenShareEvent.toObject(includeInstance, f),
    pinUpdated: (f = msg.getPinUpdated()) && proto.protobuf.collab.server.PinUpdated.toObject(includeInstance, f),
    publishBlob: (f = msg.getPublishBlob()) && proto.protobuf.collab.server.PublishBlob.toObject(includeInstance, f),
    publishAck: (f = msg.getPublishAck()) && proto.protobuf.collab.server.PublishAck.toObject(includeInstance, f),
    recallBlob: (f = msg.getRecallBlob()) && proto.protobuf.collab.server.RecallBlob.toObject(includeInstance, f),
    ping: (f = msg.getPing()) && proto.protobuf.collab.server.Ping.toObject(includeInstance, f),
    image: (f = msg.getImage()) && proto.protobuf.collab.server.Image.toObject(includeInstance, f),
    requestPresenter: (f = msg.getRequestPresenter()) && proto.protobuf.collab.server.RequestPresenter.toObject(includeInstance, f),
    announcePage: (f = msg.getAnnouncePage()) && proto.protobuf.collab.server.AnnouncePage.toObject(includeInstance, f),
    rewindEvent: (f = msg.getRewindEvent()) && proto.protobuf.collab.server.RewindEvent.toObject(includeInstance, f),
    batchEvent: (f = msg.getBatchEvent()) && proto.protobuf.collab.server.BatchEvent.toObject(includeInstance, f),
    images: (f = msg.getImages()) && proto.protobuf.collab.server.Images.toObject(includeInstance, f),
    forceFollow: (f = msg.getForceFollow()) && proto.protobuf.collab.server.ForceFollow.toObject(includeInstance, f),
    setReadOnly: (f = msg.getSetReadOnly()) && proto.protobuf.collab.server.SetReadOnly.toObject(includeInstance, f),
    yieldPresenter: (f = msg.getYieldPresenter()) && proto.protobuf.collab.server.YieldPresenter.toObject(includeInstance, f),
    autoSaveStatus: (f = msg.getAutoSaveStatus()) && proto.protobuf.collab.server.AutoSaveStatus.toObject(includeInstance, f),
    thumbnailRequest: (f = msg.getThumbnailRequest()) && proto.protobuf.collab.server.ThumbnailRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.server.Message}
 */
proto.protobuf.collab.server.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.server.Message;
  return proto.protobuf.collab.server.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.server.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.server.Message}
 */
proto.protobuf.collab.server.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.collab.server.Start;
      reader.readMessage(value,proto.protobuf.collab.server.Start.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.protobuf.collab.server.End;
      reader.readMessage(value,proto.protobuf.collab.server.End.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 3:
      var value = new proto.protobuf.collab.server.ClientJoined;
      reader.readMessage(value,proto.protobuf.collab.server.ClientJoined.deserializeBinaryFromReader);
      msg.setClientJoined(value);
      break;
    case 4:
      var value = new proto.protobuf.collab.server.ClientLeft;
      reader.readMessage(value,proto.protobuf.collab.server.ClientLeft.deserializeBinaryFromReader);
      msg.setClientLeft(value);
      break;
    case 5:
      var value = new proto.protobuf.collab.server.BoardEvent;
      reader.readMessage(value,proto.protobuf.collab.server.BoardEvent.deserializeBinaryFromReader);
      msg.setBoardEvent(value);
      break;
    case 6:
      var value = new proto.protobuf.collab.server.BoardFile;
      reader.readMessage(value,proto.protobuf.collab.server.BoardFile.deserializeBinaryFromReader);
      msg.setBoardFile(value);
      break;
    case 7:
      var value = new proto.protobuf.collab.server.ScreenShareEvent;
      reader.readMessage(value,proto.protobuf.collab.server.ScreenShareEvent.deserializeBinaryFromReader);
      msg.setScreenShareEvent(value);
      break;
    case 8:
      var value = new proto.protobuf.collab.server.PinUpdated;
      reader.readMessage(value,proto.protobuf.collab.server.PinUpdated.deserializeBinaryFromReader);
      msg.setPinUpdated(value);
      break;
    case 9:
      var value = new proto.protobuf.collab.server.PublishBlob;
      reader.readMessage(value,proto.protobuf.collab.server.PublishBlob.deserializeBinaryFromReader);
      msg.setPublishBlob(value);
      break;
    case 10:
      var value = new proto.protobuf.collab.server.PublishAck;
      reader.readMessage(value,proto.protobuf.collab.server.PublishAck.deserializeBinaryFromReader);
      msg.setPublishAck(value);
      break;
    case 11:
      var value = new proto.protobuf.collab.server.RecallBlob;
      reader.readMessage(value,proto.protobuf.collab.server.RecallBlob.deserializeBinaryFromReader);
      msg.setRecallBlob(value);
      break;
    case 12:
      var value = new proto.protobuf.collab.server.Ping;
      reader.readMessage(value,proto.protobuf.collab.server.Ping.deserializeBinaryFromReader);
      msg.setPing(value);
      break;
    case 13:
      var value = new proto.protobuf.collab.server.Image;
      reader.readMessage(value,proto.protobuf.collab.server.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 14:
      var value = new proto.protobuf.collab.server.RequestPresenter;
      reader.readMessage(value,proto.protobuf.collab.server.RequestPresenter.deserializeBinaryFromReader);
      msg.setRequestPresenter(value);
      break;
    case 15:
      var value = new proto.protobuf.collab.server.AnnouncePage;
      reader.readMessage(value,proto.protobuf.collab.server.AnnouncePage.deserializeBinaryFromReader);
      msg.setAnnouncePage(value);
      break;
    case 16:
      var value = new proto.protobuf.collab.server.RewindEvent;
      reader.readMessage(value,proto.protobuf.collab.server.RewindEvent.deserializeBinaryFromReader);
      msg.setRewindEvent(value);
      break;
    case 17:
      var value = new proto.protobuf.collab.server.BatchEvent;
      reader.readMessage(value,proto.protobuf.collab.server.BatchEvent.deserializeBinaryFromReader);
      msg.setBatchEvent(value);
      break;
    case 18:
      var value = new proto.protobuf.collab.server.Images;
      reader.readMessage(value,proto.protobuf.collab.server.Images.deserializeBinaryFromReader);
      msg.setImages(value);
      break;
    case 19:
      var value = new proto.protobuf.collab.server.ForceFollow;
      reader.readMessage(value,proto.protobuf.collab.server.ForceFollow.deserializeBinaryFromReader);
      msg.setForceFollow(value);
      break;
    case 20:
      var value = new proto.protobuf.collab.server.SetReadOnly;
      reader.readMessage(value,proto.protobuf.collab.server.SetReadOnly.deserializeBinaryFromReader);
      msg.setSetReadOnly(value);
      break;
    case 21:
      var value = new proto.protobuf.collab.server.YieldPresenter;
      reader.readMessage(value,proto.protobuf.collab.server.YieldPresenter.deserializeBinaryFromReader);
      msg.setYieldPresenter(value);
      break;
    case 22:
      var value = new proto.protobuf.collab.server.AutoSaveStatus;
      reader.readMessage(value,proto.protobuf.collab.server.AutoSaveStatus.deserializeBinaryFromReader);
      msg.setAutoSaveStatus(value);
      break;
    case 23:
      var value = new proto.protobuf.collab.server.ThumbnailRequest;
      reader.readMessage(value,proto.protobuf.collab.server.ThumbnailRequest.deserializeBinaryFromReader);
      msg.setThumbnailRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.server.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.server.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.server.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.server.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.collab.server.Start.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.collab.server.End.serializeBinaryToWriter
    );
  }
  f = message.getClientJoined();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.collab.server.ClientJoined.serializeBinaryToWriter
    );
  }
  f = message.getClientLeft();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.collab.server.ClientLeft.serializeBinaryToWriter
    );
  }
  f = message.getBoardEvent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.collab.server.BoardEvent.serializeBinaryToWriter
    );
  }
  f = message.getBoardFile();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.collab.server.BoardFile.serializeBinaryToWriter
    );
  }
  f = message.getScreenShareEvent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protobuf.collab.server.ScreenShareEvent.serializeBinaryToWriter
    );
  }
  f = message.getPinUpdated();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protobuf.collab.server.PinUpdated.serializeBinaryToWriter
    );
  }
  f = message.getPublishBlob();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.protobuf.collab.server.PublishBlob.serializeBinaryToWriter
    );
  }
  f = message.getPublishAck();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protobuf.collab.server.PublishAck.serializeBinaryToWriter
    );
  }
  f = message.getRecallBlob();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.protobuf.collab.server.RecallBlob.serializeBinaryToWriter
    );
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.protobuf.collab.server.Ping.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.protobuf.collab.server.Image.serializeBinaryToWriter
    );
  }
  f = message.getRequestPresenter();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.protobuf.collab.server.RequestPresenter.serializeBinaryToWriter
    );
  }
  f = message.getAnnouncePage();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.protobuf.collab.server.AnnouncePage.serializeBinaryToWriter
    );
  }
  f = message.getRewindEvent();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.protobuf.collab.server.RewindEvent.serializeBinaryToWriter
    );
  }
  f = message.getBatchEvent();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.protobuf.collab.server.BatchEvent.serializeBinaryToWriter
    );
  }
  f = message.getImages();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.protobuf.collab.server.Images.serializeBinaryToWriter
    );
  }
  f = message.getForceFollow();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.protobuf.collab.server.ForceFollow.serializeBinaryToWriter
    );
  }
  f = message.getSetReadOnly();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.protobuf.collab.server.SetReadOnly.serializeBinaryToWriter
    );
  }
  f = message.getYieldPresenter();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.protobuf.collab.server.YieldPresenter.serializeBinaryToWriter
    );
  }
  f = message.getAutoSaveStatus();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.protobuf.collab.server.AutoSaveStatus.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailRequest();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.protobuf.collab.server.ThumbnailRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional Start start = 1;
 * @return {?proto.protobuf.collab.server.Start}
 */
proto.protobuf.collab.server.Message.prototype.getStart = function() {
  return /** @type{?proto.protobuf.collab.server.Start} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.Start, 1));
};


/**
 * @param {?proto.protobuf.collab.server.Start|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional End end = 2;
 * @return {?proto.protobuf.collab.server.End}
 */
proto.protobuf.collab.server.Message.prototype.getEnd = function() {
  return /** @type{?proto.protobuf.collab.server.End} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.End, 2));
};


/**
 * @param {?proto.protobuf.collab.server.End|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setEnd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ClientJoined client_joined = 3;
 * @return {?proto.protobuf.collab.server.ClientJoined}
 */
proto.protobuf.collab.server.Message.prototype.getClientJoined = function() {
  return /** @type{?proto.protobuf.collab.server.ClientJoined} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.ClientJoined, 3));
};


/**
 * @param {?proto.protobuf.collab.server.ClientJoined|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setClientJoined = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearClientJoined = function() {
  return this.setClientJoined(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasClientJoined = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ClientLeft client_left = 4;
 * @return {?proto.protobuf.collab.server.ClientLeft}
 */
proto.protobuf.collab.server.Message.prototype.getClientLeft = function() {
  return /** @type{?proto.protobuf.collab.server.ClientLeft} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.ClientLeft, 4));
};


/**
 * @param {?proto.protobuf.collab.server.ClientLeft|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setClientLeft = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearClientLeft = function() {
  return this.setClientLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasClientLeft = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BoardEvent board_event = 5;
 * @return {?proto.protobuf.collab.server.BoardEvent}
 */
proto.protobuf.collab.server.Message.prototype.getBoardEvent = function() {
  return /** @type{?proto.protobuf.collab.server.BoardEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.BoardEvent, 5));
};


/**
 * @param {?proto.protobuf.collab.server.BoardEvent|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setBoardEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearBoardEvent = function() {
  return this.setBoardEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasBoardEvent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BoardFile board_file = 6;
 * @return {?proto.protobuf.collab.server.BoardFile}
 */
proto.protobuf.collab.server.Message.prototype.getBoardFile = function() {
  return /** @type{?proto.protobuf.collab.server.BoardFile} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.BoardFile, 6));
};


/**
 * @param {?proto.protobuf.collab.server.BoardFile|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setBoardFile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearBoardFile = function() {
  return this.setBoardFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasBoardFile = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ScreenShareEvent screen_share_event = 7;
 * @return {?proto.protobuf.collab.server.ScreenShareEvent}
 */
proto.protobuf.collab.server.Message.prototype.getScreenShareEvent = function() {
  return /** @type{?proto.protobuf.collab.server.ScreenShareEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.ScreenShareEvent, 7));
};


/**
 * @param {?proto.protobuf.collab.server.ScreenShareEvent|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setScreenShareEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearScreenShareEvent = function() {
  return this.setScreenShareEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasScreenShareEvent = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PinUpdated pin_updated = 8;
 * @return {?proto.protobuf.collab.server.PinUpdated}
 */
proto.protobuf.collab.server.Message.prototype.getPinUpdated = function() {
  return /** @type{?proto.protobuf.collab.server.PinUpdated} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.PinUpdated, 8));
};


/**
 * @param {?proto.protobuf.collab.server.PinUpdated|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setPinUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearPinUpdated = function() {
  return this.setPinUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasPinUpdated = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PublishBlob publish_blob = 9;
 * @return {?proto.protobuf.collab.server.PublishBlob}
 */
proto.protobuf.collab.server.Message.prototype.getPublishBlob = function() {
  return /** @type{?proto.protobuf.collab.server.PublishBlob} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.PublishBlob, 9));
};


/**
 * @param {?proto.protobuf.collab.server.PublishBlob|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setPublishBlob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearPublishBlob = function() {
  return this.setPublishBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasPublishBlob = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PublishAck publish_ack = 10;
 * @return {?proto.protobuf.collab.server.PublishAck}
 */
proto.protobuf.collab.server.Message.prototype.getPublishAck = function() {
  return /** @type{?proto.protobuf.collab.server.PublishAck} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.PublishAck, 10));
};


/**
 * @param {?proto.protobuf.collab.server.PublishAck|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setPublishAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearPublishAck = function() {
  return this.setPublishAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasPublishAck = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional RecallBlob recall_blob = 11;
 * @return {?proto.protobuf.collab.server.RecallBlob}
 */
proto.protobuf.collab.server.Message.prototype.getRecallBlob = function() {
  return /** @type{?proto.protobuf.collab.server.RecallBlob} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.RecallBlob, 11));
};


/**
 * @param {?proto.protobuf.collab.server.RecallBlob|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setRecallBlob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearRecallBlob = function() {
  return this.setRecallBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasRecallBlob = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Ping ping = 12;
 * @return {?proto.protobuf.collab.server.Ping}
 */
proto.protobuf.collab.server.Message.prototype.getPing = function() {
  return /** @type{?proto.protobuf.collab.server.Ping} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.Ping, 12));
};


/**
 * @param {?proto.protobuf.collab.server.Ping|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setPing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearPing = function() {
  return this.setPing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasPing = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Image image = 13;
 * @return {?proto.protobuf.collab.server.Image}
 */
proto.protobuf.collab.server.Message.prototype.getImage = function() {
  return /** @type{?proto.protobuf.collab.server.Image} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.Image, 13));
};


/**
 * @param {?proto.protobuf.collab.server.Image|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setImage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasImage = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional RequestPresenter request_presenter = 14;
 * @return {?proto.protobuf.collab.server.RequestPresenter}
 */
proto.protobuf.collab.server.Message.prototype.getRequestPresenter = function() {
  return /** @type{?proto.protobuf.collab.server.RequestPresenter} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.RequestPresenter, 14));
};


/**
 * @param {?proto.protobuf.collab.server.RequestPresenter|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setRequestPresenter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearRequestPresenter = function() {
  return this.setRequestPresenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasRequestPresenter = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional AnnouncePage announce_page = 15;
 * @return {?proto.protobuf.collab.server.AnnouncePage}
 */
proto.protobuf.collab.server.Message.prototype.getAnnouncePage = function() {
  return /** @type{?proto.protobuf.collab.server.AnnouncePage} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.AnnouncePage, 15));
};


/**
 * @param {?proto.protobuf.collab.server.AnnouncePage|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setAnnouncePage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearAnnouncePage = function() {
  return this.setAnnouncePage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasAnnouncePage = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional RewindEvent rewind_event = 16;
 * @return {?proto.protobuf.collab.server.RewindEvent}
 */
proto.protobuf.collab.server.Message.prototype.getRewindEvent = function() {
  return /** @type{?proto.protobuf.collab.server.RewindEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.RewindEvent, 16));
};


/**
 * @param {?proto.protobuf.collab.server.RewindEvent|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setRewindEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearRewindEvent = function() {
  return this.setRewindEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasRewindEvent = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional BatchEvent batch_event = 17;
 * @return {?proto.protobuf.collab.server.BatchEvent}
 */
proto.protobuf.collab.server.Message.prototype.getBatchEvent = function() {
  return /** @type{?proto.protobuf.collab.server.BatchEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.BatchEvent, 17));
};


/**
 * @param {?proto.protobuf.collab.server.BatchEvent|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setBatchEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearBatchEvent = function() {
  return this.setBatchEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasBatchEvent = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Images images = 18;
 * @return {?proto.protobuf.collab.server.Images}
 */
proto.protobuf.collab.server.Message.prototype.getImages = function() {
  return /** @type{?proto.protobuf.collab.server.Images} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.Images, 18));
};


/**
 * @param {?proto.protobuf.collab.server.Images|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setImages = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearImages = function() {
  return this.setImages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasImages = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ForceFollow force_follow = 19;
 * @return {?proto.protobuf.collab.server.ForceFollow}
 */
proto.protobuf.collab.server.Message.prototype.getForceFollow = function() {
  return /** @type{?proto.protobuf.collab.server.ForceFollow} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.ForceFollow, 19));
};


/**
 * @param {?proto.protobuf.collab.server.ForceFollow|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setForceFollow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearForceFollow = function() {
  return this.setForceFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasForceFollow = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional SetReadOnly set_read_only = 20;
 * @return {?proto.protobuf.collab.server.SetReadOnly}
 */
proto.protobuf.collab.server.Message.prototype.getSetReadOnly = function() {
  return /** @type{?proto.protobuf.collab.server.SetReadOnly} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.SetReadOnly, 20));
};


/**
 * @param {?proto.protobuf.collab.server.SetReadOnly|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setSetReadOnly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearSetReadOnly = function() {
  return this.setSetReadOnly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasSetReadOnly = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional YieldPresenter yield_presenter = 21;
 * @return {?proto.protobuf.collab.server.YieldPresenter}
 */
proto.protobuf.collab.server.Message.prototype.getYieldPresenter = function() {
  return /** @type{?proto.protobuf.collab.server.YieldPresenter} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.YieldPresenter, 21));
};


/**
 * @param {?proto.protobuf.collab.server.YieldPresenter|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setYieldPresenter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearYieldPresenter = function() {
  return this.setYieldPresenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasYieldPresenter = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional AutoSaveStatus auto_save_status = 22;
 * @return {?proto.protobuf.collab.server.AutoSaveStatus}
 */
proto.protobuf.collab.server.Message.prototype.getAutoSaveStatus = function() {
  return /** @type{?proto.protobuf.collab.server.AutoSaveStatus} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.AutoSaveStatus, 22));
};


/**
 * @param {?proto.protobuf.collab.server.AutoSaveStatus|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setAutoSaveStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearAutoSaveStatus = function() {
  return this.setAutoSaveStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasAutoSaveStatus = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional ThumbnailRequest thumbnail_request = 23;
 * @return {?proto.protobuf.collab.server.ThumbnailRequest}
 */
proto.protobuf.collab.server.Message.prototype.getThumbnailRequest = function() {
  return /** @type{?proto.protobuf.collab.server.ThumbnailRequest} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.server.ThumbnailRequest, 23));
};


/**
 * @param {?proto.protobuf.collab.server.ThumbnailRequest|undefined} value
 * @return {!proto.protobuf.collab.server.Message} returns this
*/
proto.protobuf.collab.server.Message.prototype.setThumbnailRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.protobuf.collab.server.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.server.Message} returns this
 */
proto.protobuf.collab.server.Message.prototype.clearThumbnailRequest = function() {
  return this.setThumbnailRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.server.Message.prototype.hasThumbnailRequest = function() {
  return jspb.Message.getField(this, 23) != null;
};


goog.object.extend(exports, proto.protobuf.collab.server);
