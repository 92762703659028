import { useApolloClient, useMutation } from '@apollo/client';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';

import * as analytics from 'client/common/analytics';
import { MUTATION_CREATE_FILE, CACHE_ID_FILES, CACHE_ID_RECENTS } from 'client/common/graphql';
import { ModalBackDrop } from 'client/components/Common/dialog-styled';

import * as Styled from './styled-upload';

const ERROR_TOO_MANY_BOARDS = 'too-many-boards';

interface Props {
  numberOfBoards: number;
  allowedToCreateFile: { allowed: boolean; limit?: number };
  onClose: () => void;
  deleteBoard: (boardId: string) => void;
}

const Upload: React.FC<Props> = ({ allowedToCreateFile: { allowed, limit }, deleteBoard, numberOfBoards, onClose }) => {
  const [createFile] = useMutation(MUTATION_CREATE_FILE);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({});
  const [fileStatus, setFileStatus] = useState<Record<string, { status: string; capped?: boolean }>>({});
  const [error, setError] = useState<string>();
  const cancellationTokens = useRef([]);
  const apolloClient = useApolloClient();

  const onDrop = useCallback(
    (
      acceptedFiles: {
        name: string;
      }[]
    ) => {
      if (limit && acceptedFiles.length + numberOfBoards > limit) {
        setError(ERROR_TOO_MANY_BOARDS);
        return;
      }

      setUploading(true);
      analytics.uploadBoard();

      acceptedFiles.forEach((file) => {
        setFileStatus((p) => ({
          ...p,
          [file.name]: { status: 'waiting', cancel: null },
        }));
        setProgress((p) => ({ ...p, [file.name]: 0 }));
      });

      acceptedFiles.forEach(async (fileToUpload) => {
        let fileName = fileToUpload.name;
        if (fileName.endsWith('.ffb')) {
          fileName = fileName.slice(0, -4);
        }
        const {
          data: {
            createFile: { uploadUri, file },
          },
        } = await createFile({ variables: { fileName } });

        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        setFileStatus((p) => ({
          ...p,
          [fileToUpload.name]: { status: 'waiting', cancel: source.cancel, file },
        }));
        cancellationTokens.current.push({
          file,
          cancel: source.cancel,
        });

        try {
          const result = await axios.put(uploadUri, fileToUpload, {
            headers: {
              'Content-Type': 'application/octet-stream',
            },
            cancelToken: source.token,
            onUploadProgress: (data) => {
              const percent = Math.round((data.loaded * 100) / data.total);
              setProgress((p) => ({ ...p, [fileToUpload.name]: percent }));
            },
          });

          if (result.status === 200) {
            setFileStatus((p) => ({
              ...p,
              [fileToUpload.name]: { status: 'done' },
            }));
            cancellationTokens.current = cancellationTokens.current.filter((f) => f.file.id !== file.id);
          } else {
            setFileStatus((p) => ({
              ...p,
              [fileToUpload.name]: { status: 'failed' },
            }));
            cancellationTokens.current = cancellationTokens.current.filter((f) => f.file.id !== file.id);
          }
        } catch (e) {
          if (e.toString() !== 'Cancel') {
            console.error(e);
          }
        }
      });
    },
    []
  );

  useEffect(() => {
    if (numberOfBoards === undefined || !allowed) {
      onClose();
    }

    return () => {
      if (Array.isArray(cancellationTokens.current)) {
        cancellationTokens.current.forEach((fs) => {
          const { cancel, file } = fs;

          if (cancel) {
            cancel();
          }

          if (file?.id) {
            deleteBoard(file.id);
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    const statuses = Object.values(fileStatus);

    if (statuses.length > 0 && statuses.length === statuses.filter((s) => s.status === 'done').length) {
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: CACHE_ID_FILES,
      });
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: CACHE_ID_RECENTS,
      });

      onClose();
    }
  }, [fileStatus]);

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.ffb',
  });

  const defaultView = (
    <>
      <img src="/images/ill_upload_file.svg" alt="Upload FlatFrog Board File" width={80} />
      <div>
        <h1>Upload from Computer</h1>
        <span>
          Drag and drop FlatFrog Board file or <strong>browse</strong>
        </span>
      </div>
    </>
  );

  const dropView = (
    <>
      <img src="/images/ill_upload_file.svg" alt="Upload FlatFrog Board File" width={80} />
      <div>
        <h1>Drop to upload file</h1>
      </div>
    </>
  );

  const rejectView = (
    <>
      <img src="/images/ill_upload_file_failed.svg" alt="Wrong file type" width={80} />
      <div>
        <h1>Only FlatFrog Board files (.ffb) are supported</h1>
        <span>
          Drag and drop FlatFrog Board file or <strong>browse</strong>
        </span>
      </div>
    </>
  );

  const tooManyView = (
    <>
      <img src="/images/ill_upload_file_failed.svg" alt="Too Many Boards" width={80} />
      <div>
        <h1>You have reached your limit of boards</h1>
        <span>
          You can only upload {(limit ?? 0) - numberOfBoards} more board
          {(limit ?? 0) - numberOfBoards > 1 && 's'}
        </span>
      </div>
    </>
  );

  // TODO: Add failed
  const progressView = (
    <>
      <img src="/images/ill_upload_file.svg" alt="Uploading file" width={80} />
      <Styled.ProgressArea>
        <Styled.ProgressBars>
          {Object.entries(progress).map((entry) => {
            const [fileName, percent] = entry;
            return (
              <div key={fileName}>
                <Styled.ProgressBar>
                  <Styled.Progress style={{ width: `${percent}%` }} />
                </Styled.ProgressBar>
                <Styled.ProgressFileName>Uploading {fileName}</Styled.ProgressFileName>
              </div>
            );
          })}
          {fileRejections.map((entry) => (
            <div key={entry.file.name}>
              <Styled.ProgressBar className="failed">
                <Styled.Progress style={{ width: `${100}%` }} />
              </Styled.ProgressBar>
              <Styled.ProgressFileName>{entry.file.name} is not a valid FlatFrog Board File</Styled.ProgressFileName>
            </div>
          ))}
        </Styled.ProgressBars>
      </Styled.ProgressArea>
    </>
  );

  const getView = () => {
    const reject = fileRejections.length > 0;

    if (!isDragActive && error === ERROR_TOO_MANY_BOARDS) {
      return tooManyView;
    }

    if (!reject && !isDragActive) {
      return defaultView;
    }
    if (reject && !isDragActive) {
      return rejectView;
    }
    if (isDragActive) {
      return dropView;
    }

    return defaultView;
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container>
        {fileStatus.length}
        {uploading && Object.entries(progress).length ? (
          progressView
        ) : (
          <Styled.DropArea {...getRootProps()}>
            <input {...getInputProps()} />
            {getView()}
          </Styled.DropArea>
        )}
        <Styled.CloseButton onClick={onClose} />
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default Upload;
