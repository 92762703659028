import { useLazyQuery } from '@apollo/client';
import * as microsoftTeams from '@microsoft/teams-js';
import GraphemeSplitter from 'grapheme-splitter';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import * as analytics from 'client/common/analytics';
import * as convert from 'client/common/convert';
import { getFirebaseAuth } from 'client/common/firebase';
import { QUERY_FILE_INFO } from 'client/common/graphql';
import getRole from 'client/common/userRole';
import { CONNECTION_STATUS, OFFICE_FILE_TYPES } from 'client/common/util';
import ActionsView from 'client/components/ActionsView';
import { TopBar } from 'client/components/App/styled';
import AppControls from 'client/components/AppControls';
import Import from 'client/components/Common/import';
import { ModalBackDrop } from 'client/components/Common/modal-styled';
import DebugView from 'client/components/DebugView';
import FeedbackDialog, { FeedbackDialogButton } from 'client/components/FeedbackDialog';
import FilterSearchField from 'client/components/FilterSearchField/FilterSearchField';
import ItemStack from 'client/components/ItemStack';
import MobileItemStack from 'client/components/ItemStack/MobileItemStack';
import MobileBar from 'client/components/MobileBar';
import Notifications from 'client/components/Notifications';
import PublishDropZone from 'client/components/PublishDropZone';
import SessionDisconnectedDialog from 'client/components/SessionDisconnectedDialog';
import SessionEndedDialog from 'client/components/SessionEndedDialog';
import SideBar from 'client/components/SideBar';
import StickyNoteEditor from 'client/components/StickyNoteEditor';
import TextBlockEditor from 'client/components/TextBlockEditor';
import BoardCodeBar from 'client/components/Whiteboard/BoardCodeBar';
import LoadingBoard from 'client/components/Whiteboard/LoadingBoard';
import Participants from 'client/components/Whiteboard/Participants';
import Whiteboard from 'client/components/Whiteboard/Whiteboard';
import ProgressBar from 'client/global-styles/progress-bar-style';
import GlobalStyle from 'client/global-styles/styled';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useEventListener from 'client/hooks/useEventListener';
import useLocalStorage from 'client/hooks/useLocalStorage';
import useLocalStorageState from 'client/hooks/useLocalStorageState';
import { useSelector } from 'client/hooks/useSelector';
import CollaborationSidePanel from 'client/components/CollaborationSidePanel';
import UwpWarning from 'client/components/Session/UwpWarning';
import { StartscreenBackground } from 'client/components/Home/styled';

import BoardInviteDialog from './BoardInviteDialog';
import { DebugContainer, SignUpPromo, TouchModeButton } from './styled';

function usePrevious(value: string) {
  const ref = useRef<string>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Session: React.FC = () => {
  const {
    isMsTeamsApp,
    isTeamsRoom,
    isViewOnlyMode,
    loadedFileInfo,
    room,
    session,
    showDisconnectDialog,
    showFeedbackModal,
    showInviteModal,
    showSessionEndedDialog,
    showStickerEditor,
    showStickyNoteEditor,
    showTextBlockEditor,
    startscreenVisibility,
  } = useSelector((state) => ({
    isMsTeamsApp: state.isMsTeamsApp,
    isTeamsRoom: state.isTeamsRoom,
    isViewOnlyMode: state.isViewOnlyMode,
    loadedFileInfo: state.loadedFileInfo,
    room: state.room,
    session: state.session,
    showDisconnectDialog: state.showDisconnectDialog,
    showFeedbackModal: state.showFeedbackModal,
    showInviteModal: state.showInviteModal,
    showItemStack: state.showItemStack,
    showSessionEndedDialog: state.showSessionEndedDialog,
    showStickerEditor: state.currentStickerEdit,
    showStickyNoteEditor: state.currentStickyNoteEdit,
    showTextBlockEditor: state.currentTextBlockEdit,
    startscreenVisibility: state.startscreenVisibility,
  }));

  const {
    addPaperToWhiteboardComponent,
    currentStickyNoteEdit,
    currentTextBlockEdit,
    enableClipBoarding,
    prepareAndPublishImage,
    setCurrentStickerEdit,
    setIsTeamsRoom,
    setIsViewOnlyMode,
    setShowInviteModal,
    setSignature,
    setUserRole,
    setZCanvasDebugMode,
    tryConnectWithSessionJoinKey,
    setMsTeamsApp,
  } = useActions();

  const [backDrop, setBackDrop] = useState('');
  const [, setAttendees] = useState(0);
  const [authTokenRefreshed, setAuthTokenRefreshed] = useState(false);
  const [filesToImport, setFilesToImport] = useState<File[]>([]);
  const [connecting, setConnecting] = useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showInviteAtCreation] = useLocalStorageState('showInviteAtCreation', true);
  const [hideGui, setHideGui] = useState(false);

  const auth = useAuth();
  const params = useParams<{ sessionJoinKey: string }>();
  const history = useHistory<{ from?: string; fromSession?: boolean; sessionJoinKey?: string; boardLink?: string }>();

  const { location } = history;
  const { state } = location;
  const [sessionReady, setSessionReady] = useState(!!state?.from);
  const [showCollaborationPanel, setShowCollaborationPanel] = useState(false);

  const [intendedRedirect, setIntendedRedirect] = useLocalStorage('intendedRedirect');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTouchEnabled, setIsTouchEnabled] = useLocalStorage('isTouchEnabled', false);

  useEffect(() => {
    analytics.viewPage('/session');
    return () => {
      analytics.setSession('');
    };
  }, []);

  useEffect(() => {
    enableClipBoarding('Session', true);
    return () => {
      enableClipBoarding('Session', false);
    };
  }, []);

  const refreshSignature = () => {
    let { displayName } = auth.user;

    if (displayName?.length > 0) {
      let signature = displayName.split(' ')[0];
      const graphemeSplitter = new GraphemeSplitter();
      if (graphemeSplitter.countGraphemes(signature) > 15) {
        const split = graphemeSplitter.splitGraphemes(signature);
        signature = split.slice(0, 16).join('');
      }
      setSignature(signature);
    } else {
      displayName = '';
      setSignature(displayName);
    }
  };

  const refreshToken = () => {
    const user = getFirebaseAuth().currentUser;
    user.getIdToken(true).then(() => {
      setAuthTokenRefreshed(true);
    });
  };

  useEffect(() => {
    // TODO can we check more stuff to know when this is needed and not always do it?
    if (auth.user) {
      refreshToken();

      (async () => {
        const r = await getRole();
        setUserRole(r.role);
      })();
    }
  }, [auth.user]);

  useEffect(() => {
    if (params.sessionJoinKey && !state?.from && !auth.loading) {
      if (authTokenRefreshed && auth.user) {
        refreshSignature();

        if (intendedRedirect) {
          setIntendedRedirect(null);
        }
        if (!connecting) {
          setConnecting(true);
          tryConnectWithSessionJoinKey(params.sessionJoinKey);
        }
      } else if (!authTokenRefreshed && (!auth.user || auth.user.guest)) {
        console.log('Redirect to join from /session');

        history.push('/join', {
          ...location.state,
          from: 'session',
          sessionJoinKey: params.sessionJoinKey,
        });
      }
    }
  }, [auth.user, auth.loading, authTokenRefreshed]);

  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting) {
      setSessionReady((ready) => {
        if (!ready) {
          analytics.validSession(!!auth.user);
        }
        return true;
      });
    }
  }, [session]);

  useEffect(() => {
    setAttendees((lastCount) => {
      const count = session.clients?.length ?? 0;
      if (count > lastCount) {
        analytics.attendees(count);
      }
      return count;
    });
  }, [session.clients]);

  const prevName = usePrevious(loadedFileInfo?.fileName);

  useEffect(() => {
    if (
      (loadedFileInfo?.new || loadedFileInfo?.template) &&
      loadedFileInfo?.fileName &&
      !prevName &&
      showInviteAtCreation &&
      !room
    ) {
      setShowInviteDialog(true);
    }
  }, [loadedFileInfo?.new, loadedFileInfo?.template, loadedFileInfo?.fileName]);

  const [getFileInfo, { data: fileData }] = useLazyQuery(QUERY_FILE_INFO);

  useEffect(() => {
    if (loadedFileInfo?.id) {
      getFileInfo({
        variables: { fileId: loadedFileInfo.id },
      });
    }
  }, [loadedFileInfo?.id]);

  const hideBackDrop = () => {
    currentStickyNoteEdit(null);
    currentTextBlockEdit(null);
    setCurrentStickerEdit();
  };

  const handleResize = () => {
    if (isMobileOnly) {
      return;
    }
    const { innerWidth } = window;
    const shouldHide = innerWidth < 400;

    if (shouldHide !== hideGui) {
      setHideGui(shouldHide);
    }
  };

  useEventListener('resize', handleResize);

  const handleEnableTouch = () => {
    setIsViewOnlyMode(false);
    setIsTouchEnabled(true);
  };

  // TODO: #ISE Commented for ISE demos
  // useEffect(() => {
  //   if (isTouchEnabled) {
  //     (setIsViewOnlyMode(false));
  //   }
  // }, []);

  useLayoutEffect(() => {
    if (!isMobileOnly) {
      handleResize();
    }
  }, []);

  useHotkeys(
    'esc',
    () => {
      hideBackDrop();
    },
    { keyup: true, keydown: false }
  );

  useHotkeys(
    'shift+ctrl+*',
    (e) => {
      for (let n = 0; n < 3; n += 1) {
        if (e.code === `Digit${n}`) {
          setZCanvasDebugMode(n);
        }
      }
    },
    { keyup: true, keydown: false }
  );

  useHotkeys('c', () => setShowCollaborationPanel(!showCollaborationPanel), {}, [showCollaborationPanel]);

  // Close menus when clicking anywhere
  useEventListener(
    'pointerdown',
    (e) => {
      window.dispatchEvent(new CustomEvent('closemenus', { detail: e.target }));
    },
    document
  );

  useEffect(() => {
    if (showStickyNoteEditor || showTextBlockEditor || showStickerEditor) {
      setBackDrop('active invisible');
    } else {
      setBackDrop('');
    }
  }, [showStickyNoteEditor, showTextBlockEditor, showStickerEditor]);

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext((context) => {
      setMsTeamsApp();
      if (context?.hostClientType === 'teamsRoomsWindows' || context?.hostClientType === 'teamsRoomsAndroid') {
        setIsTeamsRoom();
      }
    });
  }, []);

  if (!sessionReady) {
    return <LoadingBoard failure={session.failure} />;
  }

  // If we go to /session while we haven't set up a connection
  // then stop rendering before ZCanvas kicks in (to avoid errors in console) and go to start
  if (!session.id && !params.sessionJoinKey && !showDisconnectDialog.show && !showSessionEndedDialog.show) {
    if (room) {
      history.replace('/room');
    } else {
      history.replace('/dashboard');
    }
    return null;
  }

  const allowedToEditFilename =
    showInviteDialog || // shown at board creation
    fileData?.file.user.uid === auth?.user?.uid || // your file
    fileData?.file.team?.members.some((m) => m.uid === auth?.user?.uid); // in a ws you're a member of

  return (
    <>
      {false && !isMobileOnly && !isViewOnlyMode && (
        <>
          <DebugContainer>
            <DebugView />
          </DebugContainer>
          <ActionsView />
        </>
      )}
      {!isMobileOnly && !isViewOnlyMode && <FilterSearchField ready={sessionReady} />}
      <GlobalStyle />
      <ProgressBar />
      <ModalBackDrop className={backDrop} onClick={hideBackDrop} />
      <SessionEndedDialog />
      <SessionDisconnectedDialog />
      {!isMobileOnly && !isViewOnlyMode && (
        <ReactTooltip effect="solid" className="tooltips" html={true} delayShow={500} />
      )}
      {!isMobileOnly && !isViewOnlyMode && (
        <TopBar>
          <AppControls hidden={hideGui || isTeamsRoom} />
        </TopBar>
      )}
      <PublishDropZone signedIn={!!auth.user} importFiles={setFilesToImport}>
        <Whiteboard hideGui={hideGui} />
        {room && startscreenVisibility === 'visible' && <StartscreenBackground />}
        {!isMobileOnly && !isViewOnlyMode && <SideBar hidden={hideGui} />}
        {!isMobileOnly && !isViewOnlyMode && !auth.user && !isMsTeamsApp && !room && (
          <SignUpPromo>
            Need access to the board later?{' '}
            <Link
              to={{
                pathname: '/login',
                state: {
                  from: 'session',
                  sessionJoinKey: session.joinKey,
                  boardLink: state?.boardLink,
                },
              }}
            >
              Click here to sign up now
            </Link>
          </SignUpPromo>
        )}
      </PublishDropZone>
      {isMobileOnly && <MobileBar />}
      {isViewOnlyMode && <BoardCodeBar />}
      {isViewOnlyMode && <TouchModeButton onClick={handleEnableTouch}>Enable Touch Mode</TouchModeButton>}
      {room && <BoardCodeBar room onClick={() => setShowCollaborationPanel(true)} />}
      {!isMobileOnly && !room && <UwpWarning />}
      {!isMobileOnly && !isViewOnlyMode && !hideGui && (
        <Participants showCollaborationPanel={() => setShowCollaborationPanel(true)} />
      )}
      <CollaborationSidePanel show={showCollaborationPanel} hide={() => setShowCollaborationPanel(false)} />
      <Notifications hidden={hideGui} />
      {!isMobileOnly ? <ItemStack hidden={hideGui || room} /> : <MobileItemStack hidden={hideGui} />}
      {!isMobileOnly && !isViewOnlyMode && !room && <FeedbackDialogButton hidden={hideGui} />}
      {!isMobileOnly && !isViewOnlyMode && <StickyNoteEditor />}
      {!isMobileOnly && !isViewOnlyMode && <TextBlockEditor />}
      {showFeedbackModal && <FeedbackDialog />}
      {(showInviteDialog || showInviteModal) && (
        <BoardInviteDialog
          fileName={loadedFileInfo.fileName}
          fileId={loadedFileInfo.id}
          sessionJoinKey={session.joinKey}
          editDefault={!showInviteModal}
          editMode={allowedToEditFilename}
          isMsTeamsApp={isMsTeamsApp}
          onCancel={() => {
            setShowInviteDialog(false);
            setShowInviteModal(false);
          }}
          onNext={() => {
            setShowInviteDialog(false);
            setShowInviteModal(false);
          }}
        />
      )}
      {filesToImport.length > 0 && (
        <Import
          session={session}
          filesToImport={filesToImport}
          uploadFile={async (file, cancelledRef, progressCallback, doneCallback, margins) => {
            if (file.type.match('^image/(png|jpg|jpeg)')) {
              const maxSize = {
                width: margins.right - margins.left,
                height: margins.bottom - margins.top,
              };
              const position = {
                x: margins.left + 0.5 * maxSize.width,
                y: 2160.0 - (margins.top + 0.5 * maxSize.height),
              };
              prepareAndPublishImage({
                file,
                options: {
                  selectPaper: true,
                  position,
                  maxSize,
                  coordsInWritingArea: true,
                },
              });
              analytics.addImage('drop');
              doneCallback();
            }
            if (file.type.match('^application/pdf')) {
              try {
                const pages = await convert.pdfDocumentToImages(
                  file,
                  addPaperToWhiteboardComponent,
                  progressCallback,
                  cancelledRef,
                  margins
                );
                analytics.importFile(file.type, 'drop', pages, file.size / 1024);
                doneCallback();
              } catch (e) {
                analytics.importFailed(file.type, file.size / 1024);
                doneCallback(e);
              }
            } else if (file.type.match(OFFICE_FILE_TYPES)) {
              try {
                const pages = await convert.officeFileToImages(
                  file,
                  addPaperToWhiteboardComponent,
                  progressCallback,
                  cancelledRef,
                  margins
                );
                analytics.importFile(file.type, 'drop', pages, file.size / 1024);
                doneCallback();
              } catch (e) {
                analytics.importFailed(file.type, file.size / 1024);
                doneCallback(e);
              }
            }
          }}
          onClose={() => setFilesToImport([])}
        />
      )}
    </>
  );
};

export default Session;
