import ZCanvas from '@flatfrog/ffbec';

export const trashPaper = (paper: { paperId: number; removePaper: boolean }) => {
  // When a paper is trashed we need to clean up associated items and make sure the paper is deselected
  const { paperId, removePaper } = paper;
  ZCanvas.paper.deselect(paperId);
  // Sometimes trash paper is triggered by a remotely deleted paper,
  // so only remove the paper if it is required
  if (removePaper) {
    ZCanvas.paper.remove(paperId);
  }
};

export const trashPapers = (paperIds: number[]) => {
  paperIds.reverse();
  paperIds.forEach((paperId) => {
    ZCanvas.paper.deselect(paperId);
  });
  ZCanvas.beginCommandGroup();
  paperIds.forEach((paperId) => {
    ZCanvas.paper.remove(paperId);
  });
  ZCanvas.endCommandGroup();
};

export const trashSelectedPapers = () => {
  const selectedPaperIds = ZCanvas.paper.getSelectedIds();
  for (const paperId of selectedPaperIds) {
    ZCanvas.paper.deselect(paperId);
  }
  ZCanvas.paper.removeMultiple(selectedPaperIds);
};

export const getPageActionsAndInkImages = (pageId: number) => {
  const pageActions = ZCanvas.ffb.getCompressedPageActions(pageId);

  const inkImages = pageActions
    .filter(
      (action) =>
        action.Action === 'CreatePaperAction' &&
        'PaperForegroundFileName' in action.PaperInfo &&
        action.PaperInfo.PaperForegroundFileName != null &&
        ZCanvas.ffb.hasJsonObjectForInkImage(action.PaperInfo.PaperForegroundFileName)
    )
    .reduce<Record<string, { Data: string }>>((map, action) => {
      map[action.PaperInfo.PaperForegroundFileName] = ZCanvas.ffb.getJsonObjectForInkImage(
        action.PaperInfo.PaperForegroundFileName
      );
      return map;
    }, {});

  return { actions: pageActions, inkImages };
};

export const getPaperActionsAndInkImages = (paperIds: number[]) => {
  const paperActions = ZCanvas.ffb.getCompressedPaperActions(paperIds);

  const inkImages = paperActions
    .filter(
      (action) =>
        action.Action === 'CreatePaperAction' &&
        'PaperForegroundFileName' in action.PaperInfo &&
        action.PaperInfo.PaperForegroundFileName != null &&
        ZCanvas.ffb.hasJsonObjectForInkImage(action.PaperInfo.PaperForegroundFileName)
    )
    .reduce<Record<string, unknown>>((map, action) => {
      map[action.PaperInfo.PaperForegroundFileName] = ZCanvas.ffb.getJsonObjectForInkImage(
        action.PaperInfo.PaperForegroundFileName
      );
      return map;
    }, {});

  return { actions: paperActions, inkImages };
};
