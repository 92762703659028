import { isMobileOnly } from 'react-device-detect';
import ZCanvas from '@flatfrog/ffbec';
import { ZActions } from '@flatfrog/ffbec/js/zwasm';

const hexToRgb = (hex: string): [number, number, number] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

// This decides the order of the colors, so don't change them up unless needed
// Name is there in case someone wants tooltips, certain people likes tooltips
export const COLORS = [
  { name: 'Dark Gray', iconSuffix: 'gray_dark', hex: '#242424', default: true, roomBrushIcon: 'black1' },
  { name: 'Dark Blue', iconSuffix: 'blue_dark', hex: '#0a53db', default: true, roomBrushIcon: 'blue1' },
  { name: 'Blue Magenta', iconSuffix: 'blue_magenta', hex: '#db07b3', default: true, roomBrushIcon: 'magenta1' },
  { name: 'Green 1', iconSuffix: 'green1', hex: '#01c270', default: true, roomBrushIcon: 'green1' },
  { name: 'Red', iconSuffix: 'red', hex: '#df0b0b', default: true, roomBrushIcon: 'red1' },
  { name: 'Yellow', iconSuffix: 'yellow', hex: '#dddf0a', default: true, roomBrushIcon: 'yellow1' },

  { name: 'Gray', iconSuffix: 'gray', hex: '#6b6b6b', default: false },
  { name: 'Green 2', iconSuffix: 'green2', hex: '#00c2a5', default: false },
  { name: 'Light Blue', iconSuffix: 'blue_light', hex: '#0887db', default: false },
  { name: 'Light Gray', iconSuffix: 'gray_light', hex: '#b5b5b5', default: false },
  { name: 'Green 3', iconSuffix: 'green3', hex: '#44c208', default: false },
  { name: 'Orange', iconSuffix: 'orange', hex: '#de970c', default: false },
];

/* pointerSettings widths are given in writing area coordinates for a 100%
 * zoomed canvas with the correct aspect ratio. Typically this correspond
 * to a 4K resolution (3840 x 2160). */
export const STROKE_SIZES = [0.83, 1.67, 3.33, 6.67, 13.3]; /* FFB-122: [2, 4, 8, 16, 32] / 2.4; */
export const ERASE_SIZE = 20; /* FFB-122: 2 * 24 / 2.4; */
export const PEN_ERASE_SIZE = 10; /* FFB-122: 2 * 12 / 2.4 */
export const PALM_ERASE_SIZE = 100; /* FFB-122: 2 * 120 / 2.4; */
export const DEFAULT_SIZE_INDEX = 1;

export const setDefaultTooling = (isRoom = false) => {
  // Default thickness for pen
  ZCanvas.input.pointerSettings.pen.stroke.draw.width = STROKE_SIZES[DEFAULT_SIZE_INDEX];
  ZCanvas.input.pointerSettings.passivePen.stroke.draw.width = STROKE_SIZES[DEFAULT_SIZE_INDEX]; /* ZTOUCH */
  ZCanvas.input.pointerSettings.passivePen.stroke.erase.width = PEN_ERASE_SIZE; /* ZTOUCH */
  // ZCanvas.input.pointerSettings.passivePenEraser.stroke.draw.width = STROKE_SIZES[DEFAULT_SIZE_INDEX]; /* ZTOUCH */
  ZCanvas.input.pointerSettings.passivePenEraser.stroke.erase.width = PEN_ERASE_SIZE; /* ZTOUCH */
  ZCanvas.input.pointerSettings.touch.stroke.erase.width = ERASE_SIZE;

  if (isRoom) {
    ZCanvas.input.pointerSettings.pen.stroke.erase.width = PALM_ERASE_SIZE;
    ZCanvas.input.pointerSettings.mouse.action = ZActions.DRAW;
  } else {
    ZCanvas.input.pointerSettings.pen.stroke.erase.width = ERASE_SIZE;
  }

  const rgb = hexToRgb(COLORS[0].hex);
  ZCanvas.input.pointerSettings.mouse.stroke.draw.color = rgb;
  ZCanvas.input.pointerSettings.pen.stroke.draw.color = rgb;
  ZCanvas.input.pointerSettings.passivePen.stroke.draw.color = rgb;
  // ZCanvas.input.pointerSettings.passivePenEraser.stroke.draw.color = rgb;

  if (isMobileOnly) {
    console.log('Mobile', 'setting PANZOOM as action');
    ZCanvas.input.pointerSettings.touch.action = ZActions.PANZOOM;
    ZCanvas.input.pointerSettings.mouse.action = ZActions.PANZOOM;
    ZCanvas.input.pointerSettings.pen.action = ZActions.PANZOOM;
    ZCanvas.input.pointerSettings.passivePen.action = ZActions.PANZOOM;
    ZCanvas.input.pointerSettings.passivePenEraser.action = ZActions.PANZOOM;
  }
};

export const setMargins = (noMargins: boolean) => {
  if (noMargins) {
    ZCanvas.writingAreaZoomMarginsSet(true, 0, 0, 0, 0);
  } else if (isMobileOnly) {
    ZCanvas.writingAreaZoomMarginsSet(true, 8, 8, 8, 8);
  } else {
    ZCanvas.writingAreaZoomMarginsSet(true, 12, 72, 12, 72);
  }
};

const configure = (noMargins: boolean, isRoom = false) => {
  ZCanvas.writingAreaZoomLimitsSet(true, 1, 1, 1.25, 4);
  setMargins(noMargins);
  setDefaultTooling(isRoom);
};

export default configure;
