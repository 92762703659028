import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ModalContainer, ModalBackDrop, PrimaryButton, Button, ButtonContainer } from './dialog-styled';

interface Props {
  cover: boolean;
  showOverflow?: boolean;
  noCloseButton?: boolean;
  rightAlign?: boolean;
  noButtons?: boolean;
  cancelDisabled?: boolean;
  noCancelButton?: boolean;
  nextDisabled?: boolean;
  cancelText?: string;
  nextText?: string;
  onClose?: () => void;
  onCancel?: () => void;
  onNext?: () => void;
}

const Dialog: React.FC<Props> = ({
  cancelDisabled,
  cancelText,
  children,
  cover,
  nextDisabled,
  nextText,
  noButtons,
  noCancelButton,
  noCloseButton,
  onCancel,
  onClose,
  onNext,
  rightAlign,
  showOverflow,
}) => {
  const container = useRef<HTMLDivElement>();

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop />
      <ModalContainer className={[cover ? 'cover' : '', showOverflow ? 'show-overflow' : ''].join(' ')} ref={container}>
        {noCloseButton ? null : <button className="close" onClick={onClose ?? onCancel} />}
        {children}
        {noButtons ? null : (
          <ButtonContainer className={rightAlign ? 'right-align' : ''}>
            {noCancelButton ? null : (
              <Button onClick={onCancel} disabled={cancelDisabled}>
                {cancelText}
              </Button>
            )}
            <PrimaryButton onClick={onNext} disabled={nextDisabled}>
              {nextText}
            </PrimaryButton>
          </ButtonContainer>
        )}
      </ModalContainer>
    </>,
    document.getElementById('modal-portal')
  );
};

export default Dialog;
