import styled from 'styled-components';

import { NextButton } from 'client/components/Common/dialog-styled';
import { SwitchContainer } from 'client/components/Common/toggleswitch-styled';

export const Wrapper = styled.div`
  width: 360px;
  color: #242424;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translate(100%, 0);
  transition: transform 300ms ease-in-out;

  &.show {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.1);
    transform: translate(0, 0) !important;
  }

  &.room {
    padding-top: 40px;
  }

  img {
    -webkit-user-drag: none;
  }
`;

export const Header = styled.div`
  height: 56px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0 12px 0 16px;

  img {
    margin-right: 8px;
  }

  button.close {
    margin-left: auto;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background: url(/icons/icn_close_small.svg) center center no-repeat;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`;

export const PresenterFollowerControlWrapper = styled.div`
  flex-shrink: 0;
  padding: 0 8px 8px 8px;

  &.room:not(.alone) {
    order: 2;
    margin-top: auto;
  }
`;

export const AloneImage = styled.img`
  margin: 16px auto 0 auto;
  width: 176px;
`;

export const AloneWrapper = styled.div`
  padding: 16px 40px 4px 40px;
  text-align: center;

  &.room:not(.alone) {
    order: -2;
    padding: 0;

    h2.room {
      margin: 0;
    }
  }

  h2 {
    color: #242424;
    font-weight: 400;
    padding: 0;
    margin: 24px 0 0 0;
    font-size: 16px;

    &.room {
      font-size: 18px;
      font-weight: 400;
      color: #8c8c8c;

      b {
        color: #242424;
      }
    }
  }

  p {
    font-size: 13px;
    color: #8c8c8c;
    font-weight: 500;
    margin: 8px 0 0 0;
  }
`;

export const InviteWrapper = styled.div`
  height: 197px;
  flex-shrink: 0;
  min-height: 197px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.room:not(.alone) {
    min-height: 0;
    height: auto;
    order: -1;
  }
`;

export const ParticipantScrollArea = styled.div`
  margin-right: -20px;
  overflow-y: scroll;
  padding: 0 36px 0 16px;

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
  }

  &.overflown + ${InviteWrapper} {
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.2);
  }
`;

export const PresenterMarker = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  font-size: 11px;
  color: #ffffff;
  background: #279ae7;
  box-shadow: 0 0 0 2px #fff;
  line-height: 16px;

  &:after {
    content: 'P';
  }
`;

export const NoPresenter = styled.div`
  align-items: center;
  background: #c3c3c322;
  background: transparent url(/icons/icn_nopresenter.svg) center center no-repeat;
  border-color: #c3c3c3;
  border-radius: 50%;
  border: 2px solid #c3c3c3;
  color: #c3c3c3;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  position: relative;
  text-align: center;
  user-select: none;
  width: 32px;
`;

export const ParticipantListWrapper = styled.div``;

export const ParticipantListInnerWrapper = styled.div`
  margin-right: -20px;
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-right: 20px;
  scrollbar-width: none;

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const Name = styled.span`
  color: #242424;
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  margin-left: 8px;
  display: block;

  strong {
    font-weight: 600;
  }

  &.loading {
    opacity: 0.3;
  }
`;

export const Lock = styled.span`
  width: 24px;
  height: 20px;
  margin-left: auto;

  &.active {
    background: url(/icons/icn_lock_pages_header.svg) center center no-repeat;
    opacity: 1;
  }

  &.list {
    background: url(/icons/icn_lock_pages_user.svg) center center no-repeat;
  }
`;

export const Page = styled.span`
  margin-left: auto;
  width: 24px;
  height: 20px;
  background: transparent center center no-repeat;
  background-size: 24px;
  color: #8c8c8c;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 2px;
  padding: 0 4px;
  cursor: default;

  &.follow {
    color: #8c8c8c;
  }

  &.presenter {
    color: #242424;
    font-weight: 600;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.span`
  color: #8c8c8c;
  margin: 8px 0;
  height: 24px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;

  & > &,
  & > ${Lock} {
    margin: 0 0 0 auto;
  }
`;

export const PhoneIcon = styled.div`
  display: block;
  height: 16px;
  margin: 0 6px;
  width: 10px;
  background: url(/icons/icn_device_phone.svg) center center no-repeat;
  background-size: cover;
`;

export const ParticipantListItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  height: 40px;
`;

export const CopyButton = styled.button`
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #279ae7;
  color: #2287ca;
  font-size: 10px;
  font-weight: 600;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  background: transparent;

  &:hover {
    background-color: #279ae7;
    color: #ffffff;
  }
`;

export const ShareRow = styled.div`
  display: flex;
  padding: 8px;
  font-size: 13px;
  align-items: center;
`;

export const ShareRowIcon = styled.img`
  width: 16px;
  margin-right: 8px;
`;

export const HorizontalSeparator = styled.span`
  display: block;
  position: relative;
  height: 1px;
  background: rgba(0, 0, 0, 0.16);
`;

export const CollaborationControls = styled.div`
  padding: 8px ${HorizontalSeparator} {
    margin: 8px 0;
  }
`;

export const PresentButton = styled.button.attrs({
  type: 'button',
})`
  cursor: pointer;
  width: 84px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #279ae7;
  color: #279ae7;
  font-size: 13px;
  font-weight: 600;
  background-color: transparent;

  &:hover,
  &.stop-presenting {
    background-color: #279ae7;
    color: #fff;
  }
`;

export const LockedButton = styled(PresentButton)`
  border-color: #c3c3c3;
  background: transparent url('/icons/icn_lock_pages_user.svg') center center no-repeat;

  &:hover {
    background-color: transparent;
  }
`;

export const TransitionWrapper = styled.div`
  height: 0;
  overflow: hidden;
  animation: show--collaboration-controls 300ms forwards ease-out;

  &.alone {
    animation: none;
    height: auto;
  }

  @keyframes show--collaboration-controls {
    from {
      height: 0;
    }
    to {
      height: 51px;
    }
  }
`;

export const CollaborationControlsRow = styled(ShareRow)`
  user-select: none;

  ${PresentButton}, ${SwitchContainer} {
    margin-left: auto;

    > span {
      margin: 0;
    }
  }

  > span {
    position: relative;
    padding-left: 26px;
    background: url(/icons/icn_lock_pages_inactive_settings.svg) center left 2px no-repeat;

    &.on {
      background: url(/icons/icn_lock_pages_active_settings.svg) center left 2px no-repeat;
    }

    &.presenter {
      background: none;

      &:before {
        content: 'P';
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        background: #279ae7;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
`;

export const PresenterInfo = styled.div`
  margin-left: 8px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  strong,
  span {
    display: block;
  }

  strong {
    font-weight: 600;
  }
`;

export const BoardCode = styled.div`
  font-size: 26px;
  height: 48px;
  border-radius: 24px;
  line-height: 48px;
  width: 180px;
  background-color: #f5f6f7;
  transition: background-color 300ms ease-out;
  padding: 0 25px;
`;

export const BoardCodeContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.alone {
    position: inherit;
    margin-top: 48px;
  }

  &.room {
    margin-top: 12px;
    position: relative;

    ${BoardCode} {
      font-size: 30px;
      font-weight: 400;
      height: 56px;
      line-height: 56px;
      border-radius: 28px;
      cursor: pointer;

      &:hover {
        background-color: #e8e9eb;
      }

      &:active {
        transform: scale(0.92);
      }
    }
  }

  p {
    color: #8c8c8c;
    font-size: 11px;
  }

  h3 {
    font-size: 13px;
    font-weight: 400;
    margin: 16px 0;
  }
`;

export const InviteButton = styled(NextButton)`
  height: 48px;
  border-radius: 24px;
  font-size: 13px;
  width: 180px;
  box-shadow: 0px 4px 12px rgba(39, 154, 231, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease-out;

  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #2287ca;
  }

  &:active {
    transform: scale(0.92);
    box-shadow: 0px 2px 6px rgba(39, 154, 231, 0.4);
  }
`;

export const LargeBordCode = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 20vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
`;

export const BoardQRCode = styled.div`
  display: flex;
  object-fit: contain;
  background-color: white;
  border-radius: 1.333vh;
  margin: 1vh 0;
  width: 50%;
  max-width: 150px;
`;
