import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const TopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 64px;
  background: #fff;
  border: 0.5px solid #00000014;
  user-select: none;
  font-size: 13px;
  font-weight: 600;
  color: #242424;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NavGroup = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 33%;
`;

export const UserGroup = styled.div`
  margin: 0 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const BoardCodeGroup = styled.div`
  width: 33%;
  max-width: 480px;
  min-width: 280px;
  margin: 8px 0;
  position: relative;
`;

export const Logo = styled(NavLink)`
  background: url(/images/logo_flatfrogboard.svg) center center no-repeat;
  margin: 0 16px;
  width: 100px;
`;

export const Link = styled(NavLink)`
  color: #242424;
  padding: 0 16px;
  display: flex;
  align-items: center;
  margin: auto 2px;
  text-align: center;
  vertical-align: middle;
  height: 40px;
  cursor: pointer;
  border-radius: 40px;
  transition: background-color 200ms ease-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const Home = styled(Link)`
  background: url(/icons/icn_home.svg) center center no-repeat;
  width: 54px;
`;

type CodeInputProps = { isFocused?: boolean; placeholder?: string; useSmallFont?: boolean };
export const CodeInput = styled.input.attrs<CodeInputProps>((props) => ({
  type: 'text',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  maxLength: 7,
  spellCheck: false,
  placeholder: props.isFocused ? '——— ———' : props.placeholder ?? 'Enter Board Code to Join',
}))<CodeInputProps>`
  border: 1.5px solid #00000029;
  border-radius: 30px;
  background: #fff;
  height: 48px;
  width: 100%;
  font-size: ${(props) => (props.useSmallFont ? '13px' : '16px')};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 6.2px;
  text-align: center;
  transition: border-color 150ms ease-in-out;
  color: #8c8c8c;
  display: block;
  outline: none;

  &::placeholder {
    color: #8c8c8c;
    opacity: 0.5;
    font-weight: 400;
    text-transform: none;
    letter-spacing: ${(props) => (props.isFocused ? '6.2px' : 'normal')};
  }

  &:focus {
    border-color: #279ae7;
    background: #fafafa;
    color: #242424;
  }

  &.error {
    background: #df0b0b29;
    border-color: #df0b0b29;
  }
`;

export const BoardCodeHint = styled.div`
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: transparent url(/icons/icn_help_tooltip.svg) center center no-repeat;
  background-size: 16px 16px;
  border-radius: 50%;
  transition: background-color 300ms ease-out;

  &:hover {
    background-color: #00000014;
  }

  &.typing {
    display: none;
  }

  &.valid {
    background-image: url(/icons/icn_returnkey.svg);
  }
`;

export const UserName = styled.div`
  padding: 0 8px;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UserNameWithTenant = styled(UserName)`
  margin-top: -6px;
  text-align: right;
  > span {
    font-weight: 400;
    display: block;
    line-height: 16px;
    font-size: 10px;
    margin-top: -8px;
  }
`;

export const TeamBubble = styled.div`
  position: relative;
  padding: 14px;
  border: 2px solid #00000026;
  color: ${(p) => (p.color ? p.color : '#00000026')};
  border-color: ${(p) => (p.color ? p.color : '#00000026')};
  background: ${(p) =>
    p.color ? `${p.color}22` : 'rgba(0,0,0,0.08) url(/icons/icn_shared.svg) center center no-repeat'};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 300ms ease-out, border-color 300ms ease-out, background-color 300ms ease-out;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border-width: 0;
  font-weight: 600;
  font-size: 18px;
`;

export const ScrollArea = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;

export const BackButton = styled.div`
  display: block;
  position: fixed;
  top: 100px;
  right: calc(min(96% - 40px, 50% + 424px) + 16px);
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: transparent url(/icons/icn_back_page.svg) center center no-repeat;
  background-size: 16px 16px;
  border-radius: 50%;
  transition: background-color 300ms ease-out;

  @media only screen and (max-width: 848px) {
    top: 70px;
    right: calc(96% - 40px + 16px);
  }

  &:hover {
    background-color: #00000014;
  }
`;

export const MainColumn = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 96%;
  max-width: 848px;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  color: #242424;
  user-select: none;
  overflow-x: visible;
`;

export const GroupHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 16px 0;
`;

export const MyBoardsGroupHeader = styled(GroupHeader)`
  margin-bottom: 32px;
  align-items: center;
  margin-top: 36px;
`;

export const HeaderTitle = styled.div`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 600;

  &.back {
    &::before {
      content: '';
    }
  }
`;

export const HeaderMoreButton = styled.div`
  display: flex;
  font-size: 13px;
  color: #2287ca;
  font-weight: 600;
  border-radius: 20px;
  transition: background-color 300ms ease-out;
  margin-left: 32px;

  &.disabled {
    a,
    span {
      color: #242424;
      opacity: 0.6;
      pointer-events: none;
    }

    &:hover {
      background-color: transparent;
    }
  }

  a,
  span {
    padding: 7px 16px 7px 16px;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
      margin-left: -8px;
      margin-right: 8px;
    }
  }

  &:hover {
    background-color: #279ae714;
  }
`;

export const HeaderExpander = styled.div`
  width: 1px;
  height: 24px;
  margin: auto auto;
`;

export const GroupDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 40px;
  background-color: #00000014;
`;

export const CreateBoardGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CreateBoardItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const CreateBoardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(25% - 8px));
  grid-auto-flow: dense;
  grid-gap: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  margin: 8px 0 0 0;

  &.disabled {
    opacity: 0.6;
    filter: grayscale();

    ${CreateBoardItem} {
      pointer-events: none;
    }
  }
`;

export const CreateBoardThumbnail = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  cursor: pointer;
  transition: box-shadow 300ms ease-out;
  background-size: cover;

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }

  &:hover {
    box-shadow: 0 0 0 4px #00000014, 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  }
`;

export const CreateBoardBlank = styled(CreateBoardThumbnail)`
  background: #fff url(/icons/icn_plus_green.svg) center center no-repeat;
  background-size: 16px 16px;
  box-shadow: 0 0 0 4px #63c199, 0 2px 4px #00000014, 0 0 0 0.5px #00000014;

  &:hover {
    box-shadow: 0 0 0 4px #47a37c, 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  }
`;

export const CreateBoardLabel = styled.div`
  margin: 12px auto;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  padding: 0 0.5em;
  cursor: pointer;
`;

export const PromotionGroup = styled.div`
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
`;

export const PromotionCard = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  border-radius: 16px;
`;

export const PromotionVideo = styled.div`
  flex: 0 0 320px;
  position: relative;

  & > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px 0 0 16px;
  }
`;

export const PromotionText = styled.div`
  flex: 1 1 auto;
  padding: 24px 48px;
`;

export const PromotionHeader = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const PromotionBody = styled.div`
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 24px;
`;

const RoomAppLink = css`
  display: inline-block;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
`;

export const RoomAppDownloadLink = styled(NavLink)`
  ${RoomAppLink}
`;

export const RoomAppReadMoreLink = styled.a`
  ${RoomAppLink}
`;

export const PromotionCloseButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  background: transparent url(/icons/icn_close_small.svg) center center no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  background-size: 12px;
  border-radius: 4px;
`;

export const RecentBoardsGroup = styled.div`
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
`;

export const ClickGuard = styled.div`
  position: relative;
  margin: -12px -16px -12px 0;
  padding: 12px 0 12px 0;
  pointer-events: all;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const CastButton = styled.span`
  width: 32px;
  height: 32px;
  background: url(/icons/icn_open_in_room.svg) center center no-repeat;
  background-size: 18px;
  border-radius: 50%;
  transition: opacity 300ms ease-in-out;
  opacity: 32%;

  &:hover {
    //background-color: rgba(0, 0, 0, 0.08);
    opacity: 100%;
  }
`;

export const MoreButton = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin: auto 0 auto 16px;
  cursor: pointer;
  background: transparent url(/icons/icn_ellipsis_v.svg) center center no-repeat;
  background-size: 14px 14px;
  opacity: 0.33;
  transition: background-color 300ms ease-out, opacity 300ms ease-out;
  border-radius: 50%;

  &:hover {
    background-color: #00000014;
  }
`;

export const RecentBoardItem = styled.li`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 12px 16px;
  background-color: transparent;
  transition: background-color 300ms ease-out;
  position: relative;

  ${CastButton} {
    visibility: hidden;
  }

  &.loading {
    justify-content: center;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);

    ${CastButton} {
      visibility: visible;
    }
  }
`;

export const RecentBoardList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  margin: 12px 0 40px 0;
  background-color: #fff;
  box-shadow: 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  border-radius: 16px;

  a {
    color: #242424;

    &:first-child {
      ${RecentBoardItem} {
        border-radius: 4px 4px 0 0;
      }
    }

    &:last-child {
      ${RecentBoardItem} {
        border-radius: 0 0 4px 4px;
      }
    }
  }
`;

export const TemplatePanel = styled(RecentBoardList)`
  background-color: #f5f6f7;
  padding: 0;
  flex-direction: row;
  position: relative;

  &.disabled {
    opacity: 0.6;
    filter: grayscale();
  }
`;

export const RecentBoardThumbnail = styled.div`
  width: 56px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #00000029;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 56px;
  max-width: 56px;
  border-radius: 3px;
`;

export const RecentBoardLabel = styled.div`
  margin: auto 8px;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;
  align-items: center;

  & ~ ${ClickGuard} {
    width: 200px;
    padding-right: 16px;
  }
`;

export const MyBoardsBoardLabel = styled(RecentBoardLabel)`
  flex: 8 0;
  margin: 0;
`;

export const MyBoardsBoardThumbnail = styled(RecentBoardThumbnail)`
  margin-right: 8px;
`;

export const NewBoardRowWrapper = styled.div`
  &.disabled {
    opacity: 0.6;
    filter: grayscale();

    a {
      pointer-events: none;
    }
  }
`;

export const MyBoardsItem = styled(RecentBoardItem)`
  &.new-board {
    ${MyBoardsBoardLabel} {
      font-weight: 600;
    }

    ${MyBoardsBoardThumbnail} {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: 300;
      background: rgba(99, 193, 153, 0.3) url(/icons/icn_blankboard.svg) center center no-repeat;
      border-color: rgba(99, 193, 153, 0.05);
    }
  }
`;

export const SharedBoard = styled.img.attrs({
  src: '/icons/icn_collaboration.svg',
})`
  margin: 0 10px;
  width: 22px;
  opacity: 0.33;
`;

export const RecentBoardTime = styled.div`
  font-size: 13px;
  font-weight: 400;
  flex: 3 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const ShareColumn = styled.div`
  display: flex;
  width: 200px;
`;

export const CastColumn = styled.div`
  display: flex;
`;

export const RecentBoardExpander = styled.div`
  width: 1px;
  height: 24px;
  margin: auto auto;
`;

export const RecentBoardTeamName = styled.div.attrs((p) => ({
  style: {
    background: `${p.color}22`,
    color: p.color,
  },
}))`
  height: 24px;
  align-self: center;
  margin-right: 64px;
  border-radius: 3px;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-weight: 600;
  white-space: nowrap;
`;

export const RecentBoardsEmpty = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;

export const RecentBoardsEmptyIllustration = styled.img.attrs({
  src: '/images/ill_noboards.svg',
  alt: ' ',
})`
  height: 80px;
`;

export const TeamBoardsEmptyIllustration = styled(RecentBoardsEmptyIllustration).attrs({
  src: '/images/ill_teams.svg',
})``;

export const RecentBoardsEmptyTitle = styled.div`
  margin: 24px 0 8px 0;
  font-size: 16px;
  font-weight: 600;
`;

export const RecentBoardsEmptyComment = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #8c8c8c;
  text-align: center;

  a {
    color: #47a37c;
    font-weight: 600;
    text-decoration: none;
    &.blue {
      color: #2287ca;
    }
  }
`;

export const EmptyBoardsButtons = styled.div`
  margin-top: 16px;
`;

export const EmptyBoardsButton = styled.button.attrs({
  type: 'button',
})`
  height: 40px;
  margin: 0 4px;
  padding: 0 16px 0 8px;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  background: transparent;

  img {
    margin-right: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  &.blue {
    color: #279ae7;

    &:hover {
      background-color: #279ae729;
    }
  }

  &.green {
    color: #47a37c;

    &:hover {
      background-color: #23e69229;
    }
  }
`;

export const AccountInformation = styled.div`
  background: white;
  padding: 20px;
  box-shadow: 0px 2px 4px #00000014;
  border: 0.5px solid #00000014;
  border-radius: 16px;
`;

export const PanelGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin: 32px 0 0 0;
  }
`;

export const Filter = styled.div`
  font-size: 13px;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const TeamMembers = styled.div`
  font-size: 13px;
  margin-left: auto;
  display: flex;
  align-items: center;
  transform-style: preserve-3d;

  span.name-bubble-border {
    background: #fff;
    padding: 1px;
    border-radius: 50%;
  }
`;

export const PlusMoreBubble = styled.div`
  position: relative;
  padding: 14px;
  width: 30px;
  height: 30px;
  background: #d8d8d8;
  color: #242424;
  border: 2px solid #d8d8d8;
  border-color: #00000026;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
`;

export const InviteMoreButton = styled.div`
  width: 32px;
  height: 32px;
  background: url(/icons/icn_invite_members.svg) center center no-repeat;

  &:hover {
    cursor: pointer;
  }
`;

export const MyBoardsTable = styled.div`
  a {
    color: #242424;
  }
`;

export const MyBoardsColumns = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0 8px 0;
  position: relative;
  padding: 0 16px;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    left: 16px;
    right: 16px;
    bottom: 0;
  }
`;

export const MyBoardsColumn = styled.div`
  height: 30px;
  text-transform: uppercase;
  color: #8c8c8c;
  font-size: 10px;
  align-items: center;
  display: flex;

  span {
    position: relative;
    cursor: pointer;
    padding-right: 8px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: 0;
      left: 100%;
      bottom: 0;
      right: 0;
      margin: auto 0;
      background: url(/icons/icn_sort_column.svg) center center no-repeat;
      transform: rotate(0deg);
      transition: transform 200ms ease-in-out;
    }
    &.up:after {
      transform: rotate(-180deg);
    }
  }
`;

export const MyBoardsColumnTitle = styled(MyBoardsColumn)`
  flex: 8 0;
`;

export const MyBoardsColumnDate = styled(MyBoardsColumn)`
  display: flex;
  flex: 3 0;
`;

export const MyBoardsColumnCopy = styled(MyBoardsColumn)`
  width: 200px;
`;

export const TeamBoardsList = styled(RecentBoardList)`
  position: relative;
  margin: 16px 0;
  padding: 16px 0;
`;

export const MyBoardsFilterButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  height: 32px;
  padding: 0 64px 0 16px;
  border-radius: 20px;
  color: #242424;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 300ms ease-out;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    top: 1px;
    bottom: 0;
    right: 16px;
    margin: auto 0;
    background: url(/icons/icn_list_arrows.svg) center center no-repeat;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const SubHeading = styled.div`
  margin: 40px 0 16px 0;
  font-size: 23px;
  font-weight: 400;
`;

export const MyAccountLabel = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
  color: #8c8c8c;
`;

export const MyAccountValue = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #242424;

  > button {
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 13px;
    margin-left: 24px;
    color: #2287ca;
  }
`;

const MyAccountPlanBox = styled.div`
  width: fit-content;
  height: fit-content;
  background-size: cover;
  padding: 40px;
  display: flex;
  flex-direction: column;
  user-select: text;
`;

export const MyAccountFreePlanBox = styled(MyAccountPlanBox)`
  background: url(/images/image_free-plan-background.svg) center center no-repeat;
`;

export const MyAccountPaidPlanBox = styled(MyAccountPlanBox)`
  background: url(/images/image_paid-plan-background.svg) center center no-repeat;
`;

export const MyAccountPlanTitle = styled.div`
  margin-bottom: 16px;
  font-size: 41px;
  font-weight: 600;
  color: #242424;
`;

export const MyAccountPlanDetails = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #242424;
  width: 400px;

  span {
    font-weight: 600;
  }
`;

export const MyAccountPlanAction = styled.div`
  margin-top: 56px;

  span {
    font-weight: 600;
  }
`;

export const MyAccountDeleteNote = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #242424;
  padding-bottom: 32px;
  user-select: text;

  span {
    font-weight: 600;
  }
`;

export const InvitesColumnHeaderEmail = styled(MyBoardsColumn)`
  flex: 4 0;
`;

export const InvitesColumnHeaderDateCreated = styled(MyBoardsColumn)`
  flex: 2 0;
`;

export const InvitesColumnDelete = styled(MyBoardsColumn)`
  flex: 1 0;
`;

export const InvitesColumnEmail = styled.div`
  flex: 4 0;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
`;

export const InvitesColumnDateCreated = styled.div`
  flex: 2 0;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
`;

export const RoundTextBox = styled.input.attrs((props: { isFocused: boolean; placeholder?: string }) => ({
  placeholder: props.isFocused ? '' : props.placeholder ?? '',
}))`
  border: 1.5px solid #00000029;
  border-radius: 30px;
  background: #fff;
  height: 48px;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  transition: border-color 150ms ease-in-out;
  color: #8c8c8c;
  display: block;
  outline: none;
  margin: 10px 0px;
  padding: 0 12px;

  &::placeholder {
    color: #8c8c8c;
    opacity: 0.5;
    font-weight: 400;
    text-transform: none;
    letter-spacing: ${(props: { isFocused: boolean }) => (props.isFocused ? '6.2px' : 'normal')};
  }

  &:focus {
    border-color: #279ae7;
    background: #fafafa;
    color: #242424;
  }

  &.error {
    background: #df0b0b29;
    border-color: #df0b0b29;
  }

  &.invalidText {
    border-color: #d9534f;
  }
`;

export const EmailTextBox = styled(RoundTextBox).attrs({
  type: 'email',
  spellCheck: true,
})``;

export const FlexBox2 = styled.div`
  flex: 2 0;
`;

export const FlexBox3 = styled.div`
  flex: 3 0;
`;

export const FlexBox7 = styled.div`
  flex: 7 0;
`;

export const FlexBox10 = styled.div`
  flex: 10 0;
`;

export const InvitePrimaryButton = styled.button`
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #279ae7;
  color: #2287ca;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  min-width: 70px;
  height: 48px;
  padding-right: 0;
  padding-left: 0;
  letter-spacing: -0.5px;
  background: transparent;

  &:hover {
    background-color: #279ae7;
    color: #ffffff;
  }

  &:disabled {
    background-color: #8c8c8c;
    border-color: #8c8c8c;
    color: #ffffff;
    cursor: not-allowed;
  }
`;

export const InviteeEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  text-transform: lowercase;
`;
