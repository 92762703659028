import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import * as analytics from 'client/common/analytics';
import { MUTATION_CREATE_BOARD_LINK } from 'client/common/graphql';
import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import * as CreateStyled from 'client/components/Session/styled';

import * as Styled from './create-team-styled';

interface Props {
  file: {
    fileId: string;
    fileName: string;
  };
  onClose: () => void;
}

const ShareDialog: React.FC<Props> = ({ file, onClose }) => {
  const [copied, setCopied] = useState(false);
  const [boardLink, setBoardLink] = useState('');
  const [createBoardLink] = useMutation(MUTATION_CREATE_BOARD_LINK);

  useHotkeys('esc', onClose);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(boardLink);
    analytics.copyBoardLink();
    setCopied(true);
  }, [boardLink, setCopied]);

  const createInviteLink = useCallback(async () => {
    const { data } = await createBoardLink({ variables: { fileId: file.fileId } });

    setBoardLink(data.createFileLink.uri);
  }, [createBoardLink, file]);

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container style={{ minHeight: 'unset' }}>
        <Styled.CloseButton onClick={onClose} />
        <Styled.Title>Share {file.fileName} </Styled.Title>
        <CreateStyled.CopyField className={!boardLink ? 'copyDisabled' : ''}>
          {boardLink ? (
            <>
              {boardLink}
              <CreateStyled.CopyButton onClick={copyLink}>{copied ? 'Copied' : 'Copy link'}</CreateStyled.CopyButton>
            </>
          ) : (
            <CreateStyled.CreateInviteButton onClick={createInviteLink}>
              Create invite link
            </CreateStyled.CreateInviteButton>
          )}
        </CreateStyled.CopyField>
        <CreateStyled.AccessLabel>Anyone who has access to the link can join the board</CreateStyled.AccessLabel>
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default ShareDialog;
