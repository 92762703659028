import React, { useState } from 'react';

import { useAuth } from 'client/hooks/useAuth';
import NameBubble from 'client/components/Common/NameBubble';
import { useSelector } from 'client/hooks/useSelector';

import RoomAppLink from '../download-room-app/room-app-link';
import * as Styled from '../styled';

import ChangeNameModal from './change-name-modal';
import ChangePasswordModal from './change-password-modal';

interface Props {
  userEmail: string;
  userName: string;
}

const MyAccount: React.FC<Props> = ({ userEmail, userName }) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);
  const auth = useAuth();

  const tenant = useSelector((state) => state.tenant);

  return (
    <Styled.MainColumn>
      <Styled.PanelGroup>
        <Styled.GroupHeader>
          <Styled.HeaderTitle>My Account</Styled.HeaderTitle>
        </Styled.GroupHeader>
        <Styled.SubHeading>Your Profile</Styled.SubHeading>
        <Styled.AccountInformation>
          <NameBubble displayName={userName} large />
          <Styled.MyAccountLabel>First and Last name</Styled.MyAccountLabel>
          <Styled.MyAccountValue>
            {userName}
            <button onClick={() => setShowChangeName(true)}>Change</button>
          </Styled.MyAccountValue>
          {showChangeName && <ChangeNameModal onClose={() => setShowChangeName(false)} />}
          <Styled.MyAccountLabel>Email address</Styled.MyAccountLabel>
          <Styled.MyAccountValue>{userEmail}</Styled.MyAccountValue>
          {auth.user?.providerId === 'password' && (
            <>
              <Styled.MyAccountLabel>Password</Styled.MyAccountLabel>
              <Styled.MyAccountValue>
                {new Array(15).fill('•').join('')}
                <button onClick={() => setShowChangePassword(true)}>Change</button>
              </Styled.MyAccountValue>
              {showChangePassword && <ChangePasswordModal onClose={() => setShowChangePassword(false)} />}
            </>
          )}
        </Styled.AccountInformation>
        <Styled.SubHeading>Your Plan Details</Styled.SubHeading>
        {tenant ? (
          <Styled.MyAccountPaidPlanBox>
            <Styled.MyAccountPlanTitle>Business</Styled.MyAccountPlanTitle>
            <Styled.MyAccountPlanDetails>
              Your account plan is connected to <span>{userEmail}</span>
            </Styled.MyAccountPlanDetails>
          </Styled.MyAccountPaidPlanBox>
        ) : (
          <Styled.MyAccountFreePlanBox>
            <Styled.MyAccountPlanTitle>Free</Styled.MyAccountPlanTitle>
            <Styled.MyAccountPlanDetails>
              You have the Free Plan, which allows you to start a limited number of boards. An upgrade to the full
              experience of FlatFrog Board will be available soon.
            </Styled.MyAccountPlanDetails>
            <Styled.MyAccountPlanAction>
              If you have questions, contact us at <span>board@flatfrog.com</span>
            </Styled.MyAccountPlanAction>
          </Styled.MyAccountFreePlanBox>
        )}
        <Styled.GroupDivider />
        <Styled.SubHeading>Flatfrog Board for Rooms</Styled.SubHeading>
        <div>
          Flatfrog Board for Rooms is our meeting room application, designed to work on InGlass™ touchscreen displays.
          <br />
          <br />
          <RoomAppLink />
        </div>
        <Styled.GroupDivider />
        <Styled.SubHeading>Delete my Account</Styled.SubHeading>
        <Styled.MyAccountDeleteNote>
          Contact us at <span>board@flatfrog.com</span>
        </Styled.MyAccountDeleteNote>
      </Styled.PanelGroup>
    </Styled.MainColumn>
  );
};

export default MyAccount;
