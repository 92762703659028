import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CONNECTION_FAIL_REASON } from 'client/common/util';
import { ModalBackDrop } from 'client/components/Common/modal-styled';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #242424;
  text-align: center;
  display: block;
  padding-top: 24px;
  margin-bottom: 8px;
`;

const HelpText = styled.span`
  margin-bottom: 24px;
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #242424;
  text-align: center;
`;

const CancelButton = styled.button.attrs({
  type: 'button',
})`
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #2287ca;
  text-align: center;
  padding: 13px 24px;
  border-radius: 6px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #279ae71a;
  }
`;

const LoadingModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 64px;
  width: 580px;
  max-width: 96vw;
  max-height: 96vh;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 0 0.5px #00000014, 0 16px 32px #00000029;
  text-align: center;
  z-index: 5;

  .lds-ellipsis-large {
    margin: 24px auto;
    display: block;
  }

  &.small {
    display: flex;
    flex-direction: column;

    .lds-ellipsis-large {
      margin: 0 auto;
    }

    ${Title} {
      padding-top: 32px;
    }
  }
`;

interface Props {
  failure?: string;
  helpText?: string;
  loadingText?: string;
  small?: boolean;
}

const LoadingBoard: React.FC<Props> = ({ failure, helpText, loadingText, small }) => {
  const { isMsTeamsApp, room } = useSelector((state) => ({
    isMsTeamsApp: state.isMsTeamsApp,
    room: state.room,
  }));

  const [showAnimation, setShowAnimation] = useState(true);
  const [showBackButton, setShowBackButton] = useState(false);

  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    setShowAnimation(!failure);
    setShowBackButton(!!failure);
  }, [failure]);

  let title = <>{loadingText || 'Connecting\u2026'}</>;
  let body = <>{helpText || 'We are setting up a connection to the server'}</>;

  if (failure === CONNECTION_FAIL_REASON.notFound) {
    title = <>Board Not Found</>;
    body = <>The board is gone or your link was wrong</>;
  } else if (failure === CONNECTION_FAIL_REASON.unknown) {
    title = <>Couldn't Connect to Board</>;
    body = (
      <>We couldn't establish a connection to the board. This is probably our fault, please try again in a moment.</>
    );
  }

  const handleCancel = () => {
    if (isMsTeamsApp) {
      history.replace('/vc/teams/error');
    } else if (room) {
      history.replace('/room');
    } else if (auth.user) {
      history.replace('/dashboard');
    } else {
      history.replace('/');
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop className="active" />
      <LoadingModal className={small ? 'small' : ''}>
        {showAnimation && (
          <div className="lds-ellipsis-large">
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
        <Title>{title}</Title>
        {!small && <HelpText>{body}</HelpText>}
        {!small && showBackButton && (
          <CancelButton onClick={handleCancel}>{auth.user ? 'Back to Dashboard' : 'Go to Start'}</CancelButton>
        )}
      </LoadingModal>
    </>,
    document.getElementById('modal-portal')
  );
};

export default LoadingBoard;
