import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: inline-flex;
  font-size: 14px;
  align-items: center;

  > span {
    color: #555;
    margin: 0 0.5em;
    cursor: default;
    user-select: none;
    white-space: nowrap;
  }
`;

export const Checkbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))``;

export const Slider = styled.span``;

export const Switch = styled.label<{ translucent: boolean; color: string }>`
  position: relative;
  display: inline-block;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 24px;
  -webkit-tap-highlight-color: transparent;

  ${Checkbox} {
    display: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:checked + ${Slider} {
      background-color: ${(props) => props.color};
      border-color: ${(props) => props.color};
    }

    &:disabled + ${Slider} {
      border-color: #c3c3c3;
    }

    &:disabled + ${Slider}:before {
      background-color: #c3c3c3;
    }

    &:focus + ${Slider} {
    }

    &:checked + ${Slider}:before {
      transform: translateX(24px);
    }
  }

  ${Slider} {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => (props.translucent ? 'rgba(0,0,0,0.08)' : '#9e9e9e')};
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out;
    border-radius: 24px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2),
      0 0 0 1px ${(props) => (props.translucent ? 'rgba(0,0,0,0.08)' : 'rgba(255, 255, 255, 0.5)')};

    &:hover {
      border-color: #fff;

      &:before {
        background-color: #fff;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: #fff;
      transition: transform 0.15s ease-in-out;
      border-radius: 50%;
    }
  }
`;
