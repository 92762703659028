import ZCanvas, { CreatedImage } from '@flatfrog/ffbec';

const VOTING_COLOR_HAS_VOTED = '#33C65A';
const VOTING_COLOR_NOT_VOTED = '#DA303A';

class NumberAdornmentMaker {
  private offscreenCanvas = new OffscreenCanvas(600, 92);
  private cachedCreatedImages: Record<string, CreatedImage> = {};
  private updatePromise: Promise<void> = Promise.resolve();

  clearCache() {
    this.cachedCreatedImages = {};
  }

  roundRect(ctx: OffscreenCanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
    if (w < 2 * r) {
      r = w / 2;
    }
    if (h < 2 * r) {
      r = h / 2;
    }
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();
  }

  generateLabel(number: number, backgroundColor: string): Promise<Blob> {
    const font = 'bold 22px Open Sans, verdana';
    const color = '#fff';
    const numberStr = `${number}`;

    const dpr = window.devicePixelRatio || 1;
    this.offscreenCanvas.width = 180;
    this.offscreenCanvas.height = 34;
    const ctx = this.offscreenCanvas.getContext('2d');
    ctx.scale(dpr, dpr);
    ctx.font = font;
    ctx.textAlign = 'center';
    ctx.fillText(numberStr, 0, this.offscreenCanvas.height / 2);
    const m = ctx.measureText(numberStr);
    this.offscreenCanvas.width = Math.max(this.offscreenCanvas.height, m.width + 18);
    ctx.fillStyle = backgroundColor;
    this.roundRect(ctx, 1, 1, this.offscreenCanvas.width - 2, this.offscreenCanvas.height - 2, 17);
    ctx.fill();
    ctx.textBaseline = 'middle';
    ctx.font = font;
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.fillText(numberStr, this.offscreenCanvas.width / 2, this.offscreenCanvas.height / 2 + 2);
    return this.offscreenCanvas.convertToBlob();
  }

  async createNumberAdornment(number: number, backgroundColor: string): Promise<CreatedImage> {
    const imageName = `numberAdornment_${number}_${backgroundColor}`;
    let createdImage = this.cachedCreatedImages[imageName];
    if (!createdImage) {
      const labelImageData = await (await this.generateLabel(number, backgroundColor)).arrayBuffer();
      createdImage = await ZCanvas.image.loadAsync({
        data: new Uint8Array(labelImageData),
        imageName,
      });
      this.cachedCreatedImages[imageName] = createdImage;
    }
    return createdImage;
  }

  getCreatedImage(paperId: number, votes: number, backgroundColor: string) {
    if (votes === 0) {
      return Promise.resolve(undefined);
    }
    return this.createNumberAdornment(votes, backgroundColor);
  }

  update(paperId: number) {
    this.updatePromise = this.updatePromise.then(async () => {
      try {
        const votes = ZCanvas.paper.getVotes(paperId);
        const backgroundColor = ZCanvas.history.hasLocalVotes(paperId)
          ? VOTING_COLOR_HAS_VOTED
          : VOTING_COLOR_NOT_VOTED;
        const createdImage = await this.getCreatedImage(paperId, votes, backgroundColor);
        ZCanvas.paper.setTopRightAdornment(paperId, createdImage);
      } catch (e) {
        console.error(
          `numberAdornmentCanvas: Exception occurred while updating paper ${paperId}: ${e?.message}\n${e?.stack}`
        );
      }
    });
  }
}

export default new NumberAdornmentMaker();
