import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useState, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactTooltip from 'react-tooltip';

import {
  QUERY_PICKER,
  QUERY_TEAM_FILES,
  QUERY_SEARCH_BOARD,
  MUTATION_CREATE_BOARD_LINK,
  FileOrFileLink,
  File,
  isFileLink,
} from 'client/common/graphql';
import { getParticipantName, getParticipantColor } from 'client/common/participant-names';
import * as DashboardStyled from 'client/components/Dashboard/styled';
import { useAuth } from 'client/hooks/useAuth';
import useEventListener from 'client/hooks/useEventListener';
import { ContextMenu } from 'client/components/Common/ContextMenu';

import { GlobalStyle } from './styled';
import * as Styled from './styled';

const THUMBNAIL_PLACEHOLDER = '/images/ill_thumbnail_placeholder.svg';

const categoriesStatic = [
  {
    id: 'recents',
    name: 'Recents',
    default: true,
  },
  {
    id: 'personal',
    name: 'Personal',
    default: true,
  },
  {
    id: 'sharedwithme',
    name: 'Shared With me',
    default: true,
  },
];

interface Props {
  boardFilePicked: (boardFile: { id: string; name: string } | { new: true }) => void;
}

const BoardPicker: React.FC<Props> = ({ boardFilePicked }) => {
  const [selectedCategory, setSelectedCategory] = useState('recents');
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [newFileName, setNewFileName] = useState('');
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const [userInitials, setUserInitials] = useState('?');
  const [userColor, setUserColor] = useState('#888');
  const auth = useAuth();
  const userGroup = useRef<HTMLDivElement>();

  useHotkeys('esc', () => {
    if (userMenuVisible) {
      setUserMenuVisible(false);
    }
  });

  useEffect(() => {
    if (auth.user) {
      let { displayName } = auth.user;
      if (displayName?.length > 0) {
        setUserColor(getParticipantColor(displayName));
        setUserInitials(getParticipantName(displayName));
      } else {
        displayName = '';
        setUserColor(getParticipantColor(displayName));
        setUserInitials(getParticipantName(displayName));
      }
    }
  }, [auth.user]);

  const { data, loading: loadingData } = useQuery(QUERY_PICKER, {
    variables: { limit: 5, sort: 'LASTMODIFIED_DESC', input: { sort: 'LASTMODIFIED_DESC' } },
  });
  const [getTeamFiles, teamFiles] = useLazyQuery(QUERY_TEAM_FILES);
  const emptyExperience = !loadingData && data.recents.length === 0;

  // Close menus when clicking anywhere
  useEventListener(
    'pointerdown',
    (e) => {
      window.dispatchEvent(new CustomEvent('closemenus', { detail: e.target }));
    },
    document
  );

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (!userGroup.current?.contains(e.detail)) {
      setUserMenuVisible(false);
    }
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data?.allowedToCreateFile, selectedItem]);

  const [createBoardLink] = useMutation(MUTATION_CREATE_BOARD_LINK, {
    onCompleted: (d) => {
      boardFilePicked({ id: d.createFileLink.id, name: selectedItem.fileName });
    },
  });

  const categories = [
    ...categoriesStatic,
    ...(data?.teams?.teams.map((t) => ({ id: t.id, name: t.name, default: false })) ?? []),
  ];
  const categoryItems = categories.map((category) => (
    <Styled.Category
      className={category.id === selectedCategory ? 'is-current' : ''}
      onClick={() => {
        setSelectedCategory(category.id);
        if (!category.default) {
          getTeamFiles({
            variables: { input: { sort: 'LASTMODIFIED_DESC', teamId: category.id } },
          });
        }
      }}
      key={category.id}
    >
      {category.name}
    </Styled.Category>
  ));

  let fileRawList: FileOrFileLink[];

  switch (selectedCategory) {
    case 'personal':
      fileRawList = data?.files;
      break;
    case 'sharedwithme':
      fileRawList = data?.sharedWithMe;
      break;
    case 'recents':
      fileRawList = data?.recents;
      break;
    default:
      fileRawList = teamFiles.data?.teamFiles;
      break;
  }

  const selectFile = (file: FileOrFileLink) => {
    const pickedFile = { ...file };
    // workspace files from server does not contain the workspace name,
    // attach it for the detail view to display
    if (!categoriesStatic.find((c) => c.id === selectedCategory)) {
      (pickedFile as File).team = data?.teams?.teams.find((t) => t.id === selectedCategory);
    }

    setSelectedItem(pickedFile);
    if (isFileLink(file)) {
      // picked item is already a link
      boardFilePicked({ id: file.id, name: file.file.fileName });
    } else {
      // picked item is a file
      createBoardLink({ variables: { fileId: file.id } });
    }
  };

  const list = fileRawList?.map((fileOrFileLink) => {
    const file = isFileLink(fileOrFileLink) ? fileOrFileLink.file : fileOrFileLink;

    return (
      <Styled.BoardItem
        key={fileOrFileLink.id}
        onClick={() => {
          selectFile(fileOrFileLink);
        }}
      >
        <Styled.BoardThumbnail
          style={{
            backgroundImage: `url(${file.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
          }}
        />
        <Styled.BoardLabel>
          {file.fileName || 'Unnamed Board'}
          {isFileLink(fileOrFileLink) && (
            <DashboardStyled.SharedBoard
              data-tip={`Owner: ${file?.user?.displayName}${file?.user?.disabled ? ' (disabled)' : ''}`}
              data-effect="solid"
              data-delay-show="150"
            />
          )}
        </Styled.BoardLabel>
        <DashboardStyled.RecentBoardTime>{dayjs(file.dateModified).fromNow()}</DashboardStyled.RecentBoardTime>
        {file.team && (
          <DashboardStyled.RecentBoardTeamName color={getParticipantColor(file.team.name)}>
            {file.team.name}
          </DashboardStyled.RecentBoardTeamName>
        )}
      </Styled.BoardItem>
    );
  });

  const mainContent = (
    <>
      <Styled.Header>
        <Search fileSelected={selectFile} />
        <DashboardStyled.UserGroup>
          <ContextMenu items={[[{ title: 'Sign out', id: 'signout-button', onClick: () => auth.signOut() }]]}>
            <Styled.AvatarBubble
              data-tip={auth.user?.displayName ?? 'Unknown'}
              data-place="bottom"
              data-delay-show={300}
              data-effect="solid"
              color={userColor}
            >
              {userInitials}
            </Styled.AvatarBubble>
          </ContextMenu>
        </DashboardStyled.UserGroup>
      </Styled.Header>
      {!loadingData && !emptyExperience && (
        <>
          <Styled.Categories>{categoryItems}</Styled.Categories>
          <Styled.BoardList>
            {selectedCategory === 'recents' && (
              <Styled.BoardItem
                className={!data?.allowedToCreateFile.allowed && 'disabled'}
                data-tip={
                  data?.allowedToCreateFile.limit
                    ? `Maximum number (${data?.allowedToCreateFile.limit}) of boards reached`
                    : 'Not allowed to create new board'
                }
                data-tip-disable={data?.allowedToCreateFile.allowed}
                data-place="top"
                data-delay-show={300}
                data-effect="solid"
                data-offset="{'right': -140}"
                key="new"
                onClick={() => {
                  if (data?.allowedToCreateFile.allowed) {
                    setSelectedItem({ id: 'new', name: 'New Board' });
                    boardFilePicked({ new: true });
                  }
                }}
              >
                <Styled.NewBoardThumbnail />
                <Styled.BoardLabel style={{ fontWeight: 600 }}>New Board</Styled.BoardLabel>
              </Styled.BoardItem>
            )}
            {list}
          </Styled.BoardList>
        </>
      )}
      {emptyExperience && (
        <Styled.EmptyContainer>
          <h2>Welcome to FlatFrog Board</h2>
          <span>
            Choose <b>New Board</b> to get started or sign in to{' '}
            <a href="/" target="_blank">
              {window.location.host}
            </a>{' '}
            for more options and to learn more.
          </span>
          <Styled.NewBoardThumbnailEmpty
            onClick={() => {
              setSelectedItem({ id: 'new', name: 'New Board' });
              boardFilePicked({ new: true });
            }}
          />
          <Styled.NewBoardEmpty>New Board</Styled.NewBoardEmpty>
        </Styled.EmptyContainer>
      )}
    </>
  );

  return (
    <>
      <GlobalStyle />
      <Styled.Container>
        {selectedItem && (
          <Styled.BoardInfoContainer>
            <Styled.BackButton
              onClick={() => {
                boardFilePicked(undefined);
                setSelectedItem(undefined);
              }}
            >
              Back to Boards
            </Styled.BackButton>
            {selectedItem.id !== 'new' && (
              <Styled.BoardScreenshot
                style={{
                  backgroundImage: `url(${
                    selectedItem.thumbnailUri ?? selectedItem.file?.thumbnailUri ?? THUMBNAIL_PLACEHOLDER
                  })`,
                }}
              />
            )}
            {selectedItem.id === 'new' && (
              <Styled.BoardScreenshot
                style={{
                  background: '#fff url(/icons/icn_blankboard.svg) center center/10% no-repeat',
                }}
              />
            )}

            {selectedItem.id !== 'new' && (
              <Styled.BoardTitleName>{selectedItem.fileName ?? selectedItem.file.fileName}</Styled.BoardTitleName>
            )}
            {selectedItem.id === 'new' && (
              <Styled.BoardTitleEdit
                value={newFileName}
                onChange={(e) => {
                  setNewFileName(e.currentTarget.value);
                  boardFilePicked({ new: true, name: e.currentTarget.value });
                }}
              />
            )}
            {selectedItem.id !== 'new' && (
              <Styled.BoardSubtitle>
                {selectedItem.team?.name
                  ? `Workspace: ${selectedItem.team?.name}`
                  : `Owner: ${selectedItem.file ? selectedItem.file.user.displayName : selectedItem.user.displayName}`}
              </Styled.BoardSubtitle>
            )}
            {selectedItem.id === 'new' && <Styled.BoardSubtitle>Create new Board</Styled.BoardSubtitle>}
          </Styled.BoardInfoContainer>
        )}
        {!selectedItem && mainContent}

        <ReactTooltip />
      </Styled.Container>
    </>
  );
};

export default BoardPicker;

interface SearchProps {
  fileSelected: (f: FileOrFileLink) => void;
}

const Search: React.FC<SearchProps> = ({ fileSelected }) => {
  const [searchQuery, setSearchQuery] = useState(undefined);
  const [searchFieldInput, setSearchFieldInput] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [search, searchResultData] = useLazyQuery(QUERY_SEARCH_BOARD);
  const searchResultRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!searchQuery && searchResult.length > 0) {
      setSearchResult([]);
    } else if (searchQuery) {
      search({
        variables: { input: { query: searchQuery } },
      });
    }
  }, [searchQuery]);

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (!searchResultRef.current?.contains(e.detail)) {
      setSearchQuery(undefined);
    }
  });

  useHotkeys(
    'esc',
    (e) => {
      setSearchFieldInput('');
      setSearchQuery(undefined);
      (e.target as HTMLElement)?.blur();
    },
    { enableOnTags: ['INPUT'] }
  );

  useEventListener<KeyboardEvent>(
    'keydown',
    (e) => {
      const firstTarget: HTMLElement = document.querySelector('li[tabindex]:first-child');
      const lastTarget = document.querySelector('li[tabindex]:last-child');
      const currentFocusTarget = document.activeElement;

      if (lastTarget === currentFocusTarget && e.keyCode === 9) {
        e.preventDefault();
        firstTarget.focus();
      }
    },
    window
  );

  const list = searchResultData.data?.searchFiles.map((fileOrFileLink, index) => {
    const file = isFileLink(fileOrFileLink) ? fileOrFileLink.file : fileOrFileLink;

    return (
      <Styled.SearchBoardItem
        key={fileOrFileLink.id}
        onClick={() => {
          fileSelected(fileOrFileLink);
          setSearchFieldInput('');
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            fileSelected(fileOrFileLink);
            setSearchFieldInput('');
          }
        }}
        tabIndex={index + 1}
      >
        <Styled.BoardThumbnail
          style={{
            backgroundImage: `url(${file.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
          }}
        />
        <Styled.BoardLabel>
          <div>
            {file.fileName || 'Unnamed Board'}
            <Styled.Modified>{dayjs(file.dateModified).fromNow()}</Styled.Modified>
          </div>
          {isFileLink(fileOrFileLink) && (
            <DashboardStyled.SharedBoard
              data-tip={`Owner: ${file?.user?.displayName}${file?.user?.disabled ? ' (disabled)' : ''}`}
              data-effect="solid"
              data-delay-show="150"
            />
          )}
        </Styled.BoardLabel>
        {file.team && (
          <DashboardStyled.RecentBoardTeamName color={getParticipantColor(file.team.name)}>
            {file.team.name}
          </DashboardStyled.RecentBoardTeamName>
        )}
      </Styled.SearchBoardItem>
    );
  });

  return (
    <Styled.SearchContainer ref={searchResultRef}>
      <Styled.SearchBar
        tabIndex={1}
        value={searchFieldInput}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.currentTarget.value?.trim().length > 1) {
            setSearchQuery(e.currentTarget.value?.trim());
          }
        }}
        onChange={(e) => {
          setSearchFieldInput(e.currentTarget.value);

          if (e.currentTarget.value?.trim().length > 1) {
            setSearchQuery(e.currentTarget.value?.trim());
          } else {
            setSearchQuery(undefined);
          }
        }}
      />
      <Styled.SearchIcon />
      {searchQuery && (
        <Styled.SearchResult>
          <Styled.ScrollView>
            {searchResultData.loading && (
              <Styled.LoadingBoards>
                <div className="lds-ellipsis-large">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </Styled.LoadingBoards>
            )}
            {list?.length
              ? list
              : !searchResultData.loading && <Styled.LoadingBoards>No boards matched your search</Styled.LoadingBoards>}
          </Styled.ScrollView>
        </Styled.SearchResult>
      )}
    </Styled.SearchContainer>
  );
};
