import styled from 'styled-components';

export const DownloadIllustration = styled.img.attrs({
  src: '/images/ill_download_board.svg',
  alt: ' ',
  height: '120px',
})`
  margin: 1em;
`;

export const DownloadDialogContainer = styled.div`
  ul {
    list-style: none;
    padding: 0;

    li {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 15px;
      margin-bottom: 5px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;

      &.disabled {
        opacity: 0.7;
        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
`;

export const DownloadEntryImage = styled.img.attrs({
  alt: ' ',
  height: '40px',
})`
  display: block;
  margin-right: 10px;
`;

export const DownLoadEntryTitle = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1px;
  letter-spacing: -0.5px;
`;

export const DownloadEntryDescription = styled.div`
  text-align: left;
  font-size: 11px;
  letter-spacing: -0.5px;
  color: #999999;
  font-weight: 500;
`;

export const DownloadProgress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 150ms linear;
  background: #47a37c;
  border-radius: 1px;
`;
