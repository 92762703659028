import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { Link, useHistory } from 'react-router-dom';

import * as analytics from 'client/common/analytics';
import { getSupportedFileExtensions, OFFICE_FILE_TYPES } from 'client/common/util';
import * as Styled from 'client/components/Dashboard/upload/styled-upload';
import { useAuth } from 'client/hooks/useAuth';
import { Session } from 'client/state/session/session';

import { ModalBackDrop } from './dialog-styled';

interface Props {
  onClose: () => void;
  uploadFile: (
    file: File,
    cancelledRef: React.MutableRefObject<boolean>,
    progressCallback: (progress: number, capped: boolean) => void,
    doneCallback: (errorInUpload?: boolean) => void,
    margins: { top: number; right: number; bottom: number; left: number }
  ) => void;
  filesToImport?: File[];
  session: Session;
}

// Reuses all styles from ffb Upload dialog
const Import: React.FC<Props> = ({ filesToImport, onClose, session: { joinKey }, uploadFile }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<Record<string, number>>({});
  const [fileStatus, setFileStatus] = useState<Record<string, { status: string; capped?: boolean }>>({});
  const [cancelled, setCancelled] = useState(false);
  const cancelledRef = useRef<boolean>();
  cancelledRef.current = cancelled;
  const auth = useAuth();

  const history = useHistory<{ boardLink?: string }>();
  const { location } = history;
  const { state } = location;

  const uploadFiles = async (
    acceptedFiles: File[],
    fileRejections?: {
      file: File;
    }[]
  ) => {
    setUploading(true);

    acceptedFiles.forEach((file) => {
      setFileStatus((p) => ({ ...p, [file.name]: { status: 'waiting' } }));
      setProgress((p) => ({ ...p, [file.name]: 0 }));
    });

    const margins = [];
    const screenMargins = { x: 100, y: 50 };
    const step = 10;
    const noRows = Math.floor(Math.sqrt(acceptedFiles.length));
    const noColumns =
      acceptedFiles.length % noRows === 0
        ? acceptedFiles.length / noRows
        : Math.floor(acceptedFiles.length / noRows) + 1;
    const width = (3840.0 - 2 * screenMargins.x - (noColumns - 1) * step) / noColumns;
    const height = (2160.0 - 2 * screenMargins.y - (noRows - 1) * step) / noRows;
    for (let x = 0; x < noColumns; x++) {
      for (let y = 0; y < noRows; y++) {
        const left = screenMargins.x + x * (width + step);
        const top = screenMargins.y + y * (height + step);
        margins.push({
          left,
          top,
          right: left + width,
          bottom: top + height,
        });
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      setFileStatus((p) => ({
        ...p,
        [file.name]: { status: 'executing', file },
      }));
      if (cancelledRef.current) {
        break;
      }
      await uploadFile(
        file,
        cancelledRef,
        (prog, capped) => {
          if (!cancelledRef.current) {
            if (prog) {
              setProgress((p) => ({ ...p, [file.name]: prog }));
            }
            if (capped) {
              setFileStatus((p) => ({
                ...p,
                [file.name]: { status: 'executing', file, capped: true },
              }));
            }
          }
        },
        (errorInUpload) => {
          if (!cancelledRef.current) {
            if (errorInUpload) {
              setFileStatus((p) => ({
                ...p,
                [file.name]: { status: 'error', file },
              }));
            } else {
              setProgress((p) => ({ ...p, [file.name]: 100 }));
              setFileStatus((p) => ({
                ...p,
                [file.name]: { status: 'done', file },
              }));
            }
          }
        },
        margins[i]
      );
    }

    if (fileRejections) {
      fileRejections.forEach((rejectedFile) => {
        analytics.importUnsupportedFile(rejectedFile.file.type);
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (!filesToImport) {
        return;
      }

      uploadFiles(filesToImport);
    })();
    return () => {
      cancelledRef.current = true;
    };
  }, [filesToImport]);

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    uploadFiles(acceptedFiles, fileRejections);
  }, []);

  useEffect(() => {
    const statuses = Object.values(fileStatus);
    if (cancelled || (statuses.length > 0 && statuses.length === statuses.filter((s) => s.status === 'done').length)) {
      onClose();
    }
  }, [fileStatus, cancelled]);

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: getSupportedFileExtensions(!!auth.user),
  });

  const infoText = auth.user ? (
    <span>Import images, PDF and Office files.</span>
  ) : (
    <span>
      Import images, PDF and Office files.{' '}
      <Link
        onClick={(e) => e.stopPropagation()}
        to={{
          pathname: '/login',
          state: {
            from: 'session',
            sessionJoinKey: joinKey,
            boardLink: state?.boardLink,
          },
        }}
      >
        Sign in
      </Link>
      to import Office files.
    </span>
  );

  const defaultView = (
    <>
      <img src="/images/ill_upload_file.svg" alt="Upload FlatFrog Board File" width={80} />
      <div>
        <h1>Import document</h1>
        <span>
          Drag and drop file or <strong>browse</strong>
        </span>
      </div>
      <div style={{ marginTop: 30 }}>
        <Styled.InfoText>{infoText}</Styled.InfoText>
        <Styled.InfoText>
          <span>
            Head over to our{' '}
            <a
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              href="https://help.flatfrog.com/en/knowledge/how-to-import"
            >
              Knowledge base{' '}
            </a>{' '}
            to read more about formats.
          </span>
        </Styled.InfoText>
      </div>
    </>
  );

  const dropView = (
    <>
      <img src="/images/ill_upload_file.svg" alt="Upload FlatFrog Board File" width={80} />
      <div>
        <h1>Drop to import file</h1>
      </div>
    </>
  );

  const rejectView = (
    <>
      <img src="/images/ill_upload_file_failed.svg" alt="Wrong file type" width={80} />
      <div>
        <h1>Unsupported file format</h1>
        <span>
          {auth.user ? 'Only JPG, PNG, PDF and Office files are supported' : 'Only JPG, PNG and PDF is supported'}
        </span>
      </div>
    </>
  );

  const rejectOfficeView = (
    <>
      <img src="/images/ill_upload_file.svg" alt="Wrong file type" width={80} />
      <div>
        <h1>You're not signed in</h1>
        <span>Sign in to import Office files</span>
      </div>
    </>
  );

  const active = Object.entries(fileStatus).find((e) => e[1].status === 'executing');
  const done = Object.entries(fileStatus).filter((e) => e[1].status === 'done' || e[1].status === 'error');
  const currentIndex = done.length + 1;
  const progressIndexText = `(${currentIndex} of ${Object.entries(fileStatus).length})`;
  const failed = Object.entries(fileStatus)
    .filter((e) => e[1].status === 'error')
    .map((e) => e[0]);

  const errorView = (
    <>
      <img src="/images/ill_upload_file_failed.svg" alt="Error upload" width={80} />
      <div>
        <h1>Failed to import</h1>
        <span>{failed.join(', ')}</span>
      </div>
    </>
  );

  const progressView = (
    <>
      {active && <img src="/images/ill_upload_file.svg" alt="Uploading file" width={80} />}
      <Styled.ProgressArea>
        <Styled.ProgressBars>
          {active && (
            <div>
              <Styled.ProgressBar>
                <Styled.Progress style={{ width: `${progress[active[0]]}%` }} />
              </Styled.ProgressBar>
              <Styled.ProgressFileName>
                Importing {active[0]} {active[1].capped && '(first 10 pages)'}
              </Styled.ProgressFileName>
              <div>
                <Styled.ProgressFileName>{progressIndexText}</Styled.ProgressFileName>
              </div>
            </div>
          )}
          {!active && failed.length > 0 && errorView}
        </Styled.ProgressBars>
      </Styled.ProgressArea>
    </>
  );

  const getView = () => {
    const reject = fileRejections.length > 0;
    if (!reject && !isDragActive) {
      return defaultView;
    }
    if (reject && !isDragActive) {
      if (!auth.user && fileRejections.find((f) => f.file.type.match(OFFICE_FILE_TYPES))) {
        return rejectOfficeView;
      }
      return rejectView;
    }
    if (isDragActive) {
      return dropView;
    }

    return defaultView;
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container>
        {fileStatus.length}
        {uploading && Object.entries(progress).length ? (
          progressView
        ) : (
          <Styled.DropArea {...getRootProps()}>
            <input {...getInputProps()} />
            {getView()}
          </Styled.DropArea>
        )}
        <Styled.CancelButton
          style={{ bottom: 63, position: 'fixed' }}
          onClick={() => {
            setCancelled(true);
          }}
        >
          {!active && failed.length > 0 ? 'Okay' : 'Cancel'}
        </Styled.CancelButton>
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default Import;
