import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';

import * as analytics from 'client/common/analytics';
import {
  AuthProvider,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getFirebaseAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  updatePassword,
  updateProfile,
  User,
} from 'client/common/firebase';
import { resetWsClient } from 'client/common/graphql';
import { makeRequest } from 'client/services/rest';
import * as actions from 'client/state/actions';

import { useActions } from './useActions';
import useReduxAction from './useReduxAction';
import { useSelector } from './useSelector';

const authContext = createContext<AuthContext>(null);

export type BoardUser = {
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  providerId: string;
  room: boolean;
  uid: string;
  admin: boolean;
  tenantId: string | null;
  guest: boolean;
};

type AuthContext = {
  acceptInvite: (email: string, password: string, name: string, inviteId: string) => Promise<void>;
  ackError: () => void;
  changeDisplayName: (displayName: string) => Promise<boolean>;
  changePassword: (newPassword: string) => Promise<{ success: true } | { success: false; code?: string }>;
  checkEmail: (email: string) => Promise<{ valid: boolean; signInMethods: string[] }>;
  error: { code: string; message: string } | undefined;
  loading: boolean;
  reauthenticateWithPassword: (currentPassword: string) => Promise<boolean>;
  sendPasswordResetEmail: (email: string) => Promise<boolean>;
  signIn: (email: string, password: string) => Promise<void>;
  signInAsGuest: () => Promise<void>;
  signInGoogle: (forceRedirect?: boolean) => Promise<void>;
  signInMicrosoft: (forceRedirect?: boolean) => Promise<void>;
  signInRoom: () => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  updateUser: (u: User) => Promise<void>;
  user?: BoardUser;
};

const createAuthContext = (): AuthContext => {
  const [user, setUser] = useState<BoardUser | undefined>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const { tenantJoined } = useActions();

  useReduxAction(
    async (action) => {
      const roomUser = await joinTenant(action.payload);

      if (roomUser) {
        tenantJoined({ requestId: action.payload.requestId });

        setTimeout(() => {
          window.location.replace('/room');
        }, 1500);
      }
    },
    actions.joinTenant,
    []
  );
  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    tenant: state.tenant,
    collaborationServerUrl: state.collaborationServerUrl,
  }));

  useEffect(() => {
    getRedirectResult(getFirebaseAuth()).catch(setError);
  }, [tenant]);

  const providerGoogle = new GoogleAuthProvider();
  const providerMicrosoft = new OAuthProvider('microsoft.com');

  if (tenant?.azureAdDomain) {
    providerMicrosoft.setCustomParameters({
      tenant: tenant.azureAdDomain,
    });
  }

  // We create a new user object when it is updated to trigger state changes properly
  const updateUser = async (u: User) => {
    const { displayName, email, photoURL, providerData, uid, tenantId, isAnonymous } = u;
    // Using user.providerData gives you the array of providers with each provider having its own uid.
    // The list is sorted by the most recent provider used to sign in.
    const room = providerData.length === 0;

    const idTokenResult = await u.getIdTokenResult();
    const admin = !!idTokenResult.claims.admin;

    setUser({
      email,
      displayName,
      photoURL,
      providerId: room ? 'flatfrog-license-room' : providerData[0].providerId,
      room,
      uid,
      admin,
      tenantId,
      guest: isAnonymous,
    });
  };

  // Wrap any Firebase methods we want to use making sure to save the user to state.
  const signIn = async (email: string, password: string) => {
    try {
      const response = await signInWithEmailAndPassword(getFirebaseAuth(), email, password);

      await updateUser(response.user);
      setError(null);
    } catch (e) {
      setError(e);
    }
  };

  const signInAsGuest = async () => {
    try {
      await signInAnonymously(getFirebaseAuth());

      setUser(undefined);
      setError(null);
    } catch (e) {
      setError(e);
    }
  };

  const signInWithProvider = async (provider: AuthProvider, forceRedirect: boolean) => {
    const auth = getFirebaseAuth();
    // If mobile, we should try with popup first due to iOS 16.1+ issue:
    // https://github.com/firebase/firebase-js-sdk/issues/6716
    if (isMobile && !forceRedirect) {
      try {
        const response = await signInWithPopup(auth, provider);
        await updateUser(response.user);
        setError(null);
      } catch (e) {
        if (e.code === 'auth/popup-blocked') {
          await signInWithRedirect(auth, provider);
        } else {
          setError(e);
        }
      }
    } else {
      await signInWithRedirect(auth, provider);
    }
  };

  const signInGoogle = (forceRedirect = false) => signInWithProvider(providerGoogle, forceRedirect);

  const signInMicrosoft = async (forceRedirect = false) => signInWithProvider(providerMicrosoft, forceRedirect);

  const joinTenant = async ({ token, tenant: tenantId }: { token: string; tenant: string }): Promise<User | null> => {
    try {
      const auth = getFirebaseAuth();
      auth.tenantId = tenantId;
      const response = await signInWithCustomToken(auth, token);
      await updateUser(response.user);
      return response.user;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const signInRoom = async () => {
    let id = window.electronApi?.machineId;
    if (!id) {
      id = window.localStorage.getItem('roomId');
      if (!id) {
        id = uuidv4();
        window.localStorage.setItem('roomId', id);
      }
    }
    const url = `${window.INITIAL_STATE.collaborationServerUrl}/v2/room/token`;
    const resp = await makeRequest<{ token: string }>({
      method: 'POST',
      url,
      data: {
        device_id: id,
      },
      auth: {
        username: 'room-v1',
        password: window.INITIAL_STATE.roomAuth,
      },
    });

    try {
      const response = await signInWithCustomToken(getFirebaseAuth(), resp.data.token);

      await updateUser(response.user);
    } catch (e) {
      console.log(e);
    }
  };

  const signUp = async (email: string, password: string, name: string) => {
    if (!name) {
      setError({ code: 'auth/no-name' });
      return;
    }

    try {
      const response = await createUserWithEmailAndPassword(getFirebaseAuth(), email, password);

      analytics.signUp();
      await updateProfile(response.user, { displayName: name });

      await updateUser(response.user);
      setError(null);
    } catch (e) {
      setError(e);
    }
  };

  const acceptInvite = async (email: string, password: string, name: string, inviteId: string) => {
    if (!name) {
      setError({ code: 'auth/no-name' });
      return;
    }

    try {
      await makeRequest({
        method: 'PUT',
        url: `${collaborationServerUrl}/tenant/${tenant.id}/invites/${inviteId}`,
        data: { name, password },
      });

      const response = await signInWithEmailAndPassword(getFirebaseAuth(), email, password);

      analytics.signUp();
      await updateProfile(response.user, { displayName: name });

      await updateUser(response.user);
      setError(null);
    } catch (e) {
      setError(e);
    }
  };

  const signOut = useCallback(async () => {
    analytics.signOut();
    await getFirebaseAuth().signOut();
    setUser(undefined);
  }, []);

  useReduxAction(() => signOut(), actions.signout, [signOut]);

  const sendPasswordResetEmail2 = async (email: string) => {
    try {
      // Add a continue URL to visit after resetting the password
      const url = `https://${window.location.host}/login?email=${email}`;
      await sendPasswordResetEmail(getFirebaseAuth(), email, { url });
      return true;
    } catch (e) {
      return e;
    }
  };

  const reauthenticateWithPassword = async (currentPassword: string) => {
    const { currentUser } = getFirebaseAuth();

    try {
      const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
      await reauthenticateWithCredential(currentUser, credential);
      return true;
    } catch (e) {
      return e;
    }
  };

  const changePassword = async (newPassword: string) => {
    const { currentUser } = getFirebaseAuth();

    try {
      await updatePassword(currentUser, newPassword);
      return { success: true };
    } catch (e) {
      return { success: false, code: e.code };
    }
  };

  const changeDisplayName = async (displayName: string) => {
    const { currentUser } = getFirebaseAuth();

    try {
      await updateProfile(currentUser, { displayName });
      await currentUser.reload();
      await updateUser(currentUser);
      return true;
    } catch (e) {
      return e;
    }
  };

  const checkEmail = async (email: string) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(getFirebaseAuth(), email);

      if (tenant && signInMethods.length === 0) {
        setError({
          code: 'auth/invite-required',
          message:
            'You need to click the link in the invitation e-mail in order to create a new account. Please check your inbox or contact your admin user.',
        });
        return { valid: false, signInMethods };
      }

      return {
        valid: true,
        signInMethods,
      };
    } catch (e) {
      setError(e);

      return {
        valid: false,
        signInMethods: [],
      };
    }
  };

  const ackError = () => setError(null);

  const apolloClient = useApolloClient();

  useEffect(() => {
    getFirebaseAuth().tenantId = tenant ? tenant.firebaseId : null;
  }, [tenant]);

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any component that
  // utilizes this hook to re-render with the latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getFirebaseAuth(), async (authedUser) => {
      console.log('onAuthStateChanged', authedUser);
      console.log(`uid: ${authedUser?.uid}`);
      console.log(`tid: ${authedUser?.tenantId}`);

      if (authedUser) {
        if (authedUser.displayName) {
          analytics.signUp();
          await updateUser(authedUser);
        }

        analytics.signIn(authedUser.uid, authedUser.email);
        Sentry.setUser({
          id: authedUser.uid,
          email: authedUser.email,
        });
      } else {
        apolloClient.cache.reset();
        setUser(undefined);
        Sentry.configureScope((scope) => scope.setUser(null));
      }
      resetWsClient(!!authedUser);

      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    acceptInvite,
    ackError,
    changeDisplayName,
    changePassword,
    checkEmail,
    error,
    loading,
    reauthenticateWithPassword,
    sendPasswordResetEmail: sendPasswordResetEmail2,
    signIn,
    signInAsGuest,
    signInGoogle,
    signInMicrosoft,
    signInRoom,
    signOut,
    signUp,
    updateUser,
    user,
  };
};

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export const ProvideAuth: React.FC = ({ children }) => {
  const auth = createAuthContext();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => useContext(authContext);
