import React from 'react';
import styled from 'styled-components';

const EllipsisParent = styled.div.attrs(() => ({ className: 'lds-ellipsis-large' }))``;

const LoadingIndicator: React.FC = () => {
  return (
    <EllipsisParent>
      <div />
      <div />
      <div />
      <div />
    </EllipsisParent>
  );
};

export default LoadingIndicator;
