import styled from 'styled-components';

export const FilterButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  height: 32px;
  padding: 0 64px 0 16px;
  border-radius: 20px;
  color: #242424;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 300ms ease-out;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    top: 1px;
    bottom: 0;
    right: 16px;
    margin: auto 0;
    background: url(/icons/icn_list_arrows.svg) center center no-repeat;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
