import styled from 'styled-components';

import { FloatingPanel } from 'client/components/Common/common-styles';

export const PageOptionsMenu = styled.div`
  position: absolute;
  background: #ffffff;
  padding: 8px 0;
  border-radius: 6px;
  box-shadow: 0 0 0 0.5px #00000014, 0 4px 8px #00000029;
  font-size: 14px;
  text-align: left;
  cursor: initial;
  min-height: 60px;
  width: 220px;
  bottom: -8px;
  right: 56px;
  display: flex;
  flex-direction: column;
  user-select: none;
  z-index: 5;

  &:after,
  &:before {
    left: 100%;
    top: calc(100% - 26px);
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #d2d2d2;
    border-width: 9px;
    margin-top: -9px;
  }
`;

export const PageOptionsDelimiter = styled.div`
  position: relative;
  width: 35px;
  height: 1px;
  background: #00000029;
  margin: 10px 0;
`;

export const PageStatus = styled.div`
  font-size: 12px;
  color: #8c8c8c;
  user-select: none;
  margin: 4px 0;
  letter-spacing: -1px;
  white-space: nowrap;
`;

export const ReturnToPresenter = styled.button.attrs({
  type: 'button',
})`
  border: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  background: #279ae7 url(/icons/icn_to_presenter.svg) center center no-repeat;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  right: calc(100% - 8px);
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background-color: #238ad0;
  }
`;

export const Button = styled.div.attrs<{ disabled?: boolean }>((p) => ({
  onClick: p.disabled ? () => {} : p.onClick,
}))<{
  disabled?: boolean;
}>`
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  display: block;
  border: none;
  outline: none;
  color: #666;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  width: 100%;
  height: 40px;
  margin: 4px 0;
  border-radius: 4px;
  background-size: 14px;
  position: relative;
  user-select: none;
  opacity: ${(p) => (p.disabled ? 0.3 : 1)};

  @media (hover) {
    &:hover {
      background-color: ${(p) => (p.disabled ? 'transparent' : '#0000001a')};
    }
  }
  -webkit-tap-highlight-color: #e6e6e6;
`;

export const GridViewButton = styled(Button)`
  background: transparent url(/icons/icn_grid_view.svg) center center no-repeat;
`;

export const ScrollPrevButton = styled(Button)`
  background: transparent url(/icons/icn_page_forward.svg) center center no-repeat;
  height: 32px;
`;

export const ScrollNextButton = styled(Button)`
  background: transparent url(/icons/icn_page_back.svg) center center no-repeat;
  height: 32px;
`;

export const AddPageButton = styled(Button)`
  background: transparent url(/icons/icn_add_page.svg) center center no-repeat;
`;

export const SearchButton = styled(Button)`
  background: transparent url(/icons/icn_search_active.svg) center center no-repeat;
`;

export const PageOptionsButton = styled(Button)`
  background: transparent url(/icons/icn_page-menu.svg) center center no-repeat;

  &.active {
    ${PageOptionsMenu} {
      display: block;
    }
  }
`;

export const PageControls = styled.div.attrs((props) => ({
  style: props.hidden ? { opacity: 0 } : {},
}))`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  right: 8px;
  top: 50%;
  width: 56px;
  transform: translateY(-50%);

  ${FloatingPanel};

  &.room {
    left: 50%;
    right: auto;
    top: 8px;
    transform: translate(-50%, 0);
    width: auto;
    height: 56px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${Button} {
      width: 40px;
      height: 40px;
      margin: 0 4px 0 4px;
    }

    ${ScrollPrevButton} {
      transform: rotate(-90deg);
      margin: 0 12px 0 4px;
    }

    ${ScrollNextButton} {
      transform: rotate(-90deg);
      margin: 0 4px 0 12px;
    }
  }
`;

export const MenuItem = styled(Button)`
  outline: none;
  border: none;
  padding: 11px 24px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 13px;
  overflow: hidden;
`;

export const DeleteMenuItem = styled(MenuItem)`
  color: #de4b4d;
`;

export const MenuDelimiter = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background: #00000029;
  margin: 4px 0;
`;

export const ImageMenuItem = styled(MenuItem)`
  > label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    width: 100%;
    color: transparent;
    cursor: pointer;

    > input {
      pointer-events: none;
    }
  }
`;
