import React, { useCallback, useEffect, useState } from 'react';
import { isNumber } from 'lodash';

import SmallCheckbox from 'client/components/Common/small-checkbox';
import LoadingIndicator from 'client/components/Common/loadingIndicator';
import { makeRange } from 'client/common/util';

import * as Styled from './styled';

type SortFunction<T> = (items: T[], asc: boolean) => T[];

interface ListColumn<T> {
  id: string;
  title?: string;
  header?: React.ReactElement;
  content: (item: T) => React.ReactElement;
  sort?: SortFunction<T>;
}

interface Props<T> {
  items: T[];
  itemKey: (item: T) => React.Key;
  itemColumns: ListColumn<T>[];
  loading?: boolean;
  pagination?: boolean;
  itemsPerPage?: number;
  itemNoun?: string;
  selection?: boolean;
  addElementText?: string;
  onAddElementClick?: () => void;
}

const List = <T,>({
  items,
  itemKey,
  itemColumns,
  loading,
  pagination = true,
  itemsPerPage = 10,
  itemNoun = 'items',
  selection = true,
  addElementText,
  onAddElementClick,
}: React.PropsWithChildren<Props<T>>): React.ReactElement => {
  const [selectedItems, setSelectedItems] = useState<Set<T>>(new Set());

  const isAllChecked = selectedItems.size === items.length;

  const toggleSelection = useCallback(
    (item: T) => {
      if (selectedItems.has(item)) {
        setSelectedItems((s) => new Set([...s].filter((x) => x !== item)));
      } else {
        setSelectedItems((s) => new Set([...s, item]));
      }
    },
    [selectedItems]
  );

  const [currentPage, setCurrentPage] = useState(1);

  const numberOfItems = items.length;
  const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);

  const startPages = [1];
  const endPages = makeRange(Math.max(numberOfPages, 2), numberOfPages);
  /**
   * How many siblings to show next to current item. Examples below:
   *
   * 0 siblings:
   * < 1 .. (14) .. 29 >
   *
   * 1 sibling:
   * < 1 .. 13 (14) 15 .. 29 >
   *
   * 2 siblings:
   * < 1 .. 12 13 (14) 15 16 .. 29 >
   */
  const siblingCount = 1;
  const siblingsStart = Math.max(Math.min(currentPage - siblingCount, numberOfPages - siblingCount * 2 - 2), 3);
  const siblingsEnd = Math.min(
    Math.max(currentPage + siblingCount, siblingCount * 2 + 3),
    numberOfPages > 1 ? numberOfPages - 2 : numberOfPages - 1
  );

  const gotoPage = useCallback(
    (page: number) => {
      setCurrentPage(() => page);
    },
    [currentPage]
  );

  const gotoPreviousPage = () => {
    setCurrentPage((p) => {
      if (p <= 1) {
        return p;
      }

      return p - 1;
    });
  };

  const gotoNextPage = () => {
    setCurrentPage((p) => {
      if (p >= numberOfPages) {
        return p;
      }

      return p + 1;
    });
  };

  type StringOrNumber = string | number;
  const pagesToShow: StringOrNumber[] = [
    ...startPages,
    ...(siblingsStart > 3 ? ['dots'] : numberOfPages - 1 > 2 ? [2] : []),
    ...makeRange(siblingsStart, siblingsEnd),
    ...(siblingsEnd < numberOfPages - 2 ? ['dots'] : numberOfPages - 1 > 1 ? [numberOfPages - 1] : []),
    ...endPages,
  ];

  const firstItemShown = (currentPage - 1) * itemsPerPage + 1;
  const lastItemShown = Math.min(currentPage * itemsPerPage, numberOfItems);

  const [sortFunction, setSortFunction] = useState<SortFunction<T> | null>(null);
  const [sortAscending, setSortAscending] = useState(true);

  const sortedItems = sortFunction ? sortFunction([...items], sortAscending) : items;
  const itemsToShow = pagination
    ? sortedItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : sortedItems;

  if (loading) {
    return (
      <Styled.ListContainer>
        <Styled.ListLoading>
          <LoadingIndicator />
        </Styled.ListLoading>
      </Styled.ListContainer>
    );
  }

  return (
    <Styled.ListContainer>
      <Styled.ListContent>
        <Styled.ListHeader>
          {selection && (
            <Styled.ListCheckbox>
              <SmallCheckbox
                onChange={() => setSelectedItems(new Set(isAllChecked ? [] : items))}
                checked={isAllChecked}
              />
            </Styled.ListCheckbox>
          )}
          <Styled.ListItemContent>
            {itemColumns.map(
              (column) =>
                column.header &&
                React.cloneElement(
                  column.header,
                  {
                    onClick: () => {
                      if (!column.sort) {
                        return;
                      }

                      if (column.sort === sortFunction) {
                        if (sortAscending) {
                          setSortAscending(false);
                        } else {
                          setSortFunction(null);
                          setSortAscending(true);
                        }
                      } else {
                        console.log('A');
                        setSortFunction(() => column.sort);
                        setSortAscending(true);
                      }
                    },
                    style: {
                      cursor: column.sort ? 'pointer' : 'default',
                    },
                  },
                  <>
                    {column.title}
                    {column.title && (
                      <Styled.ListSortingIcon visible={sortFunction === column.sort} reversed={sortAscending} />
                    )}
                  </>
                )
            )}
          </Styled.ListItemContent>
        </Styled.ListHeader>
        <Styled.ListBody>
          {addElementText && onAddElementClick && (
            <Styled.ListBodyAddItem selection={selection} onClick={() => onAddElementClick()}>
              <Styled.AddItemIcon />
              <Styled.AddItemText>{addElementText}</Styled.AddItemText>
            </Styled.ListBodyAddItem>
          )}
          {itemsToShow.map((item) => (
            <Styled.ListBodyItem key={itemKey(item)} selected={selectedItems.has(item)}>
              {selection && (
                <Styled.ListCheckbox>
                  <SmallCheckbox onChange={() => toggleSelection(item)} checked={selectedItems.has(item)} />
                </Styled.ListCheckbox>
              )}
              <Styled.ListItemContent>
                {itemColumns.map((column) => {
                  return column.content(item);
                })}
              </Styled.ListItemContent>
            </Styled.ListBodyItem>
          ))}
        </Styled.ListBody>
      </Styled.ListContent>
      {pagination && (
        <Styled.ListPagination>
          <Styled.ListPaginationButtons>
            <Styled.ListPaginationButton onClick={() => gotoPreviousPage()} disabled={currentPage <= 1}>
              <Styled.ListPaginationLeftIcon />
            </Styled.ListPaginationButton>
            {pagesToShow.map((p) => {
              if (p === 'dots') {
                return <Styled.ListPaginationEllipsis>…</Styled.ListPaginationEllipsis>;
              }
              if (isNumber(p)) {
                return (
                  <Styled.ListPaginationButton className={currentPage === p && 'current'} onClick={() => gotoPage(p)}>
                    {p}
                  </Styled.ListPaginationButton>
                );
              }
              return null;
            })}
            <Styled.ListPaginationButton onClick={() => gotoNextPage()} disabled={currentPage >= numberOfPages}>
              <Styled.ListPaginationRightIcon />
            </Styled.ListPaginationButton>
          </Styled.ListPaginationButtons>
          <Styled.ListPaginationText>
            {firstItemShown}-{lastItemShown} of {numberOfItems} {itemNoun}
          </Styled.ListPaginationText>
        </Styled.ListPagination>
      )}
    </Styled.ListContainer>
  );
};

export default List;
