import { useQuery } from '@apollo/client';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { QUERY_MY_ITEMS } from 'client/common/graphql';
import ToolBar from 'client/components/Whiteboard/Toolbar';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { getClipboardingEnabled } from 'client/state/selectors';

import * as Styled from './styled';

interface Props {
  hidden: boolean;
}

const SideBar: React.FC<Props> = ({ hidden }) => {
  const { clipboardingEnabled, localItems, room, showItemStack } = useSelector((state) => ({
    clipboardingEnabled: getClipboardingEnabled(state),
    localItems: state.localItems.length,
    room: state.room,
    showItemStack: state.showItemStack,
  }));

  const { setShowItemStack } = useActions();

  const { data } = useQuery(QUERY_MY_ITEMS);
  const auth = useAuth();
  const stackCount = auth.user ? data?.items?.length || 0 : localItems;

  useHotkeys(
    'd',
    () => {
      if (clipboardingEnabled) {
        setShowItemStack(!showItemStack);
      }
    },
    {},
    [showItemStack, clipboardingEnabled]
  );

  return (
    <>
      <Styled.SideBarWrapper hidden={hidden} className={room ? 'room' : ''}>
        <div style={{ marginBottom: 'auto' }}>
          {!room && (
            <Styled.StackButton
              data-place="right"
              data-tip={!showItemStack ? 'My Items <span class="shortcut">D</span>' : ''}
              onPointerUp={() => {
                setShowItemStack(!showItemStack);
              }}
            >
              {stackCount > 0 && <span>{stackCount}</span>}
            </Styled.StackButton>
          )}
          <ToolBar />
        </div>
      </Styled.SideBarWrapper>
    </>
  );
};

export default SideBar;
