import React from 'react';

import { useSelector } from 'client/hooks/useSelector';

import * as Styled from '../styled';

const RoomAppLink: React.FC = () => {
  const tenant = useSelector((state) => state.tenant);

  return tenant ? (
    <Styled.RoomAppDownloadLink to="/dashboard/download-room-app">Go to download page</Styled.RoomAppDownloadLink>
  ) : (
    <Styled.RoomAppReadMoreLink href="https://www.flatfrog.com/flatfrog-board/" target="_blank">
      Read more
    </Styled.RoomAppReadMoreLink>
  );
};

export default RoomAppLink;
