import styled from 'styled-components';

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 4;
  position: absolute;
  left: 8px;
  right: 8px;
  top: 16px;
  pointer-events: none;
`;
