export interface Member {
  id: string;
  email?: string;
  displayName?: string;
  room: boolean;
  admin: boolean;
  disabled: boolean;
}

export type RoomUser = {
  id: string;
  displayName?: string;
  email?: string;
  admin: boolean;
  room: boolean;
  lastActive?: string;
};

export interface Invite {
  id: string;
  email: string;
  dateCreated: number;
  dateAccepted: number;
  dateRevoked: number;
}

export enum AutoSyncBindingProvider {
  TEAMS = 'teams',
}

export interface AutoSyncMeetingUser {
  id: string;
  name: string;
  photo?: {
    blobUrl?: string;
  };
}

export interface AutoSyncRoomUser {
  id: string;
  name: string;
}

export interface AutoSyncBinding {
  id: string;
  // provider: AutoSyncBindingProvider;
  meetingUserId: string;
  roomUserId: string;
  meetingUser: AutoSyncMeetingUser;
  roomUser: AutoSyncRoomUser;
}

export interface AzureADInfo {
  id: string;
  domain: string;
  directoryStats: {
    totalUsers: number;
    ffbUsers: number;
  };
}

export interface DirectoryUser {
  id: string;
  name: string;
  photo?: {
    blobUrl?: string;
  };
  metadata: {
    firebaseUserId: string;
    linkedProviders: {
      providerId: string;
      providerUserId: string;
    }[];
  };
}
