import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import GraphemeSplitter from 'grapheme-splitter';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import * as analytics from 'client/common/analytics';
import { getFirebaseAuth } from 'client/common/firebase';
import {
  QUERY_TEAMS,
  QUERY_FILE_LIMITS,
  MUTATION_DELETE_FILE,
  MUTATION_REMOVE_LINK,
  MUTATION_RENAME_FILE,
  MUTATION_DUPLICATE_FILE,
  MUTATION_MOVE_FILE,
  CACHE_ID_RECENTS,
  CACHE_ID_SHARED_WITH_ME,
  FileOrFileLink,
  File,
} from 'client/common/graphql';
import intersectionObserver from 'client/common/intersectionObserver';
import getRole from 'client/common/userRole';
import { CONNECTION_FAIL_REASON, CONNECTION_STATUS } from 'client/common/util';
import CastDialog from 'client/components/Cast/CastDialog';
import MobileCastDialog from 'client/components/Cast/MobileCast';
import BoardNameDialog from 'client/components/Common/BoardNameDialog';
import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import { ModalBackDrop } from 'client/components/Common/modal-styled';
import Admin from 'client/components/Dashboard/admin';
import MyAccountColumn from 'client/components/Dashboard/my-account/my-account';
import FeedbackDialog from 'client/components/FeedbackDialog';
import BoardCodeInfo from 'client/components/Home/boardcodeinfo';
import ItemStack from 'client/components/ItemStack';
import MobileItemStack from 'client/components/ItemStack/MobileItemStack';
import MobileJoinDialog from 'client/components/MobileJoinDialog';
import StickyNoteEditor from 'client/components/StickyNoteEditor';
import TextBlockEditor from 'client/components/TextBlockEditor';
import LoadingBoard from 'client/components/Whiteboard/LoadingBoard';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useCodeInput from 'client/hooks/useCodeInput';
import useEventListener from 'client/hooks/useEventListener';
import { useResetConnectionAndSession } from 'client/hooks/useResetConnectionAndSession';
import { useSelector } from 'client/hooks/useSelector';
import NameBubble from 'client/components/Common/NameBubble';
import { ContextMenu } from 'client/components/Common/ContextMenu';

import DashboardColumn from './dashboard/dashboard';
import MobileDashboard from './mobile/MobileDashboard';
import MyBoardsIndex from './my-boards';
import MoveFileDialog from './my-boards/move-file';
import ShareDialog from './my-boards/share-dialog';
import * as Styled from './styled';
import TemplateGalleryModal from './templates/template-gallery-modal';
import Upload from './upload/upload';
import UserRoleSelection from './user-role-selection';
import DownloadRoomApp from './download-room-app';

const Dashboard: React.FC = () => {
  const { session, showFeedbackModal, showItemStack, showStickyNoteEditor, showTextBlockEditor, userRole, tenant } =
    useSelector((state) => ({
      session: state.session,
      showFeedbackModal: state.showFeedbackModal,
      showItemStack: state.showItemStack,
      showStickyNoteEditor: state.currentStickyNoteEdit,
      showTextBlockEditor: state.currentTextBlockEdit,
      tenant: state.tenant,
      userRole: state.userRole,
    }));

  const {
    agreeToTermsAndPolicy,
    connectFailure,
    currentStickyNoteEdit,
    currentTextBlockEdit,
    disableClipBoarding,
    enableClipBoarding,
    setShowFeedbackModal,
    setShowItemStack,
    setSignature,
    setUserRole,
    tryConnectNew,
    tryConnectWithCode,
  } = useActions();

  const [backDrop, setBackDrop] = useState('');
  const { code, codeInput, handleCodeChange, hint: codeInputHint } = useCodeInput();
  const [boardToDelete, setBoardToDelete] = useState(undefined);
  const [boardToRename, setBoardToRename] = useState<File>(undefined);
  const [linkToRemove, setLinkToRemove] = useState(undefined);
  const [codeInputFocused, setCodeInputFocused] = useState(false);
  const [showBoardCodeInfo, setShowBoardCodeInfo] = useState(false);
  const [submittedCode, setSubmittedCode] = useState<string | false>(false);
  const [showLoadingSession, setShowLoadingSession] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [showMoveTo, setShowMoveTo] = useState(undefined);
  const [showShareDialog, setShowShareDialog] = useState(undefined);
  const [showConfirmMoveTo, setShowConfirmMoveTo] = useState(undefined);
  const [showErrorDialog, setShowErrorDialog] = useState(undefined);
  const [showUserRoleDialog, setShowUserRoleDialog] = useState(false);
  const [showUnableToDeleteDialog, setShowUnableToDeleteDialog] = useState<string | false>(false);
  const [showJoinDialog, setShowJoinDialog] = useState(false);

  const auth = useAuth();
  const history = useHistory();
  const location = useLocation<{ from?: string; teamId?: string }>();
  const urlParams = useParams<{ view?: string; subview?: string }>();

  const apolloClient = useApolloClient();

  // Could merge into dashboard query
  const teamsQuery = useQuery(QUERY_TEAMS);
  const { data: fileLimits } = useQuery(QUERY_FILE_LIMITS);

  const connectWithCode = (_code: string) => {
    setShowLoadingSession(true);
    tryConnectWithCode(_code);
    setSubmittedCode(_code);
  };

  const handleCodeInputSubmit = (e: { key: string }) => {
    if (e.key === 'Enter' && code.length === 6) {
      connectWithCode(code);
    }
  };

  // Close menus when clicking anywhere
  useEventListener<PointerEvent>(
    'pointerdown',
    (e) => {
      window.dispatchEvent(new CustomEvent('closemenus', { detail: e.target }));
    },
    document
  );

  useEffect(() => {
    enableClipBoarding('Dashboard', true);
    return () => {
      enableClipBoarding('Dashboard', false);
    };
  }, []);

  useEffect(() => {
    disableClipBoarding('codeInputFocused', codeInputFocused);
  }, [codeInputFocused]);

  useEffect(() => {
    if (session.failure) {
      setShowLoadingSession(false);
      setSubmittedCode((submitted) => {
        if (submitted) {
          analytics.invalidCode();
        }
        return false;
      });
      if (code.length !== 6) {
        connectFailure();
      }
    }
  }, [session.failure, code]);

  // When we have a session setup and in waiting phase, go to /session
  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting && showLoadingSession) {
      if (submittedCode) {
        analytics.validCode();
      }
      disableClipBoarding('codeInputFocused', false);
      if (location.state?.from) {
        history.replace(location.state.from);
      } else {
        history.replace('/dashboard');
      }
      history.push('/session', { from: 'home' });
    } else if (session?.status === CONNECTION_STATUS.failed) {
      setConnectionError(true);
    } else {
      setShowLoadingSession(false);
      setConnectionError(false);
    }
  }, [session]);

  const resetConnectionAndSession = useResetConnectionAndSession();

  useEffect(() => {
    if (session?.id) {
      // Someone left the board or went from connected to here, let's reset
      resetConnectionAndSession();
    }

    (async () => {
      const r = await getRole();
      setUserRole(r.role);
      if (r.showDialog) {
        setShowUserRoleDialog(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (urlParams.view === 'open' && urlParams.subview) {
      setShowLoadingSession(true);
      tryConnectNew({ fileInfo: { id: urlParams.subview } });
      analytics.setWorkspace(location.state?.teamId);
    } else if (urlParams.view) {
      if (urlParams.subview) {
        analytics.viewPage(`/dashboard/${urlParams.view}/${urlParams.subview}`);
      } else {
        analytics.viewPage(`/dashboard/${urlParams.view}`);
      }
    } else {
      setShowLoadingSession(false);
      analytics.viewPage('/dashboard');
    }
  }, [urlParams]);

  useEffect(() => {
    if (auth.user) {
      // The user agreed by signing in
      agreeToTermsAndPolicy(true);
    }
    // FIXME: For now, update the signature here so the user stops being an animal,
    // but we should allow the user to set a signature later
    const { displayName } = auth.user || {};

    if (displayName?.length > 0) {
      const user = getFirebaseAuth().currentUser;
      // For new users, the first token is missing display name,
      // force a refresh to make sure the token contains a name
      user.getIdToken(true);

      let signature = displayName.split(' ')[0];
      const graphemeSplitter = new GraphemeSplitter();
      if (graphemeSplitter.countGraphemes(signature) > 15) {
        const split = graphemeSplitter.splitGraphemes(signature);
        signature = split.slice(0, 16).join('');
      }
      setSignature(signature);
    } else {
      setSignature('');
    }
  }, [auth.user]);

  useEffect(() => {
    if (showStickyNoteEditor || showTextBlockEditor) {
      setBackDrop('active invisible');
    } else {
      setBackDrop('');
    }
  }, [showStickyNoteEditor, showTextBlockEditor]);

  useEffect(() => {
    if (!showItemStack) {
      currentStickyNoteEdit(null);
      currentTextBlockEdit(null);
    }
  }, [showItemStack]);

  const [duplicateFile] = useMutation(MUTATION_DUPLICATE_FILE);

  const duplicateBoard = (id: string) => {
    duplicateFile({ variables: { id } });
  };

  const [moveFile] = useMutation(MUTATION_MOVE_FILE);

  const handleMoveFile = (teamId: string, fileId: string) => {
    if (teamId === 'personal') {
      teamId = undefined;
    }
    moveFile({ variables: { input: { teamId, fileId } } })
      .then((response) => {
        if (response.data?.moveFileToTeam?.success) {
          analytics.moveFile(!!teamId);
          setShowConfirmMoveTo(undefined);
        } else {
          setShowConfirmMoveTo(undefined);
          let errorText = 'Unknown error';
          switch (response.data?.moveFileToTeam?.error) {
            case 'FILE_LIMIT':
              errorText = 'Receiving Workspace has no quota left';
              break;
            case 'NOT_ALLOWED':
              errorText = 'Not allowed';
              break;
            default:
              break;
          }
          setShowErrorDialog(errorText);
        }
      })
      .catch((e) => {
        console.error(e);
        setShowErrorDialog('Network error, please try again');
      });
  };

  useLayoutEffect(() => {
    const menus = document.getElementsByClassName('menu-container');
    intersectionObserver.disconnect();
    Array.from(menus).forEach((el) => intersectionObserver.observe(el));
  });

  const [boardToCast, setBoardToCast] = useState<FileOrFileLink>(null);

  const showCastDialog = async (e: React.MouseEvent, boardFile: FileOrFileLink) => {
    e.preventDefault();
    e.stopPropagation();

    setBoardToCast(boardFile);
  };

  const [deleteBoardFile] = useMutation(MUTATION_DELETE_FILE);

  const [removeBoardLink] = useMutation(MUTATION_REMOVE_LINK, {
    onCompleted: () => {
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: CACHE_ID_SHARED_WITH_ME,
      });
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: CACHE_ID_RECENTS,
      });
    },
  });

  const [renameFile] = useMutation(MUTATION_RENAME_FILE);

  const deleteBoard = async (id: string) => {
    setBoardToDelete(undefined);
    const r = await deleteBoardFile({ variables: { id } });
    if (!r.data.deleteFile.success) {
      setShowUnableToDeleteDialog(
        r.data.deleteFile.error === 'FILE_OPEN'
          ? 'The board is currently being used by someone'
          : 'Unable to delete board'
      );
    }
  };

  const removeLink = (link: { id: string }) => {
    setLinkToRemove(undefined);
    removeBoardLink({ variables: { linkId: link.id } });
  };

  if (isMobileOnly) {
    return (
      <>
        <MobileItemStack />
        <MobileDashboard
          setShowItemStack={setShowItemStack}
          setShowJoinDialog={setShowJoinDialog}
          showCastDialog={showCastDialog}
        />
        {showLoadingSession && <LoadingBoard failure={connectionError && CONNECTION_FAIL_REASON.notFound} />}
        {boardToCast !== null && (
          <MobileCastDialog stay={false} file={boardToCast} onCancel={() => setBoardToCast(null)} />
        )}
        {showJoinDialog && <MobileJoinDialog onSubmit={connectWithCode} onCancel={() => setShowJoinDialog(false)} />}
      </>
    );
  }

  return (
    <>
      <Styled.ScrollArea>
        <Switch>
          <Route exact path="/dashboard/my-account">
            <MyAccountColumn userName={auth.user?.displayName} userEmail={auth.user?.email} />
          </Route>
          <Route exact path="/dashboard/download-room-app">
            <DownloadRoomApp />
          </Route>
          <Route path="/dashboard/admin">{auth.user?.admin && <Admin />}</Route>
          <Route path="/dashboard/my-boards">
            <MyBoardsIndex
              showShareDialog={showShareDialog}
              setShowShareDialog={setShowShareDialog}
              showMoveTo={showMoveTo}
              setShowMoveTo={setShowMoveTo}
              deleteBoard={setBoardToDelete}
              renameBoard={setBoardToRename}
              duplicateBoard={duplicateBoard}
              removeLink={setLinkToRemove}
              allowedToCreateFile={fileLimits?.allowedToCreateFile ?? { allowed: false }}
              showCastDialog={showCastDialog}
            />
          </Route>
          <Route path="/dashboard">
            <DashboardColumn
              deleteBoard={setBoardToDelete}
              renameBoard={setBoardToRename}
              duplicateBoard={duplicateBoard}
              removeLink={setLinkToRemove}
              setShowShareDialog={setShowShareDialog}
              setShowMoveTo={setShowMoveTo}
              setShowLoading={setShowLoadingSession}
              user={auth.user}
              userRole={userRole}
              allowedToCreateFile={fileLimits?.allowedToCreateFile ?? { allowed: false }}
              showCastDialog={showCastDialog}
            />
          </Route>
        </Switch>
      </Styled.ScrollArea>
      <Switch>
        <Route exact path="/dashboard/my-boards">
          <Styled.BackButton id="back-button" onClick={() => history.push('/dashboard')} />
        </Route>
      </Switch>
      <Styled.TopBar>
        <Styled.NavGroup>
          <Styled.Logo to="/dashboard" exact id="topbar-dashboard-link" />
          <Styled.Home to="/dashboard" exact id="topbar-home-link" />
          <Styled.Link to="/dashboard/my-boards" id="topbar-myboards-link">
            Boards
          </Styled.Link>
          <Styled.Link
            to="/dashboard/my-items"
            id="topbar-myitems-link"
            onClick={(e) => {
              e.preventDefault();
              setShowItemStack(true);
            }}
          >
            My Items
          </Styled.Link>
        </Styled.NavGroup>
        <Styled.BoardCodeGroup>
          <Styled.CodeInput
            ref={codeInput}
            id="code-input"
            isFocused={codeInputFocused}
            onFocus={() => setCodeInputFocused(true)}
            onBlur={() => setCodeInputFocused(false)}
            onChange={handleCodeChange}
            onKeyDown={handleCodeInputSubmit}
            className={session.failure && 'error'}
          />
          <Styled.BoardCodeHint
            className={codeInputHint}
            id="code-hint-enter"
            onClick={() => {
              if (code.length > 0) {
                codeInput.current.focus();
                handleCodeInputSubmit({ key: 'Enter' });
              } else {
                setShowBoardCodeInfo(true);
              }
            }}
          />
        </Styled.BoardCodeGroup>
        <div style={{ width: '33%' }}>
          <ContextMenu
            items={[
              [
                { title: 'My Account', onClick: () => history.push('/dashboard/my-account') },
                { title: 'Admin Settings', onClick: () => history.push('/dashboard/admin'), hidden: !auth.user?.admin },
              ],
              [
                { title: 'Give Feedback', onClick: () => setShowFeedbackModal(true) },
                { title: 'Help', onClick: () => window.open('https://help.flatfrog.com/knowledge', '_blank') },
              ],
              [
                {
                  title: 'Sign out',
                  id: 'signout-button',
                  onClick: () => {
                    setShowItemStack(false);
                    setSignature('');
                    history.push('/dashboard');
                    auth.signOut();
                  },
                },
              ],
            ]}
          >
            <Styled.UserGroup id="user-button">
              {tenant ? (
                <Styled.UserNameWithTenant>
                  {auth.user?.displayName}
                  <span>{tenant.displayName}</span>
                </Styled.UserNameWithTenant>
              ) : (
                <Styled.UserName>{auth.user?.displayName}</Styled.UserName>
              )}
              <NameBubble displayName={auth.user?.displayName} />
            </Styled.UserGroup>
          </ContextMenu>
        </div>
      </Styled.TopBar>
      <ModalBackDrop
        className={backDrop}
        onClick={() => {
          currentStickyNoteEdit(null);
          currentTextBlockEdit(null);
        }}
      />
      <ItemStack />
      <StickyNoteEditor />
      <TextBlockEditor />
      {boardToDelete && (
        <Dialog
          onCancel={() => setBoardToDelete(undefined)}
          noCloseButton
          cancelText="Cancel"
          nextText="Delete"
          onNext={() => deleteBoard(boardToDelete.id)}
          nextDisabled={false}
          cancelDisabled={false}
          cover
        >
          <DialogStyled.Title>{`Delete board ${boardToDelete.fileName}?`}</DialogStyled.Title>
        </Dialog>
      )}
      {linkToRemove && (
        <Dialog
          onCancel={() => setLinkToRemove(undefined)}
          noCloseButton
          cancelText="Cancel"
          nextText="Remove"
          onNext={() => removeLink(linkToRemove)}
          nextDisabled={false}
          cancelDisabled={false}
          cover
        >
          <DialogStyled.Title>{`Remove link to board ${linkToRemove.file.fileName}?`}</DialogStyled.Title>
        </Dialog>
      )}
      {boardToRename && (
        <BoardNameDialog
          fileName={boardToRename.fileName}
          onCancel={() => setBoardToRename(undefined)}
          title="Rename Board"
          nextText="Rename"
          onNext={(newName) => {
            setBoardToRename(undefined);
            renameFile({ variables: { id: boardToRename.id, fileName: newName } });
          }}
        />
      )}
      {showUserRoleDialog && (
        <UserRoleSelection
          onClose={(role) => {
            setShowUserRoleDialog(false);
            setUserRole(role);
          }}
        />
      )}
      {showShareDialog && <ShareDialog file={showShareDialog} onClose={() => setShowShareDialog(undefined)} />}
      {showMoveTo && (
        <MoveFileDialog
          teams={teamsQuery?.data?.teams?.teams}
          preselectedTeam={showMoveTo.teamId}
          onClose={() => setShowMoveTo(undefined)}
          confirmMove={(teamId) => {
            if (teamId === 'personal') {
              handleMoveFile(undefined, showMoveTo.fileId);
            } else if (
              teamsQuery.data.teams.teams.find((t) => t.id === teamId).members.find((m) => m.uid === auth.user?.uid)
                .role === 'Owner'
            ) {
              handleMoveFile(teamId, showMoveTo.fileId);
            } else {
              setShowConfirmMoveTo({ teamId, fileId: showMoveTo.fileId });
            }
            setShowMoveTo(undefined);
          }}
        />
      )}
      {showErrorDialog && (
        <Dialog
          noCloseButton={true}
          noCancelButton={true}
          nextText="Ok"
          onNext={() => setShowErrorDialog(undefined)}
          cover
        >
          <DialogStyled.Title>Unable to move Board</DialogStyled.Title>
          <DialogStyled.Body>{showErrorDialog}</DialogStyled.Body>
        </Dialog>
      )}
      <ReactTooltip />
      <Switch>
        <Route path={['/dashboard/templates', '/dashboard/my-boards/templates']}>
          {fileLimits?.allowedToCreateFile?.allowed && (
            <TemplateGalleryModal
              userRole={userRole}
              onSelect={(template) => {
                tryConnectNew({ fileInfo: template });
                setShowLoadingSession(true);
              }}
              onClose={() => {
                history.replace(location.pathname.replace('/templates', ''));
              }}
            />
          )}
        </Route>
        <Route path={['/dashboard/upload', '/dashboard/my-boards/upload']}>
          <Upload
            numberOfBoards={fileLimits?.sizeFiles}
            deleteBoard={deleteBoard}
            allowedToCreateFile={fileLimits?.allowedToCreateFile ?? { allowed: false }}
            onClose={() => {
              history.replace(location.pathname.replace('/upload', ''));
            }}
          />
        </Route>
      </Switch>
      {showConfirmMoveTo && (
        <Dialog
          cancelText="Cancel"
          nextText="Transfer"
          onCancel={() => setShowConfirmMoveTo(undefined)}
          onNext={() => {
            const { teamId, fileId } = showConfirmMoveTo;
            handleMoveFile(teamId, fileId);
          }}
          cover
        >
          <DialogStyled.Title>
            Move to {teamsQuery?.data?.teams?.teams.find((t) => t.id === showConfirmMoveTo.teamId).name}
          </DialogStyled.Title>
          <DialogStyled.Body>
            Your ownership of the Board will be transferred to the Workspace's owner{' '}
            {
              teamsQuery?.data?.teams?.teams
                .find((t) => t.id === showConfirmMoveTo.teamId)
                .members.find((m) => m.role === 'Owner').displayName
            }
            . Are you sure you want to transfer ownership?
          </DialogStyled.Body>
        </Dialog>
      )}
      {showUnableToDeleteDialog && (
        <Dialog
          nextText="Ok"
          onNext={() => {
            setShowUnableToDeleteDialog(false);
          }}
          cover
          noCancelButton
        >
          <DialogStyled.Title>Unable to delete board</DialogStyled.Title>
          <DialogStyled.Body>{showUnableToDeleteDialog}</DialogStyled.Body>
        </Dialog>
      )}
      {showBoardCodeInfo && <BoardCodeInfo onClose={() => setShowBoardCodeInfo(false)} />}
      {showFeedbackModal && <FeedbackDialog />}
      {showLoadingSession && <LoadingBoard failure={connectionError && CONNECTION_FAIL_REASON.notFound} />}
      {boardToCast !== null && <CastDialog stay={false} file={boardToCast} onCancel={() => setBoardToCast(null)} />}
    </>
  );
};

export default Dashboard;
