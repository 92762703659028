import React, { useState } from 'react';
import dayjs from 'dayjs';

import * as Assets from 'client/components/Dashboard/admin/assets';
import { useFetch } from 'client/hooks/useFetch';
import { RoomUser } from 'client/components/Dashboard/admin/types';
import List from 'client/components/Dashboard/admin/list/list';
import User from 'client/components/Common/user';
import AddRoomDialog from 'client/components/Dashboard/admin/rooms/add-dialog';

import * as Styled from './styled';

const Rooms: React.FC = () => {
  const rooms = useFetch<RoomUser[]>('rooms', [], (data) =>
    data.sort((a, b) => dayjs(b.lastActive ?? 0).diff(a.lastActive ?? 0))
  );

  const [showAddRoomDialog, setShowAddRoomDialog] = useState(false);

  return (
    <>
      <Styled.ColumnHeader>
        <Styled.ColumnHeaderIcon>
          <Assets.AdminRoomsIcon />
        </Styled.ColumnHeaderIcon>
        <Styled.ColumnHeaderText>Rooms</Styled.ColumnHeaderText>
      </Styled.ColumnHeader>
      <List
        items={rooms.data}
        itemKey={(room) => room.id}
        itemColumns={[
          {
            id: 'room',
            title: 'Room',
            header: <Styled.FlexBox weight={8} />,
            content: (room) => (
              <Styled.FlexBox weight={8}>
                <User room={true} displayName={room.displayName} />
              </Styled.FlexBox>
            ),
            sort: (items, asc) => items.sort((a, b) => a.displayName.localeCompare(b.displayName) * (asc ? 1 : -1)),
          },
          {
            id: 'last-active',
            title: 'Last active',
            header: <Styled.FlexBox weight={4} />,
            content: (room) => (
              <Styled.FlexBox weight={4} style={{ fontSize: '13px' }}>
                {room.lastActive && dayjs(room.lastActive).fromNow()}
              </Styled.FlexBox>
            ),
            sort: (items, asc) =>
              items.sort((a, b) => dayjs(b.lastActive ?? 0).diff(a.lastActive ?? 0) * (asc ? 1 : -1)),
          },
        ]}
        loading={rooms.loading}
        itemNoun="rooms"
        addElementText="Add Room"
        onAddElementClick={() => setShowAddRoomDialog(true)}
      />
      {showAddRoomDialog && (
        <AddRoomDialog
          onRoomAdded={() => {
            setShowAddRoomDialog(false);
            rooms.fetch();
          }}
          onClose={() => setShowAddRoomDialog(false)}
        />
      )}
    </>
  );
};

export default Rooms;
