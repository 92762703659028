import classNames from 'classnames';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ZCanvas from '@flatfrog/ffbec';

import * as analytics from 'client/common/analytics';
import { THUMBNAIL_SIZE } from 'client/common/util';
import SmallComboButton from 'client/components/Common/smallcombobutton';
import { useActions } from 'client/hooks/useActions';
import useEventListener from 'client/hooks/useEventListener';
import { useSelector } from 'client/hooks/useSelector';

import * as Styled from './grid-styled';
import { MAX_PAGES_ALLOWED } from './PageControls';

const DragHandle = SortableHandle(() => <Styled.Handle />);

interface SortableItemProps {
  sortable: boolean;
  current: boolean;
  follower: boolean;
  presenter: boolean;
}

const WrappedComponent: React.FC<SortableItemProps> = ({ children, sortable, current, follower, presenter }) => (
  <Styled.PageThumbSortingWrapper>
    <Styled.PageThumb className={classNames({ sortable, current, follower, presenter })}>
      {children} {current}
    </Styled.PageThumb>
  </Styled.PageThumbSortingWrapper>
);

const SortableItem = SortableElement(WrappedComponent);

const createThumbnail = async (pageId: number) => {
  const { data, height, width } = ZCanvas.image.createFromPage(pageId, THUMBNAIL_SIZE.width, THUMBNAIL_SIZE.height);
  const { base64 } = await ZCanvas.image.encodeJpeg({
    width,
    height,
    pixels: data,
    flipped: true,
    base64: true,
  });

  return base64;
};

interface Props {
  changePage: (pageId: number) => void;
  currentPage: number;
  pages: number;
  showAddPage: () => void;
}

const GridView: React.FC<Props> = ({ changePage, currentPage, pages, showAddPage }) => {
  const { fileName, forceFollow, isFollower, session, showGridView } = useSelector((state) => ({
    fileName: state.loadedFileInfo?.fileName,
    forceFollow: state.forceFollow,
    isFollower: state.isFollower,
    session: state.session,
    showGridView: state.showGridView,
  }));

  const { setFollower, setShowGridView, duplicatePage } = useActions();

  const [thumbnails, setThumbnails] = useState<{ pageId: number; thumb: string }[]>([]);
  const [sortable, setSortable] = useState(false);
  const thumbGrid = useRef<HTMLDivElement>();
  const pageIds = ZCanvas.page.getIds();

  const refreshThumbnails = useCallback(async () => {
    if (showGridView) {
      setThumbnails((prev) => prev.filter(({ pageId }) => pageIds.includes(pageId)));

      for (const pageId of pageIds) {
        const thumb = await createThumbnail(pageId);

        setThumbnails((prev) => [...prev, { pageId, thumb }]);
      }
    }
  }, [pages, showGridView]);

  useEffect(() => {
    refreshThumbnails();
  }, [refreshThumbnails]);

  useEffect(() => {
    if (showGridView) {
      analytics.viewPanel('/page-grid');
    }
  }, [showGridView]);

  useEventListener<KeyboardEvent>('keyup', (e) => {
    if (e.key === 'Escape' && showGridView) {
      setShowGridView(false);
    }
  });

  useLayoutEffect(() => {
    if (showGridView && thumbGrid.current) {
      const el = thumbGrid.current.querySelector('.current');
      el.scrollIntoView(true);
    }
  }, [showGridView]);

  const ThumbGrid: React.FC = ({ children }) => <Styled.ThumbGrid ref={thumbGrid}>{children}</Styled.ThumbGrid>;

  const SortableThumbGrid = SortableContainer(ThumbGrid);

  const presenter = session?.clients?.find((c) => c.isPresenter);
  const notPresenter = !presenter?.self;

  if (!showGridView) {
    return null;
  }

  const isZTouchActive = ZCanvas.isZTouchActive();

  return (
    <>
      <Styled.Container>
        <Styled.OverflowContainer>
          <Styled.Header>
            <div className="flex-container">
              <div className="button-container">
                <Styled.ReorderButton
                  onClick={() => {
                    analytics.reorderPages(!sortable);
                    setSortable(!sortable);
                  }}
                  className={classNames({ sortable })}
                >
                  {sortable ? 'Done' : 'Reorder Pages'}
                </Styled.ReorderButton>
              </div>
              <h1>{fileName}</h1>
            </div>
            <hr />
          </Styled.Header>
          <SortableThumbGrid
            axis="xy"
            transitionDuration={300}
            onSortEnd={({ oldIndex, newIndex }) => {
              ZCanvas.page.shuffle(oldIndex, newIndex);
            }}
            helperClass="ghostThumb"
            lockToContainerEdges
            useDragHandle
          >
            {pageIds.map((pageId, i) => {
              const thumb = thumbnails.find((e) => e.pageId === pageId)?.thumb;
              const showFollower =
                !isFollower && notPresenter && presenter?.atPage === ZCanvas.page.getUuidFromId(pageId);
              const showPresenter = presenter?.atPage === ZCanvas.page.getUuidFromId(pageId);

              return (
                <SortableItem
                  key={`thumb-${i}`}
                  index={i}
                  sortable={sortable}
                  current={currentPage === i}
                  follower={showFollower}
                  presenter={showPresenter}
                >
                  <Styled.ImageWrapper
                    onClick={() => {
                      if (sortable) {
                        return;
                      }

                      if (!isFollower && notPresenter && showPresenter) {
                        setFollower(true);
                      } else if (!(notPresenter && forceFollow) && i !== ZCanvas.page.getCurrentIndex()) {
                        changePage(i);
                      }

                      setShowGridView(false);
                    }}
                  >
                    {thumb ? (
                      <img
                        src={`data:image/png;base64,${thumb}`}
                        alt={`Page ${i + 1}`}
                        onContextMenu={(e) => e.preventDefault()}
                        draggable="false"
                      />
                    ) : (
                      <Styled.LoadingImage />
                    )}
                    {sortable && <DragHandle />}
                    {!sortable && currentPage === i && pages < MAX_PAGES_ALLOWED && (
                      <Styled.AddPageButton
                        id="btn--grid-view-add-page"
                        onClick={(e) => {
                          e.stopPropagation();

                          if (pages < MAX_PAGES_ALLOWED) {
                            showAddPage();
                          }
                        }}
                      />
                    )}
                  </Styled.ImageWrapper>
                  <SmallComboButton
                    disabled={sortable}
                    direction="down"
                    actions={[
                      {
                        action: () => {
                          duplicatePage(pageId);
                          analytics.duplicatePage(true);
                        },
                        label: <Styled.DuplicateMenuItem>Duplicate Page</Styled.DuplicateMenuItem>,
                      },
                      {
                        action: () => {
                          if (pages > 1) {
                            ZCanvas.page.remove(pageId);
                            analytics.deletePage(true);
                          }
                        },
                        label: (
                          <Styled.DeleteMenuItem className={classNames({ disabled: pages <= 1 })}>
                            Delete
                          </Styled.DeleteMenuItem>
                        ),
                      },
                    ]}
                  >
                    {i + 1}
                  </SmallComboButton>
                </SortableItem>
              );
            })}
          </SortableThumbGrid>
        </Styled.OverflowContainer>
      </Styled.Container>
      {isZTouchActive && (
        <Styled.ZTouchIndicator data-place="left" data-tip="ZTouch active" data-tip-disabled="false" />
      )}
      <Styled.Close
        data-place="left"
        data-tip="Close"
        data-effect="solid"
        data-tip-disabled="false"
        onClick={(e) => {
          e.stopPropagation();
          setShowGridView(false);
        }}
      />
    </>
  );
};

export default GridView;
