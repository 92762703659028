import { createAction, createReducer } from '@reduxjs/toolkit';

import { TextAlignments } from 'client/components/Common/text-alignments';

export type TextBlockToEdit =
  | null
  | { id: string }
  | { new: true; saveExpected?: boolean }
  | {
      id?: string;
      paperId?: number;
      textSize?: number;
      backgroundColor?: string;
      outlineColor?: string;
      textColor?: string;
      content?: string;
      textAlign?: TextAlignments;
      signature?: string;
      link?: string;
    };

export const currentTextBlockEdit = createAction<TextBlockToEdit>('currentTextBlockEdit');

export default createReducer<TextBlockToEdit>(null, (builder) =>
  builder.addCase(currentTextBlockEdit, (state, action) => action.payload)
);
