import classNames from 'classnames';
import React, { useState } from 'react';

import * as analytics from 'client/common/analytics';
import { OpenInstructions } from 'client/components/Home/OpenInstructions';
import QrCode from 'client/components/Home/qrcode';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import Pin from 'client/components/Pin';

import * as Styled from './styled';

interface Props {
  alone: boolean;
}

const RoomInviteButton: React.FC = () => {
  const [showLargeCode, setShowLargeCode] = useState(false);

  return (
    <>
      <Styled.BoardCodeContainer
        className="room"
        onClick={() => {
          setShowLargeCode(true);
          analytics.highlightBoardCode();
        }}
      >
        <Styled.BoardQRCode>
          <QrCode />
        </Styled.BoardQRCode>
        <Styled.BoardCode>
          <Pin />
        </Styled.BoardCode>
        <p>Tap on code to enlarge</p>
      </Styled.BoardCodeContainer>
      {showLargeCode && <OpenInstructions hide={() => setShowLargeCode(false)} />}
    </>
  );
};

const InviteButton: React.FC<Props> = ({ alone }) => {
  const { fileId } = useSelector((state) => ({
    fileId: state.loadedFileInfo?.id,
  }));

  const auth = useAuth();
  const { setShowInviteModal } = useActions();

  const showInviteButton = fileId && auth.user;

  return (
    <>
      {showInviteButton && (
        <Styled.InviteButton onClick={() => setShowInviteModal(true)}>
          <img src="/icons/icn_invite_white.svg" alt="Invite people" />
          Invite people
        </Styled.InviteButton>
      )}
      <Styled.BoardCodeContainer className={classNames({ alone })}>
        <h3>Or enter board code to join</h3>{' '}
        <Styled.BoardCode>
          <Pin />
        </Styled.BoardCode>
      </Styled.BoardCodeContainer>
    </>
  );
};

const Invite: React.FC<Props> = ({ alone }) => {
  const { room } = useSelector((state) => ({ room: state.room }));

  return room ? <RoomInviteButton /> : <InviteButton alone={alone} />;
};

export default Invite;
