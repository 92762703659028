import ColorHash from 'color-hash';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ZCanvas, { Action } from '@flatfrog/ffbec';

import useEventListener from 'client/hooks/useEventListener';

import * as Styled from './styled';

const ActionsView: React.FC = () => {
  const { INITIAL_STATE } = window;

  if (
    INITIAL_STATE.env !== 'development' &&
    INITIAL_STATE.gcpEnv !== 'development' &&
    INITIAL_STATE.gcpEnv !== 'test'
  ) {
    return null;
  }

  const [actionEntries, setActionEntries] = useState<
    {
      action: Action;
      origin: 'local' | 'remote';
    }[]
  >([]);
  const [openFlyout, setOpenFlyout] = useState('');
  const [visible, setVisible] = useState(false);

  const actionEntriesRef = useRef([]);

  useEffect(() => {
    actionEntriesRef.current = actionEntries;
  }, [actionEntries]);

  useHotkeys(
    'cmd+shift+a, ctrl+shift+a',
    (event) => {
      event.preventDefault();
      setVisible(!visible);
    },
    { enableOnTags: ['INPUT'] },
    [visible]
  );

  const handleZCanvasEmit = (msg: { type: string; action: Action }[]) => {
    if (!visible) {
      return;
    }
    msg.forEach((m) => {
      switch (m.type) {
        case 'emitAction':
          setActionEntries([{ action: m.action, origin: 'local' }, ...actionEntriesRef.current]);
          break;
        case 'emitUndo': {
          const newActionEntries = [...actionEntriesRef.current];
          newActionEntries.shift();
          setActionEntries(newActionEntries);
          break;
        }
        case 'incomingAction':
          setActionEntries([{ action: m.action, origin: 'remote' }, ...actionEntriesRef.current]);
          break;
        default:
          break;
      }
    });
  };

  useEventListener('zcanvasinitialized', async () => {
    await ZCanvas.initialized;
    ZCanvas.addEventListener('emitAction', handleZCanvasEmit);
    ZCanvas.addEventListener('emitUndo', handleZCanvasEmit);
    ZCanvas.addEventListener('incomingAction', handleZCanvasEmit);
  });

  const darkerColorHash = new ColorHash({ lightness: [0.3], saturation: 0.9 });
  const lighterColorHash = new ColorHash({ lightness: [0.85], saturation: 0.5 });

  const getActionsEntry = (
    actionEntry: {
      action: Action;
      origin: 'local' | 'remote';
    },
    isSubaction: boolean
  ) => {
    const { action, origin } = actionEntry;
    let objectId = null;
    objectId = objectId ?? action.PageInfo?.PageId;
    objectId = objectId ?? action.PaperInfo?.PaperId;
    objectId = objectId ?? action.ObjectId;
    objectId = objectId ?? action.BoardObjectId;
    objectId = objectId ?? '';

    const title = action.Action.includes('Action') ? action.Action.substr(0, action.Action.length - 6) : action.Action;

    return (
      <Styled.ActionsEntry
        key={action.ActionId}
        style={{ color: darkerColorHash.hex(action.Action) }}
        onClick={() => {
          setOpenFlyout(JSON.stringify(action, null, 4));
          console.dir(action);
          console.log(JSON.stringify(action, null, 4));
        }}
      >
        <Styled.OriginIndicator className={isSubaction ? '' : origin}>
          {origin.substring(0, 1).toUpperCase()}
        </Styled.OriginIndicator>
        {`${isSubaction ? `-  ${title}` : title}`}
        {objectId !== '' && (
          <Styled.ObjectIdField style={{ backgroundColor: lighterColorHash.hex(String(objectId)) }}>
            {String(objectId).substring(0, 6)}
          </Styled.ObjectIdField>
        )}
      </Styled.ActionsEntry>
    );
  };

  const renderActions = () =>
    actionEntries.map((a) => {
      if (a.action.Action === 'CombinedAction') {
        return (
          <Styled.CombinedActionEntry key={a.action.ActionId}>
            {getActionsEntry(a, false)}
            {a.action.Actions.map((s) => getActionsEntry({ action: s, origin: a.origin }, true))}
          </Styled.CombinedActionEntry>
        );
      }
      return getActionsEntry(a, false);
    });

  return (
    visible && (
      <Styled.Container>
        <Styled.View>{renderActions()}</Styled.View>
        {openFlyout !== '' && <Styled.Flyout onClick={() => setOpenFlyout('')}>{openFlyout}</Styled.Flyout>}
      </Styled.Container>
    )
  );
};

export default ActionsView;
