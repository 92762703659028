import React, { useEffect, useRef, useState } from 'react';
import ZCanvas from '@flatfrog/ffbec';

import AppControls from 'client/components/AppControls';
import PageControls from 'client/components/Whiteboard/PageControls';
import { useActions } from 'client/hooks/useActions';
import useEventListener from 'client/hooks/useEventListener';

import { ItemsButton, FadeContainer } from './styled';

const MobileBar: React.FC = () => {
  const { setShowItemStack } = useActions();

  const [hidden, setHidden] = useState(false);
  const hideTimer = useRef(null);
  const [zcanvasInit, setZcanvasInit] = useState(false);

  useEffect(() => {
    ZCanvas.initialized.then(() => {
      setZcanvasInit(true);
    });

    return () => {
      if (hideTimer.current) {
        clearTimeout(hideTimer.current);
        hideTimer.current = null;
      }
    };
  }, []);

  useEventListener(
    'pointermove',
    () => {
      if (!hidden) {
        setHidden(true);
      }

      if (hideTimer.current) {
        clearTimeout(hideTimer.current);
        hideTimer.current = null;
      }
    },
    document.getElementById('zcanvas')
  );

  useEventListener(
    'pointerup',
    () => {
      if (hidden) {
        hideTimer.current = setTimeout(() => {
          setHidden(false);
          clearTimeout(hideTimer.current);
          hideTimer.current = null;
        }, 2000);
      }
    },
    document.getElementById('zcanvas')
  );

  return (
    <FadeContainer className={hidden ? 'hidden' : ''}>
      <AppControls />
      <PageControls ready={zcanvasInit} />
      <ItemsButton
        onClick={() => {
          setShowItemStack(true);
        }}
      />
    </FadeContainer>
  );
};

export default MobileBar;
