import styled from 'styled-components';

import * as DialogStyled from 'client/components/Common/dialog-styled';

export const DialogTitle = styled(DialogStyled.Title)`
  font-weight: 600;
  text-align: left;
  margin-bottom: 16px;
`;

export const DialogBody = styled(DialogStyled.Body)`
  display: block;
  text-align: left;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 16px;
`;

export const SectionBody = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const SectionBodyPrequel = styled.span`
  color: #8f8f8f;
  margin-right: 6px;
  font-weight: 500;
`;

export const LegalAnchor = styled.a`
  display: table;
`;
