import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ModalContainer, ModalBackDrop, NextButton, ButtonContainer } from './dialog-styled';

interface Props {
  runOnMount?: () => void;
  noButtons: boolean;
  onNext?: () => void;
  onBackDropClick: () => void;
  nextDisabled?: boolean;
  cover?: boolean;
  nextText?: string;
}

const DialogNoCancel: React.FC<Props> = ({
  children,
  cover,
  nextDisabled,
  nextText,
  noButtons,
  onBackDropClick,
  onNext,
  runOnMount,
}) => {
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    if (runOnMount) {
      runOnMount();
    }

    if (window.innerHeight % 2 && (container.current.clientHeight || container.current.offsetHeight) % 2) {
      container.current.style.transform = 'perspective(1px) translate3d(-50.1%, calc(-50.1% + 0.5px), 0)';
    } else {
      container.current.style.transform = 'perspective(1px) translate3d(-50.1%, -50.1%, 0)';
    }
  }, []);

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onBackDropClick} />
      <ModalContainer className={cover ? 'cover' : ''} ref={container}>
        {children}
        {noButtons ? null : (
          <ButtonContainer>
            <NextButton onClick={onNext} disabled={nextDisabled}>
              {nextText}
            </NextButton>
          </ButtonContainer>
        )}
      </ModalContainer>
    </>,
    document.getElementById('modal-portal')
  );
};

export default DialogNoCancel;
