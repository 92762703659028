import React, { useRef, useState, useEffect, CSSProperties } from 'react';
import { isMacOs } from 'react-device-detect';
import styled from 'styled-components';

import useEventListener from 'client/hooks/useEventListener';

export const MenuContainer = styled.div`
  position: absolute;
  bottom: -8px;
  left: 56px;
  width: 200px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  padding: 10px 0px;
  border-radius: 6px;
  box-shadow: 0 4px 8px #00000026;
  font-size: 14px;
  text-align: left;
  color: #242424;
  cursor: initial;
  user-select: none;
  z-index: 5;

  a {
    color: black;
  }

  &:after,
  &:before {
    right: 100%;
    top: calc(100% - 28px);
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #d2d2d2;
    border-width: 9px;
    margin-top: -9px;
  }
`;

const PasteMenuItem = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  border: none;
  padding: 8px 16px;
  background: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background: #fff;
    }
    &::after {
      opacity: 1;
    }
  }

  &::before {
    background-image: url(/icons/icn_paste.svg);
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    content: '';
  }
  &::after {
    width: var(--commandWidth);
    text-align: left;
    content: attr(data-content);
    margin-left: auto;
    opacity: 0.5;
  }
  &:hover {
    background: #f4f4f4;
  }
`;

const CutMenuItem = styled(PasteMenuItem)`
  &::before {
    background-image: url(/icons/icn_cut.svg);
  }
`;

const CopyMenuItem = styled(PasteMenuItem)`
  &::before {
    background-image: url(/icons/icn_copy.svg);
  }
`;

interface Props {
  show: boolean;
  enableCut: boolean;
  enableCopy: boolean;
  enablePaste: boolean;
  onCut: () => void;
  onCopy: () => void;
  onPaste: () => void;
  onClose: (e: CustomEvent<HTMLElement>) => void;
}

const ClipboardMenu: React.FC<Props> = ({
  enableCopy,
  enableCut,
  enablePaste,
  onClose,
  onCopy,
  onCut,
  onPaste,
  show,
}) => {
  const menu = useRef<HTMLDivElement>();
  const [commandPrefix, setCommandPrefix] = useState('Ctrl+');
  const [commandWidth, setCommandWidth] = useState('46px');

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (menu.current && !menu.current.contains(e.detail)) {
      onClose(e);
    }
  });

  useEffect(() => {
    if (isMacOs) {
      setCommandPrefix('⌘');
      setCommandWidth('24px');
    }
  }, []);

  if (!show) {
    return null;
  }

  return (
    <>
      <MenuContainer ref={menu}>
        <CutMenuItem
          disabled={!enableCut}
          onClick={onCut}
          style={{ '--commandWidth': commandWidth } as CSSProperties}
          data-content={`${commandPrefix}X`}
        >
          Cut
        </CutMenuItem>
        <CopyMenuItem
          disabled={!enableCopy}
          onClick={onCopy}
          style={{ '--commandWidth': commandWidth } as CSSProperties}
          data-content={`${commandPrefix}C`}
        >
          Copy
        </CopyMenuItem>
        <PasteMenuItem
          disabled={!enablePaste}
          onClick={onPaste}
          style={{ '--commandWidth': commandWidth } as CSSProperties}
          data-content={`${commandPrefix}V`}
        >
          Paste
        </PasteMenuItem>
      </MenuContainer>
    </>
  );
};

export default ClipboardMenu;
