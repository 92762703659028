import React, { useState } from 'react';

import getFileName from 'client/common/file-names';
import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import useEventListener from 'client/hooks/useEventListener';
import { LoadedFileInfo } from 'client/state/session/loadedFileInfo';

import {
  DownloadDialogContainer,
  DownloadEntryDescription,
  DownloadEntryImage,
  DownLoadEntryTitle,
  DownloadIllustration,
  DownloadProgress,
} from './styled';

interface DownloadEntryProps {
  description: string;
  isDownloading: boolean;
  imageSrc: string;
  onClick: () => void;
  title: string;
  progress: number;
}

const DownloadEntry: React.FC<DownloadEntryProps> = ({
  description,
  isDownloading,
  imageSrc,
  onClick,
  title,
  progress,
}) => (
  <li
    className={isDownloading ? 'disabled' : ''}
    onClick={() => {
      if (!isDownloading) {
        onClick();
      }
    }}
  >
    <DownloadEntryImage src={imageSrc} />
    <div>
      <DownLoadEntryTitle>{title}</DownLoadEntryTitle>
      <DownloadEntryDescription>{description}</DownloadEntryDescription>
    </div>
    <DownloadProgress style={{ transform: `scaleX(${progress})` }} />
  </li>
);

interface Props {
  downloadFfbFile: (filename: string) => void;
  downloadPdfFile: (filename: string) => void;
  loadedFileInfo: LoadedFileInfo;
  hide: () => void;
  onFfbDownloadFailed: () => void;
  onPdfDownloadFailed: () => void;
}

export const DownloadDialog: React.FC<Props> = ({
  downloadFfbFile,
  downloadPdfFile,
  loadedFileInfo,
  hide,
  onFfbDownloadFailed,
  onPdfDownloadFailed,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [pdfProgress, setPdfProgress] = useState(0);
  const [ffbProgress, setFfbProgress] = useState(0);

  useEventListener<CustomEvent<{ progress: number; totalProgress?: number }>>('export-ffb-progress', ({ detail }) => {
    setFfbProgress(detail.progress / detail.totalProgress);

    if (detail.progress === detail.totalProgress) {
      setTimeout(() => {
        setIsDownloading(false);
        hide();
      }, 1500);
    }
  });

  useEventListener('export-ffb-failed', () => {
    setIsDownloading(false);
    hide();
    onFfbDownloadFailed();
  });

  useEventListener<CustomEvent<{ page: number; totalPages: number }>>('export-pdf-progress', ({ detail }) => {
    setPdfProgress(detail.page / detail.totalPages);

    if (detail.page === detail.totalPages) {
      setTimeout(() => {
        setIsDownloading(false);
        hide();
      }, 1500);
    }
  });

  useEventListener('export-pdf-failed', () => {
    setIsDownloading(false);
    hide();
    onPdfDownloadFailed();
  });

  return (
    <Dialog noButtons={true} noCloseButton={isDownloading} onCancel={hide} cover>
      <DownloadIllustration />
      <DialogStyled.Title>Download Board</DialogStyled.Title>
      <DownloadDialogContainer>
        <ul>
          <DownloadEntry
            title="Download a Copy"
            description="Download FlatFrog Board file to your computer."
            imageSrc="/icons/icn_file_ffb.svg"
            isDownloading={isDownloading}
            onClick={() => {
              setIsDownloading(true);
              downloadFfbFile(getFileName('ffb', loadedFileInfo));
            }}
            progress={ffbProgress}
          />
          <DownloadEntry
            title="Download as PDF"
            description="Download PDF to your computer for presentation."
            imageSrc="/icons/icn_file_pdf.svg"
            isDownloading={isDownloading}
            onClick={() => {
              setIsDownloading(true);
              downloadPdfFile(getFileName('pdf', loadedFileInfo));
            }}
            progress={pdfProgress}
          />
        </ul>
      </DownloadDialogContainer>
    </Dialog>
  );
};
