import useLocalStorageState from 'client/hooks/useLocalStorageState';

const defaultColor = { r: 99, g: 193, b: 153 };

export const useStartScreenColor = () => {
  const [color, setColor] = useLocalStorageState('startScreenColor', defaultColor);

  return {
    color,
    setColor,
    resetColor: () => setColor(defaultColor),
  };
};
