import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import * as analytics from 'client/common/analytics';
import { File, MUTATION_DELETE_TEAM, MUTATION_REMOVE_FROM_TEAM, Team } from 'client/common/graphql';
import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';

import * as Styled from '../styled';

import TeamComponent from './team';

interface Props {
  allowedToCreateFile: { allowed: boolean; limit?: number };
  createTeam: () => void;
  deleteBoard: (f: File) => void;
  duplicateBoard: (fileId: string) => void;
  loading: boolean;
  renameBoard: (f: File) => void;
  showCastDialog: (e: React.MouseEvent<HTMLSpanElement>, file: File) => void;
  showEditTeam: (team: Team) => void;
  showInvite: (team: Team) => void;
  showMoveTo: (fileId: string, teamId: string) => void;
  showShareDialog: (id: string, fileName: string, showMove: boolean, teamId: string) => void;
  showTeamMembers: (team: Team) => void;
  teams: Team[];
}

const TeamBoards: React.FC<Props> = ({
  allowedToCreateFile,
  createTeam,
  deleteBoard,
  duplicateBoard,
  loading,
  renameBoard,
  showCastDialog,
  showEditTeam,
  showInvite,
  showMoveTo,
  showShareDialog,
  showTeamMembers,
  teams,
}) => {
  const { featureFlags } = useSelector((state) => ({ featureFlags: state.featureFlags }));

  const [showConfirmLeave, setShowConfirmLeave] = useState<Team | undefined>(undefined);
  const [showConfirmDeleteTeam, setShowConfirmDeleteTeam] = useState<Team | undefined>(undefined);

  const auth = useAuth();

  const [deleteTeam] = useMutation(MUTATION_DELETE_TEAM);
  const [removeFromTeam] = useMutation(MUTATION_REMOVE_FROM_TEAM);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [teams]);

  const handleLeaveTeam = (teamId: string) => {
    removeFromTeam({
      variables: {
        input: { teamId, userId: auth.user?.uid },
      },
    }).then(() => {
      analytics.leaveTeam();
      setShowConfirmLeave(undefined);
    });
  };

  const handleDelete = (teamId: string) => {
    deleteTeam({
      variables: {
        input: { teamId },
      },
    }).then(() => {
      setShowConfirmDeleteTeam(undefined);
    });
  };

  return (
    <>
      {showConfirmLeave && (
        <Dialog
          cancelText="Cancel"
          nextText="Leave"
          onCancel={() => setShowConfirmLeave(undefined)}
          onNext={() => {
            handleLeaveTeam(showConfirmLeave.id);
          }}
          cover
        >
          <DialogStyled.Title>Leave {showConfirmLeave.name}</DialogStyled.Title>
          <DialogStyled.Body>
            Are you sure you want to leave the Workspace?
            <br />
            You need to get invited again to gain access to these files again.
          </DialogStyled.Body>
        </Dialog>
      )}
      {showConfirmDeleteTeam && (
        <Dialog
          cancelText="Cancel"
          nextText="Delete"
          onCancel={() => setShowConfirmDeleteTeam(undefined)}
          onNext={() => {
            handleDelete(showConfirmDeleteTeam.id);
          }}
          cover
        >
          <DialogStyled.Title>Delete {showConfirmDeleteTeam.name}</DialogStyled.Title>
          <DialogStyled.Body>
            Are you sure you want to delete the Workspace?
            <br />
            All files will be moved to you as Workspace owner.
          </DialogStyled.Body>
        </Dialog>
      )}
      {teams.map((team) => (
        <TeamComponent
          key={team.id}
          allowedToCreateFile={allowedToCreateFile}
          deleteBoard={deleteBoard}
          duplicateBoard={duplicateBoard}
          loading={loading}
          renameBoard={renameBoard}
          showCastDialog={showCastDialog}
          showEditTeam={() => showEditTeam(team)}
          showInvite={() => showInvite(team)}
          showMoveTo={(fileId) => showMoveTo(fileId, team.id)}
          showShareDialog={(fileId, fileName, showMove) => showShareDialog(fileId, fileName, showMove, team.id)}
          showTeamMembers={() => showTeamMembers(team)}
          team={team}
          deleteTeam={() => setShowConfirmDeleteTeam(team)}
          leaveTeam={() => setShowConfirmLeave(team)}
        />
      ))}
      {featureFlags.enableTeams && !loading && teams.length === 0 && (
        <Styled.PanelGroup>
          <Styled.TeamBoardsList>
            <Styled.RecentBoardsEmpty>
              <Styled.TeamBoardsEmptyIllustration />
              <Styled.RecentBoardsEmptyTitle>
                Create a Workspace and invite your team colleagues
              </Styled.RecentBoardsEmptyTitle>
              <Styled.RecentBoardsEmptyComment>
                Workspace is a great way to store your team’s boards for easy access to everyone
              </Styled.RecentBoardsEmptyComment>
              <Styled.EmptyBoardsButtons>
                <Styled.EmptyBoardsButton className="blue" onClick={() => createTeam()}>
                  <img src="/icons/icn_new_team.svg" alt="Create Workspace" /> Create Workspace
                </Styled.EmptyBoardsButton>
              </Styled.EmptyBoardsButtons>
            </Styled.RecentBoardsEmpty>
          </Styled.TeamBoardsList>
        </Styled.PanelGroup>
      )}
    </>
  );
};

export default TeamBoards;
