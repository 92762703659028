import { createAction, createReducer } from '@reduxjs/toolkit';

import { sessionSocketConnected } from '../session/isSessionSocketConnected';
import { setLoadedFileInfo } from '../session/loadedFileInfo';

export const setSelectedPapers = createAction<number[]>('setSelectedPapers');

export default createReducer([] as number[], (builder) =>
  builder
    .addCase(setSelectedPapers, (state, { payload }) => payload)
    .addCase(setLoadedFileInfo, () => [])
    .addCase(sessionSocketConnected, () => [])
);
