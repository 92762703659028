import styled from 'styled-components';

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 8px;
  color: #999;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: 800;
  outline: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: cover;
  background: url(/icons/icn_close_small.svg) center center no-repeat;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const StickyNotification = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  max-width: 90%;
  padding: 16px 48px;
  color: #242424;
  background-color: white;
  border-radius: 0 0 7px 7px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16), 0 0 0 0.5px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: slide-in--board-code-bar 500ms ease;
  @keyframes slide-in--board-code-bar {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  > button.action {
    outline: none;
    border: none;
    background: transparent;
    margin-top: 16px;
    font-weight: 600;
    color: #2287ca;
    cursor: pointer;
  }
`;

export const Notification = styled.div<{ room: boolean }>`
  position: absolute;
  top: -20px;
  left: 50%;
  min-height: 40px;
  border-radius: 5px;
  background-color: #279ae7;
  box-shadow: 0 4px 8px #279ae73c;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  user-select: none;
  transform: translateX(-50%);
  animation: slide-down--notification 600ms ease-in-out forwards;

  &.out {
    animation: slide-up--notification 600ms ease-in-out forwards;
  }

  @keyframes slide-down--notification {
    100% {
      top: ${({ room }) => (room ? '76px' : '40px')};
      opacity: 1;
    }
  }

  @keyframes slide-up--notification {
    0% {
      top: ${({ room }) => (room ? '76px' : '40px')};
      opacity: 1;
    }

    100% {
      top: -20px;
      opacity: 0;
    }
  }

  > button {
    outline: none;
    border: none;
    background: transparent;
    margin-left: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    font-size: inherit;
  }
`;
