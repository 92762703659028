import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { COLORS } from 'client/common/ZCanvasAppSettings';

const [black, blue, , green, red] = COLORS;

const Button = styled.div<{ icon: string }>`
  width: 40px;
  height: 40px;
  margin-left: 8px;
  border-radius: 50%;
  background: url(${({ icon }) => icon}) center center no-repeat;
  cursor: pointer;
`;

const StrokeButton = styled(Button)`
  &.selected {
    background-color: #e0e0e0;
  }
`;

const ColorButton = styled(Button)`
  background-size: 32px;

  &.selected {
    box-shadow: inset 0 0 0 2px #242424;
  }
`;

interface Props {
  colorSelected: typeof COLORS[number];
  setColorSelected: (color: typeof COLORS[number]) => void;
  strokeSizeSelected: number;
  setStrokeSizeSelected: (size: number) => void;
}

export const ISERoomToolbar: React.FC<Props> = ({
  colorSelected,
  setColorSelected,
  strokeSizeSelected,
  setStrokeSizeSelected,
}) => (
  <>
    {[1, 3].map((size) => (
      <StrokeButton
        key={size}
        className={classNames({ selected: size === strokeSizeSelected })}
        icon={`/icons/strokes/icn_stroke${size === 1 ? 2 : 5}_gray_dark.svg`}
        onClick={() => setStrokeSizeSelected(size)}
      />
    ))}
    {[black, blue, green, red].map((color) => (
      <ColorButton
        key={color.hex}
        className={classNames({ selected: colorSelected.hex === color.hex })}
        icon={`/icons/strokes/icn_${color.iconSuffix}.svg`}
        onClick={() => setColorSelected(color)}
      />
    ))}
  </>
);
