import React, { useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';

import CookieBanner from 'client/components/Home/cookiebanner';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';

import MobileTopBar from './MobileTopBar';
import * as Styled from './styled';

const PasswordReminder: React.FC = () => {
  const { hasAgreedToTermsAndPolicy } = useSelector((state) => ({
    hasAgreedToTermsAndPolicy: state.hasAgreedToTermsAndPolicy,
  }));

  const { agreeToTermsAndPolicy } = useActions();

  const auth = useAuth();
  const email = useRef<HTMLInputElement>();
  const [validationResponse, setValidationResponse] = useState(null);
  const [success, setSuccess] = useState(false);
  const location = useLocation<{ email: string }>();

  const handleSubmit = async () => {
    if (email.current?.value) {
      const result = await auth.sendPasswordResetEmail(email.current?.value);
      if (result === true) {
        setSuccess(true);
      } else {
        setValidationResponse(result);
      }
    }
  };

  return (
    <>
      <Styled.ScrollArea>
        {isMobileOnly && <MobileTopBar />}
        <Styled.LoginForm>
          {success ? (
            <Styled.FormIllustration src="/images/ill_checkyouremail.svg" alt="Forgot Password?" />
          ) : (
            <Styled.FormIllustration src="/images/ill_forgot_password.svg" alt="Check Your Email" />
          )}
          <h1>
            {success ? 'Check Your Email' : 'Forgot Password?'}
            <Styled.InfoText>
              {success
                ? `
              An email has been sent to your email address. Please check your email to reset your password.
              `
                : `
              You will receive an email with instructions on how to reset your password.
              `}
            </Styled.InfoText>
          </h1>
          {success && (
            <Styled.InfoText style={{ marginTop: 40 }}>PS, don’t forget to check you spam folder.</Styled.InfoText>
          )}
          {!success && (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await handleSubmit();
              }}
            >
              {validationResponse?.code === 'auth/user-not-found' && (
                <div className="error">{validationResponse.message}</div>
              )}
              {location.state?.email && <Styled.EmailAddress>{location.state?.email}</Styled.EmailAddress>}
              {!location.state?.email && (
                <label
                  className={
                    validationResponse?.code === 'auth/invalid-email' ||
                    validationResponse?.code === 'auth/user-not-found'
                      ? 'error'
                      : ''
                  }
                >
                  {validationResponse?.code === 'auth/invalid-email' ? 'Email Invalid' : 'Email'}
                </label>
              )}
              <input
                name="email"
                ref={email}
                defaultValue={location.state?.email}
                id="password-reset-email-input"
                autoFocus
                required
                type={location.state?.email ? 'hidden' : 'email'}
                onChange={() => {
                  if (
                    validationResponse?.code === 'auth/invalid-email' ||
                    validationResponse?.code === 'auth/user-not-found'
                  ) {
                    setValidationResponse(null);
                  }
                }}
                className={
                  validationResponse?.code === 'auth/invalid-email' ||
                  validationResponse?.code === 'auth/user-not-found'
                    ? 'error'
                    : ''
                }
              />

              <button id="next-button" type="submit">
                {location.state?.email ? 'Send email to reset password ' : 'Next'}
              </button>

              <Link to="/login">Wait, I remember my password</Link>
            </form>
          )}
        </Styled.LoginForm>
      </Styled.ScrollArea>
      <CookieBanner accepted={hasAgreedToTermsAndPolicy} accept={() => agreeToTermsAndPolicy(true)} />
    </>
  );
};

export default PasswordReminder;
