const size = {
  mobileS: '320px',
  mobileL: '767px',
  tabletS: '768px',
  tabletL: '1024px',
};

export default {
  mobile: `only screen and (min-device-width: ${size.mobileS}) and (max-device-width: ${size.mobileL})`,
  tablet: `only screen and (min-device-width : ${size.tabletS}) and (max-device-width: ${size.tabletL})`,
};
