import React, { useState } from 'react';
import ZCanvas from '@flatfrog/ffbec';

import * as analytics from 'client/common/analytics';

import * as Styled from './paperlink-styled';

interface Props {
  finishEditPaperLink: (paperId: number) => void;
  paperId: number;
  ready: boolean;
}

const EditPaperLink: React.FC<Props> = ({ finishEditPaperLink, paperId, ready }) => {
  if (!ready) {
    return null;
  }

  let md;
  try {
    md = JSON.parse(ZCanvas.paper.getMetadata(paperId));
  } catch (err) {
    console.log('paper without metadata');
  }
  const [linkInputValue, setLinkInputValue] = useState(md?.Link ?? '');

  const submitNewLink = () => {
    const metadataString = ZCanvas.paper.getMetadata(paperId);
    let metadata;
    try {
      metadata = JSON.parse(metadataString);
    } catch (err) {
      console.log(`paper without metadata: ${err.toString()}`);
    }
    let newLink;
    if (!linkInputValue || !linkInputValue.trim()) {
      newLink = '';

      if (metadata && metadata.Link) {
        analytics.removeLinkFromItem();
      }
    } else {
      newLink = linkInputValue.startsWith('http') ? linkInputValue : `https://${linkInputValue}`;
      // Clean up url
      newLink = encodeURI(decodeURI(newLink));

      if (metadata && metadata.Link && metadata.Link !== newLink) {
        analytics.editItemLink();
      } else if (!metadata || !metadata.Link) {
        analytics.addLinkToItem();
      }
    }
    if (metadata) {
      metadata.Link = newLink;
      ZCanvas.paper.setMetadata(paperId, JSON.stringify(metadata));
    } else if (newLink) {
      ZCanvas.paper.setMetadata(paperId, JSON.stringify({ Link: newLink }));
    }
  };

  const paperInfo = ZCanvas.paper.getInfo(paperId);
  const top = paperInfo.corners.map((c) => c.y).reduce((acc, y) => Math.min(acc, y));
  const bottom = paperInfo.corners.map((c) => c.y).reduce((acc, y) => Math.max(acc, y));
  const left = paperInfo.corners.map((c) => c.x).reduce((acc, x) => Math.min(acc, x));
  const right = paperInfo.corners.map((c) => c.x).reduce((acc, x) => Math.max(acc, x));
  const position = { x: 0.5 * (left + right), y: 0.5 * (top + bottom) };
  const leftEdge = position.x - 132;
  if (leftEdge <= 65) {
    position.x += 65 - leftEdge;
  }

  return (
    <Styled.EditLinkContainer
      className={paperInfo.type === 'Sticker' ? 'sticker' : ''}
      style={{
        left: position.x,
        top: position.y,
      }}
    >
      <Styled.EditLinkIcon />
      <Styled.EditLinkInput
        value={linkInputValue}
        onChange={(e) => setLinkInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitNewLink();
            finishEditPaperLink(paperId);
          } else if (e.key === 'Escape') {
            finishEditPaperLink(paperId);
          }
        }}
        autoFocus
      />
      <Styled.OkButton
        onClick={() => {
          submitNewLink();
          finishEditPaperLink(paperId);
        }}
      >
        OK
      </Styled.OkButton>
    </Styled.EditLinkContainer>
  );
};

export default EditPaperLink;
