import styled from 'styled-components';

import { THUMBNAIL_SIZE } from 'client/common/util';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  bottom: 0;
  z-index: 6;
  background: #f0f0f0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  touch-action: none;
  user-select: none;
`;

export const OverflowContainer = styled.div`
  padding: 32px 0 0 0;
  box-sizing: content-box;
  margin: auto;
  max-width: calc(4 * 320px + 64px);
  width: calc(100% + 64px);
`;

export const Close = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  cursor: pointer;
  border: none;
  background: url(/icons/icn_close_small.svg) center center no-repeat;
  background-size: 20px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  z-index: 7;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: #00000015;
    transform: scale(0);
    transition: transform 150ms ease-in-out;
  }

  &:hover {
    &:before {
      transform: scale(1.5);
    }
  }
`;

export const AddPageButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  left: calc(100% + 2px);
  top: 50%;
  width: 48px;
  height: 48px;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  border-radius: 50%;
  background: #279ae7 url(/icons/icn_plus.svg) center center no-repeat;
  background-size: 14px 14px;
  box-shadow: 0 4px 12px #2287ca80;
  cursor: pointer;

  &:hover {
    background-color: #2287ca;
  }
`;

export const ThumbGrid = styled.div`
  overflow: auto;
  width: 100%;
  padding: 0 32px 112px 32px;
  display: flex;
  flex-wrap: wrap;
`;

export const ZTouchIndicator = styled.div`
  background: transparent url(/icons/icn_active_pen.svg) center center no-repeat;
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 16px;
  z-index: 7;
  display: block;
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
  width: 100%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 4px solid #fff;
    pointer-events: none;
  }
`;

export const LoadingImage = styled.div`
  border: 1px solid #00000019;
  border-radius: 4px;
  box-shadow: 0 2px 4px #00000026;
  position: relative;
  background: #fff url(/icons/spinner.svg) center center no-repeat;
  background-size: 20% auto;
  width: ${THUMBNAIL_SIZE.width}px;
  max-width: 100%;
  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }
`;

export const ReorderButton = styled.button.attrs({
  type: 'button',
})`
  background: url(/icons/icn_crosshair.svg) left 7px center no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  padding: 7px 16px 7px 40px;
  font-size: 13px;
  color: #2287ca;
  font-weight: 600;
  border-radius: 20px;
  transition: background-color 300ms ease-out;

  &:hover {
    background-color: #279ae714;
  }

  &.sortable {
    background-image: url(/icons/icn_done_order_pages.svg);
  }
`;

export const NewPageButton = styled(ReorderButton)`
  // TODO: Copy and change name
  background: url(/icons/icn_new_team.svg) left 7px center no-repeat;
`;

export const Header = styled.div`
  margin-bottom: 32px;
  padding: 0 8px;
  position: relative;

  > .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .button-container {
      position: absolute;
      left: 8px;
      top: 0;
    }

    h1 {
      font: normal normal 600 18px/24px Open Sans;
      letter-spacing: 0;
      padding: 0;
      margin: 0;
      text-transform: none;
      color: #242424;
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid #00000029;
    height: 1px;
    display: block;
    width: 100%;
    margin: 24px 0 0 0;
  }
`;

export const Handle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(/icons/icn_crosshair.svg) center center no-repeat;
  cursor: grab;
`;

export const PageThumb = styled.div`
  position: relative;
  width: 100%;
  max-width: 322px;
  padding: 16px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  transform: rotate(0deg) translateZ(1);
  transition: background-color 150ms ease-out, transform 400ms ease-in-out;

  &.disabled {
    opacity: 0.3;
  }

  &.sortable {
    ${ImageWrapper} {
      cursor: default !important;
      background: #f9f9f9;
      border-radius: 8px;
    }

    img {
      opacity: 20%;
    }
  }

  ${ImageWrapper} {
    transition: transform 50ms ease-in-out;

    &:hover {
      transform: scale(1.02);
    }

    &:active {
      transform: scale(0.98);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -4px;
      right: -4px;
      top: -4px;
      bottom: -4px;
      border-radius: 12px;
      background: transparent;
      transform: scale(0.9);
      transition: transform 50ms ease-in-out;
    }
  }

  img {
    max-width: 100%;
    pointer-events: none;
    box-shadow: 0 0 0 0.5px #00000014, 0 2px 6px #00000029;
    border-radius: 8px;
    position: relative;
    width: ${THUMBNAIL_SIZE.width}px;
    display: block;
  }

  &.presenter,
  &.presenter.current {
    ${ImageWrapper} {
      &::after {
        border-color: #279ae7;
      }
    }
  }

  &.follower.presenter {
    ${ImageWrapper} {
      &::after {
        border-color: #279ae7;
      }
    }
  }

  &.presenter.current.follower {
    ${ImageWrapper} {
      &::after {
        border-color: #279ae7;
      }
      &:before {
        background: #279ae7;
        left: -4px;
        right: -4px;
        top: -4px;
        bottom: -4px;
        border-radius: 12px;
        box-shadow: inset 0 0 0 4px #63c099;
        transform: scale(1);
      }
    }
  }

  &.current {
    ${ImageWrapper} {
      &::after {
        border-color: #63c099;
      }
    }
  }
`;

export const PageThumbSortingWrapper = styled.div`
  display: block;
  flex-shrink: 0;

  @media screen and (max-width: calc((4 * 320px) + 64px)) {
    min-width: 200px;
    width: 25%;
  }

  &.ghostThumb {
    ${PageThumb}.current {
      ${ImageWrapper} {
        &:before {
          background: #63c099;
          transform: scale(0);
        }
      }
    }

    ${ImageWrapper} {
      animation: tilt-thumb 400ms linear forwards;

      img {
        box-shadow: 4px 8px 16px #00000024;
      }
    }
  }

  @keyframes tilt-thumb {
    100% {
      transform: rotate(-3deg);
    }
  }
`;

const MenuItem = styled.span`
  border: none;
  outline: none;
  display: block;
  color: #242424;
  padding-left: 32px;
  cursor: pointer;

  &.disabled {
    color: #8c8c8c;
    opacity: 0.3;
    cursor: default;
  }
`;

export const DuplicateMenuItem = styled(MenuItem)`
  background: url(/icons/icn_duplicate.svg) left center no-repeat;
`;

export const DeleteMenuItem = styled(MenuItem)`
  background: url(/icons/icn_paper_trashcan.svg) left center no-repeat;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: span 4;
  padding: 16px;
`;
