import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import * as analytics from 'client/common/analytics';
import useAnimal from 'client/common/animals';
import Layout from 'client/common/helpers/Layout';
import { getParticipantColor } from 'client/common/participant-names';
import { CONNECTION_STATUS } from 'client/common/util';
import { MobilePrimaryButton } from 'client/components/Auth/MobileTopBar';
import * as Styled from 'client/components/Auth/styled';
import CookieBanner from 'client/components/Home/cookiebanner';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';

const JoinUsingQRCode: React.FC = () => {
  const { hasAgreedToTermsAndPolicy, session } = useSelector((state) => ({
    hasAgreedToTermsAndPolicy: state.hasAgreedToTermsAndPolicy,
    session: state.session,
  }));

  const { agreeToTermsAndPolicy, connectFailure, setSignature, tryConnectWithCode } = useActions();

  const params = useParams<{ code: string }>();
  const history = useHistory();
  const [animal, changeAnimal] = useAnimal();
  const auth = useAuth();

  const [code, setCode] = useState('');
  const [showError, setShowError] = useState(false);

  const handleJoin = async () => {
    if (!auth.user) {
      await auth.signInAsGuest();
    }
    agreeToTermsAndPolicy(true);
    tryConnectWithCode(params.code);
  };

  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting) {
      agreeToTermsAndPolicy(true);
      analytics.validCode();
      history.push('/session', { from: 'home' });
    }
  }, [session]);

  useEffect(() => {
    if (auth.user) {
      handleJoin();
    }
  }, [auth.user]);

  useEffect(() => {
    if (params.code) {
      setCode(params.code);
      // If code is valid 6 digits open board
      if (params.code.length !== 6) {
        setShowError(true);
      }
    }
  }, []);

  useEffect(() => {
    if (session.failure) {
      setShowError(true);
      analytics.invalidCode();
      if (code.length !== 6) {
        connectFailure(null);
      }
    }
  }, [session.failure]);

  useEffect(() => {
    if (!auth.loading) {
      if (!auth.user) {
        setSignature(animal);
      }
    }
  }, [animal, auth.loading]);

  return (
    <Layout
      mobileTopBarButton={
        <MobilePrimaryButton>
          <Link
            id="login-link"
            to={{
              pathname: '/login',
              state: {
                from: 'qrcode',
                code: params.code,
              },
            }}
          >
            Sign In
          </Link>
        </MobilePrimaryButton>
      }
      topBarItems={
        <Styled.LoginButton>
          <Styled.PromoLabel>Get the full experience of FlatFrog Board</Styled.PromoLabel>
          <Link
            id="login-link"
            to={{
              pathname: '/login',
              state: {
                from: 'qrcode',
                code: params.code,
              },
            }}
          >
            Sign In
          </Link>
        </Styled.LoginButton>
      }
      mainContent={
        <>
          {showError && <h1>Something happened</h1>}
          {showError && <p>Please scan the QR-Code again, either the provided code is invalid or outdated.</p>}
          {!showError && (
            <>
              <h1>
                Hi 👋, join Board as{' '}
                <Styled.Animal id="animal-toggle" onClick={changeAnimal} color={getParticipantColor(animal)}>
                  {animal}
                </Styled.Animal>
              </h1>

              <Styled.LargeJoinButton onClick={handleJoin}>Join</Styled.LargeJoinButton>

              <span className="privacy">
                By joining a board, you agree to the{' '}
                <a target="_blank" href="/privacy-policy">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a target="_blank" href="/terms-of-service">
                  Terms of Service
                </a>
              </span>
            </>
          )}
        </>
      }
      lowerMainContent={
        <>
          <a target="_blank" href="//www.flatfrog.com/whiteboard">
            What's FlatFrog Board?
          </a>
          <a target="_blank" href="//help.flatfrog.com/en/knowledge/flatfrog-board">
            More Support
          </a>
        </>
      }
      footer={
        <>
          <CookieBanner accepted={hasAgreedToTermsAndPolicy} accept={() => agreeToTermsAndPolicy(true)} />
        </>
      }
    />
  );
};

export default JoinUsingQRCode;
