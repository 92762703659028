import styled from 'styled-components';

import { Input } from '../styled';

export * from '../styled';

export const CodeInput = styled(Input).attrs((p) => ({
  inputMode: 'numeric',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  maxLength: 7,
  spellCheck: false,
}))`
  letter-spacing: 6.2px;
  text-transform: uppercase;
  font-weight: 600;
`;
