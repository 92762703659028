const intersectionObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      const rect = entry.boundingClientRect;
      const outsideBottom = rect.height + rect.y - entry.rootBounds.height;

      if (outsideBottom > 0) {
        entry.target.style.top = `-${outsideBottom + 8}px`;
      }
    } else if (entry.intersectionRatio === 0 && parseInt(entry.target.style.top, 10) < 0) {
      entry.target.style.top = '0px';
    }
  });
}, document.documentElement);

export default intersectionObserver;
