import { useMemo } from 'react';

import { useSelector } from 'client/hooks/useSelector';

export const useAppServerUrlWithTenant = () => {
  const { appServerUrl, tenant } = useSelector((state) => ({ appServerUrl: state.appServerUrl, tenant: state.tenant }));

  return useMemo(() => {
    const host = new URL(appServerUrl).host;
    return tenant ? `${tenant.subdomain}.${host}` : host;
  }, [appServerUrl, tenant]);
};
