import React, { useState } from 'react';
import styled from 'styled-components';

import { useSelector } from 'client/hooks/useSelector';

const Container = styled.div`
  background-color: #b90808;
  color: white;
  position: absolute;
  width: 100%;
  display: flex;
  z-index: 1000;
  padding: 14px;
  gap: 43px;
  top: 0px;
  align-items: center;
  & > a[target^='_blank'] {
    background: none;
    padding: 0;
  }
`;

const Icon = styled.img`
  height: 40px;
`;

const Header = styled.div`
  font-size: 18px;
  color: #f5f5f5;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Text = styled.div`
  font-size: 15px;
  opacity: 0.88;
  color: white;

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: 600;
  }
`;

const Right = styled.div`
  flex: 1;
  text-align: right;
  align-self: start;

  img {
    cursor: pointer;
  }
`;

const UwpWarning: React.FC = () => {
  const session = useSelector((state) => state.session);
  const [hide, setHide] = useState(false);

  if (!session.clients?.some((c) => c.clientType === 'uwp') || hide) {
    return null;
  }

  return (
    <Container>
      <Icon src="/icons/icn_warning_white.svg" />
      <a href="https://flatfrogboard.com/dashboard/download-room-app" target="_blank">
        <Header>Update the whiteboard software</Header>
        <Text>
          The FlatFrog Board for rooms software that is used on the large touch screen in this meeting is end of life.
          For better online collaboration, please contact the IT administrator and ensure the new software version is
          downloaded and installed on the large touch screen. Click to download a free upgrade.
        </Text>
      </a>
      <Right>
        <img src="/icons/icn_close_white_small.svg" onClick={() => setHide(true)} />
      </Right>
    </Container>
  );
};

export default UwpWarning;
