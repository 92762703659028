import { isMobile, isTablet } from 'react-device-detect';

import { log } from 'client/common/util.ts';
import clientPb from 'client/proto/generated/collab_client_pb';
import commonPb from 'client/proto/generated/collab_common_pb';
import serverPb from 'client/proto/generated/collab_server_pb';

exports.bufferToSessionServerMessage = buffer => {
  try {
    return serverPb.Message.deserializeBinary(buffer);
  } catch (e) {
    log(`Failed to deserialize buffer, data: ${JSON.stringify(buffer)}`);
    log(e);
    return null;
  }
};

exports.createJoinMsgBuffer = ({ name, room }) => {
  const wrapper = new clientPb.Message();
  const join = new clientPb.Join();
  join.setName(name);
  let clientType = commonPb.ClientType.DESKTOP;
  if (isTablet) {
    clientType = commonPb.ClientType.TABLET;
  } else if (isMobile) {
    clientType = commonPb.ClientType.PHONE;
  } else if (room) {
    clientType = commonPb.ClientType.ROOM;
  }
  join.setClientType(clientType);
  wrapper.setJoin(join);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createOnboardingDoneMsgBuffer = () => {
  const wrapper = new clientPb.Message();
  const onboardingDone = new clientPb.OnboardingDone();
  wrapper.setOnboardingDone(onboardingDone);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createRequestPresenter = () => {
  const wrapper = new clientPb.Message();
  const requestPresenter = new clientPb.RequestPresenter();
  wrapper.setRequestPresenter(requestPresenter);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createForceFollow = (on, receiverId) => {
  const wrapper = new clientPb.Message();
  const forceFollow = new clientPb.ForceFollow();
  forceFollow.setOn(on);
  if (receiverId) {
    forceFollow.setReceiverId(receiverId);
  }
  wrapper.setForceFollow(forceFollow);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createSetReadOnly = (on, receiverId) => {
  const wrapper = new clientPb.Message();
  const setReadOnly = new clientPb.SetReadOnly();
  setReadOnly.setOn(on);
  if (receiverId) {
    setReadOnly.setReceiverId(receiverId);
  }
  wrapper.setSetReadOnly(setReadOnly);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createAnnouncePage = ({ pageId }) => {
  const wrapper = new clientPb.Message();
  const announcePage = new clientPb.AnnouncePage();
  announcePage.setPageId(pageId.toString());
  wrapper.setAnnouncePage(announcePage);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createImageInternals = (options) => {
  const imageMsg = new clientPb.Image();
  imageMsg.setData(options.data);
  imageMsg.setFileName(options.fileName);
  if (options.paperId) {
    imageMsg.setPaperId(options.paperId);
  }
  if (options.receiverIds) {
    imageMsg.setReceiverIdList(options.receiverIds);
  }
  imageMsg.setContinue(options.continue);
  imageMsg.setMime(options.mime);
  imageMsg.setType(options.type);
  return imageMsg;
};

exports.createImageMsgBuffer = (options) => {
  const wrapper = new clientPb.Message();
  const image = exports.createImageInternals(options);
  wrapper.setImage(image);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createImagesMsgBuffer = (options) => {
  const wrapper = new clientPb.Message();
  const imagesMsg = new clientPb.Images();
  const images = options.images.map(image => exports.createImageInternals(image));
  if (options.receiverIds) {
    imagesMsg.setReceiverIdList(options.receiverIds);
  }
  imagesMsg.setImageList(images);
  wrapper.setImages(imagesMsg);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createUndoBoardEventMsgBuffer = (options) => {
  const wrapper = new clientPb.Message();
  const undoBoardEvent = new clientPb.UndoBoardEvent();
  undoBoardEvent.setActionId(options.actionId);
  undoBoardEvent.setPrevActionId(options.prevActionId);
  undoBoardEvent.setSeq(options.seq);
  wrapper.setUndoBoardEvent(undoBoardEvent);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createBoardEventInternals = (options) => {
  const boardEventMsg = new clientPb.BoardEvent();
  boardEventMsg.setData(options.data);
  boardEventMsg.setSeq(options.seq);
  if (options.receiverId) {
    boardEventMsg.setReceiverId(options.receiverId);
  }
  boardEventMsg.setContinue(options.continue);
  boardEventMsg.setActionId(options.actionId);
  boardEventMsg.setPrevActionId(options.prevActionId);
  return boardEventMsg;
};

exports.createBoardEventMsgBuffer = (options) => {
  const wrapper = new clientPb.Message();
  const boardEvent = exports.createBoardEventInternals(options);
  wrapper.setBoardEvent(boardEvent);
  return Buffer.from(wrapper.serializeBinary());
};

exports.createPublishImageBuffer = ({ receiverId, imageData }, publishId) => {
  const wrapper = new clientPb.Message();
  const publishBlob = new clientPb.PublishBlob();
  publishBlob.setPublishId(publishId);
  publishBlob.setReceiverId(receiverId);
  publishBlob.setImageData(imageData);
  publishBlob.setImage(new commonPb.Image());
  wrapper.setPublishBlob(publishBlob);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createPublishStickyNoteBuffer = ({
  receiverId,
  color,
  imageData
}, publishId) => {
  const wrapper = new clientPb.Message();
  const publishBlob = new clientPb.PublishBlob();
  publishBlob.setPublishId(publishId);
  publishBlob.setReceiverId(receiverId);
  publishBlob.setImageData(imageData);
  const stickyNoteMsg = new commonPb.StickyNote();
  stickyNoteMsg.setColor(color);
  publishBlob.setStickyNote(stickyNoteMsg);
  wrapper.setPublishBlob(publishBlob);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createPublishStickerBuffer = ({ receiverId, imageData }, publishId) => {
  const wrapper = new clientPb.Message();
  const publishBlob = new clientPb.PublishBlob();
  publishBlob.setPublishId(publishId);
  publishBlob.setReceiverId(receiverId);
  publishBlob.setImageData(imageData);
  publishBlob.setSticker(new commonPb.Sticker());
  wrapper.setPublishBlob(publishBlob);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createPingBuffer = ({ pageId, coords, color }) => {
  const wrapper = new clientPb.Message();
  const ping = new clientPb.Ping();
  ping.setPageId(pageId.toString());
  ping.setX(coords.x);
  ping.setY(coords.y);
  ping.setColor(color);
  wrapper.setPing(ping);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createAddTemplatePage = ({ templateId, pageId, pageIndex }) => {
  const wrapper = new clientPb.Message();
  const addTemplatePage = new clientPb.AddTemplatePage();
  addTemplatePage.setTemplateId(templateId);
  addTemplatePage.setPageIndex(pageIndex);
  addTemplatePage.setPageUuid(pageId);
  wrapper.setAddTemplatePage(addTemplatePage);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createKeepAlive = () => {
  const wrapper = new clientPb.Message();
  const keepAlive = new clientPb.KeepAlive();
  wrapper.setKeepAlive(keepAlive);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createRequestPresenter = () => {
  const wrapper = new clientPb.Message();
  const requestPresenter = new clientPb.RequestPresenter();
  wrapper.setRequestPresenter(requestPresenter);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createAnnouncePage = ({ pageId, isPresenter }) => {
  const wrapper = new clientPb.Message();
  const announcePage = new clientPb.AnnouncePage();
  announcePage.setPageId(pageId.toString());
  announcePage.setIsPresenter(isPresenter);
  wrapper.setAnnouncePage(announcePage);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createYieldPresenter = () => {
  const wrapper = new clientPb.Message();
  const yieldPresenter = new clientPb.YieldPresenter();
  wrapper.setYieldPresenter(yieldPresenter);

  return Buffer.from(wrapper.serializeBinary());
};

exports.createThumbnail = (data) => {
  const wrapper = new clientPb.Message();
  const thumbnail = new clientPb.Thumbnail();
  thumbnail.setData(data);
  wrapper.setThumbnail(thumbnail);

  return Buffer.from(wrapper.serializeBinary());
};
