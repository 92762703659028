import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import getFileName from 'client/common/file-names';
import { QUERY_FILE_INFO, QUERY_FILE_LIMITS, MUTATION_RENAME_FILE, FileOrFileLink } from 'client/common/graphql';
import CastDialog from 'client/components/Cast/CastDialog';
import BoardInfoDialog from 'client/components/Common/BoardInfoDialog';
import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { SettingsDialog } from 'client/components/AppControls/SettingsDialog';
import { getFirebaseAuth } from 'client/common/firebase';

import { AssignBeforeSaveDialog } from './AssignBeforeSaveDialog';
import { DownloadDialog } from './DownloadDialog';
import DropMenu from './DropMenu';
import EndSessionDialog from './EndSessionDialog';
import ExportDialog from './ExportDialog';
import { OpenBoardDialog } from './OpenBoardDialog';
import { PersonalSaveDialog } from './PersonalSaveDialog';
import { RoomSaveDialog } from './RoomSaveDialog';
import { Container, RoomFileName } from './styled';

interface ContainerElementProps {
  hidden: boolean;
  room: boolean;
}

const ContainerElement: React.FC<ContainerElementProps> = ({ children, hidden, room }) =>
  isMobileOnly ? (
    <>{children}</>
  ) : (
    <Container hidden={hidden} className={classNames({ room })}>
      {children}
    </Container>
  );

interface Props {
  hidden?: boolean;
}

const AppControls: React.FC<Props> = ({ hidden }) => {
  const { canUndo, loadedFileInfo, room, showRenameDialog, collaborationServerUrl } = useSelector((state) => ({
    canUndo: state.canUndoRedo.canUndo,
    loadedFileInfo: state.loadedFileInfo,
    room: state.room,
    showRenameDialog: state.showRenameDialog,
    collaborationServerUrl: state.collaborationServerUrl,
  }));

  const {
    downloadFfbFile,
    downloadPdfFile,
    downloadCsvFile,
    setAutoSaveStatus,
    toggleShowRenameDialog,
    addNotification,
  } = useActions();

  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [showFfbFailedDialog, setShowFfbFailedDialog] = useState(false);
  const [showPdfFailedDialog, setShowPdfFailedDialog] = useState(false);
  const [showCSVDialog, setShowCSVDialog] = useState(false);
  const [showAssignBeforeSaveDialog, setShowAssignBeforeSaveDialog] = useState(false);
  const [boardToCast, setBoardToCast] = useState<FileOrFileLink>(null);
  const [showRoomSaveDialog, setShowRoomSaveDialog] = useState(false);
  const [showPersonalSaveDialog, setShowPersonalSaveDialog] = useState(false);
  const [showOpenBoardDialog, setShowOpenBoardDialog] = useState(false);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [loadedFileInfo]);

  const downloadDialog = (
    <DownloadDialog
      loadedFileInfo={loadedFileInfo}
      hide={() => setShowDownloadDialog(false)}
      downloadFfbFile={downloadFfbFile}
      downloadPdfFile={downloadPdfFile}
      onFfbDownloadFailed={() => setShowFfbFailedDialog(true)}
      onPdfDownloadFailed={() => setShowPdfFailedDialog(true)}
    />
  );

  const ffbFailedDialog = (
    <Dialog noButtons={true} onCancel={() => setShowFfbFailedDialog(false)} cover>
      <DialogStyled.Title>Failed to save FFB</DialogStyled.Title>
      <DialogStyled.NextButton style={{ marginTop: '30px' }} onClick={() => setShowFfbFailedDialog(false)}>
        Ok
      </DialogStyled.NextButton>
    </Dialog>
  );

  const pdfFailedDialog = (
    <Dialog noButtons={true} onCancel={() => setShowPdfFailedDialog(false)} cover>
      <DialogStyled.Title>Failed to create PDF</DialogStyled.Title>
      <DialogStyled.NextButton style={{ marginTop: '30px' }} onClick={() => setShowPdfFailedDialog(false)}>
        Ok
      </DialogStyled.NextButton>
    </Dialog>
  );

  const { data: limits } = useQuery(QUERY_FILE_LIMITS);
  const allowedToCreateFile = limits?.allowedToCreateFile;

  const [getFileInfo, { data, refetch: refetchFileInfo }] = useLazyQuery(QUERY_FILE_INFO);

  useEffect(() => {
    if (loadedFileInfo?.id && !loadedFileInfo?.id.startsWith('fft_')) {
      getFileInfo({
        variables: { fileId: loadedFileInfo.id },
      });
    }
  }, [loadedFileInfo?.id]);

  useEffect(() => {
    if (loadedFileInfo?.ownerId) {
      if (refetchFileInfo) {
        refetchFileInfo();
      }
      if (showRoomSaveDialog) {
        setShowRoomSaveDialog(false);
      }
    }
  }, [loadedFileInfo?.ownerId]);

  const [renameFile] = useMutation(MUTATION_RENAME_FILE);

  const yourFile = data?.file.user.uid === auth?.user?.uid;
  const isGuestFile = yourFile && auth?.user?.room === true; // TODO: Might be redundant when we can use isRoomFile
  const isRoomFile = data?.file.user.room === true;
  const canEdit = yourFile || data?.file.team?.members.find((m) => m.uid === auth?.user?.uid);

  let ownerName;

  if (yourFile) {
    ownerName = 'You';
  } else if (isGuestFile) {
    ownerName = 'None';
  } else {
    ownerName = data?.file.user.displayName
      ? `${data?.file.user.displayName}${data?.file.user.disabled ? ' (disabled)' : ''}`
      : 'Someone else';
  }

  const renameDialog = (
    <BoardInfoDialog
      onCancel={() => toggleShowRenameDialog(false)}
      title="Board Information"
      createdDate={data?.file.dateCreated}
      owner={ownerName}
      readOnly={!canEdit}
      fileName={loadedFileInfo?.fileName}
      workspace={data?.file.team?.name ?? 'Personal'}
      loadedFileInfo={loadedFileInfo}
      onNext={(fileName) => {
        if (canEdit) {
          renameFile({ variables: { id: loadedFileInfo.id, fileName } });
          setAutoSaveStatus({ fileName });
        }
        toggleShowRenameDialog(false);
      }}
    />
  );

  const exportCSVDialog = (
    <ExportDialog
      onCancel={() => setShowCSVDialog(false)}
      onExport={(options) => {
        setShowCSVDialog(false);
        downloadCsvFile({ fileName: getFileName('csv', loadedFileInfo, false), options });
      }}
    />
  );

  const showSaveDialog = () => {
    if (room && isGuestFile) {
      setShowRoomSaveDialog(true);
    } else if (auth.user && allowedToCreateFile?.allowed && isRoomFile) {
      setShowPersonalSaveDialog(true);
    }
  };

  const assignBeforeSaveDialog = (
    <AssignBeforeSaveDialog
      hide={() => setShowAssignBeforeSaveDialog(false)}
      onAccept={showSaveDialog}
      onReject={() => {
        if (room) {
          history.replace('/room', { fromSession: true });
        }
      }}
    />
  );

  const personalSaveDialog = (
    <PersonalSaveDialog
      hide={() => setShowPersonalSaveDialog(false)}
      onSuccess={refetchFileInfo}
      loadedFileInfo={loadedFileInfo}
    />
  );

  const settingsDialog = <SettingsDialog hide={() => setShowSettingsDialog(false)} isShown={showSettingsDialog} />;

  const goHome = () => {
    if (room) {
      if (isGuestFile && canUndo) {
        setShowAssignBeforeSaveDialog(true);
      } else {
        history.replace('/room', { fromSession: true });
      }
    } else if (loadedFileInfo?.fileName) {
      history.replace('/dashboard');
    } else {
      setShowCloseDialog(true);
    }
  };

  const castBoard = () => {
    setBoardToCast(data?.file);
  };

  const sendLogs = useCallback(async () => {
    if (!auth.user) {
      console.warn('Failed to send logs! No user logged in to authenticate request');
      return;
    }
    const roomId = auth.user.uid;
    const token = await getFirebaseAuth().currentUser.getIdToken();
    const url = `${collaborationServerUrl}/send-logs`;
    const ok = await window.electronApi?.sendLogs({ roomId, token, url });
    if (ok) {
      addNotification({
        content: `Logs are now sent! Room ID: ${roomId.substring(0, 4).toUpperCase()}`,
        type: 'logsSent',
        sticky: true,
      });
    }
  }, [auth.user, collaborationServerUrl]);

  return (
    <>
      <ContainerElement hidden={hidden} room={room}>
        {showFfbFailedDialog ? ffbFailedDialog : null}
        {showPdfFailedDialog ? pdfFailedDialog : null}
        {showDownloadDialog ? downloadDialog : null}
        {showRenameDialog ? renameDialog : null}
        {showCSVDialog ? exportCSVDialog : null}
        {showAssignBeforeSaveDialog ? assignBeforeSaveDialog : null}
        {showRoomSaveDialog ? <RoomSaveDialog hide={() => setShowRoomSaveDialog(false)} /> : null}
        {showPersonalSaveDialog ? personalSaveDialog : null}
        {showOpenBoardDialog ? <OpenBoardDialog hide={() => setShowOpenBoardDialog(false)} /> : null}
        {showSettingsDialog ? settingsDialog : null}
        <DropMenu
          goHome={goHome}
          showDownloadDialog={() => setShowDownloadDialog(true)}
          showRenameDialog={() => toggleShowRenameDialog(true)}
          showSaveDialog={showSaveDialog}
          showOpenBoardDialog={() => setShowOpenBoardDialog(true)}
          showSettingsDialog={() => setShowSettingsDialog(true)}
          yourFile={yourFile}
          isGuestFile={isGuestFile}
          allowedToCreateFile={allowedToCreateFile}
          isRoomFile={isRoomFile}
          exportCsv={() => setShowCSVDialog(true)}
          canCastBoard={!!data?.file}
          castBoard={castBoard}
          sendLogs={sendLogs}
        />
        {room && loadedFileInfo?.fileName && <RoomFileName>{loadedFileInfo?.fileName}</RoomFileName>}
      </ContainerElement>

      {showCloseDialog && <EndSessionDialog onCancel={() => setShowCloseDialog(false)} />}
      {boardToCast !== null && <CastDialog stay file={boardToCast} onCancel={() => setBoardToCast(null)} />}
    </>
  );
};

export default AppControls;
