import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory, useLocation } from 'react-router-dom';

import * as Styled from 'client/common/codeDialog';
import {
  FileOrFileLink,
  isFileLink,
  MUTATION_CREATE_BOARD_LINK,
  MUTATION_OPEN_BOARD_IN_ROOM,
} from 'client/common/graphql';
import { useActions } from 'client/hooks/useActions';
import useCodeInput, { invalidCharacterMessage, noMatchingRoomErrorMessage } from 'client/hooks/useCodeInput';

interface Props {
  file: FileOrFileLink;
  onCancel: () => void;
  stay: boolean;
}

const CastView: React.FC<Props> = ({ file, onCancel, stay }) => {
  const { disableClipBoarding } = useActions();

  const { code, codeInput: inputRef, handleCodeChange, hint } = useCodeInput();
  const [createBoardLink] = useMutation(MUTATION_CREATE_BOARD_LINK);
  const [openInRoom] = useMutation(MUTATION_OPEN_BOARD_IN_ROOM);
  const [error, setError] = useState('');
  const [localFile, setLocalFile] = useState(isFileLink(file) ? file.file : file);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setError(hint === 'invalid character' ? invalidCharacterMessage : '');
  }, [hint]);

  useEffect(() => {
    disableClipBoarding('showCastDialog', true);
    inputRef.current?.focus();

    return () => {
      disableClipBoarding('showCastDialog', false);
    };
  }, []);

  useEffect(() => {
    setLocalFile(isFileLink(file) ? file.file : file);
  }, [file]);

  const open = async () => {
    let linkData: { id: string };
    setError(null);

    if (isFileLink(file)) {
      linkData = file;
    } else {
      try {
        const result = await createBoardLink({ variables: { fileId: file.id } });
        linkData = result.data.createFileLink;
      } catch (e) {
        setError('Unable to open board');
      }
    }

    if (linkData) {
      try {
        await openInRoom({
          variables: {
            input: {
              roomCode: code,
              boardLinkId: linkData.id,
            },
          },
        });

        if (!stay) {
          history.push({
            pathname: `/dashboard/open/${localFile.id}`,
            state: {
              from: location.pathname,
            },
          });
        } else {
          onCancel();
        }
      } catch (e) {
        setError(noMatchingRoomErrorMessage);
      }
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      (async () => {
        await open();
      })();
    }
  }, [code]);

  useHotkeys(
    'return',
    () => {
      if (code.length === 6) {
        open();
      }
    },
    { enableOnTags: ['INPUT'] },
    [code]
  );

  const getCodeInput = () =>
    Array.from({ ...code.split(''), length: 6 }).map((value, index) => (
      <Styled.CodeCharInput key={index} className={`${value && 'has-char'} ${code.length === index && 'active'}`}>
        {value?.toUpperCase()}
      </Styled.CodeCharInput>
    ));

  return (
    <>
      <Styled.Wrapper onClick={() => inputRef.current?.focus()}>
        {!isMobileOnly && <Styled.Thumbnail src={localFile.thumbnailUri} />}
        <Styled.Title>
          <div>
            Open in Room
            <Styled.FileName>{localFile.fileName}</Styled.FileName>
          </div>
        </Styled.Title>
        <Styled.InfoText>
          Enter the room code to <br /> open the board in the Room
        </Styled.InfoText>
        <Styled.CodeWrapper>{getCodeInput()}</Styled.CodeWrapper>
        <input maxLength={7} inputMode="numeric" type="text" autoFocus ref={inputRef} onChange={handleCodeChange} />
        {!!error && <Styled.Error>{error}</Styled.Error>}
      </Styled.Wrapper>
    </>
  );
};

export default CastView;
