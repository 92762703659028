import 'regenerator-runtime/runtime';
import { ApolloProvider } from '@apollo/client';
import * as microsoftTeams from '@microsoft/teams-js';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'client/polyfills/offscreen-canvas';
import 'client/polyfills/toBlob';
import * as analytics from 'client/common/analytics';
import { initializeAnalytics } from 'client/common/analytics';
import { client, setListener } from 'client/common/graphql';
import AppRoot from 'client/components/App';
import { ProvideAuth } from 'client/hooks/useAuth';
import * as actions from 'client/state/actions';
import cleanStorage from 'client/storage-patches';
import { store } from 'client/store';
import 'client/common/pageState';

if (window.INITIAL_STATE.gcpEnv) {
  Sentry.init({
    dsn: 'https://7eefee94692840b6afa3b91980ccee5d@sentry.io/1197324',
    environment: window.INITIAL_STATE.gcpEnv,
    release: window.INITIAL_STATE.revision.webApp.long,
  });
}

cleanStorage();

initializeAnalytics();

microsoftTeams.initialize();
microsoftTeams.getContext((c) => {
  if (c.frameContext === 'content') {
    if (c.meetingId) {
      analytics.setIntegrationContext('teams:meetingTab');
    } else if (c.channelId) {
      analytics.setIntegrationContext('teams:channelTab');
    } else {
      analytics.setIntegrationContext('teams:chatTab');
    }
  } else if (c.frameContext === 'meetingStage') {
    analytics.setIntegrationContext('teams:meetingStage');
  }
});

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

if (params.allowMobile) {
  store.dispatch(actions.setAllowMobile(params.allowMobile === '1'));
}

setListener((date) => {
  store.dispatch(actions.setGraphqlWsConnectedTime(date));
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ProvideAuth>
        <AppRoot />
      </ProvideAuth>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);
