import React, { useState } from 'react';

import { useSelector } from 'client/hooks/useSelector';
import { useFetch } from 'client/hooks/useFetch';
import { AutoSyncBindingProvider, AzureADInfo } from 'client/components/Dashboard/admin/types';
import LoadingIndicator from 'client/components/Common/loadingIndicator';
import * as Assets from 'client/components/Dashboard/admin/assets';
import AutosyncBindings from 'client/components/Dashboard/admin/integrations/autosync';
import UserFullList from 'client/components/Dashboard/admin/integrations/teams/user-full-list';
import { getFirebaseAuth } from 'client/common/firebase';
import * as rest from 'client/services/rest';

import * as Styled from './styled';

const TeamsIntegration: React.FC = () => {
  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    collaborationServerUrl: state.collaborationServerUrl,
    tenant: state.tenant,
  }));

  const azureAdInfo = useFetch<AzureADInfo | null>('i/teams', null, (data) => data);

  const [showUserFullList, setShowUserFullList] = useState(false);

  const connectWithMicrosoft = async () => {
    const token = await getFirebaseAuth().currentUser.getIdToken();
    const response = await rest.makeRequest<string>({
      method: 'GET',
      url: `${collaborationServerUrl}/tenant/${tenant.id}/i/teams/initiate`,
      authorizationToken: { auth: token },
    });

    if (response.status === 200) {
      window.location.href = response.data;
    }
  };

  const disconnectFromMicrosoft = async () => {
    const token = await getFirebaseAuth().currentUser.getIdToken();
    const response = await rest.makeRequest<string>({
      method: 'GET',
      url: `${collaborationServerUrl}/tenant/${tenant.id}/i/teams/disconnect`,
      authorizationToken: { auth: token },
    });

    if (response.status === 200) {
      azureAdInfo.fetch();
    }
  };

  const header = (
    <Styled.ColumnHeader>
      <Styled.ColumnHeaderIcon>
        <Assets.AdminIntegrationTeamsIcon />
      </Styled.ColumnHeaderIcon>
      <Styled.ColumnHeaderText>Microsoft 365</Styled.ColumnHeaderText>
    </Styled.ColumnHeader>
  );

  if (azureAdInfo.loading) {
    return (
      <>
        {header}
        <Styled.Card>
          <LoadingIndicator />
        </Styled.Card>
      </>
    );
  }

  if (!azureAdInfo.data) {
    return (
      <>
        {header}
        <Styled.ConnectIntegrationCard>
          <Styled.ConnectIntegrationImage>
            <Assets.AdminIntegrationTeamsIcon />
          </Styled.ConnectIntegrationImage>
          <Styled.ConnectIntegrationInfo>
            <Styled.ConnectIntegrationInfoTitle>
              Connect Microsoft 365 to FlatFrog Board
            </Styled.ConnectIntegrationInfoTitle>
            <Styled.ConnectIntegrationInfoText>
              View active users, sign in with Microsoft 365 account to FlatFrog Board and Auto-sync Teams meetings with
              FlatFrog Board.
            </Styled.ConnectIntegrationInfoText>
          </Styled.ConnectIntegrationInfo>
          <Styled.ConnectIntegrationButton onClick={() => connectWithMicrosoft()}>
            Connect
          </Styled.ConnectIntegrationButton>
        </Styled.ConnectIntegrationCard>
      </>
    );
  }

  const { id: tenantId, domain, directoryStats } = azureAdInfo.data;
  const percentageFfbUsers = Math.floor((directoryStats.ffbUsers / directoryStats.totalUsers) * 100.0);

  return (
    <>
      {header}
      {azureAdInfo.data ? (
        <>
          <Styled.IntegrationEnabledCard>
            <Styled.IntegrationEnabledHeader>
              <Styled.IntegrationEnabledHeaderIcon>
                <Assets.AdminIntegrationEnabledIcon />
              </Styled.IntegrationEnabledHeaderIcon>
              <Styled.IntegrationEnabledHeaderText>
                FlatFrog Board is now integrated to your Microsoft 365
              </Styled.IntegrationEnabledHeaderText>
              <Styled.IntegrationDisconnectButton>
                <Styled.MiniButton onClick={() => disconnectFromMicrosoft()}>Disconnect</Styled.MiniButton>
              </Styled.IntegrationDisconnectButton>
            </Styled.IntegrationEnabledHeader>
            <Styled.IntegrationTenantInfo>
              <Styled.IntegrationTenantInfoRow>
                <Styled.IntegrationTenantInfoRowTitle>Tenant Name:</Styled.IntegrationTenantInfoRowTitle>
                {domain}
              </Styled.IntegrationTenantInfoRow>
              <Styled.IntegrationTenantInfoRow>
                <Styled.IntegrationTenantInfoRowTitle>Tenant ID:</Styled.IntegrationTenantInfoRowTitle>
                {tenantId}
              </Styled.IntegrationTenantInfoRow>
            </Styled.IntegrationTenantInfo>
            <Styled.Divider />
            <Styled.IntegrationDirectoryStats>
              <Styled.IntegrationDirectoryStatsRow>
                <Styled.IntegrationDirectoryStatsRowValue>
                  {directoryStats.totalUsers}
                </Styled.IntegrationDirectoryStatsRowValue>{' '}
                Microsoft 365 users
              </Styled.IntegrationDirectoryStatsRow>
              <Styled.IntegrationDirectoryStatsRow>
                <Styled.IntegrationDirectoryStatsRowValue>
                  {directoryStats.ffbUsers}
                </Styled.IntegrationDirectoryStatsRowValue>{' '}
                have a FlatFrog Board account ({percentageFfbUsers}%)
              </Styled.IntegrationDirectoryStatsRow>
            </Styled.IntegrationDirectoryStats>
            <Styled.ViewDirectoryButton>
              <Styled.MiniButton onClick={() => setShowUserFullList((v) => !v)}>
                {showUserFullList ? 'Hide full list' : 'See full list'}
              </Styled.MiniButton>
            </Styled.ViewDirectoryButton>
          </Styled.IntegrationEnabledCard>
          {showUserFullList && <UserFullList />}
          <AutosyncBindings provider={AutoSyncBindingProvider.TEAMS} />
        </>
      ) : (
        <Styled.Card>Connect Microsoft 365 to FlatFrog Board</Styled.Card>
      )}
    </>
  );
};

export default TeamsIntegration;
