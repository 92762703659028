import classNames from 'classnames';
import React from 'react';

import { Item } from 'client/state/papers/localItems';
import StickyNote from 'client/components/StickyNote';
import * as analytics from 'client/common/analytics';
import TextBlock from 'client/components/TextBlock';
import * as Styled from 'client/components/ItemStack/styled';
import SmallCheckbox from 'client/components/Common/small-checkbox';
import { useActions } from 'client/hooks/useActions';

interface Props extends Item {
  index: number;
  selected: boolean;
  setSelected: (selected: boolean) => void;
  disabled: boolean;
}

export const ItemComponent: React.FC<Props> = ({
  __typename,
  backgroundColor,
  content,
  id,
  outlineColor,
  signature,
  textAlign,
  textColor,
  textSize,
  index = 0,
  selected,
  setSelected,
  disabled,
}) => {
  const { currentStickyNoteEdit, currentTextBlockEdit } = useActions();

  if (__typename === 'StickyNote') {
    return (
      <Wrapper
        id={id}
        selected={selected}
        disabled={disabled}
        index={index}
        setSelected={setSelected}
        itemOpenAction={() => {
          currentStickyNoteEdit({ id });
          analytics.viewPanel('/my-items/sticky-note-editor');
        }}
        itemSquareContent="Aa"
        itemSquareStyle={{
          background: backgroundColor,
          color: textColor,
          fontFamily: 'Caveat',
        }}
        content={content}
      >
        <StickyNote
          isSent={false}
          id={id}
          color={backgroundColor}
          content={content}
          textColor={textColor}
          textAlign={textAlign}
          textSize={textSize}
          signature={signature}
        />
      </Wrapper>
    );
  }
  if (__typename === 'TextBlock') {
    return (
      <Wrapper
        id={id}
        selected={selected}
        disabled={disabled}
        index={index}
        setSelected={setSelected}
        itemOpenAction={() => {
          currentTextBlockEdit({ id });
          analytics.viewPanel('/my-items/text-block-editor');
        }}
        itemSquareStyle={{
          background: backgroundColor,
          border: outlineColor === '#00000000' ? '1px solid rgba(0, 0, 0, 0.1)' : `2px solid ${outlineColor}`,
          color: textColor,
          fontFamily: 'Open Sans',
          fontWeight: 600,
        }}
        itemSquareContent="T"
        content={content}
      >
        <TextBlock
          id={id}
          color={backgroundColor}
          outlineColor={outlineColor}
          content={content}
          textColor={textColor}
          textAlign={textAlign}
          textSize={textSize}
        />
      </Wrapper>
    );
  }
  return null;
};

interface WrapperProps {
  id: string;
  selected: boolean;
  disabled: boolean;
  index: number;
  setSelected: (selected: boolean) => void;
  itemOpenAction: () => void;
  itemSquareStyle: React.CSSProperties;
  itemSquareContent: string;
  content: string;
}

const Wrapper: React.FC<WrapperProps> = ({
  id,
  selected,
  disabled,
  index,
  setSelected,
  itemOpenAction,
  itemSquareStyle,
  itemSquareContent,
  children,
  content,
}) => (
  <Styled.ListItemWrapper
    key={id}
    className={classNames({ selected, disabled })}
    style={{ animationDelay: `${index * 50}ms` }}
  >
    <Styled.ListItemColumn className="checkbox-col">
      <label>
        <SmallCheckbox
          id={`item-stack-select-item-${index}`}
          checked={selected}
          disabled={disabled}
          onChange={(e) => setSelected(e.target.checked)}
        />
      </label>
    </Styled.ListItemColumn>
    <Styled.ListItemColumn onClick={disabled ? null : itemOpenAction} className="item-col">
      <Styled.ItemSquare style={itemSquareStyle}>
        {itemSquareContent}
        {/* THIS IS JUST TO HAVE EVERYTHING TO BE ABLE TO RENDER IT */}
        <div style={{ position: 'absolute', opacity: 0, transform: 'scale(0)' }}>{children}</div>
      </Styled.ItemSquare>
      <Styled.ItemLabel>{content}</Styled.ItemLabel>
    </Styled.ListItemColumn>
  </Styled.ListItemWrapper>
);
