import { Tenant } from 'client/state/settings/tenant';

const fetchTenant = async (suffix: string): Promise<Tenant | null> => {
  const response = await fetch(`${window.INITIAL_STATE.collaborationServerUrl}/tenant/${suffix}`);
  return response.ok ? response.json() : null;
};

export const fetchTenantById = (tenantId: string) => fetchTenant(`subdomain/${tenantId}`);

export const fetchTenantByFirebaseId = (firebaseId: string) => fetchTenant(`firebase/${firebaseId}`);

export const fetchTenantByAzureAdDomain = (azureAdDomain: string) => fetchTenant(`azuread/${azureAdDomain}`);
