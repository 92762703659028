import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import * as DashboardStyled from 'client/components/Dashboard/styled';
import * as DialogStyled from 'client/components/Common/dialog-styled';

export const Container = styled(DashboardStyled.MainColumn)`
  max-width: 1082px;
  flex-direction: row;
  padding-top: 56px;
  padding-bottom: 56px;
`;

export const SidebarColumn = styled.div`
  flex: 0 0 210px;
  margin-right: 24px;
`;

export const Sidebar = styled.div`
  margin-top: 48px;
`;

export const SidebarItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #242424;

  &.active {
    background: rgba(0, 0, 0, 0.04) 0 0 no-repeat padding-box;
  }

  > img {
    margin-right: 12px;
    width: 16px;
  }
`;

export const SidebarSection = styled.div`
  margin-bottom: 8px;
`;

export const SidebarSectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 8px;
  margin-right: 4px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.32px;
  font-weight: 600;
  color: #8c8c8c;
`;

export const MainColumn = styled.div`
  flex: 1 1 auto;
`;

export const ColumnHeader = styled.div`
  display: flex;
  height: 32px;
  margin-bottom: 16px;
  margin-left: 8px;
`;

export const ColumnHeaderIcon = styled.div`
  width: 32px;
  margin-right: 4px;
  > img {
    padding: 8px;
  }
`;

export const ColumnHeaderText = styled.div`
  font-size: 23px;
  letter-spacing: -0.57px;
`;

export const ColumnHeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const SectionHeader = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  margin-left: 44px;
  max-width: 512px;
`;

export const SectionHeaderTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
`;

export const SectionHeaderSubtitle = styled.div`
  margin-top: 4px;
  font-size: 13px;
  line-height: 18px;
  color: #8c8c8c;
`;

export const FlexBox = styled.div<{ weight?: number }>`
  flex: ${(p) => (p.weight ? p.weight : 'initial')};
`;

export const ContextMenuButton = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin: auto 0 auto 16px;
  cursor: pointer;
  background: transparent url(/icons/icn_ellipsis_v.svg) center center no-repeat;
  background-size: 14px 14px;
  opacity: 0.33;
  transition: background-color 300ms ease-out, opacity 300ms ease-out;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const DialogTitle = styled(DialogStyled.Title)``;
export const DialogBody = styled(DialogStyled.Body)`
  font-size: 13px;
`;

const _DialogForm = styled.form`
  margin: 24px 0 0;
`;

export const DialogForm = styled(_DialogForm)`
  width: 320px;
  text-align: left;
`;

const _DialogButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  & > button {
    margin: 0 8px;
  }
`;

export const DialogButtonContainer = styled(_DialogButtonContainer)`
  margin-top: 32px;
`;

export const InputGroup = styled.div`
  margin-bottom: 16px;
`;

export const Input = styled.input.attrs<{ type?: string }>((p) => ({
  type: p.type || 'text',
}))`
  display: block;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: #242424;
  height: 48px;
  padding: 12px 16px;
  background: #fff;
  border: 2px solid #c3c3c3;
  border-radius: 6px;
  width: 100%;
  margin: 0;

  &:focus {
    border-color: #279ae7;
    background-color: #fafafa;
  }

  &::placeholder {
    color: #8c8c8c;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.26px;
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 8px;
`;

export enum ButtonSize {
  DEFAULT,
  SMALL,
  MINI,
}

export enum ButtonProfile {
  DEFAULT,
  OUTLINED,
  MINIMAL,
}

export const Button = styled.button.attrs<{ type?: string }>((p) => ({
  type: p.type || 'button',
}))<{ size?: ButtonSize; profile?: ButtonProfile }>`
  outline: none;
  background: #eaeaea;
  color: #242424;
  min-width: 120px;
  padding: 13px 21px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #d6d6d6;
  }

  &:disabled {
    opacity: 0.8;
    filter: grayscale();
    cursor: default;
  }

  ${(p) =>
    p.size === ButtonSize.SMALL &&
    css`
      min-width: 96px;
      padding: 11px 18px;
      border-radius: 5px;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.32px;
    `}

  ${(p) =>
    p.size === ButtonSize.MINI &&
    css`
      min-width: 70px;
      padding: 5px 8px;
      border-radius: 3px;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.24px;
    `}

  ${(p) =>
    p.profile === ButtonProfile.OUTLINED &&
    css`
      background: transparent;
      border: 1px solid #eaeaea;
      color: #eaeaea;

      &:hover {
        background: #eaeaea;
        color: #242424;
      }
    `}

  ${(p) =>
    p.profile === ButtonProfile.MINIMAL &&
    css`
      background: transparent;
      padding: 0;
    `}
`;

export const PrimaryButton = styled(Button)`
  background-color: #279ae7;
  color: #fff;
  &:hover {
    background-color: #2287ca;
  }

  ${(p) =>
    p.profile === ButtonProfile.OUTLINED &&
    css`
      background: transparent;
      border: 1px solid #279ae7;
      color: #2287ca;

      &:hover {
        background: #279ae7;
        color: #fff;
      }
    `}
`;
