import React from 'react';
import ReactDOM from 'react-dom';

import { ModalBackDrop } from 'client/components/Common/dialog-styled';

import * as Styled from './styled-template-gallery';
import TemplateGallery from './template-gallery';

interface Props {
  onClose: () => void;
  userRole: string;
  onSelect: (template: { teamId?: string; new: true } | { templateId: string; teamId: string; template: true }) => void;
}

const TemplateGalleryModal: React.FC<Props> = ({ onClose, userRole, onSelect }) =>
  ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.GalleryContainer>
        <TemplateGallery modal={true} onClose={onClose} userRole={userRole} onSelect={onSelect} />
        <Styled.CloseButton onClick={onClose} />
      </Styled.GalleryContainer>
    </>,
    document.getElementById('modal-portal')
  );

export default TemplateGalleryModal;
