import ColorHash from 'color-hash';

export const getParticipantName = (name: string): string => {
  if (name === null) {
    return '?';
  }

  const trimmedName = name.trim();

  if (trimmedName.length === 0) {
    return '?';
  }

  if (trimmedName.length > 1) {
    const splitName = trimmedName.split(/\s+/);
    if (splitName.length > 1) {
      return `${splitName[0][0].toUpperCase()}${splitName[1][0].toUpperCase()}`;
    }

    return `${trimmedName[0].toUpperCase()}${trimmedName[1]}`;
  }

  return trimmedName[0].toUpperCase();
};

const colorHash = new ColorHash({ lightness: [0.4], saturation: 0.5 });

export const getParticipantColor = (name: string): string => colorHash.hex(name);
export const getParticipantColorRGB = (name: string): [number, number, number] => colorHash.rgb(name);
