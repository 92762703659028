import classNames from 'classnames';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import * as analytics from 'client/common/analytics';
import { AppServerUrl } from 'client/components/Common/AppServerUrl';
import useEventListener from 'client/hooks/useEventListener';
import { useSelector } from 'client/hooks/useSelector';

import Invite from './Invite';
import ParticipantList from './ParticipantList';
import PresenterFollower from './PresenterFollower';
import * as Styled from './styled';

const isOverflown = ({ clientHeight, scrollHeight }: HTMLDivElement) => scrollHeight > clientHeight;

interface Props {
  show: boolean;
  hide: () => void;
}

const CollaborationSidePanel: React.FC<Props> = ({ show, hide }) => {
  const { room, session } = useSelector((state) => ({
    session: state.session,
    room: state.room,
  }));

  const scrollArea = useRef<HTMLDivElement>();
  const [alone, setAlone] = useState(true);
  const wrapper = useRef<HTMLDivElement>();

  useEffect(() => {
    const clients = session.clients || [];
    setAlone(clients.length < 2);
  }, [session]);

  useEffect(() => {
    if (show) {
      analytics.viewPanel('/collaboration');
    }
  }, [show]);

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (!wrapper.current?.contains(e.detail)) {
      hide();
    }
  });

  const handleScrollAreaResize = () => {
    if (alone) {
      return;
    }

    const { classList } = scrollArea.current;

    if (isOverflown(scrollArea.current)) {
      classList.add('overflown');
    } else {
      classList.remove('overflown');
    }
  };

  useEventListener('resize', handleScrollAreaResize, window);

  useLayoutEffect(() => {
    handleScrollAreaResize();
  });

  return (
    <Styled.Wrapper ref={wrapper} className={classNames({ show, room })}>
      {!room && (
        <Styled.Header>
          <img src="/icons/icn_collaboration.svg" alt="Collaboration" />
          Collaboration
          <button className="close" onClick={hide} />
        </Styled.Header>
      )}
      {alone && <Styled.AloneImage src="/images/ill_ping_pong.svg" alt="Alone" />}
      {(alone || room) && (
        <Styled.AloneWrapper className={classNames({ room, alone })}>
          {room ? (
            <h2 className="room">
              Join board from your device at
              <br />
              <AppServerUrl path="/join" />
            </h2>
          ) : (
            <h2>
              Invite people to start
              <br />
              collaborate on this board
            </h2>
          )}
        </Styled.AloneWrapper>
      )}
      {!alone && (
        <>
          <Styled.PresenterFollowerControlWrapper className={classNames({ room, alone })}>
            <PresenterFollower alone={alone} />
          </Styled.PresenterFollowerControlWrapper>
          <Styled.ParticipantScrollArea ref={scrollArea}>
            <ParticipantList />
          </Styled.ParticipantScrollArea>
        </>
      )}
      {session.id && (
        <Styled.InviteWrapper className={classNames({ room, alone })}>
          <Invite alone={alone} />
        </Styled.InviteWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default CollaborationSidePanel;
