import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { fetchTenantById, fetchTenantByFirebaseId } from 'client/state/settings/fetchTenant';

export type Tenant = {
  id: string;
  subdomain: string;
  firebaseId: string;
  displayName: string;
  signInMethods: {
    google: boolean;
    microsoft: boolean;
    email: boolean;
  };
  logoUrl: string;
  azureAdDomain: string;
} | null;

const setTenantState = createAction<Tenant>('setTenant');

export const setTenant = createAsyncThunk('setTenant', async (tenantId: string, { dispatch }) => {
  const tenant = await fetchTenantById(tenantId);
  if (tenant) {
    dispatch(setTenantState(tenant));
  }
});

export const setTenantByFirebaseId = createAsyncThunk(
  'setTenantByFirebaseId',
  async (firebaseId: string, { dispatch }) => {
    const tenant = await fetchTenantByFirebaseId(firebaseId);

    if (tenant) {
      dispatch(setTenantState(tenant));
    }
  }
);

export default createReducer<Tenant>(null, (builder) =>
  builder.addCase(setTenantState, (state, action) => action.payload)
);
