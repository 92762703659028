import cropUrl from 'crop-url';
import React from 'react';
import ZCanvas from '@flatfrog/ffbec';

import * as analytics from 'client/common/analytics';
import { useSelector } from 'client/hooks/useSelector';

import * as Styled from './paperlink-styled';

interface Props {
  paperId: number;
  ready: boolean;
}

const PaperLink: React.FC<Props> = ({ paperId, ready }) => {
  if (!ready) {
    return null;
  }

  const { isTeamsRoom } = useSelector((state) => ({
    isTeamsRoom: state.isTeamsRoom,
  }));

  const metadataString = ZCanvas.paper.getMetadata(paperId);
  let metadata: { Link: string };
  try {
    metadata = JSON.parse(metadataString);
  } catch (err) {
    console.log('paper without metadata');
  }

  const paperInfo = ZCanvas.paper.getInfo(paperId);
  const top = paperInfo.corners.map((c) => c.y).reduce((acc, y) => Math.min(acc, y));
  const left = paperInfo.corners.map((c) => c.x).reduce((acc, x) => Math.min(acc, x));
  const right = paperInfo.corners.map((c) => c.x).reduce((acc, x) => Math.max(acc, x));

  let shownLink = metadata?.Link ?? '';
  if (shownLink.startsWith('https://')) {
    shownLink = shownLink.substring('https://'.length);
  }
  if (shownLink.startsWith('http://')) {
    shownLink = shownLink.substring('http://'.length);
  }
  if (shownLink.startsWith('www.')) {
    shownLink = shownLink.substring('www.'.length);
  }
  shownLink = cropUrl(shownLink, 20);

  return (
    <Styled.PaperLinkContainer
      active={!isTeamsRoom}
      style={{
        left: 0.5 * (left + right),
        top: top - 10,
      }}
    >
      {!isTeamsRoom && (
        <a target="_blank" href={metadata?.Link} onClick={() => analytics.clickItemLink()}>
          <Styled.LinkIcon active={true} />
          {shownLink}
        </a>
      )}

      {isTeamsRoom && (
        <a>
          <Styled.LinkIcon active={false} />
          {shownLink}
        </a>
      )}
    </Styled.PaperLinkContainer>
  );
};

export default PaperLink;
