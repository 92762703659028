import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { RootState } from 'client/store';
import { addNotification } from 'client/state/actions';

export const setForceFollow = createAsyncThunk('setForceFollow', (on: boolean, { getState, dispatch }) => {
  const { session } = getState() as RootState;
  const client = session.clients.find((c) => c.isPresenter);

  if (client && !client.self) {
    dispatch(
      addNotification({
        content: `${client.name} has ${on ? 'locked' : 'unlocked'} the board`,
        type: 'setForceFollow',
      })
    );
  }

  return on;
});

export default createReducer(false, (builder) =>
  builder.addCase(setForceFollow.fulfilled, (state, action) => action.payload)
);
