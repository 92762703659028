import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ZCanvas from '@flatfrog/ffbec';

import useEventListener from 'client/hooks/useEventListener';

import * as Styled from './styled';

const DebugView = () => {
  const { env, gcpEnv, room } = window.INITIAL_STATE;

  if (env !== 'development' && gcpEnv !== 'development' && gcpEnv !== 'test') {
    return null;
  }

  // We have a stupid mix of room for prod/dev atm
  if (room) {
    return null;
  }

  const [visible, setVisible] = useState(true);
  const [stickinessErrors, setStickinessErrors] = useState<{ pageId: number; paperId: number }[]>([]);
  const [infoText, setInfoText] = useState('Last refresh failed');
  const [titleText, setTitleText] = useState('Needs refresh!');

  const visibleRef = useRef<boolean>(visible);

  useEffect(() => {
    visibleRef.current = visible;
  }, [visible]);

  useHotkeys(
    'cmd+d, ctrl+d',
    (event) => {
      event.preventDefault();
      setVisible(!visible);
    },
    { enableOnTags: ['INPUT'] },
    [visible]
  );

  useEffect(() => {
    setTitleText('Needs refresh!');
    setInfoText('Last refresh failed');
    setStickinessErrors([]);
  }, [visible]);

  const updateResults = () => {
    if (!visibleRef.current) {
      return;
    }
    if (!ZCanvas.getZcoreCreated || !ZCanvas.getZcoreCreated()) {
      setTitleText('Needs refresh!');
      setInfoText('Last refresh failed');
      return;
    }
    const stickinessIssues = ZCanvas.paper.debugGetStickinessIssues();
    setStickinessErrors(stickinessIssues);
    setTitleText(
      stickinessIssues.length === 0 ? 'No stickiness errors' : `${stickinessIssues.length} stickiness errors`
    );
    const pagesText =
      stickinessIssues.length > 0
        ? `Pages ${[...new Set(stickinessIssues.map((e) => e.pageId))]
            .map((pageId) => ZCanvas.page.getIndexFromId(pageId) + 1)
            .join(', ')}  |  `
        : '';

    setInfoText(`${pagesText}${dayjs().format('HH:mm:ss')}`);
  };

  useEventListener('zcanvasinitialized', async () => {
    await ZCanvas.initialized;
    ZCanvas.addEventListener('CURRENT_PAGE_CHANGED', updateResults);
    ZCanvas.addEventListener('PAPER_CREATED', updateResults);
    ZCanvas.addEventListener('PAPER_METADATA_CHANGED', updateResults);
    ZCanvas.addEventListener('PAPER_REMOVED', updateResults);
    ZCanvas.addEventListener('PAPER_MANIPULATION_ENDED', updateResults);
    ZCanvas.addEventListener('PAPER_TAPPED', updateResults);
    setInterval(() => updateResults(), 100);
  });

  return (
    visible && (
      <Styled.Container>
        <Styled.View className={stickinessErrors.length > 0 ? 'error' : ''}>
          <Styled.CloseButton
            onClick={() => {
              setVisible(false);
            }}
          />
          <Styled.HorizontalStack>
            <Styled.VerticalStack>
              <Styled.Title>{titleText}</Styled.Title>
              <Styled.SubTitle>{infoText}</Styled.SubTitle>
            </Styled.VerticalStack>
            <Styled.Button
              onClick={() => {
                updateResults();
              }}
            >
              Refresh
            </Styled.Button>
            <Styled.Button
              onClick={() => {
                const currentPageId = ZCanvas.page.getCurrentId();
                const paperIds = stickinessErrors.filter((e) => e.pageId === currentPageId).map((e) => e.paperId);
                paperIds.forEach((paperId, index) => {
                  ZCanvas.paper.select(paperId, index !== 0);
                });
              }}
            >
              View
            </Styled.Button>
          </Styled.HorizontalStack>
        </Styled.View>
      </Styled.Container>
    )
  );
};

export default DebugView;
