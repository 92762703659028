import { createAction, createReducer } from '@reduxjs/toolkit';

export type Listener = (
  action: {
    latestCorrectActionId?: string;
    preventRedo?: boolean;
    json?:
      | {
          ActionId: string;
          _prevActionId: string;
        }
      | {
          ActionId: string;
          _prevActionId: string;
        }[];
  },
  initialStateDone: boolean
) => void;

export const addActionListener = createAction<Listener>('addActionListener');
export const removeActionListener = createAction<Listener>('removeActionListener');

export default createReducer<Listener[]>([], (builder) =>
  builder
    .addCase(addActionListener, (state, action) => [...state, action.payload])
    .addCase(removeActionListener, (state, action) => state.filter((l) => l.toString() !== action.payload.toString()))
);
