import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ZCanvas from '@flatfrog/ffbec';

import * as analytics from 'client/common/analytics';
import { BackgroundColors } from 'client/components/StickyNote/colors';
import { useActions } from 'client/hooks/useActions';
import useEventListener from 'client/hooks/useEventListener';
import { useSelector } from 'client/hooks/useSelector';

import * as Styled from './styled';

interface Props {
  ready: boolean;
}

const FilterSearchField: React.FC<Props> = ({ ready }) => {
  if (!ready) {
    return null;
  }

  const { forceFollow, isFollower, lostConnection, session, showFilterSearchField } = useSelector((state) => ({
    forceFollow: state.forceFollow,
    isFollower: state.isFollower,
    lostConnection: state.showDisconnectDialog.show,
    session: state.session,
    showFilterSearchField: state.showFilterSearchField,
  }));

  const { disableClipBoarding, setFollower, setShowFilterSearchField } = useActions();

  const [filterString, setFilterString] = useState('');
  const [pagesString, setPagesString] = useState('');
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);
  const [searchFieldShaking, setSearchFieldShaking] = useState(false);
  const [searchResults, setSearchResults] = useState<Record<number, number[]>>({});
  const [currentPageId, setCurrentPageId] = useState(1);
  const [foundResults, setFoundResults] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<
    (
      | {
          type: 'color';
          colorId: number;
        }
      | {
          type: 'sticker';
          imageData: string;
          imageHash: string;
        }
    )[]
  >([]);

  const searchFieldRef = useRef<HTMLInputElement>(null);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const visibleRef = useRef(false);

  useEffect(() => {
    disableClipBoarding('searchFieldFocused', searchFieldFocused);
  }, [searchFieldFocused]);

  useEffect(() => {
    visibleRef.current = showFilterSearchField;
    if (showFilterSearchField) {
      ZCanvas.paper.filterAll();
      setCurrentPageId(ZCanvas.page.getCurrentId());
      setSearchFieldShaking(false);
      setShowMoreOptions(true);
    } else {
      setFilterString('');
      setPagesString('');
      setSelectedFilters([]);
      setSearchResults({});
      if (ZCanvas.getZcoreCreated && ZCanvas.getZcoreCreated()) {
        ZCanvas.paper.unfilterAll();
      }
    }
  }, [showFilterSearchField]);

  useEffect(() => {
    if (lostConnection && showFilterSearchField) {
      setShowFilterSearchField(false);
    }
  }, [lostConnection, showFilterSearchField]);

  useEffect(() => {
    if (!showFilterSearchField && foundResults) {
      // User has found results and closed box
      analytics.searched();
      setFoundResults(false);
    }
  }, [showFilterSearchField, foundResults]);

  const filterStringRef = useRef('');
  useEffect(() => {
    filterStringRef.current = filterString;
  }, [filterString]);

  const searchResultsRef = useRef({});
  useEffect(() => {
    searchResultsRef.current = searchResults;
  }, [searchResults]);

  const currentPageIdRef = useRef(1);
  useEffect(() => {
    currentPageIdRef.current = currentPageId;
  }, [currentPageId]);

  const selectedFiltersRef = useRef([]);
  useEffect(() => {
    selectedFiltersRef.current = selectedFilters;
  }, [selectedFilters]);

  useHotkeys(
    'esc',
    () => {
      if (!visibleRef.current) {
        return;
      }

      setShowFilterSearchField(false);
    },
    { enableOnTags: ['INPUT'] },
    [visibleRef]
  );

  useHotkeys(
    'cmd+f, ctrl+f',
    (event) => {
      event.preventDefault();

      if (visibleRef.current) {
        searchFieldRef.current.focus();
      } else {
        setShowFilterSearchField(true);
      }
    },
    { enableOnTags: ['INPUT'] },
    [visibleRef, searchFieldRef]
  );

  const disablePageChanges = !session?.clients?.find((c) => c.self)?.isPresenter && forceFollow;

  if (!forceFollow && searchFieldShaking) {
    setSearchFieldShaking(false);
  }

  useEffect(() => {
    if (filterStringRef.current === '' && selectedFiltersRef.current.length === 0) {
      setPagesString('');
    } else {
      const pageIds = Object.keys(searchResultsRef.current);

      if (pageIds.length === 0) {
        setPagesString('No results');
      } else if (currentPageIdRef.current in searchResultsRef.current) {
        setPagesString(`${1 + pageIds.indexOf(`${currentPageIdRef.current}`)} / ${pageIds.length} pages`);
        setFoundResults(true);
      } else {
        setPagesString(`${pageIds.length} other page${pageIds.length > 1 ? 's' : ''}`);
        setFoundResults(true);
      }
    }
  }, [searchResults, filterString, selectedFilters, currentPageId]);

  const refreshResults = () => {
    if (!visibleRef.current) {
      return;
    }

    const colors = selectedFiltersRef.current.filter((f) => f.type === 'color').map((c) => c.colorId);
    const stickers = selectedFiltersRef.current.filter((f) => f.type === 'sticker').map((s) => s.imageHash);
    const results = ZCanvas.paper?.filterPapers(filterStringRef.current, colors, stickers);
    setSearchResults(results ?? {});
  };

  useEffect(() => {
    refreshResults();
  }, [filterString, selectedFilters]);

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (!visibleRef.current) {
      return;
    }
    if (!searchFieldRef.current.contains(e.detail)) {
      searchFieldRef.current.blur();
    }
    if (!outerContainerRef.current.contains(e.detail)) {
      setShowMoreOptions(false);
    }
  });

  const handlePaperCreated = () => {
    refreshResults();
  };

  const handlePaperMetadataChanged = () => {
    refreshResults();
  };

  const handlePageChanged = (event: { pageId: number }[]) => {
    if (!visibleRef.current) {
      return;
    }

    const [{ pageId }] = event;
    setCurrentPageId(pageId);
  };

  useEventListener('zcanvasinitialized', async () => {
    await ZCanvas.initialized;
    ZCanvas.addEventListener('CURRENT_PAGE_CHANGED', handlePageChanged);
    ZCanvas.addEventListener('PAPER_CREATED', handlePaperCreated);
    ZCanvas.addEventListener('PAPER_METADATA_CHANGED', handlePaperMetadataChanged);
  });

  const handleSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowMoreOptions(event.target.value === '');
    setFilterString(event.target.value);
    setSearchFieldShaking(false);
  };

  const handleSearchFieldKeyDown = (event: React.KeyboardEvent | React.MouseEvent, reverse = false) => {
    if (Object.keys(searchResults).length < 1) {
      return;
    }
    if (disablePageChanges) {
      setSearchFieldShaking(true);
      return;
    }

    const pageIdsWithResults = Object.keys(searchResults);
    const allPageIds = ZCanvas.page.getIds();

    if (reverse) {
      allPageIds.reverse();
    }

    const currentIndex = allPageIds.indexOf(currentPageId);
    const toSearch = [...allPageIds.slice(currentIndex + 1), ...allPageIds.slice(0, currentIndex + 1)];
    const found = toSearch.find((pageId) => pageIdsWithResults.includes(pageId.toString()));
    ZCanvas.page.setCurrentId(found);
    if (!forceFollow && !session?.clients?.find((c) => c.self)?.isPresenter && isFollower) {
      setFollower(false);
    }

    searchFieldRef.current.focus();
  };

  const searchResultsKeys = Object.keys(searchResults);

  const colorIdsToColors: Record<number, string> = {};
  BackgroundColors.forEach((c) => {
    colorIdsToColors[c.FFZinkEnumMapping] = c.color;
  });

  const overflowLength = 4;
  const renderChosenFilters = () => {
    const visibleFilters = selectedFilters.slice(0, overflowLength).map((filter) => {
      if (filter.type === 'color') {
        return (
          <Styled.ChosenFilterContainer
            key={`color:${filter.colorId}`}
            onClick={() => {
              setSelectedFilters(selectedFilters.filter((f) => f !== filter));
              searchFieldRef.current.focus();
            }}
          >
            <Styled.ChosenColorFilter style={{ backgroundColor: colorIdsToColors[filter.colorId] }} />
            <Styled.ChosenFilterOverlay />
          </Styled.ChosenFilterContainer>
        );
      }
      if (filter.type === 'sticker') {
        return (
          <Styled.ChosenFilterContainer
            key={`sticker:${filter.imageHash}`}
            onClick={() => {
              setSelectedFilters(selectedFilters.filter((f) => f !== filter));
              searchFieldRef.current.focus();
            }}
          >
            <div style={{ position: 'absolute' }}>
              <img draggable="false" src={URL.createObjectURL(new Blob([filter.imageData], { type: 'image/png' }))} />
            </div>
            <Styled.ChosenFilterOverlay />
          </Styled.ChosenFilterContainer>
        );
      }
      return null;
    });
    if (selectedFilters.length > overflowLength) {
      visibleFilters.push(
        <Styled.ChosenFilterOverflowIndicator
          key={'overflow-item'}
          onClick={() => {
            const newOrder = [
              ...selectedFilters.slice(overflowLength, selectedFilters.length),
              ...selectedFilters.slice(0, overflowLength),
            ];
            setSelectedFilters(newOrder);
          }}
        >
          {`+${selectedFilters.length - overflowLength}`}
        </Styled.ChosenFilterOverflowIndicator>
      );
    }
    return visibleFilters;
  };

  const renderFilterOptions = () => {
    const order = BackgroundColors.map((c) => c.FFZinkEnumMapping);
    const usedColors = ZCanvas.paper
      .getUsedPaperColors()
      .sort((a, b) => order.indexOf(a) - order.indexOf(b))
      .map((colorId) => (
        <Styled.OptionContainer
          key={colorId}
          onClick={(e) => {
            if (!e.shiftKey) {
              setShowMoreOptions(false);
              searchFieldRef.current.focus();
            }
            if (selectedFilters.some((f) => f.type === 'color' && f.colorId === colorId)) {
              setSelectedFilters([...selectedFilters.filter((f) => !(f.type === 'color' && f.colorId === colorId))]);
            } else {
              const newEntry = { type: 'color' as const, colorId };
              const oldEntries = selectedFilters.filter((f) => f.type !== 'color');
              setSelectedFilters(
                oldEntries.length >= overflowLength ? [newEntry, ...oldEntries] : [...oldEntries, newEntry]
              );
            }
          }}
        >
          <Styled.ColorOption style={{ backgroundColor: colorIdsToColors[colorId] }} />
        </Styled.OptionContainer>
      ));
    const usedStickers = ZCanvas.paper.getUsedStickerImages().map((stickerImage) => (
      <Styled.OptionContainer
        key={stickerImage.imageHash}
        onClick={(e) => {
          if (!e.shiftKey) {
            setShowMoreOptions(false);
            searchFieldRef.current.focus();
          }
          if (selectedFilters.some((f) => f.type === 'sticker' && f.imageHash === stickerImage.imageHash)) {
            setSelectedFilters([
              ...selectedFilters.filter((f) => !(f.type === 'sticker' && f.imageHash === stickerImage.imageHash)),
            ]);
          } else {
            const newEntry = {
              type: 'sticker' as const,
              imageHash: stickerImage.imageHash,
              imageData: stickerImage.imageData,
            };
            setSelectedFilters(
              selectedFilters.length >= overflowLength ? [newEntry, ...selectedFilters] : [...selectedFilters, newEntry]
            );
          }
        }}
      >
        <img draggable="false" src={URL.createObjectURL(new Blob([stickerImage.imageData], { type: 'image/png' }))} />
      </Styled.OptionContainer>
    ));
    return (
      <Styled.MoreOptionsContainer className={showMoreOptions ? 'open' : 'closed'}>
        <Styled.MoreOptionsHeader>Stickers</Styled.MoreOptionsHeader>
        <Styled.MoreOptionsCategoryContainer className={usedStickers.length === 0 ? 'no-items' : ''}>
          {usedStickers.length ? usedStickers : 'No stickers in current file'}
        </Styled.MoreOptionsCategoryContainer>
        <Styled.MoreOptionsHeader>Sticky Note Colors</Styled.MoreOptionsHeader>
        <Styled.MoreOptionsCategoryContainer className={usedColors.length === 0 ? 'no-items' : ''}>
          {usedColors.length ? usedColors : 'No sticky notes in current file'}
        </Styled.MoreOptionsCategoryContainer>
        <Styled.MultiSelectTip>Hold shift to multi-select</Styled.MultiSelectTip>
      </Styled.MoreOptionsContainer>
    );
  };

  return (
    showFilterSearchField && (
      <Styled.FullWidthWrapper>
        <Styled.OuterContainer ref={outerContainerRef}>
          <Styled.VerticalStack>
            <Styled.SearchFieldContainer
              className={(() => {
                if (searchFieldShaking) {
                  return 'shaking';
                }
                if (searchFieldFocused) {
                  return 'focused';
                }
                return '';
              })()}
            >
              <Styled.SearchButton
                onClick={() => {
                  searchFieldRef.current.focus();
                  setShowMoreOptions(!showMoreOptions);
                }}
              />
              <Styled.ChosenFiltersContainer>{renderChosenFilters()}</Styled.ChosenFiltersContainer>
              <Styled.SearchField
                ref={searchFieldRef}
                onChange={handleSearchFieldChange}
                onKeyDown={(event) => {
                  switch (event.key) {
                    case 'Enter':
                      handleSearchFieldKeyDown(event, event.shiftKey);
                      break;
                    case 'Backspace':
                      if (event.currentTarget.value === '') {
                        setSelectedFilters(selectedFilters.slice(0, -1));
                      }
                      break;
                    case 'ArrowDown':
                    case 'ArrowUp':
                      setShowMoreOptions(!showMoreOptions);
                      event.preventDefault();
                      break;
                    default:
                      break;
                  }
                }}
                onFocus={() => setSearchFieldFocused(true)}
                onBlur={() => setSearchFieldFocused(false)}
                autoFocus
              />
              {pagesString.length > 0 && <Styled.PagesTextField>{pagesString}</Styled.PagesTextField>}
              <Styled.Delimiter />
              <Styled.PreviousPageButton
                className={
                  (searchResultsKeys.length > 1 ||
                    (searchResultsKeys.length === 1 && searchResultsKeys[0] !== currentPageId.toString())) &&
                  !disablePageChanges
                    ? ''
                    : 'disabled'
                }
                onClick={(e) => handleSearchFieldKeyDown(e, true)}
              />
              <Styled.NextPageButton
                className={
                  (searchResultsKeys.length > 1 ||
                    (searchResultsKeys.length === 1 && searchResultsKeys[0] !== currentPageId.toString())) &&
                  !disablePageChanges
                    ? ''
                    : 'disabled'
                }
                onClick={handleSearchFieldKeyDown}
              />
              <Styled.ExitFilterButton
                onClick={() => {
                  setShowFilterSearchField(false);
                }}
              />
            </Styled.SearchFieldContainer>
            {!showMoreOptions && searchFieldShaking && disablePageChanges && (
              <Styled.FollowerInfo>Someone is presenting with page lock on</Styled.FollowerInfo>
            )}
            {renderFilterOptions()}
            {showMoreOptions && searchFieldShaking && disablePageChanges && (
              <Styled.FollowerInfo>Someone is presenting with page lock on</Styled.FollowerInfo>
            )}
          </Styled.VerticalStack>
          {searchResultsKeys.includes(currentPageId.toString()) && (
            <Styled.SelectResultButton
              onClick={() => {
                const paperIds = searchResults[currentPageId];
                if (paperIds && paperIds.length > 0) {
                  paperIds.forEach((paperId, index) => {
                    ZCanvas.paper.select(paperId, index !== 0);
                  });
                  setShowFilterSearchField(false);
                }
              }}
            >
              Select result
            </Styled.SelectResultButton>
          )}
        </Styled.OuterContainer>
      </Styled.FullWidthWrapper>
    )
  );
};

export default FilterSearchField;
