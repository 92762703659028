import styled from 'styled-components';

import { FloatingPanel } from 'client/components/Common/common-styles';
import {
  Title,
  FileNameField as CommonFileNameField,
  ButtonContainer,
  NextButton,
  CancelButton,
} from 'client/components/Common/dialog-styled';

export const GroupHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 600;
`;

export const CreateBoardThumbnail = styled.div`
  width: 157px;
  height: 86px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  cursor: pointer;
  transition: box-shadow 300ms ease-out;

  &:hover {
    box-shadow: 0 0 0 4px #00000014, 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  }
`;

export const RecentBoardItem = styled.li`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 12px 16px;
  background-color: #fff;
  transition: background-color 300ms ease-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const SignUpPromo = styled.div`
  ${FloatingPanel};
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  color: #242424;
  font-size: 13px;
`;

export const DebugContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
`;

export const CopyField = styled.div`
  display: block;
  position: relative;
  text-align: left;
  font-size: 13px;
  color: #242424;
  height: 40px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
  padding: 8px;
  padding-right: 120px;
  padding-left: 16px;
  background: #f5f6f7;
  width: 466px;
  margin: 0;
  border-radius: 5px;

  &.copyDisabled {
    border: 0px;
    background-color: #f5f6f7;
    border-radius: 5px;
    padding: 0px;
  }
`;

export const CopyButton = styled.div`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  font-weight: 600;
  padding: 0px 24px;
  color: #2287ca;
  cursor: pointer;

  &:hover {
    color: #279ae7;
  }
`;

export const CreateInviteButton = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  color: #2287ca;
  cursor: pointer;

  &:hover {
    color: #279ae7;
  }
`;

export const ChangeButton = styled.div`
  position: absolute;
  right: 24px;
  font-size: 13px;
  font-weight: 600;
  color: #2287ca;
  cursor: pointer;

  &:hover {
    color: #155682;
  }
`;

export const CreateDialogLabel = styled.label`
  font-size: 13px;
  margin: 16px 0 8px 0;
  width: 100%;
  display: block;
  text-align: left;
`;

export const AccessLabel = styled(CreateDialogLabel)`
  color: #8c8c8c;
  margin-top: 8px;
`;

export const CreateDialogTitle = styled(Title)`
  margin-bottom: 8px;
  text-transform: none;
`;

export const FileNameContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 466px;
  align-items: center;
`;

export const FileNameField = styled(CommonFileNameField).attrs((props) => ({
  disabled: props.className === 'editDisabled',
  placeholder: 'Enter a board name',
}))`
  font-size: 13px;
  height: 40px;
  width: 100%;
  padding-left: 14px;
  border-radius: 5px;

  border: 2px solid #00000029;
  &:focus {
    border: 2px solid #279ae7;
    background-color: #f5f6f7;
  }

  &.editDisabled {
    border: 0px;
    background-color: #f5f6f7;
  }

  &.error {
    background: #fff;
    border-color: #df0b0b;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  width: 466px;

  > ${ButtonContainer} {
    margin-top: 0px;
  }

  span {
    font-size: 11px;
    font-weight: 500;
    margin-left: 8px;
  }

  ${NextButton} {
    font-weight: 600;
    font-size: 13px;
    width: 80px;
    height: 40px;
    max-width: 80px;
    min-width: 80px;
    margin: 0 0 0 8px;
    border-radius: 5px;

    &.faded {
      background-color: #279ae77a;
    }
  }

  ${CancelButton} {
    width: 80px;
    height: 40px;
    font-weight: 600;
    font-size: 13px;
    max-width: 80px;
    min-width: 80px;
    margin: 0px;
    border-radius: 5px;
  }
`;

export const EmailErrors = styled.div`
  position: absolute;
  top: -25;
  right: 0;
  color: #df0b0b;
  margin: 8px 0 16px 0;
  font-size: 11px;
  font-weight: 500;
  text-align: left;
`;

export const NameError = styled.div`
  position: absolute;
  top: -32;
  right: 0;
  color: #df0b0b;
  margin: 8px 0 16px 0;
  font-size: 11px;
  font-weight: 500;
  text-align: left;
`;

export const TouchModeButton = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  cursor: pointer;
  transform: translateX(-50%);
  font-size: 13px;
  padding: 7px 16px;
  border-radius: 16px;
  color: #2287ca;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16), 0 0 0 0.5px rgba(0, 0, 0, 0.08);
  text-align: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  animation: slide-in--touch-mode-button 500ms ease;
  @keyframes slide-in--touch-mode-button {
    0% {
      transform: translateX(-50%) translateY(100%);
    }
    100% {
      transform: translateX(-50%) translateY(0);
    }
  }
`;
