import React, { useEffect, useRef, useState } from 'react';
import * as browser from 'react-device-detect';
import { isMobileOnly } from 'react-device-detect';
import { Link, useHistory, useLocation } from 'react-router-dom';

import * as analytics from 'client/common/analytics';
import isValidBrowser from 'client/common/browserCheck';
import CookieBanner from 'client/components/Home/cookiebanner';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useLocalStorage from 'client/hooks/useLocalStorage';
import { useResetConnectionAndSession } from 'client/hooks/useResetConnectionAndSession';
import { useSelector } from 'client/hooks/useSelector';
import { makeRequest } from 'client/services/rest';
import { Tenant } from 'client/state/settings/tenant';

import MobileTopBar, { BackButton, MobilePrimaryButton } from './MobileTopBar';
import * as Styled from './styled';

const fetchInvite = async (collaborationServerUrl: string, tenant: Tenant, inviteId: string) => {
  const result = await makeRequest<{ email: string; dateAccepted: string | null; dateRevoked: string | null }>({
    method: 'GET',
    url: `${collaborationServerUrl}/tenant/${tenant.id}/invites/${inviteId}`,
  });
  return result.data;
};

const Login: React.FC = () => {
  const { hasAgreedToTermsAndPolicy, tenant, collaborationServerUrl } = useSelector((state) => ({
    hasAgreedToTermsAndPolicy: state.hasAgreedToTermsAndPolicy,
    tenant: state.tenant,
    collaborationServerUrl: state.collaborationServerUrl,
  }));

  const { agreeToTermsAndPolicy, setTenant } = useActions();

  const auth = useAuth();
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const name = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordHint, setPasswordHint] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [inTeams, setInTeams] = useState(false);
  const [validationResponse, setValidationResponse] = useState(null);
  const location = useLocation<{
    code?: string;
    sessionJoinKey?: string;
    boardLink?: string;
    from?: string;
  }>();
  const history = useHistory();
  const [, setIntendedRedirect] = useLocalStorage('intendedRedirect');
  const [fromSession, setFromSession] = useState(null);
  const [inviteId, setInviteId] = useState<string>(null);

  const resetConnectionAndSession = useResetConnectionAndSession();
  const availableSignInMethods = tenant?.signInMethods ?? { google: true, microsoft: false, email: true };

  useEffect(() => {
    if (!isValidBrowser) {
      history.replace('/unsupported-browser');
    } else {
      analytics.activateAndViewPage('/');
    }
  }, []);

  // Someone left the board or went from connected to here, let's reset
  useEffect(() => {
    resetConnectionAndSession();

    if (location.state?.from === 'join' || location.state?.from === 'session') {
      let url;
      if (location.state.boardLink) {
        url = `/boards/link/${location.state.boardLink}`;
      } else if (location.state.sessionJoinKey) {
        url = `/session/${location.state.sessionJoinKey}`;
      }
      setFromSession(url);
      setIntendedRedirect(url);
    } else if (location.state?.from === 'qrcode') {
      setIntendedRedirect(`/qrcode/${location.state.code}`);
    }

    const params = new URLSearchParams(window.location.search);
    const teamsParam = !!params.get('teams');
    setInTeams(teamsParam);
    if (teamsParam) {
      setIntendedRedirect('/vc/teams/authredirect');
    }

    const tenantId = params.get('tenantId');
    if (tenantId) {
      setTenant(tenantId);
    }
  }, []);

  useEffect(() => {
    if (auth.error) {
      console.log(auth.error);
      const { code, message } = auth.error;
      setValidationResponse({ code, message });
      auth.ackError();
    }
  }, [auth.error]);

  const handleSubmit = async () => {
    setValidationResponse(null);

    if (!showPassword && !showSignUp) {
      const { valid, signInMethods } = await auth.checkEmail(email.current?.value);

      if (valid) {
        if (signInMethods.length === 0) {
          setShowSignUp(true);
        } else if (signInMethods.includes('google.com')) {
          await auth.signInGoogle(inTeams);
        } else if (signInMethods.includes('microsoft.com')) {
          await auth.signInMicrosoft(inTeams);
        } else if (signInMethods.includes('password')) {
          setShowPassword(true);
        }
      }
    } else if (showPassword) {
      await auth.signIn(email.current?.value, password.current?.value);
    } else if (showSignUp) {
      if (inviteId) {
        await auth.acceptInvite(email.current?.value, password.current?.value, name.current?.value, inviteId);
      } else {
        await auth.signUp(email.current?.value, password.current?.value, name.current?.value);
      }
    }
  };

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (inviteId && tenant) {
      fetchInvite(collaborationServerUrl, tenant, inviteId)
        .then((i) => {
          if (i.dateAccepted || i.dateRevoked) {
            throw new Error('invite-not-found');
          } else {
            email.current.value = i.email;
            setShowSignUp(true);
          }
        })
        .catch(() => {
          setValidationResponse({
            message: 'This invite is not valid. Please contact your local administrator.',
            code: 'invite-not-found',
          });
          setInviteId(null);
        });
    }
  }, [collaborationServerUrl, inviteId, tenant]);

  useEffect(() => {
    if (query.has('google') && (!tenant || availableSignInMethods.google)) {
      query.delete('google');
      history.replace({ search: query.toString() });
      auth.signInGoogle(inTeams);
    }
    if (query.has('microsoft') && (!tenant || availableSignInMethods.microsoft)) {
      query.delete('microsoft');
      history.replace({ search: query.toString() });
      auth.signInMicrosoft(inTeams);
    } else if (query.has('email')) {
      email.current.value = query.get('email');
      query.delete('email');
      history.replace({ search: query.toString() });
      handleSubmit();
    } else if (query.has('invite')) {
      const id = query.get('invite');
      query.delete('invite');
      history.replace({ search: query.toString() });
      setInviteId(id);
    }
  }, []);

  useEffect(() => {
    if (auth.error && auth.error.code === 'auth/user-disabled') {
      history.push('/account-disabled');
    }
  }, [auth.error]);

  return (
    <>
      <Styled.ScrollArea>
        {isMobileOnly && (
          <MobileTopBar>
            {!showSignUp ? null : <BackButton onClick={() => setShowSignUp(false)} />}
            <MobilePrimaryButton>
              <a
                onClick={() => {
                  if (location.state?.from === 'qrcode') {
                    history.push(`/qrcode/${location.state.code}`);
                  } else {
                    history.push('/code');
                  }
                }}
              >
                Join Guest
              </a>
            </MobilePrimaryButton>
          </MobileTopBar>
        )}

        <Styled.LoginForm className={showSignUp ? 'sign-up' : ''}>
          {tenant && (
            <div
              style={{
                fontSize: '12px',
                marginBottom: '12px',
              }}
            >
              You are on the {tenant.displayName} tenant.
            </div>
          )}
          {!availableSignInMethods.email && (
            <h1>The IT department in your organisation has configured single sign on using your Microsoft account</h1>
          )}
          {availableSignInMethods.email && (
            <>
              <h1>
                Sign {showSignUp ? 'up' : 'in'} to get full experience of
                <br />
                FlatFrog Board
              </h1>

              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await handleSubmit();
                }}
              >
                {['auth/too-many-requests', 'auth/invite-required', 'invite-not-found'].includes(
                  validationResponse?.code
                ) && <div className="error">{validationResponse.message}</div>}
                <div style={{ display: showPassword ? 'none' : 'block' }}>
                  <label className={validationResponse?.code === 'auth/invalid-email' ? 'error' : ''}>
                    {validationResponse?.code === 'auth/invalid-email' ? 'Email Invalid' : 'Email'}
                  </label>
                  <input
                    type="text"
                    name="email"
                    ref={email}
                    id="signin-email-input"
                    disabled={showSignUp || showPassword}
                    autoFocus
                    onChange={() => {
                      if (validationResponse?.code === 'auth/invalid-email') {
                        setValidationResponse(null);
                      }
                    }}
                    className={validationResponse?.code === 'auth/invalid-email' ? 'error' : ''}
                  />
                </div>

                {showPassword && (
                  <>
                    <label className={validationResponse?.code === 'auth/wrong-password' ? 'error' : ''}>
                      {validationResponse?.code === 'auth/wrong-password' ? 'Invalid password' : 'Password'}
                      <Link to={{ pathname: '/reset-password', state: { email: email.current?.value } }}>
                        Forgot Password?
                      </Link>
                    </label>
                    <Styled.PasswordFieldWrapper>
                      <input
                        className={validationResponse?.code === 'auth/wrong-password' ? 'error' : ''}
                        type={passwordHint ? 'text' : 'password'}
                        name="password"
                        ref={password}
                        id="signin-password-input"
                        onChange={() => {
                          if (validationResponse?.code === 'auth/wrong-password') {
                            setValidationResponse(null);
                          }
                        }}
                        autoFocus
                      />
                      {!browser.isEdge && (
                        <Styled.PasswordHintToggle
                          className={passwordHint ? 'show' : ''}
                          id="signin-password-hint"
                          onMouseDown={() => {
                            setPasswordHint(true);
                          }}
                          onMouseUp={() => {
                            setPasswordHint(false);
                          }}
                        />
                      )}
                    </Styled.PasswordFieldWrapper>
                  </>
                )}

                {showSignUp && (
                  <>
                    <label className={validationResponse?.code === 'auth/no-name' ? 'error' : ''}>
                      First and last name
                    </label>
                    <input
                      className={validationResponse?.code === 'auth/no-name' ? 'error' : ''}
                      type="text"
                      name="name"
                      ref={name}
                      id="signup-name-input"
                      autoFocus
                    />
                    <label className={validationResponse?.code === 'auth/weak-password' ? 'error' : ''}>
                      {validationResponse?.code === 'auth/weak-password'
                        ? 'The password must be at least 6 characters'
                        : 'Choose password'}
                    </label>
                    <Styled.PasswordFieldWrapper>
                      <input
                        className={validationResponse?.code === 'auth/weak-password' ? 'error' : ''}
                        type={passwordHint ? 'text' : 'password'}
                        name="password"
                        ref={password}
                        onChange={() => {
                          if (validationResponse?.code === 'auth/weak-password') {
                            setValidationResponse(null);
                          }
                        }}
                        id="signup-password-input"
                      />
                      <Styled.PasswordHintToggle
                        className={passwordHint ? 'show' : ''}
                        id="signup-password-hint"
                        onMouseDown={() => {
                          setPasswordHint(true);
                        }}
                        onMouseUp={() => {
                          setPasswordHint(false);
                        }}
                      />
                    </Styled.PasswordFieldWrapper>
                  </>
                )}

                <button id="next-button" type="submit">
                  {showSignUp ? 'Sign Up' : showPassword ? 'Sign In' : 'Next'}
                </button>
                {!(showSignUp || showPassword) && (
                  <span className="sign-up-help">
                    No account yet? Enter{' '}
                    <span className="action" id="focus-email" onClick={() => email.current.focus()}>
                      your email
                    </span>{' '}
                    to sign up
                  </span>
                )}
                {showPassword && (
                  <span className="sign-up-help">
                    <span className="action" id="change-email" onClick={() => setShowPassword(false)}>
                      Change
                    </span>{' '}
                    {email.current?.value}
                  </span>
                )}
                {showSignUp && (
                  <span className="sign-up-help terms">
                    By signing up, you agree to the{' '}
                    <a target="_blank" href="/privacy-policy">
                      Privacy&nbsp;Policy
                    </a>{' '}
                    and{' '}
                    <a target="_blank" href="/terms-of-service">
                      Terms&nbsp;of&nbsp;Service
                    </a>
                  </span>
                )}
              </form>
            </>
          )}

          <Styled.SSO>
            {(availableSignInMethods.google || availableSignInMethods.microsoft) && (
              <>
                {availableSignInMethods.email && (
                  <span className="sso-help-text">Or sign {showSignUp ? 'up' : 'in'} with</span>
                )}
                {availableSignInMethods.google && (
                  <button
                    className="sso sso.google"
                    id="google-sso-button"
                    onClick={async () => {
                      await auth.signInGoogle(inTeams);
                    }}
                  >
                    <span className="google">Sign in with Google</span>
                  </button>
                )}
                {availableSignInMethods.microsoft && (
                  <button
                    className="sso sso.microsoft"
                    id="microsoft-sso-button"
                    onClick={async () => {
                      await auth.signInMicrosoft(inTeams);
                    }}
                  >
                    <span className="microsoft">Sign in with Microsoft</span>
                  </button>
                )}
              </>
            )}
            <span className="sign-up-help terms">
              By signing in, you agree to the{' '}
              <a target="_blank" href="/privacy-policy">
                Privacy&nbsp;Policy
              </a>{' '}
              and{' '}
              <a target="_blank" href="/terms-of-service">
                Terms&nbsp;of&nbsp;Service
              </a>
            </span>
          </Styled.SSO>
        </Styled.LoginForm>
      </Styled.ScrollArea>

      {!showSignUp && !fromSession && !inTeams && !isMobileOnly && (
        <Styled.LoginButton>
          <Styled.PromoLabel>
            {location.state?.from === 'qrcode' ? 'Join as guest' : 'I have a Board Code'}
          </Styled.PromoLabel>
          <a
            onClick={() => {
              if (location.state?.from === 'qrcode') {
                history.push(`/qrcode/${location.state.code}`);
              } else {
                history.push('/code');
              }
            }}
          >
            Join Board
          </a>
        </Styled.LoginButton>
      )}

      {!showSignUp && fromSession && (
        <Styled.LoginButton>
          <a
            onClick={() => {
              history.push({
                pathname: fromSession,
                state: {
                  backToSessionFromLogin: true,
                },
              });
            }}
          >
            Back to the board
          </a>
        </Styled.LoginButton>
      )}

      {showSignUp && (
        <Styled.LoginButton>
          <a
            onClick={() => {
              setShowSignUp(false);
            }}
          >
            Back
          </a>
        </Styled.LoginButton>
      )}
      <CookieBanner accepted={hasAgreedToTermsAndPolicy} accept={() => agreeToTermsAndPolicy(true)} />
    </>
  );
};

export default Login;
