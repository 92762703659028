import slugId from 'slugid';
import { createAction, createReducer } from '@reduxjs/toolkit';

type Notification = {
  id: string;
  content: string | JSX.Element;
  type: string;
  args?: string[];
  pluralize?: (args: string[]) => string;
  sticky?: boolean;
  action?: () => void;
  actionText?: string;
};

// The prio of notifications, if not present here it'll just be in back of the queue
const notificationPriorities = ['setPresenter', 'setForceFollow'];

const groupAdjacent = (a: Notification[] = []) =>
  a.reduceRight<Notification[][]>(
    ([group = [], ...result], notification) => {
      if (group.length > 0) {
        if (group[0].type === notification.type) {
          return [[notification, ...group], ...result];
        }
        return [[notification], group, ...result];
      }
      return [[notification], ...result];
    },
    [[]]
  );

const insertAndPluralizeNotifications = (notification: Notification, queue: Notification[]) => {
  const priority = notificationPriorities.findIndex((n) => n === notification.type);
  let newQueue;

  // if there is a priority, look the correct index to place it
  if (priority > -1) {
    let index = 1;

    for (let i = 1; i < queue.length; i++) {
      const currentPriority = notificationPriorities.findIndex((n) => n === queue[i].type);
      if (priority <= currentPriority && currentPriority > -1) {
        index++;
      }
    }

    const queueCopy = [...queue];
    queueCopy.splice(index, 0, notification);
    newQueue = queueCopy;
  } else {
    newQueue = [...queue, notification];
  }

  // Now we wanna squash em
  const groupedQueue = groupAdjacent(newQueue.slice(1));

  const flattenedQueue: Notification[] = [];
  groupedQueue.forEach((group) => {
    if (group.length > 1) {
      const [firstInGroup] = group;
      const { pluralize } = firstInGroup;

      if (pluralize) {
        const args = group.flatMap((n) => n.args);
        const plural = pluralize(args);
        flattenedQueue.push({
          id: slugId.v4(),
          args,
          content: plural,
          pluralize,
          type: firstInGroup.type,
        });
      } else {
        flattenedQueue.push(...group);
      }
    } else {
      flattenedQueue.push(...group);
    }
  });

  return [newQueue[0], ...flattenedQueue];
};

export const addNotification = createAction<Omit<Notification, 'id'>>('addNotification');
export const removeNotification = createAction<string>('removeNotification');
export const clearNotifications = createAction('clearNotifications');

export default createReducer<Notification[]>([], (builder) =>
  builder
    .addCase(addNotification, (state, { payload }) => {
      const notification = { id: slugId.v4(), ...payload };

      if (payload.sticky) {
        return [notification];
      }
      if (state.some((n) => n.sticky)) {
        return state;
      }
      return insertAndPluralizeNotifications(notification, state);
    })
    .addCase(removeNotification, (state, { payload }) => state.filter((n) => n.id !== payload))
    .addCase(clearNotifications, () => [])
);
