import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { ModalBackDrop, ModalContainer } from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import { getFirebaseAuth } from 'client/common/firebase';
import * as rest from 'client/services/rest';

import * as Styled from './styled';

// .split(/[\s,]+/)

const GIGA_EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

const EmailInput: React.FC<{ emailsChanged: (emails: string[]) => void }> = ({ emailsChanged }) => {
  const [inputValue, setInputValue] = useState('');
  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    emailsChanged(emails);
  }, [emails]);

  const isEmailValid = useCallback(
    (email) => {
      return GIGA_EMAIL_REGEX.test(email) && !emails.includes(email);
    },
    [emails]
  );

  const addIfValidEmail = useCallback(() => {
    const emailsToAdd = inputValue
      .split(/[\s,]+/)
      .map((email) => (isEmailValid(email) ? email : undefined))
      .filter((email) => email);
    setEmails((_emails) => [..._emails, ...emailsToAdd]);
    if (emailsToAdd.length > 0) {
      setInputValue('');
    }
  }, [emails, inputValue]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
        e.preventDefault();
        addIfValidEmail();
      }
    },
    [addIfValidEmail]
  );

  const onPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      const emailsToAdd = e.clipboardData
        .getData('text')
        .split(/[\s,]+/)
        .map((email) => (isEmailValid(email) ? email : undefined))
        .filter((email) => email);
      setEmails((_emails) => [..._emails, ...emailsToAdd]);
      return false;
    },
    [emails]
  );

  // const Input = (props) => <components.Input {...props} onPaste={onPaste} />;

  return (
    <CreatableSelect
      isClearable={false}
      isMulti
      components={{ DropdownIndicator: null }}
      getOptionLabel={(o) => o}
      getOptionValue={(o) => o}
      inputValue={inputValue}
      menuIsOpen={false}
      onBlur={() => {
        console.log('onBlur');
        addIfValidEmail();
      }}
      onChange={(v) => {
        console.log('onChange');
        setEmails(v as string[]);
      }}
      onInputChange={(v) => {
        console.log('onInputChange');
        setInputValue(v);
      }}
      onKeyDown={(e) => {
        console.log('onKeyDown');
        handleKeyDown(e);
      }}
      placeholder=""
      value={emails}
      styles={{
        valueContainer: (base) => ({ ...base, maxHeight: '100px', overflowY: 'auto' }),
        input: (base) => ({ ...base, '& input': { height: 'inherit' } }),
      }}
    />
  );
};

interface Props {
  onUsersInvited: () => void;
  onClose: () => void;
}

const InviteUsersDialog: React.FC<Props> = ({ onUsersInvited, onClose }) => {
  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    tenant: state.tenant,
    collaborationServerUrl: state.collaborationServerUrl,
  }));

  const { disableClipBoarding } = useActions();

  const [emails, setEmails] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(false);

  useHotkeys('esc', () => {
    onClose();
  });

  useEffect(() => {
    disableClipBoarding('InviteUsersDialog', true);
    return () => {
      disableClipBoarding('InviteUsersDialog', false);
    };
  }, []);

  const inviteUsers = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (emails.length === 0) {
        return;
      }

      try {
        setDisabled(true);
        const token = await getFirebaseAuth().currentUser.getIdToken();

        const response = await rest.makeRequest<boolean>({
          method: 'POST',
          url: `${collaborationServerUrl}/tenant/${tenant.id}/invites`,
          authorizationToken: { auth: token },
          data: { emails },
        });

        onUsersInvited();
      } catch (err) {
        console.error(err);
      } finally {
        setDisabled(false);
      }
    },
    [collaborationServerUrl, onUsersInvited, emails]
  );

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <ModalContainer>
        <Styled.DialogTitle>Invite Users</Styled.DialogTitle>
        <Styled.DialogForm onSubmit={inviteUsers}>
          <div>
            <Styled.InputGroup>
              <Styled.Label>Enter e-mail addresses</Styled.Label>
              <EmailInput emailsChanged={(e) => setEmails(e)} />
            </Styled.InputGroup>
          </div>
          <Styled.DialogButtonContainer>
            <Styled.Button onClick={() => onClose()} disabled={disabled}>
              Cancel
            </Styled.Button>
            <Styled.PrimaryButton type="submit" disabled={disabled}>
              Invite
            </Styled.PrimaryButton>
          </Styled.DialogButtonContainer>
        </Styled.DialogForm>
      </ModalContainer>
    </>,
    document.getElementById('modal-portal')
  );
};

export default InviteUsersDialog;
