import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { FileOrFileLink, isFile, isFileLink, QUERY_MY_BOARDS } from 'client/common/graphql';
import { CopyButton } from 'client/components/CollaborationSidePanel/styled';
import { CollapsiblePanel } from 'client/components/CollapsiblePanel';
import { useSelector } from 'client/hooks/useSelector';
import NameBubble from 'client/components/Common/NameBubble';
import { useAuth } from 'client/hooks/useAuth';
import { ContextMenu } from 'client/components/Common/ContextMenu';

import * as Styled from '../styled';

enum Filter {
  MyBoards = 'Only my Boards',
  Shared = 'Shared with me',
  All = 'All Boards',
}

const THUMBNAIL_PLACEHOLDER = '/images/ill_thumbnail_placeholder.svg';

type SortOrder =
  | 'SORTINGDATE_ASC'
  | 'SORTINGDATE_DESC'
  | 'LASTMODIFIED_ASC'
  | 'LASTMODIFIED_DESC'
  | 'FILENAME_ASC'
  | 'FILENAME_DESC';

interface Props {
  allowedToCreateFile: { allowed: boolean; limit?: number };
  deleteBoard: (f: FileOrFileLink) => void;
  duplicateBoard: (id: string) => void;
  removeLink: (f: FileOrFileLink) => void;
  renameBoard: (f: FileOrFileLink) => void;
  setSort: (sort: SortOrder) => void;
  showCastDialog: (e: React.MouseEvent, file: FileOrFileLink) => void;
  showMoveTo: (fileId: string) => void;
  showShareDialog: (fileId: string, fileName: string, showMove: boolean) => void;
  sort: SortOrder;
}

const MyBoards: React.FC<Props> = ({
  allowedToCreateFile,
  deleteBoard,
  duplicateBoard,
  removeLink,
  renameBoard,
  setSort,
  showCastDialog,
  showMoveTo,
  showShareDialog,
  sort,
}) => {
  const { featureFlags } = useSelector((state) => ({ featureFlags: state.featureFlags }));

  const [filter, setFilter] = useState(Filter.All);
  const location = useLocation();
  const history = useHistory();

  const query = useQuery(QUERY_MY_BOARDS, { variables: { sort, input: { sort } } });

  const files = query.loading ? [] : query.data?.files || [];

  const sharedWithMe = (!query.loading && query.data?.sharedWithMe) || [];
  const allFiles = (!query.loading && query.data?.allFiles) || [];

  const { user } = useAuth();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [filter, allowedToCreateFile.allowed, files.length]);

  let items;
  if (filter === Filter.MyBoards) {
    items = files.map((f) => (
      <Link
        to={{
          pathname: f.id !== 'load' ? `/dashboard/open/${f.id}` : '',
          state: {
            from: location.pathname,
          },
        }}
        key={f.id}
        id={`start-${f.id}`}
      >
        <Styled.MyBoardsItem>
          <Styled.MyBoardsBoardLabel>
            <Styled.MyBoardsBoardThumbnail
              style={{
                backgroundImage: `url(${f.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
              }}
            />
            {f.fileName || 'Unnamed Board'}
          </Styled.MyBoardsBoardLabel>
          <Styled.RecentBoardExpander />
          {/* TODO: This should be a <time datetime={f.dateModified}> */}
          <Styled.RecentBoardTime>{dayjs(f.dateModified).fromNow()}</Styled.RecentBoardTime>
          <Styled.ShareColumn>
            <Styled.ClickGuard onClick={(e) => e.preventDefault()}>
              <CopyButton
                style={{ margin: 'auto 0' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showShareDialog(f.id, f.fileName, true);
                }}
              >
                Share
              </CopyButton>
              <Styled.CastButton data-tip="Open in room" data-effect="solid" onClick={(e) => showCastDialog(e, f)} />
              <ContextMenu
                items={[
                  [
                    {
                      title: 'Move to a Workspace',
                      onClick: () => showMoveTo(f.id),
                      hidden: !featureFlags.enableTeams,
                    },
                    {
                      title: 'Duplicate',
                      onClick: () => duplicateBoard(f.id),
                      disabled: !allowedToCreateFile.allowed,
                    },
                    {
                      title: 'Rename',
                      onClick: () => renameBoard(f),
                    },
                  ],
                  [
                    {
                      onClick: () => deleteBoard(f),
                      title: 'Delete Board',
                    },
                  ],
                ]}
              >
                <Styled.MoreButton id={`menu-${f.id}`} />
              </ContextMenu>
            </Styled.ClickGuard>
          </Styled.ShareColumn>
        </Styled.MyBoardsItem>
      </Link>
    ));
  } else if (filter === Filter.Shared) {
    items = sharedWithMe.map((f) => (
      <Link
        to={{
          pathname: f.id !== 'load' ? `/dashboard/open/${f.file.id}` : '',
          state: {
            from: location.pathname,
          },
        }}
        key={f.id}
        id={`start-${f.id}`}
      >
        <Styled.MyBoardsItem>
          <Styled.MyBoardsBoardLabel>
            <Styled.MyBoardsBoardThumbnail
              style={{
                backgroundImage: `url(${f.file?.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
              }}
            />
            {f.file?.fileName}
            <Styled.SharedBoard
              data-tip={`Owner: ${f.file?.user?.displayName}${f.file?.user?.disabled ? ' (disabled)' : ''}`}
              data-effect="solid"
              data-delay-show="150"
            />
          </Styled.MyBoardsBoardLabel>
          <Styled.RecentBoardTime>{dayjs(f.dateAccessed).fromNow()}</Styled.RecentBoardTime>
          <Styled.ShareColumn>
            <Styled.ClickGuard onClick={(e) => e.preventDefault()}>
              <CopyButton
                style={{ margin: 'auto 0', visibility: f.file ? 'visible' : 'hidden' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (f.file) {
                    showShareDialog(f.file.id, f.file.fileName, false);
                  }
                }}
              >
                Share
              </CopyButton>
              <Styled.CastButton data-tip="Open in room" data-effect="solid" onClick={(e) => showCastDialog(e, f)} />
              <ContextMenu
                items={[
                  [
                    {
                      disabled: !allowedToCreateFile.allowed,
                      onClick: () => {
                        duplicateBoard(f.id);
                        setFilter(Filter.MyBoards);
                      },
                      title: 'Duplicate',
                    },
                    {
                      disabled: true,
                      onClick: () => {},
                      title: 'Rename',
                    },
                  ],
                  [
                    {
                      onClick: () => removeLink(f),
                      title: 'Remove Link',
                    },
                  ],
                ]}
              >
                <Styled.MoreButton id={`menu-${f.id}`} />
              </ContextMenu>
            </Styled.ClickGuard>
          </Styled.ShareColumn>
        </Styled.MyBoardsItem>
      </Link>
    ));
  } else {
    items = allFiles.map((f) => {
      const file = isFileLink(f) ? f.file : f;

      return (
        <Link
          to={{
            pathname: file.id !== 'load' ? `/dashboard/open/${file.id}` : '',
            state: {
              from: location.pathname,
            },
          }}
          key={file.id}
          id={`start-${file.id}`}
        >
          <Styled.MyBoardsItem>
            <Styled.MyBoardsBoardLabel>
              <Styled.MyBoardsBoardThumbnail
                style={{
                  backgroundImage: `url(${file.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
                }}
              />
              {file.fileName}
              {isFileLink(f) && (
                <Styled.SharedBoard
                  data-tip={`Owner: ${f.file?.user?.displayName}${f.file?.user?.disabled ? ' (disabled)' : ''}`}
                  data-effect="solid"
                  data-delay-show="150"
                />
              )}
            </Styled.MyBoardsBoardLabel>
            <Styled.RecentBoardTime>
              {dayjs(isFileLink(f) ? f.dateAccessed : file.dateModified).fromNow()}
            </Styled.RecentBoardTime>
            <Styled.ShareColumn>
              <Styled.ClickGuard onClick={(e) => e.preventDefault()}>
                <CopyButton
                  style={{
                    margin: 'auto 0',
                    visibility: file ? 'visible' : 'hidden',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (file.id) {
                      showShareDialog(file.id, file.fileName, isFile(f));
                    }
                  }}
                >
                  Share
                </CopyButton>
                <Styled.CastButton data-tip="Open in room" data-effect="solid" onClick={(e) => showCastDialog(e, f)} />
                <ContextMenu
                  items={
                    isFileLink(f)
                      ? [
                          [
                            {
                              disabled: !allowedToCreateFile.allowed,
                              onClick: () => duplicateBoard(f.id),
                              title: 'Duplicate',
                            },
                            {
                              disabled: true,
                              onClick: () => {},
                              title: 'Rename',
                            },
                          ],
                          [
                            {
                              onClick: () => removeLink(f),
                              title: 'Remove Link',
                            },
                          ],
                        ]
                      : [
                          [
                            {
                              hidden: !featureFlags.enableTeams,
                              title: 'Move to a Workspace',
                              onClick: () => showMoveTo(f.id),
                            },
                            {
                              disabled: !allowedToCreateFile.allowed,
                              onClick: () => duplicateBoard(f.id),
                              title: 'Duplicate',
                            },
                            {
                              onClick: () => renameBoard(f),
                              title: 'Rename',
                            },
                          ],
                          [
                            {
                              onClick: () => deleteBoard(f),
                              title: 'Delete Board',
                            },
                          ],
                        ]
                  }
                >
                  <Styled.MoreButton id={`menu-${file.id}`} />
                </ContextMenu>
              </Styled.ClickGuard>
            </Styled.ShareColumn>
          </Styled.MyBoardsItem>
        </Link>
      );
    });
  }

  const header = (
    <>
      <NameBubble displayName={user.displayName} />
      Personal
      <Styled.Filter>
        <ContextMenu
          items={[
            [
              {
                title: Filter.All,
                selected: filter === Filter.All,
                onClick: () => {
                  setFilter(Filter.All);
                  setSort(sort === 'LASTMODIFIED_ASC' ? 'SORTINGDATE_DESC' : 'LASTMODIFIED_ASC');
                },
              },
              {
                title: Filter.MyBoards,
                selected: filter === Filter.MyBoards,
                onClick: () => {
                  setFilter(Filter.MyBoards);
                  setSort(sort === 'SORTINGDATE_ASC' ? 'LASTMODIFIED_ASC' : 'LASTMODIFIED_DESC');
                },
              },
              {
                title: Filter.Shared,
                selected: filter === Filter.Shared,
                onClick: () => {
                  setFilter(Filter.Shared);
                  setSort(sort === 'SORTINGDATE_ASC' ? 'LASTMODIFIED_ASC' : 'LASTMODIFIED_DESC');
                },
              },
            ],
          ]}
        >
          <Styled.MyBoardsFilterButton>
            <span style={{ fontWeight: 'normal', marginRight: '5px' }}>Filter:</span>
            {filter}
          </Styled.MyBoardsFilterButton>
        </ContextMenu>
      </Styled.Filter>
    </>
  );

  return (
    <CollapsiblePanel heading={header}>
      <Styled.MyBoardsTable>
        {((!query.loading && allFiles.length > 0) || files.length > 0 || sharedWithMe.length > 0) && (
          <Styled.MyBoardsColumns>
            <Styled.MyBoardsColumnTitle>
              <span
                className={sort.endsWith('_ASC') ? 'up' : ''}
                id="filename-sort-toggle"
                onClick={() => setSort(sort === 'FILENAME_DESC' ? 'FILENAME_ASC' : 'FILENAME_DESC')}
              >
                Filename
              </span>
            </Styled.MyBoardsColumnTitle>
            <Styled.MyBoardsColumnDate>
              <span
                className={sort.endsWith('_ASC') ? 'up' : ''}
                id="date-sort-toggle"
                onClick={() => {
                  if (filter === Filter.All) {
                    setSort(sort === 'SORTINGDATE_ASC' ? 'SORTINGDATE_DESC' : 'SORTINGDATE_ASC');
                  } else {
                    setSort(sort === 'LASTMODIFIED_ASC' ? 'LASTMODIFIED_DESC' : 'LASTMODIFIED_ASC');
                  }
                }}
              >
                {(() => {
                  switch (filter) {
                    case Filter.All:
                      return 'Date accessed';
                    case Filter.Shared:
                      return 'Date accessed';
                    default:
                    case Filter.MyBoards:
                      return 'Date modified';
                  }
                })()}
              </span>
            </Styled.MyBoardsColumnDate>
            <Styled.MyBoardsColumnCopy>Share</Styled.MyBoardsColumnCopy>
          </Styled.MyBoardsColumns>
        )}
        {!query.loading && items.length > 0 && (
          <>
            <Styled.NewBoardRowWrapper
              className={!allowedToCreateFile.allowed && 'disabled'}
              data-tip={
                allowedToCreateFile.limit
                  ? `Maximum number (${allowedToCreateFile.limit}) of boards reached`
                  : 'Not allowed to create new board'
              }
              data-tip-disable={query.loading || allowedToCreateFile.allowed}
              data-place="top"
              data-delay-show={300}
              data-effect="solid"
            >
              <Link to={!allowedToCreateFile.allowed ? '#' : '/dashboard/my-boards/templates'}>
                <Styled.MyBoardsItem className="new-board">
                  <Styled.MyBoardsBoardLabel>
                    <Styled.MyBoardsBoardThumbnail />
                    New Board
                  </Styled.MyBoardsBoardLabel>
                </Styled.MyBoardsItem>
              </Link>
            </Styled.NewBoardRowWrapper>
            {items}
          </>
        )}
        {query.loading && <Styled.MyBoardsItem className="loading">Loading personal files&hellip;</Styled.MyBoardsItem>}
        {!query.loading && items.length === 0 && (
          <Styled.RecentBoardsEmpty>
            <Styled.RecentBoardsEmptyIllustration />
            <Styled.RecentBoardsEmptyTitle>
              {[Filter.All, Filter.MyBoards].includes(filter)
                ? 'You don’t have any Boards yet'
                : 'You don’t have any Shared Boards yet'}
            </Styled.RecentBoardsEmptyTitle>
            {[Filter.All, Filter.MyBoards].includes(filter) && (
              <Styled.RecentBoardsEmptyComment>
                Create a new board or upload from your computer
              </Styled.RecentBoardsEmptyComment>
            )}
            {[Filter.All, Filter.MyBoards].includes(filter) && (
              <Styled.EmptyBoardsButtons
                data-tip={
                  allowedToCreateFile.limit
                    ? `Maximum number (${allowedToCreateFile.limit}) of boards reached`
                    : 'Not allowed to create new board'
                }
                data-tip-disable={allowedToCreateFile.allowed}
                data-delay-show={300}
                data-effect="solid"
                data-place="top"
              >
                <Styled.EmptyBoardsButton
                  disabled={!allowedToCreateFile.allowed}
                  className={allowedToCreateFile.allowed && 'green'}
                  onClick={() => {
                    history.push('/dashboard/my-boards/templates');
                  }}
                >
                  <img src="/icons/icn_new_board.svg" alt="New Board" /> New Board
                </Styled.EmptyBoardsButton>
                <Styled.EmptyBoardsButton
                  disabled={!allowedToCreateFile.allowed}
                  className={allowedToCreateFile.allowed && 'blue'}
                  onClick={() => {
                    history.push('/dashboard/my-boards/upload');
                  }}
                >
                  <img src="/icons/icn_upload_file.svg" alt="Upload from desktop" /> Upload from computer
                </Styled.EmptyBoardsButton>
              </Styled.EmptyBoardsButtons>
            )}
          </Styled.RecentBoardsEmpty>
        )}
      </Styled.MyBoardsTable>
    </CollapsiblePanel>
  );
};

export default MyBoards;
