import styled from 'styled-components';

// TODO: Make more generic, this one is just copy pasted
export const FlyOutContainer = styled.div`
  position: absolute;
  bottom: -8px;
  left: 56px;
  width: 200px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  padding: 10px 0;
  border-radius: 6px;
  box-shadow: 0 4px 8px #00000026;
  font-size: 14px;
  text-align: left;
  color: #242424;
  cursor: initial;
  user-select: none;

  a {
    color: black;
  }

  &:after,
  &:before {
    right: 100%;
    top: calc(100% - 28px);
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #d2d2d2;
    border-width: 9px;
    margin-top: -9px;
  }
`;
