import styled, { css } from 'styled-components';

export const FloatingPanel = css`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 0.5px #00000014, 0 4px 8px #00000029;
  padding: 4px;
`;

export const MenuContainer = styled.div`
  position: absolute;
  ${FloatingPanel};
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transform: translateX(var(--translateIntoViewX)) translateY(var(--translateIntoViewY));
  transition: opacity 0.1s ease;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40px;
  transition: max-width 0.1s ease-out;
  &.expanded {
    max-width: 220px;
  }
  &.hideOverflow {
    overflow: hidden;
  }
`;

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 200ms ease;
  background-width: 40px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 40px;
  font-size: 13px;
  font-weight: 600;
  color: #242424;

  &.expanded {
    width: 220px;
    background-position: 4px center;
  }

  &:hover {
    background-color: #00000014;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: #fff;
    }
  }
`;

export const MenuItemIcon = styled.div`
  width: 40px;
  height: 40px;

  background-repeat: no-repeat;
  background-position: center center;
`;

export const MoreOptionsIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_menu.svg);
`;

export const EditIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_edit.svg);
`;

export const VoteUpIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_plus-one.svg);
`;

export const VoteDownIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_minus-one.svg);
`;

export const DeleteIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_trashcan.svg);
`;

export const SetAsBackgroundIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_background.svg);
`;

export const CreateLinkIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_link_focused.svg);
  &.active {
    background-image: url(/icons/icn_link_active.svg);
  }
`;

export const StickerIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_stickers.svg);
`;

export const ChangeLayerIcon = styled(MenuItemIcon)`
  &.background {
    background-image: url(/icons/icn_paper_lock_active.svg);
  }
  &.foreground {
    background-image: url(/icons/icn_paper_lock_inactive.svg);
  }
`;

export const DuplicateIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_duplicate.svg);
`;

export const CutIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_cut.svg);
`;

export const CopyIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_copy.svg);
`;

export const GridIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_grid.svg);
`;

export const StackIcon = styled(MenuItemIcon)`
  background-image: url(/icons/icn_paper_stack.svg);
`;

export const Shortcut = styled.div`
  position: absolute;
  right: 12px;
  font-size: 13px;
  font-weight: 600;
  color: #8c8c8c;
`;

export const CountItem = styled.div`
  position: absolute;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    background: #279ae7;
    min-width: 21px;
    height: 21px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10.5px;
    text-align: center;
  }
  &.collapsed {
    margin-left: 20px;
    margin-top: -20px;
  }
  &.expanded {
    right: 5px;
    > span {
      padding: 10px;
    }
  }
`;

export const DropDownMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 0;
  width: 170px;
  ${FloatingPanel}
`;

export const DropDownMenuPositioner = styled.div`
  position: absolute;
  left: -4px;
  display: none;
  top: calc(100% + 2px);
  z-index: 5;
`;

export const ColorItem = styled.div`
  width: 36px;
  height: 36px;
  background: var(--color);
  margin: 2px;
  border: 1px solid #00000019;
  border-radius: 2px;
  &:hover {
    background-color: var(--color);
  }
`;

export const ColorDropDownItem = styled(ColorItem)`
  background: var(--color) url(/icons/icn_dropdown_arrow_gray.svg) center center no-repeat;
  width: 30px;
  height: 30px;
  margin: 5px 5px 5px 5px;

  &.open {
    ${DropDownMenuPositioner} {
      display: block;
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #00000029;
  margin: 4px 0;
`;
