export function removeCode() {
  console.log('Should remove code');
  window.history.replaceState(null, '', '/');
}

export function setCode(code: string) {
  const pathBlocks = window.location.pathname.split('/');
  // Don't replace location with updated code if current location is based on session key
  if (!(pathBlocks.length === 3 && pathBlocks[1] === 'session')) {
    window.history.replaceState(
      {
        code,
      },
      '',
      `/code/${code}`
    );
  }
}

export function setSessionUrl(sessionToken: string) {
  window.history.replaceState(
    {
      sessionToken,
    },
    '',
    `/session/${sessionToken}`
  );
}
