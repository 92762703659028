// Note: When adding a new sticker or updating the existing one,
// make sure to update the imageName in this list
// (It's generated by bec from the image contents)
const STICKERS = [
  { category: 'emojis', imageName: 'oTXkCG3Mb-gfW7wY3WXCFspPzmc', fileName: 'sticker_12_emoji_thumbs_up.png' },
  { category: 'emojis', imageName: 'UBEDLKgJrKiybKhJMB-cSC6gtV8', fileName: 'sticker_12_emoji_thumbs_down.png' },
  { category: 'emojis', imageName: 'g0Sr4meUIYiKRFD-EOmWOEqnmAU', fileName: 'sticker_01_emoji_smiley.png' },
  { category: 'emojis', imageName: 'R-NT6FFGdMT47VdYWay2fiEu85c', fileName: 'sticker_02_emoji_worried.png' },
  { category: 'emojis', imageName: '5cYmfXae34UwKlsdRLts_YRJ39o', fileName: 'sticker_03_emoji_concern.png' },
  { category: 'emojis', imageName: 'Xuc6onKSKwigivI9B87pwB_BmTE', fileName: 'sticker_04_emoji_hearts.png' },
  { category: 'emojis', imageName: 'nIfXti081hLQSOvJDC9KT_1eVug', fileName: 'sticker_05_emoji_sick.png' },
  { category: 'emojis', imageName: '8LLYe0NRu-6LBN_2JvGWxwDhmKo', fileName: 'sticker_06_emoji_holiday.png' },
  { category: 'emojis', imageName: 'Z4eqKP1qPiWRkxaJkdvn9pGmUDw', fileName: 'sticker_07_WFH.png' },
  { category: 'emojis', imageName: 'TyttvPijzjVXu4ynO1fHAIlZ67Y', fileName: 'sticker_08_emoji_poop.png' },
  { category: 'emojis', imageName: 'fnwlRkJ-8QQtj4RBf69S_3U9dmM', fileName: 'sticker_09_emoji_alien.png' },
  { category: 'emojis', imageName: '_Z8h84xDJx3ECtUpNbvwiahdNGo', fileName: 'sticker_10_emoji_ghost.png' },
  { category: 'emojis', imageName: 'xaOaqWU6nM6KcAbJKDAXDxgwMZk', fileName: 'sticker_11_emoji_star.png' },
  { category: 'emojis', imageName: 'dUf6EGlGk_5JaDex9PYcTxIKo-4', fileName: 'sticker_13_emoji_money_bag.png' },
  { category: 'emojis', imageName: 'h65o2DGsKvfd4ZQsn0YIw8_Ndrc', fileName: 'sticker_14_ok.png' },
  { category: 'emojis', imageName: 'r5MIPteACp4VnZA49X9biUnofbk', fileName: 'sticker_15_error.png' },
  { category: 'emojis', imageName: '-lOFa1LyBLrXCKUVC112i8VJoM4', fileName: 'sticker_16_stop.png' },
  { category: 'emojis', imageName: 'bISqObg_EdZ7T5ZM8ERO9TEjrpg', fileName: 'sticker_17_warning_triangle.png' },
  { category: 'emojis', imageName: '6v13Bshr8Z4p0kHb1puRU3waQVQ', fileName: 'sticker_18_warning_fire.png' },
  { category: 'emojis', imageName: 'sa9DqoaUst5MTtiacw3jmdh_9Y8', fileName: 'sticker_19_information.png' },
  { category: 'emojis', imageName: 'n7S_qEJXBDrV3NBQSTqnm2PkU_0', fileName: 'sticker_20_question.png' },
  { category: 'emojis', imageName: 'WwPYBBgW07Pa_XUmh5QiTnmgOhw', fileName: 'sticker_21_pause.png' },
  { category: 'emojis', imageName: 'X15bNi0usXLMv9JcIkDEkIrFhq4', fileName: 'sticker_22_play.png' },
  { category: 'emojis', imageName: 'bMod6vpZYs5bMykaNjxhirewxCc', fileName: 'sticker_23_construction.png' },
  { category: 'emojis', imageName: 'oQxD6itRdmJxt1LPJMUimQtS6jg', fileName: 'sticker_24_nuclear.png' },
  { category: 'emojis', imageName: '9LjVUSyoLnx-4T72Wgmbc4RRPyA', fileName: 'sticker_25_light_red.png' },
  { category: 'emojis', imageName: 'k0pOTqFE4WkfqkVmyPyipbohTzc', fileName: 'sticker_26_light_yellow.png' },
  { category: 'emojis', imageName: 'lK2NqYiCkQy3Lm7G1rU4sGNCDlU', fileName: 'sticker_27_light_green.png' },
  { category: 'emojis', imageName: 'fFRjVmiggQYVXtEA-5YAopL_R9c', fileName: 'sticker_28_arrow_red.png' },
  { category: 'emojis', imageName: 'NGleulU5NjbEBDQ0SVPQMu2gu1o', fileName: 'sticker_29_arrow_yellow.png' },
  { category: 'emojis', imageName: 'G8xRTlEu0UXpUu_L3rgQc4dMS5c', fileName: 'sticker_30_arrow_green.png' },
  { category: 'emojis', imageName: '5UrADiY0kMO0ofOkybM1tzK6eOM', fileName: 'sticker_31_flag_red.png' },
  { category: 'emojis', imageName: 'J45Q14LxrhFxXf5aVEZrDOXGKrQ', fileName: 'sticker_32_corona.png' },
  { category: 'emojis', imageName: 'BDKiGY8sUTe0Si2E6isrZgbhOss', fileName: 'sticker_33_factory.png' },
  { category: 'emojis', imageName: 'xhuOzm1-hGXtWPzWDVu-bhXRbB8', fileName: 'sticker_34_attention.png' },
  { category: 'emojis', imageName: 'Rdl-M_aT9elfvbUiHbVOB83aEcY', fileName: 'sticker_35_cog_wheel.png' },
  { category: 'emojis', imageName: 'CUCmsLjI-7oaTNWdUqKa_f2uB-M', fileName: 'sticker_36_wrench.png' },
  { category: 'emojis', imageName: '8uawAcqu9lFE-rZY9FfTX825S8c', fileName: 'sticker_37_bug_red.png' },
  { category: 'emojis', imageName: 'snaINcMthmp6izEwl232kHex5Mg', fileName: 'sticker_38_bug_blue.png' },
  { category: 'emojis', imageName: 'dFMMtz-ZJrJL0XLMMKqlyM7JtUE', fileName: 'sticker_39_bug_green.png' },
  { category: 'emojis', imageName: 'clwAdFsdSs_ZzxpGVekSag19cXQ', fileName: 'sticker_40_hourglass.png' },
  { category: 'emojis', imageName: 'xTQG3aJgkeE3gDAjKaXgdNxM_bw', fileName: 'sticker_41_earth.png' },
  { category: 'emojis', imageName: 'cD28nViDvO0QD0A2TAw5GIj87Xw', fileName: 'sticker_42_pinpoint.png' },
  { category: 'emojis', imageName: 'iNH147WUO8O-BHoIFt0sUNRTzwY', fileName: 'sticker_43_airplane.png' },
  { category: 'emojis', imageName: '3Z3Tu2xVC_QPKTQsEqZ-jUiyu6Y', fileName: 'sticker_44_rocket.png' },
  { category: 'emojis', imageName: 'RhwXglY6ZCAK2-j30Obd_Oe6gH0', fileName: 'sticker_45_light_bulb.png' },
  { category: 'emojis', imageName: 'N173dUg3Ss5H0t3KnoevvxZxgHc', fileName: 'sticker_46_durian.png' },
  { category: 'magnets', imageName: '-lOXP6ZC45T6z1MX0d7D2q7RxxU', fileName: 'sticker_47_1.png' },
  { category: 'magnets', imageName: '75IsJX69TRXMn1ZrdAEA5A3rYuc', fileName: 'sticker_48_2.png' },
  { category: 'magnets', imageName: 'B-kMi68PMEn_ncEQA1leH9jYH4k', fileName: 'sticker_49_3.png' },
  { category: 'magnets', imageName: 'JUxtG3V8mDYIadOOX3E7j14jnAM', fileName: 'sticker_50_4.png' },
  { category: 'magnets', imageName: 'mpwiY_7rZMIBgr98j7W7axfVqxQ', fileName: 'sticker_51_5.png' },
  { category: 'magnets', imageName: 'GT2Qz0HPop1O3hNtXgbVTZAsPZI', fileName: 'sticker_52_6.png' },
  { category: 'magnets', imageName: 'wH8d7_tX4NOQXZRbCsw_aie7Qow', fileName: 'sticker_53_7.png' },
  { category: 'magnets', imageName: 'xR2NjfVhXyqQFqP1APvjpzESGkA', fileName: 'sticker_54_8.png' },
  { category: 'magnets', imageName: 'gK20IKR7ik0YGbu54dJuVmhzwfg', fileName: 'sticker_55_9.png' },
  { category: 'magnets', imageName: 'Z8TbMpNZsg0MdYvjuMEsbDE-Hcc', fileName: 'sticker_56_A.png' },
  { category: 'magnets', imageName: 'p9Z6wgLxumdyEWGC7lgy4Aco51U', fileName: 'sticker_57_B.png' },
  { category: 'magnets', imageName: 'RsTMX50BlJSItJuYy-FjeuXnjwg', fileName: 'sticker_58_C.png' },
  { category: 'magnets', imageName: 'K4F63VZJ73kPotSqJj7Vz5wxnu8', fileName: 'sticker_59_D.png' },
  { category: 'magnets', imageName: '1LiGxN7-ZN0DlXC_gYKLliEbR7k', fileName: 'sticker_60_E.png' },
  { category: 'magnets', imageName: '1VwpRa5XacbQFQagV0T2Ihnelfs', fileName: 'sticker_61_F.png' },
  { category: 'magnets', imageName: 'amtsjZLAZVV2Iyq-YgKPhpy2DXQ', fileName: 'sticker_62_G.png' },
  { category: 'magnets', imageName: 'Q323Fta93DJomp82dWzaUVWfYg0', fileName: 'sticker_63_H.png' },
  { category: 'magnets', imageName: 's5wGmVwxP3G7tyqexmVwVBI0rpo', fileName: 'sticker_64_I.png' },
  { category: 'magnets', imageName: 'eQWeVGRwbUdvlQsIbRJHYtQrODA', fileName: 'sticker_65_magnet_green.png' },
  { category: 'magnets', imageName: 'o9cXA8bAYy2HD1KG8IbWeFFyg8E', fileName: 'sticker_66_magnet_blue.png' },
  { category: 'magnets', imageName: 'Kae0qXvwISpookDcvP60GUOkFYg', fileName: 'sticker_67_magnet_yellow.png' },
  { category: 'magnets', imageName: 'XmCmUgUyzydcgRyJdzw1GljeLmc', fileName: 'sticker_68_magnet_red.png' },
  { category: 'magnets', imageName: 'RKLPPTxT80XTr9TSN-wxZzy7K2M', fileName: 'sticker_69_magnet_turqoise.png' },
  { category: 'magnets', imageName: '9_Juk_wQwDp_fOUVIIijEoxDoRo', fileName: 'sticker_70_magnet_orange.png' },
  { category: 'magnets', imageName: 'SFE_4XFiOrevSNAS7Zg31upwkvE', fileName: 'sticker_71_magnet_magenta.png' },
  { category: 'magnets', imageName: 'Xp2x_SHWnm7ttSAYxfhJ5PdvQ9o', fileName: 'sticker_72_magnet_black.png' },
  { category: 'magnets', imageName: 'A_F3Pm74MBmbGbiZeT8gJxMxT2w', fileName: 'sticker_73_magnet_pink.png' },
  { category: 'magnets', imageName: '3R0APdU5RiexfK3seXO50iwKL_M', fileName: 'sticker_74_white_circle.png' },
  { category: 'magnets', imageName: 'L471zDoULYLx1zZH8jBZnJMaY0g', fileName: 'sticker_75_white_rectangle.png' },
  { category: 'magnets', imageName: 'lombdkti5aP1v0lQsm2CIunuy-E', fileName: 'sticker_76_white_octagon.png' },
];

export default STICKERS;
