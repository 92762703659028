import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

// When true, mixpanel tracking starts at Activation or later rather than Acquisition
// Acquistion = app is loaded
// Activation = user signs in OR user enters valid board code OR user joins valid session link
// Retention (no extra action)
// Referral = user copies link
// Revenue = user clicks upgrade plan
const MIXPANEL_AFTER_ACTIVATION = true;
let mixpanelActive = false;
let testTracking: (o: unknown) => void | undefined;
const actionOccurrences = new Map<string, number>();
const CONSOLE_LOG_TRACKING_MAGIC = 'TestAnalytics: ';
let isTestEnv: boolean;

let integrationContext: string | undefined;

function initMixpanel() {
  isTestEnv = window.INITIAL_STATE.gcpEnv === 'test';
  if (!isTestEnv) {
    mixpanel.init(
      window.INITIAL_STATE.gcpEnv === 'production'
        ? '2da61402bcf1cf77685630a185944eff'
        : '1f9a874db4360dab22d95e4374b1b071',
      {
        api_host: 'https://api-eu.mixpanel.com',
        property_blacklist: ['$current_url'],
      },
      ''
    );
    mixpanel.set_group('SessionId', 'NONE');
    mixpanel.set_group('BoardFileId', 'NONE');
    mixpanel.set_group('WorkspaceId', 'NONE');
    mixpanelActive = true;
  } else {
    // On test we test tracking rather than send the data to mixpanel (which would drive cost)
    // Testing is done by logging to the console
    console.log('Analytics will track to console.log');
    testTracking = (o) => console.log(CONSOLE_LOG_TRACKING_MAGIC + JSON.stringify(o));
    testTracking({ group: 'SessionId', value: 'NONE' });
    testTracking({ group: 'BoardFileId', value: 'NONE' });
    testTracking({ group: 'WorkspaceId', value: 'NONE' });
  }
}

function track(event: string, more?: Record<string, unknown>) {
  if (mixpanelActive) {
    const _more = { ...more };
    if (integrationContext) {
      _more.IntegrationContext = integrationContext;
    }
    mixpanel.track(event, _more);
  } else if (testTracking) {
    testTracking({ event, more });
  }
}

function activateAndTrack(event: string, more?: Record<string, unknown>) {
  if (!mixpanelActive) {
    initMixpanel();
  }
  track(event, more);
}

// Activation events
export function signIn(uid: string, email: string) {
  activateAndTrack('SignIn');
  const person = {
    $email: email,
    USER_ID: uid,
    PlanType: 'Free',
  };
  if (mixpanelActive) {
    mixpanel.identify(uid);
    mixpanel.people.set(person);
  } else if (testTracking) {
    testTracking(person);
  }
  TagManager.dataLayer({
    dataLayer: {
      event: 'SignIn',
      email,
    },
  });
}

export function selectPreferInviteAtCreate(invite: boolean) {
  if (mixpanelActive) {
    mixpanel.people.set({
      InviteAtCreate: invite,
    });
  }
}

export function selectUserRole(role: string) {
  const userRole = { UserRole: role };
  if (mixpanelActive) {
    mixpanel.people.set(userRole);
  } else if (testTracking) {
    testTracking(userRole);
  }
}

export function becType(type: string) {
  activateAndTrack('BecType', { Type: type });
}

export function roomFirstRun() {
  activateAndTrack('RoomFirstRun');
}

export function appType(type: string) {
  activateAndTrack('AppType', { Type: type });
}

export function validCode() {
  activateAndTrack('UseValidCode');
}

export function validSession(signedIn: boolean) {
  activateAndTrack('JoinValidSession', { SignedIn: signedIn });
}

export function validBoardLink() {
  activateAndTrack('UseValidBoardLink');
}

export function startNewBoard(id?: string) {
  activateAndTrack('StartNewBoard', { board: id ?? 'BLANK' });
}

// Retention events
export function openBoard() {
  activateAndTrack('OpenBoard');
}

export function uploadBoard() {
  activateAndTrack('UploadBoard');
}

// Referral events
export function copyBoardLink() {
  activateAndTrack('CopyBoardLink');
}

export function highlightBoardCode() {
  activateAndTrack('HighlightBoardCode');
}

export function sendBoardInviteEmail(count: number) {
  activateAndTrack('SendBoardInviteEmail', { Count: count });
}

// Revenue events
export function clickUpgrade() {
  activateAndTrack('ClickUpgrade');
}

// Other session events
async function getHash(txt: string) {
  const msgUint8 = new TextEncoder().encode(txt);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

// Set the value of a mixpanel group to the hash of the value (for privacy)
async function trackGroupHash(group: string, value: string) {
  if (value) {
    const hash = await getHash(value);
    if (mixpanelActive) {
      mixpanel.set_group(group, hash);
      mixpanel.get_group(group, hash).set({ $name: hash });
    } else if (testTracking) {
      testTracking({ group, value: hash });
    }
  } else if (mixpanelActive) {
    mixpanel.set_group(group, 'NONE');
  } else if (testTracking) {
    testTracking({ group, value: 'NONE' });
  }
}

export async function setSession(id: string) {
  trackGroupHash('SessionId', id);
}

export function setIntegrationContext(ctx: string) {
  integrationContext = ctx;
}

export async function setWorkspace(id: string) {
  trackGroupHash('WorkspaceId', id);
}

let boardLoadingStarted = false;
let boardLoadingId: string | false = false;
export async function setBoardId(id: string) {
  if (boardLoadingStarted) {
    boardLoadingId = id;
  } else {
    trackGroupHash('BoardFileId', id);
  }
}
export function startBoardLoading() {
  if ('performance' in window) {
    performance.clearMarks();
    performance.clearMeasures();
    performance.mark('start');
    boardLoadingStarted = true;
  }
}
export function markBoardLoading(mark: string) {
  if (boardLoadingStarted) {
    performance.mark(mark);
  }
}
export function endBoardLoading() {
  if (boardLoadingStarted) {
    if (boardLoadingId) {
      trackGroupHash('BoardFileId', boardLoadingId);
      boardLoadingId = false;
    }
    try {
      performance.mark('done');
      const marks = performance.getEntriesByType('mark');
      for (let i = 0; i < marks.length - 1; i += 1) {
        performance.measure(`${marks[i].name}-${marks[i + 1].name}`, marks[i].name, marks[i + 1].name);
      }
      performance.measure('boardloading', 'start', 'done');
      const measures = performance.getEntriesByType('measure');
      const boardloading = measures.reduce((a, v) => ({ ...a, [v.name]: Math.round(v.duration) }), {});
      performance.clearMarks();
      performance.clearMeasures();

      // Add some other useful data, move if too ugly to have it here
      const canvas = document.getElementById('zcanvas') as HTMLCanvasElement;
      const canvasInfo: { width?: number; height?: number } = {};

      if (canvas) {
        canvasInfo.width = canvas.width;
        canvasInfo.height = canvas.height;
      }

      if (window.INITIAL_STATE.env === 'development') {
        console.log('Board loading', { ...boardloading, canvasInfo });
      }
      track('BoardLoading', { ...boardloading, canvasInfo });
    } catch (e) {
      console.log(e);
    }
  }
  boardLoadingStarted = false;
}

export function attendees(count: number) {
  activateAndTrack('Attendees', { Count: count });
}

// General events (only if mixpanel is activated)
export function signOut() {
  track('SignOut');
}

export function invalidCode() {
  track('UseInvalidCode');
}

export function importFailed(type: string, size: number) {
  track('ImportFailed', { Type: type, Size: size });
}

export function importUnsupportedFile(type: string) {
  track('ImportUnsupportedFile', { Type: type });
}

export function importFile(type: string, method: string, pages: number, size: number) {
  track('ImportFile', {
    Type: type,
    Method: method,
    Pages: pages,
    Size: size,
  });
}

function reportView(page: string) {
  track(page);
  TagManager.dataLayer({
    dataLayer: {
      event: 'PageView',
      page,
    },
  });
}

let lastPage: string | null = null;
export function viewPage(p: string) {
  lastPage = p;
  reportView(p);
}

export function activateAndViewPage(p: string) {
  if (!mixpanelActive) {
    initMixpanel();
  }
  viewPage(p);
}

export function viewPanel(p: string) {
  reportView(`${lastPage}${p}`);
}

// UX events

// Save items
export function saveStickyNote() {
  track('SaveStickyNote');
}
export function saveTextBlock() {
  track('SaveTextBlock');
}
export function duplicateFromMyItems(count: number) {
  track('DuplicateMyItems', { Count: count });
}

// Add items
export function addStickyNote() {
  track('AddStickyNote');
}
export function addTextBlock() {
  track('AddTextBlock');
}
export function addSticker(id: string) {
  track('AddSticker', { Id: id });
}
export function addImage(method: string) {
  track('AddImage', { Method: method });
}
export function addFromMyItems(count: number) {
  track('AddMyItems', { Count: count });
}

// Delete items
export function deleteFromEditor() {
  track('DeleteEditor');
}
export function deleteFromMenu(count: number) {
  track('DeleteItems', { Count: count });
}
export function deleteFromMyItems(count: number) {
  track('DeleteMyItems', { Count: count });
}

// Lock items
export function lockItems(count: number) {
  track('LockItems', { Count: count });
}
export function unlockItems(count: number) {
  track('UnlockItems', { Count: count });
}

// Paper Links
export function createItemWithLink() {
  track('CreateItemWithLink');
}
export function createItemWithoutLink() {
  track('CreateItemWithoutLink');
}
export function addLinkToItem() {
  track('AddLinkToItem');
}
export function editItemLink() {
  track('EditItemLink');
}
export function removeLinkFromItem() {
  track('RemoveLinkFromItem');
}
export function clickItemLink() {
  track('ClickItemLink');
}

// Page management
export function addPage(props: { id?: string; fromPageGrid: boolean }) {
  track('AddPage', { board: props.id ?? 'BLANK', Where: props.fromPageGrid ? 'PageGrid' : 'PageMenu' });
}
export function deletePage(fromPageGrid: boolean) {
  track('DeletePage', { Where: fromPageGrid ? 'PageGrid' : 'PageMenu' });
}
export function reorderPages(modeOn: boolean) {
  track('ReorderPages', { Mode: modeOn ? 'On' : 'Off' });
}
export function duplicatePage(fromPageGrid: boolean) {
  track('DuplicatePage', { Where: fromPageGrid ? 'PageGrid' : 'PageMenu' });
}

// Backgrounds
export function setBackground(fromPaperMenu: boolean) {
  track('SetBackground', { Where: fromPaperMenu ? 'PaperMenu' : 'PageMenu' });
}
export function replaceBackground() {
  track('ReplaceBackground');
}
export function removeBackground() {
  track('RemoveBackground');
}

// Zoom
export function zoomIn() {
  track('ZoomIn');
}
export function zoomOut() {
  track('ZoomOut');
}
export function zoomReset() {
  track('ZoomReset');
}

// Presentation
export function presentOn() {
  track('PresentOn');
}
export function presentOff() {
  track('PresentOff');
}
export function presentLockOn() {
  track('PresentLockOn');
}
export function presentLockOff() {
  track('PresentLockOff');
}
export function jumpToPresenter() {
  track('JumpToPresenter');
}

export function signUp() {
  activateAndTrack('SignUp');
  TagManager.dataLayer({
    dataLayer: {
      event: 'SignUp',
    },
  });
}

// Board manipulation
export function pointerPing() {
  track('PointerPing');
}

function trackAction(action: string) {
  actionOccurrences.set(action, (actionOccurrences.get(action) ?? 0) + 1);
}

export function boardEvent(action: { Action?: string; Actions?: { Action?: string }[] }) {
  if (action.Action) {
    if (action.Action === 'CombinedAction' && action.Actions) {
      action.Actions.forEach((a) => {
        if (a.Action) {
          if (isTestEnv) {
            track('BoardEvent', { action: a.Action });
          } else {
            trackAction(a.Action);
          }
        }
      });
    } else if (isTestEnv) {
      track('BoardEvent', { action: action.Action });
    } else {
      trackAction(action.Action);
    }
  }
}

// Undo/Redo
export function undo() {
  track('Undo');
}
export function redo() {
  track('Redo');
}

// Tools
export function enableActivePen() {
  track('EnableActivePen');
}
export function disableActivePen() {
  track('DisableActivePen');
}
export function searched() {
  track('SearchResult');
}

// Teams
export function createTeam(invites: number) {
  activateAndTrack('CreateTeam', { Invites: invites });
}

export function inviteToTeam(invites: number) {
  activateAndTrack('InviteToTeam', { Invites: invites });
}

export function moveFile(toTeam: boolean) {
  activateAndTrack('MoveFile', { To: toTeam ? 'ToTeam' : 'ToPersonal' });
}

export function leaveTeam() {
  activateAndTrack('LeaveTeam');
}

export function removeUserFromTeam() {
  activateAndTrack('RemoveUserTeam');
}

export function initializeAnalytics() {
  const tagManagerArgs = {
    gtmId: 'GTM-N3DSLDJ',
    auth: window.INITIAL_STATE.gcpEnv === 'production' ? '0ADxhOtlWnm3sAK4ubSQmA' : 'ZGAlexc_GO5syk94fduh3g',
    preview: window.INITIAL_STATE.gcpEnv === 'production' ? 'env-1' : 'env-30',
  };
  TagManager.initialize(tagManagerArgs);

  // Wait with mixpanel tracking until user has activated?
  if (!MIXPANEL_AFTER_ACTIVATION) {
    initMixpanel();
  }
}

export function whiteboardCreated() {
  actionOccurrences.clear();
}
export function whiteboardDestroyed() {
  for (const [action, count] of actionOccurrences) {
    track('BoardEvent', { action, count });
  }
}
