import React from 'react';
import styled from 'styled-components';

import * as analytics from 'client/common/analytics';
import isValidBrowser from 'client/common/browserCheck';
import * as Modal from 'client/components/Common/modal-styled';

const BrowserBlockModal: React.FC = () => {
  if (isValidBrowser) {
    return null;
  }
  analytics.viewPage('/unsupported-browser');

  const ModalContainer = styled(Modal.BaseModalContainer)`
    color: #242424;
    padding: 40px;

    h3 {
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
      padding: 0;
    }

    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  `;

  const Logo = styled.img.attrs({
    src: '/images/logo_flatfrogboard.svg',
  })`
    position: absolute;
    top: 16px;
    left: 16px;
    width: 80px;
  `;

  const BrowserList = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 32px;
  `;

  const Browser = styled.a`
    width: 100px;
    color: rgba(93, 93, 93, 0.6);
    text-align: center;
    font-size: 14px;
    border-radius: 8px;
    border-width: 16px;
    margin-right: 8px;
    text-decoration: none;
    padding-bottom: 16px;
    padding-top: 16px;

    &:last-child {
      margin-right: 0px;
    }

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 8px;
    }

    &:hover {
      background-color: #f4f4f4;
    }
  `;

  return (
    <>
      <Modal.ModalBackDrop className="active" />
      <ModalContainer className="active">
        <Logo />
        <h3>Unsupported Browser</h3>
        <p>Use one of the following browsers:</p>
        <BrowserList>
          <Browser href="https://www.google.com/chrome/" target="_blank">
            <img src="/images/Chrome_browser@2x.png" height={64} />
            Chrome
          </Browser>
          <Browser href="https://www.mozilla.org/en-US/firefox/browsers/" target="_blank">
            <img src="/images/Firefox_browser@2x.png" height={64} />
            Firefox
          </Browser>
          <Browser href="https://www.microsoft.com/en-US/edge" target="_blank">
            <img src="/images/Edge_browser@2x.png" height={64} />
            New Edge
          </Browser>
          <Browser href="https://www.opera.com/download" target="_blank">
            <img src="/images/Opera_browser@2x.png" height={64} />
            Opera
          </Browser>
        </BrowserList>
      </ModalContainer>
    </>
  );
};

export default BrowserBlockModal;
