import styled from 'styled-components';

import * as T from '../templates/styled-template-gallery';

export const Container = styled(T.GalleryContainer)`
  padding: 40px;
  width: 700px;
  min-height: 500px;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DropArea = styled.div`
  border: 2px dashed #00000029;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  > div {
    text-align: center;
  }

  h1 {
    color: #242424;
    padding: 0;
    margin: 24px 0 8px 0;
    font-size: 18px;
    text-transform: none;
  }

  span {
    font-size: 13px;

    strong {
      color: #2287ca;
      font-weight: normal;
      cursor: pointer;
    }
  }
`;

export const ProgressBars = styled.div`
  min-height: 0; // This is important for all items begin visible when scrolling for some reason...
`;

export const ProgressArea = styled(DropArea)`
  border-color: transparent;
  text-align: center;
  overflow: auto;
  flex-grow: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProgressFileName = styled.span`
  color: #8c8c8c;
  font-size: 13px;
`;

export const Progress = styled.div`
  background: #2287ca;
  height: 8px;
  border-radius: 8px;
  transition: width 100ms ease-in-out;
`;

export const ProgressBar = styled.div`
  border: 2px solid #242424;
  width: 240px;
  height: 16px;
  border-radius: 8px;
  margin: 24px auto 8px auto;
  display: flex;
  align-items: center;
  padding: 0 2px;

  &.failed {
    border-color: #df0b0b;

    ${Progress} {
      background: #dd5b5b;
    }

    & + ${ProgressFileName} {
      color: #df0b0b;
    }
  }
`;

export const CloseButton = styled(T.CloseButton)``;

export const CancelButton = styled.div`
  display: flex;
  font-size: 13px;
  color: #2287ca;
  padding: 7px 16px;
  font-weight: 600;
  border-radius: 20px;
  transition: background-color 300ms ease-out;
  cursor: pointer;

  &:hover {
    background-color: #279ae729;
  }
`;

export const InfoText = styled.div`
  margin-bottom: 6px;

  a {
    margin-right: 6px;
  }
`;
