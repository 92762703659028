// source: collab_client.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var collab_common_pb = require('./collab_common_pb.js');
goog.object.extend(proto, collab_common_pb);
goog.exportSymbol('proto.protobuf.collab.client.AddTemplatePage', null, global);
goog.exportSymbol('proto.protobuf.collab.client.AnnouncePage', null, global);
goog.exportSymbol('proto.protobuf.collab.client.BatchEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.client.BoardEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.client.BoardFile', null, global);
goog.exportSymbol('proto.protobuf.collab.client.ForceFollow', null, global);
goog.exportSymbol('proto.protobuf.collab.client.Image', null, global);
goog.exportSymbol('proto.protobuf.collab.client.Images', null, global);
goog.exportSymbol('proto.protobuf.collab.client.Join', null, global);
goog.exportSymbol('proto.protobuf.collab.client.KeepAlive', null, global);
goog.exportSymbol('proto.protobuf.collab.client.Message', null, global);
goog.exportSymbol('proto.protobuf.collab.client.Message.TypeCase', null, global);
goog.exportSymbol('proto.protobuf.collab.client.OnboardingDone', null, global);
goog.exportSymbol('proto.protobuf.collab.client.PinUpdated', null, global);
goog.exportSymbol('proto.protobuf.collab.client.Ping', null, global);
goog.exportSymbol('proto.protobuf.collab.client.PublishAck', null, global);
goog.exportSymbol('proto.protobuf.collab.client.PublishBlob', null, global);
goog.exportSymbol('proto.protobuf.collab.client.PublishBlob.TypeCase', null, global);
goog.exportSymbol('proto.protobuf.collab.client.RecallBlob', null, global);
goog.exportSymbol('proto.protobuf.collab.client.RequestPresenter', null, global);
goog.exportSymbol('proto.protobuf.collab.client.ScreenShareEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.client.SetReadOnly', null, global);
goog.exportSymbol('proto.protobuf.collab.client.Thumbnail', null, global);
goog.exportSymbol('proto.protobuf.collab.client.UndoBoardEvent', null, global);
goog.exportSymbol('proto.protobuf.collab.client.YieldPresenter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.Join = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.Join, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.Join.displayName = 'proto.protobuf.collab.client.Join';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.OnboardingDone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.OnboardingDone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.OnboardingDone.displayName = 'proto.protobuf.collab.client.OnboardingDone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.PinUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.PinUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.PinUpdated.displayName = 'proto.protobuf.collab.client.PinUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.BoardEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.BoardEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.BoardEvent.displayName = 'proto.protobuf.collab.client.BoardEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.BatchEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.collab.client.BatchEvent.repeatedFields_, null);
};
goog.inherits(proto.protobuf.collab.client.BatchEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.BatchEvent.displayName = 'proto.protobuf.collab.client.BatchEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.UndoBoardEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.UndoBoardEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.UndoBoardEvent.displayName = 'proto.protobuf.collab.client.UndoBoardEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.collab.client.Image.repeatedFields_, null);
};
goog.inherits(proto.protobuf.collab.client.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.Image.displayName = 'proto.protobuf.collab.client.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.Images = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobuf.collab.client.Images.repeatedFields_, null);
};
goog.inherits(proto.protobuf.collab.client.Images, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.Images.displayName = 'proto.protobuf.collab.client.Images';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.BoardFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.BoardFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.BoardFile.displayName = 'proto.protobuf.collab.client.BoardFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.ScreenShareEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.ScreenShareEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.ScreenShareEvent.displayName = 'proto.protobuf.collab.client.ScreenShareEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.PublishBlob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.protobuf.collab.client.PublishBlob.oneofGroups_);
};
goog.inherits(proto.protobuf.collab.client.PublishBlob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.PublishBlob.displayName = 'proto.protobuf.collab.client.PublishBlob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.PublishAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.PublishAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.PublishAck.displayName = 'proto.protobuf.collab.client.PublishAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.RecallBlob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.RecallBlob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.RecallBlob.displayName = 'proto.protobuf.collab.client.RecallBlob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.Ping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.Ping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.Ping.displayName = 'proto.protobuf.collab.client.Ping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.KeepAlive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.KeepAlive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.KeepAlive.displayName = 'proto.protobuf.collab.client.KeepAlive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.RequestPresenter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.RequestPresenter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.RequestPresenter.displayName = 'proto.protobuf.collab.client.RequestPresenter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.ForceFollow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.ForceFollow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.ForceFollow.displayName = 'proto.protobuf.collab.client.ForceFollow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.SetReadOnly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.SetReadOnly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.SetReadOnly.displayName = 'proto.protobuf.collab.client.SetReadOnly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.AnnouncePage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.AnnouncePage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.AnnouncePage.displayName = 'proto.protobuf.collab.client.AnnouncePage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.YieldPresenter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.YieldPresenter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.YieldPresenter.displayName = 'proto.protobuf.collab.client.YieldPresenter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.Thumbnail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.Thumbnail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.Thumbnail.displayName = 'proto.protobuf.collab.client.Thumbnail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.AddTemplatePage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobuf.collab.client.AddTemplatePage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.AddTemplatePage.displayName = 'proto.protobuf.collab.client.AddTemplatePage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobuf.collab.client.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.protobuf.collab.client.Message.oneofGroups_);
};
goog.inherits(proto.protobuf.collab.client.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobuf.collab.client.Message.displayName = 'proto.protobuf.collab.client.Message';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.Join.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.Join.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.Join} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Join.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.Join}
 */
proto.protobuf.collab.client.Join.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.Join;
  return proto.protobuf.collab.client.Join.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.Join} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.Join}
 */
proto.protobuf.collab.client.Join.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.protobuf.collab.common.ClientType} */ (reader.readEnum());
      msg.setClientType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Join.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.Join.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.Join} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Join.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.protobuf.collab.client.Join.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.Join} returns this
 */
proto.protobuf.collab.client.Join.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional protobuf.collab.common.ClientType client_type = 2;
 * @return {!proto.protobuf.collab.common.ClientType}
 */
proto.protobuf.collab.client.Join.prototype.getClientType = function() {
  return /** @type {!proto.protobuf.collab.common.ClientType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.protobuf.collab.common.ClientType} value
 * @return {!proto.protobuf.collab.client.Join} returns this
 */
proto.protobuf.collab.client.Join.prototype.setClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.OnboardingDone.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.OnboardingDone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.OnboardingDone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.OnboardingDone.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.OnboardingDone}
 */
proto.protobuf.collab.client.OnboardingDone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.OnboardingDone;
  return proto.protobuf.collab.client.OnboardingDone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.OnboardingDone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.OnboardingDone}
 */
proto.protobuf.collab.client.OnboardingDone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.OnboardingDone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.OnboardingDone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.OnboardingDone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.OnboardingDone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.PinUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.PinUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.PinUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.PinUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.PinUpdated}
 */
proto.protobuf.collab.client.PinUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.PinUpdated;
  return proto.protobuf.collab.client.PinUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.PinUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.PinUpdated}
 */
proto.protobuf.collab.client.PinUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.PinUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.PinUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.PinUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.PinUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiresAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.PinUpdated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.PinUpdated} returns this
 */
proto.protobuf.collab.client.PinUpdated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.protobuf.collab.client.PinUpdated.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.PinUpdated} returns this
 */
proto.protobuf.collab.client.PinUpdated.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 expires_at = 3;
 * @return {number}
 */
proto.protobuf.collab.client.PinUpdated.prototype.getExpiresAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.client.PinUpdated} returns this
 */
proto.protobuf.collab.client.PinUpdated.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.BoardEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.BoardEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.BoardEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.BoardEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64(),
    seq: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    prevActionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pb_continue: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.BoardEvent}
 */
proto.protobuf.collab.client.BoardEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.BoardEvent;
  return proto.protobuf.collab.client.BoardEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.BoardEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.BoardEvent}
 */
proto.protobuf.collab.client.BoardEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeq(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevActionId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContinue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.BoardEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.BoardEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.BoardEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.BoardEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSeq();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrevActionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContinue();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string receiver_id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getReceiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.BoardEvent} returns this
 */
proto.protobuf.collab.client.BoardEvent.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.BoardEvent} returns this
 */
proto.protobuf.collab.client.BoardEvent.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional int64 seq = 3;
 * @return {number}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.client.BoardEvent} returns this
 */
proto.protobuf.collab.client.BoardEvent.prototype.setSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string action_id = 4;
 * @return {string}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.BoardEvent} returns this
 */
proto.protobuf.collab.client.BoardEvent.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string prev_action_id = 5;
 * @return {string}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getPrevActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.BoardEvent} returns this
 */
proto.protobuf.collab.client.BoardEvent.prototype.setPrevActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool continue = 6;
 * @return {boolean}
 */
proto.protobuf.collab.client.BoardEvent.prototype.getContinue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.client.BoardEvent} returns this
 */
proto.protobuf.collab.client.BoardEvent.prototype.setContinue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.collab.client.BatchEvent.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.BatchEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.BatchEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.BatchEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.BatchEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    boardEventList: jspb.Message.toObjectList(msg.getBoardEventList(),
    proto.protobuf.collab.client.BoardEvent.toObject, includeInstance),
    seq: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.BatchEvent}
 */
proto.protobuf.collab.client.BatchEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.BatchEvent;
  return proto.protobuf.collab.client.BatchEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.BatchEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.BatchEvent}
 */
proto.protobuf.collab.client.BatchEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReceiverId(value);
      break;
    case 2:
      var value = new proto.protobuf.collab.client.BoardEvent;
      reader.readMessage(value,proto.protobuf.collab.client.BoardEvent.deserializeBinaryFromReader);
      msg.addBoardEvent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.BatchEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.BatchEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.BatchEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.BatchEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBoardEventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.protobuf.collab.client.BoardEvent.serializeBinaryToWriter
    );
  }
  f = message.getSeq();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated string receiver_id = 1;
 * @return {!Array<string>}
 */
proto.protobuf.collab.client.BatchEvent.prototype.getReceiverIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protobuf.collab.client.BatchEvent} returns this
 */
proto.protobuf.collab.client.BatchEvent.prototype.setReceiverIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.client.BatchEvent} returns this
 */
proto.protobuf.collab.client.BatchEvent.prototype.addReceiverId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.client.BatchEvent} returns this
 */
proto.protobuf.collab.client.BatchEvent.prototype.clearReceiverIdList = function() {
  return this.setReceiverIdList([]);
};


/**
 * repeated BoardEvent board_event = 2;
 * @return {!Array<!proto.protobuf.collab.client.BoardEvent>}
 */
proto.protobuf.collab.client.BatchEvent.prototype.getBoardEventList = function() {
  return /** @type{!Array<!proto.protobuf.collab.client.BoardEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.collab.client.BoardEvent, 2));
};


/**
 * @param {!Array<!proto.protobuf.collab.client.BoardEvent>} value
 * @return {!proto.protobuf.collab.client.BatchEvent} returns this
*/
proto.protobuf.collab.client.BatchEvent.prototype.setBoardEventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.protobuf.collab.client.BoardEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.client.BoardEvent}
 */
proto.protobuf.collab.client.BatchEvent.prototype.addBoardEvent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.protobuf.collab.client.BoardEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.client.BatchEvent} returns this
 */
proto.protobuf.collab.client.BatchEvent.prototype.clearBoardEventList = function() {
  return this.setBoardEventList([]);
};


/**
 * optional int64 seq = 3;
 * @return {number}
 */
proto.protobuf.collab.client.BatchEvent.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.client.BatchEvent} returns this
 */
proto.protobuf.collab.client.BatchEvent.prototype.setSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.UndoBoardEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.UndoBoardEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.UndoBoardEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    seq: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    prevActionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.UndoBoardEvent}
 */
proto.protobuf.collab.client.UndoBoardEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.UndoBoardEvent;
  return proto.protobuf.collab.client.UndoBoardEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.UndoBoardEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.UndoBoardEvent}
 */
proto.protobuf.collab.client.UndoBoardEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeq(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevActionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.UndoBoardEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.UndoBoardEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.UndoBoardEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeq();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrevActionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 seq = 1;
 * @return {number}
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.client.UndoBoardEvent} returns this
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.setSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string action_id = 2;
 * @return {string}
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.UndoBoardEvent} returns this
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string prev_action_id = 3;
 * @return {string}
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.getPrevActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.UndoBoardEvent} returns this
 */
proto.protobuf.collab.client.UndoBoardEvent.prototype.setPrevActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.collab.client.Image.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    data: msg.getData_asB64(),
    mime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paperId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pb_continue: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.Image}
 */
proto.protobuf.collab.client.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.Image;
  return proto.protobuf.collab.client.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.Image}
 */
proto.protobuf.collab.client.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReceiverId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaperId(value);
      break;
    case 6:
      var value = /** @type {!proto.protobuf.collab.common.ImageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContinue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaperId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getContinue();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * repeated string receiver_id = 1;
 * @return {!Array<string>}
 */
proto.protobuf.collab.client.Image.prototype.getReceiverIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.setReceiverIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.addReceiverId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.clearReceiverIdList = function() {
  return this.setReceiverIdList([]);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.Image.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.client.Image.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Image.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string mime = 3;
 * @return {string}
 */
proto.protobuf.collab.client.Image.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.protobuf.collab.client.Image.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string paper_id = 5;
 * @return {string}
 */
proto.protobuf.collab.client.Image.prototype.getPaperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.setPaperId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional protobuf.collab.common.ImageType type = 6;
 * @return {!proto.protobuf.collab.common.ImageType}
 */
proto.protobuf.collab.client.Image.prototype.getType = function() {
  return /** @type {!proto.protobuf.collab.common.ImageType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.protobuf.collab.common.ImageType} value
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool continue = 7;
 * @return {boolean}
 */
proto.protobuf.collab.client.Image.prototype.getContinue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.client.Image} returns this
 */
proto.protobuf.collab.client.Image.prototype.setContinue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobuf.collab.client.Images.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.Images.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.Images.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.Images} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Images.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    imageList: jspb.Message.toObjectList(msg.getImageList(),
    proto.protobuf.collab.client.Image.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.Images}
 */
proto.protobuf.collab.client.Images.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.Images;
  return proto.protobuf.collab.client.Images.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.Images} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.Images}
 */
proto.protobuf.collab.client.Images.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReceiverId(value);
      break;
    case 2:
      var value = new proto.protobuf.collab.client.Image;
      reader.readMessage(value,proto.protobuf.collab.client.Image.deserializeBinaryFromReader);
      msg.addImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Images.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.Images.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.Images} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Images.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.protobuf.collab.client.Image.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string receiver_id = 1;
 * @return {!Array<string>}
 */
proto.protobuf.collab.client.Images.prototype.getReceiverIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protobuf.collab.client.Images} returns this
 */
proto.protobuf.collab.client.Images.prototype.setReceiverIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.client.Images} returns this
 */
proto.protobuf.collab.client.Images.prototype.addReceiverId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.client.Images} returns this
 */
proto.protobuf.collab.client.Images.prototype.clearReceiverIdList = function() {
  return this.setReceiverIdList([]);
};


/**
 * repeated Image image = 2;
 * @return {!Array<!proto.protobuf.collab.client.Image>}
 */
proto.protobuf.collab.client.Images.prototype.getImageList = function() {
  return /** @type{!Array<!proto.protobuf.collab.client.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobuf.collab.client.Image, 2));
};


/**
 * @param {!Array<!proto.protobuf.collab.client.Image>} value
 * @return {!proto.protobuf.collab.client.Images} returns this
*/
proto.protobuf.collab.client.Images.prototype.setImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.protobuf.collab.client.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobuf.collab.client.Image}
 */
proto.protobuf.collab.client.Images.prototype.addImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.protobuf.collab.client.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobuf.collab.client.Images} returns this
 */
proto.protobuf.collab.client.Images.prototype.clearImageList = function() {
  return this.setImageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.BoardFile.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.BoardFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.BoardFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.BoardFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.BoardFile}
 */
proto.protobuf.collab.client.BoardFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.BoardFile;
  return proto.protobuf.collab.client.BoardFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.BoardFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.BoardFile}
 */
proto.protobuf.collab.client.BoardFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.BoardFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.BoardFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.BoardFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.BoardFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.protobuf.collab.client.BoardFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.BoardFile} returns this
 */
proto.protobuf.collab.client.BoardFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.BoardFile.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.client.BoardFile.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.BoardFile.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.BoardFile} returns this
 */
proto.protobuf.collab.client.BoardFile.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.ScreenShareEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.ScreenShareEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.ScreenShareEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.ScreenShareEvent}
 */
proto.protobuf.collab.client.ScreenShareEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.ScreenShareEvent;
  return proto.protobuf.collab.client.ScreenShareEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.ScreenShareEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.ScreenShareEvent}
 */
proto.protobuf.collab.client.ScreenShareEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.ScreenShareEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.ScreenShareEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.ScreenShareEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string receiver_id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.getReceiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.ScreenShareEvent} returns this
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.ScreenShareEvent} returns this
 */
proto.protobuf.collab.client.ScreenShareEvent.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.protobuf.collab.client.PublishBlob.oneofGroups_ = [[3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.protobuf.collab.client.PublishBlob.TypeCase = {
  TYPE_NOT_SET: 0,
  IMAGE: 3,
  STICKY_NOTE: 4,
  STICKER: 5,
  BOARD_FILE: 6,
  TEXT_BLOCK: 7
};

/**
 * @return {proto.protobuf.collab.client.PublishBlob.TypeCase}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getTypeCase = function() {
  return /** @type {proto.protobuf.collab.client.PublishBlob.TypeCase} */(jspb.Message.computeOneofCase(this, proto.protobuf.collab.client.PublishBlob.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.PublishBlob.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.PublishBlob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.PublishBlob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.PublishBlob.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publishId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    image: (f = msg.getImage()) && collab_common_pb.Image.toObject(includeInstance, f),
    stickyNote: (f = msg.getStickyNote()) && collab_common_pb.StickyNote.toObject(includeInstance, f),
    sticker: (f = msg.getSticker()) && collab_common_pb.Sticker.toObject(includeInstance, f),
    boardFile: (f = msg.getBoardFile()) && collab_common_pb.BoardFile.toObject(includeInstance, f),
    textBlock: (f = msg.getTextBlock()) && collab_common_pb.TextBlock.toObject(includeInstance, f),
    imageData: msg.getImageData_asB64(),
    itemProperties: msg.getItemProperties_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.PublishBlob}
 */
proto.protobuf.collab.client.PublishBlob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.PublishBlob;
  return proto.protobuf.collab.client.PublishBlob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.PublishBlob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.PublishBlob}
 */
proto.protobuf.collab.client.PublishBlob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishId(value);
      break;
    case 3:
      var value = new collab_common_pb.Image;
      reader.readMessage(value,collab_common_pb.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 4:
      var value = new collab_common_pb.StickyNote;
      reader.readMessage(value,collab_common_pb.StickyNote.deserializeBinaryFromReader);
      msg.setStickyNote(value);
      break;
    case 5:
      var value = new collab_common_pb.Sticker;
      reader.readMessage(value,collab_common_pb.Sticker.deserializeBinaryFromReader);
      msg.setSticker(value);
      break;
    case 6:
      var value = new collab_common_pb.BoardFile;
      reader.readMessage(value,collab_common_pb.BoardFile.deserializeBinaryFromReader);
      msg.setBoardFile(value);
      break;
    case 7:
      var value = new collab_common_pb.TextBlock;
      reader.readMessage(value,collab_common_pb.TextBlock.deserializeBinaryFromReader);
      msg.setTextBlock(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImageData(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setItemProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.PublishBlob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.PublishBlob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.PublishBlob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.PublishBlob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublishId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      collab_common_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getStickyNote();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      collab_common_pb.StickyNote.serializeBinaryToWriter
    );
  }
  f = message.getSticker();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      collab_common_pb.Sticker.serializeBinaryToWriter
    );
  }
  f = message.getBoardFile();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      collab_common_pb.BoardFile.serializeBinaryToWriter
    );
  }
  f = message.getTextBlock();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      collab_common_pb.TextBlock.serializeBinaryToWriter
    );
  }
  f = message.getImageData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getItemProperties_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
};


/**
 * optional string receiver_id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getReceiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string publish_id = 2;
 * @return {string}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getPublishId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.setPublishId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional protobuf.collab.common.Image image = 3;
 * @return {?proto.protobuf.collab.common.Image}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getImage = function() {
  return /** @type{?proto.protobuf.collab.common.Image} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.Image, 3));
};


/**
 * @param {?proto.protobuf.collab.common.Image|undefined} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
*/
proto.protobuf.collab.client.PublishBlob.prototype.setImage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.protobuf.collab.client.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.PublishBlob.prototype.hasImage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional protobuf.collab.common.StickyNote sticky_note = 4;
 * @return {?proto.protobuf.collab.common.StickyNote}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getStickyNote = function() {
  return /** @type{?proto.protobuf.collab.common.StickyNote} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.StickyNote, 4));
};


/**
 * @param {?proto.protobuf.collab.common.StickyNote|undefined} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
*/
proto.protobuf.collab.client.PublishBlob.prototype.setStickyNote = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.protobuf.collab.client.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.clearStickyNote = function() {
  return this.setStickyNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.PublishBlob.prototype.hasStickyNote = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional protobuf.collab.common.Sticker sticker = 5;
 * @return {?proto.protobuf.collab.common.Sticker}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getSticker = function() {
  return /** @type{?proto.protobuf.collab.common.Sticker} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.Sticker, 5));
};


/**
 * @param {?proto.protobuf.collab.common.Sticker|undefined} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
*/
proto.protobuf.collab.client.PublishBlob.prototype.setSticker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.protobuf.collab.client.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.clearSticker = function() {
  return this.setSticker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.PublishBlob.prototype.hasSticker = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional protobuf.collab.common.BoardFile board_file = 6;
 * @return {?proto.protobuf.collab.common.BoardFile}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getBoardFile = function() {
  return /** @type{?proto.protobuf.collab.common.BoardFile} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.BoardFile, 6));
};


/**
 * @param {?proto.protobuf.collab.common.BoardFile|undefined} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
*/
proto.protobuf.collab.client.PublishBlob.prototype.setBoardFile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.protobuf.collab.client.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.clearBoardFile = function() {
  return this.setBoardFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.PublishBlob.prototype.hasBoardFile = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional protobuf.collab.common.TextBlock text_block = 7;
 * @return {?proto.protobuf.collab.common.TextBlock}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getTextBlock = function() {
  return /** @type{?proto.protobuf.collab.common.TextBlock} */ (
    jspb.Message.getWrapperField(this, collab_common_pb.TextBlock, 7));
};


/**
 * @param {?proto.protobuf.collab.common.TextBlock|undefined} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
*/
proto.protobuf.collab.client.PublishBlob.prototype.setTextBlock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.protobuf.collab.client.PublishBlob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.clearTextBlock = function() {
  return this.setTextBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.PublishBlob.prototype.hasTextBlock = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes image_data = 8;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getImageData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes image_data = 8;
 * This is a type-conversion wrapper around `getImageData()`
 * @return {string}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getImageData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImageData()));
};


/**
 * optional bytes image_data = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImageData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getImageData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImageData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.setImageData = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional bytes item_properties = 9;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getItemProperties = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes item_properties = 9;
 * This is a type-conversion wrapper around `getItemProperties()`
 * @return {string}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getItemProperties_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getItemProperties()));
};


/**
 * optional bytes item_properties = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getItemProperties()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.PublishBlob.prototype.getItemProperties_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getItemProperties()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.PublishBlob} returns this
 */
proto.protobuf.collab.client.PublishBlob.prototype.setItemProperties = function(value) {
  return jspb.Message.setProto3BytesField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.PublishAck.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.PublishAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.PublishAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.PublishAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publishId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.PublishAck}
 */
proto.protobuf.collab.client.PublishAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.PublishAck;
  return proto.protobuf.collab.client.PublishAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.PublishAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.PublishAck}
 */
proto.protobuf.collab.client.PublishAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.PublishAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.PublishAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.PublishAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.PublishAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublishId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string receiver_id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.PublishAck.prototype.getReceiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.PublishAck} returns this
 */
proto.protobuf.collab.client.PublishAck.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string publish_id = 2;
 * @return {string}
 */
proto.protobuf.collab.client.PublishAck.prototype.getPublishId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.PublishAck} returns this
 */
proto.protobuf.collab.client.PublishAck.prototype.setPublishId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.RecallBlob.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.RecallBlob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.RecallBlob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.RecallBlob.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publishId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.RecallBlob}
 */
proto.protobuf.collab.client.RecallBlob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.RecallBlob;
  return proto.protobuf.collab.client.RecallBlob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.RecallBlob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.RecallBlob}
 */
proto.protobuf.collab.client.RecallBlob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.RecallBlob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.RecallBlob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.RecallBlob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.RecallBlob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublishId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string receiver_id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.RecallBlob.prototype.getReceiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.RecallBlob} returns this
 */
proto.protobuf.collab.client.RecallBlob.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string publish_id = 2;
 * @return {string}
 */
proto.protobuf.collab.client.RecallBlob.prototype.getPublishId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.RecallBlob} returns this
 */
proto.protobuf.collab.client.RecallBlob.prototype.setPublishId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.Ping.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.Ping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.Ping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Ping.toObject = function(includeInstance, msg) {
  var f, obj = {
    color: msg.getColor_asB64(),
    pageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.Ping}
 */
proto.protobuf.collab.client.Ping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.Ping;
  return proto.protobuf.collab.client.Ping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.Ping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.Ping}
 */
proto.protobuf.collab.client.Ping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setColor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Ping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.Ping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.Ping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Ping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColor_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional bytes color = 1;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.Ping.prototype.getColor = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes color = 1;
 * This is a type-conversion wrapper around `getColor()`
 * @return {string}
 */
proto.protobuf.collab.client.Ping.prototype.getColor_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getColor()));
};


/**
 * optional bytes color = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getColor()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Ping.prototype.getColor_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getColor()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.Ping} returns this
 */
proto.protobuf.collab.client.Ping.prototype.setColor = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string page_id = 2;
 * @return {string}
 */
proto.protobuf.collab.client.Ping.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.Ping} returns this
 */
proto.protobuf.collab.client.Ping.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float x = 3;
 * @return {number}
 */
proto.protobuf.collab.client.Ping.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.client.Ping} returns this
 */
proto.protobuf.collab.client.Ping.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float y = 4;
 * @return {number}
 */
proto.protobuf.collab.client.Ping.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.client.Ping} returns this
 */
proto.protobuf.collab.client.Ping.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.KeepAlive.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.KeepAlive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.KeepAlive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.KeepAlive.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.KeepAlive}
 */
proto.protobuf.collab.client.KeepAlive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.KeepAlive;
  return proto.protobuf.collab.client.KeepAlive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.KeepAlive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.KeepAlive}
 */
proto.protobuf.collab.client.KeepAlive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.KeepAlive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.KeepAlive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.KeepAlive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.KeepAlive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.RequestPresenter.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.RequestPresenter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.RequestPresenter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.RequestPresenter.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.RequestPresenter}
 */
proto.protobuf.collab.client.RequestPresenter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.RequestPresenter;
  return proto.protobuf.collab.client.RequestPresenter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.RequestPresenter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.RequestPresenter}
 */
proto.protobuf.collab.client.RequestPresenter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.RequestPresenter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.RequestPresenter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.RequestPresenter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.RequestPresenter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.ForceFollow.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.ForceFollow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.ForceFollow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.ForceFollow.toObject = function(includeInstance, msg) {
  var f, obj = {
    on: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.ForceFollow}
 */
proto.protobuf.collab.client.ForceFollow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.ForceFollow;
  return proto.protobuf.collab.client.ForceFollow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.ForceFollow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.ForceFollow}
 */
proto.protobuf.collab.client.ForceFollow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.ForceFollow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.ForceFollow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.ForceFollow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.ForceFollow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOn();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool on = 1;
 * @return {boolean}
 */
proto.protobuf.collab.client.ForceFollow.prototype.getOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.client.ForceFollow} returns this
 */
proto.protobuf.collab.client.ForceFollow.prototype.setOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.SetReadOnly.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.SetReadOnly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.SetReadOnly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.SetReadOnly.toObject = function(includeInstance, msg) {
  var f, obj = {
    on: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.SetReadOnly}
 */
proto.protobuf.collab.client.SetReadOnly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.SetReadOnly;
  return proto.protobuf.collab.client.SetReadOnly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.SetReadOnly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.SetReadOnly}
 */
proto.protobuf.collab.client.SetReadOnly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.SetReadOnly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.SetReadOnly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.SetReadOnly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.SetReadOnly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOn();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool on = 1;
 * @return {boolean}
 */
proto.protobuf.collab.client.SetReadOnly.prototype.getOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.client.SetReadOnly} returns this
 */
proto.protobuf.collab.client.SetReadOnly.prototype.setOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.AnnouncePage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.AnnouncePage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.AnnouncePage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.AnnouncePage.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isPresenter: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.AnnouncePage}
 */
proto.protobuf.collab.client.AnnouncePage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.AnnouncePage;
  return proto.protobuf.collab.client.AnnouncePage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.AnnouncePage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.AnnouncePage}
 */
proto.protobuf.collab.client.AnnouncePage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPresenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.AnnouncePage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.AnnouncePage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.AnnouncePage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.AnnouncePage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsPresenter();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.AnnouncePage.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.AnnouncePage} returns this
 */
proto.protobuf.collab.client.AnnouncePage.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_presenter = 2;
 * @return {boolean}
 */
proto.protobuf.collab.client.AnnouncePage.prototype.getIsPresenter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protobuf.collab.client.AnnouncePage} returns this
 */
proto.protobuf.collab.client.AnnouncePage.prototype.setIsPresenter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.YieldPresenter.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.YieldPresenter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.YieldPresenter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.YieldPresenter.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.YieldPresenter}
 */
proto.protobuf.collab.client.YieldPresenter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.YieldPresenter;
  return proto.protobuf.collab.client.YieldPresenter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.YieldPresenter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.YieldPresenter}
 */
proto.protobuf.collab.client.YieldPresenter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.YieldPresenter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.YieldPresenter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.YieldPresenter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.YieldPresenter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.Thumbnail.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.Thumbnail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.Thumbnail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Thumbnail.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.Thumbnail}
 */
proto.protobuf.collab.client.Thumbnail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.Thumbnail;
  return proto.protobuf.collab.client.Thumbnail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.Thumbnail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.Thumbnail}
 */
proto.protobuf.collab.client.Thumbnail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Thumbnail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.Thumbnail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.Thumbnail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Thumbnail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes data = 1;
 * @return {!(string|Uint8Array)}
 */
proto.protobuf.collab.client.Thumbnail.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.protobuf.collab.client.Thumbnail.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Thumbnail.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.protobuf.collab.client.Thumbnail} returns this
 */
proto.protobuf.collab.client.Thumbnail.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.AddTemplatePage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.AddTemplatePage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.AddTemplatePage.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageUuid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.AddTemplatePage}
 */
proto.protobuf.collab.client.AddTemplatePage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.AddTemplatePage;
  return proto.protobuf.collab.client.AddTemplatePage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.AddTemplatePage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.AddTemplatePage}
 */
proto.protobuf.collab.client.AddTemplatePage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.AddTemplatePage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.AddTemplatePage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.AddTemplatePage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPageUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string template_id = 1;
 * @return {string}
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.AddTemplatePage} returns this
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 page_index = 2;
 * @return {number}
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.getPageIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobuf.collab.client.AddTemplatePage} returns this
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.setPageIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_uuid = 3;
 * @return {string}
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.getPageUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobuf.collab.client.AddTemplatePage} returns this
 */
proto.protobuf.collab.client.AddTemplatePage.prototype.setPageUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.protobuf.collab.client.Message.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21]];

/**
 * @enum {number}
 */
proto.protobuf.collab.client.Message.TypeCase = {
  TYPE_NOT_SET: 0,
  JOIN: 1,
  BOARD_EVENT: 2,
  BOARD_FILE: 3,
  SCREEN_SHARE_EVENT: 4,
  PUBLISH_BLOB: 5,
  PUBLISH_ACK: 6,
  RECALL_BLOB: 7,
  PING: 8,
  KEEP_ALIVE: 9,
  IMAGE: 10,
  REQUEST_PRESENTER: 11,
  ANNOUNCE_PAGE: 12,
  UNDO_BOARD_EVENT: 13,
  BATCH_EVENT: 14,
  IMAGES: 15,
  ONBOARDING_DONE: 16,
  FORCE_FOLLOW: 17,
  SET_READ_ONLY: 18,
  YIELD_PRESENTER: 19,
  THUMBNAIL: 20,
  ADD_TEMPLATE_PAGE: 21
};

/**
 * @return {proto.protobuf.collab.client.Message.TypeCase}
 */
proto.protobuf.collab.client.Message.prototype.getTypeCase = function() {
  return /** @type {proto.protobuf.collab.client.Message.TypeCase} */(jspb.Message.computeOneofCase(this, proto.protobuf.collab.client.Message.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobuf.collab.client.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.protobuf.collab.client.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobuf.collab.client.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    join: (f = msg.getJoin()) && proto.protobuf.collab.client.Join.toObject(includeInstance, f),
    boardEvent: (f = msg.getBoardEvent()) && proto.protobuf.collab.client.BoardEvent.toObject(includeInstance, f),
    boardFile: (f = msg.getBoardFile()) && proto.protobuf.collab.client.BoardFile.toObject(includeInstance, f),
    screenShareEvent: (f = msg.getScreenShareEvent()) && proto.protobuf.collab.client.ScreenShareEvent.toObject(includeInstance, f),
    publishBlob: (f = msg.getPublishBlob()) && proto.protobuf.collab.client.PublishBlob.toObject(includeInstance, f),
    publishAck: (f = msg.getPublishAck()) && proto.protobuf.collab.client.PublishAck.toObject(includeInstance, f),
    recallBlob: (f = msg.getRecallBlob()) && proto.protobuf.collab.client.RecallBlob.toObject(includeInstance, f),
    ping: (f = msg.getPing()) && proto.protobuf.collab.client.Ping.toObject(includeInstance, f),
    keepAlive: (f = msg.getKeepAlive()) && proto.protobuf.collab.client.KeepAlive.toObject(includeInstance, f),
    image: (f = msg.getImage()) && proto.protobuf.collab.client.Image.toObject(includeInstance, f),
    requestPresenter: (f = msg.getRequestPresenter()) && proto.protobuf.collab.client.RequestPresenter.toObject(includeInstance, f),
    announcePage: (f = msg.getAnnouncePage()) && proto.protobuf.collab.client.AnnouncePage.toObject(includeInstance, f),
    undoBoardEvent: (f = msg.getUndoBoardEvent()) && proto.protobuf.collab.client.UndoBoardEvent.toObject(includeInstance, f),
    batchEvent: (f = msg.getBatchEvent()) && proto.protobuf.collab.client.BatchEvent.toObject(includeInstance, f),
    images: (f = msg.getImages()) && proto.protobuf.collab.client.Images.toObject(includeInstance, f),
    onboardingDone: (f = msg.getOnboardingDone()) && proto.protobuf.collab.client.OnboardingDone.toObject(includeInstance, f),
    forceFollow: (f = msg.getForceFollow()) && proto.protobuf.collab.client.ForceFollow.toObject(includeInstance, f),
    setReadOnly: (f = msg.getSetReadOnly()) && proto.protobuf.collab.client.SetReadOnly.toObject(includeInstance, f),
    yieldPresenter: (f = msg.getYieldPresenter()) && proto.protobuf.collab.client.YieldPresenter.toObject(includeInstance, f),
    thumbnail: (f = msg.getThumbnail()) && proto.protobuf.collab.client.Thumbnail.toObject(includeInstance, f),
    addTemplatePage: (f = msg.getAddTemplatePage()) && proto.protobuf.collab.client.AddTemplatePage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobuf.collab.client.Message}
 */
proto.protobuf.collab.client.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobuf.collab.client.Message;
  return proto.protobuf.collab.client.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobuf.collab.client.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobuf.collab.client.Message}
 */
proto.protobuf.collab.client.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protobuf.collab.client.Join;
      reader.readMessage(value,proto.protobuf.collab.client.Join.deserializeBinaryFromReader);
      msg.setJoin(value);
      break;
    case 2:
      var value = new proto.protobuf.collab.client.BoardEvent;
      reader.readMessage(value,proto.protobuf.collab.client.BoardEvent.deserializeBinaryFromReader);
      msg.setBoardEvent(value);
      break;
    case 3:
      var value = new proto.protobuf.collab.client.BoardFile;
      reader.readMessage(value,proto.protobuf.collab.client.BoardFile.deserializeBinaryFromReader);
      msg.setBoardFile(value);
      break;
    case 4:
      var value = new proto.protobuf.collab.client.ScreenShareEvent;
      reader.readMessage(value,proto.protobuf.collab.client.ScreenShareEvent.deserializeBinaryFromReader);
      msg.setScreenShareEvent(value);
      break;
    case 5:
      var value = new proto.protobuf.collab.client.PublishBlob;
      reader.readMessage(value,proto.protobuf.collab.client.PublishBlob.deserializeBinaryFromReader);
      msg.setPublishBlob(value);
      break;
    case 6:
      var value = new proto.protobuf.collab.client.PublishAck;
      reader.readMessage(value,proto.protobuf.collab.client.PublishAck.deserializeBinaryFromReader);
      msg.setPublishAck(value);
      break;
    case 7:
      var value = new proto.protobuf.collab.client.RecallBlob;
      reader.readMessage(value,proto.protobuf.collab.client.RecallBlob.deserializeBinaryFromReader);
      msg.setRecallBlob(value);
      break;
    case 8:
      var value = new proto.protobuf.collab.client.Ping;
      reader.readMessage(value,proto.protobuf.collab.client.Ping.deserializeBinaryFromReader);
      msg.setPing(value);
      break;
    case 9:
      var value = new proto.protobuf.collab.client.KeepAlive;
      reader.readMessage(value,proto.protobuf.collab.client.KeepAlive.deserializeBinaryFromReader);
      msg.setKeepAlive(value);
      break;
    case 10:
      var value = new proto.protobuf.collab.client.Image;
      reader.readMessage(value,proto.protobuf.collab.client.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 11:
      var value = new proto.protobuf.collab.client.RequestPresenter;
      reader.readMessage(value,proto.protobuf.collab.client.RequestPresenter.deserializeBinaryFromReader);
      msg.setRequestPresenter(value);
      break;
    case 12:
      var value = new proto.protobuf.collab.client.AnnouncePage;
      reader.readMessage(value,proto.protobuf.collab.client.AnnouncePage.deserializeBinaryFromReader);
      msg.setAnnouncePage(value);
      break;
    case 13:
      var value = new proto.protobuf.collab.client.UndoBoardEvent;
      reader.readMessage(value,proto.protobuf.collab.client.UndoBoardEvent.deserializeBinaryFromReader);
      msg.setUndoBoardEvent(value);
      break;
    case 14:
      var value = new proto.protobuf.collab.client.BatchEvent;
      reader.readMessage(value,proto.protobuf.collab.client.BatchEvent.deserializeBinaryFromReader);
      msg.setBatchEvent(value);
      break;
    case 15:
      var value = new proto.protobuf.collab.client.Images;
      reader.readMessage(value,proto.protobuf.collab.client.Images.deserializeBinaryFromReader);
      msg.setImages(value);
      break;
    case 16:
      var value = new proto.protobuf.collab.client.OnboardingDone;
      reader.readMessage(value,proto.protobuf.collab.client.OnboardingDone.deserializeBinaryFromReader);
      msg.setOnboardingDone(value);
      break;
    case 17:
      var value = new proto.protobuf.collab.client.ForceFollow;
      reader.readMessage(value,proto.protobuf.collab.client.ForceFollow.deserializeBinaryFromReader);
      msg.setForceFollow(value);
      break;
    case 18:
      var value = new proto.protobuf.collab.client.SetReadOnly;
      reader.readMessage(value,proto.protobuf.collab.client.SetReadOnly.deserializeBinaryFromReader);
      msg.setSetReadOnly(value);
      break;
    case 19:
      var value = new proto.protobuf.collab.client.YieldPresenter;
      reader.readMessage(value,proto.protobuf.collab.client.YieldPresenter.deserializeBinaryFromReader);
      msg.setYieldPresenter(value);
      break;
    case 20:
      var value = new proto.protobuf.collab.client.Thumbnail;
      reader.readMessage(value,proto.protobuf.collab.client.Thumbnail.deserializeBinaryFromReader);
      msg.setThumbnail(value);
      break;
    case 21:
      var value = new proto.protobuf.collab.client.AddTemplatePage;
      reader.readMessage(value,proto.protobuf.collab.client.AddTemplatePage.deserializeBinaryFromReader);
      msg.setAddTemplatePage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobuf.collab.client.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobuf.collab.client.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobuf.collab.client.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobuf.collab.client.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJoin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protobuf.collab.client.Join.serializeBinaryToWriter
    );
  }
  f = message.getBoardEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protobuf.collab.client.BoardEvent.serializeBinaryToWriter
    );
  }
  f = message.getBoardFile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protobuf.collab.client.BoardFile.serializeBinaryToWriter
    );
  }
  f = message.getScreenShareEvent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protobuf.collab.client.ScreenShareEvent.serializeBinaryToWriter
    );
  }
  f = message.getPublishBlob();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protobuf.collab.client.PublishBlob.serializeBinaryToWriter
    );
  }
  f = message.getPublishAck();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protobuf.collab.client.PublishAck.serializeBinaryToWriter
    );
  }
  f = message.getRecallBlob();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protobuf.collab.client.RecallBlob.serializeBinaryToWriter
    );
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protobuf.collab.client.Ping.serializeBinaryToWriter
    );
  }
  f = message.getKeepAlive();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.protobuf.collab.client.KeepAlive.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protobuf.collab.client.Image.serializeBinaryToWriter
    );
  }
  f = message.getRequestPresenter();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.protobuf.collab.client.RequestPresenter.serializeBinaryToWriter
    );
  }
  f = message.getAnnouncePage();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.protobuf.collab.client.AnnouncePage.serializeBinaryToWriter
    );
  }
  f = message.getUndoBoardEvent();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.protobuf.collab.client.UndoBoardEvent.serializeBinaryToWriter
    );
  }
  f = message.getBatchEvent();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.protobuf.collab.client.BatchEvent.serializeBinaryToWriter
    );
  }
  f = message.getImages();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.protobuf.collab.client.Images.serializeBinaryToWriter
    );
  }
  f = message.getOnboardingDone();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.protobuf.collab.client.OnboardingDone.serializeBinaryToWriter
    );
  }
  f = message.getForceFollow();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.protobuf.collab.client.ForceFollow.serializeBinaryToWriter
    );
  }
  f = message.getSetReadOnly();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.protobuf.collab.client.SetReadOnly.serializeBinaryToWriter
    );
  }
  f = message.getYieldPresenter();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.protobuf.collab.client.YieldPresenter.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.protobuf.collab.client.Thumbnail.serializeBinaryToWriter
    );
  }
  f = message.getAddTemplatePage();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.protobuf.collab.client.AddTemplatePage.serializeBinaryToWriter
    );
  }
};


/**
 * optional Join join = 1;
 * @return {?proto.protobuf.collab.client.Join}
 */
proto.protobuf.collab.client.Message.prototype.getJoin = function() {
  return /** @type{?proto.protobuf.collab.client.Join} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.Join, 1));
};


/**
 * @param {?proto.protobuf.collab.client.Join|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setJoin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearJoin = function() {
  return this.setJoin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasJoin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BoardEvent board_event = 2;
 * @return {?proto.protobuf.collab.client.BoardEvent}
 */
proto.protobuf.collab.client.Message.prototype.getBoardEvent = function() {
  return /** @type{?proto.protobuf.collab.client.BoardEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.BoardEvent, 2));
};


/**
 * @param {?proto.protobuf.collab.client.BoardEvent|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setBoardEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearBoardEvent = function() {
  return this.setBoardEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasBoardEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BoardFile board_file = 3;
 * @return {?proto.protobuf.collab.client.BoardFile}
 */
proto.protobuf.collab.client.Message.prototype.getBoardFile = function() {
  return /** @type{?proto.protobuf.collab.client.BoardFile} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.BoardFile, 3));
};


/**
 * @param {?proto.protobuf.collab.client.BoardFile|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setBoardFile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearBoardFile = function() {
  return this.setBoardFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasBoardFile = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScreenShareEvent screen_share_event = 4;
 * @return {?proto.protobuf.collab.client.ScreenShareEvent}
 */
proto.protobuf.collab.client.Message.prototype.getScreenShareEvent = function() {
  return /** @type{?proto.protobuf.collab.client.ScreenShareEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.ScreenShareEvent, 4));
};


/**
 * @param {?proto.protobuf.collab.client.ScreenShareEvent|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setScreenShareEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearScreenShareEvent = function() {
  return this.setScreenShareEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasScreenShareEvent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PublishBlob publish_blob = 5;
 * @return {?proto.protobuf.collab.client.PublishBlob}
 */
proto.protobuf.collab.client.Message.prototype.getPublishBlob = function() {
  return /** @type{?proto.protobuf.collab.client.PublishBlob} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.PublishBlob, 5));
};


/**
 * @param {?proto.protobuf.collab.client.PublishBlob|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setPublishBlob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearPublishBlob = function() {
  return this.setPublishBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasPublishBlob = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PublishAck publish_ack = 6;
 * @return {?proto.protobuf.collab.client.PublishAck}
 */
proto.protobuf.collab.client.Message.prototype.getPublishAck = function() {
  return /** @type{?proto.protobuf.collab.client.PublishAck} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.PublishAck, 6));
};


/**
 * @param {?proto.protobuf.collab.client.PublishAck|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setPublishAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearPublishAck = function() {
  return this.setPublishAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasPublishAck = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RecallBlob recall_blob = 7;
 * @return {?proto.protobuf.collab.client.RecallBlob}
 */
proto.protobuf.collab.client.Message.prototype.getRecallBlob = function() {
  return /** @type{?proto.protobuf.collab.client.RecallBlob} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.RecallBlob, 7));
};


/**
 * @param {?proto.protobuf.collab.client.RecallBlob|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setRecallBlob = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearRecallBlob = function() {
  return this.setRecallBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasRecallBlob = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Ping ping = 8;
 * @return {?proto.protobuf.collab.client.Ping}
 */
proto.protobuf.collab.client.Message.prototype.getPing = function() {
  return /** @type{?proto.protobuf.collab.client.Ping} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.Ping, 8));
};


/**
 * @param {?proto.protobuf.collab.client.Ping|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setPing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearPing = function() {
  return this.setPing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasPing = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional KeepAlive keep_alive = 9;
 * @return {?proto.protobuf.collab.client.KeepAlive}
 */
proto.protobuf.collab.client.Message.prototype.getKeepAlive = function() {
  return /** @type{?proto.protobuf.collab.client.KeepAlive} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.KeepAlive, 9));
};


/**
 * @param {?proto.protobuf.collab.client.KeepAlive|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setKeepAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearKeepAlive = function() {
  return this.setKeepAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasKeepAlive = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Image image = 10;
 * @return {?proto.protobuf.collab.client.Image}
 */
proto.protobuf.collab.client.Message.prototype.getImage = function() {
  return /** @type{?proto.protobuf.collab.client.Image} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.Image, 10));
};


/**
 * @param {?proto.protobuf.collab.client.Image|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setImage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasImage = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional RequestPresenter request_presenter = 11;
 * @return {?proto.protobuf.collab.client.RequestPresenter}
 */
proto.protobuf.collab.client.Message.prototype.getRequestPresenter = function() {
  return /** @type{?proto.protobuf.collab.client.RequestPresenter} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.RequestPresenter, 11));
};


/**
 * @param {?proto.protobuf.collab.client.RequestPresenter|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setRequestPresenter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearRequestPresenter = function() {
  return this.setRequestPresenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasRequestPresenter = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional AnnouncePage announce_page = 12;
 * @return {?proto.protobuf.collab.client.AnnouncePage}
 */
proto.protobuf.collab.client.Message.prototype.getAnnouncePage = function() {
  return /** @type{?proto.protobuf.collab.client.AnnouncePage} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.AnnouncePage, 12));
};


/**
 * @param {?proto.protobuf.collab.client.AnnouncePage|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setAnnouncePage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearAnnouncePage = function() {
  return this.setAnnouncePage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasAnnouncePage = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional UndoBoardEvent undo_board_event = 13;
 * @return {?proto.protobuf.collab.client.UndoBoardEvent}
 */
proto.protobuf.collab.client.Message.prototype.getUndoBoardEvent = function() {
  return /** @type{?proto.protobuf.collab.client.UndoBoardEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.UndoBoardEvent, 13));
};


/**
 * @param {?proto.protobuf.collab.client.UndoBoardEvent|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setUndoBoardEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearUndoBoardEvent = function() {
  return this.setUndoBoardEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasUndoBoardEvent = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional BatchEvent batch_event = 14;
 * @return {?proto.protobuf.collab.client.BatchEvent}
 */
proto.protobuf.collab.client.Message.prototype.getBatchEvent = function() {
  return /** @type{?proto.protobuf.collab.client.BatchEvent} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.BatchEvent, 14));
};


/**
 * @param {?proto.protobuf.collab.client.BatchEvent|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setBatchEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearBatchEvent = function() {
  return this.setBatchEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasBatchEvent = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Images images = 15;
 * @return {?proto.protobuf.collab.client.Images}
 */
proto.protobuf.collab.client.Message.prototype.getImages = function() {
  return /** @type{?proto.protobuf.collab.client.Images} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.Images, 15));
};


/**
 * @param {?proto.protobuf.collab.client.Images|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setImages = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearImages = function() {
  return this.setImages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasImages = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional OnboardingDone onboarding_done = 16;
 * @return {?proto.protobuf.collab.client.OnboardingDone}
 */
proto.protobuf.collab.client.Message.prototype.getOnboardingDone = function() {
  return /** @type{?proto.protobuf.collab.client.OnboardingDone} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.OnboardingDone, 16));
};


/**
 * @param {?proto.protobuf.collab.client.OnboardingDone|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setOnboardingDone = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearOnboardingDone = function() {
  return this.setOnboardingDone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasOnboardingDone = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ForceFollow force_follow = 17;
 * @return {?proto.protobuf.collab.client.ForceFollow}
 */
proto.protobuf.collab.client.Message.prototype.getForceFollow = function() {
  return /** @type{?proto.protobuf.collab.client.ForceFollow} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.ForceFollow, 17));
};


/**
 * @param {?proto.protobuf.collab.client.ForceFollow|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setForceFollow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearForceFollow = function() {
  return this.setForceFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasForceFollow = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional SetReadOnly set_read_only = 18;
 * @return {?proto.protobuf.collab.client.SetReadOnly}
 */
proto.protobuf.collab.client.Message.prototype.getSetReadOnly = function() {
  return /** @type{?proto.protobuf.collab.client.SetReadOnly} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.SetReadOnly, 18));
};


/**
 * @param {?proto.protobuf.collab.client.SetReadOnly|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setSetReadOnly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearSetReadOnly = function() {
  return this.setSetReadOnly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasSetReadOnly = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional YieldPresenter yield_presenter = 19;
 * @return {?proto.protobuf.collab.client.YieldPresenter}
 */
proto.protobuf.collab.client.Message.prototype.getYieldPresenter = function() {
  return /** @type{?proto.protobuf.collab.client.YieldPresenter} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.YieldPresenter, 19));
};


/**
 * @param {?proto.protobuf.collab.client.YieldPresenter|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setYieldPresenter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearYieldPresenter = function() {
  return this.setYieldPresenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasYieldPresenter = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Thumbnail thumbnail = 20;
 * @return {?proto.protobuf.collab.client.Thumbnail}
 */
proto.protobuf.collab.client.Message.prototype.getThumbnail = function() {
  return /** @type{?proto.protobuf.collab.client.Thumbnail} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.Thumbnail, 20));
};


/**
 * @param {?proto.protobuf.collab.client.Thumbnail|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setThumbnail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearThumbnail = function() {
  return this.setThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasThumbnail = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional AddTemplatePage add_template_page = 21;
 * @return {?proto.protobuf.collab.client.AddTemplatePage}
 */
proto.protobuf.collab.client.Message.prototype.getAddTemplatePage = function() {
  return /** @type{?proto.protobuf.collab.client.AddTemplatePage} */ (
    jspb.Message.getWrapperField(this, proto.protobuf.collab.client.AddTemplatePage, 21));
};


/**
 * @param {?proto.protobuf.collab.client.AddTemplatePage|undefined} value
 * @return {!proto.protobuf.collab.client.Message} returns this
*/
proto.protobuf.collab.client.Message.prototype.setAddTemplatePage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.protobuf.collab.client.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobuf.collab.client.Message} returns this
 */
proto.protobuf.collab.client.Message.prototype.clearAddTemplatePage = function() {
  return this.setAddTemplatePage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobuf.collab.client.Message.prototype.hasAddTemplatePage = function() {
  return jspb.Message.getField(this, 21) != null;
};


goog.object.extend(exports, proto.protobuf.collab.client);
