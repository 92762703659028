import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { getParticipantColor, getParticipantName } from 'client/common/participant-names';
import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { Team } from 'client/common/graphql';

import { Buttons, FieldForm } from '../my-account/modals-styled';
import { TeamBubble } from '../styled';

import * as Styled from './create-team-styled';

interface Props {
  onClose: () => void;
  onEdit: (teamId: string, name: string, description: string) => void;
  team: Team;
}

const EditTeam: React.FC<Props> = ({ onClose, onEdit, team }) => {
  const [name, setName] = useState(team.name);
  const [description, setDescription] = useState(team.description);

  const { disableClipBoarding } = useActions();

  useHotkeys('esc', () => {
    onClose();
  });

  useEffect(() => {
    disableClipBoarding('EditTeam', true);
    return () => {
      disableClipBoarding('EditTeam', false);
    };
  }, []);

  const next = useCallback(
    (e) => {
      e.preventDefault();

      onEdit(team.id, name, description);
    },
    [name, description]
  );

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container>
        <Styled.Title>Edit Workspace</Styled.Title>
        <FieldForm>
          <form onSubmit={next}>
            <div style={{ display: 'block' }}>
              <label htmlFor="team-name-input">Workspace Name</label>
              <div style={{ position: 'relative' }}>
                <TeamBubble color={name && getParticipantColor(name)}>
                  {name ? getParticipantName(name) : ''}
                </TeamBubble>
                <Styled.TeamInput
                  type="text"
                  name="team-name"
                  id="team-name-input"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  maxLength={60}
                />
              </div>
              <label htmlFor="team-description">Description (optional)</label>
              <textarea
                style={{ resize: 'none' }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="team-description"
                name="team-description"
              />
            </div>
            <Buttons>
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
                className="cancel"
              >
                Cancel
              </button>
              <button type="submit">Done</button>
            </Buttons>
          </form>
        </FieldForm>
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default EditTeam;
