import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export {
  AuthProvider,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getRedirectResult,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup,
  updatePassword,
  updateProfile,
} from 'firebase/auth';

const firebase = initializeApp(window.INITIAL_STATE.firebaseConfig);

export const getFirebaseAuth = () => getAuth(firebase);
