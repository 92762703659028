import React, { useEffect, useState, useRef, useCallback } from 'react';

import { useSelector } from 'client/hooks/useSelector';
import { useActions } from 'client/hooks/useActions';

import * as Styled from './styled';

interface Props {
  hidden: boolean;
}

const Notifications: React.FC<Props> = ({ hidden }) => {
  const { room, notifications } = useSelector((state) => ({
    room: state.room,
    notifications: state.notifications,
  }));

  const [show, setShow] = useState(true);
  const notificationTimeouts = useRef([]);
  const { removeNotification, clearNotifications } = useActions();

  const handleTimeout = useCallback(
    (id) => {
      setShow(false);

      // Remove timeout
      const timeout = notificationTimeouts.current.find((t) => t.id === id);
      clearTimeout(timeout.timeoutId);
      notificationTimeouts.current = notificationTimeouts.current.filter((t) => t.id !== id);
    },
    [notificationTimeouts, show]
  );

  const handleAnimationEnd = useCallback(
    (id) => {
      if (show) {
        notificationTimeouts.current.push({
          id,
          timeoutId: setTimeout(() => {
            handleTimeout(id);
          }, 3000),
        });
      } else {
        removeNotification(id);
        setShow(true);
      }
    },
    [notificationTimeouts, show]
  );

  const clearQueue = useCallback(() => {
    if (document.visibilityState === 'visible') {
      setShow(false);
      clearNotifications();
      notificationTimeouts.current.forEach((entry) => {
        clearTimeout(entry.timeoutId);
      });
      setShow(true);
    }
  }, [notificationTimeouts, show]);

  useEffect(() => {
    document.addEventListener('visibilitychange', clearQueue);

    return () => {
      notificationTimeouts.current.forEach((entry) => {
        clearTimeout(entry.timeoutId);
      });
      document.removeEventListener('visibilitychange', clearQueue);
    };
  }, []);

  const [notificationToShow] = notifications;

  if (hidden || !notificationToShow) {
    return null;
  }

  return (
    <>
      {notificationToShow.sticky ? (
        <Styled.StickyNotification>
          <Styled.CloseButton onClick={() => removeNotification(notificationToShow.id)} />
          {notificationToShow.content}
          {notificationToShow.action && (
            <button
              className="action"
              onClick={() => {
                notificationToShow.action();
                removeNotification(notificationToShow.id);
              }}
            >
              {notificationToShow.actionText}
            </button>
          )}
        </Styled.StickyNotification>
      ) : (
        <Styled.Notification
          room={room}
          className={show ? 'in' : 'out'}
          onAnimationEnd={() => {
            handleAnimationEnd(notificationToShow.id);
          }}
        >
          {notificationToShow.content}
        </Styled.Notification>
      )}
    </>
  );
};

export default Notifications;
