import { createAction, createReducer } from '@reduxjs/toolkit';

export const assignItemCanvas = createAction('assignItemCanvas', (id: string, canvas: unknown) => ({
  payload: { id, canvas },
}));
export const deleteItemCanvas = createAction<string>('deleteItemCanvas');

export default createReducer<{ id: string; canvas: unknown }[]>([], (builder) =>
  builder
    .addCase(assignItemCanvas, (state, { payload: { canvas, id } }) =>
      state.some((n) => n.id === id)
        ? state.map((note) => (note.id === id ? { id: note.id, canvas } : note))
        : [{ id, canvas }, ...state]
    )
    .addCase(deleteItemCanvas, (state, action) => state.filter((n) => n.id !== action.payload))
);
