import clipboard from './clipboard/clipboard';
import clipboardingDisablers from './clipboard/clipboardingDisablers';
import clipboardingEnablers from './clipboard/clipboardingEnablers';
import pasteToolEnabled from './clipboard/pasteToolEnabled';
import actionListeners from './misc/actionListeners';
import ActionReducer from './misc/actionReducer';
import graphqlWsConnectedTime from './misc/graphqlWsConnectedTime';
import notifications from './notifications';
import currentStickerEdit from './papers/currentStickerEdit';
import currentStickyNoteEdit from './papers/currentStickyNoteEdit';
import currentTextBlockEdit from './papers/currentTextBlockEdit';
import itemCanvases from './papers/itemCanvases';
import lastStickyNoteProps from './papers/lastStickyNoteProps';
import lastTextBlockProps from './papers/lastTextBlockProps';
import localItems from './papers/localItems';
import selectedPapers from './papers/selectedPapers';
import roomLoadingMessage from './room/roomLoadingMessage';
import forceFollow from './session/forceFollow';
import initialLoadingDone from './session/initialLoadingDone';
import isFollower from './session/isFollower';
import isSessionSocketConnected from './session/isSessionSocketConnected';
import isViewOnlyMode from './session/isViewOnlyMode';
import loadedFileInfo from './session/loadedFileInfo';
import roomPin from './session/roomPin';
import session from './session/session';
import allowMobile from './settings/allowMobile';
import appServerUrl from './settings/appServerUrl';
import collaborationServerUrl from './settings/collaborationServerUrl';
import displaySignatureByDefault from './settings/displaySignatureByDefault';
import featureFlags from './settings/featureFlags';
import hasAgreedToTermsAndPolicy from './settings/hasAgreedToTermsAndPolicy';
import isMsTeamsApp from './settings/isMsTeamsApp';
import isTeamsRoom from './settings/isTeamsRoom';
import requireUserConsent from './settings/requireUserConsent';
import revision from './settings/revision';
import room from './settings/room';
import signatureText from './settings/signatureText';
import tenant from './settings/tenant';
import tenantId from './settings/tenantId';
import userRole from './settings/userRole';
import zCanvasDebugMode from './settings/zCanvasDebugMode';
import canUndoRedo from './tools/canUndoRedo';
import currentZAction from './tools/currentZAction';
import hasActivePen from './tools/hasActivePen';
import readOnly from './views/readOnly';
import showDisconnectDialog from './views/showDisconnectDialog';
import showFeedbackModal from './views/showFeedbackModal';
import showFilterSearchField from './views/showFilterSearchField';
import showGridView from './views/showGridView';
import showInviteModal from './views/showInviteModal';
import showItemStack from './views/showItemStack';
import showRenameDialog from './views/showRenameDialog';
import showSessionEndedDialog from './views/showSessionEndedDialog';
import startscreenVisibility from './views/startscreenVisibility';
import version from './misc/version';

export default {
  ActionReducer,
  actionListeners,
  allowMobile,
  appServerUrl,
  canUndoRedo,
  clipboardingDisablers,
  clipboardingEnablers,
  clipboard,
  collaborationServerUrl,
  currentStickerEdit,
  currentStickyNoteEdit,
  currentTextBlockEdit,
  currentZAction,
  displaySignatureByDefault,
  featureFlags,
  forceFollow,
  graphqlWsConnectedTime,
  hasActivePen,
  hasAgreedToTermsAndPolicy,
  initialLoadingDone,
  isFollower,
  isMsTeamsApp,
  isSessionSocketConnected,
  isTeamsRoom,
  isViewOnlyMode,
  itemCanvases,
  lastStickyNoteProps,
  lastTextBlockProps,
  loadedFileInfo,
  localItems,
  notifications,
  pasteToolEnabled,
  readOnly,
  requireUserConsent,
  revision,
  room,
  roomLoadingMessage,
  roomPin,
  selectedPapers,
  session,
  showDisconnectDialog,
  showFeedbackModal,
  showFilterSearchField,
  showGridView,
  showInviteModal,
  showItemStack,
  showRenameDialog,
  showSessionEndedDialog,
  signatureText,
  startscreenVisibility,
  tenant,
  tenantId,
  userRole,
  version,
  zCanvasDebugMode,
};
