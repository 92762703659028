import styled from 'styled-components';

export const AdminUsersIcon = styled.img.attrs({
  src: '/icons/icn_admin_users.svg',
  alt: ' ',
})``;

export const AdminRoomsIcon = styled.img.attrs({
  src: '/icons/icn_admin_rooms.svg',
  alt: ' ',
})``;

export const AdminIntegrationTeamsIcon = styled.img.attrs({
  src: '/icons/icn_microsoft_365.svg',
  alt: ' ',
})``;

export const AdminIntegrationOktaIcon = styled.img.attrs({
  src: '/icons/icn_okta.png',
  alt: ' ',
})``;

export const AdminIntegrationGoogleIcon = styled.img.attrs({
  src: '/icons/icn_google.png',
  alt: ' ',
})``;

export const AdminIntegrationZoomIcon = styled.img.attrs({
  src: '/icons/icn_zoom.png',
  alt: ' ',
})``;

export const AdminIntegrationWebexIcon = styled.img.attrs({
  src: '/icons/icn_webex.png',
  alt: ' ',
})``;

export const AdminIntegrationEnabledIcon = styled.img.attrs({
  src: '/icons/icn_integration_enabled.svg',
  alt: ' ',
})``;

export const AdminBindingIcon = styled.img.attrs({
  src: '/icons/icn_binding.svg',
  alt: ' ',
})``;
