import styled from 'styled-components';

export const ModalBackDrop = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  backdrop-filter: blur(3px);

  &.active {
    display: block;
  }
`;

export const CheckboxPanel = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

export const Button = styled.div`
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin: 0 10px;
  box-shadow: 1px 1px 0 #4e9c77, 2px 2px 4px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-weight: 500;
  background: #63c199;
  padding: 8px 16px;
  user-select: none;
  text-overflow: clip;
  white-space: nowrap;

  &:hover {
    background-color: #58af87;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 32px;
`;

export const DownloadProgress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 150ms linear;
  background: #47a37c;
  border-radius: 1px;
`;
