import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';

import Pin from 'client/components/Pin';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  height: 48px;
  right: 40px;
  padding: 8px 16px;
  color: #242424;
  background-color: white;
  border-radius: 0 0 7px 7px;
  font-size: 23px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16), 0 0 0 0.5px rgba(0, 0, 0, 0.08);

  animation: slide-in--board-code-bar 500ms ease;
  @keyframes slide-in--board-code-bar {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  &.room {
    width: 156px;
    height: 56px;
    font: normal normal normal 30px/32px Open Sans;
    padding: 8px 16px 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface Props {
  room?: boolean;
  onClick?: () => void;
}

const BoardCodeBar: React.FC<Props> = ({ room, onClick }) => (
  <Wrapper className={classNames({ room })} onClick={onClick}>
    <Pin />
  </Wrapper>
);

export default BoardCodeBar;
