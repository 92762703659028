import { useSubscription } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { useSelector } from 'client/hooks/useSelector';

export default (subscription: TypedDocumentNode) => {
  const websockettime = useSelector((state) => state.graphqlWsConnectedTime);
  const { data } = useSubscription(subscription, { variables: { websockettime } });

  return data;
};
