import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

import { currentStickyNoteEdit } from '../papers/currentStickyNoteEdit';
import { currentTextBlockEdit } from '../papers/currentTextBlockEdit';
import { setShowFeedbackModal } from '../views/showFeedbackModal';
import { setShowGridView } from '../views/showGridView';

export const disableClipBoarding = createAction('disableClipBoarding', (id: string, disable: boolean) => ({
  payload: { id, disable },
}));

const add = (state: string[], item: string) => (state.includes(item) ? state : [...state, item]);
const remove = (state: string[], item: string) => state.filter((i) => i !== item);

const addOrRemove = (state: string[], { payload, type }: PayloadAction<unknown>) =>
  payload ? add(state, type) : remove(state, type);

export default createReducer<string[]>([], (builder) =>
  builder
    .addCase(disableClipBoarding, (state, { payload: { disable, id } }) =>
      disable ? add(state, id) : remove(state, id)
    )
    .addCase(currentStickyNoteEdit, addOrRemove)
    .addCase(currentTextBlockEdit, addOrRemove)
    .addCase(setShowFeedbackModal, addOrRemove)
    .addCase(setShowGridView, addOrRemove)
);
