import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 32px;
  display: flex;
`;

const Logo = styled.div`
  width: 140px;
  height: 48px;
  background: url(/images/FFB_Logo.svg) no-repeat;
  background-size: contain;
  flex-direction: column;
`;

export const MobilePrimaryButton = styled.div`
  text-decoration: none;
  text-align: center;
  background: #279ae7;
  color: #fff;
  border-radius: 40px;
  padding: 0 16px;
  height: 48px;
  border: none;
  outline: none;
  box-shadow: 0 8px 16px #279ae740;
  margin-left: auto;
  letter-spacing: -0.32px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;

  a {
    margin: 0;
    padding: 0;
    color: #fff;
    min-width: 80px;
  }
`;

export const BackButton = styled.button.attrs({
  type: 'button',
})`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #0000001a url(/icons/icn_back.svg) center center no-repeat;
  order: -1;
  border: none;
  margin-right: 30px;
`;

const MobileTopBar: React.FC = ({ children }) => (
  <Container>
    <Logo />
    {children}
  </Container>
);

export default MobileTopBar;
