export const ForegroundColors = ['#242424', '#0887DB', '#01C270', '#DF0B0B', '#FFFFFF'];

export const BackgroundColors = ['#00000000', '#FFFFFF', '#242424', '#279AE7', '#01C270', '#DF0B0B'];

export const OutlineColors = ['#00000000', '#242424', '#0887DB', '#01C270', '#DF0B0B'];

export const Styles = [
  { textColor: 0, color: 0, outlineColor: 0 },
  { textColor: 0, color: 1, outlineColor: 1 },
  { textColor: 1, color: 1, outlineColor: 2 },
  { textColor: 2, color: 1, outlineColor: 3 },
  { textColor: 3, color: 1, outlineColor: 4 },
  { textColor: 0, color: 1, outlineColor: 0 },
  { textColor: 4, color: 2, outlineColor: 0 },
  { textColor: 4, color: 3, outlineColor: 0 },
  { textColor: 4, color: 4, outlineColor: 0 },
  { textColor: 4, color: 5, outlineColor: 0 },
];
