import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import * as Styled from './styled';

type Props = {
  heading: JSX.Element | string;
  loading?: boolean;
};

export const CollapsiblePanel: React.FC<Props> = ({ heading, loading, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const panelRef = useRef<HTMLUListElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  const toggle = () => {
    const panel = panelRef.current;
    const wrapper = wrapperRef.current;

    if (!collapsed) {
      panel.style.maxHeight = `${panel.clientHeight}px`;
      wrapper.style.overflow = 'hidden';
    } else {
      setTimeout(() => {
        wrapper.style.overflow = 'visible';
        panel.style.maxHeight = null;
      }, 300);
    }

    // Gives a short delay to allow the CSS above to render before we collapse
    setTimeout(() => setCollapsed(!collapsed), 50);
  };

  return (
    <Styled.Panel ref={panelRef} className={collapsed ? 'collapsed' : ''}>
      <Styled.Header>
        {children && !collapsed && <Styled.CollapseButton onClick={toggle} />}
        {heading}
      </Styled.Header>
      <Styled.Wrapper ref={wrapperRef}>
        {children}
        {!loading && children && (
          <Styled.Footer>
            <Styled.CollapseButton className={classNames({ collapsed })} onClick={toggle} />
          </Styled.Footer>
        )}
      </Styled.Wrapper>
    </Styled.Panel>
  );
};
