import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { ModalBackDrop, ModalContainer } from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import useCodeInput from 'client/hooks/useCodeInput';
import { getFirebaseAuth } from 'client/common/firebase';
import * as rest from 'client/services/rest';
import { RoomUser } from 'client/components/Dashboard/admin/types';

import * as Styled from './styled';

interface Props {
  onRoomAdded: (roomUser: RoomUser) => void;
  onClose: () => void;
}

const AddRoomDialog: React.FC<Props> = ({ onRoomAdded, onClose }) => {
  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    tenant: state.tenant,
    collaborationServerUrl: state.collaborationServerUrl,
  }));

  const { disableClipBoarding } = useActions();

  const { code, codeInput, handleCodeChange, hint: codeHint } = useCodeInput();

  const [name, setName] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(false);

  useHotkeys('esc', () => {
    onClose();
  });

  useEffect(() => {
    disableClipBoarding('AddRoomDialog', true);
    return () => {
      disableClipBoarding('AddRoomDialog', false);
    };
  }, []);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  const addRoom = useCallback(
    async (e: React.FormEvent) => {
      console.log('1');
      e.preventDefault();

      if (!name) {
        return;
      }

      console.log('2');

      if (codeHint !== 'valid') {
        return;
      }

      console.log('3');

      try {
        setDisabled(true);
        const token = await getFirebaseAuth().currentUser.getIdToken();

        const response = await rest.makeRequest<RoomUser>({
          method: 'POST',
          url: `${collaborationServerUrl}/tenant/${tenant.id}/rooms`,
          authorizationToken: { auth: token },
          data: { code, name },
        });

        onRoomAdded(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setDisabled(false);
      }
    },
    [collaborationServerUrl, onRoomAdded, code, name]
  );

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <ModalContainer>
        <Styled.DialogTitle>Add Room</Styled.DialogTitle>
        <Styled.DialogForm onSubmit={addRoom}>
          <div>
            <Styled.InputGroup>
              <Styled.Label>Enter the Room Code</Styled.Label>
              <Styled.CodeInput ref={codeInput} onChange={handleCodeChange} disabled={disabled} />
            </Styled.InputGroup>
            <Styled.InputGroup>
              <Styled.Label>Enter a Room Name</Styled.Label>
              <Styled.Input
                value={name}
                onChange={onNameChange}
                disabled={disabled}
                placeholder="Eg. The Big Conference Room"
              />
            </Styled.InputGroup>
          </div>
          <Styled.DialogButtonContainer>
            <Styled.Button onClick={() => onClose()} disabled={disabled}>
              Cancel
            </Styled.Button>
            <Styled.PrimaryButton type="submit" disabled={disabled}>
              Add
            </Styled.PrimaryButton>
          </Styled.DialogButtonContainer>
        </Styled.DialogForm>
      </ModalContainer>
    </>,
    document.getElementById('modal-portal')
  );
};

export default AddRoomDialog;
