import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import * as analytics from 'client/common/analytics';
import {
  File,
  MUTATION_CREATE_TEAM,
  MUTATION_INVITE_TO_TEAM,
  MUTATION_REMOVE_FROM_TEAM,
  MUTATION_REMOVE_INVITE_FROM_TEAM,
  MUTATION_UPDATE_TEAM,
  QUERY_TEAMS,
  QUERY_TEAM_INVITES,
} from 'client/common/graphql';
import { useSelector } from 'client/hooks/useSelector';

import * as Styled from '../styled';

import CreateTeam from './create-team';
import EditTeam from './edit-team';
import MyBoards from './my-boards';
import TeamBoards from './team-boards';
import TeamInviteDialog from './team-invite-dialog';
import TeamMembers from './team-members';

type SortOrder =
  | 'SORTINGDATE_ASC'
  | 'SORTINGDATE_DESC'
  | 'LASTMODIFIED_ASC'
  | 'LASTMODIFIED_DESC'
  | 'FILENAME_ASC'
  | 'FILENAME_DESC';

interface Props {
  allowedToCreateFile: { allowed: boolean; limit?: number };
  deleteBoard: (f: File) => void;
  duplicateBoard: (boardId: string) => void;
  removeLink: (link: unknown) => void;
  renameBoard: (f: File) => void;
  setShowMoveTo: (f: { fileId: string; teamId?: string; link?: boolean }) => void;
  setShowShareDialog: (options: { fileId: string; fileName: string; showMove: boolean; teamId?: string }) => void;
  showCastDialog: (e: React.MouseEvent<HTMLSpanElement>, file: File) => void;
  showMoveTo: () => void;
  showShareDialog: () => void;
}

const MyBoardsIndex: React.FC<Props> = ({
  allowedToCreateFile,
  deleteBoard,
  duplicateBoard,
  removeLink,
  renameBoard,
  setShowMoveTo,
  setShowShareDialog,
  showCastDialog,
}) => {
  const { featureFlags } = useSelector((state) => ({
    featureFlags: state.featureFlags,
  }));

  const [selectedTeamId, setSelectedTeamId] = useState<string>(undefined);
  const [showDialog, setShowDialog] = useState<'none' | 'create' | 'members' | 'invite' | 'edit'>('none');

  const teamsQuery = useQuery(QUERY_TEAMS);
  const [fetchInvites, { data: invites, refetch: refetchInvites }] = useLazyQuery(QUERY_TEAM_INVITES);
  const [sort, setSort] = useState<SortOrder>('SORTINGDATE_DESC');

  useEffect(() => {
    if (selectedTeamId) {
      fetchInvites({
        variables: { input: { teamId: selectedTeamId } },
      });
    }
  }, [selectedTeamId]);

  const selectedTeam = teamsQuery.data?.teams.teams.find((t) => t.id === selectedTeamId);

  const [createTeam] = useMutation(MUTATION_CREATE_TEAM);
  const [inviteToTeam] = useMutation(MUTATION_INVITE_TO_TEAM);
  const [removeFromTeam] = useMutation(MUTATION_REMOVE_FROM_TEAM);
  const [removeInviteFromTeamMutation] = useMutation(MUTATION_REMOVE_INVITE_FROM_TEAM);
  const [updateTeam] = useMutation(MUTATION_UPDATE_TEAM);

  const handleCreate = async (name: string, description: string, emails: string[]) => {
    try {
      const response = await createTeam({ variables: { input: { name, description } } });

      if (response.data?.createTeam?.success) {
        const teamId = response.data.createTeam.team.id;
        await inviteToTeam({ variables: { input: { emails, teamId } } });

        analytics.createTeam(emails.length);
        setShowDialog('none');
        await teamsQuery.refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async (teamId: string, name: string, description: string) => {
    try {
      const response = await updateTeam({ variables: { input: { teamId, name, description } } });

      if (response.data?.updateTeam?.success) {
        setShowDialog('none');
      }
      await teamsQuery.refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleInviteToTeam = async (emails: string[], teamId: string) => {
    try {
      await inviteToTeam({ variables: { input: { emails, teamId } } });
      setShowDialog('none');
      analytics.inviteToTeam(emails.length);
      await refetchInvites();
    } catch (error) {
      console.error(error);
    }
  };

  const removeUserFromTeam = async (userId: string, teamId: string) => {
    try {
      await removeFromTeam({
        variables: {
          input: { teamId, userId },
        },
      });
      analytics.removeUserFromTeam();
      await teamsQuery.refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const removeInviteFromTeam = async (teamId: string, email: string) => {
    try {
      await removeInviteFromTeamMutation({
        variables: {
          input: { teamId, email },
        },
      });
      await refetchInvites();
    } catch (error) {
      console.error(error);
    }
  };

  const teams = teamsQuery.data?.teams.teams ?? [];
  const allowedToCreateTeam = teamsQuery.data?.allowedToCreateTeam;

  return (
    <>
      {showDialog === 'create' && <CreateTeam onCreate={handleCreate} onClose={() => setShowDialog('none')} />}
      {showDialog === 'edit' && (
        <EditTeam team={selectedTeam} onEdit={handleEdit} onClose={() => setShowDialog('none')} />
      )}
      {showDialog === 'members' && (
        <TeamMembers
          removeUserFromTeam={removeUserFromTeam}
          removeInviteFromTeam={removeInviteFromTeam}
          showInvite={() => setShowDialog('invite')}
          team={selectedTeam}
          invites={invites?.teamInvites.emails ?? []}
          onClose={() => setShowDialog('none')}
        />
      )}
      {showDialog === 'invite' && (
        <TeamInviteDialog team={selectedTeam} invite={handleInviteToTeam} onClose={() => setShowDialog('none')} />
      )}
      <Styled.MainColumn>
        <Styled.MyBoardsGroupHeader>
          <Styled.HeaderTitle>Boards</Styled.HeaderTitle>
          <Styled.HeaderExpander />
          {featureFlags.enableTeams && (
            <>
              <Styled.HeaderMoreButton
                className={classNames({ disabled: !allowedToCreateTeam?.allowed })}
                data-tip={`Maximum number of Workspaces (${allowedToCreateTeam?.limit}) reached`}
                data-tip-disable={allowedToCreateTeam?.allowed}
                data-place="top"
                data-delay-show={300}
                data-effect="solid"
                id="workspace-create-button"
                onClick={() => {
                  if (allowedToCreateTeam?.allowed) {
                    setShowDialog('create');
                  }
                }}
              >
                <span>
                  <img src="/icons/icn_new_team.svg" alt="Create Workspace" />
                  Create Workspace
                </span>
              </Styled.HeaderMoreButton>
            </>
          )}
          <Styled.HeaderMoreButton
            className={classNames({ disabled: !allowedToCreateFile?.allowed })}
            data-tip={
              allowedToCreateFile.limit
                ? `Maximum number (${allowedToCreateFile.limit}) of boards reached`
                : 'Not allowed to create new board'
            }
            data-tip-disable={allowedToCreateFile.allowed}
            data-place="top"
            data-delay-show={300}
            data-effect="solid"
          >
            <Link id="open-from-computer" to="/dashboard/my-boards/upload">
              <img src="/icons/icn_upload_file.svg" alt="Upload" />
              Upload from computer
            </Link>
          </Styled.HeaderMoreButton>
        </Styled.MyBoardsGroupHeader>
        <MyBoards
          allowedToCreateFile={allowedToCreateFile}
          deleteBoard={deleteBoard}
          duplicateBoard={duplicateBoard}
          removeLink={removeLink}
          renameBoard={renameBoard}
          setSort={setSort}
          showCastDialog={showCastDialog}
          showMoveTo={(fileId) => setShowMoveTo({ fileId })}
          showShareDialog={(fileId, fileName, showMove) => setShowShareDialog({ fileId, fileName, showMove })}
          sort={sort}
        />
        <TeamBoards
          loading={teamsQuery.loading}
          showShareDialog={(fileId, fileName, showMove, teamId) =>
            setShowShareDialog({ fileId, fileName, showMove, teamId })
          }
          showMoveTo={(fileId, teamId) => setShowMoveTo({ fileId, teamId, link: false })}
          teams={teams}
          createTeam={() => setShowDialog('create')}
          showTeamMembers={(team) => {
            setSelectedTeamId(team.id);
            setShowDialog('members');
          }}
          showEditTeam={(team) => {
            setSelectedTeamId(team.id);
            setShowDialog('edit');
          }}
          showInvite={(team) => {
            setSelectedTeamId(team.id);
            setShowDialog('invite');
          }}
          allowedToCreateFile={allowedToCreateFile}
          deleteBoard={deleteBoard}
          duplicateBoard={duplicateBoard}
          renameBoard={renameBoard}
          showCastDialog={showCastDialog}
        />
      </Styled.MainColumn>
    </>
  );
};

export default MyBoardsIndex;
