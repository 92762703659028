import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { QUERY_LINK, QUERY_FILE_INFO } from 'client/common/graphql';
import { CONNECTION_STATUS } from 'client/common/util';
import LoadingBoard from 'client/components/Whiteboard/LoadingBoard';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useResetConnectionAndSession } from 'client/hooks/useResetConnectionAndSession';
import { useSelector } from 'client/hooks/useSelector';

import { GlobalStyle } from './styled';
import * as Styled from './styled';

const ErrorView: React.FC = () => {
  const { session } = useSelector((state) => ({
    session: state.session,
  }));

  const { tryConnectNew } = useActions();

  const history = useHistory();
  const auth = useAuth();
  const location = useLocation<{ boardInfo?: { id: string; link: string } }>();

  const [showLoadingSession, setShowLoadingSession] = useState(false);
  const [getLink, queryLink] = useLazyQuery(QUERY_LINK, {
    variables: { linkId: location.state?.boardInfo?.id },
  });

  const [getFile, queryFile] = useLazyQuery(QUERY_FILE_INFO, {
    variables: { fileId: location.state?.boardInfo?.id },
  });

  useEffect(() => {
    if (!auth.loading) {
      if (auth.user) {
        if (location.state?.boardInfo?.link) {
          getLink();
        } else {
          getFile();
        }
      }
    }
  }, [auth.user, auth.loading]);

  const resetConnectionAndSession = useResetConnectionAndSession();

  useEffect(() => {
    resetConnectionAndSession();
  }, []);

  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting && showLoadingSession) {
      history.replace('/session');
    } else if (session?.status === CONNECTION_STATUS.failed) {
      setShowLoadingSession(false);
    }
  }, [session]);

  if (queryLink?.loading || queryFile?.loading || auth.loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      <GlobalStyle />
      <Styled.InfoContainer>
        <img src="/images/ill_cloud_failed.svg" width={80} alt="FlatFrog Board" />
        <h3>Something went wrong, try to open the file again</h3>
        <Styled.InfoButton
          onClick={() => {
            if (queryFile.data) {
              setShowLoadingSession(true);
              tryConnectNew({ fileInfo: { id: queryFile.data.file.id } });
            } else {
              const link = `/boards/link/${location.state?.boardInfo?.id}`;
              history.replace(link);
            }
          }}
        >
          Try again
        </Styled.InfoButton>
      </Styled.InfoContainer>
      {showLoadingSession && <LoadingBoard />}
    </>
  );
};

export default ErrorView;
