import styled from 'styled-components';

export const Canvas = styled.canvas.attrs({
  id: 'zcanvas',
  width: '1920px',
  height: '1080px',
})`
  display: block;
  touch-action: none;
  user-select: none;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  visibility: visible;
`;

export const WhiteboardWrapper = styled.div`
  height: 100%;
  background-color: transparent;
`;
