import * as microsoftTeams from '@microsoft/teams-js';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Context as TeamsContext } from '@microsoft/teams-js';

import * as analytics from 'client/common/analytics';
import { useActions } from 'client/hooks/useActions';
import { makeRequest } from 'client/services/rest';
import { getFirebaseAuth } from 'client/common/firebase';
import { useAuth } from 'client/hooks/useAuth';

import MeetingSidePanel from './MeetingSidePanel';

const TeamsTab: React.FC = () => {
  const { setMsTeamsApp } = useActions();

  const history = useHistory();
  const auth = useAuth();

  const [context, setContext] = useState<TeamsContext | undefined>(undefined);
  microsoftTeams.initialize();

  const urlParams = new URLSearchParams(window.location.search);
  const boardLink = urlParams.get('boardLink');
  const name = urlParams.get('name');
  const link = `/boards/link/${boardLink}`;

  useEffect(() => {
    if (auth.loading) {
      return;
    }

    const addBotToCall = async (tenantId: string, chatId: string, _boardLink: string) => {
      const token = await getFirebaseAuth().currentUser.getIdToken();
      return makeRequest({
        method: 'POST',
        url: `${window.INITIAL_STATE.collaborationServerUrl}/i/teams/call`,
        data: { tenantId, chatId, boardLink: _boardLink },
        authorizationToken: { auth: token },
      });
    };

    if (boardLink) {
      if (context.frameContext === 'content') {
        if (context.meetingId) {
          analytics.setIntegrationContext('teams:meetingTab');
        } else if (context.channelId) {
          analytics.setIntegrationContext('teams:channelTab');
        } else {
          analytics.setIntegrationContext('teams:chatTab');
        }
        history.replace(`${link}?teams=true`);
      } else if (context.frameContext === 'meetingStage') {
        analytics.setIntegrationContext('teams:meetingStage');
        addBotToCall(context.tid, context.chatId, boardLink)
          .then(() => {
            history.replace(`${link}?teams=true`);
          })
          .catch((e) => {
            console.error('Unable to add bot to Teams call!', e);
            history.replace(`${link}?teams=true`);
          });
      }
    }
  }, [context, auth.loading]);

  useEffect(() => {
    // Get the user context from Teams and set it in the state
    microsoftTeams.getContext((c) => {
      setMsTeamsApp();
      setContext(c);
    });
  }, []);

  // side panel in a meeting
  if (context?.frameContext === 'sidePanel') {
    analytics.setIntegrationContext('teams:sidePanel');
    return <MeetingSidePanel name={name} boardLink={boardLink} link={link} />;
  }
  return null;
};

export default TeamsTab;
