import { createAction, createReducer } from '@reduxjs/toolkit';

import { TextAlignments } from 'client/components/Common/text-alignments';

export type StickyNoteToEdit =
  | null
  | { id: string }
  | { new: true; saveExpected?: boolean }
  | {
      paperId?: number;
      textSize?: number;
      backgroundColor?: string;
      textColor?: string;
      content?: string;
      textAlign?: TextAlignments;
      signature?: string;
      link?: string;
    };

export const currentStickyNoteEdit = createAction<StickyNoteToEdit>('currentStickyNoteEdit');

export default createReducer<StickyNoteToEdit>(null, (builder) =>
  builder.addCase(currentStickyNoteEdit, (state, action) => action.payload)
);
