import styled from 'styled-components';

export const MenuContainer = styled.div.attrs({
  className: 'menu-container',
})`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 220px;
  background: #ffffff;
  padding: 8px 0;
  border-radius: 6px;
  box-shadow: 0 0 0 0.5px #00000014, 0 4px 8px #00000029;
  text-align: left;
  color: #242424;
  user-select: none;
`;

export const MenuItemGroup = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid #00000029;
  }
`;

export const ContextMenu = styled.div`
  &.active {
    ${MenuContainer} {
      display: block;
      z-index: 2;
    }
  }
`;

export const MenuItem = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  border: none;
  padding: 11px 24px;
  background: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  position: relative;
  color: #242424;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      background: #fff;
    }
  }

  &:hover {
    background: #f4f4f4;
  }

  &.option {
    &::before {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      content: '';
    }

    &.selected {
      &::before {
        background: url(/icons/icn_menu_checked.svg) center center no-repeat;
        background-size: 16px 16px;
      }
    }
  }
`;
