import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: none;
  position: absolute;
  top: -8px;
  left: calc(100% + 14px);
  min-width: 260px;
  background: #ffffff;
  padding: 8px 0;
  border-radius: 6px;
  box-shadow: 0 0 0 0.5px #00000014, 0 4px 8px #00000029;
  text-align: left;
  color: #242424;
  z-index: 2;

  &.is-mobile {
    bottom: 8px;
    top: auto;
    left: 50%;
    transform: translate(-50%, 0);
    position: fixed;
    width: 96vw;
    border-radius: 16px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.16), 0 0 0 0.5px #00000014;
    padding: 16px 8px;
    z-index: 9;
  }
`;

export const MenuGroup = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid #00000029;
  }
  padding: 4px 0;

  &:empty {
    display: none;
  }
`;

export const MenuItemExternalLinkIcon = styled.img`
  margin: 0 0 0 0.5em;
  height: 1em;
`;

export const MenuItem = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  border: none;
  padding: 10px 20px;
  background: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  position: relative;
  color: #242424;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background: #fff;
    }
  }

  &:hover {
    background: #f4f4f4;
  }

  > img {
    height: 24px;
    margin-right: 8px;
  }

  > ${MenuItemExternalLinkIcon} {
    margin: 0 0 0 0.5em;
    height: 1em;
  }
`;

export const FileNameMenuItemContainer = styled(MenuItem)`
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    background: #fff;
  }
`;

export const FileStatus = styled.div<{ blobColor: string }>`
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  max-width: 260px;
  display: block;

  &:before {
    display: inline-block;
    background: ${(props) => props.blobColor};
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    content: '';
  }
`;

export const FileNameMenuItem = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #2287ca;
  max-width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Hamburger = styled.div`
  display: block;
  height: 32px;
  width: 48px;
  outline: none;
  border: none;
  background: url(/icons/icn_burger_menu.svg) center center no-repeat;
  background-size: auto;
  padding: 0;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.active {
    ${MenuContainer} {
      display: block;
    }
  }

  &.mobile-bar {
    width: 17vw;
    height: 17vw;
    max-width: 64px;
    max-height: 64px;
    margin: 0;

    &:hover,
    &:active {
      background-color: transparent;
    }
  }
`;
