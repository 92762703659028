import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export const useCurrentTime = () => {
  const [currentDateString, setCurrentDateString] = useState('');
  const [currentTimeString, setCurrentTimeString] = useState('');

  const setStrings = () => {
    const current = dayjs();

    setCurrentTimeString(current.format('HH:mm'));
    setCurrentDateString(current.format('ddd D MMM'));
  };

  useEffect(() => {
    setStrings();

    const intervalID = setInterval(setStrings, 1000);

    return () => clearInterval(intervalID);
  }, []);

  return [currentDateString, currentTimeString];
};
