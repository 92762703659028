import styled from 'styled-components';

import { FloatingPanel } from 'client/components/Common/common-styles';

export const Container = styled.div.attrs((props) => ({
  style: props.hidden ? { opacity: 0, pointerEvents: 'none' } : {},
}))`
  display: flex;
  align-items: center;
  pointer-events: all;
  ${FloatingPanel};
  width: 56px;

  &.room {
    box-shadow: none;
    background: transparent;
    position: relative;
  }
`;

export const RoomFileName = styled.div`
  position: absolute;
  left: calc(12px + 100%);
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: -0.9px;
  color: #8c8c8c;
  white-space: nowrap;
  pointer-events: none;
`;
