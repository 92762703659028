import React from 'react';

import * as DialogStyled from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';

import { ModalBackDrop, ButtonGroup, InfoText, Dots } from './styled';

const SessionDisconnectedDialog: React.FC = () => {
  const { showDisconnectDialog } = useSelector((state) => ({
    showDisconnectDialog: state.showDisconnectDialog,
  }));

  const { toggleSessionEndedDialog, toggleShowReconnectDialog } = useActions();

  return showDisconnectDialog.show ? (
    <>
      <ModalBackDrop className="active" />
      <DialogStyled.ModalContainer className="active cover" style={{ overflow: 'visible' }}>
        <DialogStyled.Title>
          Trying to Reconnect <Dots />
        </DialogStyled.Title>

        <DialogStyled.Body>Please wait. Thank you for your patience.</DialogStyled.Body>
        <ButtonGroup>
          <DialogStyled.NextButton
            id="sessionDisconnectedDialog"
            onClick={() => {
              toggleShowReconnectDialog(false);
              toggleSessionEndedDialog(true);
            }}
          >
            Leave
          </DialogStyled.NextButton>
        </ButtonGroup>
        <InfoText>You may Leave the board if you do not want to wait.</InfoText>
      </DialogStyled.ModalContainer>
    </>
  ) : null;
};

export default SessionDisconnectedDialog;
