import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import { Team } from 'client/common/graphql';

import { Buttons, FieldForm } from '../my-account/modals-styled';

import * as Styled from './create-team-styled';
import EmailTextarea from './email-textarea';

interface Props {
  invite: (emails: string[], teamId: string) => void;
  onClose: () => void;
  team: Team;
}

const TeamInviteDialog: React.FC<Props> = ({ invite, onClose, team }) => {
  const [emailError, setEmailError] = useState<boolean>();
  const [emails, setEmails] = useState<string[]>([]);

  const { disableClipBoarding } = useActions();

  const { tenant } = useSelector((state) => ({ tenant: state.tenant }));

  const emailsChanged = useCallback((value: string[]) => {
    setEmails(value);
    setEmailError(false);
  }, []);

  useHotkeys('esc', () => {
    onClose();
  });

  useEffect(() => {
    disableClipBoarding('TeamInviteDialog', true);
    return () => {
      disableClipBoarding('TeamInviteDialog', false);
    };
  }, []);

  const handleInvite = (e: React.FormEvent) => {
    e.preventDefault();

    if (emails.length === 0) {
      setEmailError(true);
    } else {
      invite(emails, team.id);
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container>
        <Styled.Title>Add people to {team.name}</Styled.Title>
        <FieldForm>
          <form onSubmit={handleInvite}>
            <div style={{ display: 'block', textAlign: 'left' }}>
              <label>
                People will be added automatically to your Workspace.
                {!tenant &&
                  ' If the email is not registered they will receive an invitation to sign up to join the Workspace.'}
              </label>
              <EmailTextarea emailsChanged={emailsChanged} existingUsers={team.members} />
              {emailError && <Styled.EmailErrors>No valid emails entered</Styled.EmailErrors>}
            </div>
            <Buttons>
              <button type="button" onClick={() => onClose()} className="cancel">
                Cancel
              </button>
              <button type="submit">Add</button>
            </Buttons>
          </form>
        </FieldForm>
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default TeamInviteDialog;
