import { createAsyncThunk } from '@reduxjs/toolkit';
import ZCanvas from '@flatfrog/ffbec';
import { isIPad13 } from 'react-device-detect';

import { findBackgroundColor } from 'client/components/StickyNote/colors';
import { csvOptions } from 'client/components/Common/types';

export const downloadCsvFile = createAsyncThunk(
  'downloadCsvFile',
  async (payload: { fileName: string; options: string[] }) => {
    const fileContent = await ZCanvas.ffb.save(true, true);

    const stickyNotes = fileContent.Actions.filter(
      (a) => a.Action === 'CreatePaperAction' && a.PaperInfo.PaperVariety === 'StickyNote'
    ).map((s) => {
      const metadata = s.PaperInfo.PaperMetadata ? JSON.parse(s.PaperInfo.PaperMetadata) : {};
      const group = s.PaperInfo.PaperId;

      let color = metadata.Color;
      if (!color) {
        const newColor = findBackgroundColor(s.PaperInfo.PaperColor);
        color = newColor.color;
      }

      return {
        id: s.PaperInfo.PaperId,
        text: metadata.Content?.replace(/"/g, '""') ?? '',
        color,
        colorName: s.PaperInfo.PaperColor,
        signature: metadata.SignatureText?.replace(/"/g, '""'),
        pageNumber: ZCanvas.page.getIndexFromUuid(s.PaperInfo.PageId) + 1,
        page: s.PaperInfo.PageId,
        locked: s.PaperInfo.AddedInBackground,
        parent,
        group,
        x: Math.round(s.PaperInfo.InitialPosition[0]),
        y: Math.round(s.PaperInfo.InitialPosition[1]),
        link: metadata.Link?.replace(/"/g, '""') ?? '',
      };
    });

    const columns: Record<string, string> = {
      text: 'text',
      signature: 'signature',
      colorName: 'color',
      pageNumber: 'page',
    };

    payload.options.forEach((opt) => {
      const option = csvOptions.find((co) => co.key === opt);

      if (option) {
        columns[option.key] = option.desc.toLowerCase();
      }
    });

    // also makes sure it's the same order of cols
    const csv = [columns, ...stickyNotes]
      .map((s: Record<string, unknown>) => {
        const keys = Object.keys(columns);
        const rowObject: Record<string, string> = {};
        for (const k of keys) {
          rowObject[k] = `"${s[k] ?? ''}"`;
        }

        // TODO: Make some nice GUI where the separator is an option in some ways
        return Object.values(rowObject).join(';');
      })
      .join('\r\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('download', payload.fileName);
    a.setAttribute('href', url);
    a.click();
    window.URL.revokeObjectURL(url);
  }
);

export const downloadFfbFile = createAsyncThunk('downloadFfbFile', async (payload: string) => {
  const fileContents = await ZCanvas.ffb.save();
  const blob = new Blob([fileContents], { type: 'application/json;charset=utf-8' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  // Append .ffb if not present
  const fileName = `${payload.replace(/^(.+)\.ffb$/i, '$1')}.ffb`;
  a.setAttribute('download', fileName);
  a.setAttribute('href', url);
  a.click();
  window.URL.revokeObjectURL(url);
});

export const downloadPdfFile = createAsyncThunk('downloadPdfFile', async (payload: string) => {
  try {
    // Append .pdf if not present
    const fileName = `${payload.replace(/^(.+)\.pdf$/i, '$1')}.pdf`;

    const { page } = ZCanvas;

    const resolution = {
      width: 1920,
      height: 1080,
    };

    const { jsPDF: JsPDF } = await import('jspdf');

    const doc = new JsPDF({
      orientation: 'landscape',
      format: [resolution.width, resolution.height],
      unit: 'pt',
    });

    const pageIds = page.getIds();

    // One initial page already present
    for (let i = 1; i < pageIds.length; i += 1) {
      doc.addPage();
    }

    for (let i = 0; i < pageIds.length; i += 1) {
      doc.setPage(i + 1);

      const imageData = ZCanvas.image.createFromPage(pageIds[i], resolution.width, resolution.height);
      const png = (
        await ZCanvas.image.encodePng({
          width: imageData.width,
          height: imageData.height,
          pixels: imageData.data,
          flipped: true,
        })
      ).data;

      doc.addImage(new Uint8Array(png), 'PNG', 0, 0, resolution.width, resolution.height, undefined, 'NONE');
      window.dispatchEvent(
        new CustomEvent('export-pdf-progress', { detail: { page: i + 1, totalPages: pageIds.length } })
      );
    }

    if (isIPad13) {
      const buffer = doc.output('arraybuffer');
      const myblob = new Blob([buffer], {
        type: 'application/octet-stream',
      });
      const url = window.URL.createObjectURL(myblob);
      const a = document.createElement('a');
      a.setAttribute('download', fileName);
      a.setAttribute('href', url);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      doc.save(fileName);
    }
  } catch (error) {
    console.error(error);
    window.dispatchEvent(new CustomEvent('export-pdf-failed', { detail: { error } }));
  }
});
