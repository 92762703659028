import React from 'react';
import styled from 'styled-components';

import * as Styled from 'client/common/codeDialog';
import { FileOrFileLink } from 'client/common/graphql';

import CastView from './CastView';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  background: #fff;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  color: #242424;

  ${Styled.Wrapper} {
    padding: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  ${Styled.Title} {
    height: 64px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    font: normal normal normal 15px/36px Open Sans;
    letter-spacing: -0.37px;
    color: #242424;
  }

  ${Styled.InfoText} {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #242424;
    flex-grow: 1;
  }

  ${Styled.CodeWrapper} {
    display: flex;
    justify-content: center;
    margin: 40px auto;
  }

  ${Styled.OpenButton} {
    width: auto;
    margin: 0 24px 12px 24px;
  }

  ${Styled.FileName} {
    line-height: 1px;
  }
`;

const CancelButton = styled(Styled.OpenButton)`
  margin-bottom: 24px;
  background: #fff !important;
  color: #242424 !important;
`;

interface Props {
  file: FileOrFileLink;
  onCancel: () => void;
  stay: false;
}

const MobileCastDialog: React.FC<Props> = ({ file, onCancel, stay }) => (
  <Wrapper>
    <CastView file={file} onCancel={onCancel} stay={stay} />
    <CancelButton onClick={onCancel}>Cancel</CancelButton>
  </Wrapper>
);

export default MobileCastDialog;
