import styled from 'styled-components';

export const ModalBackDrop = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  backdrop-filter: blur(3px);

  &.active {
    display: block;
  }
`;

export const Button = styled.div`
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin: 10 0 10 0;
  box-shadow: 1px 1px 0 #4e9c77, 2px 2px 4px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-weight: 500;
  background: #63c199;
  padding: 8px 16px;
  user-select: none;
  text-overflow: clip;
  white-space: nowrap;

  &:hover {
    background-color: #58af87;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 40px;
`;

export const InfoText = styled.div`
  margin-top: 14px;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #555555;
  font-weight: 400;
`;

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 2s infinite;
    content: '';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
  }
`;
