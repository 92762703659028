import * as microsoftTeams from '@microsoft/teams-js';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';

import * as analytics from 'client/common/analytics';
import useAnimal from 'client/common/animals';
import Layout from 'client/common/helpers/Layout';
import { getParticipantColor } from 'client/common/participant-names';
import { CONNECTION_STATUS, CONNECTION_FAIL_REASON } from 'client/common/util';
import * as Styled from 'client/components/Auth/styled';
import LoadingBoard from 'client/components/Whiteboard/LoadingBoard';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useLocalStorage from 'client/hooks/useLocalStorage';
import useLocalStorageState from 'client/hooks/useLocalStorageState';
import { useResetConnectionAndSession } from 'client/hooks/useResetConnectionAndSession';
import { useSelector } from 'client/hooks/useSelector';
import { fetchTenantByAzureAdDomain } from 'client/state/settings/fetchTenant';

import BoardCodeInfo from './boardcodeinfo';
import CookieBanner from './cookiebanner';

const Join: React.FC = () => {
  const { hasAgreedToTermsAndPolicy, localItems, session, tenant } = useSelector((state) => ({
    hasAgreedToTermsAndPolicy: state.hasAgreedToTermsAndPolicy,
    localItems: state.localItems,
    session: state.session,
    tenant: state.tenant,
  }));

  const { agreeToTermsAndPolicy, emptyLocalItems, setSignature, tryConnectNew, tryConnectWithSessionJoinKey } =
    useActions();

  const [animal, changeAnimal] = useAnimal();
  const [showBoardCodeInfo, setShowBoardCodeInfo] = useState(false);
  const [, setPreviousBoardLink] = useLocalStorageState('previousBoardLink', undefined);

  const [, setTeamsId] = useState('');
  const [, setTeamsName] = useState('');
  const [, setTeamsNameLocalStorage] = useLocalStorage('teamsNameLocalStorage', '');

  const history = useHistory();
  const location = useLocation<{
    teams?: boolean;
    sessionJoinKey?: string;
    boardLink?: string;
    backToSessionFromLogin?: boolean;
  }>();
  const auth = useAuth();

  if (!location.state?.sessionJoinKey && !location.state?.boardLink) {
    history.push('/');
    return null;
  }

  useEffect(() => {
    setSignature(animal);
  }, [animal]);

  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting) {
      history.push('/session', {
        from: 'join',
        boardLink: location.state.boardLink,
      });
    }
  }, [session]);

  const handleJoin = async () => {
    if (!auth.user) {
      await auth.signInAsGuest();
    }

    agreeToTermsAndPolicy(true);
    if (location.state.sessionJoinKey) {
      console.log('[HANDLE JOIN] Joining with session key', location.state.sessionJoinKey);
      tryConnectWithSessionJoinKey(location.state.sessionJoinKey);
    } else if (location.state.boardLink) {
      setPreviousBoardLink(location.state.boardLink);
      tryConnectNew({ fileInfo: { linkId: location.state.boardLink, link: true } });
    }
  };

  const resetConnectionAndSession = useResetConnectionAndSession();

  // Someone left the board or went from connected to here, let's reset
  useEffect(() => {
    analytics.viewPage('/join');
    resetConnectionAndSession();

    if (location.state?.backToSessionFromLogin === true) {
      handleJoin();
    }

    if (localItems.length) {
      emptyLocalItems(location.state.sessionJoinKey);
    }
  }, []);

  useEffect(() => {
    // Get teams location context
    microsoftTeams.initialize();
    microsoftTeams.getContext(async (context) => {
      if (context?.userPrincipalName) {
        setTeamsName(context?.userPrincipalName);
        setTeamsNameLocalStorage(context?.userPrincipalName);
      }

      if (context?.teamId) {
        setTeamsId(context?.teamId);
      }

      if (context?.tid) {
        const tenantByAzure = await fetchTenantByAzureAdDomain(context.tid);
        const hostname = window.location.hostname;
        if (tenantByAzure?.subdomain && !hostname.startsWith(tenantByAzure.subdomain)) {
          window.location.hostname = `${tenantByAzure.subdomain}.${hostname}`;
        }
      }
    });
  }, []);

  const failureDialog = session.failure && <LoadingBoard failure={CONNECTION_FAIL_REASON.notFound} />;

  const inMsTeams = location.state.teams;
  const loginInMSTeams = () => {
    microsoftTeams.authentication.authenticate({
      url: `/login?teams=true${tenant ? `&tenantId=${tenant.subdomain}` : ''}`,
      width: 650,
      height: 1000,
      successCallback: () => {
        console.log('Join: successcallback');
      },
      failureCallback: () => {},
    });
  };

  if (auth.user && !auth.user.guest && !auth.loading) {
    if (location.state?.boardLink && location.state?.teams) {
      const link = `/boards/link/${location.state?.boardLink}`;
      return <Redirect to={{ pathname: link }} />;
    }
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }

  return (
    <Layout
      mobileTopBarButton={
        <Link
          id="login-link"
          onClick={
            inMsTeams
              ? (e) => {
                  e.preventDefault();
                  loginInMSTeams();
                }
              : undefined
          }
          to={{
            pathname: '/login',
            state: {
              from: 'join',
              sessionJoinKey: location.state.sessionJoinKey,
              boardLink: location.state.boardLink,
            },
          }}
        >
          Sign In
        </Link>
      }
      topBarItems={
        <Styled.LoginButton>
          <Styled.PromoLabel>Get the full experience of FlatFrog Board</Styled.PromoLabel>
          <Link
            id="login-link"
            onClick={
              inMsTeams
                ? (e) => {
                    e.preventDefault();
                    loginInMSTeams();
                  }
                : undefined
            }
            to={{
              pathname: '/login',
              state: {
                from: 'join',
                sessionJoinKey: location.state.sessionJoinKey,
                boardLink: location.state.boardLink,
              },
            }}
          >
            Sign In
          </Link>
        </Styled.LoginButton>
      }
      upperMainContent={
        <>
          This link has been shared with you. You don't seem to be{' '}
          <Link
            onClick={
              inMsTeams
                ? (e) => {
                    e.preventDefault();
                    loginInMSTeams();
                  }
                : undefined
            }
            to={{
              pathname: '/login',
              state: {
                from: 'join',
                sessionJoinKey: location.state.sessionJoinKey,
                boardLink: location.state.boardLink,
              },
            }}
          >
            signed in.
          </Link>
        </>
      }
      mainContent={
        <>
          <h1>
            Hi 👋, join Board as{' '}
            <Styled.Animal id="animal-toggle" onClick={changeAnimal} color={getParticipantColor(animal)}>
              {animal}
            </Styled.Animal>
          </h1>

          <Styled.LargeJoinButton onClick={handleJoin}>Join</Styled.LargeJoinButton>

          <span className="privacy">
            By joining a board, you agree to the{' '}
            <a target="_blank" href="/privacy-policy">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a target="_blank" href="/terms-of-service">
              Terms of Service
            </a>
          </span>
        </>
      }
      lowerMainContent={
        <>
          {!inMsTeams && (
            <a href="#" onClick={() => setShowBoardCodeInfo(true)}>
              What's a Board Code?
            </a>
          )}
          <a target="_blank" href="//www.flatfrog.com/flatfrog-board">
            What's FlatFrog Board?
          </a>
          <a target="_blank" href="//help.flatfrog.com/en/knowledge/flatfrog-board">
            More Support
          </a>
        </>
      }
      footer={
        <>
          {isMobileOnly && <Styled.Filler />}
          {showBoardCodeInfo && <BoardCodeInfo onClose={() => setShowBoardCodeInfo(false)} />}
          <CookieBanner accepted={hasAgreedToTermsAndPolicy} accept={() => agreeToTermsAndPolicy(true)} />
          {failureDialog}
        </>
      }
    />
  );
};

export default Join;
