import { useMutation } from '@apollo/client';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { useEffect, useState } from 'react';

import { getFirebaseAuth } from 'client/common/firebase';
import { MUTATION_CREATE_BOARD_LINK, MUTATION_CREATE_FILE } from 'client/common/graphql';
import BoardPicker from 'client/components/Teams/BoardPicker';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { fetchTenantByAzureAdDomain } from 'client/state/settings/fetchTenant';
import { useActions } from 'client/hooks/useActions';

import { GlobalStyle } from './styled';
import * as Styled from './styled';

const TeamsConfig: React.FC = () => {
  const { tenant } = useSelector((state) => ({
    tenant: state.tenant,
  }));

  const { setMsTeamsApp } = useActions();
  const [selectedBoard, setSelectedBoard] = useState(undefined);

  const auth = useAuth();

  const [createBoardLink] = useMutation(MUTATION_CREATE_BOARD_LINK);
  const [createFile] = useMutation(MUTATION_CREATE_FILE);

  microsoftTeams.initialize();
  microsoftTeams.settings.registerOnSaveHandler(async (saveEvent) => {
    if (selectedBoard.new) {
      try {
        const fileCreate = await createFile({ variables: { fileName: selectedBoard.name, empty: true } });
        const linkCreate = await createBoardLink({ variables: { fileId: fileCreate.data.createFile.file.id } });
        const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
        microsoftTeams.settings.setSettings({
          suggestedDisplayName: fileCreate.data.createFile.file.fileName,
          entityId: linkCreate.data.createFileLink.id,
          contentUrl: `${baseUrl}/vc/teams/link?boardLink=${
            linkCreate.data.createFileLink.id
          }&name=${encodeURIComponent(fileCreate.data.createFile.file.fileName)}`,
          websiteUrl: `${baseUrl}/boards/link/${linkCreate.data.createFileLink.id}`,
        });
        saveEvent.notifySuccess();
      } catch (e) {
        console.log(e);
        saveEvent.notifyFailure();
      }
    } else {
      const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
      microsoftTeams.settings.setSettings({
        suggestedDisplayName: selectedBoard.name,
        entityId: selectedBoard.id,
        contentUrl: `${baseUrl}/vc/teams/link?boardLink=${selectedBoard.id}&name=${encodeURIComponent(
          selectedBoard.name
        )}`,
        websiteUrl: `${baseUrl}/boards/link/${selectedBoard.id}`,
      });
      saveEvent.notifySuccess();
    }
  });

  useEffect(() => {
    if (selectedBoard) {
      microsoftTeams.settings.setValidityState(true);
    } else {
      microsoftTeams.settings.setValidityState(false);
    }
  }, [selectedBoard]);

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext(async (context) => {
      setMsTeamsApp();
      if (context?.tid) {
        const tenantByAzure = await fetchTenantByAzureAdDomain(context.tid);
        const hostname = window.location.hostname;

        if (tenantByAzure?.subdomain && !hostname.startsWith(tenantByAzure.subdomain)) {
          window.location.hostname = `${tenantByAzure.subdomain}.${hostname}`;
        }
      }
    });
  }, []);

  if (!auth.user && !auth.loading) {
    return (
      <>
        <GlobalStyle />
        <Styled.InfoContainer>
          <img src="/images/ill_ping_pong.svg" width={160} alt="FlatFrog Board" />
          <h3>
            Start using FlatFrog Board <br /> for Microsoft Teams
          </h3>
          <Styled.InfoButton
            onClick={() => {
              microsoftTeams.authentication.authenticate({
                url: `/login?teams=true${tenant ? `&tenantId=${tenant.subdomain}` : ''}`,
                width: 650,
                height: 1000,
                successCallback: async () => {
                  console.log('TeamsConfig: successcallback');
                  const user = getFirebaseAuth().currentUser;
                  if (user) {
                    await user.reload();
                    auth.updateUser(getFirebaseAuth().currentUser);
                  }
                },
                failureCallback: () => {},
              });
            }}
          >
            Get started
          </Styled.InfoButton>
        </Styled.InfoContainer>
      </>
    );
  }

  if (auth.user) {
    return (
      <>
        <BoardPicker
          boardFilePicked={(board) => {
            setSelectedBoard(board);
          }}
        />
      </>
    );
  }

  return <div></div>;
};

export default TeamsConfig;
