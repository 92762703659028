import styled from 'styled-components';

export const PlusMore = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  color: ${(p) => p.color};
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transform-style: preserve-3d;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid #d8d8d8;
  background: #d8d8d8;
  padding: 14px;

  &:before {
    transform: translateZ(-1px);
    content: '';
    position: absolute;
    inset: -2px;
    background: #fff;
    border-radius: 50%;
    display: block;
  }
`;

export const Stack = styled.div`
  position: relative;
  flex-direction: column;
  @media (max-height: 680px) {
    flex-direction: row-reverse;
  }
  gap: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Bubbles = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-height: 680px) {
    flex-direction: row;
  }

  > * + * {
    margin-top: -6px;

    @media (max-height: 680px) {
      margin-top: inherit;
      margin-left: -6px;
    }
  }
`;

export const ParticipantsWrapper = styled.div`
  display: flex;
  align-items: center;
  pointer-events: all;
  flex-direction: column;
  position: absolute;
  right: 12px;
  top: 16px;

  > span {
    margin-left: 4px;
    text-transform: capitalize;
    white-space: nowrap;
    font-size: 14px;
    max-width: 20vw;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.room {
    right: 212px;
    top: 12px;

    ${Stack} {
      flex-wrap: nowrap;
      flex-direction: row-reverse;

      ${Bubbles} {
        flex-direction: row;

        > * + * {
          margin-top: inherit;
          margin-left: -6px;
        }
      }
    }
  }
`;

export const ExpandButton = styled.button`
  width: 48px;
  height: 48px;
  box-shadow: 0 4px 8px #279ae73c;
  border: none;
  outline: none;
  background: #279ae7 url(/icons/icn_invite_white.svg) center no-repeat;
  border-radius: 50%;
  cursor: pointer;
`;
