import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import slugid from 'slugid';

import { MUTATION_CREATE_ITEM, MUTATION_UPDATE_ITEM } from 'client/common/graphql';
import { TextAlignments } from 'client/components/Common/text-alignments';
import StickyNote, { STICKY_NOTE_DEFAULT_FONT_SIZE } from 'client/components/StickyNote';
import { BackgroundColors, ForegroundColors } from 'client/components/StickyNote/colors';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { Item } from 'client/state/papers/localItems';

import * as Styled from './mobile-editor-styled';

const [defaultForeground] = ForegroundColors;
const [defaultBackground] = BackgroundColors;

interface Props {
  item: Item;
  onClose: () => void;
  onSend: (id: string) => void;
}

const MobileEditor: React.FC<Props> = ({ item: itemToEdit, onClose, onSend }) => {
  const { addLocalItem, updateLocalItem } = useActions();

  const [editMode, setEditMode] = useState(false);

  const [item, setItem] = useState(null);

  const auth = useAuth();
  const [updateItem] = useMutation(MUTATION_UPDATE_ITEM);
  const [createItem] = useMutation(MUTATION_CREATE_ITEM);

  useEffect(() => {
    if (itemToEdit) {
      setItem(itemToEdit);
    } else {
      setItem({
        type: 'StickyNote',
        backgroundColor: defaultBackground.color,
        textSize: STICKY_NOTE_DEFAULT_FONT_SIZE,
        textColor: defaultForeground,
        textAlign: TextAlignments.Left,
        content: '',
        signature: '',
      });
      setEditMode(true);
    }
  }, [itemToEdit]);

  // Create server side item if logged in, otherwise a local item
  const saveNewStickyNote = async (content: string) => {
    if (auth.user) {
      const newItem = await createItem({ variables: { input: { ...item, content } } });

      setItem(newItem.data.createItem.item);
    } else {
      const newItem = { id: slugid.nice(), ...item, content, __typename: 'StickyNote' };

      addLocalItem(newItem);
      setItem(newItem);
    }
  };

  // Update item server side if logged in, otherwise a local item
  const updateStickyNote = (updatedProps: Partial<Item>) => {
    const newItem = { ...item, ...updatedProps };
    setItem(newItem);

    if (item.id) {
      const input = { id: item.id, ...updatedProps };

      if (auth.user) {
        updateItem({ variables: { input } });
      } else {
        updateLocalItem(input);
      }
    }
  };

  const onEditDone = (content: string) => {
    if (item.id) {
      updateStickyNote({ content });
    } else {
      saveNewStickyNote(content);
    }
    setEditMode(false);
  };

  if (!item) {
    return null;
  }

  return editMode ? (
    <TextEditor item={item} onEditDone={onEditDone} />
  ) : (
    <StickyNoteEditor
      item={item}
      setEditMode={setEditMode}
      onSend={onSend}
      onClose={onClose}
      updateStickyNote={updateStickyNote}
    />
  );
};

interface TextEditorProps {
  item: Item;
  onEditDone: (content: string) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({ item, onEditDone }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(item.content);
  }, []);

  return (
    <Styled.Container className="edit" style={{ background: item.backgroundColor }}>
      <Styled.TopArea>{!item.id ? 'Add' : 'Edit'} Sticky Note</Styled.TopArea>
      <Styled.TextArea
        placeholder="Write something"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        style={{ background: item.backgroundColor }}
        autoFocus
      />
      <Styled.BottomBar>
        <Styled.PrimaryButton className="edit" onClick={() => onEditDone(content)}>
          Done
        </Styled.PrimaryButton>
      </Styled.BottomBar>
    </Styled.Container>
  );
};

interface StickyNoteEditorProps {
  item: Item;
  setEditMode: (editMode: boolean) => void;
  updateStickyNote: (props: Partial<Item>) => void;
  onClose: () => void;
  onSend: (id: string) => void;
}
const StickyNoteEditor: React.FC<StickyNoteEditorProps> = ({
  item,
  setEditMode,
  updateStickyNote,
  onClose,
  onSend,
}) => {
  const { session, signatureText } = useSelector((state) => ({
    session: state.session,
    signatureText: state.signatureText,
  }));

  const [showPalette, setShowPalette] = useState(false);
  const [showTextSettings, setShowTextSettings] = useState(false);

  return (
    <Styled.Container style={{ background: '#242424' }}>
      <Styled.TopArea>{!item.id ? 'Add' : 'Edit'} Sticky Note</Styled.TopArea>
      <Styled.SettingsArea>
        <div onClick={() => setEditMode(true)}>
          <div style={{ pointerEvents: 'none' }}>
            <StickyNote
              isSent={false}
              id={item.id}
              color={item.backgroundColor}
              content={item.content}
              textColor={defaultForeground}
              textAlign={item.textAlign}
              textSize={item.textSize}
              signature={item.signature}
            />
          </div>
        </div>
        <Styled.SettingButtons>
          <Styled.ColorSettingsButton
            style={{ background: item.backgroundColor }}
            onClick={() => setShowPalette(true)}
          />
          <Styled.TextStyleSettingsButton onClick={() => setShowTextSettings(true)} />
          <Styled.SignatureToggle
            className={classNames({ active: item.signature })}
            onClick={() => {
              updateStickyNote({ signature: item.signature ? '' : item.signature || signatureText });
            }}
          />
        </Styled.SettingButtons>
      </Styled.SettingsArea>
      <Styled.BottomBar>
        <Styled.SecondaryButton onClick={onClose}>Back</Styled.SecondaryButton>
        {session?.id && item.id && <Styled.PrimaryButton onClick={() => onSend(item.id)}>Send</Styled.PrimaryButton>}
        <Styled.SecondaryButton className="hidden">New</Styled.SecondaryButton>
      </Styled.BottomBar>
      {(showTextSettings || showPalette) && (
        <Styled.Overlay
          onClick={() => {
            setShowTextSettings(false);
            setShowPalette(false);
          }}
        />
      )}
      {showPalette && (
        <Styled.Palette>
          {BackgroundColors.map((color) => (
            <Styled.ColorSettingsButton
              key={color.color}
              className={classNames({ active: item.backgroundColor === color.color })}
              style={{ background: color.color }}
              onClick={() => {
                updateStickyNote({ backgroundColor: color.color });
                setShowPalette(false);
              }}
            />
          ))}
        </Styled.Palette>
      )}
      {showTextSettings && (
        <Styled.Palette>
          <AlignButton updateStickyNote={updateStickyNote} item={item} textAlign={TextAlignments.Left} />
          <AlignButton updateStickyNote={updateStickyNote} item={item} textAlign={TextAlignments.Center} />
          <AlignButton updateStickyNote={updateStickyNote} item={item} textAlign={TextAlignments.Right} />
        </Styled.Palette>
      )}
    </Styled.Container>
  );
};

interface AlignButtonProps {
  item: Item;
  textAlign: TextAlignments;
  updateStickyNote: (props: { textAlign: TextAlignments }) => void;
}

const AlignButton: React.FC<AlignButtonProps> = ({ item, textAlign, updateStickyNote }) => (
  <Styled.AlignButton
    className={classNames({ active: item.textAlign === textAlign }, textAlign)}
    onClick={() => updateStickyNote({ textAlign })}
  />
);

export default MobileEditor;
