import { createGlobalStyle } from 'styled-components';

import { SMALL_BREAK } from 'client/common/util';

export default createGlobalStyle`

  // Removes the inner shadow on iOS
  input[type=text] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .ghostThumb {
    z-index: 999;
    opacity: 0.7;
  }
  
  .tooltips {
    border-radius: 4px !important;
    background: #242424 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    height: 32px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    
    span.shortcut {
      color: #C3C3C3;
      margin-left: 16px;
    }
  }

  body, html {
    padding: 0;
    margin: 0;
  }
  
  :focus { outline: none; }
  ::-moz-focus-inner { border: 0; }
  
  input::-ms-clear { 
    display: none;
  }
  
  html {
    overflow: hidden;
    height: 100%;
    -webkit-text-size-adjust: none;
    touch-action: pan-y;
  }
  
  body {
    height: 100%;
    overflow: auto;
    background: #F0F0F0;
    font-family: 'Open Sans', sans-serif;
    color: #8C8C8C;
    -webkit-touch-callout: none;
  }
  
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
  }
  
  h1 {
    color: #8C8C8C;
    font-weight: 400;
    padding: 0;
    margin: 1em 0 0 0;
    text-align: center;
    font-size: 42px;
    text-transform: uppercase;
    
    @media all and (max-width: ${SMALL_BREAK}px) {
      font-size: 24px;
    }
  }
  
input, button, textarea {
  font-family: 'Open Sans', sans-serif;
}  
  
input[type=range] {
  //-webkit-appearance: none;
  width: 100%;
  margin: 6px 0;
}
input[type=range]:focus {
  outline: none;
}

.slide-300 {
  transition: transform 300ms ease-in-out, opacity 200ms ease-in-out;
  transform-origin: top left;
  
  &.on {
    transform: scale(1); // translate(0, 0);
    opacity: 1;
  }
  
  &.off {
    transform: scale(0); //translate(0, 100%);
    opacity: 0;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 16px;
  
  &.stack {
    position: absolute;
    top: 14px;
    transform: scale(0.5);

   > div {
    background: #666;
   }
  }
  
  > div {
    position: absolute;
    top: 6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #279AE7;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(8px, 0);
      }
    }
    
    &:nth-child(1) {
      left: 0;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 0;
      animation: lds-ellipsis2 0.6s infinite;
    }
    
    &:nth-child(3) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    
    &:nth-child(4) {
      left: 16px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

.lds-ellipsis-large {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 32px;

    &.stack {
      position: absolute;
      top: 28px;
      transform: scale(0.5);

      > div {
        background: #666;
      }
    }

    > div {
      position: absolute;
      top: 12px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #279AE7;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      @keyframes lds-ellipsis-large1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis-large3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis-large2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(16px, 0);
        }
      }

      &:nth-child(1) {
        left: 0;
        animation: lds-ellipsis-large1 0.6s infinite;
      }

      &:nth-child(2) {
        left: 0;
        animation: lds-ellipsis-large2 0.6s infinite;
      }

      &:nth-child(3) {
        left: 16px;
        animation: lds-ellipsis-large2 0.6s infinite;
      }

      &:nth-child(4) {
        left: 32px;
        animation: lds-ellipsis-large3 0.6s infinite;
      }
    }
  }
  
`;
