import ZCanvas from '@flatfrog/ffbec';

const offscreenCanvas = new OffscreenCanvas(600, 92);

const roundRect = (ctx: OffscreenCanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) => {
  if (w < 2 * r) {
    r = w / 2;
  }
  if (h < 2 * r) {
    r = h / 2;
  }
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();
};

const generateLabel = async (title: string) => {
  if (title?.length === 0) {
    title = 'A room';
  }
  const font = 'bold 36px Open Sans, verdana';
  const color = '#fff';
  const backgroundColor = '#242424';

  offscreenCanvas.width = 600;
  offscreenCanvas.height = 92;

  const ctx = offscreenCanvas.getContext('2d');

  const dpr = window.devicePixelRatio || 1;
  ctx.scale(dpr, dpr);
  ctx.font = font;
  ctx.textAlign = 'left';
  ctx.fillText(title, 0, offscreenCanvas.height / 2);

  const m = ctx.measureText(title.trim());
  offscreenCanvas.width = m.width + 36;
  ctx.fillStyle = backgroundColor;
  roundRect(ctx, 0, 0, offscreenCanvas.width, offscreenCanvas.height, 10);
  ctx.fill();
  ctx.textBaseline = 'middle';
  ctx.font = font;
  ctx.fillStyle = color;
  ctx.textAlign = 'center';
  ctx.fillText(title.trim(), offscreenCanvas.width / 2, offscreenCanvas.height / 2 + 4);

  return offscreenCanvas.convertToBlob();
};

const addClientPingLabel = async (name: string) => {
  await ZCanvas.initialized;

  const labelImageData = await generateLabel(name);
  const arrayBuffer = await labelImageData.arrayBuffer();
  return ZCanvas.image.loadAsync({
    data: new Uint8Array(arrayBuffer),
  });
};

export default addClientPingLabel;
