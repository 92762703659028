import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { getParticipantColor, getParticipantName } from 'client/common/participant-names';
import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import { useAuth } from 'client/hooks/useAuth';

import { Buttons, FieldForm } from '../my-account/modals-styled';
import { TeamBubble } from '../styled';

import * as Styled from './create-team-styled';
import EmailTextarea from './email-textarea';

interface Props {
  onClose: () => void;
  onCreate: (name: string, description: string, emails: string[]) => void;
}

const CreateTeam: React.FC<Props> = ({ onClose, onCreate }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [showInvite, setShowInvite] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [emailError, setEmailError] = useState<boolean>();
  const [emails, setEmails] = useState<string[]>([]);
  const { user } = useAuth();

  const { disableClipBoarding } = useActions();

  const { tenant } = useSelector((state) => ({ tenant: state.tenant }));

  useHotkeys('esc', () => {
    onClose();
  });

  useEffect(() => {
    disableClipBoarding('CreateTeam', true);
    return () => {
      disableClipBoarding('CreateTeam', false);
    };
  }, []);

  const emailsChanged = useCallback((value: string[]) => {
    setEmails(value);
    setEmailError(false);
  }, []);

  const next = useCallback(
    (e, skip?: boolean) => {
      e.preventDefault();

      if (!showInvite) {
        setShowInvite(true);
      } else if (!skip && emails.length === 0) {
        setEmailError(true);
      } else {
        setNextEnabled(false);
        onCreate(name, description, emails);
      }
    },
    [showInvite, name, description, emails]
  );

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container>
        <Styled.Title>Create Workspace</Styled.Title>
        <FieldForm>
          <form onSubmit={next}>
            <div style={{ display: showInvite ? 'none' : 'block' }}>
              <label htmlFor="team-name-input">Workspace Name</label>
              <div style={{ position: 'relative' }}>
                <TeamBubble color={name && getParticipantColor(name)}>
                  {name ? getParticipantName(name) : ''}
                </TeamBubble>
                <Styled.TeamInput
                  type="text"
                  name="team-name"
                  id="team-name-input"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  maxLength={60}
                />
              </div>
              <label htmlFor="team-description">Description (optional)</label>
              <textarea
                style={{ resize: 'none' }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="team-description"
                name="team-description"
              />
            </div>
            <div style={{ display: showInvite ? 'block' : 'none', textAlign: 'left' }}>
              <p>
                People will be added automatically to your Workspace.
                {!tenant &&
                  ' If the email is not registered they will receive an invitation to sign up to join the Workspace.'}
              </p>
              <label>Invites</label>
              <EmailTextarea id="create-workspace-email-input" emailsChanged={emailsChanged} existingUsers={[user]} />
              {emailError && <Styled.EmailErrors>No valid emails entered</Styled.EmailErrors>}
            </div>
            <Buttons>
              <button
                type="button"
                onClick={(e) => {
                  if (showInvite) {
                    next(e, true);
                  } else {
                    onClose();
                  }
                }}
                className="cancel"
              >
                {showInvite ? 'Skip for now' : 'Cancel'}
              </button>
              <button disabled={!nextEnabled} type="submit">
                {showInvite ? 'Create' : 'Next'}
              </button>
            </Buttons>
          </form>
        </FieldForm>
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default CreateTeam;
