import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 100px;

  animation: slide-in--debug-view 500ms ease;
  @keyframes slide-in--debug-view {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const View = styled.div`
  height: 70px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 0 0 0.5px rgba(0, 0, 0, 0.08);
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  min-width: 330px;
  resize: vertical;
  cursor: move;

  &.error {
    background-color: rgba(255, 220, 220, 1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 0 0 2px red;
  }
`;

export const CombinedActionEntry = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionsEntry = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  font-size: 12px;
  color: #222222;
  text-align: left;
  padding: 2px;
  width: 100%;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const OriginIndicator = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  color: white;
  font-size: 7px;
  font-weight: bold;
  margin-right: 5px;
  text-align: center;
  margin-top: 4px;
  opacity: 0;

  &.remote {
    opacity: 1;
    background-color: #60aa77;
  }

  &.local {
    opacity: 1;
    background-color: #6077aa;
  }
`;

export const ObjectIdField = styled.div`
  position: absolute;
  height: 12px;
  width: 50px;
  padding: 0 5px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.9);
  font-size: 8px;
  text-align: center;
  background-color: black;
  margin-top: 2px;
  right: 10px;
`;

export const Flyout = styled.div`
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  top: 5px;
  left: 5px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  border: 1px solid #999999;
  overflow-y: auto;
  max-height: 90vh;
  white-space: pre-wrap;
`;
