import React, { useEffect, useState, useRef, useMemo } from 'react';
import { isMacOs, isMobileOnly } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import ZCanvas from '@flatfrog/ffbec';
import classNames from 'classnames';

import * as analytics from 'client/common/analytics';
import TemplateGalleryModal from 'client/components/Dashboard/templates/template-gallery-modal';
import { NextButton, Pages, PrevButton } from 'client/components/MobileBar/styled';
import GridView from 'client/components/Whiteboard/GridView';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useEventListener from 'client/hooks/useEventListener';
import useReduxAction from 'client/hooks/useReduxAction';
import { useSelector } from 'client/hooks/useSelector';
import * as actions from 'client/state/actions';
import { Client } from 'client/state/actions';
import usePrevious from 'client/hooks/usePrevious';

import * as Styles from './PageControls-styled';

export const MAX_PAGES_ALLOWED = 32;

interface Props {
  hidden?: boolean;
  ready: boolean;
}

const PageControls: React.FC<Props> = ({ hidden, ready }) => {
  const {
    forceFollow,
    isFollower,
    isViewOnlyMode,
    lostConnection,
    room,
    session,
    showFilterSearchField,
    showGridView,
    userRole,
  } = useSelector((state) => ({
    forceFollow: state.forceFollow,
    isFollower: state.isFollower,
    isViewOnlyMode: state.isViewOnlyMode,
    lostConnection: state.showDisconnectDialog.show,
    room: state.room,
    session: state.session,
    showFilterSearchField: state.showFilterSearchField,
    showGridView: state.showGridView,
    userRole: state.userRole,
  }));

  const {
    addTemplatePage,
    prepareAndPublishImage,
    sendAnnouncePage,
    setFollower,
    setPageForClient,
    setShowFilterSearchField,
    setShowGridView,
    toggleShowReconnectDialog,
    duplicatePage,
  } = useActions();

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageOptionsTrayOpen, setPageOptionsTrayOpen] = useState(false);
  const [showNewPageGallery, setShowNewPageGallery] = useState(false);
  const [switchToPage, setSwitchToPage] = useState(undefined);
  const auth = useAuth();

  // refs to access switchToPage and pagesRef since handlePageEvent closure see stale versions of direct state
  // see https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
  const switchToPageRef = useRef(switchToPage);
  switchToPageRef.current = switchToPage;

  const pagesRef = useRef(pages);
  pagesRef.current = pages;

  const selfRef = useRef<Client | undefined>(undefined);
  const pageOptionsButton = useRef<HTMLDivElement>();

  const [commandPrefix, setCommandPrefix] = useState('Ctrl+');

  const currentPresenter = useMemo(() => session.clients?.find((c) => c.isPresenter), [session]);

  useEffect(() => {
    selfRef.current = session.clients?.find((c) => c.self);
  }, [session]);

  useEffect(() => {
    if (isMacOs) {
      setCommandPrefix('⌘');
    }
  }, []);

  const self = session.clients?.find((c) => c.self);

  const changePage = (toPage: number, stopFollowing = true) => {
    if (toPage >= 0 && toPage < pagesRef.current) {
      ReactTooltip.hide();

      if (isFollower && stopFollowing) {
        setFollower(false);
      }

      ZCanvas.page.setCurrentIndex(toPage);
      setCurrentPage(toPage);
    }
  };

  const changeToPreviousPage = () => changePage(currentPage - 1);
  const changeToNextPage = () => changePage(currentPage + 1);

  const handlePageEvent = (e: { type: string; pageId: number }[]) => {
    const numPages = ZCanvas.page.getCount();
    const currentIndex = ZCanvas.page.getCurrentIndex();
    setPages(numPages);
    setCurrentPage(currentIndex);
    const [{ type, pageId }] = e;

    if (type === 'CURRENT_PAGE_CHANGED' || type === 'PAGE_REMOVED' || type === 'PAGE_ORDER_CHANGED') {
      // Announce page
      const uuid = ZCanvas.page.getUuidFromIndex(currentIndex);
      if (uuid !== undefined) {
        sendAnnouncePage(uuid);

        if (selfRef.current) {
          setPageForClient(selfRef.current.id, uuid);
        }
      } else {
        console.error('Bad current page, will not announce it', currentIndex);
      }
    } else if (type === 'PAGE_ADDED' && switchToPageRef.current === ZCanvas.page.getUuidFromId(pageId)) {
      changePage(ZCanvas.page.getIndexFromUuid(switchToPageRef.current));
      setSwitchToPage(undefined);
    }

    ReactTooltip.rebuild();
  };

  useEffect(() => {
    if (!ready) {
      return;
    }
    const pageEvents = [
      'PAGE_ADDED',
      'PAGE_REMOVED',
      'PAGE_ORDER_CHANGED',
      'CURRENT_PAGE_CHANGED',
      'CURRENT_PAGE_EDITING_LAYER_CHANGED',
    ];

    pageEvents.forEach(
      (
        event:
          | 'PAGE_ADDED'
          | 'PAGE_REMOVED'
          | 'PAGE_ORDER_CHANGED'
          | 'CURRENT_PAGE_CHANGED'
          | 'CURRENT_PAGE_EDITING_LAYER_CHANGED'
      ) => ZCanvas.addEventListener(event, handlePageEvent)
    );

    return () => pageEvents.forEach((event) => ZCanvas.removeEventListener(event, handlePageEvent));
  }, [session.id, ready]);

  useEffect(() => {
    if (lostConnection) {
      setPageOptionsTrayOpen(false);
    }
  }, [lostConnection]);

  const prevIsFollower = usePrevious(isFollower);

  useEffect(() => {
    if (!prevIsFollower && isFollower) {
      const index = ZCanvas.page.getIndexFromUuid(currentPresenter.atPage);

      if (index >= 0) {
        changePage(index, false);
      }
    }
  }, [prevIsFollower, isFollower]);

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (!pageOptionsButton?.current?.contains(e.detail)) {
      setPageOptionsTrayOpen(false);
    }
  });

  useEventListener<KeyboardEvent>('keydown', (event) => {
    if (!selfRef.current?.isPresenter && forceFollow) {
      return;
    }

    switch (event.key) {
      case 'PageDown': {
        changeToNextPage();
        break;
      }
      case 'PageUp': {
        changeToPreviousPage();
        break;
      }
      default:
        break;
    }
  });

  useReduxAction(
    () => {
      ZCanvas.page.setCurrentIndex(0);
      setCurrentPage(0);
      setPages(ZCanvas.page.getCount());

      if (selfRef.current) {
        const uuid = ZCanvas.page.getUuidFromIndex(0);

        if (uuid) {
          setPageForClient(selfRef.current.id, uuid);
          sendAnnouncePage(uuid);
        }
        toggleShowReconnectDialog(false);
      }
    },
    actions.setInitialLoadingDone,
    [selfRef]
  );

  const isLocked = !self?.isPresenter && forceFollow;

  useEffect(() => {
    if (isLocked) {
      setShowGridView(false);
      setPageOptionsTrayOpen(false);
    }
  }, [isLocked]);

  if (!ready) {
    return null;
  }

  const showReturnButton = currentPresenter && !isFollower && !self?.isPresenter;

  const pageHasBackground = pageOptionsTrayOpen && ZCanvas.paper.getPageHasBackgroundPaper();

  if (isViewOnlyMode) {
    // TODO: Touch mode button?
    return null;
  }

  /**
   * This is for the mobile bottom bar. Reason for using this component is because it has a lot of logic
   * for changing pages, presenter/follower etc. A refactoring of this might be good some day
   */
  if (isMobileOnly) {
    return (
      <>
        <PrevButton
          className={classNames({ disabled: isLocked || currentPage === 0 })}
          onClick={() => {
            if (!isLocked) {
              changeToPreviousPage();
            }
          }}
        />
        <Pages>
          Page {currentPage + 1} / {pages}
        </Pages>
        <NextButton
          className={classNames({ disabled: isLocked || currentPage + 1 === pages }) ? 'disabled' : ''}
          onClick={() => {
            if (!isLocked) {
              changeToNextPage();
            }
          }}
        />
      </>
    );
  }

  const addBlankPage = () => {
    ZCanvas.page.create();

    if (!selfRef.current?.isPresenter && isFollower) {
      setFollower(false);
    }
    analytics.addPage({ fromPageGrid: showGridView });
  };

  const closePipWindow = () => {
    if (window.ffbecandroidjni?.close_pip_window) {
      window.ffbecandroidjni.close_pip_window();
    }
  };

  const showAddPage = () => {
    if (pages < MAX_PAGES_ALLOWED) {
      closePipWindow();
      if (auth.user) {
        setShowNewPageGallery(true);
      } else {
        addBlankPage();
      }
    }
  };

  const wait = (timeToDelay: number) => new Promise((resolve) => setTimeout(resolve, timeToDelay));

  return (
    <>
      {showNewPageGallery && (
        <TemplateGalleryModal
          userRole={userRole}
          onSelect={async (template) => {
            // ZCanvas.pause();
            while (!ZCanvas.beginSyncSection()) {
              console.log('Will add template page, waiting for BEC to settle down first...');
              await wait(100);
            }
            try {
              const currentIndex = ZCanvas.page.getCurrentIndex();

              if ('new' in template) {
                addBlankPage();
              } else if ('template' in template) {
                const pageUuid = uuidv4();
                analytics.addPage({ fromPageGrid: showGridView, id: template.templateId });
                setSwitchToPage(pageUuid);

                addTemplatePage(template.templateId, pageUuid, currentIndex + 1);
              }
              setShowNewPageGallery(false);
            } catch {
              console.error('Error occurred while adding template.');
            }
            ZCanvas.endSyncSection();
            // ZCanvas.resume();
          }}
          onClose={() => {
            setShowNewPageGallery(false);
          }}
        />
      )}
      <GridView pages={pages} currentPage={currentPage} changePage={changePage} showAddPage={showAddPage} />
      <Styles.PageControls
        className={classNames({ room })}
        data-tip="The presenter has locked the board"
        data-place="left"
        data-tip-disable={!isLocked || room}
        hidden={hidden}
      >
        <Styles.GridViewButton
          data-place="left"
          data-tip="View All Pages"
          data-tip-disable={room}
          disabled={isLocked}
          onClick={() => {
            closePipWindow();
            setShowGridView(true);
          }}
          id="btn--grid-view"
        />
        <Styles.ScrollPrevButton
          disabled={isLocked || currentPage === 0}
          onClick={changeToPreviousPage}
          data-place="left"
          data-tip={`Previous Page <span class="shortcut">Page Up</span>`}
          data-html="true"
          id="btn--prev-page"
        />
        <Styles.PageStatus>
          {currentPage + 1} / {pages}
          {showReturnButton && (
            <Styles.ReturnToPresenter
              id="follow-presenter"
              onClick={() => setFollower(true)}
              data-tip={(() => {
                const page = ZCanvas.page.getIndexFromUuid(currentPresenter?.atPage) + 1;

                if (page === currentPage + 1) {
                  return `Follow presenter ${currentPresenter ? `(${currentPresenter.name})` : ''}`;
                }

                return `Follow presenter ${currentPresenter ? `(${currentPresenter.name})` : ''} on page ${page}`;
              })()}
            />
          )}
        </Styles.PageStatus>
        <Styles.ScrollNextButton
          disabled={isLocked || currentPage + 1 === pages}
          onClick={changeToNextPage}
          data-place="left"
          data-tip={`Next Page <span class="shortcut">Page Down</span>`}
          data-html="true"
          id="btn--next-page"
        />
        <Styles.AddPageButton
          id="btn--add-page"
          data-place="left"
          data-tip={pages >= MAX_PAGES_ALLOWED ? 'Maximum number of pages reached' : 'Add Page'}
          disabled={isLocked || pages >= MAX_PAGES_ALLOWED}
          onClick={() => {
            ReactTooltip.hide();
            // Tooltip doesn't work on disabled elements, so we do like this instead
            showAddPage();
          }}
        />
        {!room && (
          <>
            <Styles.PageOptionsButton
              ref={pageOptionsButton}
              id="page-options-button"
              disabled={isLocked}
              data-place="left"
              data-tip={!pageOptionsTrayOpen ? 'Page Menu' : ''}
              onClick={() => {
                ReactTooltip.hide();
                setPageOptionsTrayOpen(!pageOptionsTrayOpen);
              }}
            >
              {pageOptionsTrayOpen && (
                <Styles.PageOptionsMenu onClick={(e) => e.stopPropagation()}>
                  <Styles.ImageMenuItem>
                    <label>
                      <input
                        accept=".png,.jpeg,.jpg"
                        type="file"
                        onChange={(e) => {
                          Array.from(e.target.files).forEach((file) => {
                            if (file.type.match('^image/(png|jpg|jpeg)')) {
                              prepareAndPublishImage({ file, options: { setAsBackground: true } });
                              setPageOptionsTrayOpen(false);
                              if (pageHasBackground) {
                                analytics.replaceBackground();
                              } else {
                                analytics.setBackground(false);
                              }
                            }
                          });
                          // TODO: Analytics
                          e.target.value = null;
                        }}
                      ></input>
                    </label>
                    {pageHasBackground ? 'Replace Background' : 'Set Background'}
                  </Styles.ImageMenuItem>
                  <Styles.MenuItem
                    disabled={!pageHasBackground}
                    onClick={() => {
                      ZCanvas.paper.removeBackgroundPaperFromPage();
                      setPageOptionsTrayOpen(false);
                      analytics.removeBackground();
                    }}
                  >
                    Remove Background
                  </Styles.MenuItem>
                  <Styles.MenuItem
                    disabled={isLocked}
                    onClick={() => {
                      duplicatePage(ZCanvas.page.getCurrentId());
                      analytics.duplicatePage(false);
                      setPageOptionsTrayOpen(false);
                    }}
                  >
                    Duplicate Page
                  </Styles.MenuItem>
                  <Styles.MenuDelimiter />
                  <Styles.DeleteMenuItem
                    disabled={isLocked || ZCanvas.page.getIds().length <= 1}
                    onClick={() => {
                      ZCanvas.page.removeCurrent();
                      analytics.deletePage(false);
                      setPageOptionsTrayOpen(false);
                    }}
                  >
                    Delete Page
                  </Styles.DeleteMenuItem>
                </Styles.PageOptionsMenu>
              )}
            </Styles.PageOptionsButton>
            <Styles.PageOptionsDelimiter />
            <Styles.SearchButton
              data-place="left"
              data-tip={`Search <span class="shortcut">${commandPrefix}F</span>`}
              onClick={() => {
                ReactTooltip.hide();
                setShowFilterSearchField(!showFilterSearchField);
              }}
            />
          </>
        )}
      </Styles.PageControls>
    </>
  );
};

export default PageControls;
