import React from 'react';
import { useHistory } from 'react-router-dom';

import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';

interface Props {
  onCancel: () => void;
}

const EndSessionDialog: React.FC<Props> = ({ onCancel }) => {
  const history = useHistory();

  const onNext = () => {
    history.replace('/dashboard');
  };

  return (
    <Dialog noCloseButton={true} cancelText="Cancel" nextText="Close anyway" onNext={onNext} onCancel={onCancel} cover>
      <DialogStyled.Title>Close Board</DialogStyled.Title>
      <DialogStyled.Body>The Board is not saved and will be lost if you continue</DialogStyled.Body>
    </Dialog>
  );
};
export default EndSessionDialog;
