import { createAction, createReducer } from '@reduxjs/toolkit';
import { ZActions, ZShapes } from '@flatfrog/ffbec/js/zwasm';

type State = [number | string, number, boolean];

export const setZAction = createAction('setZAction', (zAction: number | string, zShape: number, event: boolean) => ({
  payload: { zAction, zShape, event },
}));

export default createReducer<State>([ZActions.SELECT, ZShapes.FREEFORM, false], (builder) =>
  builder.addCase(setZAction, (state, { payload: { event, zAction, zShape } }) => [zAction, zShape, event])
);
