import React, { useState, useRef, useCallback } from 'react';
import { DraggableEvent, DraggableProps } from 'react-draggable';

import { COLORS } from 'client/common/ZCanvasAppSettings';
import Toggleswitch from 'client/components/Common/toggleswitch';

import { CloseTrayButton, Color, InkToolToggle, Pen, PenWrapper, Tray } from './styled';

interface PaletteProps {
  setShowTray: (show: boolean) => void;
  onStop: () => void;
  onDrag: (e: DraggableEvent) => void;
}

const Draggable: React.FC<Partial<DraggableProps>> = React.lazy(() => import('react-draggable'));

const Palette: React.FC<PaletteProps> = ({ setShowTray, children, onStop, onDrag }) => {
  const startPos = useRef({ x: 0, y: 0 });
  const onStart = useCallback((e, { x, y }) => {
    startPos.current = { x, y };
  }, []);

  const DISTANCE_THRESH = 15;

  return (
    <React.Suspense fallback={<></>}>
      <Draggable
        onStart={onStart}
        onStop={onStop}
        onDrag={(e, data) => {
          // Since users have a hard time to select colors without nudging the tray, we want to have some kind of
          // threshold for the distance moved
          const deltaX = startPos.current.x - data.x;
          const deltaY = startPos.current.y - data.y;

          if (Math.hypot(deltaX, deltaY) > DISTANCE_THRESH) {
            onDrag(e);
          }
        }}
        defaultPosition={{ x: 0, y: -250 }}
      >
        <Tray>
          <CloseTrayButton onPointerUp={() => setShowTray(false)} />
          {children}
        </Tray>
      </Draggable>
    </React.Suspense>
  );
};

interface Props {
  colorSelected: typeof COLORS[number];
  inkTool: 'line' | 'draw' | 'erase';
  setColorSelected: (color: typeof COLORS[number]) => void;
  setInkTool: (inkTook: 'line' | 'draw') => void;
  setStrokeSizeSelected: (sizeIndex: number) => void;
  strokeSizeSelected: number;
}

const RoomToolbar: React.FC<Props> = ({
  colorSelected,
  inkTool,
  setColorSelected,
  setInkTool,
  setStrokeSizeSelected,
  strokeSizeSelected,
}) => {
  // This matches the default colors and sizes set in the config/toolbar
  const [brushes, setBrushes] = useState<
    {
      sizeIndex: number;
      color: typeof COLORS[number];
      inkTool: 'draw' | 'line';
    }[]
  >([
    {
      sizeIndex: 0,
      color: COLORS[1],
      inkTool: 'draw',
    },
    {
      sizeIndex: 1,
      color: COLORS[0],
      inkTool: 'draw',
    },
    {
      sizeIndex: 2,
      color: COLORS[4],
      inkTool: 'draw',
    },
  ]);

  const [showTray, setShowTray] = useState(false);
  const [draggingTray, setDraggingTray] = useState(false);

  const wrapper = useRef();

  const setColor = useCallback(
    (e: React.PointerEvent, color: typeof COLORS[0]) => {
      if (draggingTray) {
        return;
      }

      setBrushes(
        brushes.map((brush) =>
          brush.sizeIndex === strokeSizeSelected
            ? {
                ...brush,
                color,
              }
            : brush
        )
      );

      setColorSelected(color);
    },
    [brushes, strokeSizeSelected, draggingTray]
  );

  const setLine = useCallback(
    (line) => {
      if (draggingTray) {
        return;
      }

      const newInkTool = line ? 'line' : 'draw';

      setBrushes(
        brushes.map((brush) =>
          brush.sizeIndex === strokeSizeSelected
            ? {
                ...brush,
                inkTool: newInkTool,
              }
            : brush
        )
      );

      setInkTool(newInkTool);
    },
    [brushes, strokeSizeSelected, draggingTray]
  );

  const onDragStopped = useCallback(() => {
    setDraggingTray(false);
  }, []);

  const onDragStarted = useCallback(() => {
    setDraggingTray(true);
  }, []);

  return (
    <>
      <PenWrapper ref={wrapper}>
        {showTray && (
          <Palette setShowTray={setShowTray} onStop={onDragStopped} onDrag={onDragStarted}>
            {COLORS.filter((color) => color.default).map((color, idx) => {
              const active = colorSelected.hex === color.hex;

              return (
                <Color
                  key={idx}
                  className={active ? 'active' : ''}
                  style={{ backgroundColor: color.hex }}
                  onPointerUp={(e) => {
                    setColor(e, color);
                  }}
                />
              );
            })}

            <InkToolToggle
              onPointerUp={() => {
                setLine(inkTool !== 'line');
              }}
            >
              Lines
              <div style={{ pointerEvents: 'none', width: 48 }}>
                <Toggleswitch
                  translucent
                  id="lines-room-tools"
                  checked={inkTool === 'line'}
                  onChange={(e) => e.preventDefault()}
                />
              </div>
            </InkToolToggle>
          </Palette>
        )}
        {brushes.map((brushObject, idx) => {
          const active = colorSelected.hex === brushObject.color.hex && strokeSizeSelected === brushObject.sizeIndex;
          return (
            <Pen
              key={idx}
              style={{
                backgroundImage: `url(/icons/pix_pen${idx + 1}@2x.png), url(/icons/pen${idx + 1}_${
                  brushObject.color.roomBrushIcon
                }.svg)`,
              }}
              className={active ? 'active' : ''}
              onPointerUp={() => {
                // Click once for selecting, click twice for bringing up the tray
                if (active && !showTray) {
                  setShowTray(true);
                } else {
                  setInkTool(brushObject.inkTool);
                  setColorSelected(brushObject.color);
                  setStrokeSizeSelected(brushObject.sizeIndex);
                }
              }}
            />
          );
        })}
      </PenWrapper>
    </>
  );
};

export default RoomToolbar;
