import { isMobile as _isMobile, isIPad13 } from 'react-device-detect';
import styled from 'styled-components';

import { Button, FadeContainer } from 'client/components/MobileBar/styled';
import { StickyNoteWrapper } from 'client/components/StickyNote/styled';
import { TextBlockWrapper } from 'client/components/TextBlock/styled';

const isMobile = _isMobile || isIPad13;

export const ToggleButton = styled.div`
  top: 80px;
  right: 0;
  height: 28px;
  width: 28px;
  background: #fff;
  box-shadow: 0 1px 2px #00000029;
  position: absolute;
  border-radius: 50%;
  transform: translate(calc(50%), 0);
  cursor: pointer;
  border: 1px solid #00000019;

  transition: background-color 200ms ease-out;
  &:hover {
    background-color: rgba(240, 240, 240);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(/icons/icn_chevron.svg) center center no-repeat;
    background-size: 40%;
    transform: rotate(-90deg);
    opacity: 0.6;
    transition: transform 200ms ease-in-out;
  }

  &.open {
    &:after {
      transform: rotate(90deg);
    }
  }
`;

export const SideButtons = styled.div`
  position: absolute;
  width: 64px;
  right: 0;
  top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  transition: opacity 150ms linear;
`;

export const ScrollArea = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  flex-grow: 1;
  position: relative;
  max-width: 100vw;
  opacity: 0;
  //pointer-events: ${isMobile ? 'initial' : 'none'};
  transition: opacity 150ms linear;
  //touch-action: pan-y;

  &.show {
    opacity: 1;
    pointer-events: initial;
  }

  &.empty {
    padding-top: 97px;
    justify-content: center;
    text-align: center;
  }

  ${StickyNoteWrapper} {
    &.mobile {
      height: 210px;
      margin: 10px auto;
    }
  }

  ${TextBlockWrapper} {
    &.mobile {
      height: 60px;
      margin: 10px auto;
    }
  }

  &.mobile {
    padding-bottom: 74px;
  }
`;

export const MenuHoverPositioner = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding-top: 16px;
  cursor: initial;
`;

export const MenuContainer = styled.div`
  position: relative;
  width: 220px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  padding: 10px 0;
  border-radius: 6px;
  box-shadow: 0 4px 8px #00000026;
  font-size: 14px;
  text-align: left;
  color: #242424;
  cursor: initial;

  a {
    color: black;
  }

  &:after,
  &:before {
    bottom: 100%;
    left: 17px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #d2d2d2;
    border-width: 9px;
    margin-left: -9px;
  }
`;

export const AddStickyNoteItem = styled.div`
  padding: 12px 16px;
  background: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(/icons/icn_stickynote.svg);
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    content: '';
  }

  &:hover {
    background: #f4f4f4;
  }
`;

export const AddTextItem = styled.div`
  padding: 12px 16px;
  background: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(/icons/icn_text.svg);
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    margin-left: 3px;
    margin-right: 19px;
    content: '';
  }

  &:hover {
    background: #f4f4f4;
  }
`;

export const AddItemButton = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  flex-shrink: 0;
  position: absolute;
  left: 16px;
  top: 11px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  flex-direction: row;
  font-size: 14px;
  cursor: pointer;
  padding: 9px 14px;
  box-shadow: none;
  border: none;
  z-index: 1;
  background: #279ae7 url(/icons/icn_new_items_white.svg) center center no-repeat;
  width: 40px;
  height: 40px;
  border-radius: 5px;

  &.active {
    ${MenuHoverPositioner} {
      display: block;
    }
    background-color: #2293df;
  }

  /*
  &::before {
    background-image: url(/icons/icn_new_items_white.svg);
    background-size: 12px;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    content: "";
  }

  &::after {
    background-image: url(/icons/icn_dropdown_arrow_white.svg);
    background-size: 8px;
    width: 8px;
    height: 8px;
    margin-left: 8px;
    content: "";
  }
  */

  &:hover {
    background-color: #2287ca;
  }

  &:disabled {
    cursor: default;
    &:hover {
      background-color: #63c199;
    }
  }
`;

export const HeaderArea = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Wrapper = styled.div.attrs((props) => ({
  style: props.hidden ? { opacity: 0 } : {},
}))`
  width: 450px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #00000019;
  background: #fafafa;
  padding-top: 69px;
  margin-right: ${isMobile ? '0' : '10px'};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  transform: translate(-100%, 0);
  transition: transform 300ms ease-in-out;

  ${ScrollArea}, ${AddItemButton}, .empty-text {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &.show {
    box-shadow: 0 4px 8px #00000026;
    transform: translate(0, 0) !important;

    ${ScrollArea}, ${AddItemButton}, .empty-text {
      opacity: 1 !important;
    }

    ${ToggleButton} {
      opacity: 1 !important;
    }
  }

  &.empty {
    justify-content: center;
    text-align: center;
  }

  &.not-connected {
    ${SideButtons} {
      display: none;
    }

    ${ToggleButton} {
      opacity: 0;
    }
  }

  &.connected {
    transform: translate(-100%, 0);

    &.show {
      transform: translate(0, 0) !important;
    }
  }

  &.mobile {
    border: none;
    width: 100vw;
    padding-top: 64px;
    background: #fff;

    ${HeaderArea} {
      top: 0;
    }
  }
`;

export const MobileControls = styled(FadeContainer)`
  height: 74px;
  padding: 8px 16px;
`;

export const MobileButton = styled(Button)`
  font: normal normal 600 13px/22px 'Open Sans';
  letter-spacing: 0;
  color: #242424;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;

  &.disabled {
    pointer-events: none;
  }

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }

  &.send {
    color: #2287ca;
  }

  &.delete {
    color: #df0b0b;
  }
`;

export const MobileAddButton = styled(MobileButton)`
  width: 112px;
  max-width: 50vw;
  background: #63c199 url(/icons/icn_plus.svg) center center no-repeat;
  background-size: 16px;
  border: 1px solid #63c199;
  border-radius: 40px;

  &.select {
    background: rgba(0, 0, 0, 0.04);
    color: #242424;
    box-shadow: none;
    border: none;
  }
`;

export const CloseButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  right: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 18px;
  height: 18px;
  padding: 20px;
  background: url(/icons/icn_close_small.svg) center center no-repeat;
  background-size: 18px;
  &:disabled {
    cursor: default;
  }
`;

export const SendButton = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  padding: 9px 16px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  height: 40px;
  cursor: pointer;
  background: #279ae7;
  margin-left: 8px;

  box-shadow: none;
  border: none;
  border-radius: 5px;

  transition: transform 100ms linear, box-shadow 100ms linear;

  &:disabled {
    opacity: 0.5;
    color: #242424;
    background: none;
    border: 1px solid transparent;
    box-shadow: none;
    cursor: default;
    &:hover {
      background: none;
    }
  }

  &:hover {
    background: #2287ca;
  }

  &.mobile {
    margin: 0;
    margin-left: auto;
  }
`;

export const SaveButton = styled(SendButton)`
  background: #0887db;
  border: 1px solid #257bc2;
  &:hover {
    background: #257bc2;
  }
`;

export const DeleteButton = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  padding: 20px 20px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background: transparent url(/icons/icn_paper_trashcan.svg) center center no-repeat;

  transition: transform 100ms linear, box-shadow 100ms linear;

  &:disabled {
    opacity: 0.5;
    color: #242424;
    box-shadow: none;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.mobile {
    margin: 0;
    margin-left: auto;
  }
`;

export const DuplicateButton = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  padding: 20px 20px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background: transparent url(/icons/icn_duplicate.svg) center center no-repeat;

  transition: transform 100ms linear, box-shadow 100ms linear;

  &:disabled {
    opacity: 0.5;
    color: #242424;
    box-shadow: none;
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.mobile {
    margin: 0;
    margin-left: auto;
  }
`;

export const ListViewButton = styled.button.attrs({
  type: 'button',
})`
  width: 32px;
  height: 32px;
  display: block;
  border: none;
  position: relative;
  margin: 0 4px;
  background: transparent url(/icons/icn_listview.svg) center center no-repeat;
  border-radius: 50%;
  outline: none;
  -webkit-tap-highlight-color: transparent;
`;

export const NoteViewButton = styled(ListViewButton)`
  background: transparent url(/icons/icn_gridview.svg) center center no-repeat;
`;

export const ViewModeButtons = styled.div`
  color: #707070;
  display: flex;
  position: absolute;
  right: 12px;

  &.list {
    ${ListViewButton} {
      background-color: #0000000d;
    }

    ${NoteViewButton} {
      background-color: transparent;
    }
  }

  &.note {
    ${NoteViewButton} {
      background-color: #0000000d;
    }
  }
`;

export const ItemSquare = styled.span`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 2px;
  border: 1px solid #00000019;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
`;

export const ItemLabel = styled.div`
  padding: 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 58px;
  font-size: 13px;
  color: #242424;

  &.mobile {
  }
`;

export const ListItemColumn = styled.div`
  display: flex;
  max-width: calc(100% - 48px);
  justify-content: left;
  align-items: center;
  flex-grow: 1;
  padding: 12px 8px;
  user-select: none;

  &.checkbox-col {
    flex-grow: 0;
    padding: 0;

    > label {
      width: 48px;
      height: 64px;
      display: flex;
      justify-content: center;
    }
  }

  &.item-col {
    padding-left: 0;
  }

  &.mobile {
    &.checkbox-col {
      margin-left: -16px;
    }
  }
`;

export const DeleteUnderlay = styled.div`
  position: absolute;
  background: #df0b0b url(/icons/icn_deleteboard_white.svg) right 24px center no-repeat;
  background-size: 16px;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80px;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 1em;
`;

export const SendUnderlay = styled(DeleteUnderlay)`
  background: #279ae7 url(/icons/icn_send_white.svg) left 24px center no-repeat;
  top: 0;
  bottom: 0;
  right: auto;
  left: 0;
  justify-content: left;
`;

export const ItemRow = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  height: 64px;
`;

export const ListItemWrapper = styled.div`
  background: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  border-top: 1px solid #dbdbdb;
  cursor: pointer;

  &.selected {
    background-color: #eff7fc;
  }

  &.mobile {
    border: none;
    padding: 0 24px;
    position: absolute;
    left: -8px;
    right: -8px;
    top: 0;
    bottom: 0;
    width: auto;
    max-width: none;
    transform: none;
    animation: none;
    transition: transform 150ms linear;
    border-radius: 8px;

    &.selected {
      background-color: #eff7fc;

      &:hover {
        background: #eff7fc;
      }
    }

    &:hover {
      background: #fff;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &.disabled {
    cursor: default;

    &:hover {
      background: #fff;
    }
    &.selected {
      background-color: #fff;
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:last-child {
    border-bottom: 1px solid #dbdbdb;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: #2680eb url(/icons/icn_send_white.svg) center center no-repeat;
    transform: translateX(100%);
    transition: transform 200ms ease-out;
  }

  &.to-be-sent {
    transform: translateX(0) scaleY(1);
    animation: send--ListItemWrapper 200ms ease-in-out forwards;
    animation-delay: 500ms !important;

    &:after {
      transform: translateX(0);
    }
  }

  &.sent {
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgba(219, 219, 219, 0.5);
    color: rgba(96, 96, 96, 0.5);
    ${ItemSquare} {
      opacity: 0.5;
    }
  }

  animation: slideIn--ListItemWrapper 200ms ease-in-out forwards;
  transform: translateX(-100%) scaleY(1);

  @keyframes send--ListItemWrapper {
    0% {
      transform: translateX(0) scaleY(1);
    }

    100% {
      transform: translateX(0) scaleY(0);
    }
  }

  @keyframes slideIn--ListItemWrapper {
    0% {
      transform: translateX(-100%) scaleY(1);
    }

    100% {
      transform: translateX(0) scaleY(1);
    }
  }
`;

export const SendBubble = styled.button.attrs({
  type: 'button',
})`
  display: block;
  outline: none;
  min-width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  bottom: 0;
  right: 8px;
  background: #fff url(/icons/icn_send.svg) center center no-repeat;
  background-size: 16px auto;
  cursor: pointer;
  box-shadow: 0 1px 2px #00000026;
  border: 1px solid #0000001a;

  transition: transform 100ms linear, box-shadow 100ms linear;

  &:hover {
    box-shadow: none;
    transform: translateY(calc(-50% + 2px));
  }
`;

export const RecallButton = styled(SendBubble)`
  background-image: url(/icons/icn_recall.svg);
`;

export const StackHeader = styled.div`
  display: flex;
  padding: 12px 16px 9px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.32px;
  color: #242424;
  align-items: center;
  position: relative;
`;

export const StackHeaderLabel = styled.span`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.32px;
  color: #242424;
`;

export const StackHeaderButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const StickyNoteSideButton = styled.div`
  width: 40px;
  height: 40px;
  margin: 2px 0;
  background: transparent url(/icons/icn_stickynote.svg) center center no-repeat;
  background-size: 24px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 200ms ease-out;

  -webkit-tap-highlight-color: #e6e6e6;

  @media (hover) {
    &:hover {
      background-color: #e6e6e6;
    }
  }

  &.active {
    background-color: #e6e6e6;
  }

  &.disabled {
    opacity: 0.3;

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;

export const ImageSideButton = styled(StickyNoteSideButton)`
  background: transparent url(/icons/icn_import_file.svg) center center no-repeat;
  overflow: hidden;

  > label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    color: transparent;
    cursor: pointer;

    > input {
      pointer-events: none;
    }
  }
`;

export const ToolSideButton = styled(StickyNoteSideButton)<{ icon: string }>`
  background: transparent url(${(props) => props.icon}) center center no-repeat;
`;

export const InkToolButton = styled(ToolSideButton)`
  margin-right: 4px;
`;

export const ExpandButton = styled.div`
  width: 100%;
  height: 20px;
  background: transparent url(/icons/icn_page_back.svg) center center no-repeat;
  background-size: 10px;
  transition: background-color 200ms ease-out;
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }
  &.expanded {
    background-image: url(/icons/icn_page_forward.svg);
  }
`;

export const TextSideButton = styled(StickyNoteSideButton)`
  background: transparent url(/icons/icn_text.svg) center center no-repeat;
`;

export const PipWindowSideButton = styled(StickyNoteSideButton)`
  background: transparent url(/icons/icn_contentsharing.svg) center center no-repeat;
  position: relative;
`;

export const StickersSideButton = styled(StickyNoteSideButton)`
  background: transparent url(/icons/icn_stickers.svg) center center no-repeat;
  position: relative;
`;

export const PasteSideButton = styled(StickyNoteSideButton)`
  background: transparent url(/icons/icn_paste.svg) center center no-repeat;
`;

export const ToolTraySideContainer = styled(StickyNoteSideButton)`
  &:hover {
    background: none;
  }
  background: none;
`;

export const ToolTraySideButton = styled(StickyNoteSideButton)`
  background: transparent url(/icons/icn_pen.svg) center center no-repeat;
  position: relative;

  &.ink-tool-used.erase {
    background-image: url(/icons/icn_eraser.svg);
  }

  &.ink-tool-used.erase.active-pen {
    background-image: url(/icons/icn_active_eraser.svg);
  }

  &.ink-tool-used.pen {
    background-image: url(/icons/icn_pen.svg);
  }

  &.ink-tool-used.pen.active-pen {
    background-image: url(/icons/icn_active_pen.svg);
  }

  &.ink-tool-used.line {
    background-image: url(/icons/icn_line.svg);
  }

  &.ink-tool-used.line.active-pen {
    background-image: url(/icons/icn_active_line.svg);
  }
`;

export const ClipboardMenuSideButton = styled(StickyNoteSideButton)`
  background: transparent url(/icons/icn_clipboard.svg) center center no-repeat;
  position: relative;
`;

export const DoubleButton = styled(StickyNoteSideButton).attrs({
  className: 'double-button',
})`
  background: none;
  display: flex;
  height: 34px;

  &:hover {
    background: none;
  }

  ${ToolSideButton} {
    height: 32px;
  }
`;
