import styled from 'styled-components';

export const Body = styled.div`
  margin-top: 30px;
`;

export const CollapseHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  text-indent: 18px;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    width: 16px;
    height: 16px;
    background: url(/icons/icn_expand.svg) center center no-repeat;
    background-size: 10px;
    transform-origin: center;
    transition: transform 100ms linear;
  }
`;

export const Option = styled.li`
  padding: 10px 8px;
  margin: 0 8px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);

  &:last-child {
    border: none;
  }

  input {
    width: 2em;
    text-align: center;
    font-size: 16px;
    color: #242424;
    height: 16px;
    padding: 12px 8px;
    background: #fff;
    border: 2px solid #00000029;
    border-radius: 6px;
    margin: 8px 0;

    &:focus {
      background: #fafafa;
      border-color: #279ae7;
    }

    &.error {
      background: #df0b0b29;
      border-color: #df0b0b;
    }
  }
`;

export const Options = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0;
  overflow: hidden;
  max-height: 400px;
  transition: max-height 300ms ease-in-out;
`;

export const AdvancedOptionsContainer = styled.div`
  margin: 16px 0;
  text-align: left;

  &.collapsed {
    ${CollapseHeader} {
      &:before {
        transform: rotate(-90deg);
      }
    }

    ${Options} {
      max-height: 0;
    }
  }
`;
