import React from 'react';

import * as Styled from './styled';

interface Props {
  show: boolean;
  showActivePen: boolean;
}

const ToolTray: React.FC<Props> = ({ children, show, showActivePen }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <Styled.ToolTrayContainer showActivePen={showActivePen}>{children}</Styled.ToolTrayContainer>
    </>
  );
};

export default ToolTray;
