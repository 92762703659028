import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';

import CookieBanner from 'client/components/Home/cookiebanner';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';

import MobileTopBar from './MobileTopBar';
import * as Styled from './styled';

const AccountDisabled: React.FC = () => {
  const { hasAgreedToTermsAndPolicy, tenant } = useSelector((state) => ({
    hasAgreedToTermsAndPolicy: state.hasAgreedToTermsAndPolicy,
    tenant: state.tenant,
  }));

  const { agreeToTermsAndPolicy } = useActions();

  return (
    <>
      <Styled.ScrollArea>
        {isMobileOnly && <MobileTopBar />}
        <Styled.LoginForm>
          <Styled.FormIllustration src="/images/ill_warning.svg" alt="Warning" />
          <h1>
            Account Disabled
            <Styled.InfoText>
              {tenant
                ? 'Your account has been disabled by your tenant administrator. Please contact your admin to find out more.'
                : 'Your account has been disabled. Please contact our support team to find out more.'}
            </Styled.InfoText>
          </h1>
          <Link to="/login">Go Back</Link>
        </Styled.LoginForm>
      </Styled.ScrollArea>
      <CookieBanner accepted={hasAgreedToTermsAndPolicy} accept={() => agreeToTermsAndPolicy(true)} />
    </>
  );
};

export default AccountDisabled;
