import styled from 'styled-components';

import { Buttons } from '../my-account/modals-styled';
import { TeamBubble } from '../styled';

export const Container = styled.div`
  position: fixed;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px;
  max-width: 98vw;
  max-height: calc(100vh - 128px);
  min-height: 250px;

  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 0 0.5px #00000014, 0 16px 32px #00000028;
  color: #242424;

  display: flex;
  flex-direction: column;

  padding: 40px;

  input,
  textarea {
    margin-bottom: 16px;
    padding: 13px 16px;
  }

  p {
    margin-top: 8px;
    font-size: 13px;
    color: #242424;
    font-weight: 500;
    display: block;
    margin-bottom: 24px;
  }

  ${TeamBubble} {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  ${Buttons} {
    margin: 32px 0 0 0;
    justify-content: center;

    button {
      margin: 0 8px;
    }
  }
`;

export const EmailErrors = styled.div`
  color: #df0b0b;
  margin: 8px 0 16px 0;
  font-size: 12px;
  text-align: left;
`;

export const Title = styled.div`
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const TeamInput = styled.input`
  padding-left: 48px !important;
`;

export const TeamMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  margin-top: 16px;
`;

export const Name = styled.span`
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  margin-left: 8px;
  display: block;
`;

export const Email = styled.span`
  color: #8c8c8c;
  font-size: 10px;
  line-height: 14px;
  font-weight: normal;
  margin-left: 8px;
  display: block;
`;

export const RoleTag = styled.div.attrs<{ immutable?: boolean }>((props) => ({
  className: props.immutable ? 'nopointer' : '',
}))<{ immutable?: boolean }>`
  opacity: 75%;
  cursor: pointer;
  flex-grow: 0;
  text-align: right;
  margin-left: auto;
  transition: opacity 120ms ease-out, background-color 120ms ease-out;

  &.nopointer {
    cursor: default;
  }

  > span.remove {
    display: block;
    width: 32px;
    height: 32px;
    background: url(/icons/icn_close_small.svg) center center no-repeat;

    &:hover {
      border-radius: 50%;
      background-color: #00000014;
    }
  }

  &:hover {
    opacity: 1 !important;
  }
`;

export const TeamMemberListItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  height: 40px;
  flex-shrink: 0;
  padding: 0 8px;
  cursor: default;
  color: #242424;

  &:hover {
    background-color: #0000000a;
    border-radius: 5px;
  }

  &.pending {
    color: #8c8c8c;

    ${RoleTag} {
      opacity: 0.32;
    }
  }
`;

export const CloseButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 16px;
  right: 16px;
  color: #999;
  border: none;
  padding: 8px;
  margin: 0;
  font-weight: 800;
  outline: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  background-size: cover;

  background: url(/icons/icn_close_small.svg) center center no-repeat;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const InviteButton = styled.div`
  display: inline-flex;
  width: fit-content;
  font-size: 13px;
  color: #2287ca;
  font-weight: 600;
  border-radius: 20px;
  transition: background-color 300ms ease-out;
  justify-content: space-around;
  margin: 24px auto 0 auto;

  a,
  span {
    padding: 7px 8px;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  &:hover {
    background-color: #279ae714;
  }
`;

export const MoveTeamItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  height: 40px;

  ${TeamBubble} {
    position: static;
  }
`;

export const TeamDialogListImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #0000000a url(/icons/icn_invite_pending.svg) center center no-repeat;
`;

export const MoveButton = styled.button`
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #279ae7;
  background-color: #279ae7;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  text-transform: uppercase;
  letter-spacing: -0.5px;

  &:hover {
    background-color: #44aff7;
    color: #ffffff;
  }
`;
