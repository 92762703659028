import React, { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import ZCanvas, { ZPaperMenuInfo } from '@flatfrog/ffbec';
import { ZActions, ZPaperType, ZShapes } from '@flatfrog/ffbec/js/zwasm';

import * as analytics from 'client/common/analytics';
import { trashPaper, trashSelectedPapers } from 'client/common/helpers/ZCanvasHelpers';
import NumberAdornmentMaker from 'client/common/numberAdornmentCanvas';
import { getParticipantColorRGB } from 'client/common/participant-names';
import configure, { setMargins } from 'client/common/ZCanvasAppSettings';
import ZoomControls from 'client/components/Whiteboard/ZoomControls';
import { useActions } from 'client/hooks/useActions';
import usePrevious from 'client/hooks/usePrevious';
import { useSelector } from 'client/hooks/useSelector';
import { getClipboardingEnabled } from 'client/state/selectors';
import { Listener } from 'client/state/misc/actionListeners';

import EditPaperLink from './EditPaperLink';
import LoadingBoard from './LoadingBoard';
import PageControls from './PageControls';
import PaperLink from './PaperLink';
import PaperMenu from './PaperMenu';
import * as Styles from './styles';
import { ZOOM_IN_ACTION, ZOOM_OUT_ACTION } from './ZoomControls';

interface Props {
  hideGui: boolean;
}

const WhiteBoard: React.FC<Props> = ({ hideGui }) => {
  const {
    clipboardingEnabled,
    currentZAction,
    isViewOnlyMode,
    loadedFileInfo,
    room,
    selectedPapers,
    session,
    showDisconnectDialog,
    showSessionEndedDialog,
    signatureText,
  } = useSelector((state) => ({
    clipboardingEnabled: getClipboardingEnabled(state),
    currentZAction: state.currentZAction,
    isViewOnlyMode: state.isViewOnlyMode,
    loadedFileInfo: state.loadedFileInfo,
    room: state.room,
    selectedPapers: state.selectedPapers,
    session: state.session,
    showDisconnectDialog: state.showDisconnectDialog,
    showSessionEndedDialog: state.showSessionEndedDialog,
    signatureText: state.signatureText,
  }));

  const {
    addActionListener,
    addPaperLinkAdornment,
    connectSessionSocket,
    disableClipBoarding,
    editPaper,
    prepareBoardEvent,
    preparePing,
    removeActionListener,
    setCanUndoRedo,
    setInitialLoadingDone,
    setSelectedPapers,
  } = useActions();

  const [doubleTappedPaper, setDoubleTappedPaper] = useState(null);
  const [cursor, setCursor] = useState('default');
  const [initialised, setInitialised] = useState(false);
  const [longpressOn, setLongpressOn] = useState(false);
  const [firstLoadingDone, setFirstLoadingDone] = useState(false);
  const [editPaperLinks, setEditPaperLinks] = useState([]);
  const [touchPaperMenus, setTouchPaperMenus] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [manipulatedPaperIds, setManipulatedPaperIds] = useState([]);
  const [paperMenuInfoForSelectedPapers, setPaperMenuInfoForSelectedPapers] = useState<ZPaperMenuInfo>(null);

  const previousEvent = useRef<string>();
  const zCanvas = useRef<HTMLCanvasElement>(null);
  const wrapper = useRef(null);
  const cornerCursorIndex = useRef(0);
  const cornerCursors = ['sw-resize', 'se-resize', 'ne-resize', 'nw-resize'];
  const editPaperLinksRef = useRef([]);

  useEffect(() => {
    editPaperLinksRef.current = editPaperLinks;
    disableClipBoarding('editPaperLinks', editPaperLinks.length > 0);
  }, [editPaperLinks]);

  const touchPaperMenusRef = useRef([]);

  useEffect(() => {
    touchPaperMenusRef.current = touchPaperMenus;
  }, [touchPaperMenus]);

  const prevInitialised = usePrevious(initialised);

  useHotkeys(
    'backspace, delete',
    () => {
      if (clipboardingEnabled && selectedPapers.length > 0) {
        trashSelectedPapers();
        analytics.deleteFromMenu(selectedPapers.length);
      }
    },
    {},
    [clipboardingEnabled, selectedPapers]
  );

  useHotkeys(
    'alt + g',
    () => {
      if (ZCanvas.paper.arrangeSelectedPaperValid()) {
        ZCanvas.paper.arrangeSelectedPaperInGrid();
      }
    },
    [clipboardingEnabled, selectedPapers]
  );

  useHotkeys(
    'alt + s',
    () => {
      if (ZCanvas.paper.arrangeSelectedPaperValid()) {
        ZCanvas.paper.arrangeSelectedPaperInStack();
      }
    },
    [clipboardingEnabled, selectedPapers]
  );

  useHotkeys(
    'enter',
    (e) => {
      if (
        !clipboardingEnabled ||
        !paperMenuInfoForSelectedPapers ||
        paperMenuInfoForSelectedPapers.validPaperIds.length === 0 ||
        paperMenuInfoForSelectedPapers.validPaperIds.filter((paperId) => manipulatedPaperIds.includes(paperId))
          .length !== 0
      ) {
        return;
      }
      if (paperMenuInfoForSelectedPapers.nbrStackBasePapers !== 1) {
        return;
      }
      const paperType =
        paperMenuInfoForSelectedPapers.paperTypes[paperMenuInfoForSelectedPapers.firstStackBasePaperIndex];
      if (paperType !== ZPaperType.STICKY_NOTE && paperType !== ZPaperType.TEXTBOX) {
        return;
      }

      e.preventDefault();
      editPaper(paperMenuInfoForSelectedPapers.validPaperIds[paperMenuInfoForSelectedPapers.firstStackBasePaperIndex]);
    },
    {},
    [clipboardingEnabled, paperMenuInfoForSelectedPapers]
  );

  const doneLoading = (initialStateDone: boolean) => {
    if (initialStateDone) {
      setFirstLoadingDone(true);
      ZCanvas.endPreloading();
      ZCanvas.history.setUndoLowWatermark();
      analytics.markBoardLoading('loaded');
      analytics.endBoardLoading();
      setInitialLoadingDone(firstLoadingDone);
    }
  };

  const handleAction: Listener = (action, initialStateDone) => {
    if (action.latestCorrectActionId) {
      ZCanvas.enqueueServerCommand({
        type: 'rewind',
        actionId: action.latestCorrectActionId,
        preventRedo: action.preventRedo === true,
      });
    } else {
      const actions = Array.isArray(action.json) ? action.json : [action.json];
      ZCanvas.enqueueServerCommand({
        type: 'json',
        actions,
        callbacks: initialStateDone ? [() => doneLoading(initialStateDone)] : [],
      });
    }
  };

  const handleZCanvasCanUndoRedoChanged = (msg: { canUndo: boolean; canRedo: boolean }[]) => {
    for (const event of msg) {
      setCanUndoRedo(event.canUndo, event.canRedo);
    }
  };

  const handleInitialized = () => {
    setInitialised(true);

    console.log(`Whiteboard: ZCanvas initialized, doing post-init...`);
    const timeBegin = performance.now();

    analytics.markBoardLoading('whiteboardinited');
    connectSessionSocket();

    const timeEnd = performance.now();
    console.log(`Whiteboard: Post-init took ${timeEnd - timeBegin} ms.`);
  };

  const handleLongPress = (events: { pageId: number; x: number; y: number }[]) => {
    for (const event of events) {
      const color = getParticipantColorRGB(signatureText);
      ZCanvas.page.ping(event.pageId, event.x, event.y, color, true);
    }
  };

  const handlePagePing = (events: { pageId: number; x: number; y: number }[]) => {
    for (const event of events) {
      const color = getParticipantColorRGB(signatureText);

      preparePing({
        pageId: event.pageId,
        x: event.x,
        y: 2160.0 - event.y,
        color,
      });
    }
  };

  // FIXME: hotkeys
  const handleUndoRedoKeys = (e: KeyboardEvent) => {
    if (!clipboardingEnabled) {
      return;
    }

    if ((e.getModifierState('Control') || e.getModifierState('Meta')) && e.key.toLowerCase() === 'z') {
      if (e.getModifierState('Shift')) {
        ZCanvas.redo();
        e.preventDefault();
        analytics.redo();
      } else {
        ZCanvas.undo();
        e.preventDefault();
        analytics.undo();
      }
    }
  };

  const handleTouchMove = (e: TouchEvent & { scale: number }) => {
    if (e.scale !== 1) {
      e.preventDefault();
    }
  };

  const handlePaperVotesChanged = (events: { paperId: number; votes: number }[]) => {
    for (const event of events) {
      NumberAdornmentMaker.update(event.paperId);
    }
  };

  const updateLinkAdornment = (paperId: number) => {
    const metadataString = ZCanvas.paper.getMetadata(paperId);
    let metadata;
    try {
      metadata = JSON.parse(metadataString);
    } catch (err) {
      // Ignore
    }
    const isBackgroundPaper =
      ZCanvas.paper.getPageHasBackgroundPaper() && ZCanvas.paper.getPageBackgroundPaper() === paperId;
    if (metadata && metadata.Link && !isBackgroundPaper) {
      addPaperLinkAdornment(paperId);
    } else {
      ZCanvas.paper.setBottomLeftAdornment(paperId);
    }
  };

  const handlePaperMetadataChanged = (events: { paperId: number }[]) => {
    for (const event of events) {
      updateLinkAdornment(event.paperId);
    }
  };

  const handlePreloadEnded = () => {
    const noMargins = hideGui || room || isViewOnlyMode;
    configure(noMargins, room);
    const paperIds = ZCanvas.page
      .getIds()
      .map((pageId) => ZCanvas.paper.getPaperIds(pageId))
      .flat()
      .filter((paperId) => {
        const metadataString = ZCanvas.paper.getMetadata(paperId);
        try {
          const metadata = JSON.parse(metadataString);
          return metadata && metadata.Link;
        } catch (err) {
          // ignore
        }
        return false;
      })
      .filter(
        (paperId: number) =>
          !ZCanvas.paper.getPageHasBackgroundPaper() || ZCanvas.paper.getPageBackgroundPaper() !== paperId
      );
    paperIds.forEach((paperId) => {
      addPaperLinkAdornment(paperId);
    });
  };

  useEffect(() => {
    if (initialised) {
      (async () => {
        await ZCanvas.initialized;
        NumberAdornmentMaker.clearCache();
        const tapConfig = ZCanvas.getTapConfig();
        if (longpressOn) {
          ZCanvas.setTapConfig({
            ...tapConfig,
            longPressAnimationStart: 0.35,
            longPressMinDelta: 1,
            isMultiLayerSelectEnabled: true,
          });
        } else {
          ZCanvas.setTapConfig({
            ...tapConfig,
            longPressAnimationStart: 99,
            longPressMinDelta: 99,
            isMultiLayerSelectEnabled: true,
          });
        }
      })();
    }
  }, [initialised, longpressOn]);

  // Change cursor depending on tool
  useEffect(() => {
    const [action, zShape] = currentZAction;

    if (action === ZActions.DRAW && zShape === ZShapes.FREEFORM) {
      zCanvas.current.style.cursor = 'url(/icons/cursor_inking_24x24.png) 0 23, crosshair';
    } else if (action === ZActions.DRAW && zShape === ZShapes.LINE) {
      zCanvas.current.style.cursor = 'crosshair';
    } else if (action === ZActions.ERASE) {
      zCanvas.current.style.cursor = 'url(/icons/cursor_eraser_24x24.png) 0 23, auto';
    } else if (action === ZOOM_IN_ACTION) {
      zCanvas.current.style.cursor = 'zoom-in';
    } else if (action === ZOOM_OUT_ACTION) {
      zCanvas.current.style.cursor = 'zoom-out';
    } else if (action === ZActions.PANZOOM) {
      zCanvas.current.style.cursor = 'grab';
    } else {
      zCanvas.current.style.cursor = 'auto';
    }
  }, [currentZAction]);

  useEffect(() => {
    const canvas = zCanvas.current;
    canvas.style.cursor = cursor;
  }, [cursor]);

  useEffect(() => {
    console.log('Whiteboard: Initialize...');
    const initTimeBegin = performance.now();

    const isElectron = () => {
      // Renderer process
      if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
      }

      // Main process
      if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
      }

      // Detect the user agent when the `nodeIntegration` option is set to true
      if (
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        navigator.userAgent.indexOf('Electron') >= 0
      ) {
        return true;
      }

      return false;
    };

    const isDesktopBecNative = isElectron() && window.electronApi?.ffbec_addon;
    const isAndroidBecNative = typeof window.ffbecandroidjni !== 'undefined';

    if (isDesktopBecNative) {
      const platform = window.electronApi.sendSync('getPlatform') as string;
      window.becType = platform;
    } else if (isAndroidBecNative) {
      window.becType = 'android';
    } else {
      window.becType = 'wasm';
    }

    analytics.becType(window.becType);
    analytics.appType(room ? 'room' : 'web');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ZCanvas(zCanvas.current, room && (isDesktopBecNative || isAndroidBecNative), room);

    analytics.whiteboardCreated();

    ZCanvas.initialized.then(() => {
      handleInitialized();
    });

    ZCanvas.addEventListener('emitAction', prepareBoardEvent);
    ZCanvas.addEventListener('emitImage', prepareBoardEvent);
    ZCanvas.addEventListener('emitInkImage', prepareBoardEvent);
    ZCanvas.addEventListener('emitUndo', prepareBoardEvent);
    ZCanvas.addEventListener('emitRedo', prepareBoardEvent);
    ZCanvas.addEventListener('canUndoRedoChanged', handleZCanvasCanUndoRedoChanged);

    zCanvas.current.addEventListener('touchmove', handleTouchMove, { passive: false });

    const initTimeEnd = performance.now();
    console.log(`Whiteboard: Initialization took ${initTimeEnd - initTimeBegin} ms.`);

    return () => {
      analytics.whiteboardDestroyed();
      removeActionListener(handleAction);

      ZCanvas.hide();

      setTimeout(() => {
        if (ZCanvas.getZcoreCreated()) {
          console.log('destroy from Whiteboard component.');
          ZCanvas.destroy();
          window.becType = undefined;
        }
      }, 1);

      if (zCanvas.current) {
        zCanvas.current.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUndoRedoKeys);
    return () => {
      window.removeEventListener('keydown', handleUndoRedoKeys);
    };
  }, [clipboardingEnabled]);

  useEffect(() => {
    // TODO: Clean up this mess
    const { clients } = session;
    if (initialised) {
      if (session && clients?.length > 0) {
        if (clients?.length > 0) {
          setLongpressOn(true);
        } else {
          setLongpressOn(false);
        }
      } else {
        setLongpressOn(false);
      }
    }
  }, [initialised, session]);

  const handleManipulationStarted = (events: { paperId: number }[]) => {
    for (const event of events) {
      const { paperId } = event;
      setManipulatedPaperIds([...manipulatedPaperIds, paperId]);
      if (touchPaperMenusRef.current.some((m) => m.paperId === paperId || m.stackedPaperIds.includes(paperId))) {
        setTouchPaperMenus(
          touchPaperMenusRef.current.filter((m) => m.paperId !== paperId && !m.stackedPaperIds.includes(paperId))
        );
      }
      if (editPaperLinksRef.current.some((l) => l.paperId === paperId)) {
        setEditPaperLinks(editPaperLinksRef.current.filter((l) => l.paperId !== paperId));
      }
    }

    if (previousEvent.current === 'mouseout') {
      setCursor('grabbing');
    }

    if (previousEvent.current === 'cornerout') {
      console.log(cornerCursorIndex);
      setCursor(cornerCursors[cornerCursorIndex.current]);
    }

    previousEvent.current = 'manipulationstart';
  };

  const handleManipulationEnded = (events: { paperId: number }[]) => {
    for (const event of events) {
      const { paperId } = event;
      setManipulatedPaperIds(manipulatedPaperIds.filter((id) => id !== paperId));

      // Remove link badge on background paper
      if (ZCanvas.paper.getPageHasBackgroundPaper() && ZCanvas.paper.getPageBackgroundPaper() === paperId) {
        ZCanvas.paper.setBottomLeftAdornment(paperId);
      }
    }

    if (previousEvent.current !== 'mouseover') {
      setCursor('default');
    }

    previousEvent.current = 'manipulationend';
  };

  const handleManipulationCancelled = (events: { paperId: number }[]) => {
    for (const event of events) {
      const { paperId } = event;
      setManipulatedPaperIds(manipulatedPaperIds.filter((id) => id !== paperId));
    }
  };

  const handlePaperCorner = (events: { cornerIndex: number; type: string }[]) => {
    for (const event of events) {
      if (event.type === 'PAPER_CORNER_HIGHLIGHT_STARTED') {
        previousEvent.current = 'cornerover';
        cornerCursorIndex.current = event.cornerIndex;
        setCursor(cornerCursors[event.cornerIndex]);
      } else {
        setCursor('default');

        if (previousEvent.current === 'mouseover') {
          setCursor('grab');
        }

        previousEvent.current = 'cornerout';
      }
    }
  };

  const handlePaperAnyHighlightedChanged = (events: { anyHighlighted: boolean }[]) => {
    for (const event of events) {
      // TODO: only do this is select mode
      if (event.anyHighlighted) {
        setCursor('grab');
        previousEvent.current = 'mouseover';
      } else {
        setCursor('default');
        previousEvent.current = 'mouseout';
      }
    }
  };

  const handlePaperTapped = (events: { paperId: number; selectionBased: boolean }[]) => {
    for (const event of events) {
      const { paperId, selectionBased } = event;

      if (!selectionBased) {
        if (touchPaperMenusRef.current.some((m) => m.paperId === paperId || m.stackedPaperIds.includes(paperId))) {
          setTouchPaperMenus(
            touchPaperMenusRef.current.filter((m) => m.paperId !== paperId && !m.stackedPaperIds.includes(paperId))
          );
        } else {
          const stackedPaperIds: number[] = [];
          const pageId = ZCanvas.page.getCurrentId();
          const paperIds = ZCanvas.paper.getPaperIds(pageId);
          for (let i = paperIds.indexOf(paperId) + 1; i < paperIds.length; i += 1) {
            const stackParent = ZCanvas.paper.getStackedOnPaperId(paperIds[i]);
            if (stackParent && (stackParent === paperId || stackedPaperIds.includes(stackParent))) {
              stackedPaperIds.push(paperIds[i]);
            }
          }
          setTouchPaperMenus([...touchPaperMenusRef.current, { paperId, stackedPaperIds }]);
          ZCanvas.paper.deselectAll();
          setSelectedPapers([]);
        }
      }
    }
  };

  const handlePaperDoubleTapped = (events: { paperId: number }[]) => {
    for (const event of events) {
      // We don't have the properties here, so use an intermediate state for indicating double taps
      setDoubleTappedPaper(event.paperId);
    }
  };

  useEffect(() => {
    if (!room && doubleTappedPaper) {
      editPaper(doubleTappedPaper);
      setDoubleTappedPaper(null);
    }
  }, [doubleTappedPaper]);

  const handlePaperSelectionsChanged = () => {
    const selectedPaperIds = ZCanvas.paper.getSelectedIds();
    setSelectedPapers(selectedPaperIds);
    if (selectedPaperIds.length > 0) {
      setTouchPaperMenus([]);
      setEditPaperLinks([]);
    }
  };

  const handlePaperRemoved = (events: { paperId: number }[]) => {
    for (const event of events) {
      trashPaper({ paperId: event.paperId, removePaper: false });
    }
  };

  const handleSelectStarted = () => {
    setIsSelecting(true);
  };

  const handleSelectEnded = () => {
    setIsSelecting(false);
  };

  const handleCurrentPageChanged = () => {
    setTouchPaperMenus([]);
    setEditPaperLinks([]);
  };

  const setCanvasMargins = () => {
    ZCanvas.initialized.then(() => {
      const noMargins = hideGui || room || isViewOnlyMode;
      setMargins(noMargins);
    });
  };

  useEffect(() => {
    setCanvasMargins();
  }, [hideGui, room, isViewOnlyMode]);

  // Set any general configs
  useEffect(() => {
    if (!prevInitialised && initialised) {
      ZCanvas.beginPreloading();
      addActionListener(handleAction);

      // Ping feature
      setLongpressOn(false);
      const defaultPingConfig = ZCanvas.getPingConfig();
      ZCanvas.setPingConfig({
        ...defaultPingConfig,
        fadeDuration: 2.0,
        firstGrowDuration: 0.75,
        maxRadiiPixels: 120.0,
        minRadiiPixels: 33.0,
        widenDuration: 0.2,
      });
      ZCanvas.addEventListener('LONG_PRESS', handleLongPress);
      ZCanvas.addEventListener('PAGE_PING', handlePagePing);

      ZCanvas.addEventListener('PAPER_TAPPED', handlePaperTapped);
      ZCanvas.addEventListener('PAPER_DOUBLE_TAPPED', handlePaperDoubleTapped);

      ZCanvas.addEventListener('PAPER_REMOVED', handlePaperRemoved);

      ZCanvas.addEventListener('PAPER_MANIPULATION_STARTED', handleManipulationStarted);
      ZCanvas.addEventListener('PAPER_MANIPULATION_ENDED', handleManipulationEnded);
      ZCanvas.addEventListener('PAPER_MANIPULATION_CANCELLED', handleManipulationCancelled);
      ZCanvas.addEventListener('PAPER_CORNER_HIGHLIGHT_STARTED', handlePaperCorner);
      ZCanvas.addEventListener('PAPER_CORNER_HIGHLIGHT_ENDED', handlePaperCorner);
      ZCanvas.addEventListener('PAPER_ANY_HIGHLIGHTED_CHANGED', handlePaperAnyHighlightedChanged);

      ZCanvas.addEventListener('PAPER_SELECTIONS_CHANGED', handlePaperSelectionsChanged);
      ZCanvas.addEventListener('SELECT_ACTION_STARTED', handleSelectStarted);
      ZCanvas.addEventListener('SELECT_ACTION_ENDED', handleSelectEnded);

      ZCanvas.addEventListener('CURRENT_PAGE_CHANGED', handleCurrentPageChanged);

      // Voting feature
      ZCanvas.addEventListener('PAPER_VOTES_CHANGED', handlePaperVotesChanged);

      // Paper links
      ZCanvas.addEventListener('PAPER_METADATA_CHANGED', handlePaperMetadataChanged);
      ZCanvas.addEventListener('preloadEnded', handlePreloadEnded);

      const noMargins = hideGui || room || isViewOnlyMode;
      configure(noMargins, room);
    }
  }, [initialised]);

  useEffect(() => {
    if (selectedPapers?.length > 0) {
      const paperMenuInfo = ZCanvas.paper.getPaperMenuInfo(selectedPapers);
      setPaperMenuInfoForSelectedPapers(paperMenuInfo);
    } else {
      setPaperMenuInfoForSelectedPapers(null);
    }
  }, [selectedPapers]);

  let loadingText;
  if (loadedFileInfo?.new || loadedFileInfo?.template) {
    loadingText = 'Creating Board\u2026';
  } else if (loadedFileInfo?.id) {
    loadingText = 'Opening Board\u2026';
  }

  const showEditPaperLinkForPaper = (paperId: number) => editPaperLinks.map((e) => e.paperId).includes(paperId);

  const showPaperLinkForPaper = (paperId: number) => {
    const metadataString = ZCanvas.paper.getMetadata(paperId);
    if (!metadataString) {
      return false;
    }
    let metadata;
    try {
      metadata = JSON.parse(metadataString);
    } catch (err) {
      console.log('paper without metadata');
    }
    if (!metadata || !metadata.Link) {
      return false;
    }
    if (showEditPaperLinkForPaper(paperId)) {
      return false;
    }
    return true;
  };

  const showPaperLinkForPapers = (paperIds: number[]) => {
    const paperMenuInfo = ZCanvas.paper.getPaperMenuInfo(paperIds);
    if (paperMenuInfo.nbrStackBasePapers !== 1) {
      return false;
    }
    return showPaperLinkForPaper(paperMenuInfo.validPaperIds[paperMenuInfo.firstStackBasePaperIndex]);
  };

  // Note: A paper layer < 0 means that the paper id doesn't have a corresponding paper
  // This might happen when papers are created/removed in rapid succession
  const validPaperId = (paperId: number) =>
    ZCanvas.paper.getPaperLayer(paperId) >= 0 && !manipulatedPaperIds.includes(paperId);

  return (
    <>
      {!isMobileOnly && <PageControls ready={initialised} hidden={hideGui} />}
      {!isMobileOnly && !isViewOnlyMode && !room && <ZoomControls hidden={hideGui} />}
      <Styles.WhiteboardWrapper ref={wrapper}>
        <Styles.Canvas ref={zCanvas} />
        {touchPaperMenus.map(
          (m) =>
            validPaperId(m.paperId) &&
            !showEditPaperLinkForPaper(m.paperId) && (
              <PaperMenu
                key={m.paperId}
                ready={initialised}
                element={zCanvas}
                paperIds={[m.paperId, ...m.stackedPaperIds]}
                isSelectionBased={false} // No selections on touch, so shortcuts won't work
                hideMenu={() => {
                  if (touchPaperMenusRef.current.some((menu) => menu.paperId === m.paperId)) {
                    setTouchPaperMenus(touchPaperMenusRef.current.filter((menu) => menu.paperId !== m.paperId));
                  }
                }}
                setEditPaperLink={(id) => setEditPaperLinks([...editPaperLinks, { paperId: id }])}
              />
            )
        )}
        {touchPaperMenus.map(
          (m) =>
            validPaperId(m.paperId) &&
            showPaperLinkForPaper(m.paperId) && <PaperLink key={m.paperId} ready={initialised} paperId={m.paperId} />
        )}

        {touchPaperMenus.map(
          (m) =>
            validPaperId(m.paperId) &&
            !room &&
            showEditPaperLinkForPaper(m.paperId) && (
              <EditPaperLink
                key={m.paperId}
                ready={initialised}
                paperId={m.paperId}
                finishEditPaperLink={(id) => setEditPaperLinks(editPaperLinks.filter((l) => l.paperId !== id))}
              />
            )
        )}

        {!isSelecting &&
          paperMenuInfoForSelectedPapers?.validPaperIds.length > 0 &&
          !showEditPaperLinkForPaper(
            paperMenuInfoForSelectedPapers.validPaperIds[paperMenuInfoForSelectedPapers.firstStackBasePaperIndex]
          ) && (
            <PaperMenu
              ready={initialised}
              element={zCanvas}
              paperIds={paperMenuInfoForSelectedPapers.validPaperIds}
              isSelectionBased
              hideMenu={() => {
                setSelectedPapers([]);
              }}
              setEditPaperLink={(id) => setEditPaperLinks([...editPaperLinks, { paperId: id }])}
            />
          )}
        {!isSelecting &&
          paperMenuInfoForSelectedPapers?.validPaperIds.length > 0 &&
          showPaperLinkForPapers(selectedPapers) && (
            <PaperLink
              ready={initialised}
              paperId={
                paperMenuInfoForSelectedPapers.validPaperIds[paperMenuInfoForSelectedPapers.firstStackBasePaperIndex]
              }
            />
          )}
        {!isSelecting &&
          paperMenuInfoForSelectedPapers?.validPaperIds.length > 0 &&
          showEditPaperLinkForPaper(
            paperMenuInfoForSelectedPapers.validPaperIds[paperMenuInfoForSelectedPapers.firstStackBasePaperIndex]
          ) && (
            <EditPaperLink
              ready={initialised}
              paperId={
                paperMenuInfoForSelectedPapers.validPaperIds[paperMenuInfoForSelectedPapers.firstStackBasePaperIndex]
              }
              finishEditPaperLink={(id) => setEditPaperLinks(editPaperLinks.filter((l) => l.paperId !== id))}
            />
          )}
      </Styles.WhiteboardWrapper>
      {firstLoadingDone === false &&
        !showSessionEndedDialog.show &&
        !showDisconnectDialog?.show &&
        !(room && loadedFileInfo?.new) && (
          <LoadingBoard
            small={hideGui}
            loadingText={loadingText}
            helpText={loadingText && 'Depending on your connection this might take a moment'}
          />
        )}
    </>
  );
};

export default WhiteBoard;
