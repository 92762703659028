import styled from 'styled-components';

export const FloatingInfoText = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-direction: column;

  @keyframes grow--FloatingInfoText {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  > div {
    text-align: center;
    transform: scale(0);

    animation: grow--FloatingInfoText 300ms ease-in-out forwards;
  }

  h1,
  span {
    font-size: 18px;
    color: #242424;
    text-transform: none;
    margin-bottom: 8px;
    font-weight: 600;
  }

  span {
    font-size: 13px;
    font-weight: normal;
  }

  img {
    margin: 0 auto;
    display: block;
  }
`;
