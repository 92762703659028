import styled from 'styled-components';

export const Container = styled.div`
  align-self: flex-end;
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
  animation: slide-in--debug-view 500ms ease;
  @keyframes slide-in--debug-view {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const HorizontalStack = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;
`;

export const View = styled.div`
  height: 50px;
  background-color: white;
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 0 0 0.5px rgba(0, 0, 0, 0.08);

  &.error {
    background-color: rgba(255, 220, 220, 1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 0 0 2px red;
  }
`;

export const Title = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 13px;
`;

export const SubTitle = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 10px;
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
  margin-left: 5px;
  cursor: pointer;
  border-radius: 4px;
  border-width: 1px;

  &:hover {
    background-color: #aaaaaa;
  }
`;

export const CloseButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  background: transparent url(/icons/icn_close_small.svg) center center no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  background-size: 8px;
  border-radius: 4px;
`;
