import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';

import * as Styled from 'client/components/Dashboard/admin/styled';
import * as Assets from 'client/components/Dashboard/admin/assets';
import { useFetch } from 'client/hooks/useFetch';
import { Invite, Member } from 'client/components/Dashboard/admin/types';
import List from 'client/components/Dashboard/admin/list/list';
import User from 'client/components/Common/user';
import FilterBox from 'client/components/Dashboard/admin/filterbox';
import { ContextMenu } from 'client/components/Common/ContextMenu';
import { useAuth } from 'client/hooks/useAuth';
import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import { getFirebaseAuth } from 'client/common/firebase';
import * as rest from 'client/services/rest';
import { useSelector } from 'client/hooks/useSelector';
import InviteUsersDialog from 'client/components/Dashboard/admin/users/invite-dialog';
import { ButtonProfile, ButtonSize } from 'client/components/Dashboard/admin/styled';
import { useAppServerUrlWithTenant } from 'client/hooks/useAppServerUrlWithTenant';

const Users: React.FC = () => {
  const appServerUrl = useAppServerUrlWithTenant();
  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    collaborationServerUrl: state.collaborationServerUrl,
    tenant: state.tenant,
  }));

  const users = useFetch<Member[]>('users', [], (data) =>
    data.sort((a, b) => a.displayName?.localeCompare(b.displayName))
  );

  const invites = useFetch<Invite[]>('invites', [], (data) =>
    data
      .filter((_invites) => !(_invites.dateAccepted || _invites.dateRevoked))
      .sort((a, b) => dayjs(b.dateCreated ?? 0).diff(a.dateCreated ?? 0))
  );

  const auth = useAuth();

  const [showInviteUsersDialog, setShowInviteUsersDialog] = useState(false);

  const [showDisabledUsers, setShowDisabledUsers] = useState(false);
  const [userToDisableOrReEnable, setUserToDisableOrReEnable] = useState<Member | null>(null);

  const [inviteToRevoke, setInviteToRevoke] = useState<Invite | null>(null);

  const revokeInvite = useCallback(async () => {
    if (!inviteToRevoke) {
      return;
    }

    const token = await getFirebaseAuth().currentUser.getIdToken();
    await rest.makeRequest({
      method: 'DELETE',
      url: `${collaborationServerUrl}/tenant/${tenant.id}/invites/${inviteToRevoke.id}`,
      authorizationToken: { auth: token },
    });

    setInviteToRevoke(null);
    invites.fetch();
  }, [collaborationServerUrl, tenant, inviteToRevoke]);

  const disableOrReEnableUser = useCallback(async () => {
    if (!userToDisableOrReEnable) {
      return;
    }

    const token = await getFirebaseAuth().currentUser.getIdToken();
    await rest.makeRequest({
      method: 'POST',
      url: `${collaborationServerUrl}/tenant/${tenant.id}/users/${userToDisableOrReEnable.id}/${
        userToDisableOrReEnable.disabled ? 'reenable' : 'disable'
      }`,
      authorizationToken: { auth: token },
    });

    setUserToDisableOrReEnable(null);
    users.fetch();
  }, [collaborationServerUrl, tenant, userToDisableOrReEnable]);

  const copyInviteLink = useCallback((invite: Invite) => {
    navigator.clipboard.writeText(`https://${appServerUrl}/login?invite=${invite.id}`);
  }, []);

  return (
    <>
      <Styled.ColumnHeader>
        <Styled.ColumnHeaderIcon>
          <Assets.AdminUsersIcon />
        </Styled.ColumnHeaderIcon>
        <Styled.ColumnHeaderText>Users</Styled.ColumnHeaderText>
        <Styled.ColumnHeaderRight>
          <FilterBox
            items={[
              { title: 'Hide disabled users', value: false },
              { title: 'Show disabled users', value: true },
            ]}
            selectedValue={showDisabledUsers}
            onSelected={(value) => setShowDisabledUsers(value)}
          />
        </Styled.ColumnHeaderRight>
      </Styled.ColumnHeader>
      <List
        items={users.data.filter((u) => (showDisabledUsers ? true : !u.disabled))}
        itemKey={(user) => user.id}
        itemColumns={[
          {
            id: 'user',
            title: 'User',
            header: <Styled.FlexBox />,
            content: (user) => (
              <User displayName={user.displayName} email={user.email} admin={user.admin} disabled={user.disabled} />
            ),
            sort: (items, asc) => items.sort((a, b) => a.displayName?.localeCompare(b.displayName) * (asc ? 1 : -1)),
          },
          {
            id: 'menu',
            content: (user) =>
              user.id !== auth.user.email && (
                <ContextMenu
                  items={[
                    [
                      {
                        title: user.disabled ? 'Re-enable' : 'Disable',
                        disabled: users.loading,
                        onClick: () => setUserToDisableOrReEnable(user),
                      },
                    ],
                  ]}
                >
                  <Styled.ContextMenuButton />
                </ContextMenu>
              ),
          },
        ]}
        loading={users.loading}
        itemNoun="users"
        addElementText="Invite Users"
        onAddElementClick={() => setShowInviteUsersDialog(true)}
      />
      {invites.data.length > 0 && (
        <>
          <Styled.SectionHeader>
            <Styled.SectionHeaderTitle>Invites</Styled.SectionHeaderTitle>
            <Styled.SectionHeaderSubtitle>Users invited to join your tenant</Styled.SectionHeaderSubtitle>
          </Styled.SectionHeader>
          <List
            items={invites.data}
            itemKey={(invite) => invite.id}
            itemColumns={[
              {
                id: 'email',
                title: 'Email',
                header: <Styled.FlexBox weight={8} />,
                content: (invite) => (
                  <Styled.FlexBox weight={8} style={{ fontSize: '13px' }}>
                    {invite.email}
                  </Styled.FlexBox>
                ),
                sort: (items, asc) => items.sort((a, b) => a.email?.localeCompare(b.email) * (asc ? 1 : -1)),
              },
              {
                id: 'invited-at',
                title: 'Invited at',
                header: <Styled.FlexBox weight={4} />,
                content: (invite) => (
                  <Styled.FlexBox weight={4} style={{ fontSize: '13px' }}>
                    {dayjs(invite.dateCreated).calendar()}
                  </Styled.FlexBox>
                ),
                sort: (items, asc) =>
                  items.sort((a, b) => dayjs(b.dateCreated ?? 0).diff(a.dateCreated ?? 0) * (asc ? 1 : -1)),
              },
              {
                id: 'copy-link',
                header: <Styled.FlexBox weight={2} />,
                content: (invite) => (
                  <Styled.FlexBox weight={2}>
                    <Styled.PrimaryButton
                      onClick={() => copyInviteLink(invite)}
                      profile={ButtonProfile.OUTLINED}
                      size={ButtonSize.MINI}
                    >
                      COPY LINK
                    </Styled.PrimaryButton>
                  </Styled.FlexBox>
                ),
              },
              {
                id: 'menu',
                header: <Styled.FlexBox weight={1} />,
                content: (invite) => (
                  <Styled.FlexBox weight={1}>
                    <ContextMenu
                      items={[
                        [
                          {
                            title: 'Revoke invite',
                            disabled: invites.loading,
                            onClick: () => setInviteToRevoke(invite),
                          },
                        ],
                      ]}
                    >
                      <Styled.ContextMenuButton />
                    </ContextMenu>
                  </Styled.FlexBox>
                ),
              },
            ]}
            loading={invites.loading}
            itemNoun="invited users"
          />
        </>
      )}
      {userToDisableOrReEnable && (
        <Dialog
          cancelText="Cancel"
          nextText={userToDisableOrReEnable.disabled ? 'Re-enable' : 'Disable'}
          onCancel={() => setUserToDisableOrReEnable(null)}
          onNext={disableOrReEnableUser}
          cover
        >
          <DialogStyled.Title>{userToDisableOrReEnable.disabled ? 'Re-enable' : 'Disable'} user</DialogStyled.Title>
          {userToDisableOrReEnable.disabled ? (
            <DialogStyled.Body>Are you sure you want to re-enable this user?</DialogStyled.Body>
          ) : (
            <DialogStyled.Body>
              Are you sure you want to disable this user?
              <br />
              You can always re-enable the user if needed.
            </DialogStyled.Body>
          )}
        </Dialog>
      )}
      {inviteToRevoke && (
        <Dialog
          cancelText="Cancel"
          nextText="Revoke"
          onCancel={() => setInviteToRevoke(null)}
          onNext={revokeInvite}
          cover
        >
          <DialogStyled.Title>Revoke invite</DialogStyled.Title>
          <DialogStyled.Body>Are you sure you want to revoke the invite to {inviteToRevoke.email}?</DialogStyled.Body>
        </Dialog>
      )}
      {showInviteUsersDialog && (
        <InviteUsersDialog
          onUsersInvited={() => {
            setShowInviteUsersDialog(false);
            invites.fetch();
          }}
          onClose={() => setShowInviteUsersDialog(false)}
        />
      )}
    </>
  );
};

export default Users;
