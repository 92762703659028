import React from 'react';

export const YouTubePlayer: React.FC<{ embedId: string }> = ({ embedId }) => {
  return (
    <iframe
      src={`https://www.youtube-nocookie.com/embed/${embedId}?rel=0&controls=1&autoplay=0&mute=0&start=0`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
    />
  );
};
