import React from 'react';

import { AppServerUrl } from 'client/components/Common/AppServerUrl';
import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import Pin from 'client/components/Pin';

import { BoardCode, SaveFromRoomIllustration } from './styled';

interface Props {
  hide: () => void;
}

export const OpenBoardDialog: React.FC<Props> = ({ hide }) => (
  <Dialog noButtons={true} onCancel={hide} cover>
    <>
      <SaveFromRoomIllustration />
      <DialogStyled.Title style={{ fontWeight: 600 }}>Open board via your personal device</DialogStyled.Title>
      <DialogStyled.Body style={{ fontSize: '13px', display: 'block' }}>
        Sign in on <AppServerUrl />
      </DialogStyled.Body>
      <DialogStyled.Body style={{ fontSize: '13px' }}>
        Go to your boards and choose{' '}
        <img
          style={{
            width: '14px',
            marginLeft: '5px',
            marginTop: '4px',
            marginRight: '2px',
          }}
          src="/icons/icn_open_in_room.svg"
        />{' '}
        <em>Open in Room</em>
      </DialogStyled.Body>
      <DialogStyled.Body style={{ fontSize: '13px' }}>and enter the Room code:</DialogStyled.Body>
      <BoardCode>
        <Pin />
      </BoardCode>
    </>
  </Dialog>
);
