import styled from 'styled-components';

export const BrandLogo = styled.div`
  background-image: url(${(props: { url: string }) => (props.url ? props.url : '')});
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  margin: 64px;
  background-size: contain;
  position: absolute;
  bottom: 0;
`;
