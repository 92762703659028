import React from 'react';

import { AppServerUrl } from 'client/components/Common/AppServerUrl';
import Dialog from 'client/components/Common/dialog';
import Pin from 'client/components/Pin';
import QrCode from 'client/components/Home/qrcode';

import {
  Column,
  ColumnText,
  ColumnTitle,
  ColumnTop,
  PinWrapper,
  QRCodeWrapper,
  Spacer,
  Text,
  Title,
  Columns,
} from './styled';

interface Props {
  hide: () => void;
}

export const RoomSaveDialog: React.FC<Props> = ({ hide }) => (
  <Dialog noButtons={true} onCancel={hide} cover>
    <Title>Two ways to save your board</Title>
    <Text>The board is saved automatically when you join from your personal device.</Text>
    <Columns>
      <Column>
        <ColumnTop>
          <QRCodeWrapper>
            <QrCode />
          </QRCodeWrapper>
        </ColumnTop>
        <ColumnTitle>Scan QR code</ColumnTitle>
        <ColumnText>Use the camera app or QR code reader app on your phone and scan this code.</ColumnText>
      </Column>
      <Spacer>
        <ColumnTop />
        <ColumnTitle>OR</ColumnTitle>
      </Spacer>
      <Column>
        <ColumnTop>
          <PinWrapper>
            <Pin />
          </PinWrapper>
        </ColumnTop>
        <ColumnTitle>Enter room code</ColumnTitle>
        <ColumnText>
          Sign in on <AppServerUrl /> and enter this code to join the board.
        </ColumnText>
      </Column>
    </Columns>
  </Dialog>
);
