import React from 'react';

import * as Styled from 'client/components/Auth/styled';
import DialogNoCancel from 'client/components/Common/dialog-no-cancel';

interface Props {
  onClose: () => void;
}

const BoardCodeInfo: React.FC<Props> = ({ onClose }) => (
  <DialogNoCancel noButtons onBackDropClick={onClose}>
    <img src="/images/ill_whereistheboardcode.svg" />
    <Styled.BoardCodeInfoTitle>You find the Board Code in the top right corner</Styled.BoardCodeInfoTitle>
    <Styled.BoardCodeInfoBody>
      Visit{' '}
      <a target="_blank" href="//help.flatfrog.com/en/knowledge/inviting-joining-remote-web">
        Knowledge Base
      </a>{' '}
      to learn more.
    </Styled.BoardCodeInfoBody>
    <Styled.BoardCodeInfoConfirm onClick={onClose}>Got it</Styled.BoardCodeInfoConfirm>
  </DialogNoCancel>
);

export default BoardCodeInfo;
