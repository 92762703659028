import styled from 'styled-components';

export const Footer = styled.div`
  margin: 8px 16px -16px 16px;
  padding: 4px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  text-align: center;
  height: 40px;
`;

export const CollapseButton = styled.button`
  background: url(/icons/icn_collpase.svg) center center no-repeat;
  height: 32px;
  width: 72px;
  border-radius: 32px;
  border: none;
  outline: none;
  transition: background-color 300ms ease-out;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;

  &:hover {
    background-color: #0000000a;
  }

  &.collapsed {
    background-image: url(/icons/icn_expand.svg);
  }
`;

export const Header = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 23px;
  position: relative;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div`
  transition: max-height 300ms ease-in-out;
  max-height: 1000vh;
`;

export const Panel = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  margin: 12px 0 40px 0;
  background-color: #fff;
  box-shadow: 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  border-radius: 16px;
  position: relative;
  margin: 0 0 16px 0;
  padding: 16px 0;

  transition: max-height 300ms ease-in-out;

  ${CollapseButton} {
    bottom: 4px;
  }

  &.collapsed {
    max-height: 64px !important;

    ${Wrapper} {
      max-height: 0 !important;
    }

    ${CollapseButton} {
      bottom: 16px;
    }
  }
`;
