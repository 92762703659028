import React, { useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import * as analytics from 'client/common/analytics';
import { getSupportedFileTypes } from 'client/common/util';

import * as Styled from './styled';

interface Props {
  signedIn: boolean;
  importFiles: (files: File[]) => void;
}

const useReportFileRejections = (fileRejections: FileRejection[]) => {
  const reportedFileTypes: Record<string, boolean> = {};

  useEffect(() => {
    fileRejections.forEach(({ file }) => {
      if (!reportedFileTypes[file.type]) {
        reportedFileTypes[file.type] = true;
        analytics.importUnsupportedFile(file.type);
      }
    });
  }, [fileRejections]);
};

const PublishDropZone: React.FC<Props> = ({ signedIn, children, importFiles }) => {
  const { isDragAccept, isDragActive, getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: importFiles,
    noClick: true,
    validator: (file) => {
      const dropSupport = getSupportedFileTypes(signedIn);

      if (!file.type.match(dropSupport)) {
        return {
          code: 'unsupported-file-type',
          message: '',
        };
      }
    },
  });

  useReportFileRejections(fileRejections);

  return (
    <div {...getRootProps()}>
      {children}
      <input {...getInputProps()} />
      {isDragActive && (
        <Styled.FloatingInfoText>
          {isDragAccept ? (
            <>
              <img src="/images/ill_upload_images.svg" />
              <h1>Drop documents to add them to the board</h1>
            </>
          ) : (
            <>
              <img src="/images/ill_upload_images_wrong.svg" />
              <h1>Wrong format</h1>
            </>
          )}
          <span>{signedIn ? 'Supporting PNG, JPG, PDF and Office files' : 'Supporting PNG, JPG, PDF'}</span>
        </Styled.FloatingInfoText>
      )}
    </div>
  );
};

export default PublishDropZone;
