import { createAction, createReducer } from '@reduxjs/toolkit';

import { toggleShowReconnectDialog } from './showDisconnectDialog';
import { toggleSessionEndedDialog } from './showSessionEndedDialog';

export const setShowGridView = createAction<boolean>('setShowGridView');

export default createReducer(false, (builder) =>
  builder
    .addCase(setShowGridView, (state, action) => action.payload)
    .addCase(toggleSessionEndedDialog, (state, { payload }) => (payload.show ? false : state))
    .addCase(toggleShowReconnectDialog, (state, { payload }) => (payload ? false : state))
);
