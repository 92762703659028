import { AnyAction, createAction, createReducer, ThunkAction } from '@reduxjs/toolkit';

import { TextAlignments } from 'client/components/Common/text-alignments';
import { RootState } from 'client/store';

export type Item = {
  __typename: 'StickyNote' | 'TextBlock';
  id: string;
  sessionJoinKey?: string;
  textColor: string;
  backgroundColor: string;
  outlineColor?: string;
  textSize: number;
  textAlign: TextAlignments;
  content: string;
  link?: string;
  type?: string;
  signature?: string;
};

export const deleteLocalItem = createAction<string>('deleteLocalItem');
export const updateLocalItem = createAction<Partial<Item> & { id: string; __typename?: string }>('updateLocalItem');
export const emptyLocalItems = createAction<string>('emptyLocalItems');

const addLocalItemInternally = createAction<Item>('addLocalItem');

export const addLocalItem =
  (item: Item): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { session } = getState();

    if (session.joinKey) {
      item.sessionJoinKey = session.joinKey;
    }

    dispatch(addLocalItemInternally(item));
  };
/*
      case App.UPDATE_LOCAL_ITEM: {
        return state.map((item) => (item.id === action.item.id ? { ...item, ...action.item } : item));
      }

 */
export default createReducer([] as Item[], (builder) =>
  builder
    .addCase(addLocalItemInternally, (state, action) => [action.payload, ...state])
    .addCase(deleteLocalItem, (state, action) => state.filter((item) => item.id !== action.payload))
    .addCase(updateLocalItem, (state, { payload }) =>
      state.map((item) => (item.id === payload.id ? { ...item, ...payload } : item))
    )
    .addCase(emptyLocalItems, (state, { payload }) =>
      payload ? state.filter((item) => item.sessionJoinKey === payload) : []
    )
);
