import styled from 'styled-components';

export const ModalBackDrop = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3px);
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  &.active {
    display: block;
    &.invisible {
      backdrop-filter: none;
    }
  }

  &.no-filter {
    backdrop-filter: none;
  }
`;

export const BaseModalContainer = styled.div`
  position: fixed;
  background: #fff;
  border: none;
  box-shadow: 0 0 0 0.5px #00000014, 0 16px 32px #00000029;
  padding: 40px 64px;
  border-radius: 16px;
  //border-radius: 6px;
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 50%;
  z-index: 5;
  transition: all 300ms ease-in-out;
  pointer-events: none;
  transform: translate3d(-50%, -50%, 0);
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 20px;
  max-width: 96vw;
  text-align: center;
  color: #242424;

  &.active {
    opacity: 1;
    pointer-events: visible;
    top: 50%;
  }

  button.close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #999;
    border: none;
    padding: 8px;
    margin: 0;
    font-weight: 800;
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: url(/icons/icn_close_small.svg) center center no-repeat;

    &:hover {
      color: #555;
    }
  }
`;

export const NewBaseModalContainer = styled.div`
  position: fixed;
  background: #fff;
  box-shadow: 0 16px 32px #00000029, 0px 0px 0px 1px #0000001a;
  border-radius: 16px;
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 50%;
  z-index: 5;
  transition: all 300ms ease-in-out;
  pointer-events: none;
  transform: translate3d(-50%, -50%, 0);
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 96vw;
  text-align: center;
  color: #242424;

  &.active {
    opacity: 1;
    pointer-events: visible;
    top: 50%;
  }

  button.close {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    padding: 8px;
    margin: 0;
    outline: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 16px;
    transition: background-color 300ms ease-out;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/icons/icn_close_small.svg) center center no-repeat;
    }

    &:hover {
      background-color: #0000000b;
    }
  }
`;
