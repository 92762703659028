import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';

import NameBubble from 'client/components/Common/NameBubble';

const Container = styled.div`
  flex: 4 0;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
  }
`;

const NameWrapper = styled.div`
  margin-left: 8px;
`;

const Name = styled.div`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.32px;
`;

const Email = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: #8c8c8c;
  letter-spacing: -0.25px;
`;

interface Props {
  email?: string;
  displayName?: string;
  room?: boolean;
  admin?: boolean;
  disabled?: boolean;
  image?: string;
}

const ImageBubble = styled.img<{ disabled: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const User: React.FC<Props> = ({ email, displayName, room, admin, disabled, image }) => (
  <Container className={classNames({ disabled })}>
    {image ? (
      <ImageBubble src={image} disabled={disabled} />
    ) : (
      <NameBubble displayName={displayName} room={room} disabled={disabled} />
    )}
    <NameWrapper>
      <Name>
        {displayName}
        {admin && !disabled ? ' (Admin)' : ''}
        {disabled ? ' (disabled)' : ''}
      </Name>
      <Email>{email}</Email>
    </NameWrapper>
  </Container>
);

export default User;
