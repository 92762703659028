import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { getParticipantColor, getParticipantName } from 'client/common/participant-names';

const Bubble = styled.div`
  position: relative;
  padding: 14px;
  width: 100%;
  height: 100%;
  border: 2px solid #00000026;
  color: ${(p) => (p.color ? p.color : '#00000026')};
  border-color: ${(p) => (p.color ? p.color : '#00000026')};
  background: ${(p) => (p.color ? `${p.color}22` : 'rgba(0,0,0,0.08)')};
  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 300ms ease-out, border-color 300ms ease-out, background-color 300ms ease-out;
  font-weight: 500;
  user-select: none;

  &.large {
    border: 4px solid #00000026;
    font-size: 31px;
    margin-bottom: 16px;
  }
`;

const Background = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 50%;

  &.large {
    width: 80px;
    height: 80px;
  }

  &.disabled {
    opacity: 0.5;
  }
`;

interface Props {
  displayName?: string;
  onClick?: () => void;
  large?: boolean;
  style?: CSSProperties;
  room?: boolean;
  disabled?: boolean;
}

const RoomIcon = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  mask-image: url(/icons/icn_device_rooms.svg);
  background-color: ${(p) => (p.color ? p.color : '#00000026')};
`;

const NameBubble: React.FC<Props> = ({ displayName, onClick, large, disabled, children, style, room }) => (
  <Background style={style} className={classNames({ large, disabled })}>
    <Bubble
      color={getParticipantColor(displayName)}
      data-tip={`${displayName}${disabled ? ' (disabled)' : ''}`}
      data-effect="solid"
      onClick={onClick}
      className={classNames({ large })}
    >
      {room ? <RoomIcon color={getParticipantColor(displayName)} /> : getParticipantName(displayName || null)}
      {children}
    </Bubble>
  </Background>
);

export default NameBubble;
