import React, { useRef } from 'react';
import styled from 'styled-components';

const Box = styled.input.attrs(() => ({
  type: 'checkbox',
}))``;

const Fakebox = styled.div`
  position: relative;
`;

const Switch = styled.label``;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
  position: relative;
  font-size: 12px;
  color: #242424;

  ${Box} {
    visibility: hidden;
    display: block;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 1em 0 0;
    -webkit-tap-highlight-color: transparent;

    + ${Fakebox} {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: #fff;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(/icons/icn_radiobutton.svg) center center no-repeat;
      transition: background-color 200ms ease-in-out;
    }

    &:checked + ${Fakebox} {
      background: url(/icons/icn_radiobutton_active.svg) center center no-repeat;
    }
  }
`;

interface Props {
  id?: string;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  clickableText?: boolean;
}

const Checkbox: React.FC<Props> = ({ checked, children, clickableText, disabled, id, onChange }) => {
  const ref = useRef<HTMLInputElement>();

  return (
    <SwitchContainer>
      <Switch id={id}>
        <Box ref={ref} disabled={disabled} onChange={onChange} checked={checked} />
        <Fakebox />
      </Switch>
      <span
        onClick={
          clickableText &&
          (() => {
            ref.current.click();
          })
        }
      >
        {children}
      </span>
    </SwitchContainer>
  );
};

export default Checkbox;
