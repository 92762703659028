import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Box = styled.input.attrs(() => ({
  type: 'checkbox',
}))``;

const Fakebox = styled.div.attrs({ className: 'fakeBox' })`
  pointer-events: none;
  position: relative;
`;

const Switch = styled.label`
  display: flex;
  align-items: center;
  text-align: start;
  user-select: none;
  position: relative;
  font-size: 14px;
  color: #242424;
  font-weight: 500;

  > span {
    margin-left: 16px;
  }
`;

const HoverEffect = styled.div`
  pointer-events: none;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
  position: relative;
  font-size: 12px;
  color: #242424;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${HoverEffect} {
        transform: scale(1);
      }
    }
  }

  ${HoverEffect} {
    position: absolute;
    background: #00000015;
    border-radius: 16px;
    margin-left: -8px;
    width: 32px;
    height: 32px;
    transform: scale(0.25);
    -webkit-transition: transform 300ms ease-out;
    transition: transform 300ms ease-out;
  }

  ${Box} {
    visibility: hidden;
    display: block;
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    -webkit-tap-highlight-color: transparent;

    + ${Fakebox} {
      width: 16px;
      height: 16px;
      border-radius: 3px;
      background: #fff url(/icons/icn_item_checkbox.svg) center center no-repeat;
      transition: background-color 200ms ease-in-out;
    }

    &:checked + ${Fakebox} {
      background: url(/icons/icn_item_checkbox_active.svg) center center no-repeat;
    }

    &:indeterminate + ${Fakebox} {
      background: url(/icons/icn_item_checkbox_not_all.svg) center center no-repeat;
    }
  }
`;

interface Props {
  id?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  tooltip?: string;
}

const SmallCheckbox: React.FC<Props> = ({ checked, children, disabled, id, indeterminate, onChange, tooltip }) => {
  const input = useRef<HTMLInputElement>();

  useEffect(() => {
    input.current.indeterminate = indeterminate;
  }, [input]);

  return (
    <SwitchContainer>
      <Switch htmlFor={id}>
        {!disabled && <HoverEffect />}
        <Box id={id} ref={input} disabled={disabled} onChange={onChange} checked={checked} />
        <Fakebox
          onClick={() => {
            input.current.click();
          }}
          data-place={tooltip ? 'right' : ''}
          data-tip={tooltip || ''}
          data-effect={tooltip ? 'solid' : ''}
        />
        {children && <span>{children}</span>}
      </Switch>
    </SwitchContainer>
  );
};

export default SmallCheckbox;
