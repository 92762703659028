import { createAction, createReducer } from '@reduxjs/toolkit';

import { setLoadedFileInfo } from '../session/loadedFileInfo';

export type ClipboardEntry = { action: 'cut' | 'copy'; data: string };

export const addToClipboard = createAction('addToClipboard', (action: 'cut' | 'copy', data: string) => ({
  payload: {
    action,
    data,
  },
}));

export default createReducer<ClipboardEntry | null>(null, (builder) =>
  builder.addCase(addToClipboard, (state, action) => action.payload).addCase(setLoadedFileInfo, () => null)
);
