import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ModalContainer } from 'client/components/Common/dialog-styled';
import { ModalBackDrop } from 'client/components/Common/modal-styled';

const Loading: React.FC = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  });

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop className="active" />
      <ModalContainer>Loading...</ModalContainer>
    </>,
    document.getElementById('modal-portal')
  );
};

export default Loading;
