import React from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

import { useAppServerUrlWithTenant } from 'client/hooks/useAppServerUrlWithTenant';
import { useSelector } from 'client/hooks/useSelector';

const Wrapper = styled.div`
  overflow: hidden;
  text-align: center;
`;

const QRStyled = styled(QRCode)`
  height: 100%;
  width: 100%;
  border: 12px solid white;
  border-radius: 10px;
  shape-rendering: crispedges;
`;

const QrCode: React.FC = () => {
  const appServerUrl = useAppServerUrlWithTenant();
  const { roomPin } = useSelector((state) => ({
    roomPin: state.roomPin,
  }));

  const qrCodeUrl = `https://${appServerUrl}/qrcode/${roomPin}`;

  return (
    <Wrapper>
      <QRStyled size={512} viewBox="0 0 512 512" value={qrCodeUrl} />
    </Wrapper>
  );
};

export default QrCode;
