import React from 'react';

import { BrandLogo } from 'client/components/Common/styled';
import { useSelector } from 'client/hooks/useSelector';

export const TenantLogo: React.FC = () => {
  const { tenant } = useSelector((state) => ({
    tenant: state.tenant,
  }));

  return tenant?.logoUrl ? <BrandLogo url={tenant?.logoUrl} /> : null;
};
