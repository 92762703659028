import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { useSelector } from 'client/hooks/useSelector';
import { useFetch } from 'client/hooks/useFetch';
import User from 'client/components/Common/user';

const { MultiValueLabel } = components;

const GIGA_EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

interface Props {
  maximized?: boolean;
  expandable?: boolean;
  id?: string;
  existingUsers?: { uid: string; email: string }[];
  emailsChanged: (emails: string[]) => void;
}

const EmailInput: React.FC<Props> = ({ id, emailsChanged, existingUsers = [] }) => {
  const [inputValue, setInputValue] = useState('');
  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    emailsChanged(emails);
  }, [emails]);

  const addIfValidEmail = useCallback(() => {
    if (
      GIGA_EMAIL_REGEX.test(inputValue) &&
      !emails.includes(inputValue) &&
      !existingUsers.some((u) => u.email === inputValue)
    ) {
      setEmails((email) => [...email, inputValue]);
      setInputValue('');
    }
  }, [emails, inputValue]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
        e.preventDefault();

        addIfValidEmail();
      }
    },
    [addIfValidEmail]
  );

  return (
    <CreatableSelect
      id={id}
      isClearable={false}
      isMulti
      components={{ DropdownIndicator: null }}
      getOptionLabel={(o) => o}
      getOptionValue={(o) => o}
      inputValue={inputValue}
      menuIsOpen={false}
      onBlur={addIfValidEmail}
      onChange={(v) => setEmails(v as string[])}
      onInputChange={setInputValue}
      onKeyDown={handleKeyDown}
      placeholder="Enter email address"
      value={emails}
      styles={{
        valueContainer: (base) => ({ ...base, maxHeight: '100px', overflowY: 'auto' }),
        input: (base) => ({ ...base, '& input': { height: 'inherit' } }),
      }}
    />
  );
};

const EmailSelect: React.FC<Props> = ({ id, emailsChanged, existingUsers = [] }) => {
  const membersList = useFetch<{ email: string; id: string; displayName?: string; disabled: boolean }[]>(
    'users',
    [],
    (data) =>
      data
        .filter((d) => !d.disabled && !existingUsers.some((u) => u.uid === d.id))
        .sort((a, b) => a.email?.localeCompare(b.email))
  );

  return (
    <Select
      id={id}
      isClearable={false}
      isMulti
      components={{ MultiValueLabel: (props) => <MultiValueLabel {...props}>{props.data.email}</MultiValueLabel> }}
      formatOptionLabel={User}
      getOptionValue={(o) => `${o.email}|${o.displayName}`}
      isLoading={membersList.loading}
      onChange={(selected) => emailsChanged(selected.map((o) => o.email))}
      options={membersList.data}
      placeholder="Enter email address"
      styles={{
        valueContainer: (base) => ({ ...base, maxHeight: '100px', overflowY: 'auto' }),
        input: (base) => ({ ...base, '& input': { height: 'inherit' } }),
      }}
    />
  );
};

const EmailTextarea: React.FC<Props> = (props) => {
  const { tenant } = useSelector((state) => ({ tenant: state.tenant }));

  return tenant ? <EmailSelect {...props} /> : <EmailInput {...props} />;
};

export default EmailTextarea;
