import React, { useState, useRef } from 'react';

import useEventListener from 'client/hooks/useEventListener';

import Dialog from './dialog';
import * as DialogStyled from './dialog-styled';

const truncateAndTrimString = (str: string) => {
  const trimmed = str.trim();
  const maxLength = 128;
  return trimmed.length > maxLength ? `${trimmed.substring(0, maxLength - 1)}...` : trimmed;
};

interface Props {
  fileName?: string;
  onNext: (fileName: string) => void;
  onCancel: () => void;
  nextText: string;
  title: string;
}

const BoardNameDialog: React.FC<Props> = ({ fileName, nextText, onCancel, onNext, title }) => {
  const [newName, setNewName] = useState(fileName || '');
  const filenameRef = useRef();

  useEventListener<KeyboardEvent>(
    'keyup',
    (e) => {
      if (e.key === 'Enter') {
        onNext(truncateAndTrimString(newName));
      }
    },
    filenameRef.current
  );

  return (
    <Dialog
      onCancel={onCancel}
      cancelText="Cancel"
      nextText={nextText || 'Save'}
      onNext={() => {
        onNext(truncateAndTrimString(newName));
      }}
      nextDisabled={!newName || newName.trim().length < 3 || newName.trim() === fileName}
      cancelDisabled={false}
      noCloseButton
      rightAlign
      cover
    >
      <DialogStyled.Title>{title}</DialogStyled.Title>
      <DialogStyled.FileNameLabel>Name</DialogStyled.FileNameLabel>
      <DialogStyled.FileNameField
        ref={filenameRef}
        value={newName}
        maxLength={128}
        onChange={(e) => setNewName(e.target.value)}
      />
    </Dialog>
  );
};

export default BoardNameDialog;
