import styled from 'styled-components';

import { CreateBoardList, CreateBoardThumbnail, CreateBoardBlank } from '../styled';

export const CreateBoardListGallery = styled(CreateBoardList)`
  grid-template-columns: repeat(auto-fill, calc(25% - 8px));
  margin-bottom: 6px;
`;

export const CreateBoardBlankGallery = styled(CreateBoardBlank)``;

export const CreateBoardThumbnailGallery = styled(CreateBoardThumbnail)``;

export const GalleryContainer = styled.div`
  position: fixed;
  z-index: 9;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 980px;
  max-width: 98vw;
  height: 550px;
  max-height: calc(100vh - 128px);

  background-color: #f5f6f7;
  border-radius: 16px;
  box-shadow: 0 0 0 0.5px #00000014, 0 16px 32px #00000028;
  color: #242424;

  display: flex;
`;

export const SearchInput = styled.input.attrs({
  type: 'text',
})`
  outline: none;
  width: 100%;
  border: 2px solid #00000029;
  border-radius: 5px;
  padding: 0 16px;
  padding-left: 40px;
  height: 40px;
  min-height: 40px;
  font-size: 13px;
  font-weight: 400;
  color: #8c8c8c;
  background: #fff url(/icons/icn_search_inactive.svg) left 16px center no-repeat;
  margin-bottom: 24px;

  &:focus {
    color: #242424;
    background: #fafafa url(/icons/icn_search_active.svg) left 16px center no-repeat;
    border-color: #279ae7;
  }
`;

export const NavColumScroll = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    max-height: 100%;
  }
`;

export const NavColumn = styled.div`
  width: 260px;
  background-color: #ffffff;
  box-shadow: 0.5px 0 0 #00000014;
  padding: 32px 24px 32px;
  border-radius: 16px 0 0 16px;
  display: flex;
  flex-direction: column;
  position: relative;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #242424;
    text-transform: none;
    text-align: left;
    margin: 16px 0 24px 0;
    padding: 0 16px;
  }

  // Generated by HashLink, hence no own component
  a,
  span.placeholder {
    font-size: 13px;
    font-weight: 600;
    padding: 0 16px;
    height: 40px;
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: #242424;

    &:hover {
      background-color: #00000014;
    }

    &:active,
    &.is-current {
      color: #ffffff;
      background-color: #279ae7;
    }
  }

  span.placeholder {
    cursor: default;
    &:hover,
    &:active {
      color: #242424;
      background: transparent;
    }
  }
`;

export const MainColumnFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  font-size: 11px;
`;

export const NavColumnFooter = styled(MainColumnFooter)`
  padding-left: 24px;
  padding-right: 24px;
  align-items: flex-start;
`;

export const CategoryHeader = styled.a`
  display: block;
  color: #242424;
  margin-bottom: 24px;
  padding-top: 34px;
  font-size: 16px;
  font-weight: 600;

  &.room {
    padding-top: 16px;
  }
`;

export const MainContent = styled.div`
  padding: 0 40px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 64px;

  &.full-height {
    bottom: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.disabled li {
    pointer-events: none;
  }

  &.room {
    top: 16px;
    bottom: 16px;
  }
`;

export const MainColumn = styled.div`
  flex-grow: 1;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.button.attrs({
  type: 'button',
})`
  width: 32px;
  height: 32px;
  background: url(/icons/icn_close_small.svg) center center no-repeat;
  background-size: 12px;
  position: absolute;
  right: 8px;
  top: 8px;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #00000014;
  }
`;
