import { useCallback, useEffect, useState } from 'react';

import { getFirebaseAuth } from 'client/common/firebase';
import { useSelector } from 'client/hooks/useSelector';
import * as rest from 'client/services/rest';

export interface FetchResult<T> {
  fetch: () => Promise<void>;
  data: T;
  loading: boolean;
}

export const useFetch = <T, U = T>(path: string, initialData: U, transform: (data: T) => U): FetchResult<U> => {
  const [data, setData] = useState<U>(initialData);
  const [loading, setLoading] = useState(false);

  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    collaborationServerUrl: state.collaborationServerUrl,
    tenant: state.tenant,
  }));

  const fetch = useCallback(async () => {
    const timeoutId = setTimeout(() => setLoading(true));
    const token = await getFirebaseAuth().currentUser.getIdToken();

    try {
      const response = await rest.makeRequest<T>({
        method: 'GET',
        url: `${collaborationServerUrl}/tenant/${tenant.id}/${path}`,
        authorizationToken: { auth: token },
      });
      setData(transform(response.data));
    } catch (e) {
      setData(initialData);
      console.error(e);
    } finally {
      clearTimeout(timeoutId);
      setLoading(false);
    }
  }, [collaborationServerUrl, tenant]);

  useEffect(() => {
    if (collaborationServerUrl && tenant) {
      fetch();
    }
  }, [collaborationServerUrl, tenant]);

  return {
    fetch,
    data,
    loading,
  };
};
