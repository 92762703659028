import styled from 'styled-components';

export const SaveFromRoomIllustration = styled.img.attrs({
  src: '/images/ill_save_board_from_room.svg',
  alt: ' ',
  height: '120px',
})`
  margin: 0px;
  margintop: 24px;
  marginbottom: 24px;
`;

export const BoardCode = styled.div`
  font-size: 41px;
  height: 56px;
  line-height: 56px;
  margin: 16px;
`;
