import styled from 'styled-components';

import { ButtonSize, PrimaryButton } from 'client/components/Dashboard/admin/styled';

export * from '../../styled';

const _Divider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.16);
`;

export const Divider = styled(_Divider)`
  width: 240px;
  margin: 16px 0;
`;

export const MiniButton = styled.button.attrs<{ type?: string }>((p) => ({
  type: p.type || 'button',
}))`
  outline: none;
  background: transparent;
  color: #2287ca;
  padding: 0;
  border: none;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    filter: grayscale();
    cursor: default;
  }
`;

const _Card = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 1) 0 0 no-repeat padding-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  .lds-ellipsis-large {
    align-self: center;
    margin: 0 auto;
  }
`;

export const Card = styled(_Card)`
  min-height: 138px;
`;

export const IntegrationEnabledCard = styled(Card)`
  padding: 40px 48px 64px;
  flex-direction: column;
`;

export const IntegrationEnabledHeader = styled.div`
  display: flex;
  height: 24px;
`;

export const IntegrationEnabledHeaderIcon = styled.div`
  margin-right: 8px;
`;

export const IntegrationEnabledHeaderText = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

export const IntegrationDisconnectButton = styled.div`
  margin-left: 16px;
  margin-bottom: 1px;
  align-self: end;
`;

export const IntegrationTenantInfo = styled.div`
  margin-top: 16px;
`;

export const IntegrationTenantInfoRow = styled.div`
  font-size: 13px;
  line-height: 18px;
`;

export const IntegrationTenantInfoRowTitle = styled.span`
  color: #8c8c8c;
  margin-right: 4px;
`;

export const IntegrationDirectoryStats = styled.div``;

export const IntegrationDirectoryStatsRow = styled.div`
  font-size: 13px;
  line-height: 18px;
`;

export const IntegrationDirectoryStatsRowValue = styled.span`
  font-weight: bold;
`;

export const ViewDirectoryButton = styled.div`
  margin-top: 8px;
`;

export const ConnectIntegrationCard = styled(Card)`
  padding: 40px 48px;
  flex-direction: row;
  align-items: center;
`;

export const ConnectIntegrationImage = styled.div`
  margin-right: 32px;
  > img {
    width: 36px;
  }
`;

export const ConnectIntegrationInfo = styled.div`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.32px;
  max-width: 440px;
`;

export const ConnectIntegrationInfoTitle = styled.div`
  font-weight: 600;
`;

export const ConnectIntegrationInfoText = styled.div``;

export const ConnectIntegrationButton = styled(PrimaryButton).attrs({ size: ButtonSize.SMALL })`
  margin-left: auto;
`;
