import styled, { css } from 'styled-components';

export const StartScreenWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999;
`;

interface GradientLayerProps {
  $color: { r: number; g: number; b: number };
}

export const GradientLayer = styled.div.attrs<GradientLayerProps>(({ $color: { r, g, b } }) => ({
  style: {
    backgroundImage: `radial-gradient(ellipse farthest-corner at bottom, rgba(${r}, ${g}, ${b}, .3) 0%, rgb(${r}, ${g}, ${b}) 25%, #000000 150%)`,
  },
}))<GradientLayerProps>`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const StartscreenBackground = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: white;
`;

export const LoadingText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Open Sans';
  color: #ffffff;
  font-weight: 400;
  font-size: 3vh;

  animation: fade-in-out--start-screen-loading-text 2000ms ease-in-out 0s infinite;

  @keyframes fade-in-out--start-screen-loading-text {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const BlurLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px) opacity(1);
`;

export const ScrollLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateY(0);

  border-radius: 16pt;
  cursor: pointer;

  &.exiting {
    animation: exit--scroll-layer 500ms ease-in 0s 1;
  }

  @keyframes exit--scroll-layer {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
`;

export const UILayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 5vh;

  animation: enter--start-screen-ui-layer 500ms ease-in-out 0s 1;

  @keyframes enter--start-screen-ui-layer {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TapText = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  white-space: pre;
  font-family: 'Open Sans';
  color: #ffffff;
  font-weight: 400;
  font-size: 2.8vh;
  pointer-events: none;
`;

export const TopLeftContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  line-height: 6vh;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.05);
  width: fit-content;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  padding: 4vh;
`;

export const Clock = styled.div`
  color: #ffffff;
  font-size: 7.4vh;
  font-weight: 500;
  text-shadow: 0px 3px 6px #00000066;
`;

export const Date = styled.div`
  color: #ffffffc9;
  font-size: 3.7vh;
  font-weight: 300;
`;

export const Logo = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6vh;
  width: 18vh;
  background: url(icons/logo-flatfrogboard-white.svg) center center no-repeat;
  background-size: contain;
  padding-left: 20vh;
  display: flex;
  align-items: center;
`;

export const VersionTextContainer = styled.div<{ $display: boolean }>`
  margin-left: 2vh;
  flex-direction: column;
  opacity: 0;
  transition: opacity 500ms;

  ${(props) =>
    props.$display &&
    css`
      opacity: 1;
    `}
`;

export const VersionText = styled.div`
  font-size: 2.5vh;
  color: white;
`;

export const BecText = styled.div`
  font-size: 1.25vh;
  color: white;
`;

export const BottomRightContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  color: white;
`;

export const ColorPickerPopup = styled.div`
  bottom: 10vh;
  position: absolute;
  left: 0;
  border: 3px solid rgb(255 255 255 / 40%);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 9px #00000070;
  background: rgb(255 255 255 / 30%);

  button {
    background: #ffffff33 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
    padding: 1px;
    border: none;
    padding: 3px;
    color: white;
  }
`;

export const OffButton = styled.div`
  width: 6vh;
  height: 6vh;
  background: url(icons/icn_off.svg) center center no-repeat;
  background-size: 3vh;
  border-radius: 1vh;

  &:hover {
    background-color: rgba(255, 255, 255, 0.16);
  }
`;

export const TopRightContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  max-width: 22vh;
  background-color: #fff1;
  border-radius: 8px;
  opacity: ${(p) => (p.visible ? 1 : 0)};
  transition: ${(p) => (p.visible ? 'opacity 500ms' : 'none')};
`;

export const BoardCode = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 2.8vh;
  letter-spacing: 1vh;
  color: white;
  line-height: 5vh;
  justify-content: center;
  padding: 8px;
  border-bottom: 1px solid #ffffff80;
`;

export const JoinBoardText = styled.div`
  font-family: 'Open Sans', serif;
  font-size: 1.3vh;
  color: #ffffff80;
  text-align: center;

  b {
    color: #ffffffe6;
    font-size: 1.7vh;
  }
`;

export const BoardQRCode = styled.div`
  display: flex;
  padding: 8px;
`;

export const InstructionText = styled.div`
  padding: 8px;
`;
