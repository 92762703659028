import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { isMobileOnly, isTablet, isSafari } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactTooltip from 'react-tooltip';
import ZCanvas from '@flatfrog/ffbec';
import { ZActions, ZShapes } from '@flatfrog/ffbec/js/zwasm';

import * as analytics from 'client/common/analytics';
import * as convert from 'client/common/convert';
import { OFFICE_FILE_TYPES } from 'client/common/util';
import { COLORS, DEFAULT_SIZE_INDEX, ERASE_SIZE, STROKE_SIZES } from 'client/common/ZCanvasAppSettings';
import Import from 'client/components/Common/import';
import ToggleSwitch from 'client/components/Common/toggleswitch';
import { packStickyNoteMetadata } from 'client/components/Common/types';
import * as StyledStack from 'client/components/ItemStack/styled';
import ClipboardMenu from 'client/components/SideBar/clipboardMenu';
import * as StyledSideBar from 'client/components/SideBar/styled';
import ToolTray from 'client/components/SideBar/toolTray';
import StickerTray from 'client/components/StickerTray';
import { BackgroundColors } from 'client/components/StickyNote/colors';
import { useActions } from 'client/hooks/useActions';
import useEventListener from 'client/hooks/useEventListener';
import useReduxAction from 'client/hooks/useReduxAction';
import { useSelector } from 'client/hooks/useSelector';
import * as actions from 'client/state/actions';
import { copyPapers, cutPapers } from 'client/middleware/items';

import { ISERoomToolbar } from './ISERoomToolbar';
import RoomToolbar from './RoomTools';

const hexToRgb = (hex: string): [number, number, number] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

const Toolbar: React.FC = () => {
  const {
    canRedo,
    canUndo,
    clients,
    currentZAction,
    featureFlags,
    forceFollow,
    hasActivePen,
    isMsTeamsApp,
    pasteToolEnabled,
    room,
    selectedPapers,
    session,
  } = useSelector((state) => ({
    canRedo: state.canUndoRedo.canRedo,
    canUndo: state.canUndoRedo.canUndo,
    clients: state.session.clients,
    currentZAction: state.currentZAction,
    featureFlags: state.featureFlags,
    forceFollow: state.forceFollow,
    hasActivePen: state.hasActivePen,
    isMsTeamsApp: state.isMsTeamsApp,
    pasteToolEnabled: state.pasteToolEnabled,
    readOnly: state.readOnly,
    room: state.room,
    selectedPapers: state.selectedPapers,
    session: state.session,
  }));

  const {
    addPaperToWhiteboardComponent,
    currentStickyNoteEdit,
    currentTextBlockEdit,
    prepareAndPublishImage,
    prepareAndPublishSticker,
    prepareAndPublishStickyNote,
    setHasActivePen,
    setZAction,
    triggerPasteEvent,
  } = useActions();

  // Array of [thing selected, event]
  // Colors and sizes are the indices of the arrays above
  const [toolSelected, setToolSelected] = useState(currentZAction);
  const [colorSelected, setColorSelected] = useState<[typeof COLORS[number], boolean]>([COLORS[0], false]);
  const [strokeSizeSelected, setStrokeSizeSelected] = useState<[number, boolean]>([DEFAULT_SIZE_INDEX, false]);
  const [localHasActivePen, setLocalHasActivePen] = useState(hasActivePen);
  const [firstSelection, setFirstSelection] = useState(false);

  const [inkToolTrayOpen, setInkToolTrayOpen] = useState(false);
  const [activePenSwitch, setActivePenSwitch] = useState(localHasActivePen);
  const [inkTool, setInkTool] = useState<'draw' | 'line' | 'erase'>('draw');

  const [inkTraysExpanded, setInkTraysExpanded] = useState(false);

  const [stickerTrayOpen, setStickerTrayOpen] = useState(false);

  const [clipboardMenuOpen, setClipboardMenuOpen] = useState(false);

  const [showImportDialog, setShowImportDialog] = useState(false);

  const clipboardMenuButton = useRef<HTMLDivElement>();

  const ITEM_PLACEMENT = 'ITEM_PLACEMENT';
  const PEN_ERASER = 'PEN_ERASER';

  const detectPen = (e: PointerEvent) => {
    if (isMobileOnly) {
      return;
    }

    if (!localHasActivePen && e.pointerType === 'pen' && e.buttons === 1) {
      // Trigger active pen mode when a pen is in contact.
      // Prevent palm eraser from triggering active pen mode,
      // by checking the pen eraser buttons (32 if eraser, 1 for pen contact).
      setHasActivePen(true);
      const [currentTool, currentShape] = toolSelected;
      setInkToolTrayOpen(true);
      if (currentTool === ZActions.ERASE) {
        setZAction(PEN_ERASER, ZShapes.FREEFORM, true);
      } else {
        setZAction(ZActions.SELECT, currentShape, true);
        if (!firstSelection) {
          setStrokeSizeSelected([DEFAULT_SIZE_INDEX, true]);
          setFirstSelection(true);
        }
      }
    }
  };

  useEventListener<PointerEvent>('pointerdown', detectPen);

  useEffect(() => {
    setToolSelected(currentZAction);
  }, [currentZAction]);

  useEffect(() => {
    setLocalHasActivePen((hadActivePen) => {
      if (hadActivePen) {
        // Only track disable if the mode was previously on
        analytics.disableActivePen();
      } else if (hasActivePen) {
        analytics.enableActivePen();
      }
      return hasActivePen;
    });
    setActivePenSwitch(hasActivePen);
  }, [hasActivePen]);

  // Tool changed
  useEffect(() => {
    ZCanvas.initialized.then(() => {
      const [tool, shape, event] = toolSelected;
      const [strokeSizeIndex] = strokeSizeSelected;

      if (event) {
        ['mouse', 'touch', 'passivePen', 'passivePenEraser'].forEach(
          (pointerType: keyof typeof ZCanvas.input.pointerSettings) => {
            const pointerSettings = ZCanvas.input.pointerSettings[pointerType];
            pointerSettings.action = tool === ZActions.ERASE ? ZActions.ERASE : ZActions.DRAW;
            pointerSettings.stroke.draw.width = STROKE_SIZES[strokeSizeIndex];
            pointerSettings.stroke.draw.shape = shape;
            if (pointerType === 'mouse') {
              pointerSettings.stroke.erase.width = 3 * ERASE_SIZE;
            } else {
              pointerSettings.stroke.erase.width = ERASE_SIZE;
            }
          }
        );

        if (localHasActivePen && tool !== PEN_ERASER) {
          const pointerSettings = ZCanvas.input.pointerSettings.pen;
          pointerSettings.action = ZActions.DRAW;
          pointerSettings.stroke.draw.shape = shape;
          pointerSettings.stroke.draw.width = STROKE_SIZES[strokeSizeIndex];
        }
      }

      if (tool === ZActions.SELECT) {
        ['mouse', 'touch'].forEach((pointerType: keyof typeof ZCanvas.input.pointerSettings) => {
          const pointerSettings = ZCanvas.input.pointerSettings[pointerType];
          if (pointerType === 'mouse') {
            pointerSettings.action = ZActions.SELECT;
            pointerSettings.stroke.draw.shape = ZShapes.FREEFORM;
          } else if (isTablet) {
            pointerSettings.action = ZActions.SELECT_OR_PANZOOM;
            pointerSettings.stroke.draw.shape = ZShapes.FREEFORM;
          } else {
            pointerSettings.action = ZActions.MANIPULATE_OR_ERASE;
            pointerSettings.stroke.draw.shape = ZShapes.FREEFORM;
          }
        });
      } else if (tool === ZActions.PANZOOM) {
        ['mouse', 'touch'].forEach((pointerType: keyof typeof ZCanvas.input.pointerSettings) => {
          const pointerSettings = ZCanvas.input.pointerSettings[pointerType];
          pointerSettings.action = ZActions.PANZOOM;
          pointerSettings.stroke.draw.shape = ZShapes.FREEFORM;
        });
      } else if (!Object.values(ZActions).includes(tool as number)) {
        // We have a custom tool, like zoom or something
        ['mouse', 'touch', 'passivePen'].forEach((pointerType: keyof typeof ZCanvas.input.pointerSettings) => {
          const pointerSettings = ZCanvas.input.pointerSettings[pointerType];
          pointerSettings.action = tool === PEN_ERASER ? ZActions.SELECT : ZActions.HOVER;
          pointerSettings.stroke.draw.shape = ZShapes.FREEFORM;
        });

        if (localHasActivePen && tool === PEN_ERASER) {
          const pointerSettings = ZCanvas.input.pointerSettings.pen;
          pointerSettings.action = ZActions.ERASE;
          pointerSettings.stroke.draw.shape = ZShapes.FREEFORM;
          pointerSettings.stroke.erase.width = ERASE_SIZE;
        }
      }
      ReactTooltip.hide();
    });
  }, [toolSelected]);

  useEffect(() => {
    setToolSelected(currentZAction);
  }, []);

  useEffect(() => {
    const [color, event] = colorSelected;

    if (event) {
      const rgb = hexToRgb(color.hex);
      ZCanvas.initialized.then(() => {
        ZCanvas.input.pointerSettings.mouse.stroke.draw.color = rgb;
        ZCanvas.input.pointerSettings.touch.stroke.draw.color = rgb;
        ZCanvas.input.pointerSettings.pen.stroke.draw.color = rgb;
        ZCanvas.input.pointerSettings.passivePen.stroke.draw.color = rgb;
      });
    }
  }, [colorSelected]);

  // Stroke width changed
  useEffect(() => {
    const [strokeSizeIndex, event] = strokeSizeSelected;

    if (event) {
      ZCanvas.initialized.then(() => {
        ZCanvas.input.pointerSettings.mouse.stroke.draw.width = STROKE_SIZES[strokeSizeIndex];
        ZCanvas.input.pointerSettings.touch.stroke.draw.width = STROKE_SIZES[strokeSizeIndex];
        ZCanvas.input.pointerSettings.pen.stroke.draw.width = STROKE_SIZES[strokeSizeIndex];
        ZCanvas.input.pointerSettings.passivePen.stroke.draw.width = STROKE_SIZES[strokeSizeIndex];
      });
    }
  }, [strokeSizeSelected]);

  // Set restricted undo/redo on or off
  useEffect(() => {
    ZCanvas.initialized.then(() => {
      const presenter = clients?.find((c) => c.isPresenter);
      ZCanvas.history.setRestrictedMode(forceFollow && presenter && !presenter.self);
    });
  }, [forceFollow, clients]);

  useEffect(
    () => () => {
      setZAction(ZActions.SELECT, ZShapes.FREEFORM, false);
    },
    []
  );

  const togglePipWindow = () => {
    if (window.ffbecandroidjni?.toggle_pip_window_state) {
      window.ffbecandroidjni.toggle_pip_window_state();
    }
  };

  // =============================================================
  // Toolbar pointer event, also used for shortcuts in many cases
  // =============================================================
  const newStickyNote = () => {
    setInkToolTrayOpen(false);
    setStickerTrayOpen(false);

    if (room) {
      const [{ color }] = BackgroundColors;
      const metadata = packStickyNoteMetadata({ color });
      // Create an empty sticky note
      prepareAndPublishStickyNote({
        file: null,
        options: {
          color,
          metadata,
          selectPaper: false,
          entrance: 'bottom',
        },
      });
    } else {
      // Create a draft sticky note and open the editor
      currentStickyNoteEdit({ new: true });
      analytics.viewPanel('/sticky-note-editor');
    }
  };

  const newTextBox = () => {
    if (!localHasActivePen) {
      setInkToolTrayOpen(false);
    }
    setStickerTrayOpen(false);
    currentTextBlockEdit({ new: true });
    analytics.viewPanel('/text-block-editor');
  };

  const selectTool = (e: boolean) => {
    if (!localHasActivePen) {
      setInkToolTrayOpen(false);
      setStickerTrayOpen(false);
      setZAction(ZActions.SELECT, ZShapes.FREEFORM, e);
    }
    ReactTooltip.hide();
  };

  const drawTool = (shortcut: boolean, penMode: boolean) => {
    if (shortcut && !(inkToolTrayOpen && inkTool !== 'draw')) {
      setStickerTrayOpen(false);
      setInkToolTrayOpen(!inkToolTrayOpen);
    }

    setInkTool('draw');

    if (penMode) {
      setZAction(ZActions.SELECT, ZShapes.FREEFORM, true);
    } else {
      setZAction(ZActions.DRAW, ZShapes.FREEFORM, true);
    }

    if (!firstSelection) {
      setStrokeSizeSelected([DEFAULT_SIZE_INDEX, true]);
      setFirstSelection(true);
    }
  };

  const lineTool = (shortcut: boolean, penMode: boolean) => {
    if (shortcut && !(inkToolTrayOpen && inkTool !== 'line')) {
      setStickerTrayOpen(false);
      setInkToolTrayOpen(!inkToolTrayOpen);
    }

    setInkTool('line');

    if (penMode) {
      setZAction(ZActions.SELECT, ZShapes.LINE, true);
    } else {
      setZAction(ZActions.DRAW, ZShapes.LINE, true);
    }

    if (!firstSelection) {
      setStrokeSizeSelected([DEFAULT_SIZE_INDEX, true]);
      setFirstSelection(true);
    }
  };

  const eraseTool = (shortcut: boolean, penMode: boolean) => {
    if (shortcut && !(inkToolTrayOpen && inkTool !== 'erase')) {
      setStickerTrayOpen(false);
      setInkToolTrayOpen(!inkToolTrayOpen);
    }

    setInkTool('erase');

    if (penMode) {
      setZAction(PEN_ERASER, ZShapes.FREEFORM, false);
    } else {
      setZAction(ZActions.ERASE, ZShapes.FREEFORM, true);
    }
  };

  const inkToolTrayButtonPressed = () => {
    setStickerTrayOpen(false);
    setInkToolTrayOpen(!inkToolTrayOpen);
    ReactTooltip.hide();

    const [currentTool] = toolSelected;
    if (!localHasActivePen && currentTool !== ZActions.DRAW && currentTool !== ZActions.ERASE) {
      // Choose draw tool when non-ink tools are selected (except in active pen mode)
      drawTool(false, localHasActivePen);
      return;
    }

    switch (inkTool) {
      case 'draw':
        drawTool(false, localHasActivePen);
        break;
      case 'erase':
        eraseTool(false, localHasActivePen);
        break;
      case 'line':
        lineTool(false, localHasActivePen);
        break;
      default:
        drawTool(false, localHasActivePen);
    }
  };

  // ==========================
  // Shortcuts for the toolbar
  // ===========================

  // New sticky note
  useHotkeys(
    's',
    () => {
      setStickerTrayOpen(!stickerTrayOpen);
    },
    {},
    [stickerTrayOpen]
  );

  useHotkeys(
    'n',
    (e) => {
      e.preventDefault();
      newStickyNote();
    },
    {},
    [localHasActivePen]
  );

  // New text box
  useHotkeys(
    't',
    (e) => {
      e.preventDefault();
      newTextBox();
    },
    {},
    [localHasActivePen]
  );

  // Upload image
  useHotkeys(
    'i',
    () => {
      setShowImportDialog(true);
    },
    {},
    []
  );

  // Select tool
  useHotkeys(
    'v, esc',
    () => {
      selectTool(false);
    },
    {},
    [localHasActivePen]
  );

  // Draw tool
  useHotkeys(
    'b',
    () => {
      drawTool(true, localHasActivePen);
    },
    {},
    [localHasActivePen, inkToolTrayOpen, toolSelected]
  );

  useHotkeys(
    'l',
    () => {
      if (!featureFlags.enableLineTool) {
        return;
      }
      lineTool(true, localHasActivePen);
    },
    {},
    [localHasActivePen, inkToolTrayOpen, toolSelected]
  );

  // Erase tool
  useHotkeys(
    'e',
    () => {
      eraseTool(true, localHasActivePen);
    },
    {},
    [localHasActivePen, inkToolTrayOpen, toolSelected]
  );

  const [currentTool] = toolSelected;
  const [currentColor] = colorSelected;
  const [currentSize] = strokeSizeSelected;

  useReduxAction(
    () => {
      // In the reconnect case we need to set color again
      setColorSelected([COLORS[0], false]);
      setStrokeSizeSelected([DEFAULT_SIZE_INDEX, false]);
    },
    actions.setInitialLoadingDone,
    []
  );

  const renderStrokeButtons = (type: string) => {
    const disabled = type === 'erase';
    const buttons = [];

    for (let size = 2; size <= STROKE_SIZES.length - 1; size += 1) {
      const { iconSuffix } = currentColor;
      let active = currentSize === size - 1 && ![ZActions.SELECT, ZActions.ERASE, PEN_ERASER].includes(currentTool);

      if (localHasActivePen) {
        active = currentSize === size - 1 && currentTool !== PEN_ERASER;
      }

      const icon =
        type === 'draw'
          ? `/icons/strokes/icn_stroke${size}_${iconSuffix}.svg`
          : `/icons/lines/icn_line_stroke${size}_${iconSuffix}.svg`;

      let style = null;
      if (size === STROKE_SIZES.length) {
        style = { marginBottom: '6px' };
      }

      buttons.push(
        <StyledStack.InkToolButton
          className={disabled ? 'disabled' : active && 'active'}
          style={style}
          icon={icon}
          key={`stroke-${size}`}
          onPointerUp={(e) => {
            if (disabled) {
              return;
            }
            e.stopPropagation();
            e.persist();
            setStrokeSizeSelected([size - 1, true]);

            if (!localHasActivePen) {
              if (type === 'draw') {
                setZAction(ZActions.DRAW, ZShapes.FREEFORM, true);
              } else {
                setZAction(ZActions.DRAW, ZShapes.LINE, true);
              }
            } else if (type === 'draw') {
              setZAction(ZActions.SELECT, ZShapes.FREEFORM, true);
            } else {
              setZAction(ZActions.SELECT, ZShapes.LINE, true);
            }
          }}
        />
      );
    }

    return buttons;
  };

  const platformHasClipboard = () => !window.ffbecandroidjni && !isSafari && !isMsTeamsApp;

  const renderColorButtons = (type: string) => {
    const disabled = type === 'erase';
    const buttons: JSX.Element[] = [];

    COLORS.forEach((color) => {
      let active = currentColor === color && ![ZActions.ERASE, ZActions.SELECT, PEN_ERASER].includes(currentTool);

      if (localHasActivePen) {
        active = currentColor === color && currentTool !== PEN_ERASER;
      }

      buttons.push(
        <StyledStack.InkToolButton
          className={disabled ? 'disabled' : active && 'active'}
          key={color.iconSuffix}
          icon={`/icons/strokes/icn_${color.iconSuffix}.svg`}
          onPointerUp={(e) => {
            if (disabled) {
              return;
            }
            e.stopPropagation();
            e.persist();
            setColorSelected([color, true]);

            // If we have a non brush tool selected when selecting color, then switch to brush. Might change later.
            if (!localHasActivePen && (ZActions.SELECT === currentTool || ZActions.ERASE === currentTool)) {
              if (type === 'draw') {
                setZAction(ZActions.DRAW, ZShapes.FREEFORM, true);
              } else {
                setZAction(ZActions.DRAW, ZShapes.LINE, true);
              }
            } else if (localHasActivePen) {
              if (type === 'draw') {
                setZAction(ZActions.SELECT, ZShapes.FREEFORM, true);
              } else {
                setZAction(ZActions.SELECT, ZShapes.LINE, true);
              }
            }
          }}
        />
      );
    });

    return buttons;
  };

  return (
    <>
      <div>
        {window.ffbecandroidjni !== undefined && (
          <StyledStack.PipWindowSideButton
            data-place="right"
            onClick={() => {
              togglePipWindow();
            }}
          />
        )}
        <StyledStack.StickyNoteSideButton
          data-place="right"
          data-tip={'New Sticky Note <span class="shortcut">N</span>'}
          data-tip-disable={room}
          className={currentTool === ITEM_PLACEMENT && 'active'}
          onClick={() => {
            ReactTooltip.hide();
            newStickyNote();
          }}
        />
        {!room && (
          <StyledStack.TextSideButton
            data-place="right"
            data-tip-disable={room}
            data-tip={'New Text <span class="shortcut">T</span>'}
            onClick={() => {
              ReactTooltip.hide();
              newTextBox();
            }}
          />
        )}
        <StyledStack.StickersSideButton
          data-place="right"
          data-tip={'New Sticker <span class="shortcut">S</span>'}
          data-tip-disable={stickerTrayOpen || room}
          onClick={() => {
            ReactTooltip.hide();
            setStickerTrayOpen(!stickerTrayOpen);
          }}
        >
          <StickerTray
            stickerTrayOpen={stickerTrayOpen}
            setStickerTrayOpen={(open) => setStickerTrayOpen(open)}
            defaultStickerSelected={(fileName) => {
              prepareAndPublishSticker({
                url: fileName,
                options: {
                  selectPaper: true,
                },
              });
            }}
            customStickerSelected={(file) => {
              prepareAndPublishSticker({
                file,
                options: {
                  selectPaper: true,
                },
              });
            }}
            existingStickerSelected={(imageId) => {
              prepareAndPublishSticker({
                imageId,
                options: {
                  selectPaper: true,
                },
              });
            }}
            room={room}
            placement={room ? 'vertical' : 'horizontal'}
          />
        </StyledStack.StickersSideButton>
        {!room && (
          <StyledStack.ImageSideButton
            onClick={() => setShowImportDialog(true)}
            data-place="right"
            data-tip={'Import <span class="shortcut">I</span>'}
          ></StyledStack.ImageSideButton>
        )}
      </div>
      {!room && <StyledSideBar.Divider />}
      <div>
        {!room && (
          <StyledStack.ToolSideButton
            className={(currentTool === ZActions.SELECT || localHasActivePen) && 'active'}
            icon="/icons/icn_select.svg"
            data-place="right"
            data-tip={'Select <span class="shortcut">V / ESC</span>'}
            onPointerUp={(e) => {
              e.persist();
              selectTool(true);
            }}
          />
        )}
        {room &&
          (featureFlags.enableRoomPenUI ? (
            <RoomToolbar
              colorSelected={colorSelected[0]}
              setColorSelected={(color) => setColorSelected([color, true])}
              strokeSizeSelected={strokeSizeSelected[0]}
              setStrokeSizeSelected={(strokeSize) => setStrokeSizeSelected([strokeSize, true])}
              inkTool={inkTool}
              setInkTool={(tool) => {
                if (tool === 'draw') {
                  drawTool(false, localHasActivePen);
                } else {
                  lineTool(false, localHasActivePen);
                }
              }}
            />
          ) : (
            <ISERoomToolbar
              colorSelected={colorSelected[0]}
              setColorSelected={(color) => setColorSelected([color, true])}
              strokeSizeSelected={strokeSizeSelected[0]}
              setStrokeSizeSelected={(strokeSize) => setStrokeSizeSelected([strokeSize, true])}
            />
          ))}
        {!room && (
          <StyledStack.ToolTraySideButton
            data-place="right"
            data-tip={'Draw <span class="shortcut">B</span>'}
            data-tip-disable={inkToolTrayOpen || room}
            className={`${(currentTool === ZActions.DRAW || currentTool === ZActions.ERASE) && 'active'}
          ${localHasActivePen && 'active-pen'}
          ${(localHasActivePen || currentTool === ZActions.DRAW || currentTool === ZActions.ERASE) && 'ink-tool-used'}
          ${inkTool === 'draw' && 'pen'}
          ${inkTool === 'line' && 'line'}
          ${inkTool === 'erase' && 'erase'}`}
            onPointerUp={(e) => {
              e.persist();
              inkToolTrayButtonPressed();
            }}
          >
            <ToolTray show={inkToolTrayOpen} showActivePen={localHasActivePen}>
              <StyledSideBar.CloseTray
                onClick={() => {
                  setInkToolTrayOpen(false);
                }}
              />
              <StyledSideBar.TrayTitle>{inkTool}</StyledSideBar.TrayTitle>
              <StyledSideBar.ToolTrayGrid style={{ paddingTop: '0' }}>
                <StyledStack.InkToolButton
                  className={inkTool === 'draw' && 'active'}
                  icon="/icons/icn_pen.svg"
                  data-place="right"
                  data-tip={'Draw Tool <span class="shortcut">B</span>'}
                  onPointerUp={(e) => {
                    e.persist();
                    e.stopPropagation();
                    drawTool(false, localHasActivePen);
                  }}
                />
                {featureFlags.enableLineTool && (
                  <StyledStack.InkToolButton
                    className={inkTool === 'line' && 'active'}
                    icon="/icons/icn_line.svg"
                    data-place="right"
                    data-tip={'Line Tool <span class="shortcut">L</span>'}
                    onPointerUp={(e) => {
                      e.persist();
                      e.stopPropagation();
                      lineTool(false, localHasActivePen);
                    }}
                  />
                )}
                <StyledStack.InkToolButton
                  className={inkTool === 'erase' && 'active'}
                  icon="/icons/icn_eraser.svg"
                  data-place="right"
                  data-tip={'Erase Tool <span class="shortcut">E</span>'}
                  onPointerUp={(e) => {
                    e.persist();
                    e.stopPropagation();
                    eraseTool(false, localHasActivePen);
                  }}
                />
              </StyledSideBar.ToolTrayGrid>
              <StyledSideBar.TrayDivider />
              <StyledSideBar.ToolTrayGrid style={{ paddingBottom: '0' }}>
                {renderStrokeButtons(inkTool)}
              </StyledSideBar.ToolTrayGrid>
              <StyledSideBar.ToolTrayGrid
                style={{
                  paddingTop: '0',
                  paddingBottom: '0',
                  height: `${inkTraysExpanded ? 0.33 * COLORS.length * 44 : 88}px`,
                }}
              >
                {renderColorButtons(inkTool)}
              </StyledSideBar.ToolTrayGrid>
              <StyledStack.ExpandButton
                className={inkTraysExpanded && 'expanded'}
                data-place="right"
                data-tip={inkTraysExpanded ? 'Show Less Colors' : 'Show More Colors'}
                onPointerUp={(e) => {
                  e.persist();
                  e.stopPropagation();
                  setInkTraysExpanded(!inkTraysExpanded);
                  ReactTooltip.hide();
                }}
              />
              {localHasActivePen && (
                <>
                  <StyledSideBar.TrayDivider />
                  <StyledSideBar.ActivePenWrapper
                    onPointerUp={(e) => {
                      // Stop the pointer event from closing the flyout
                      e.stopPropagation();
                    }}
                  >
                    <StyledSideBar.ActivePenIcon />
                    <ToggleSwitch
                      checked={activePenSwitch}
                      color="#279AE7"
                      onChange={() => {
                        if (activePenSwitch) {
                          setTimeout(() => {
                            setHasActivePen(false);
                            switch (inkTool) {
                              case 'draw':
                                drawTool(false, false);
                                return;
                              case 'erase':
                                eraseTool(false, false);
                                return;
                              case 'line':
                                lineTool(false, false);
                                return;
                              default:
                                drawTool(false, false);
                            }
                          }, 200);
                        }
                        setActivePenSwitch(!activePenSwitch);
                      }}
                    />
                  </StyledSideBar.ActivePenWrapper>
                </>
              )}
            </ToolTray>
          </StyledStack.ToolTraySideButton>
        )}
        <StyledStack.DoubleButton data-place="right" data-tip="Undo/Redo" data-effect="solid" data-tip-disable={room}>
          <StyledStack.ToolSideButton
            className={classNames({ disabled: !canUndo })}
            icon="/icons/icn_undo_alt.svg"
            onClick={() => {
              ReactTooltip.hide();
              if (canUndo) {
                ZCanvas.undo();
                analytics.undo();
              }
            }}
          />
          <StyledStack.ToolSideButton
            onClick={() => {
              ReactTooltip.hide();
              if (canRedo) {
                ZCanvas.redo();
                analytics.redo();
              }
            }}
            className={!canRedo && 'disabled'}
            icon="/icons/icn_redo_alt.svg"
          />
        </StyledStack.DoubleButton>
        {platformHasClipboard() && (
          <StyledStack.ClipboardMenuSideButton
            ref={clipboardMenuButton}
            data-place="right"
            data-tip="Clipboard"
            data-tip-disable={clipboardMenuOpen || room}
            className={clipboardMenuOpen && 'active'}
            onClick={() => {
              setClipboardMenuOpen(!clipboardMenuOpen);
              ReactTooltip.hide();
            }}
          >
            <ClipboardMenu
              show={clipboardMenuOpen}
              enableCut={selectedPapers.length > 0}
              enableCopy={selectedPapers.length > 0}
              enablePaste={pasteToolEnabled}
              onCut={() => cutPapers(selectedPapers)}
              onCopy={() => copyPapers(selectedPapers)}
              onPaste={() => triggerPasteEvent()}
              onClose={(e) => {
                if (!clipboardMenuButton.current.contains(e.detail)) {
                  setClipboardMenuOpen(false);
                }
              }}
            />
          </StyledStack.ClipboardMenuSideButton>
        )}
      </div>
      {showImportDialog && (
        <Import
          session={session}
          uploadFile={async (file, cancelledRef, progressCallback, doneCallback, margins) => {
            if (file.type.match('^image/(png|jpg|jpeg)')) {
              const maxSize = {
                width: margins.right - margins.left,
                height: margins.bottom - margins.top,
              };
              const position = {
                x: margins.left + 0.5 * maxSize.width,
                y: 2160.0 - (margins.top + 0.5 * maxSize.height),
              };
              prepareAndPublishImage({
                file,
                options: {
                  selectPaper: true,
                  position,
                  maxSize,
                  coordsInWritingArea: true,
                },
              });
              analytics.addImage('open');
              if (!localHasActivePen) {
                setInkToolTrayOpen(false);
              }
              setStickerTrayOpen(false);
              doneCallback();
            }
            if (file.type.match('^application/pdf')) {
              try {
                const pages = await convert.pdfDocumentToImages(
                  file,
                  addPaperToWhiteboardComponent,
                  progressCallback,
                  cancelledRef,
                  margins
                );
                analytics.importFile(file.type, 'open', pages, file.size / 1024);
                doneCallback();
              } catch (e) {
                analytics.importFailed(file.type, file.size / 1024);
                doneCallback(e);
              }

              if (!localHasActivePen) {
                setInkToolTrayOpen(false);
              }
              setStickerTrayOpen(false);
            } else if (file.type.match(OFFICE_FILE_TYPES)) {
              try {
                const pages = await convert.officeFileToImages(
                  file,
                  addPaperToWhiteboardComponent,
                  progressCallback,
                  cancelledRef,
                  margins
                );
                analytics.importFile(file.type, 'open', pages, file.size / 1024);
                doneCallback();
              } catch (e) {
                analytics.importFailed(file.type, file.size / 1024);
                doneCallback(e);
              }
            }
          }}
          onClose={() => setShowImportDialog(false)}
        />
      )}
    </>
  );
};

export default Toolbar;
