import React, { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';

import * as analytics from 'client/common/analytics';
import { ModalBackDrop } from 'client/components/Common/modal-styled';
import { useAuth } from 'client/hooks/useAuth';
import useEventListener from 'client/hooks/useEventListener';
import { useSelector } from 'client/hooks/useSelector';

import * as Styled from './dropMenu-styled';

interface Props {
  allowedToCreateFile: { allowed: boolean; limit?: number };
  canCastBoard: boolean;
  castBoard: () => void;
  exportCsv: () => void;
  goHome: () => void;
  sendLogs: () => void;
  isGuestFile: boolean;
  isRoomFile: boolean;
  showDownloadDialog: () => void;
  showOpenBoardDialog: () => void;
  showRenameDialog: () => void;
  showSaveDialog: () => void;
  showSettingsDialog: () => void;
  yourFile: boolean;
}

const DropMenu: React.FC<Props> = ({
  allowedToCreateFile,
  canCastBoard,
  castBoard,
  exportCsv,
  goHome,
  sendLogs,
  isGuestFile,
  isRoomFile,
  showDownloadDialog,
  showOpenBoardDialog,
  showRenameDialog,
  showSaveDialog,
  showSettingsDialog,
  yourFile,
}) => {
  const { isMsTeamsApp, loadedFileInfo, room, session } = useSelector((state) => ({
    isMsTeamsApp: state.isMsTeamsApp,
    loadedFileInfo: state.loadedFileInfo,
    room: state.room,
    session: state.session,
  }));

  const isInternalEnv = window.INITIAL_STATE.gcpEnv !== 'production';

  const [showMenuFlyout, setShowMenuFlyout] = useState(false);
  const hamburger = useRef<HTMLDivElement>();
  const auth = useAuth();

  const setTitle = () => {
    if (loadedFileInfo?.fileName) {
      document.title = loadedFileInfo.fileName;
    } else {
      document.title = 'FlatFrog Board';
    }
    analytics.setBoardId(loadedFileInfo?.id);
  };

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (!hamburger.current.contains(e.detail)) {
      setShowMenuFlyout(false);
    }
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [session]);

  useEffect(() => {
    setTitle();

    return () => {
      document.title = 'FlatFrog Board';
    };
  }, []);

  useEffect(() => {
    setTitle();
  }, [loadedFileInfo]);

  let blobColor = '#e08f26';
  let saveText = 'Syncing changes...';

  if (loadedFileInfo?.success === false) {
    blobColor = '#fc0f03';
    saveText = 'Error syncing to cloud';
  } else if (loadedFileInfo?.latestLocalActionId === loadedFileInfo?.latestServerActionId) {
    blobColor = '#26e058';
    saveText = 'All changes synced to cloud';
  }

  const closePipWindow = () => {
    if (window.ffbecandroidjni?.close_pip_window) {
      window.ffbecandroidjni.close_pip_window();
    }
  };

  return (
    <>
      <Styled.Hamburger
        id="session-burger-menu"
        ref={hamburger}
        onClick={(e) => {
          e.stopPropagation();
          ReactTooltip.hide();
          closePipWindow();
          setShowMenuFlyout(!showMenuFlyout);
        }}
        className={`${showMenuFlyout ? 'active' : ''} ${isMobileOnly ? 'mobile-bar' : ''}`}
      >
        {isMobileOnly && <ModalBackDrop className={showMenuFlyout ? 'active' : ''} />}
        <Styled.MenuContainer className={isMobileOnly ? 'is-mobile' : ''}>
          <Styled.MenuGroup>
            {auth.user && !isRoomFile && loadedFileInfo?.fileName && (
              <Styled.FileNameMenuItemContainer
                data-tip={yourFile ? 'Rename' : 'File information'}
                onClick={showRenameDialog}
              >
                <Styled.FileNameMenuItem>{loadedFileInfo?.fileName}</Styled.FileNameMenuItem>
                <Styled.FileStatus blobColor={blobColor}>{saveText}</Styled.FileStatus>
                <span />
              </Styled.FileNameMenuItemContainer>
            )}
          </Styled.MenuGroup>
          <Styled.MenuGroup>
            {room && <Styled.MenuItem onClick={showOpenBoardDialog}>Open board…</Styled.MenuItem>}
            {!room && auth.user && canCastBoard && (
              <Styled.MenuItem onClick={castBoard}>
                <img src="/icons/icn_open_in_room.svg" />
                Open in Room
              </Styled.MenuItem>
            )}
            {isMsTeamsApp && loadedFileInfo?.linkUrl && (
              <Styled.MenuItem onClick={() => window.open(loadedFileInfo.linkUrl, '_blank')}>
                Open in Browser
                <Styled.MenuItemExternalLinkIcon src="/icons/icn_external_link.svg" />
              </Styled.MenuItem>
            )}
            {((room && isGuestFile) || (auth.user && allowedToCreateFile?.allowed && isRoomFile)) && (
              <Styled.MenuItem id="session-burger-menu-save" onClick={showSaveDialog}>
                Save to cloud…
              </Styled.MenuItem>
            )}
          </Styled.MenuGroup>
          <Styled.MenuGroup>
            {!isMsTeamsApp && !room && (
              <Styled.MenuItem id="session-burger-menu-download" onClick={showDownloadDialog}>
                Download
              </Styled.MenuItem>
            )}
            {!isMsTeamsApp && !isMobileOnly && !room && (
              <Styled.MenuItem id="session-burger-menu-csv" onClick={exportCsv}>
                Export as CSV
              </Styled.MenuItem>
            )}
          </Styled.MenuGroup>
          <Styled.MenuGroup>
            <Styled.MenuItem
              id="session-burger-menu-help"
              onClick={() => window.open('https://help.flatfrog.com/knowledge', '_blank')}
            >
              Help
              <Styled.MenuItemExternalLinkIcon src="/icons/icn_external_link.svg" />
            </Styled.MenuItem>
            {isInternalEnv && room && !isMsTeamsApp && (
              <Styled.MenuItem id="session-burger-menu-send-logs" onClick={sendLogs}>
                Send logs
              </Styled.MenuItem>
            )}
          </Styled.MenuGroup>
          <Styled.MenuGroup>
            <Styled.MenuItem id="session-burger-menu-settings" onClick={showSettingsDialog}>
              About...
            </Styled.MenuItem>
          </Styled.MenuGroup>
          <Styled.MenuGroup>
            {!isMsTeamsApp && (
              <Styled.MenuItem id="session-burger-menu-close" onClick={goHome}>
                Close Board
              </Styled.MenuItem>
            )}
          </Styled.MenuGroup>
        </Styled.MenuContainer>
      </Styled.Hamburger>
    </>
  );
};

export default DropMenu;
