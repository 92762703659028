import axios, { AxiosBasicCredentials, AxiosRequestConfig } from 'axios';

type Options = {
  url: string;
  method?: 'GET' | 'DELETE' | 'POST' | 'PUT';
  data?: Record<string, unknown>;
  auth?: AxiosBasicCredentials;
  authorizationToken?: { auth: string } | false;
  onUploadProgress?: (progressEvent: unknown) => void;
  additionalOptions?: AxiosRequestConfig;
};

export const makeRequest = <T>(options: Options) =>
  axios.request<T>({
    data: options.data ?? null,
    method: options.method ?? 'GET',
    url: options.url,
    headers: options.authorizationToken
      ? {
          Authorization: `Bearer ${options.authorizationToken.auth}`,
        }
      : undefined,
    auth: options.auth,
    onUploadProgress: options.onUploadProgress ?? null,
    ...options.additionalOptions,
  });
