import styled from 'styled-components';

import { FloatingPanel } from 'client/components/Common/common-styles';

export const PenWrapper = styled.div`
  display: flex;
  height: 40px;
  position: relative;
`;

export const Pen = styled.div`
  width: 32px;
  height: 72px;
  background: url(/icons/pix_pen1@2x.png) top center no-repeat;
  background-size: cover;
  margin: 0 8px 0 8px;
  transform: translateY(-5px);
  transition: transform 120ms ease-in-out;

  &.active {
    transform: translateY(-22px);
  }
`;

export const Tray = styled.div`
  ${FloatingPanel};
  position: absolute;
  border-radius: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 135px;
  padding: 32px 0 8px 0;
  z-index: 2;
`;

export const Color = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 4px;
  flex-shrink: 0;

  &.active {
    outline: 2px solid white;
    outline-offset: -5px;
  }
`;

export const InkToolToggle = styled.div`
  width: 100%;
  background: #f5f6f7;
  border-radius: 6px;
  margin: 8px;
  color: #242424;
  font-size: 13px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseTrayButton = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  background: url(/icons/icn_close_small.svg) center center no-repeat;
  background-size: 8px;
`;
