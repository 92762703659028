import { useQuery } from '@apollo/client';
import truncate from 'lodash/truncate';
import dayjs from 'dayjs';
import React, { useLayoutEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { File, QUERY_TEAM_FILES, Team } from 'client/common/graphql';
import intersectionObserver from 'client/common/intersectionObserver';
import { getParticipantName, getParticipantColor } from 'client/common/participant-names';
import { CopyButton } from 'client/components/CollaborationSidePanel/styled';
import { CollapsiblePanel } from 'client/components/CollapsiblePanel';
import { useAuth } from 'client/hooks/useAuth';
import NameBubble from 'client/components/Common/NameBubble';
import { ContextMenu } from 'client/components/Common/ContextMenu';

import * as Styled from '../styled';

const THUMBNAIL_PLACEHOLDER = '/images/ill_thumbnail_placeholder.svg';
const MAX_CLIENTS_IN_LIST = 4;

interface Props {
  allowedToCreateFile: { allowed: boolean; limit?: number };
  deleteBoard: (f: File) => void;
  deleteTeam: () => void;
  duplicateBoard: (fileId: string) => void;
  leaveTeam: () => void;
  loading: boolean;
  renameBoard: (f: File) => void;
  showCastDialog: (e: React.MouseEvent<HTMLSpanElement>, file: File) => void;
  showEditTeam: () => void;
  showInvite: () => void;
  showMoveTo: (fileId: string) => void;
  showShareDialog: (id: string, fileName: string, showMove: boolean) => void;
  showTeamMembers: () => void;
  team: Team;
}

const TeamComponent: React.FC<Props> = ({
  allowedToCreateFile,
  deleteBoard,
  deleteTeam,
  duplicateBoard,
  leaveTeam,
  loading,
  renameBoard,
  showCastDialog,
  showEditTeam,
  showInvite,
  showMoveTo,
  showShareDialog,
  showTeamMembers,
  team,
}) => {
  const auth = useAuth();
  const location = useLocation();

  // For teams
  const [sorting, setSorting] = useState('FILENAME_ASC');

  useLayoutEffect(() => {
    const menus = document.getElementsByClassName('menu-container');
    intersectionObserver.disconnect();
    Array.from(menus).forEach((el) => intersectionObserver.observe(el));
  });

  const owner = team.members.find((member) => member.uid === auth?.user?.uid)?.role === 'Owner';

  const filesQuery = useQuery(QUERY_TEAM_FILES, {
    variables: { input: { sort: sorting, teamId: team.id } },
  });

  const items = (files: File[], teamId: string) =>
    files.map((f) => (
      <Link
        to={{
          pathname: f.id !== 'load' ? `/dashboard/open/${f.id}` : '',
          state: {
            from: location.pathname,
            teamId,
          },
        }}
        key={f.id}
        id={`start-${f.id}`}
      >
        <Styled.MyBoardsItem>
          <Styled.MyBoardsBoardLabel>
            <Styled.MyBoardsBoardThumbnail
              style={{
                backgroundImage: `url(${f.thumbnailUri ?? THUMBNAIL_PLACEHOLDER})`,
              }}
            />
            {f.fileName || 'Unnamed Board'}
          </Styled.MyBoardsBoardLabel>
          <Styled.RecentBoardExpander />
          {/* TODO: This should be a <time datetime={f.dateModified}> */}
          <Styled.RecentBoardTime>{dayjs(f.dateModified).fromNow()}</Styled.RecentBoardTime>
          <Styled.ShareColumn>
            <Styled.ClickGuard onClick={(e) => e.preventDefault()}>
              <CopyButton
                style={{ margin: 'auto 0' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showShareDialog(f.id, f.fileName, !!owner);
                }}
              >
                Share
              </CopyButton>
              <Styled.CastColumn>
                <Styled.CastButton onClick={(e) => showCastDialog(e, f)} />
              </Styled.CastColumn>
              <ContextMenu
                items={[
                  [
                    {
                      title: 'Change Workspace',
                      onClick: () => showMoveTo(f.id),
                      hidden: !owner,
                    },
                    {
                      title: 'Duplicate',
                      onClick: () => duplicateBoard(f.id),
                      disabled: !allowedToCreateFile.allowed,
                    },
                    {
                      title: 'Rename',
                      onClick: () => renameBoard(f),
                    },
                  ],
                  [
                    {
                      onClick: () => deleteBoard(f),
                      title: 'Delete Board',
                    },
                  ],
                ]}
              >
                <Styled.MoreButton id={`menu-${f.id}`} />
              </ContextMenu>
            </Styled.ClickGuard>
          </Styled.ShareColumn>
        </Styled.MyBoardsItem>
      </Link>
    ));

  const sortedMembers = [
    team.members.find((m) => m.role === 'Owner'),
    ...team.members.filter((m) => m.role !== 'Owner'),
  ].filter((m) => m);
  const memberSlice = sortedMembers.slice(0, MAX_CLIENTS_IN_LIST);
  const showMore = team.members.length > MAX_CLIENTS_IN_LIST;
  const newBoard = (
    <Styled.NewBoardRowWrapper
      className={!allowedToCreateFile.allowed && 'disabled'}
      data-tip={
        allowedToCreateFile.limit
          ? `Maximum number (${allowedToCreateFile.limit}) of boards reached`
          : 'Not allowed to create new board'
      }
      data-tip-disable={loading || allowedToCreateFile.allowed}
      data-place="top"
      data-delay-show={300}
      data-effect="solid"
    >
      <Link
        to={{
          pathname: allowedToCreateFile.allowed ? '/dashboard/my-boards/templates' : undefined,
          state: { team },
        }}
      >
        <Styled.MyBoardsItem className="new-board">
          <Styled.MyBoardsBoardLabel>
            <Styled.MyBoardsBoardThumbnail />
            New Board
          </Styled.MyBoardsBoardLabel>
        </Styled.MyBoardsItem>
      </Link>
    </Styled.NewBoardRowWrapper>
  );

  return (
    <CollapsiblePanel
      heading={
        <>
          <Styled.TeamBubble
            color={getParticipantColor(team.name)}
            data-tip={truncate(team.description, { length: 120 })}
            data-effect="solid"
          >
            {getParticipantName(team.name)}
          </Styled.TeamBubble>
          <div data-tip={truncate(team.description, { length: 120 })} data-effect="solid">
            {team.name}
          </div>
          <Styled.TeamMembers>
            {memberSlice.map((member, idx) => (
              <span
                key={idx}
                className="name-bubble-border"
                style={{
                  transform: `translateX(${
                    (memberSlice.length - (idx + (showMore ? 0 : 1))) * 6
                  }px) translateZ(-${idx}px)`,
                }}
              >
                <NameBubble displayName={member.displayName} onClick={showTeamMembers} disabled={member.disabled} />
              </span>
            ))}
            {showMore && (
              <Styled.PlusMoreBubble
                data-tip="View all Workspace members"
                data-effect="solid"
                onClick={showTeamMembers}
                style={{
                  transform: `translateZ(-${MAX_CLIENTS_IN_LIST}px)`,
                }}
              >
                +{team.members.length - MAX_CLIENTS_IN_LIST}
              </Styled.PlusMoreBubble>
            )}
          </Styled.TeamMembers>
          {owner && (
            <Styled.InviteMoreButton data-tip="Add workspace members" data-effect="solid" onClick={showInvite} />
          )}
          <ContextMenu
            items={[
              [
                {
                  title: 'Edit Workspace',
                  onClick: showEditTeam,
                  hidden: !owner,
                },
                {
                  title: `${owner ? 'Edit' : 'View'} Workspace Members`,
                  onClick: showTeamMembers,
                },
              ],
              [
                {
                  title: 'Delete Workspace',
                  onClick: deleteTeam,
                  hidden: !owner,
                },
                {
                  title: 'Leave Workspace',
                  onClick: leaveTeam,
                  hidden: owner,
                },
              ],
            ]}
          >
            <Styled.MoreButton id={`workspace-dot-button-${team.id}`} />
          </ContextMenu>
        </>
      }
    >
      <Styled.MyBoardsTable>
        <Styled.MyBoardsColumns>
          <Styled.MyBoardsColumnTitle>
            <span
              className={sorting.endsWith('_ASC') ? 'up' : ''}
              onClick={() => setSorting(sorting === 'FILENAME_DESC' ? 'FILENAME_ASC' : 'FILENAME_DESC')}
            >
              Filename
            </span>
          </Styled.MyBoardsColumnTitle>
          <Styled.MyBoardsColumnDate>
            <span
              className={sorting.endsWith('_ASC') ? 'up' : ''}
              onClick={() => {
                setSorting(sorting === 'LASTMODIFIED_ASC' ? 'LASTMODIFIED_DESC' : 'LASTMODIFIED_ASC');
              }}
            >
              Date modified
            </span>
          </Styled.MyBoardsColumnDate>
          <Styled.MyBoardsColumnCopy>Share</Styled.MyBoardsColumnCopy>
        </Styled.MyBoardsColumns>
        <>
          {newBoard}
          {filesQuery?.data?.teamFiles && items(filesQuery?.data?.teamFiles, team.id)}
        </>
      </Styled.MyBoardsTable>
    </CollapsiblePanel>
  );
};

export default TeamComponent;
