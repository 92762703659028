import React from 'react';
import styled from 'styled-components';

import * as Styled from '../styled';

const Container = styled.div`
  background: white;
  margin-top: 31px;
  padding: 50px 40px;
  box-shadow: 0px 2px 4px #00000014;
  border: 0.5px solid #00000014;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 31px;
`;

const Header = styled.div`
  font: normal normal normal 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #242424;
  margin-bottom: 10px;
`;

const Text = styled.div`
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #242424;

  & + & {
    margin-top: 20px;
  }
`;

const Button = styled.button`
  background: #279ae7;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 40px;
  font: normal normal medium 15px/20px Open Sans;
  letter-spacing: -0.37px;
  color: #ffffff;
  padding: 18px 36px;
  margin: 26px;

  &:hover {
    background: #2287ca;
  }
`;

// TODO: Fetch latest version download link from latest.yml
const getDownloadLink = (version: string) => {
  const getBucketName = () => {
    switch (window.INITIAL_STATE.gcpEnv) {
      case 'development':
        return 'room-installer-dev';
      case 'test':
        return 'room-installer-test';
      case 'beta':
        return 'room-installer-beta';
      default:
        return 'room-installer-prod';
    }
  };

  return `https://storage.googleapis.com/${getBucketName()}/FlatFrog Board for Rooms Setup ${version}.exe`;
};

const DownloadRoomApp: React.FC = () => (
  <Styled.MainColumn>
    <Container>
      <img src="/images/roomapp.png" />
      <Header>Download Flatfrog Board for Rooms</Header>
      <Text>
        With Flatfrog Board for Rooms you get a digital whiteboard for your meeting rooms. It is designed for an
        intuitive and natural experience on large touchscreens.
      </Text>
      <Text>
        The app should be installed on a PC (Windows 10 1809 or later) that is connected to a InGlass™ touch display for
        best performance but works with all large touch displays.
      </Text>
      <a href={getDownloadLink(process.env.APP_VERSION)}>
        <Button>Download Flatfrog Board for Rooms</Button>
      </a>
      <Text>
        Learn more about{' '}
        <a href="https://www.flatfrog.com/inglass" target="_blank">
          InGlass™
        </a>
        ,{' '}
        <a href="https://help.flatfrog.com/en/knowledge/pc-requirements" target="_blank">
          system requirements
        </a>{' '}
        and{' '}
        <a
          href="https://help.flatfrog.com/en/knowledge/screen-setup-whiteboard-companion-vs-single-screen"
          target="_blank"
        >
          how to setup the meeting room
        </a>
        .
      </Text>
      <Text>
        If you need extra security for your meetings, see our{' '}
        <a href="https://www.flatfrog.com/pricing" target="_blank">
          pricing page
        </a>{' '}
        for more options.
      </Text>
    </Container>
  </Styled.MainColumn>
);

export default DownloadRoomApp;
