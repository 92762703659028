import { useApolloClient } from '@apollo/client';
import GraphemeSplitter from 'grapheme-splitter';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as analytics from 'client/common/analytics';
import { getFirebaseAuth } from 'client/common/firebase';
import { MUTATION_SAVE_BOARD_LINK, QUERY_LINK } from 'client/common/graphql';
import { CONNECTION_STATUS, CONNECTION_FAIL_REASON } from 'client/common/util';
import LoadingBoard from 'client/components/Whiteboard/LoadingBoard';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useLocalStorageState from 'client/hooks/useLocalStorageState';
import { useSelector } from 'client/hooks/useSelector';

const BoardLink: React.FC = () => {
  const { session } = useSelector((state) => ({ session: state.session }));

  const { setSignature, tryConnectNew } = useActions();

  const params = useParams<{ linkId: string }>();
  const auth = useAuth();
  const apolloClient = useApolloClient();
  const [error, setError] = useState(false);
  const [previousBoardLink] = useLocalStorageState('previousBoardLink', undefined);
  const [connecting, setConnecting] = useState(false);
  const history = useHistory();

  const searchQ = new URLSearchParams(window.location.search);
  const inTeams = !!searchQ.get('teams');

  useEffect(() => {
    analytics.viewPage('/boards/link');
  }, []);

  const resolveAndSaveBoardLink = async () => {
    // Resolve link to file
    const result = await apolloClient.query({
      query: QUERY_LINK,
      variables: { linkId: params.linkId },
    });
    if (result.data.link) {
      // Save link to users library of board links
      try {
        await apolloClient.mutate({
          mutation: MUTATION_SAVE_BOARD_LINK,
          variables: { linkId: params.linkId },
        });
      } catch (e) {
        // ignore
      }
      const user = getFirebaseAuth().currentUser;
      // For new users, the first token is missing display name,
      // force a refresh to make sure the token contains a name
      await user.getIdToken(true);

      if (!connecting) {
        setConnecting(true);
        tryConnectNew({ fileInfo: { linkId: params.linkId, link: true } });
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (!auth.loading) {
      if (auth.user && !auth.user.guest) {
        resolveAndSaveBoardLink();
      } else if (previousBoardLink && previousBoardLink === params.linkId) {
        tryConnectNew({ fileInfo: { linkId: previousBoardLink, link: true } });
      } else {
        history.replace('/join', { from: 'boardlink', boardLink: params.linkId, teams: inTeams });
      }
    }
  }, [auth.user, auth.loading]);

  useEffect(() => {
    if (auth.user && auth.user.displayName) {
      let { displayName } = auth.user;
      if (displayName.length > 0) {
        let signature = displayName.split(' ')[0];
        const graphemeSplitter = new GraphemeSplitter();
        if (graphemeSplitter.countGraphemes(signature) > 15) {
          const split = graphemeSplitter.splitGraphemes(signature);
          signature = split.slice(0, 16).join('');
        }
        setSignature(signature);
      } else {
        displayName = '';
        setSignature(displayName);
      }
    }
  }, [auth.user]);

  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting) {
      analytics.validBoardLink();
      history.replace('/session', { from: 'boardlink' });
    }
  }, [session]);

  return <LoadingBoard failure={error && CONNECTION_FAIL_REASON.notFound} />;
};

export default BoardLink;
