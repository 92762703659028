import styled from 'styled-components';

import * as AuthStyled from 'client/components/Auth/styled';
import { ModalContainer } from 'client/components/Common/dialog-styled';

export const Container = styled(ModalContainer)`
  padding: 64px;
  width: 450px;
`;

export const Title = styled.span`
  font-size: 18px;
  color: #242424;
  font-weight: 600;
  margin-bottom: 24px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FieldForm = styled(AuthStyled.LoginForm)`
  background: none;
  position: static;
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  transform: none;

  button {
    width: 120px;
    font-weight: 600;
    height: 48px;
    font-size: 13px;

    &:disabled {
      opacity: 0.8;
      filter: grayscale();
      cursor: default;

      &:hover {
        background: #279ae7;
      }
    }
  }

  button.cancel {
    background: rgba(0, 0, 0, 0.08);
    color: #000;

    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }
  }
`;
