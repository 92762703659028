import styled, { createGlobalStyle } from 'styled-components';

import device from 'client/common/deviceSize';
import { UserGroup, RecentBoardTeamName } from 'client/components/Dashboard/styled';

export const GlobalStyle = createGlobalStyle`
  body {
    background: transparent !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  padding: 12px 8px;
  border-radius: 18px;
  background-color: white;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 16px;

  > ${UserGroup} {
    width: auto;
    margin: 0;
    padding-left: 8px;
  }
`;

export const NameBubble = styled.div`
  position: relative;
  padding: 14px;
  width: 30px;
  height: 30px;
  border: 2px solid #00000026;
  color: ${(p) => (p.color ? p.color : '#00000026')};
  border-color: ${(p) => (p.color ? p.color : '#00000026')};
  background: ${(p) => (p.color ? `${p.color}22` : 'rgba(0,0,0,0.08)')};
  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 300ms ease-out, border-color 300ms ease-out, background-color 300ms ease-out;
`;

export const AvatarBubble = styled(NameBubble)`
  width: 40px;
  height: 40px;
  font-size: 16px;
  border: 2.5px solid;
`;

export const LoadingBoards = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
`;

export const ScrollView = styled.div`
  overflow-y: scroll;
  max-height: 240px;
  min-height: 56px;
`;

export const SearchResult = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 8px;
  padding: 8px 0;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 0 0.5px #00000014, 0 4px 8px #00000029;
  z-index: 1;
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: transparent url(/icons/icn_search_active.svg) center center no-repeat;
  opacity: 0.5;
`;

export const SearchBar = styled.input.attrs(() => ({
  type: 'text',
  placeholder: 'Search Board',
}))`
  border: 2px solid #00000029;
  border-radius: 20px;
  height: 40px;
  padding-left: 40px;
  width: 100%;
  transition: border-color 150ms ease-in-out;
  color: #242424;
  display: block;
  outline: none;

  &::placeholder {
    color: #8c8c8c;
  }

  &:focus {
    border-color: #279ae7;
    background: #fafafa;

    + ${SearchIcon} {
      opacity: 1;
    }
  }
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: flex-start;
`;

export const Category = styled.div`
  margin: 4px;
  cursor: pointer;
  border-radius: 16px;
  background-color: #f5f6f7;
  font-size: 12px;
  font-weight: 600;
  padding: 7px 8px;
  color: #242424;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #ebeced;
  }

  &.is-current {
    background: #279ae7;
    color: #ffffff;

    &:hover {
      background-color: #279ae7;
    }
  }
`;

export const BoardList = styled.div`
  flex-grow: 4;
  overflow-y: scroll;
  color: #242424;
`;

export const BoardLabel = styled.div`
  margin: auto 8px;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  width: 33%;
  display: flex;
`;

export const Modified = styled.div`
  color: #8c8c8c;
  font-size: 12px;
`;

export const SearchBoardItem = styled.li`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 0 16px;
  height: 48px;
  width: 100%;
  background-color: transparent;
  transition: background-color 300ms ease-out;
  color: #242424;
  align-items: center;

  &:focus {
    background: #0000000a;
    border: 2px solid #279ae7;
    border-radius: 6px;
    padding: 0 14px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  > ${BoardLabel} {
    flex-grow: 2;
  }

  > ${RecentBoardTeamName} {
    margin-right: 0;
    flex-shrink: 0;
  }
`;

export const BoardThumbnail = styled.div`
  width: 56px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #00000029;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 56px;
  max-width: 56px;
  border-radius: 3px;
`;

export const NewBoardThumbnail = styled(BoardThumbnail)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 300;
  background: rgba(99, 193, 153, 0.3) url(/icons/icn_blankboard.svg) center center no-repeat;
  border-color: rgba(99, 193, 153, 0.05);
`;

export const BoardItem = styled.li`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 64px;
  padding: 16px;
  background-color: transparent;
  transition: background-color 300ms ease-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }

  &.disabled {
    > ${NewBoardThumbnail} {
      filter: grayscale(100%);
    }
  }

  > ${RecentBoardTeamName} {
    margin-right: 0;
    flex-shrink: 0;
  }
`;

export const BoardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackButton = styled.div`
  align-self: flex-start;
  letter-spacing: -0.32px;
  color: #2287ca;
  font-size: 13px;
  margin-bottom: 8px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    color: #289eed;
  }
`;

export const BoardScreenshot = styled.div`
  width: 336px;
  height: 192px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 16px;
  box-shadow: 0 0 0 0.5px #00000014, 0 2px 6px #00000029;
  border: 4px solid #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const BoardScreenshotSmall = styled(BoardScreenshot)`
  width: 252px;
  height: 144px;
`;

export const BoardTitleName = styled.div`
  margin-top: 0px;
  font-size: 13px;
  font-weight: 600;
  color: #242424;
`;

export const SideInfoBox = styled.div`
  background: #4a4a4a;
  border-radius: 8px;
  padding: 8px 16px;
  margin-top: 24px;
  font-size: 13px;
  color: #e0e0e0;

  > p {
    margin: 8px 0;
    padding: 0;

    img {
      vertical-align: middle;
      margin: 0 4px 0 6px;
    }
  }

  a {
    color: #279ae7;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      color: #289eed;
    }
  }
`;

export const BoardSubtitle = styled.div`
  font-size: 13px;
  margin-top: 4px;
  color: #242424;
`;

export const BoardTitleEdit = styled.input.attrs(() => ({
  type: 'text',
  placeholder: 'Untitled - date',
}))`
  margin: 12px;
  font-size: 16px;
  outline: none;
  border: none;
  border-bottom: 1px solid #00000029;
  text-align: center;
`;

export const SidePanel = styled.div`
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  @media ${device.mobile} {
    margin: 16px;
  }

  ${BoardScreenshotSmall} {
    width: 100vw;
    height: calc(100vw * 0.5625);
    margin: 0;

    @media ${device.mobile} {
      width: 80vw;
      height: calc(80vw * 0.5625);
    }
  }
`;

export const InfoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 18px;

  > img {
    margin-bottom: 24px;
  }

  > h3 {
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
    color: #242424;
    font: normal normal 600 18px/26px Open Sans;
    letter-spacing: -0.45px;
    margin-bottom: 32px;
  }
`;

export const InfoButton = styled.div`
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  padding: 8px;
  background: #279ae7;
  color: #ffffff;
  width: 160px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #289eed;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;

  > h2 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
  }
  > span {
    text-align: center;
    max-width: 85vw;
  }
`;

export const NewBoardThumbnailEmpty = styled(NewBoardThumbnail)`
  height: 90px;
  width: 160px;
  max-width: 160px;
  margin-top: 24px;
  margin-bottom: 8px;

  &:hover {
    background-color: rgba(99, 193, 153, 0.4);
  }
`;

export const NewBoardEmpty = styled.div`
  font-size: 13px;
  font-weight: 600;
`;
