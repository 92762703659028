import ZCanvas from '@flatfrog/ffbec';

import * as actions from 'client/state/actions';
import { store } from 'client/store';
import * as selectors from 'client/state/selectors';
import { CLIPBOARD_MAGIC } from 'client/components/Common/types';
import { handleImagesPaste, handleInternalPaste, handleTextPaste } from 'client/state/actions';
import { getPaperActionsAndInkImages } from 'client/common/helpers/ZCanvasHelpers';

export const cutPapers = async (paperIds: number[]) => {
  if (paperIds.length > 0) {
    const actionsAndInkImages = getPaperActionsAndInkImages(paperIds);

    for (const paperId of paperIds) {
      ZCanvas.paper.deselect(paperId);
    }

    ZCanvas.paper.removeMultiple(paperIds);

    await navigator.clipboard.writeText(CLIPBOARD_MAGIC);

    await store.dispatch(actions.addToClipboard('cut', JSON.stringify(actionsAndInkImages)));
  } else {
    console.log('Nothing was cut');
  }
};

const watchCutEvents = () => {
  window.addEventListener('cut', async (event: ClipboardEvent) => {
    const clipboardingEnabled = selectors.getClipboardingEnabled(store.getState());

    if (clipboardingEnabled) {
      event.preventDefault();
      const paperIds = ZCanvas.paper.getSelectedIds();
      await cutPapers(paperIds);
    }
  });
};

export const copyPapers = async (paperIds: number[]) => {
  if (paperIds.length > 0) {
    const actionsAndInkImages = getPaperActionsAndInkImages(paperIds);

    await navigator.clipboard.writeText(CLIPBOARD_MAGIC);

    await store.dispatch(actions.addToClipboard('copy', JSON.stringify(actionsAndInkImages)));
  } else {
    console.log('Nothing was copied');
  }
};

const watchCopyEvents = () => {
  window.addEventListener('copy', async (event: ClipboardEvent) => {
    const clipboardingEnabled = selectors.getClipboardingEnabled(store.getState());

    if (clipboardingEnabled) {
      event.preventDefault();
      const paperIds = ZCanvas.paper.getSelectedIds();
      await copyPapers(paperIds);
    }
  });
};

const watchPasteEvents = () => {
  window.addEventListener('paste', async (event: ClipboardEvent) => {
    // When a paste event occurs, we take the clipboard content from the event unless we posted internally
    const state = store.getState();
    const clipboardingEnabled = selectors.getClipboardingEnabled(state);

    if (clipboardingEnabled) {
      event.preventDefault();
      event.stopPropagation();
      // First check if there is a plain text indicating that we should use the internal clipboard
      const data = event.clipboardData;
      const plainText = data.getData('text/plain');

      if (plainText === CLIPBOARD_MAGIC) {
        const clipboard = state.clipboard;

        if (clipboard) {
          store.dispatch(handleInternalPaste(clipboard));
        }
      } else if (data.types.includes('Files')) {
        store.dispatch(handleImagesPaste(data));
      } else {
        store.dispatch(handleTextPaste({ htmlText: data.getData('text/html'), plainText }));
      }
    }
  });
};

const watchPermissionStatus = async () => {
  const handlePermission = (state: PermissionState) => store.dispatch(actions.enablePasteTool(state !== 'denied'));

  try {
    const permissionStatus = await navigator.permissions.query({
      name: 'clipboard-read' as PermissionName,
    });
    handlePermission(permissionStatus.state);
    permissionStatus.addEventListener('change', () => handlePermission(permissionStatus.state));
  } catch {
    store?.dispatch(actions.enablePasteTool(false));
  }
};

watchCutEvents();
watchCopyEvents();
watchPasteEvents();
watchPermissionStatus();
