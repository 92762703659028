import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components';

import * as analytics from 'client/common/analytics';
import Dialog from 'client/components/Common/dialog';
import { useAuth } from 'client/hooks/useAuth';

const Title = styled.div`
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 520px;
  height: 240px;
  margin-bottom: 24px;
`;

export const RoleThumbnail = styled.div`
  width: 100%;
  background-color: #fff;
  width: 90px;
  cursor: pointer;
  transition: box-shadow 300ms ease-out;
  background-size: cover;

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }

  &:hover {
    box-shadow: 0 0 0 4px #00000014, 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  }

  &.selected {
    box-shadow: 0 0 0 4px #00000014, 0 2px 4px #00000014, 0 0 0 0.5px #00000014;
  }
`;

const RoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  margin-top: 24px;
`;

const RoleText = styled.div`
  margin-top: 8px;
  color: #5d5d5d;
  font-size: 12px;
  width: 90px;
  text-align: center;

  &.selected {
    font-weight: 600;
  }
`;

const roles = [
  { text: 'Brainstorming and Ideation', icn: 'img_brainstorming.png' },
  { text: 'Engineering', icn: 'img_engineering.png' },
  { text: 'Research and Design', icn: 'img_researchdesign.png' },
  { text: 'Marketing and Sales', icn: 'img_marketingsales.png' },
  { text: 'Product Planning', icn: 'img_productplanning.png' },
  { text: 'Project Management', icn: 'img_projmgmt.png' },
  { text: 'Strategic Planning and Workshops', icn: 'img_strategyplanning.png' },
  { text: 'Other', icn: 'img_other.png' },
];

interface Props {
  onClose: (userrole: string) => void;
}

const UserRoleSelection: React.FC<Props> = ({ onClose }) => {
  const [selected, setSelected] = useState(-1);

  const auth = useAuth();

  useHotkeys('esc', () => {
    onClose('No answer');
  });

  const handleNext = async () => {
    analytics.selectUserRole(roles[selected].text);
    onClose(roles[selected].text);
  };

  const handleClose = async () => {
    onClose('No answer');
  };

  const roleObjects = roles.map((r, i) => {
    const isSelected = i === selected;
    return (
      <RoleContainer key={r.text} onClick={() => setSelected(i)}>
        <RoleThumbnail className={isSelected ? 'selected' : ''} style={{ backgroundImage: `url(/icons/${r.icn})` }} />
        <RoleText className={isSelected ? 'selected' : ''}>{r.text}</RoleText>
      </RoleContainer>
    );
  });

  if (!auth.user) {
    return null;
  }

  return (
    <Dialog
      nextText="Submit"
      cancelText="Skip"
      nextDisabled={selected < 0}
      onCancel={handleClose}
      onNext={handleNext}
      cover
    >
      <Title>
        {`Hi, ${auth.user.displayName}`}.<br />
        What kind of work do you do?
      </Title>
      <Container>{roleObjects}</Container>
    </Dialog>
  );
};

export default UserRoleSelection;
