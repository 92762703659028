import { useEffect, useState } from 'react';
import { isElectron } from 'react-device-detect';

import { useActions } from 'client/hooks/useActions';

export const useAppVersion = () => {
  const [appVersion, setAppVersion] = useState<string | null>(null);
  const [appNextVersion, setAppNextVersion] = useState<string | null>(null);

  const { setVersion: setVersionState } = useActions();

  useEffect(() => {
    if (isElectron && window.electronApi) {
      window.electronApi?.receive('appversion', (data) => {
        setAppVersion(data);
      });
      window.electronApi?.receive('appnextversion', (data) => {
        setAppNextVersion(data);
      });
      window.electronApi?.send('appversion');
    } else {
      const version: string = require('../../../package.json').version;
      setAppVersion(version);
    }
  }, []);

  useEffect(() => {
    console.log(appVersion);
    setVersionState(appVersion);
  }, [appVersion]);

  return { appVersion, appNextVersion };
};
