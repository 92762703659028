import styled from 'styled-components';

import { StickyNoteWrapper } from 'client/components/StickyNote/styled';

import { MobileAddButton, MobileButton, MobileControls } from './styled';

export const TopArea = styled.div`
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #242424;
  z-index: 222;
  color: #fff;
  display: flex;
  flex-direction: column;

  &.edit {
    color: #242424;
  }
`;

export const BottomBar = styled(MobileControls)`
  background: transparent;
  position: relative;
  flex-shrink: 0;
`;

export const SecondaryButton = styled(MobileButton)`
  color: #fff;
`;

export const PrimaryButton = styled(MobileAddButton)`
  margin: 0 auto;
  border: none;
  color: #fff;
  background: #279ae7;

  &.edit {
    background: #00000014;
    border: none;
    color: #000;
  }
`;

export const TextArea = styled.textarea`
  flex: 1;
  border: none;
  padding: 32px;
  color: #242424;
  font: normal normal normal 23px/22px Open Sans;
  background: transparent;
`;

export const SettingsButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin: 0 8px;
  background: #373737 center center no-repeat;
  position: relative;
`;

export const TextStyleSettingsButton = styled(SettingsButton)`
  background-image: url(/icons/icn_text_style.svg);
`;

export const SignatureToggle = styled(SettingsButton)`
  background-image: url(/icons/icn_signature.svg);
  border: 3px solid #373737;

  &.active {
    border: 3px solid #279ae7;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 50%;
      border: 2px solid #242424;
    }
  }
`;

export const ColorSettingsButton = styled(SettingsButton)`
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 50%;
    border: 2px solid #242424;
  }
`;

export const TextSettingsButton = styled(SettingsButton)`
  border-radius: 6px;
  background-color: transparent;

  &.active {
    background-color: rgba(255, 255, 255, 0.16) !important;
  }

  margin: 8px 0;
`;

export const AlignButton = styled(TextSettingsButton)`
  &.left {
    background: url(/icons/icn_text_align_left_white.svg) center center no-repeat;
  }

  &.center {
    background: url(/icons/icn_text_align_center_white.svg) center center no-repeat;
  }

  &.right {
    background: url(/icons/icn_text_align_right_white.svg) center center no-repeat;
  }

  &.smaller {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 1px;
    background: rgba(255, 255, 255, 0.16) url(/icons/icn_textsize_smaller_white.svg) center center no-repeat;
  }

  &.bigger {
    margin-left: 16px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: rgba(255, 255, 255, 0.16) url(/icons/icn_textsize_bigger_white.svg) center center no-repeat;
  }
`;

export const SettingButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const SettingsArea = styled.div`
  flex: 1;
  padding: 16px;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-direction: column;

  ${StickyNoteWrapper} {
    height: auto;
    width: 100%;
  }

  @media (orientation: landscape) {
    ${StickyNoteWrapper} {
      // compensate for top bar, bottom bar, settings and paddings
      height: calc(100vmin - 64px - 74px - 96px);
      width: auto;
    }
  }
`;

export const Palette = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #363636;
  border: 0.5px solid #00000029;
  border-radius: 16px 16px 0 0;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${ColorSettingsButton} {
    margin: 8px;
  }
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
