import React, { useState } from 'react';
import styled from 'styled-components';

import { FloatingPanel } from './common-styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  padding: 11px 16px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  background: #63c199;

  border: 0px solid #47a37c;
  border-radius: 5px;

  &.multiple {
    border-radius: 4px 0 0 4px;
  }

  &.gray {
    background: #0000001a;
    border: none;
    color: #242424;
    &:hover {
      background: #00000029;
    }
  }

  &:disabled {
    opacity: 0.5;
    color: #242424;
    background: none;
    border: 1px solid transparent;
    box-shadow: none;
    cursor: default;
    &:hover {
      background: none;
    }
  }

  &:hover {
    background: #47a37c;
    -webkit-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
  }
`;

const Menu = styled.div`
  display: flex;
  padding: 8px 0;
  ${FloatingPanel}
  border: 1px solid #00000029;
`;

const MenuPositioner = styled.div`
  position: absolute;
  right: -1px;
  display: none;
  padding-top: 16px;
  cursor: initial;

  &.up {
    bottom: calc(100% + 8px);
    ${Menu} {
      flex-direction: column-reverse;
    }
  }

  &.down {
    top: calc(100% - 8px);
    ${Menu} {
      flex-direction: column;
    }
  }
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 10px 16px 10px 24px;
  background: #fff;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;

  &:hover {
    background: #f4f4f4;
  }
`;

const DropDown = styled.button.attrs({
  type: 'button',
})`
  position: relative;
  outline: none;
  padding: 20px 20px;
  cursor: pointer;
  background: #63c199 url(/icons/icn_dropdown_arrow_white.svg) center center no-repeat;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border: solid #47a37c;
  border-width: 1px 1px 1px 0;
  border-radius: 0 4px 4px 0;

  &.open {
    ${MenuPositioner} {
      display: block;
    }
    background-color: #47a37c;
  }

  &.gray {
    background: #0000001a url(/icons/icn_dropdown_arrow_gray.svg) center center no-repeat;
    border: solid #bcbcbc;
    border-width: 0px 0px 0px 1px;
    box-shadow: none;
    &:hover {
      background: #00000029 url(/icons/icn_dropdown_arrow_gray.svg) center center no-repeat;
    }
  }

  &:disabled {
    opacity: 0.5;
    color: #242424;
    background: transparent url(/icons/icn_dropdown_arrow_gray.svg) center center no-repeat;
    border: solid transparent;
    box-shadow: none;
    cursor: default;
    &:hover {
      background: none;
    }
  }

  &:hover {
    background-color: #47a37c;
    -webkit-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
  }
`;

interface Props {
  onDropDown?: () => void;
  disabled?: boolean;
  actions: { label: string; action: () => void }[];
  style: React.CSSProperties;
  gray: boolean;
  open: boolean;
  direction?: 'up' | 'down';
}

const ComboButton: React.FC<Props> = ({ actions, direction, disabled, gray, onDropDown, style }) => {
  // The parent can override the drop down menu state with property "open".
  const [open, setOpen] = useState(false);
  let dropDownAction = null;
  if (onDropDown) {
    dropDownAction = disabled ? () => {} : onDropDown;
  } else {
    dropDownAction = () => !disabled && setOpen(!open);
  }

  const menuItems = actions.map((action, index) => (
    <MenuItem key={`action-${index}`} onClick={action.action}>
      {action.label}
    </MenuItem>
  ));

  return (
    <Wrapper style={style}>
      <Button
        className={(actions.length > 1 ? 'multiple' : '') + (disabled ? ' disabled' : '') + (gray ? ' gray' : '')}
        onClick={actions[0].action}
      >
        {actions[0].label}
      </Button>
      {actions.length > 1 ? (
        <DropDown
          className={(open ? 'open' : '') + (disabled ? ' disabled' : '') + (gray ? ' gray' : '')}
          onClick={dropDownAction}
        >
          <MenuPositioner className={direction === 'down' ? direction : 'up'}>
            <Menu>{menuItems}</Menu>
          </MenuPositioner>
        </DropDown>
      ) : null}
    </Wrapper>
  );
};

export default ComboButton;
