import { PayloadAction } from '@reduxjs/toolkit';
import { BaseActionCreator } from '@reduxjs/toolkit/src/createAction';
import { useEffect } from 'react';
import { useStore } from 'react-redux';

import { RootState } from 'client/store';

/**
 * Subscribes to redux store events
 */
export default function useReduxAction<T>(
  effect: (action: PayloadAction<T>) => void,
  actionCreator: BaseActionCreator<T, string>,
  deps: unknown[] = []
) {
  const store = useStore<RootState>();

  const handleChange = () => {
    const state = store.getState();
    const action = state.ActionReducer;

    if (action.type === actionCreator.type) {
      effect(action);
    }
  };

  useEffect(() => {
    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
  }, deps);
}
