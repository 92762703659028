export const IMAGE = 'image';
export const STICKER = 'sticker';

export interface CsvOption {
  desc: string;
  key: string;
  hidden?: boolean;
}

export const csvOptions: CsvOption[] = [
  { desc: 'Color Code (hex)', key: 'color' },
  { desc: 'Locked', key: 'locked' },
  { desc: 'Coordinates', key: 'coords' },
  { desc: 'X', key: 'x', hidden: true },
  { desc: 'Y', key: 'y', hidden: true },
  { desc: 'Id', key: 'id' },
  { desc: 'Page Id', key: 'page' },
  { desc: 'Parent Id', key: 'parent' },
  { desc: 'Group Id', key: 'group' },
  { desc: 'Link', key: 'link' },
];

export const packStickyNoteMetadata = (props: {
  content?: string;
  color?: string;
  textColor?: string;
  signature?: string;
  textSize?: number;
  textAlign?: string;
  link?: string;
}) => {
  const pascalSet = {
    Content: props.content,
    Color: props.color,
    TextColor: props.textColor,
    SignatureText: props.signature,
    TextSize: props.textSize,
    TextAlign: props.textAlign,
    Link: props.link,
  };
  return JSON.stringify(pascalSet);
};

export const packTextBlockMetadata = (props: {
  content: string;
  color: string;
  textColor: string;
  outlineColor?: string;
  signature?: string;
  textSize: number;
  textAlign: string;
  link?: string;
}) => {
  const pascalSet = {
    Content: props.content,
    Color: props.color,
    TextColor: props.textColor,
    OutlineColor: props.outlineColor,
    TextSize: props.textSize,
    TextAlign: props.textAlign,
    Link: props.link,
  };
  return JSON.stringify(pascalSet);
};

export const CLIPBOARD_MAGIC = 'application/ffb';
