import styled from 'styled-components';

import { BaseModalContainer } from 'client/components/Common/modal-styled';

export const Editor = styled(BaseModalContainer)`
  flex-direction: column;
  width: auto;
  padding: 0;
  justify-content: normal;
  align-items: normal;
  text-align: left;
  z-index: 5;
  overflow: visible;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 16px 32px 0 rgba(0, 0, 0, 0.16);

  @keyframes tilt {
    from,
    to {
      transform: translate(-50%, -50%);
      transform-origin: center center;
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -50%) rotate(-10deg);
      transform-origin: bottom left;
      opacity: 0;
    }
    60% {
      transform: translate(-50%, -50%) rotate(0deg) scale(0.8);
      transform-origin: bottom center;
      opacity: 0;
    }
  }

  &.tilt {
    animation: tilt 0.5s ease-out;
  }

  @keyframes zoom {
    from {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    to {
      transform: translate(-50%, -50%) scale(0.2);
      opacity: 0;
    }
  }

  &.zoom {
    animation: zoom 0.25s ease-out;
    top: 50%;
  }
`;

export const Header = styled.div`
  background: #fff;
  height: 48px;
  border-bottom: 1px solid rgba(88, 88, 88, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  user-select: none;
`;

export const Container = styled.div`
  width: 448px;
  height: 516px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: normal;
  align-items: center;
  text-align: left;
  z-index: 5;
  background: #f4f4f4;
  visibility: hidden;
  border-radius: 6px;

  &.active {
    visibility: visible;
  }
`;

export const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

export const TextSizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-right: 8px;
`;

export const TextSizeButton = styled.button`
  border: 1px solid #24242426;
  width: 32px;
  height: 32px;
  padding: 0;
  position: relative;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
  background-color: #ffffff;
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:disabled {
    opacity: 0.5;
  }

  &.smaller {
    background-image: url(/icons/icn_textsize_smaller.svg);
    margin-left: 8px;
  }

  &.bigger {
    background-image: url(/icons/icn_textsize_bigger.svg);
  }
`;

export const StyleList = styled.ul`
  list-style: none;
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
`;

export const StyleButton = styled.div.attrs<{ active: boolean }>((props) => ({
  className: props.active ? 'active textColor' : 'textColor',
}))<{
  active: boolean;
  color: string;
}>`
  padding: 0;
  display: inline-block;
  margin-left: 8px;
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  box-shadow: 0 0 0 3px #00000000, inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  transition: box-shadow 0.3s ease-in-out;

  &:hover,
  &.active {
    box-shadow: 0 0 0 3px #00000029, inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
`;

export const TextArea = styled.textarea<{ placeholderColor: string }>`
  overflow: hidden;
  resize: none;
  width: 300px;
  height: 300px;
  padding: 30px 20px;
  outline: none;
  box-shadow: 0 3px 6px #00000029, inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  border: none;
  font-family: Caveat;
  line-height: 1;
  margin-bottom: 24px;

  &::placeholder {
    color: ${(props) => props.placeholderColor};
  }
`;

export const LinkContainer = styled.div`
  height: 32px;
  margin-bottom: 24px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: horizontal;
  border: 2px solid #00000029;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid #279ae7;
  }
`;

export const LinkTextField = styled.input.attrs({
  type: 'text',
})`
  height: 100%;
  min-height: 100%;
  resize: none;
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  border: none;
  background-color: transparent;
`;

export const EditLinkIcon = styled.div`
  width: 28px;
  height: 28px;
  margin: 0 6px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/icn_link_inactive.svg);
`;

export const SignatureWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: right;
  bottom: calc(24px + 32px + 24px + 12px);
  right: calc((448px - 300px) / 2 + 17px);
`;

export const Signature = styled.div`
  font-family: Caveat;
  font-size: 30px;
  user-select: none;
`;

export const BottomMenu = styled.div`
  bottom: 24px;
  right: 24px;
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const DeleteButton = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  padding: 20px 28px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.08) url(/icons/icn_paper_trashcan.svg) center center no-repeat;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.16);
  }
`;
