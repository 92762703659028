import React, { MutableRefObject } from 'react';
import slugid from 'slugid';

import { getFirebaseAuth } from 'client/common/firebase';
import { IMAGE } from 'client/components/Common/types';
import { PaperOptions } from 'client/state/papers/actions';

const maxRenderedWidth = 3840 * 2;
const maxRenderedHeight = 3840 * 2;

export const pdfDocumentToImages = (
  file: File,
  addPaperToWhiteboardComponent: (payload: { file: ArrayBuffer; options: PaperOptions }) => void,
  progressCallback: (progress: number, capped?: boolean) => void,
  cancelledRef: MutableRefObject<boolean>,
  margins: { top: number; right: number; bottom: number; left: number }
) =>
  new Promise<number>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = async function () {
      const typedarray = new Uint8Array(this.result as ArrayBuffer);

      const { pdfjs } = await import('react-pdf/dist/esm/entry.webpack');

      const loadingTask = pdfjs.getDocument(typedarray);
      const pdfDocument = await loadingTask.promise;

      let pages = pdfDocument.numPages;
      if (pages > 10) {
        pages = 10;
        if (progressCallback) {
          progressCallback(undefined, true);
        }
      }

      const margin = 20;
      const usableSize = {
        width: margins.right - margins.left,
        height: margins.bottom - margins.top,
      };
      const imagesPerRow = usableSize.width > usableSize.height ? 3 : 2; // Could be improved
      const noRows = Math.floor((pages - 0.5) / imagesPerRow) + 1;
      const maxWidth =
        (usableSize.width - (Math.min(imagesPerRow, pages) - 1) * margin) / Math.min(imagesPerRow, pages);
      const maxHeight = (usableSize.height - (noRows - 1) * margin) / noRows;

      for (let pageIndex = pages; pageIndex > 0; pageIndex--) {
        if (cancelledRef?.current) {
          return resolve(0);
        }
        const pdfPage = await pdfDocument.getPage(pageIndex);
        let scale;
        if (pdfPage.view[2] > pdfPage.view[3]) {
          scale = maxRenderedWidth / pdfPage.view[2];
        } else {
          scale = maxRenderedHeight / pdfPage.view[3];
        }
        const viewport = pdfPage.getViewport({ scale });
        if (viewport.width > maxRenderedWidth) {
          viewport.width = maxRenderedWidth;
        }
        if (viewport.height > maxRenderedHeight) {
          viewport.height = maxRenderedHeight;
        }
        const offscreen = new OffscreenCanvas(viewport.width, viewport.height);
        const ctx = offscreen.getContext('2d');
        const renderTask = pdfPage.render({
          canvasContext: ctx,
          viewport,
        });
        await renderTask.promise;
        const blob = await offscreen.convertToBlob({ type: 'image/jpeg', quality: 0.7 });
        const ab = await blob.arrayBuffer();
        if (cancelledRef?.current) {
          return resolve(0);
        }

        addPaperToWhiteboardComponent({
          file: ab,
          options: {
            type: IMAGE,
            position: {
              x: margins.left + ((pageIndex - 1) % imagesPerRow) * (maxWidth + margin) + 0.5 * maxWidth,
              y:
                2160.0 -
                (margins.top + Math.floor((pageIndex - 1) / imagesPerRow) * (maxHeight + margin) + 0.5 * maxHeight),
            },
            coordsInWritingArea: true,
            maxSize: { width: maxWidth, height: maxHeight },
            selectPaper: true,
            file: { size: blob.size, name: file.name + pageIndex, type: 'image/jpeg' },
          },
        });
        if (progressCallback) {
          progressCallback(((pages - pageIndex) / pages) * 100);
        }
      }
      resolve(pdfDocument.numPages);
    };
    fileReader.readAsArrayBuffer(file);
  });

export const officeFileToImages = async (
  file: File,
  addPaperToWhiteboardComponent: (payload: { file: ArrayBuffer; options: PaperOptions }) => void,
  progressCallback: (progress: number, capped?: boolean) => void,
  cancelledRef: React.MutableRefObject<boolean>,
  margins: { top: number; right: number; bottom: number; left: number }
) => {
  if (file.size > 30000000) {
    throw new Error('File too big');
  }

  const user = getFirebaseAuth().currentUser;
  const token = await user?.getIdToken();

  if (!token) {
    throw new Error('Unauthenticated');
  }

  const data = new FormData();
  data.append('file', file);
  let serverUrl = `/convert/${slugid.nice()}`;
  // electron
  if (window.location.host === '-') {
    serverUrl = window.INITIAL_STATE.appServerUrl + serverUrl;
  }
  const resp = await fetch(serverUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });

  if (cancelledRef?.current) {
    return;
  }

  if (resp.ok) {
    const blob = await resp.blob();
    progressCallback(50);

    return pdfDocumentToImages(
      blob as File,
      addPaperToWhiteboardComponent,
      (p, c) => progressCallback(50 + p, c),
      cancelledRef,
      margins
    );
  }

  throw new Error('Unable to convert');
};
