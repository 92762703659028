import { isMobile, isIPad13 } from 'react-device-detect';
import styled from 'styled-components';

import { BaseModalContainer } from './modal-styled';

export const ModalContainer = styled(BaseModalContainer)`
  opacity: 1;
  pointer-events: visible;
  top: 50%;
  padding: 40px;
  max-width: 96%;
  min-width: ${isMobile ? 'auto' : '450px'};

  &.cover {
    min-width: ${isMobile && !isIPad13 ? '96%' : 'auto'};
  }

  &.show-overflow {
    overflow: visible;
    padding-bottom: 128px;
  }
`;

export const ModalBackDrop = styled.div`
  position: fixed;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.25);
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
  button {
    margin: ${isMobile ? '0.5em' : '0'} 0.5em;
  }

  &.right-align {
    align-self: flex-end;
  }
`;

export const Button = styled.button.attrs<{ type?: string }>((p) => ({
  type: p.type || 'button',
}))`
  outline: none;
  background: #eaeaea;
  color: #242424;
  min-width: 120px;
  padding: 13px 21px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #d6d6d6;
  }
  &:disabled {
    opacity: 0.8;
    filter: grayscale();
    cursor: default;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: #279ae7;
  color: #fff;
  &:hover {
    background-color: #2287ca;
  }
`;

export const NextButton = styled.button.attrs<{ type?: string }>((p) => ({
  type: p.type || 'button',
}))`
  outline: none;
  min-width: 100px;
  background: #279ae7;
  color: #fff;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #3795d4;
  }
  &:disabled {
    background: #c8c8c8;
    box-shadow: none;
    text-shadow: -1px -1px 0 #bebebe;
    color: #f0f0f0;
    cursor: default;
  }
`;

export const CancelButton = styled(NextButton)`
  background-color: #0000001a;
  color: #242424;

  &:hover {
    background-color: #00000029;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  text-transform: capitalize;
  margin: 0;
  display: flex;
  align-items: center;
  color: #242424;
`;

export const Body = styled.div`
  margin-top: 8px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #242424;
  font-weight: 500;
`;

export const TopLeftLogo = styled.img.attrs({
  src: '/images/logo_flatfrogboard.svg',
})`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 80px;
`;

export const FileNameField = styled.input.attrs({
  type: 'text',
  autoFocus: true,
})`
  display: block;
  text-align: left;
  font-size: 16px;
  color: #242424;
  height: 48px;
  padding: 8px;
  background: #fff;
  border: 2px solid #279ae7;
  border-radius: 6px;
  width: 100%;
  margin: 0;
`;

export const FileNameLabel = styled.label`
  font: normal normal 600 13px/18px 'Open Sans';
  color: #8c8c8c;
  margin: 32px 0 4px 0;
  width: 100%;
  display: block;
  text-align: left;
`;

const FileAttribute = styled.div`
  width: 220px;
  margin-top: 8px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-start;
  font-size: 13px;

  div:last-child {
    width: 130px;
  }
`;

export const AutoSaveField = styled(FileAttribute)<{ blobColor: string }>`
  margin-top: 16px;
  div:last-child {
    &:before {
      display: inline-block;
      background: ${(props) => props.blobColor};
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      content: '';
    }
  }
`;
export const OwnerField = styled(FileAttribute)``;
export const CreatedField = styled(FileAttribute)``;
