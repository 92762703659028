import React, { useState, useRef } from 'react';

type Hint = '' | 'invalid character' | 'valid';

export default function useCodeInput(): {
  code: string;
  codeInput: React.MutableRefObject<HTMLInputElement>;
  handleCodeChange: () => void;
  hint: Hint;
} {
  const [code, setCode] = useState('');
  const [hint, setHint] = useState<Hint>('');
  const codeInput = useRef<HTMLInputElement>();

  const handleCodeChange = () => {
    const input = codeInput.current;
    let position = input.selectionEnd;
    const { length } = input.value;

    const invalidCharacter = !/^[\d ]*$/.test(input.value);

    const value = input.value
      .replace(/\D/g, '')
      .replace(/(.{3})/g, '$1 ')
      .trim();

    if (value.charAt(position - 1) === ' ' && value.charAt(length - 1) === ' ' && length !== value.length) {
      position++;
    }

    input.value = value;
    input.selectionEnd = position;

    const trimmedValue = value.replace(/\s+/, '');

    setCode(trimmedValue);

    if (trimmedValue.length === 6) {
      setHint('valid');
    } else if (invalidCharacter) {
      setHint('invalid character');
    } else {
      setHint('');
    }
  };

  return { code, codeInput, handleCodeChange, hint };
}

export const noMatchingRoomErrorMessage = 'No room with this code was found';
export const invalidCharacterMessage = 'The code can only contain numbers';
