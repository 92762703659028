import React from 'react';

import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';

import { WarningIllustration } from './styled';

interface Props {
  hide: () => void;
  onAccept: () => void;
  onReject: () => void;
}

export const AssignBeforeSaveDialog: React.FC<Props> = ({ hide, onAccept, onReject }) => (
  <Dialog noButtons={true} noCloseButton={true} cover>
    <WarningIllustration />
    <DialogStyled.Title>You have unsaved work</DialogStyled.Title>
    <DialogStyled.Body>
      Do you want to save by assigning your work to
      <br />a person before closing?
    </DialogStyled.Body>
    <DialogStyled.NextButton
      style={{ marginTop: '24px', width: '100%' }}
      onClick={() => {
        onAccept();
        hide();
      }}
    >
      Save
    </DialogStyled.NextButton>
    <DialogStyled.CancelButton
      style={{ marginTop: '8px', width: '100%' }}
      onClick={() => {
        onReject();
        hide();
      }}
    >
      Don't Save
    </DialogStyled.CancelButton>
    <DialogStyled.CancelButton style={{ marginTop: '16px', width: '100%' }} onClick={hide}>
      Cancel
    </DialogStyled.CancelButton>
  </Dialog>
);
