import styled from 'styled-components';

import * as DialogStyled from 'client/components/Common/dialog-styled';

export const PinWrapper = styled.div`
  font-size: 29px;
  display: flex;
  align-items: center;
  height: 59px;
  line-height: 56px;
  margin: 16px;
  background-color: white;
  color: #242424;
  border-radius: 7px;
  padding: 8px 16px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16), 0 0 0 0.5px rgba(0, 0, 0, 0.08);
`;

export const QRCodeWrapper = styled.div`
  display: flex;
  object-fit: contain;
  background-color: white;
  width: 150px;
`;

export const Columns = styled.div`
  display: flex;
  margin-top: 26px;
`;

export const Column = styled.div`
  background: #f5f6f7;
  flex: 1;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 293px;
`;

export const Spacer = styled.div`
  padding: 25px;
  font-weight: 600;
`;

export const ColumnTop = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
`;

export const Title = styled(DialogStyled.Title)`
  text-transform: none;
  font-weight: 600;
`;

export const ColumnTitle = styled(DialogStyled.Title)`
  margin-top: 20px;
`;

export const Text = styled(DialogStyled.Body)`
  font-size: 15px;
`;

export const ColumnText = styled.div`
  margin-top: 8px;
  font-size: 15px;
`;
