import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as Styled from 'client/common/codeDialog';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import useCodeInput, { invalidCharacterMessage, noMatchingRoomErrorMessage } from 'client/hooks/useCodeInput';

const Dialog = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  background: #fff;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  color: #242424;

  ${Styled.Wrapper} {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  ${Styled.Title} {
    height: 64px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    font: normal normal normal 15px/36px Open Sans;
    letter-spacing: -0.37px;
    color: #242424;
  }

  ${Styled.InfoText} {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #242424;
    flex-grow: 1;
  }

  ${Styled.CodeWrapper} {
    display: flex;
    justify-content: center;
    margin: 40px auto;
  }

  ${Styled.OpenButton} {
    width: auto;
    margin: 0 24px 12px 24px;

    &:first-of-type {
      margin-top: auto;
    }
  }
`;

const CancelButton = styled(Styled.OpenButton)`
  margin-bottom: 24px;
  background: #fff !important;
  color: #242424 !important;
`;

interface Props {
  onSubmit: (code: string) => void;
  onCancel: () => void;
}

const MobileJoinDialog: React.FC<Props> = ({ onSubmit, onCancel }) => {
  const {
    session: { failure },
  } = useSelector((state) => ({
    session: state.session,
  }));

  const { disableClipBoarding } = useActions();

  const { code, codeInput: inputRef, handleCodeChange, hint } = useCodeInput();
  const [error, setError] = useState('');

  useEffect(() => {
    disableClipBoarding('showJoinDialog', true);
    inputRef.current?.focus();

    return () => {
      disableClipBoarding('showJoinDialog', false);
    };
  }, []);

  useEffect(() => {
    setError(hint === 'invalid character' ? invalidCharacterMessage : '');
  }, [hint]);

  useEffect(() => {
    if (failure) {
      setError(noMatchingRoomErrorMessage);
    }
  }, [failure]);

  useEffect(() => {
    if (code.length === 6) {
      onSubmit(code);
    }
  }, [code]);

  const getCodeInput = () =>
    Array.from({ ...code.split(''), length: 6 }).map((value, index) => (
      <Styled.CodeCharInput key={index} className={`${value && 'has-char'} ${code.length === index && 'active'}`}>
        {value?.toUpperCase()}
      </Styled.CodeCharInput>
    ));

  return (
    <Dialog>
      <Styled.Wrapper onClick={() => inputRef.current?.focus()}>
        <Styled.Title>Join Room</Styled.Title>
        <Styled.InfoText>
          Enter the room code to <br /> join the Room
        </Styled.InfoText>
        <Styled.CodeWrapper>{getCodeInput()}</Styled.CodeWrapper>
        <input maxLength={7} inputMode="numeric" type="text" ref={inputRef} autoFocus onChange={handleCodeChange} />
        {!!error && <Styled.Error>{error}</Styled.Error>}
      </Styled.Wrapper>
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
    </Dialog>
  );
};

export default MobileJoinDialog;
