import { createAsyncThunk } from '@reduxjs/toolkit';
import ZCanvas, { CreatedImage } from '@flatfrog/ffbec';
import { v4 as uuidv4 } from 'uuid';
import { toByteArray } from 'base64-js';

import { findBackgroundColor } from 'client/components/StickyNote/colors';
import { getPageActionsAndInkImages } from 'client/common/helpers/ZCanvasHelpers';

export const duplicatePage = createAsyncThunk('duplicatePage', async (pageId: number) => {
  const actionsAndInkImages = getPageActionsAndInkImages(pageId);

  if (actionsAndInkImages.actions.length === 0) {
    console.assert('No actions returned from getPageActionsAndInkImages! This should not happen');
  }

  const loadedInkImages: Record<string, CreatedImage> = {};

  for (const imageName of Object.keys(actionsAndInkImages.inkImages)) {
    const imageData = toByteArray(actionsAndInkImages.inkImages[imageName].Data);
    loadedInkImages[imageName] = await ZCanvas.image.loadAsync({
      data: imageData,
      imageName,
      flags: 2,
    });
  }

  console.assert(Object.keys(actionsAndInkImages.inkImages).length === Object.keys(loadedInkImages).length);

  if (ZCanvas.page.getCurrentId() !== pageId) {
    ZCanvas.page.setCurrentId(pageId);
    await ZCanvas.afterUpdate();
  }

  ZCanvas.beginCommandGroup();

  const newPageId = ZCanvas.page.create();

  await ZCanvas.afterUpdate();
  await ZCanvas.afterUpdate();

  const oldPaperUuidsToNewPaperIds: Record<string, number> = {};
  const oldPaperUuidsToNewPaperUuids: Record<string, string> = {};

  const createPageAction = actionsAndInkImages.actions.find((a) => a.Action === 'CreatePageAction');
  const oldPageUuid = createPageAction.PageInfo.PageId;
  const newPageUuid = ZCanvas.page.getUuidFromId(newPageId);

  if (!newPageUuid) {
    console.error('New page UUID is not set yet!');
  }

  actionsAndInkImages.actions
    .filter((a) => a.Action === 'CreatePaperAction')
    .forEach((a) => {
      // Update initial offset
      const initialPosition = {
        x: a.PaperInfo.InitialPosition[0],
        y: 2160.0 - a.PaperInfo.InitialPosition[1],
      };
      const targetPosition = initialPosition;

      let paperId = null;
      switch (a.PaperInfo.PaperVariety) {
        case 'StickyNote': {
          const { FFZinkEnumMapping } = findBackgroundColor(a.PaperInfo.PaperColor);
          if (Object.keys(loadedInkImages).includes(a.PaperInfo.PaperForegroundFileName)) {
            const createdImage = loadedInkImages[a.PaperInfo.PaperForegroundFileName];
            paperId = ZCanvas.paper.createStickyNote(
              initialPosition.x,
              initialPosition.y,
              targetPosition.x,
              targetPosition.y,
              a.PaperInfo.Size[0],
              a.PaperInfo.Size[1],
              FFZinkEnumMapping,
              createdImage,
              a.PaperInfo.PaperMetadata,
              a.PaperInfo.InitialRotation,
              a.PaperInfo.InitialScale,
              false,
              true
            );
          } else {
            paperId = ZCanvas.paper.createStickyNote(
              initialPosition.x,
              initialPosition.y,
              targetPosition.x,
              targetPosition.y,
              a.PaperInfo.Size[0],
              a.PaperInfo.Size[1],
              FFZinkEnumMapping,
              undefined,
              a.PaperInfo.PaperMetadata,
              a.PaperInfo.InitialRotation,
              a.PaperInfo.InitialScale,
              false,
              true
            );
          }
          break;
        }
        case 'Image':
          paperId = ZCanvas.paper.createImage(
            initialPosition.x,
            initialPosition.y,
            targetPosition.x,
            targetPosition.y,
            a.PaperInfo.Size[0],
            a.PaperInfo.Size[1],
            ZCanvas.image.getByName(a.PaperInfo.ImageParameters.ImageFileName),
            a.PaperInfo.PaperMetadata,
            a.PaperInfo.InitialRotation,
            a.PaperInfo.InitialScale,
            false,
            true
          );
          break;
        case 'Sticker':
          paperId = ZCanvas.paper.createSticker(
            initialPosition.x,
            initialPosition.y,
            targetPosition.x,
            targetPosition.y,
            a.PaperInfo.Size[0],
            a.PaperInfo.Size[1],
            ZCanvas.image.getByName(a.PaperInfo.ImageParameters.ImageFileName),
            a.PaperInfo.PaperMetadata,
            a.PaperInfo.InitialRotation,
            false,
            true
          );
          break;
        case 'TextBox':
          paperId = ZCanvas.paper.createTextBox(
            initialPosition.x,
            initialPosition.y,
            targetPosition.x,
            targetPosition.y,
            a.PaperInfo.Size[0],
            a.PaperInfo.Size[1],
            ZCanvas.image.getByName(a.PaperInfo.ImageParameters.ImageFileName),
            a.PaperInfo.PaperMetadata,
            a.PaperInfo.InitialRotation,
            a.PaperInfo.InitialScale,
            false,
            true
          );
          break;
        default:
          break;
      }
      if (a.PaperInfo.AddedInBackground) {
        ZCanvas.paper.setPaperLayer(paperId, 0);
      }
      oldPaperUuidsToNewPaperIds[a.PaperInfo.PaperId] = paperId;
    });

  // NOTE: We wait for two BEC thread updates here.
  // Why? Well, the outgoing CreatePageAction/CreatePaperAction takes two updates to bubble out to the zhistory and app itself.
  // This should be investigated.. could be due to the Node ThreadSafeFunction in bec_addon.cpp, for instance.
  // Meanwile, this works.
  await ZCanvas.afterUpdate();
  await ZCanvas.afterUpdate();

  for (const oldPaperUuid of Object.keys(oldPaperUuidsToNewPaperIds)) {
    oldPaperUuidsToNewPaperUuids[oldPaperUuid] = ZCanvas.paper.getUuid(oldPaperUuidsToNewPaperIds[oldPaperUuid]);
  }

  actionsAndInkImages.actions
    .filter((a) => a.Action !== 'CreatePaperAction' && a.Action !== 'CreatePageAction')
    .forEach((a) => {
      let json = JSON.stringify({ ...a, ActionId: uuidv4() });
      json = json.replaceAll(oldPageUuid as string, newPageUuid);
      for (const oldPaperUuid of Object.keys(oldPaperUuidsToNewPaperUuids)) {
        json = json.replaceAll(oldPaperUuid, oldPaperUuidsToNewPaperUuids[oldPaperUuid]);
      }
      ZCanvas.history.applyLocalJsonAction(json);
    });

  ZCanvas.endCommandGroup();
});
