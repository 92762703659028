import { useState } from 'react';

const animals = [
  'Bear',
  'Butterfly',
  'Crocodile',
  'Dog',
  'Dolphin',
  'Eagle',
  'Elephant',
  'Falcon',
  'Fox',
  'Hummingbird',
  'Jaguar',
  'Kangaroo',
  'Ladybug',
  'Leopard',
  'Lion',
  'Mustang',
  'Moose',
  'Owl',
  'Panda',
  'Panther',
  'Reindeer',
  'Seahorse',
  'Seal',
  'T-Rex',
  'Tiger',
  'Unicorn',
  'Wildcat',
  'Zebra',
];

const rndAnimal = () => animals[Math.floor(Math.random() * animals.length)];

export default function useAnimal(): [string, () => void] {
  const [animal, setAnimal] = useState(rndAnimal());
  const changeAnimal = () => {
    setAnimal(rndAnimal());
  };
  return [animal, changeAnimal];
}
