import React from 'react';

import { ContextMenu } from 'client/components/Common/ContextMenu';

import * as Styled from './styled';

interface FilterItem<T> {
  title: string;
  value: T;
}

interface Props<T> {
  items: FilterItem<T>[];
  selectedValue: T;
  onSelected: (value: T) => void;
  defaultTitle?: string;
}

const FilterBox = <T,>({
  items,
  selectedValue,
  onSelected,
  defaultTitle,
}: React.PropsWithChildren<Props<T>>): React.ReactElement => {
  const selectedTitle = items.find((i) => i.value === selectedValue)?.title ?? defaultTitle ?? 'No value selected';

  return (
    <ContextMenu
      items={[
        items.map((i) => ({
          title: i.title,
          selected: selectedValue === i.value,
          onClick: () => onSelected(i.value),
        })),
      ]}
    >
      <Styled.FilterButton>{selectedTitle}</Styled.FilterButton>
    </ContextMenu>
  );
};

export default FilterBox;
