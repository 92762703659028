import styled from 'styled-components';

import { Button } from 'client/components/MobileBar/styled';
import { MenuContainer } from 'client/components/Common/ContextMenu-styled';

export const RecentBoardTeamName = styled.div.attrs((p) => ({
  style: {
    background: `${p.color}22`,
    color: p.color,
  },
}))`
  height: 24px;
  border-radius: 3px;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-weight: 600;
  margin-left: auto;
`;

export const EmailTrimmer = styled.span`
  font-size: 10px;
  display: block;
  margin-left: auto;
  color: #999;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: white;
  position: absolute;

  ${MenuContainer} {
    bottom: 8px;
    top: auto;
    left: 8px;
    right: 8px;
    width: auto;
    position: fixed;
    z-index: 51;
  }
`;

export const MobileButton = styled(Button)`
  font: normal normal 600 13px/22px 'Open Sans';
  letter-spacing: 0;
  color: #242424;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }
`;

export const ItemsButton = styled(Button)`
  background: url(icons/icn_myitems.svg) center center no-repeat;
`;

export const MobileSearchButton = styled(MobileButton)`
  width: 112px;
  max-width: 50vw;
  background: #0000000b url(/icons/icn_search.svg) center center no-repeat;
  background-size: 16px;
  border-radius: 40px;

  &.select {
    background: rgba(0, 0, 0, 0.04);
    color: #242424;
    box-shadow: none;
    border: none;
  }
`;

export const MobileJoinButton = styled(MobileSearchButton)`
  background-image: none;
  background-color: #279ae729;
  color: #2287ca;
`;

export const MobileBar = styled.div`
  flex-shrink: 0;
  height: 74px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: 1px hotpink !important;
  outline-offset: -2px;

  > * {
    height: 56px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 64px;
  color: #242424;
  flex-shrink: 0;
`;

export const SearchResult = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 8px;
  padding: 8px 0;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 0 0.5px #00000014, 0 4px 8px #00000029;
  z-index: 1;
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 8px;

  height: 40px;
  margin: 8px 0;
  flex-shrink: 0;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Category = styled.div`
  margin: 4px;
  cursor: pointer;
  border-radius: 16px;
  background-color: #f5f6f7;
  font-size: 12px;
  font-weight: 600;
  padding: 7px 8px;
  color: #242424;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #ebeced;
  }

  &.is-current {
    background: #279ae7;
    color: #ffffff;

    &:hover {
      background-color: #279ae7;
    }
  }
`;

export const BoardList = styled.div`
  flex-grow: 4;
  overflow-y: scroll;
  color: #242424;

  a {
    color: #242424;
  }
`;

export const Recency = styled.div`
  font: normal normal normal 12px/17px Open Sans;
  color: #8c8c8c;
`;

export const BoardLabel = styled.div`
  font-size: 15px;
  font-weight: 400;
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BoardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-grow: 1;
  overflow: hidden;
  padding: 0 8px;
`;

export const BoardThumbnail = styled.div`
  width: 56px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #00000029;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 56px;
  max-width: 56px;
  border-radius: 3px;
`;

export const NewBoardThumbnail = styled(BoardThumbnail)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 300;
  background: rgba(99, 193, 153, 0.3) url(/icons/icn_blankboard.svg) center center no-repeat;
  border-color: rgba(99, 193, 153, 0.05);
`;

export const BoardItem = styled.li`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 64px;
  padding: 0 16px;
  align-items: center;
  background-color: transparent;
  transition: background-color 300ms ease-out;
  width: 100%;
  max-width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }

  &.disabled {
    > ${NewBoardThumbnail} {
      filter: grayscale(100%);
    }
  }

  > ${RecentBoardTeamName} {
    margin-right: 0;
    flex-shrink: 0;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;

  > img {
    margin-bottom: 2em;
  }
  > h2 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
  }
  > span {
    text-align: center;
    max-width: 85vw;
  }
`;
