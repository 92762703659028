import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import ZCanvas, { Action } from '@flatfrog/ffbec';
import * as log from 'loglevel';
import naClUtil from 'tweetnacl-util';

import { RootState } from 'client/store';
import * as analytics from 'client/common/analytics';
import {
  addNotification,
  connectFailure,
  resetSession,
  sendBoardEvent,
  ServerSession,
  sessionSocketDisconnection,
  setConnectedStatus,
  setLatestActionId,
  setLoadedFileInfo,
  setSession,
  toggleSessionEndedDialog,
  toggleShowReconnectDialog,
  toggleShowRenameDialog,
  undo,
} from 'client/state/actions';
import { getFirebaseAuth } from 'client/common/firebase';
import * as rest from 'client/services/rest';
import { CONNECTION_FAIL_REASON, CONNECTION_STATUS } from 'client/common/util';
import { removeCode } from 'client/common/urlHelper';

type LinkFile = { linkId: string; link: true };
type TemplateFile = { templateId: string; teamId: string; template: true };
type NewFile = { teamId?: string; new: true };

type FileInfo = { id: string } | TemplateFile | LinkFile | NewFile;

const isTemplateFile = (f: FileInfo): f is TemplateFile => (f as TemplateFile).template;
const isLinkFile = (f: FileInfo): f is LinkFile => (f as LinkFile).link;
const isNewFile = (f: FileInfo): f is NewFile => (f as NewFile).new;

const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const connectRoomSocket = createAction('connectRoomSocket');
export const connectSessionSocket = createAction('connectSessionSocket');
export const disconnectClient = createAction('disconnectClient');
export const disconnectSessionSocket = createAction('disconnectSessionSocket');
export const sessionEnd = createAsyncThunk(
  'sessionEnd',
  ({ errorMessage }: { errorMessage?: string }, { dispatch }) => {
    dispatch(toggleSessionEndedDialog(true, errorMessage));
    dispatch(disconnectSessionSocket());
    dispatch(resetSession());
  }
);

const getIdToken = async () => {
  const user = getFirebaseAuth().currentUser;
  try {
    return user?.getIdToken();
  } catch (e) {
    console.error(e);
  }
  return null;
};

const checkConnection = createAsyncThunk('checkConnection', (reconnecting: boolean, { dispatch, getState }) => {
  const state = getState() as RootState;

  if (state.session.status === CONNECTION_STATUS.waiting) {
    dispatch(setConnectedStatus(CONNECTION_STATUS.failed));
    removeCode();

    if (reconnecting) {
      dispatch(toggleShowReconnectDialog(false));
      dispatch(sessionSocketDisconnection());
    }
  }
});

export const downloadSession = createAction('downloadSession', (sessionId: string, sessionJoinKey: string) => ({
  payload: {
    sessionId,
    sessionJoinKey,
  },
}));

export const tryConnectNew = createAsyncThunk(
  'tryConnectNew',
  async (payload: { fileInfo: FileInfo; reconnecting?: boolean }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { reconnecting, fileInfo = { new: true } } = payload;

    try {
      if (!reconnecting) {
        if (isTemplateFile(fileInfo)) {
          analytics.startNewBoard(fileInfo.templateId);
        } else if (isNewFile(fileInfo)) {
          analytics.startNewBoard();
        } else {
          analytics.openBoard();
        }
        analytics.startBoardLoading();
      }

      if (isLinkFile(fileInfo)) {
        dispatch(setLoadedFileInfo({ ...fileInfo, linkUrl: `/boards/link/${fileInfo.linkId}` }));
      } else {
        dispatch(setLoadedFileInfo(fileInfo));
      }

      let name;
      const user = getFirebaseAuth().currentUser;

      // TODO: Is this actually used?
      if (user?.displayName) {
        name = user.displayName;
      } else {
        name = state.signatureText;
      }

      const firebaseToken = await getIdToken();

      if (!reconnecting) {
        analytics.markBoardLoading('receivedtoken');
      }

      const serverUrl = state.collaborationServerUrl;
      let url;

      if (isTemplateFile(fileInfo)) {
        url = `${serverUrl}/v2/sessions/template/${fileInfo.templateId}`;
      } else if (isLinkFile(fileInfo)) {
        url = `${serverUrl}/v2/sessions/link/${fileInfo.linkId}`;
      } else if (isNewFile(fileInfo)) {
        url = `${serverUrl}/v2/sessions`;
      } else {
        url = `${serverUrl}/v2/sessions/file/${fileInfo.id}`;
      }

      const response = await rest.makeRequest<{ token: string; session: ServerSession }>({
        method: 'POST',
        url,
        authorizationToken: firebaseToken && { auth: firebaseToken },
        data: {
          name: encodeURIComponent(name),
          teamId: 'teamId' in fileInfo ? fileInfo.teamId : false,
        },
      });

      const { token, session } = response.data;

      if (!reconnecting) {
        analytics.markBoardLoading('receivedsession');
      }
      dispatch(downloadSession(session.id, session.joinKey));

      dispatch(setSession({ ...session, token, status: CONNECTION_STATUS.waiting }));
      // If reconnecting, the whiteboard component is already
      // shown and won't connect the session socket
      if (reconnecting) {
        dispatch(connectSessionSocket());
      }

      // Timeout to receive start message on websocket connection
      await delay(15_000);

      dispatch(checkConnection(reconnecting));
    } catch (error) {
      log.error(error);
      dispatch(
        connectFailure(
          error.response?.status === 404 ? CONNECTION_FAIL_REASON.notFound : CONNECTION_FAIL_REASON.unknown
        )
      );

      if (reconnecting) {
        dispatch(toggleShowReconnectDialog(false));
        dispatch(sessionSocketDisconnection());
      }
    }
  }
);

export const tryConnectWithCode = createAsyncThunk('tryConnectWithCode', (code: string, { dispatch }) =>
  dispatch(tryConnect({ code, type: 'code' }))
);

export const tryConnectWithSessionJoinKey = createAsyncThunk(
  'tryConnectWithSessionJoinKey',
  (code: string, { dispatch }) => dispatch(tryConnect({ code, type: 'session' }))
);

const tryConnect = createAsyncThunk(
  'tryConnect',
  async ({ code, type }: { code: string; type: 'code' | 'session' }, { getState, dispatch }) => {
    const state = getState() as RootState;

    analytics.startBoardLoading();
    const serverUrl = state.collaborationServerUrl;
    try {
      const endPoint =
        type === 'code' ? `${serverUrl}/v2/join/code/${code.toUpperCase()}` : `${serverUrl}/v2/join/session/${code}`;

      const firebaseToken = await getIdToken();
      analytics.markBoardLoading('receivedtoken');
      dispatch(setLoadedFileInfo(null));

      const response = await rest.makeRequest<{
        token: string;
        session: ServerSession;
        fileAction: { fileName: string; action: 'no_action' | 'file_owned' | 'not_allowed' | 'link_added' };
      }>({
        method: 'GET',
        url: endPoint,
        authorizationToken: firebaseToken ? { auth: firebaseToken } : false,
      });

      const { token, session, fileAction } = response.data;

      analytics.markBoardLoading('receivedsession');
      dispatch(downloadSession(session.id, session.joinKey));
      dispatch(setSession({ ...session, token, status: CONNECTION_STATUS.waiting }));

      if (fileAction.action === 'file_owned') {
        dispatch(
          addNotification({
            content: `The board has been saved as ${fileAction.fileName}`,
            type: 'unownedFileSaved',
            sticky: true,
            action: () => dispatch(toggleShowRenameDialog(true)),
            actionText: 'Rename board',
          })
        );
      }
      if (fileAction.action === 'not_allowed') {
        dispatch(
          addNotification({
            content: 'Board can not be saved. You have exceeded the maximum number of boards for the free version',
            type: 'unownedFileSaved',
          })
        );
      }

      // 15 seconds timeout to receive start message on websocket connection
      await delay(15_000);

      dispatch(checkConnection(false));
    } catch (error) {
      log.error(error);
      dispatch(
        connectFailure(
          error.response?.status === 404 ? CONNECTION_FAIL_REASON.notFound : CONNECTION_FAIL_REASON.unknown
        )
      );
    }
  }
);

export const sendYieldPresenter = createAction('sendYieldPresenter');

export const sendPing = createAction<{ pageId: number; coords: { x: number; y: number }; color: Uint8Array }>(
  'sendPing'
);

export const sendRequestPresenter = createAction('sendRequestPresenter');

export const sendForceFollow = createAction('sendForceFollow', (on: boolean, receiverId?: string) => ({
  payload: { on, receiverId },
}));

export const sendImage = createAction<{
  data: string;
  fileName: string;
  mime: string;
  type: number;
  continue?: boolean;
  paperId?: string;
}>('sendImage');

export const sendSetReadOnly = createAction('sendSetReadOnly', (on: boolean, receiverId: string) => ({
  payload: { on, receiverId },
}));

export const preparePing = createAsyncThunk(
  'preparePing',
  async (
    payload: { pageId: number; x: number; y: number; color: [number, number, number] },
    { getState, dispatch }
  ) => {
    const { session } = getState() as RootState;

    if (session.clients?.length > 1) {
      const pageId = ZCanvas.page.getCurrentIndex();

      const [r, g, b] = payload.color;
      dispatch(sendPing({ pageId, coords: { x: payload.x, y: payload.y }, color: new Uint8Array(payload.color) }));

      const imageObject = session.clients.find((c) => c.self).pingLabel;
      await ZCanvas.writingAreaPing(
        payload.pageId,
        payload.x,
        2160.0 - payload.y,
        [r / 255, g / 255, b / 255, 0.8],
        imageObject.id
      );
    }
  }
);

export const sendAnnouncePage = createAction<string>('sendAnnouncePage');

export const prepareBoardEvent = createAsyncThunk(
  'prepareBoardEvent',
  (
    payload: {
      actionId: string;
      receiverId: string;
      prevActionId: string;
      type: string;
      action: Action;
      imageData: string;
      imageName: string;
      mimeType: string;
      paperId: string;
    }[],
    { getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const session = state.session;

      if (session) {
        for (const emitEvent of payload) {
          switch (emitEvent.type) {
            case 'emitAction':
              {
                const { action } = emitEvent;
                const stringAction = JSON.stringify(action);

                analytics.boardEvent(action);

                const SPLIT_SIZE = 750000; // protobuf ~= 500kb
                let offset = 0;
                let toBeContinued = true;
                while (toBeContinued) {
                  toBeContinued = offset + SPLIT_SIZE < stringAction.length;
                  const partLength = Math.min(SPLIT_SIZE, stringAction.length - offset);
                  const jsonPart = stringAction.slice(offset, offset + partLength);
                  offset += SPLIT_SIZE;

                  dispatch(
                    sendBoardEvent({
                      data: naClUtil.decodeUTF8(jsonPart),
                      actionId: action.ActionId,
                      prevActionId: emitEvent.prevActionId,
                      continue: toBeContinued,
                    })
                  );
                }
              }
              break;
            case 'emitImage':
              {
                const { imageData, imageName, mimeType } = emitEvent;
                const SPLIT_SIZE = 150000;
                let offset = 0;
                let toBeContinued = true;
                while (toBeContinued) {
                  toBeContinued = offset + SPLIT_SIZE < imageData.length;
                  const partLength = Math.min(SPLIT_SIZE, imageData.length - offset);
                  const part = imageData.slice(offset, offset + partLength);
                  offset += SPLIT_SIZE;

                  dispatch(
                    sendImage({
                      data: part,
                      fileName: imageName,
                      mime: mimeType,
                      type: 0,
                      continue: toBeContinued,
                    })
                  );
                }
              }
              break;
            case 'emitInkImage':
              {
                const { imageData, imageName, paperId, mimeType } = emitEvent;
                dispatch(
                  sendImage({
                    data: imageData,
                    fileName: imageName,
                    paperId,
                    mime: mimeType,
                    type: 1,
                  })
                );
              }
              break;
            case 'emitUndo':
              dispatch(
                undo({
                  receiverId: emitEvent.receiverId,
                  actionId: emitEvent.actionId,
                  prevActionId: emitEvent.prevActionId,
                })
              );

              dispatch(setLatestActionId(emitEvent.prevActionId));
              break;
            default:
              console.warn(`Unhandled zcanvas event type ${emitEvent.type}`);
              break;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
);
