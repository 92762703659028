import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactTooltip from 'react-tooltip';
import slugid from 'slugid';
import ZCanvas from '@flatfrog/ffbec';
import ZImage from '@flatfrog/ffbec/js/zimage';
import { getNbrColorsForString } from '@flatfrog/ffbec/js/zutil';

import * as analytics from 'client/common/analytics';
import { MUTATION_CREATE_ITEM, MUTATION_DELETE_ITEM, MUTATION_UPDATE_ITEM, QUERY_ITEM } from 'client/common/graphql';
import { trashPaper } from 'client/common/helpers/ZCanvasHelpers';
import ComboButton from 'client/components/Common/combo-button';
import { TextAlignments } from 'client/components/Common/text-alignments';
import ToggleSwitch from 'client/components/Common/toggleswitch';
import { packStickyNoteMetadata } from 'client/components/Common/types';
import StickyNote, { STICKY_NOTE_DEFAULT_FONT_SIZE, STICKY_NOTE_SCALE_FACTOR } from 'client/components/StickyNote';
import { BackgroundColors, ForegroundColors } from 'client/components/StickyNote/colors';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { StickyNoteToEdit } from 'client/state/papers/currentStickyNoteEdit';
import { LastStickyNoteProps } from 'client/state/papers/lastStickyNoteProps';
import { Item } from 'client/state/papers/localItems';
import { Session } from 'client/state/session/session';

import {
  BottomMenu,
  Container,
  DeleteButton,
  EditLinkIcon,
  Editor,
  Header,
  LinkContainer,
  LinkTextField,
  Signature,
  SignatureWrapper,
  StyleButton,
  StyleList,
  TextArea,
  TextSizeButton,
  TextSizeWrapper,
  TopMenu,
} from './styled';

// eslint-disable-next-line import/no-named-as-default

const SIZE_RANGE = [16, 24, 32, 40, 48, 56, 64, 80, 88, 96];

const PLACEHOLDER = 'Add text';

const StickyNoteEditorContainer = () => {
  const { currentStickyNote, displaySignatureByDefault, lastStickyNoteProps, localItems, session, signatureText } =
    useSelector((state) => ({
      currentStickyNote: state.currentStickyNoteEdit,
      displaySignatureByDefault: state.displaySignatureByDefault,
      lastStickyNoteProps: state.lastStickyNoteProps,
      localItems: state.localItems,
      session: state.session,
      signatureText: state.signatureText,
    }));

  return currentStickyNote ? (
    <StickyNoteEditor
      currentStickyNote={currentStickyNote}
      displaySignatureByDefault={displaySignatureByDefault}
      lastStickyNoteProps={lastStickyNoteProps}
      localItems={localItems}
      session={session}
      signatureText={signatureText}
    />
  ) : null;
};

interface Props {
  currentStickyNote: StickyNoteToEdit;
  displaySignatureByDefault: boolean;
  lastStickyNoteProps: LastStickyNoteProps;
  localItems: Item[];
  session: Session;
  signatureText: string;
}

const StickyNoteEditor: React.FC<Props> = ({
  currentStickyNote,
  displaySignatureByDefault,
  lastStickyNoteProps,
  localItems,
  session,
  signatureText,
}) => {
  const {
    addLocalItem,
    currentStickyNoteEdit,
    deleteItemCanvas,
    deleteLocalItem,
    setDisplaySignatureByDefault,
    setShowItemStack,
    setStickyNoteLastProps,
    updateLocalItem,
    prepareAndPublishStickyNote,
    updateItemOnBoard,
  } = useActions();

  const [activeColor, setActiveColor] = useState('');
  const [activeTextColor, setActiveTextColor] = useState('');
  const [currentContent, setCurrentContent] = useState('');
  const [currentLink, setCurrentLink] = useState('');
  const [textSize, setTextSize] = useState(STICKY_NOTE_DEFAULT_FONT_SIZE);
  const [desiredTextSize, setDesiredTextSize] = useState(80);
  const [cappedTextSize, setCappedTextSize] = useState(false);
  const [textAlign, setTextAlign] = useState(TextAlignments.Left);
  const [signature, setSignature] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [paperId, setPaperId] = useState(null);
  const [action, setAction] = useState(null);
  const [buttonActions, setButtonActions] = useState([]);
  const [primaryButtonAction, setPrimaryButtonAction] = useState(0);
  const stickyNoteId = useRef<StickyNoteToEdit>();
  const editor = useRef<HTMLDivElement>();
  const textArea = useRef<HTMLTextAreaElement>();
  const linkTextField = useRef<HTMLInputElement>();
  const auth = useAuth();
  const canvas = useRef<HTMLCanvasElement>();

  const [getItem, { data, previousData }] = useLazyQuery(QUERY_ITEM, { fetchPolicy: 'network-only' });
  const [updateItem] = useMutation(MUTATION_UPDATE_ITEM);
  const [createItem] = useMutation(MUTATION_CREATE_ITEM);
  const [deleteItem] = useMutation(MUTATION_DELETE_ITEM);

  const updateFromItem = (item: Item) => {
    setActiveColor(item.backgroundColor);
    setActiveTextColor(item.textColor);
    setCurrentContent(item.content);
    setCurrentLink(item.link);
    setTextAlign(item.textAlign || TextAlignments.Left);
    setTextSize(item.textSize || STICKY_NOTE_DEFAULT_FONT_SIZE);
    setIsDraft(false);
    setPaperId(null);
    setSignature(item.signature);
    textArea.current.value = item.content;
    linkTextField.current.value = item.link;
  };

  useHotkeys(
    'esc',
    () => {
      setAction('close');
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], filter: () => !!currentStickyNote }
  );

  useEffect(() => {
    if (data?.item) {
      updateFromItem(data.item);
    } else if (!data?.item && previousData?.item) {
      // Item deleted from different device, close editor
      currentStickyNoteEdit(null);
    }
  }, [data]);

  const getFormattedLink = () => {
    if (!currentLink || !currentLink.trim()) {
      return '';
    }
    const httpsLink = currentLink.startsWith('http') ? currentLink : `https://${currentLink}`;
    // Clean up url
    return encodeURI(decodeURI(httpsLink));
  };

  // Create server side item if logged in, otherwise a local item
  const saveItemState = () => {
    console.log('saveItemState');
    const input = {
      backgroundColor: activeColor,
      textSize,
      textColor: activeTextColor,
      textAlign,
      content: currentContent,
      link: getFormattedLink(),
      type: 'StickyNote',
      signature,
    };

    if (!auth.user) {
      addLocalItem({ __typename: 'StickyNote', id: slugid.nice(), ...input });
    } else {
      createItem({ variables: { input } });
    }
  };

  // Update item server side if logged in, otherwise a local item
  const updateItemState = () => {
    console.log('updateItemState');

    if ('id' in stickyNoteId.current) {
      const id = stickyNoteId.current.id;

      const input = {
        id,
        backgroundColor: activeColor,
        textSize,
        textAlign,
        content: currentContent,
        link: getFormattedLink(),
        signature,
      };

      if (auth.user) {
        updateItem({ variables: { input } });
      } else {
        updateLocalItem({ __typename: 'StickyNote', ...input });
      }
    }
  };

  const reset = () => {
    setActiveColor(lastStickyNoteProps.backgroundColor || BackgroundColors[0].color);
    setActiveTextColor(lastStickyNoteProps.textColor || ForegroundColors[0]);
    setTextAlign(lastStickyNoteProps.textAlign || TextAlignments.Left);
    setTextSize(lastStickyNoteProps.textSize || STICKY_NOTE_DEFAULT_FONT_SIZE);

    setCurrentContent('');
    setCurrentLink('');
    setIsDraft(true);
    setPaperId(null);
    setSignature(displaySignatureByDefault ? signatureText : '');
    textArea.current.value = '';
    linkTextField.current.value = '';
  };

  // Handle change of sticky note
  useEffect(() => {
    // Opening the editor
    if (stickyNoteId.current === null && currentStickyNote !== null) {
      editor.current.classList.add('active');
      setTimeout(() => textArea.current.focus(), 0);
    }
    // Opening a sticky note for editing
    if (stickyNoteId.current !== currentStickyNote && currentStickyNote !== null) {
      if ('paperId' in currentStickyNote) {
        // Editing a paper on the canvas
        const textSizeFromProps = currentStickyNote.textSize || STICKY_NOTE_DEFAULT_FONT_SIZE;
        setActiveColor(currentStickyNote.backgroundColor || BackgroundColors[0].color);
        setActiveTextColor(currentStickyNote.textColor || ForegroundColors[0]);
        setCurrentContent(currentStickyNote.content || '');
        setTextAlign(currentStickyNote.textAlign || TextAlignments.Left);
        setTextSize(textSizeFromProps);
        setPaperId(currentStickyNote.paperId);
        setIsDraft(false);
        setSignature(currentStickyNote.signature || '');
        textArea.current.value = currentStickyNote.content || '';
        setCurrentLink(currentStickyNote.link);
        linkTextField.current.value = currentStickyNote.link || '';

        setDesiredTextSize(
          Math.max(
            SIZE_RANGE[0],
            SIZE_RANGE.reduce((a, b) => {
              if (
                Math.abs(a - textSizeFromProps * STICKY_NOTE_SCALE_FACTOR) >
                Math.abs(b - textSizeFromProps * STICKY_NOTE_SCALE_FACTOR)
              ) {
                return b;
              }
              return a;
            }, 0)
          )
        );
        setCappedTextSize(false);
      } else if ('id' in currentStickyNote) {
        // Editing a My Items paper
        if (auth.user) {
          getItem({ variables: { id: currentStickyNote.id } });
        } else {
          const item = localItems.find((i) => i.id === currentStickyNote.id);
          updateFromItem(item);
        }
      } else {
        // Creating a new
        reset();
      }
    }
    // Closing the editor
    if (currentStickyNote === null) {
      editor.current.classList.remove('active');
      textArea.current.blur();
      linkTextField.current.blur();

      setStickyNoteLastProps({
        textColor: activeTextColor,
        backgroundColor: activeColor,
        textSize,
        textAlign,
        signature,
      });
    }
    // Remember the current sticky note
    stickyNoteId.current = currentStickyNote;
  }, [currentStickyNote]);

  useEffect(() => {
    if (!action) {
      return;
    }

    // Link analytics
    if (action === 'save' || action === 'update' || action === 'add') {
      const link = getFormattedLink();
      if (paperId) {
        let metadata;
        try {
          metadata = JSON.parse(ZCanvas.paper.getMetadata(paperId));
        } catch (err) {
          console.log('paper without metadata');
        }
        if (metadata && metadata.Link) {
          if (metadata.Link && !link) {
            analytics.removeLinkFromItem();
          } else if (!metadata.Link && link) {
            analytics.addLinkToItem();
          } else if (metadata.Link !== link) {
            analytics.editItemLink();
          }
        } else if (link) {
          analytics.addLinkToItem();
        }
      } else if (link) {
        analytics.createItemWithLink();
      } else {
        analytics.createItemWithoutLink();
      }
    }

    if (action === 'save') {
      // If this is a paper item, i.e. it corresponds to a paper on the board,
      // it should be saved as a copy rather than saved itself
      if (paperId) {
        // Do the tilt animation (open the item stack and send the current paper there)
        // Halt the tilt animation before the new item appears
        setShowItemStack(true);
        editor.current.classList.add('tilt');
        setTimeout(() => editor.current.classList.remove('tilt'), 300);
        // Copy the item to server
        saveItemState();
        currentStickyNoteEdit(null);
      } else if (isDraft) {
        setIsDraft(false);
        // Do the tilt animation (open the item stack and send the current paper there)
        // Reset the tilt animation after completion
        setShowItemStack(true);
        editor.current.classList.add('tilt');
        setTimeout(() => editor.current.classList.remove('tilt'), 600);
        // Save the sticky note
        saveItemState();
        setStickyNoteLastProps({
          textColor: activeTextColor,
          backgroundColor: activeColor,
          textSize,
          textAlign,
          signature,
        });
        // When we save a draft item, we assume "bulk mode", i.e. we immediately create a new item
        // Create a new draft sticky note
        currentStickyNoteEdit({ new: true, saveExpected: true });
        textArea.current.focus();

        analytics.saveStickyNote();
      } else {
        // Save the sticky note
        updateItemState();
        // props.setItemDraft(stickyNoteId.current, false);
        // Close the editor
        currentStickyNoteEdit(null);
      }
    } else if (action === 'update') {
      console.log('update', stickyNoteId.current);

      const handleSendUpdateToBoard = (publishId: string, itemId: string, paperId2: number) => {
        const metadata = packStickyNoteMetadata({
          color: activeColor,
          content: currentContent,
          link: getFormattedLink(),
          textColor: activeTextColor,
          signature,
          textSize,
          textAlign,
        });

        let currentMetadata;
        try {
          currentMetadata = JSON.parse(ZCanvas.paper.getMetadata(paperId2));
        } catch (err) {
          console.log('paper without metadata');
          console.log(err.toString());
        }

        // Since some papers are missing some metadata properties,
        // we first check if both the old and new property is missing or empty.
        // We then check if it has actually changed.
        let needsImageUpdate = false;
        needsImageUpdate =
          needsImageUpdate ||
          (!(!currentMetadata?.Content && !currentContent) && currentMetadata?.Content !== currentContent);
        needsImageUpdate =
          needsImageUpdate ||
          (!(!currentMetadata?.TextColor && !activeTextColor) && currentMetadata?.TextColor !== activeTextColor);
        needsImageUpdate =
          needsImageUpdate ||
          (!(!currentMetadata?.SignatureText && !signature) && currentMetadata?.SignatureText !== signature);
        needsImageUpdate =
          needsImageUpdate ||
          (!(!currentMetadata?.TextAlign && !textAlign) && currentMetadata?.TextAlign !== textAlign);
        needsImageUpdate =
          needsImageUpdate || (!(!currentMetadata?.TextSize && !textSize) && currentMetadata?.TextSize !== textSize);

        let imageArrayBuffer = null;
        if (needsImageUpdate) {
          imageArrayBuffer = ZImage.canvasToPngBlob(canvas.current, getNbrColorsForString(currentContent));
        }
        updateItemOnBoard({
          file: imageArrayBuffer,
          options: {
            color: activeColor,
            itemId,
            paperId: paperId2,
            metadata,
            type: 'StickyNote',
          },
        });
      };

      handleSendUpdateToBoard(null, 'id' in stickyNoteId.current ? stickyNoteId.current.id : undefined, paperId);
      currentStickyNoteEdit(null);
    } else if (action === 'add') {
      setIsDraft(false);

      if (editor.current) {
        editor.current.classList.add('zoom');
        setTimeout(() => {
          if (editor.current) {
            editor.current.classList.remove('zoom');
          }
        }, 250);
      }

      if (session?.id) {
        const handleSendToBoard = (itemId: string) => {
          const metadata = packStickyNoteMetadata({
            color: activeColor,
            content: currentContent,
            link: getFormattedLink(),
            textColor: activeTextColor,
            signature,
            textSize,
            textAlign,
          });
          const imageArrayBuffer = ZImage.canvasToPngBlob(canvas.current, getNbrColorsForString(currentContent));
          prepareAndPublishStickyNote({
            file: imageArrayBuffer,
            options: {
              color: activeColor,
              itemId,
              metadata,
              selectPaper: true,
            },
          });
        };
        handleSendToBoard('id' in stickyNoteId.current ? stickyNoteId.current.id : undefined);
      }
      currentStickyNoteEdit(null);

      if ('id' in stickyNoteId.current) {
        if (auth.user) {
          deleteItem({
            variables: {
              input: {
                id: stickyNoteId.current.id,
              },
            },
          });
        } else {
          deleteLocalItem(stickyNoteId.current.id);
        }
        deleteItemCanvas(stickyNoteId.current.id);
      }

      setShowItemStack(false);
      analytics.addStickyNote();
    } else if (action === 'delete') {
      if (paperId) {
        // This item has an associated paper, that should be trashed (the item will be deleted as a result)
        trashPaper({ paperId, removePaper: true });
        ReactTooltip.hide();
      } else if ('id' in stickyNoteId.current) {
        if (auth.user) {
          deleteItem({
            variables: {
              input: {
                id: stickyNoteId.current.id,
              },
            },
          });
        } else {
          deleteLocalItem(stickyNoteId.current.id);
        }
        deleteItemCanvas(stickyNoteId.current.id);
      }
      currentStickyNoteEdit(null);

      analytics.deleteFromEditor();
    } else if (action === 'close') {
      currentStickyNoteEdit(null);
    }
    setAction(null);
  }, [
    action,
    paperId,
    currentContent,
    currentLink,
    activeColor,
    activeTextColor,
    textSize,
    textAlign,
    signature,
    session,
  ]);

  useEffect(() => {
    const buttonActionsList = [
      {
        label: isDraft ? 'Save for later' : paperId ? 'Copy to My Items' : 'Done',
        action: () => setAction('save'),
      },
    ];
    if (session?.id) {
      buttonActionsList.push({
        label: paperId ? 'Done' : 'Add to Board',
        action: paperId ? () => setAction('update') : () => setAction('add'),
      });
    }
    setPrimaryButtonAction('saveExpected' in currentStickyNote ? 0 : buttonActionsList.length - 1);
    setButtonActions(buttonActionsList);
  }, [currentStickyNote, session, paperId, isDraft]);

  useLayoutEffect(() => {
    if (document.activeElement !== linkTextField.current) {
      textArea.current.focus();
    }
  }, []);

  const stickyNoteEditorView = (
    <>
      <TopMenu>
        <TextSizeWrapper>
          <TextSizeButton
            className="bigger"
            disabled={cappedTextSize || desiredTextSize === SIZE_RANGE[SIZE_RANGE.length - 1]}
            onClick={() => {
              setDesiredTextSize(
                SIZE_RANGE.reduce((a, b) => {
                  if (b <= desiredTextSize) {
                    return a;
                  }
                  if (a === desiredTextSize) {
                    // Return the first value larger than desiredTextSize
                    return b;
                  }
                  return a;
                }, desiredTextSize)
              );
              textArea.current.focus();
            }}
          />
          <TextSizeButton
            className="smaller"
            disabled={desiredTextSize === SIZE_RANGE[0] || textSize * STICKY_NOTE_SCALE_FACTOR < SIZE_RANGE[0]}
            onClick={() => {
              setDesiredTextSize(
                [...SIZE_RANGE].reverse().reduce((a, b) => {
                  if (b > desiredTextSize) {
                    return a;
                  }
                  if (a === desiredTextSize) {
                    // Return the first value smaller than desiredTextSize
                    return b;
                  }
                  return a;
                }, desiredTextSize)
              );
              textArea.current.focus();
            }}
          />
        </TextSizeWrapper>
        <StyleList>
          {BackgroundColors.map((bg) => (
            <StyleButton
              data-place="top"
              data-tip={bg.name}
              data-effect="solid"
              key={bg.color}
              color={bg.color}
              active={bg.color === activeColor}
              onClick={() => {
                setActiveColor(bg.color);
                textArea.current.focus();
              }}
              id={`set-style-${stickyNoteId.current}`}
            />
          ))}
        </StyleList>
      </TopMenu>
      <LinkContainer>
        <EditLinkIcon />
        <LinkTextField
          ref={(r) => {
            linkTextField.current = r;
          }}
          placeholder="Add a link"
          onChange={() => {
            setCurrentLink(linkTextField.current.value);
          }}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              (e.getModifierState('Control') || e.getModifierState('Meta')) &&
              buttonActions.length > 0
            ) {
              // Make the default action on Ctrl/Cmd+Enter rather than making a new line
              e.preventDefault();
              buttonActions[primaryButtonAction].action();
            } else if (e.key === 'Tab' || e.key === 'Enter') {
              e.preventDefault();
              textArea.current.focus();
            }
          }}
        />
      </LinkContainer>
      <div>
        <TextArea
          style={{
            background: activeColor,
            fontSize: textSize * STICKY_NOTE_SCALE_FACTOR,
            color: activeTextColor,
            textAlign,
          }}
          ref={(r) => {
            textArea.current = r;
          }}
          onChange={() => {
            setCurrentContent(textArea.current.value);
          }}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              (e.getModifierState('Control') || e.getModifierState('Meta')) &&
              buttonActions.length > 0
            ) {
              // Make the default action on Ctrl/Cmd+Enter rather than making a new line
              e.preventDefault();
              buttonActions[primaryButtonAction].action();
            }
          }}
          placeholder={PLACEHOLDER}
          placeholderColor={`${activeTextColor}70`}
          tabIndex={-1}
          id={`textarea-${stickyNoteId.current}`}
        />
        {(signature || signatureText) && ( // Disable if we don't have a signature to add
          <SignatureWrapper>
            <ToggleSwitch
              checked={!!signature}
              translucent={!signature}
              id={`signature-toggle-${stickyNoteId.current}`}
              onChange={() => {
                // Let our decision to use our own signature here propagate to the default signature setting
                if (!signature) {
                  setDisplaySignatureByDefault(true);
                } else if (signature === signatureText) {
                  setDisplaySignatureByDefault(false);
                }
                setSignature(signature ? '' : signatureText);
                textArea.current.focus();
              }}
            />
            <Signature
              style={{
                color: activeTextColor,
                opacity: signature ? 1 : 0.08,
              }}
            >
              {signature || signatureText}
            </Signature>
          </SignatureWrapper>
        )}
      </div>
      <div style={{ display: 'none' }}>
        <StickyNote
          color={activeColor}
          content={currentContent}
          link={getFormattedLink()}
          textColor={activeTextColor}
          signature={signature}
          desiredTextSize={desiredTextSize / STICKY_NOTE_SCALE_FACTOR}
          textSize={textSize}
          textAlign={textAlign}
          onAdjustTextSize={(size, cappedSize) => {
            setTextSize(size);
            setCappedTextSize(cappedSize);
          }}
          preview
          canvasRef={canvas}
        />
      </div>
      <BottomMenu>
        {!isDraft ? (
          <DeleteButton data-place="top" data-tip="Delete" data-effect="solid" onClick={() => setAction('delete')} />
        ) : null}
        {buttonActions.map((buttonAction, index) => (
          <ComboButton
            key={buttonAction.label}
            actions={[buttonAction]}
            open={false}
            style={{ marginLeft: '16px' }}
            gray={index !== primaryButtonAction}
          />
        ))}
      </BottomMenu>
    </>
  );

  return (
    <>
      <Editor
        className="active"
        id={`editor-${stickyNoteId.current}`}
        ref={(r) => {
          editor.current = r;
        }}
        onClick={() => {
          if (document.activeElement !== linkTextField.current) {
            textArea.current.focus();
          }
        }}
      >
        <button className="close" onClick={() => setAction('close')} />
        <Header>{isDraft ? 'Add' : 'Edit'} Sticky Note</Header>
        <Container className="active">{stickyNoteEditorView}</Container>
      </Editor>
    </>
  );
};

export default StickyNoteEditorContainer;
