import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { File, FileOrFileLink, isFile, isFileLink, QUERY_DASHBOARD } from 'client/common/graphql';
import { getParticipantColor } from 'client/common/participant-names';
import { CopyButton } from 'client/components/CollaborationSidePanel/styled';
import { YouTubePlayer } from 'client/components/Common/YouTubePlayer';
import TemplateGallery from 'client/components/Dashboard/templates/template-gallery';
import { useActions } from 'client/hooks/useActions';
import useLocalStorageState from 'client/hooks/useLocalStorageState';
import { useSelector } from 'client/hooks/useSelector';
import { ContextMenu } from 'client/components/Common/ContextMenu';

import RoomAppLink from '../download-room-app/room-app-link';
import * as Styled from '../styled';

const THUMBNAIL_PLACEHOLDER = '/images/ill_thumbnail_placeholder.svg';

interface Props {
  allowedToCreateFile: { allowed: boolean; limit?: number };
  deleteBoard: (file: FileOrFileLink) => void;
  duplicateBoard: (boardId: string) => void;
  removeLink: (link: unknown) => void;
  renameBoard: (file: File) => void;
  setShowLoading: (loading: boolean) => void;
  setShowMoveTo: (options: { fileId: string; teamId: string }) => void;
  setShowShareDialog: (options: { fileId: string; fileName: string; showMove: boolean; teamId: string }) => void;
  showCastDialog: (e: React.MouseEvent, file: FileOrFileLink) => void;
  user: { uid: string };
  userRole: string;
}

const RECENT_FILES_LIMIT = 5;

const DashboardColumn: React.FC<Props> = ({
  allowedToCreateFile,
  deleteBoard,
  duplicateBoard,
  removeLink,
  renameBoard,
  setShowLoading,
  setShowMoveTo,
  setShowShareDialog,
  showCastDialog,
  user,
  userRole,
}) => {
  const { featureFlags } = useSelector((state) => ({ featureFlags: state.featureFlags }));

  const { tryConnectNew } = useActions();

  const history = useHistory();

  const [showRoomAppPromo, setShowRoomAppPromo] = useLocalStorageState('showRoomAppPromo', true);

  const query = useQuery(QUERY_DASHBOARD, {
    variables: { limit: RECENT_FILES_LIMIT },
  });
  const files = query.loading ? [] : query.data?.recents || [];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [files]);

  const hideRoomAppPromo = () => {
    setShowRoomAppPromo(false);
  };

  const recentItems = files.map((f) => {
    const fileId = isFileLink(f) ? f.file.id : f.id;
    const team = (isFile(f) && f.team) || (isFileLink(f) && f.file?.team);

    return (
      <Link
        to={{
          pathname: fileId !== 'load' ? `/dashboard/open/${fileId}` : '',
          state: { teamId: isFileLink(f) ? f.file?.team?.id : f.team?.id },
        }}
        key={f.id}
        id={`start-${f.id}`}
      >
        <Styled.RecentBoardItem>
          <Styled.RecentBoardThumbnail
            style={{
              backgroundImage: `url(${isFileLink(f) ? f.file?.thumbnailUri ?? THUMBNAIL_PLACEHOLDER : f.thumbnailUri})`,
            }}
          />
          <Styled.RecentBoardLabel>
            {(isFileLink(f) ? f.file?.fileName : f.fileName) || 'Unnamed Board'}
            {isFileLink(f) && (
              <Styled.SharedBoard
                data-tip={`Owner: ${f.file?.user?.displayName}${f.file?.user?.disabled ? ' (disabled)' : ''}`}
                data-effect="solid"
                data-delay-show="150"
              />
            )}
          </Styled.RecentBoardLabel>
          <Styled.RecentBoardExpander />
          {team && (
            <Styled.RecentBoardTeamName color={getParticipantColor(team?.name)}>{team.name}</Styled.RecentBoardTeamName>
          )}
          <Styled.ClickGuard onClick={(e) => e.preventDefault()}>
            <CopyButton
              style={{ margin: 'auto 0' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowShareDialog({
                  fileId,
                  fileName: isFileLink(f) ? f.file.fileName : f.fileName,
                  showMove: isFile(f) && f.user.uid === user.uid,
                  teamId: team?.id,
                });
              }}
            >
              Share
            </CopyButton>
            <Styled.CastButton data-tip="Open in room" data-effect="solid" onClick={(e) => showCastDialog(e, f)} />
            <ContextMenu
              items={
                isFileLink(f)
                  ? [
                      [
                        {
                          title: 'Duplicate',
                          onClick: () => duplicateBoard(fileId),
                          disabled: !allowedToCreateFile.allowed,
                        },
                        {
                          title: 'Rename',
                          disabled: true,
                          onClick: () => {},
                        },
                      ],
                      [
                        {
                          title: 'Remove Link',
                          onClick: () => removeLink(f),
                        },
                      ],
                    ]
                  : [
                      [
                        {
                          title: team ? 'Change Workspace' : 'Move to a Workspace',
                          onClick: () => setShowMoveTo({ fileId: f.id, teamId: f.team?.id }),
                          hidden: !(featureFlags.enableTeams && f.user?.uid === user.uid),
                        },
                        {
                          title: 'Duplicate',
                          onClick: () => duplicateBoard(fileId),
                          disabled: !allowedToCreateFile.allowed,
                        },
                        {
                          title: 'Rename',
                          onClick: () => renameBoard(f),
                        },
                      ],
                      [
                        {
                          title: 'Delete Board',
                          onClick: () => deleteBoard(f),
                        },
                      ],
                    ]
              }
            >
              <Styled.MoreButton id={`menu-${fileId}`} />
            </ContextMenu>
          </Styled.ClickGuard>
        </Styled.RecentBoardItem>
      </Link>
    );
  });

  return (
    <Styled.MainColumn>
      {showRoomAppPromo && (
        <Styled.PromotionGroup>
          <Styled.PromotionCard>
            <Styled.PromotionCloseButton onClick={hideRoomAppPromo} />
            <Styled.PromotionVideo>
              <YouTubePlayer embedId="8QotnTuPMF4" />
            </Styled.PromotionVideo>
            <Styled.PromotionText>
              <Styled.PromotionHeader>Unlock the full potential of FlatFrog Board</Styled.PromotionHeader>
              <Styled.PromotionBody>
                Learn how FlatFrog Board can improve your meeting room or conference calls.
              </Styled.PromotionBody>
              <RoomAppLink />
            </Styled.PromotionText>
          </Styled.PromotionCard>
        </Styled.PromotionGroup>
      )}
      <Styled.RecentBoardsGroup>
        <Styled.GroupHeader>
          <Styled.HeaderTitle>Recent Boards</Styled.HeaderTitle>
          <Styled.HeaderExpander />
          <Styled.HeaderMoreButton id="show-my-boards">
            <Link to="/dashboard/my-boards">My Boards</Link>
          </Styled.HeaderMoreButton>
        </Styled.GroupHeader>
        <Styled.RecentBoardList>
          {query.loading && (
            <Styled.RecentBoardItem className="loading">Loading recent boards&hellip;</Styled.RecentBoardItem>
          )}
          {recentItems.length > 0 && !query.loading && recentItems}
          {recentItems.length === 0 && !query.loading && (
            <Styled.RecentBoardsEmpty>
              <Styled.RecentBoardsEmptyIllustration />
              <Styled.RecentBoardsEmptyTitle>You don’t have any Boards yet</Styled.RecentBoardsEmptyTitle>
              <Styled.RecentBoardsEmptyComment>
                Create a new board or upload from your computer
              </Styled.RecentBoardsEmptyComment>
              <Styled.EmptyBoardsButtons>
                <Styled.EmptyBoardsButton
                  className="green"
                  onClick={() => {
                    history.push('/dashboard/templates');
                  }}
                >
                  <img src="/icons/icn_new_board.svg" alt="New Board" /> New Board
                </Styled.EmptyBoardsButton>
                <Styled.EmptyBoardsButton
                  className="blue"
                  onClick={() => {
                    history.push('/dashboard/upload');
                  }}
                >
                  <img src="/icons/icn_upload_file.svg" alt="Upload from desktop" /> Upload from computer
                </Styled.EmptyBoardsButton>
              </Styled.EmptyBoardsButtons>
            </Styled.RecentBoardsEmpty>
          )}
        </Styled.RecentBoardList>
      </Styled.RecentBoardsGroup>

      <Styled.CreateBoardGroup>
        <Styled.GroupHeader>
          <Styled.HeaderTitle>Start New Board</Styled.HeaderTitle>
        </Styled.GroupHeader>
        <Styled.TemplatePanel
          className={!allowedToCreateFile.allowed && 'disabled'}
          data-tip={
            allowedToCreateFile.limit
              ? `Maximum number (${allowedToCreateFile.limit}) of boards reached`
              : 'Not allowed to create new board'
          }
          data-tip-disable={query.loading || allowedToCreateFile.allowed}
          data-place="top"
          data-delay-show={300}
          data-effect="solid"
        >
          <TemplateGallery
            userRole={userRole}
            onSelect={(template) => {
              tryConnectNew({ fileInfo: template });
              setShowLoading(true);
            }}
            disabled={!allowedToCreateFile.allowed}
          />
        </Styled.TemplatePanel>
      </Styled.CreateBoardGroup>
    </Styled.MainColumn>
  );
};

export default DashboardColumn;
