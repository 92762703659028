import React from 'react';
import ReactTooltip from 'react-tooltip';
import ZCanvas from '@flatfrog/ffbec';
import classNames from 'classnames';

import * as analytics from 'client/common/analytics';
import ToggleSwitch from 'client/components/Common/toggleswitch';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import NameBubble from 'client/components/Common/NameBubble';

import * as Styled from './styled';

interface Props {
  alone: boolean;
}

const PresenterFollower: React.FC<Props> = ({ alone }) => {
  const { forceFollow, session } = useSelector((state) => ({
    forceFollow: state.forceFollow,
    session: state.session,
  }));

  const { sendAnnouncePage, sendForceFollow, sendRequestPresenter, sendYieldPresenter, setForceFollow, setPresenter } =
    useActions();

  const { clients } = session;
  const self = clients?.find((c) => c.self);
  const presenter = clients?.find((c) => c.isPresenter);

  if (!self) {
    return null;
  }

  return (
    <>
      {alone && <Styled.Title style={{ paddingLeft: 8 }}>Settings</Styled.Title>}
      <Styled.CollaborationControls>
        {!alone && (
          <Styled.CollaborationControlsRow>
            {presenter ? (
              <NameBubble room={presenter.clientType === 'room'} displayName={presenter.name}>
                {presenter.isPresenter && <Styled.PresenterMarker />}
              </NameBubble>
            ) : (
              <Styled.NoPresenter className="nobody" />
            )}
            <Styled.PresenterInfo>
              {presenter ? (
                self.isPresenter ? (
                  <strong>You're Presenting</strong>
                ) : (
                  <>
                    <strong>{presenter.name ?? '?'}</strong>
                    <span>is currently presenting</span>
                  </>
                )
              ) : (
                <strong>No one is presenting</strong>
              )}
            </Styled.PresenterInfo>
            {!self.isPresenter && !(presenter && forceFollow) && (
              <Styled.PresentButton
                data-tip="Take Control"
                onClick={() => {
                  sendRequestPresenter();
                  setPresenter({ clientId: self.id });

                  // This is just to trigger everything correctly with the isPresenter flag on announcePage
                  const currentIndex = ZCanvas.page.getCurrentIndex();
                  const uuid = ZCanvas.page.getUuidFromIndex(currentIndex);
                  if (uuid !== undefined) {
                    sendAnnouncePage(uuid);
                  }
                  ReactTooltip.hide();

                  analytics.presentOn();
                }}
              >
                Present
              </Styled.PresentButton>
            )}
            {self.isPresenter && (
              <Styled.PresentButton
                data-tip="Stop presenting"
                className="stop-presenting"
                onClick={() => {
                  if (forceFollow) {
                    sendForceFollow(false);
                    setForceFollow(false);
                  }

                  sendYieldPresenter();
                  setPresenter({ clientId: self.id, isPresenter: false });
                  ReactTooltip.hide();

                  analytics.presentOff();
                }}
              >
                Stop
              </Styled.PresentButton>
            )}
            {!self.isPresenter && forceFollow && (
              <Styled.LockedButton data-place="left" data-tip="The presenter has locked the board" />
            )}
          </Styled.CollaborationControlsRow>
        )}
        {alone && (
          <Styled.CollaborationControlsRow>
            <span className="presenter">Start as Presenter</span>
            <ToggleSwitch
              color="#279AE7"
              checked={self.isPresenter}
              onChange={() => {
                if (!self.isPresenter) {
                  sendRequestPresenter();
                  setPresenter({ clientId: self.id });
                  analytics.presentOn();
                } else {
                  sendYieldPresenter();
                  setPresenter({ clientId: self.id, isPresenter: false });
                  sendForceFollow(false);
                  setForceFollow(false);
                  analytics.presentOff();
                }
                ReactTooltip.hide();
              }}
            />
          </Styled.CollaborationControlsRow>
        )}
        {self.isPresenter && (
          <Styled.TransitionWrapper className={classNames({ alone })}>
            {!alone && <Styled.HorizontalSeparator />}
            <Styled.CollaborationControlsRow>
              <span className={forceFollow ? 'on' : 'off'}>Lock participants to your page</span>
              <ToggleSwitch
                color="#279AE7"
                checked={forceFollow}
                onChange={() => {
                  setForceFollow(!forceFollow);
                  sendForceFollow(!forceFollow);
                  if (forceFollow) {
                    analytics.presentLockOff();
                  } else {
                    analytics.presentLockOn();
                  }
                }}
              />
            </Styled.CollaborationControlsRow>
          </Styled.TransitionWrapper>
        )}
      </Styled.CollaborationControls>
    </>
  );
};

export default PresenterFollower;
