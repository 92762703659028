import React from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { Team } from 'client/common/graphql';
import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useAuth } from 'client/hooks/useAuth';
import User from 'client/components/Common/user';

import * as Styled from './create-team-styled';

interface Props {
  team: Team;
  invites: string[];
  onClose: () => void;
  showInvite: () => void;
  removeInviteFromTeam: (teamId: string, email: string) => void;
  removeUserFromTeam: (userId: string, teamId: string) => void;
}

const TeamMembers: React.FC<Props> = ({
  onClose,
  removeInviteFromTeam,
  removeUserFromTeam,
  showInvite,
  team: { id, members },
  invites,
}) => {
  useHotkeys('esc', () => {
    onClose();
  });

  const auth = useAuth();
  const owner = members.find((member) => member.uid === auth?.user?.uid)?.role === 'Owner';

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container>
        <Styled.CloseButton onClick={onClose} id="btn--workspace-members-close" />
        <Styled.Title>Members ({members.length})</Styled.Title>
        <Styled.TeamMemberContainer>
          {[members.find((m) => m.role === 'Owner'), ...members.filter((m) => m.role !== 'Owner')]
            .filter((m) => m)
            .map((member, idx) => (
              <Styled.TeamMemberListItem key={`fly-${member.uid}-${idx}`}>
                <User displayName={member.displayName} email={member.email} disabled={member.disabled} />
                {(owner || member.role === 'Owner') && (
                  <Styled.RoleTag
                    immutable={member.role === 'Owner'}
                    onClick={() => {
                      if (member.role !== 'Owner') {
                        removeUserFromTeam(member.uid, id);
                      }
                    }}
                  >
                    {member.role === 'Owner' ? 'Owner' : <span className="remove" />}{' '}
                  </Styled.RoleTag>
                )}
              </Styled.TeamMemberListItem>
            ))}
          {invites.map((email) => (
            <Styled.TeamMemberListItem key={`fly-${email}`} className="pending">
              <Styled.TeamDialogListImage />
              <Styled.Name>{email} (invite pending)</Styled.Name>
              {owner && (
                <Styled.RoleTag
                  onClick={() => {
                    removeInviteFromTeam(id, email);
                  }}
                >
                  <span className="remove" />
                </Styled.RoleTag>
              )}
            </Styled.TeamMemberListItem>
          ))}
        </Styled.TeamMemberContainer>
        {owner && (
          <Styled.InviteButton onClick={showInvite}>
            <span>
              <img src="/icons/icn_new_team.svg" alt="Invite People" />
              Invite People
            </span>
          </Styled.InviteButton>
        )}
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default TeamMembers;
