import { useQuery, useMutation } from '@apollo/client';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import * as analytics from 'client/common/analytics';
import { QUERY_MY_ITEMS, MUTATION_DELETE_ITEMS, MUTATION_CREATE_ITEMS } from 'client/common/graphql';
import SmallCheckbox from 'client/components/Common/small-checkbox';
import StickyNote from 'client/components/StickyNote';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { Item } from 'client/state/papers/localItems';

import MobileEditor from './MobileEditor';
import * as Styled from './styled';

const SWIPE_BUTTON_WIDTH = 80;

interface ItemComponentProps {
  item: Item;
  selected: boolean;
  selectMode: boolean;
  disabled: boolean;
  onDelete: () => void;
  onSend: () => void;
  setSelected: (selected: boolean) => void;
  onEdit: () => void;
  canSend: boolean;
}

const ItemComponent: React.FC<ItemComponentProps> = ({
  item,
  selected,
  selectMode,
  disabled,
  onDelete,
  onSend,
  setSelected,
  onEdit,
  canSend,
}) => {
  const [position, setPosition] = useState(0);
  const [swiping, setSwiping] = useState(0);

  const onSwipe = useSwipeable({
    onSwiping: ({ deltaX, event }) => {
      if (!selectMode) {
        event.stopPropagation();

        const x = position + deltaX;

        if ((canSend || x <= 0) && Math.abs(x) <= SWIPE_BUTTON_WIDTH) {
          setSwiping(x);
        }
      }
    },
    onSwiped: ({ deltaX, event }) => {
      if (!selectMode) {
        event.stopPropagation();

        const x = Math.abs(swiping) < SWIPE_BUTTON_WIDTH * 0.8 ? 0 : Math.sign(deltaX) * SWIPE_BUTTON_WIDTH;

        setPosition(x);
        setSwiping(x);
      }
    },
  });

  return (
    <Styled.ItemRow>
      <Styled.SendUnderlay onClick={onSend} />
      <Styled.DeleteUnderlay onClick={onDelete} />
      <Styled.ListItemWrapper
        style={selectMode ? {} : { transform: `translateX(${swiping}px)` }}
        className={classNames(
          {
            selected,
            disabled,
          },
          'mobile'
        )}
        {...onSwipe}
      >
        {selectMode && (
          <Styled.ListItemColumn className="checkbox-col mobile">
            <label>
              <SmallCheckbox checked={selected} disabled={disabled} onChange={(e) => setSelected(e.target.checked)} />
            </label>
          </Styled.ListItemColumn>
        )}
        <Styled.ListItemColumn
          onClick={() => {
            if (selectMode) {
              setSelected(!selected);
            } else {
              onEdit();
            }
          }}
          className="item-col mobile"
        >
          <Styled.ItemSquare
            style={{
              background: item.backgroundColor,
              color: item.textColor,
              fontFamily: 'Caveat',
            }}
          >
            {'Aa'}
            <div style={{ position: 'absolute', opacity: 0, transform: 'scale(0)' }}>
              <StickyNote
                isSent={false}
                key={item.id}
                id={item.id}
                color={item.backgroundColor}
                content={item.content}
                textColor={item.textColor}
                textAlign={item.textAlign}
                textSize={item.textSize}
                signature={item.signature}
              />
            </div>
          </Styled.ItemSquare>
          <Styled.ItemLabel className="mobile">{item.content}</Styled.ItemLabel>
        </Styled.ListItemColumn>{' '}
      </Styled.ListItemWrapper>
    </Styled.ItemRow>
  );
};

interface Props {
  hidden?: boolean;
}

const MobileItemStack: React.FC<Props> = ({ hidden }) => {
  const { currentStickyNoteId, currentTextBlockId, localItems, session, showItemStack, signatureText } = useSelector(
    (state) => ({
      currentStickyNoteId: state.currentStickyNoteEdit,
      currentTextBlockId: state.currentTextBlockEdit,
      localItems: state.localItems,
      session: state.session,
      showItemStack: state.showItemStack,
      signatureText: state.signatureText,
    })
  );

  const { addItemsToBoard, deleteItemCanvas, deleteLocalItem, emptyLocalItems, setShowItemStack } = useActions();

  const auth = useAuth();

  const [selectedItems, setSelectedItems] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const query = useQuery(QUERY_MY_ITEMS, { nextFetchPolicy: 'network-only' });

  const [deleteItems] = useMutation(MUTATION_DELETE_ITEMS);
  const [createItems] = useMutation(MUTATION_CREATE_ITEMS);

  const [stickyNotes, setStickyNotes] = useState([]);

  useEffect(() => {
    if (showItemStack) {
      analytics.viewPanel('/my-items');
    }
  }, [showItemStack]);

  useEffect(() => {
    setDisabled(!!(currentStickyNoteId || currentTextBlockId));
  }, [currentStickyNoteId, currentTextBlockId]);

  useEffect(() => {
    setSelectedItems([]);
  }, [selectMode]);

  const doSendItems = (itemsToSend: string | string[]) => {
    const count = Array.isArray(itemsToSend) ? itemsToSend.length : 1;
    addItemsToBoard(!Array.isArray(itemsToSend) ? [itemsToSend] : itemsToSend);
    setSelectedItems([]);
    analytics.addFromMyItems(count);
  };

  const doDeleteItems = (itemsToDelete: string | string[]) => {
    const itemsToHandle = !Array.isArray(itemsToDelete) ? [itemsToDelete] : itemsToDelete;
    const count = itemsToHandle.length;

    if (auth.user) {
      deleteItems({ variables: { input: { ids: itemsToHandle } } });
      itemsToHandle.forEach((id) => {
        deleteItemCanvas(id);
      });
    } else {
      itemsToHandle.forEach((id) => {
        deleteLocalItem(id);
        deleteItemCanvas(id);
      });
    }

    setSelectedItems([]);
    analytics.deleteFromMyItems(count);
  };

  useEffect(() => {
    if (editItem) {
      setShowEditor(true);
    } else {
      setShowEditor(false);
    }
  }, [editItem]);

  useEffect(() => {
    if (auth.user && localItems.length > 0) {
      const idsToSave = localItems.map((item) => item.id);

      const itemsToSave = localItems
        .map(({ content, textColor, backgroundColor, outlineColor, textSize, textAlign, link, type }) => ({
          content,
          textColor,
          backgroundColor,
          outlineColor,
          textSize,
          textAlign,
          link,
          type,
          signature: signatureText,
        }))
        .reverse();

      createItems({ variables: { input: itemsToSave } }).then(() => {
        emptyLocalItems();
        idsToSave.forEach(deleteItemCanvas);
      });
    }
  }, [auth.user]);

  useEffect(() => {
    const items: Item[] = (auth.user ? query?.data?.items : localItems) ?? [];

    setStickyNotes(
      items.filter((item) => item.__typename === 'StickyNote').map((item) => ({ ...item, position: 0, swiping: 0 }))
    );
  }, [localItems, query?.data?.items]);

  const scrollArea =
    stickyNotes.length > 0 ? (
      <Styled.ScrollArea className={classNames({ show: showItemStack }, 'mobile')}>
        <div>
          {stickyNotes.map((item) => (
            <ItemComponent
              key={item.id}
              item={item}
              selected={selectedItems.includes(item.id)}
              setSelected={(selected) =>
                selected
                  ? setSelectedItems([item.id, ...selectedItems])
                  : setSelectedItems(selectedItems.filter((id) => id !== item.id))
              }
              disabled={disabled}
              onEdit={() => setEditItem(item)}
              onSend={() => {
                doSendItems(item.id);
                setShowItemStack(false);
              }}
              onDelete={() => doDeleteItems(item.id)}
              selectMode={selectMode}
              canSend={!!session?.id}
            />
          ))}
        </div>
      </Styled.ScrollArea>
    ) : (
      <Styled.ScrollArea className={classNames({ show: showItemStack }, 'empty')}>
        <img src="/images/ill_my_items_empty.svg" />
        <div style={{ fontSize: '14px', marginTop: '16px', userSelect: 'none' }}>My Items is Empty</div>
      </Styled.ScrollArea>
    );

  return (
    <>
      <Styled.Wrapper
        className={classNames({ show: showItemStack }, session?.id ? 'connected' : 'not-connected', 'mobile')}
        hidden={hidden}
      >
        <Styled.HeaderArea>
          <div
            style={{
              margin: 'auto',
              color: '#242424',
              fontWeight: 500,
              fontSize: '16px',
              userSelect: 'none',
            }}
          >
            My Items
          </div>
        </Styled.HeaderArea>
        {scrollArea}
        <Styled.MobileControls>
          <Styled.MobileButton
            className={classNames({ delete: selectMode })}
            onClick={() => {
              if (!selectMode) {
                setShowItemStack(false);
              } else {
                doDeleteItems(selectedItems.length ? selectedItems : stickyNotes.map((i) => i.id));
                setSelectMode(false);
              }
            }}
          >
            {selectMode ? (selectedItems.length > 0 ? `Delete (${selectedItems.length})` : 'Delete All') : 'Close'}
          </Styled.MobileButton>
          <Styled.MobileAddButton
            className={classNames({ select: selectMode })}
            onClick={() => {
              if (selectMode) {
                setSelectMode(!selectMode);
              } else {
                setShowEditor(true);
              }
            }}
          >
            {selectMode ? 'Done' : ''}
          </Styled.MobileAddButton>
          <Styled.MobileButton
            className={classNames({
              send: selectMode,
              hidden: stickyNotes.length === 0,
              disabled: !session?.id && selectMode,
            })}
            onClick={() => {
              if (selectMode) {
                doSendItems(selectedItems.length ? selectedItems : stickyNotes.map((i) => i.id));
                setSelectMode(false);
                setShowItemStack(false);
              } else {
                setSelectMode(true);
              }
            }}
          >
            {selectMode ? (selectedItems.length > 0 ? `Send (${selectedItems.length})` : 'Send All') : 'Select'}
          </Styled.MobileButton>
        </Styled.MobileControls>
      </Styled.Wrapper>
      {showEditor && (
        <MobileEditor
          onClose={() => {
            setEditItem(null);
            setShowEditor(false);
          }}
          onSend={(id) => {
            doSendItems(id);
            setEditItem(null);
            setShowEditor(false);
            setShowItemStack(false);
          }}
          item={editItem}
        />
      )}
    </>
  );
};

export default MobileItemStack;
