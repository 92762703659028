import React from 'react';

import * as Styled from 'client/components/Auth/styled';

interface Props {
  accept: () => void;
  accepted: boolean;
}

const CookieBanner: React.FC<Props> = ({ accept, accepted }) => (
  <Styled.CookieBanner className={!accepted && 'notaccepted'}>
    <Styled.CookieBannerText>
      This website uses cookies to improve your experience.
      <br />
      See our{' '}
      <a target="_blank" href="/privacy-policy">
        Privacy Policy
      </a>{' '}
      to learn more.
    </Styled.CookieBannerText>
    <Styled.CookieBannerButton onClick={accept}>Accept</Styled.CookieBannerButton>
  </Styled.CookieBanner>
);

export default CookieBanner;
