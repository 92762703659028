import { createReducer } from '@reduxjs/toolkit';

export default createReducer(
  {
    type: null,
    payload: null,
    meta: null,
    error: false,
  },
  (builder) => builder.addDefaultCase((state, action) => ({ ...state, ...action }))
);
