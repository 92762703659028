import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { getParticipantColor, getParticipantName } from 'client/common/participant-names';
import CheckBox from 'client/components/Common/checkbox';
import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useAuth } from 'client/hooks/useAuth';
import NameBubble from 'client/components/Common/NameBubble';

import { Buttons, FieldForm } from '../my-account/modals-styled';
import { TeamBubble } from '../styled';

import * as Styled from './create-team-styled';

interface Props {
  onClose: () => void;
  preselectedTeam: string;
  confirmMove: (team: string) => void;
  teams: { id: string; name: string }[];
}

const MoveFileDialog: React.FC<Props> = ({ confirmMove, onClose, preselectedTeam, teams }) => {
  useHotkeys('esc', () => {
    onClose();
  });

  const [selectedTeam, setSelectedTeam] = useState(preselectedTeam ?? 'personal');

  const auth = useAuth();

  const teamsWithPersonal = [{ id: 'personal', name: 'Personal' }, ...teams].map((team) => (
    <Styled.MoveTeamItem key={team.id} onClick={() => setSelectedTeam(team.id)}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {team.id === 'personal' ? (
          <NameBubble displayName={auth.user?.displayName} />
        ) : (
          <TeamBubble color={team.name && getParticipantColor(team.name)}>{getParticipantName(team.name)}</TeamBubble>
        )}
        <div style={{ marginLeft: '12px' }}>{team.name}</div>
      </div>
      <CheckBox
        // TODO check other uses of the checkbox as it is now blue
        checked={team.id === selectedTeam}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedTeam(team.id);
          }
        }}
      />
    </Styled.MoveTeamItem>
  ));

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.Container>
        <Styled.CloseButton onClick={onClose} />
        <Styled.Title>Change Workspace</Styled.Title>
        <div style={{ marginBottom: '34px', fontSize: '13px', textAlign: 'center' }}>
          Your Board will be automatically shared to the members of the Workspace
        </div>
        <div style={{ overflowY: 'auto' }}>{teamsWithPersonal}</div>
        <div style={{ height: '50px' }} />
        <FieldForm>
          <Buttons>
            <button
              type="button"
              onClick={() => {
                onClose();
              }}
              className="cancel"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                confirmMove(selectedTeam);
              }}
            >
              Move
            </button>
          </Buttons>
        </FieldForm>
      </Styled.Container>
    </>,
    document.getElementById('modal-portal')
  );
};

export default MoveFileDialog;
