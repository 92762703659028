import React from 'react';

import { FileOrFileLink } from 'client/common/graphql';
import Dialog from 'client/components/Common/dialog';

import CastView from './CastView';

interface Props {
  file: FileOrFileLink;
  onCancel: () => void;
  stay: boolean;
}

const CastDialog: React.FC<Props> = ({ file, onCancel, stay }) => (
  <Dialog cover noButtons onCancel={() => onCancel()}>
    <CastView file={file} onCancel={onCancel} stay={stay} />
  </Dialog>
);

export default CastDialog;
