import React from 'react';
import styled from 'styled-components';

import { useSelector } from 'client/hooks/useSelector';

const Container = styled.span`
  display: grid;
  grid-template-columns: repeat(3, 2fr) 1fr repeat(3, 2fr);

  span {
    text-align: center;
  }
`;

const Pin: React.FC = () => {
  const { roomPin, session } = useSelector((state) => ({
    session: state.session,
    roomPin: state.roomPin,
  }));

  const pin = roomPin ?? session.pin;

  if (!pin) {
    return null;
  }

  const spacedPin = `${pin.slice(0, 3)} ${pin.slice(3)}`;

  return (
    <Container>
      {[...spacedPin].map((v, i) => (
        <span key={i}>{v}</span>
      ))}
    </Container>
  );
};

export default Pin;
