const clean = () => {
  if (!window.localStorage) {
    return;
  }

  const storage = window.localStorage.getItem('redux');

  if (storage === null) {
    return;
  }

  try {
    const parsed = JSON.parse(storage);
    /*
      Patch for 1.1.14-2018-12-19

      lastWorkingCode has changed from string to object and crashes the app.
      Solution:
        Remove the entry
    */
    if (parsed.lastWorkingCode && typeof parsed.lastWorkingCode !== 'object') {
      const copy = { ...parsed };
      delete copy.lastWorkingCode;

      window.localStorage.setItem('redux', JSON.stringify(copy));
    }
  } catch (e) {
    window.localStorage.clear();
  }
};

export default clean;
