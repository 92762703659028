import { createAction, createReducer } from '@reduxjs/toolkit';

export const enableClipBoarding = createAction('enableClipBoarding', (id: string, enable: boolean) => ({
  payload: { id, enable },
}));

const add = (state: string[], item: string) => (state.includes(item) ? state : [...state, item]);
const remove = (state: string[], item: string) => state.filter((i) => i !== item);

export default createReducer<string[]>([], (builder) =>
  builder.addCase(enableClipBoarding, (state, { payload: { enable, id } }) =>
    enable ? add(state, id) : remove(state, id)
  )
);
