import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Scrollspy from 'react-scrollspy';
import classNames from 'classnames';

import { useSelector } from 'client/hooks/useSelector';
import { QUERY_TEMPLATES } from 'client/common/graphql';
import { useActions } from 'client/hooks/useActions';

import { CreateBoardItem, CreateBoardLabel } from '../styled';

import * as Styled from './styled-template-gallery';

interface Props {
  modal?: boolean;
  onClose?: () => void;
  onSelect: (template: { teamId?: string; new: true } | { templateId: string; teamId: string; template: true }) => void;
  userRole: string;
  disabled?: boolean;
}

const TemplateGallery: React.FC<Props> = ({ modal, onClose, onSelect, userRole, disabled }) => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const searchInput = useRef<HTMLInputElement>();
  const location = useLocation<{ team: { id: string } }>();
  const { room } = useSelector((state) => ({
    room: state.room,
  }));
  const { disableClipBoarding } = useActions();

  const [getTemplates, { loading, data }] = useLazyQuery(QUERY_TEMPLATES);

  useEffect(() => {
    if (userRole) {
      getTemplates({
        variables: { input: { role: userRole } },
      });
    }
  }, [userRole]);

  useEffect(() => {
    disableClipBoarding('TemplateGallery', true);
    return () => {
      disableClipBoarding('TemplateGallery', false);
    };
  }, []);

  const templates = data?.templates || [];

  const handleSearch = useCallback(() => {
    setSearchQuery(searchInput.current.value);
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      const allTemplates = templates.flatMap((t) => t.templates);
      const filtered = allTemplates
        .filter((template) => template.name.toLowerCase().trim().includes(searchQuery.toLowerCase().trim()))
        .filter((value, index, self) => self.indexOf(value) === index);

      if (filtered.length) {
        setSearchResult(filtered);
      } else {
        setSearchResult([]);
      }
    } else {
      setSearchResult([]);
    }
  }, [searchQuery]);

  const teamId = location.state?.team?.id;

  useHotkeys(
    'enter',
    (e) => {
      e.preventDefault();
      if (onClose && !searchActive) {
        onSelect({ teamId, new: true });
      }
    },
    [searchActive]
  );

  useHotkeys(
    'esc',
    () => {
      if (searchActive) {
        if (searchInput.current?.value && searchInput.current.value.length) {
          searchInput.current.value = '';
          handleSearch();
        } else if (searchInput.current?.value.length === 0) {
          searchInput.current.blur();
        }
      } else if (onClose) {
        onClose();
      }
    },
    { enableOnTags: ['INPUT'] },
    [searchActive]
  );

  const createBoardItem = (template: { name: string; id: string; thumbnail: string }) => (
    <CreateBoardItem key={template.id} onClick={() => onSelect({ templateId: template.id, template: true, teamId })}>
      <Styled.CreateBoardThumbnailGallery
        style={template.thumbnail && { backgroundImage: `url(data:image/png;base64,${template.thumbnail})` }}
      />
      <CreateBoardLabel data-tip={`Template: ${template.name}`} data-place="top" data-delay-show={1000}>
        {template.name}
      </CreateBoardLabel>
    </CreateBoardItem>
  );

  const mainColumn = (
    <>
      {loading ? (
        <div>Loading templates&hellip;</div>
      ) : (
        <Styled.MainContent
          id={`template-gallery-scroll-area${modal ? '-modal' : ''}`}
          className={classNames({ 'full-height': !onClose, disabled, room: !!room })}
        >
          {templates.map((category, index) => (
            <div id={`category-${category.id}${modal ? '-modal' : ''}`} key={category.id}>
              <Styled.CategoryHeader className={classNames({ room: !!room })}>{category.name}</Styled.CategoryHeader>
              <Styled.CreateBoardListGallery>
                {index === 0 && (
                  <CreateBoardItem key="blank-item">
                    <Styled.CreateBoardBlankGallery id="start-blank" onClick={() => onSelect({ teamId, new: true })} />
                    <CreateBoardLabel data-tip="Blank Board" data-place="top" data-delay-show={1000}>
                      Blank Board
                    </CreateBoardLabel>
                  </CreateBoardItem>
                )}
                {category.templates.map((template) => createBoardItem(template))}
              </Styled.CreateBoardListGallery>
            </div>
          ))}
        </Styled.MainContent>
      )}
    </>
  );

  const searchResultColumn = (
    <>
      <Styled.MainContent
        id={`template-gallery-scroll-area${modal ? '-modal' : ''}`}
        className={classNames({ 'full-height': !onClose, room: !!room })}
      >
        <Styled.CategoryHeader className={classNames({ room: !!room })}>
          {searchResult.length === 0 && 'No Results'}
          {searchResult.length > 0 && `${searchResult.length} Results for "${searchQuery}"`}
        </Styled.CategoryHeader>

        <Styled.CreateBoardListGallery>
          {searchResult.length > 0 &&
            searchResult.map((template) => (
              <React.Fragment key={template.id}>{createBoardItem(template)}</React.Fragment>
            ))}
        </Styled.CreateBoardListGallery>
      </Styled.MainContent>
    </>
  );

  return (
    <>
      <Styled.NavColumn>
        {!room && (
          <Styled.SearchInput
            ref={searchInput}
            onFocus={() => setSearchActive(true)}
            onBlur={() => setSearchActive(false)}
            onChange={handleSearch}
            disabled={loading || disabled}
            placeholder="Search Templates&hellip;"
          />
        )}
        <Styled.NavColumScroll>
          {searchQuery ? (
            <>
              <div>
                {templates.map((category) => (
                  <span className="placeholder" key={category.name}>
                    {category.name}
                  </span>
                ))}
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <div>Loading categories&hellip;</div>
              ) : (
                <Scrollspy
                  items={templates.map((c) => `category-${c.id}${modal ? '-modal' : ''}`)}
                  componentTag="div"
                  currentClassName="is-current"
                  rootEl={`#template-gallery-scroll-area${modal ? '-modal' : ''}`}
                  offset={-100}
                >
                  {templates.map((category) => (
                    <HashLink
                      smooth
                      to={`${window.location.pathname}#category-${category.id}${modal ? '-modal' : ''}`}
                      key={category.id}
                    >
                      {category.name}
                    </HashLink>
                  ))}
                </Scrollspy>
              )}
            </>
          )}
        </Styled.NavColumScroll>
      </Styled.NavColumn>
      <Styled.MainColumn>
        {searchQuery ? searchResultColumn : mainColumn}
        {onClose && !room && (
          <Styled.MainColumnFooter>
            {!searchActive && (
              <div>
                Hit <strong>Enter</strong> to add a Blank page
              </div>
            )}
          </Styled.MainColumnFooter>
        )}
      </Styled.MainColumn>
    </>
  );
};

export default TemplateGallery;
