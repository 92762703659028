import { backOff } from 'exponential-backoff';
import * as log from 'loglevel';
import slug from 'slugid';
import naClUtil from 'tweetnacl-util';
import ZCanvas from '@flatfrog/ffbec';
import { ZImageLoadFlag } from '@flatfrog/ffbec/js/zwasm';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { getFirebaseAuth } from 'client/common/firebase';
import addClientPingLabel from 'client/common/pingLabelCanvas';
import { setSessionUrl } from 'client/common/urlHelper';
import { CONNECTION_STATUS, THUMBNAIL_SIZE } from 'client/common/util';
import * as rest from 'client/services/rest';
import * as actions from 'client/state/actions';
import serverPb, { Message, BatchEvent, BoardEvent, Image, RewindEvent } from 'client/proto/generated/collab_server_pb';
import { AppDispatch, RootState } from 'client/store';
import { Client } from 'client/state/session/session';
import commonPb from 'client/proto/generated/collab_common_pb';

const {
  createOnboardingDoneMsgBuffer,
  createBoardEventMsgBuffer,
  createUndoBoardEventMsgBuffer,
  bufferToSessionServerMessage,
  createImageMsgBuffer,
  createJoinMsgBuffer,
  createAnnouncePage,
  createPingBuffer,
  createForceFollow,
  createRequestPresenter,
  createYieldPresenter,
  createAddTemplatePage,
  createThumbnail,
  createSetReadOnly,
} = require('client/proto/proto-collab.js');

let workerEventListener: (
  event: MessageEvent<{
    ab: ArrayBuffer;
    sequence: number;
    code: string;
    action: string;
  }>
) => void;

const websocketworker = new Worker(new URL('./websocketworker.ts', import.meta.url), {
  type: 'module',
  name: 'websocketworker',
});

let websocketWorkerSequence = -1;

const websocketWorkerQueue: {
  ab: ArrayBuffer;
  sequence: number;
  code: string;
  action: string;
}[] = [];

let numStartEvents = 0;
let numStartImages = 0;
let onboardingCompleted = false;
let numberOfHostEventsReceived = 0;
let numberOfHostImageEventsReceived = 0;
let sessionResponsePromise: Promise<{
  actions: {
    ActionId: string;
    _prevActionId: string;
  }[];
}>;
let sessionResponsePromiseHandled = false;

let bufferedFollowPage: string | -1 = -1;
let bufferedBatches: Message[];

const clientId = slug.v4();

// events received out of order
let outOfOrderEvents: (
  | { batchEvent: BatchEvent; sequenceNum: number; senderId: string }
  | { sequencedEvent: BoardEvent | RewindEvent; sequenceNum: number; senderId: string }
)[] = [];

// "live" board events received will processing the initial events
let queuedDuringInitialEvents: { boardEvent: BoardEvent; sequenceNum: number }[] = [];
let bufferedImages: Image[] = [];
let lastReceivedSeqNumber = -1;
let sendingSeqNumber = 0;

let handleClose: (code: string) => void;

// Storage of actions/images spread over severall protobufs until final part has arrived
const multiStash: Record<string, string> = {};
const multiImages: Record<string, Uint8Array> = {};

const actOnSequencedEvent = (
  getState: () => RootState,
  dispatch: AppDispatch,
  sequencedEvent: BoardEvent | RewindEvent,
  initialLoadingDone: boolean
) => {
  const state = getState();
  const { actionListeners } = state;

  if ('getLatestCorrectActionId' in sequencedEvent) {
    const latestCorrectActionId = sequencedEvent.getLatestCorrectActionId();
    const preventRedo = sequencedEvent.getPreventRedo();
    dispatch(actions.setLatestActionId(latestCorrectActionId));
    actionListeners.forEach((listenerCallback) =>
      listenerCallback({ latestCorrectActionId, preventRedo }, initialLoadingDone)
    );
  } else {
    const data = sequencedEvent.getData() as Uint8Array;

    if (sequencedEvent.getContinue()) {
      if (!multiStash[sequencedEvent.getActionId()]) {
        multiStash[sequencedEvent.getActionId()] = naClUtil.encodeUTF8(data);
      } else {
        multiStash[sequencedEvent.getActionId()] += naClUtil.encodeUTF8(data);
      }
      return;
    }

    const json = JSON.parse((multiStash[sequencedEvent.getActionId()] || '') + naClUtil.encodeUTF8(data));
    delete multiStash[sequencedEvent.getActionId()];
    json._prevActionId = sequencedEvent.getPrevActionId();

    if (initialLoadingDone || numberOfHostEventsReceived >= numStartEvents) {
      dispatch(actions.setLatestActionId(json.ActionId));
    }
    actionListeners.forEach((listenerCallback) => listenerCallback({ json }, initialLoadingDone));
  }
};

const handleImage = (getState: () => RootState, image: Image) => {
  const imageData = image.getData() as Uint8Array;
  const { session } = getState();

  if (session.status !== CONNECTION_STATUS.connected) {
    log.debug('received image before start event, buffering');
    bufferedImages.push(image);
    return;
  }

  // non final part of multi part image, save and return
  const key = image.getFileName();

  if (!multiImages[key]) {
    multiImages[key] = imageData;
  } else {
    const prevData = multiImages[key];
    const data = new Uint8Array(prevData.length + imageData.length);
    data.set(prevData);
    data.set(imageData, prevData.length);
    multiImages[key] = data;
  }

  if (image.getContinue()) {
    return;
  }

  const data = multiImages[key] || null;
  delete multiImages[key];

  const paperId = image.getPaperId();
  const isInkImage = paperId !== undefined && paperId !== '';

  ZCanvas.history.addImage(key, data, paperId);

  ZCanvas.image.loadAsync({
    data,
    imageName: key,
    flags: isInkImage ? ZImageLoadFlag.MONOCHROME_TEXT : ZImageLoadFlag.NONE,
  });

  numberOfHostImageEventsReceived += 1;
  if (
    !onboardingCompleted &&
    numberOfHostEventsReceived === numStartEvents &&
    numberOfHostImageEventsReceived === numStartImages
  ) {
    onboardingCompleted = true;
    const protoBuff = createOnboardingDoneMsgBuffer();
    websocketworker.postMessage({ action: 'msg', msg: protoBuff });
  }
};

const handleBatchEvent = (getState: () => RootState, dispatch: AppDispatch, msg: Message) => {
  const batchEvent = msg.getBatchEvent();
  const senderId = batchEvent.getSenderId();
  const sequenceNum = batchEvent.getSeq();
  const boardEvents = batchEvent.getBoardEventList();
  console.log(`handleBatch new seq: ${sequenceNum} last saved: ${lastReceivedSeqNumber}`);

  if (onboardingCompleted) {
    console.log('--------- GOT BATCH BEFORE WE GOT A START!!!!! ------------');
  }

  if (sequenceNum !== lastReceivedSeqNumber + 1) {
    console.warn(`Received event out of order, saving, seq number:${sequenceNum}`);
    outOfOrderEvents.push({ batchEvent, sequenceNum, senderId });
    outOfOrderEvents.sort((a, b) => a.sequenceNum - b.sequenceNum);
  } else {
    // Normal
    lastReceivedSeqNumber += 1;
    boardEvents.forEach((boardEvent, index) =>
      actOnSequencedEvent(
        getState,
        dispatch,
        boardEvent,
        numberOfHostEventsReceived + boardEvents.length >= numStartEvents && index === boardEvents.length - 1
      )
    );
    numberOfHostEventsReceived += boardEvents.length;

    while (bufferedImages.length > 0) {
      const image = bufferedImages.shift();
      handleImage(getState, image);
    }

    // check if any old out of order events are now resolved.
    while (outOfOrderEvents.length > 0 && outOfOrderEvents[0].sequenceNum === lastReceivedSeqNumber + 1) {
      const event = outOfOrderEvents.shift();
      console.log(`Sending saved out of order event, still have ${outOfOrderEvents.length} events waiting`);

      if ('batchEvent' in event) {
        const boardEventsOutOfOrder = event.batchEvent.getBoardEventList();
        for (let i = 0; i < boardEventsOutOfOrder.length; i += 1) {
          const boardEvent = boardEventsOutOfOrder[i];

          const initialLoadingDone =
            numberOfHostEventsReceived + boardEventsOutOfOrder.length >= numStartEvents &&
            i === boardEventsOutOfOrder.length - 1;

          actOnSequencedEvent(getState, dispatch, boardEvent, initialLoadingDone);
        }
        numberOfHostEventsReceived += boardEventsOutOfOrder.length;
      } else {
        // Out of order boardevent should wait until finished processing batch events
        queuedDuringInitialEvents.push({ boardEvent: event.sequencedEvent as BoardEvent, sequenceNum });
      }

      lastReceivedSeqNumber += 1;
    }
  }
  if (numberOfHostEventsReceived >= numStartEvents) {
    console.log(
      `initial state done, received ${numberOfHostEventsReceived} events (still queued: ${queuedDuringInitialEvents.length})`
    );
    queuedDuringInitialEvents.sort((a, b) => a.sequenceNum - b.sequenceNum);
    queuedDuringInitialEvents.forEach((event) => {
      const existingAction = ZCanvas.history.actions.find(
        (historyAction) =>
          historyAction.ActionId === event.boardEvent.getActionId() &&
          historyAction._prevActionId === event.boardEvent.getPrevActionId()
      );

      if (existingAction) {
        // Mitigation for the race condition that if a board event is received at the server between the server sees a
        // new client and the host sees it, it will be sent twice to the joining client. Once as a normal board event
        // and once as a part of the onboarding. A better solution would be for the server to tell the host, what
        // action was considered the latest when the new client joined and the host could adapt the onboarding package
        // accordingly.
        console.log('doing nothing for buffered action, already applied');
      } else {
        console.log('applying buffered action');
        actOnSequencedEvent(getState, dispatch, event.boardEvent, false);
      }
    });
    queuedDuringInitialEvents = [];

    if (bufferedFollowPage !== -1 && getState().isFollower) {
      ZCanvas.enqueueServerCommand({ type: 'setCurrentPage', pageUuid: bufferedFollowPage });
      bufferedFollowPage = -1;
    }

    if (numberOfHostImageEventsReceived === numStartImages && !onboardingCompleted) {
      onboardingCompleted = true;
      const protoBuff = createOnboardingDoneMsgBuffer();
      websocketworker.postMessage({ action: 'msg', msg: protoBuff });
    }
  }
};

const handleImages = (getState: () => RootState, dispatch: AppDispatch, msg: Message) => {
  msg
    .getImages()
    .getImageList()
    .forEach((image) => handleImage(getState, image));
};

const handleSequencedEvent = (getState: () => RootState, dispatch: AppDispatch, msg: Message) => {
  let sequencedEvent: BoardEvent | RewindEvent = msg.getBoardEvent();
  if (!sequencedEvent) {
    sequencedEvent = msg.getRewindEvent();
  }
  const senderId = sequencedEvent.getSenderId();
  const sequenceNum = sequencedEvent.getSeq();
  log.info(`handleSequencedEvent: new seq ${sequenceNum}, last saved ${lastReceivedSeqNumber}`);

  if (sequenceNum !== lastReceivedSeqNumber + 1) {
    log.warn(`Received event out of order, saving, seq number:${sequenceNum}`);
    outOfOrderEvents.push({ sequencedEvent, sequenceNum, senderId });
    outOfOrderEvents.sort((a, b) => a.sequenceNum - b.sequenceNum);
  } else {
    lastReceivedSeqNumber += 1;
    // Start events still ongoing

    // If we havent received all start events or if we dont even know how many start events there are, wait with
    // normal board events
    if (numberOfHostEventsReceived < numStartEvents || numStartEvents === 0) {
      queuedDuringInitialEvents.push({ boardEvent: sequencedEvent as BoardEvent, sequenceNum });
    } else {
      // Normal
      actOnSequencedEvent(getState, dispatch, sequencedEvent, false);
      // check if any old out of order events are now resolved.
      while (outOfOrderEvents.length > 0 && outOfOrderEvents[0].sequenceNum === lastReceivedSeqNumber + 1) {
        const event = outOfOrderEvents.shift();

        if ('sequencedEvent' in event) {
          log.debug(`Sending saved out of order event, still have ${outOfOrderEvents.length} events waiting`);
          actOnSequencedEvent(getState, dispatch, event.sequencedEvent, false);
          lastReceivedSeqNumber += 1;
        }
      }
    }
  }
};

const handleThumbnailRequest = async () => {
  const firstPageId = ZCanvas.page.getIdFromIndex(0);
  if (!firstPageId) {
    // No page exists yet.
    return;
  }
  const imageData = ZCanvas.image.createFromPage(firstPageId, THUMBNAIL_SIZE.width, THUMBNAIL_SIZE.height);

  if (!imageData || imageData.height <= 0 || imageData.width <= 0 || !imageData.data) {
    // Likely a faulty thumbnail
    return;
  }

  const encodedImage = await ZCanvas.image.encodeJpeg({
    width: imageData.width,
    height: imageData.height,
    pixels: imageData.data,
    flipped: true,
  });

  if (websocketworker) {
    websocketworker.postMessage({ action: 'msg', msg: createThumbnail(new Uint8Array(encodedImage.data)) });
  }
};

export async function downloadSession(collaborationServerUrl: string, sessionId: string, sessionJoinKey: string) {
  // eslint-disable-next-line no-async-promise-executor
  sessionResponsePromise = new Promise(async (resolve) => {
    try {
      const startTime = Date.now();
      const response = await rest.makeRequest<{
        actions: {
          ActionId: string;
          _prevActionId: string;
        }[];
      }>({
        url: `${collaborationServerUrl}/v2/sessions/${sessionId}/content/${sessionJoinKey}`,
      });
      console.log('time to fetch actions (HTTP GET)', Date.now() - startTime, 'ms');
      resolve(response.data);
    } catch (e) {
      console.log('exception');
      console.log(e);
      resolve(null);
    }
  });
}

const getClientType = (type: number) => {
  switch (type) {
    case commonPb.ClientType.UWP:
      return 'uwp';
    case commonPb.ClientType.PHONE:
      return 'phone';
    case commonPb.ClientType.TABLET:
      return 'tablet';
    case commonPb.ClientType.ROOM:
      return 'room';
    case commonPb.ClientType.DESKTOP:
    default:
      return 'desktop';
  }
};

const convertClient = (client: commonPb.Client, self = false): Client => ({
  id: client.getId(),
  userId: client.getUserId(),
  name: client.getName(),
  clientType: getClientType(client.getClientType()),
  joinedAt: new Date(client.getJoinedAt() * 1000),
  self,
});

const handleMessage = (getState: () => RootState, dispatch: AppDispatch, msg: Message) => {
  const typeCase = msg?.getTypeCase();
  switch (typeCase) {
    case serverPb.Message.TypeCase.START: {
      log.debug('Got message [START]');

      const startMsg = msg.getStart();
      const client = startMsg.getMyself();
      const clients = startMsg.getParticipantList().map((c) => convertClient(c));
      numStartEvents = startMsg.getNumInitialEvents();
      numStartImages = startMsg.getNumInitialImages();
      log.debug(`session - start (events:${numStartEvents} images:${numStartImages})`);

      dispatch(actions.setSessionClients([...clients, convertClient(client, true)]));

      // To get rid of session ended dialog when casting a new board file to a room
      // TODO check if it is safe to always do
      dispatch(actions.toggleSessionEndedDialog(false));

      // TODO how to reset only for reconnect and
      // opening new file without unmounting the session/whiteboard/canvas
      const { session, actionListeners } = getState();
      // if (oldNumStartEvents > 0) {
      console.log('resetting ZCanvas from START msg');
      ZCanvas.reset();
      // }

      ZCanvas.beginPreloading();

      numberOfHostEventsReceived = 0;
      numberOfHostImageEventsReceived = 0;
      sessionResponsePromiseHandled = false;
      onboardingCompleted = false;

      dispatch(actions.setConnectedStatus(CONNECTION_STATUS.connected));
      setSessionUrl(session.joinKey);

      // Add ping labels for each client, we should be ready to it now
      getState().session.clients.forEach((c) => {
        addClientPingLabel(c.name).then((createdImage) => {
          dispatch(actions.setPingLabelForClient(c.id, createdImage));
        });
      });

      (async () => {
        const data = await sessionResponsePromise;
        if (!data) {
          dispatch(actions.sessionEnd({ errorMessage: 'Unable to fetch file content' }));
          return;
        }
        const serverActions = data.actions;
        serverActions.forEach((a, i) => {
          if (i > 0) {
            a._prevActionId = serverActions[i - 1].ActionId;
          }
        });
        actionListeners.forEach((listenerCallback) =>
          listenerCallback({ json: serverActions }, serverActions.length === numStartEvents)
        );
        numberOfHostEventsReceived = serverActions.length;
        dispatch(actions.setLatestActionId(serverActions[serverActions.length - 1].ActionId));

        if (numStartEvents === numberOfHostEventsReceived && numStartImages === numberOfHostImageEventsReceived) {
          onboardingCompleted = true;
          const protoBuff = createOnboardingDoneMsgBuffer();
          websocketworker.postMessage({ action: 'msg', msg: protoBuff });

          if (bufferedFollowPage !== -1 && getState().isFollower) {
            ZCanvas.enqueueServerCommand({ type: 'setCurrentPage', pageUuid: bufferedFollowPage });
            bufferedFollowPage = -1;
          }
        }

        sessionResponsePromiseHandled = true;

        // batch messages received over websocket while
        // processing the initial state downloaded over HTTP GET
        if (bufferedBatches) {
          bufferedBatches.forEach((message) => {
            handleBatchEvent(getState, dispatch, message);
          });
          bufferedBatches = undefined;
        }
      })();
      break;
    }
    case serverPb.Message.TypeCase.CLIENT_JOINED: {
      log.debug('Got message [CLIENT_JOINED]');

      const clientJoined = msg.getClientJoined();
      const client = clientJoined.getClient();

      dispatch(actions.addSessionClient(convertClient(client)));

      // TODO: It *might* be dangerous to add the label here if ZCanvas is not loaded, test and evaluate
      addClientPingLabel(client.getName()).then((imageObject) => {
        dispatch(actions.setPingLabelForClient(client.getId(), imageObject));
      });

      break;
    }
    case serverPb.Message.TypeCase.CLIENT_LEFT: {
      log.debug('Got message [CLIENT_LEFT]');

      const clientLeft = msg.getClientLeft();

      const { clients } = getState().session;
      const clientLeaving = clients.find((c) => c.id === clientLeft.getClientId());

      if (clientLeaving?.isPresenter) {
        dispatch(actions.setPresenter({ clientId: clientLeaving.id, isPresenter: false }));
        dispatch(actions.setForceFollow(false));
      }

      dispatch(actions.removeSessionClient(clientLeaving));
      break;
    }
    case serverPb.Message.TypeCase.END: {
      log.debug('Got message [END]');
      dispatch(actions.sessionEnd({}));
      break;
    }
    case serverPb.Message.TypeCase.IMAGE: {
      log.debug('Got message [IMAGE]');
      handleImage(getState, msg.getImage());
      break;
    }
    case serverPb.Message.TypeCase.IMAGES: {
      log.debug('Got message [IMAGES]');
      handleImages(getState, dispatch, msg);
      break;
    }
    case serverPb.Message.TypeCase.BATCH_EVENT: {
      log.debug('Got message [BATCH_EVENT]');
      // State the server loaded from file is now downloaded via HTTP GET (see downloadSession).
      // Any actions added in the current session is sent in BATCH messages over the websocket
      if (!sessionResponsePromiseHandled) {
        if (!bufferedBatches) {
          bufferedBatches = [];
        }
        bufferedBatches.push(msg);
      } else {
        handleBatchEvent(getState, dispatch, msg);
      }
      break;
    }
    case serverPb.Message.TypeCase.BOARD_EVENT: {
      log.debug('Got message [BOARD_EVENT]');
      handleSequencedEvent(getState, dispatch, msg);
      break;
    }
    case serverPb.Message.TypeCase.PIN_UPDATED: {
      log.debug('Got message [PIN_UPDATED]');
      const pinUpdated = msg.getPinUpdated();
      dispatch(actions.updateSessionPin(pinUpdated.getCode()));
      break;
    }
    case serverPb.Message.TypeCase.PING: {
      log.debug('Got message [PING]');
      const ping = msg.getPing();
      const [r, g, b] = ping.getColor() as Uint8Array;
      // PageId in proto is actually index. Rename later
      const pageId = ZCanvas.page.getIdFromIndex(Number(ping.getPageId()));

      if (ping.getPageId().toString() === ZCanvas.page.getCurrentIndex().toString()) {
        const state = getState();
        const client = state.session.clients.find((c) => c.id === ping.getSenderId());
        ZCanvas.writingAreaPing(
          pageId,
          ping.getX(),
          2160.0 - ping.getY(),
          [r / 255, g / 255, b / 255, 0.8],
          client.pingLabel.id
        );
      }

      break;
    }
    case serverPb.Message.TypeCase.ANNOUNCE_PAGE: {
      log.debug('Got message [ANNOUNCE_PAGE]');
      const pageAnnounce = msg.getAnnouncePage();
      const pageId = pageAnnounce.getPageId();
      dispatch(actions.setPageForClient(pageAnnounce.getSenderId(), pageId));

      if (
        (pageAnnounce.getIsPresenter() && getState().isFollower) ||
        (ZCanvas.page.getCurrentIndex() === -1 && pageAnnounce.getSenderId() === clientId)
      ) {
        if (numberOfHostEventsReceived >= numStartEvents) {
          bufferedFollowPage = -1;
          ZCanvas.enqueueServerCommand({ type: 'setCurrentPage', pageUuid: pageId });
        } else {
          bufferedFollowPage = pageId;
        }
      }
      break;
    }
    case serverPb.Message.TypeCase.REQUEST_PRESENTER: {
      log.debug('Got message [REQUEST_PRESENTER]');
      const presenterRequest = msg.getRequestPresenter();

      dispatch(actions.setPresenter({ clientId: presenterRequest.getSenderId() }));
      break;
    }
    case serverPb.Message.TypeCase.YIELD_PRESENTER: {
      log.debug('Got message [YIELD_PRESENTER]');
      const presenterYield = msg.getYieldPresenter();

      dispatch(actions.setPresenter({ clientId: presenterYield.getSenderId(), isPresenter: false }));
      break;
    }
    case serverPb.Message.TypeCase.FORCE_FOLLOW: {
      log.debug('Got message [FORCE_FOLLOW]');
      const forceFollow = msg.getForceFollow();

      dispatch(actions.setForceFollow(forceFollow.getOn()));
      break;
    }
    case serverPb.Message.TypeCase.SET_READ_ONLY: {
      log.debug('Got message [SET_READ_ONLY]');
      const setReadOnly = msg.getSetReadOnly();

      dispatch(actions.setReadOnly(setReadOnly.getOn()));
      break;
    }
    case serverPb.Message.TypeCase.AUTO_SAVE_STATUS: {
      log.debug('Got message [AUTO_SAVE_STATUS]');
      const autoSaveStatus = msg.getAutoSaveStatus();

      dispatch(
        actions.setAutoSaveStatus({
          success: autoSaveStatus.getSuccess(),
          savedAt: autoSaveStatus.getSavedAt(),
          fileName: autoSaveStatus.getFileName(),
          fileId: autoSaveStatus.getFileId(),
          actionId: autoSaveStatus.getActionId(),
          ownerId: autoSaveStatus.getOwnerId(),
        })
      );
      break;
    }
    case serverPb.Message.TypeCase.THUMBNAIL_REQUEST: {
      log.debug('Got message [THUMBNAIL_REQUEST]');
      if (!getState().showFilterSearchField) {
        handleThumbnailRequest();
      }
      break;
    }
    /*
    case serverPb.Message.TypeCase.HOST_MISSING: {
      dispatch(actions.toggleShowReconnectDialog(true));
      break;
    }
     */
    case serverPb.Message.TypeCase.REWIND_EVENT: {
      log.debug('Got message [REWIND_EVENT]');
      handleSequencedEvent(getState, dispatch, msg);
      break;
    }
    default:
      console.warn(`Unknown type ${msg?.getTypeCase()} msg received`);
      break;
  }
};

async function getSessionStatus(serverUrl: string, sessionId: string, token: string) {
  try {
    await rest.makeRequest({
      method: 'GET',
      url: `${serverUrl}/sessions/${sessionId}`,
      authorizationToken: { auth: token },
    });
    return 200;
  } catch (e) {
    if (e.response) {
      return e.response.status;
    }
    return undefined;
  }
}

const getName = (signatureText: string) => {
  const user = getFirebaseAuth().currentUser;

  if (user?.displayName) {
    return user.displayName;
  }

  return signatureText ?? window.electronApi.machineName;
};

const sendJoin = (name: string, room: boolean) => {
  const msg = createJoinMsgBuffer({ name, room });
  websocketworker.postMessage({ action: 'msg', msg });
};

async function connect(
  sessionServerUrl: string,
  sessionId: string,
  token: string,
  getState: () => RootState,
  dispatch: AppDispatch,
  resolve?: () => void,
  reject?: () => void
) {
  log.info('Connecting to websocket');
  if (workerEventListener) {
    websocketworker.removeEventListener('message', workerEventListener);
  }

  workerEventListener = (event) => {
    if (event.data.action) {
      switch (event.data.action) {
        case 'connected':
          if (resolve) {
            resolve();
            resolve = null;
            reject = null;
          } else {
            dispatch(actions.sessionSocketConnected());
            const { room, signatureText } = getState();

            sendJoin(getName(signatureText), room);
          }
          break;
        case 'closed':
          if (!reject) {
            handleClose(event.data.code);
          } else {
            reject();
          }
          break;
        default:
          console.error(`Unhandled message from worker: ${event.data.action}`);
          break;
      }
    } else if (event.data.sequence - websocketWorkerSequence === 1) {
      websocketWorkerSequence = event.data.sequence;
      const msg = bufferToSessionServerMessage(new Uint8Array(event.data.ab));
      handleMessage(getState, dispatch, msg);

      while (websocketWorkerQueue.length > 0 && websocketWorkerQueue[0].sequence - websocketWorkerSequence === 1) {
        websocketWorkerSequence = websocketWorkerQueue[0].sequence;
        const eventBuffered = websocketWorkerQueue.shift();
        const msgBuffered = bufferToSessionServerMessage(new Uint8Array(eventBuffered.ab));
        handleMessage(getState, dispatch, msgBuffered);
      }
    } else {
      websocketWorkerQueue.push(event.data);
      websocketWorkerQueue.sort((a, b) => a.sequence - b.sequence);
    }
  };

  websocketworker.addEventListener('message', workerEventListener);

  const { version } = getState();

  const reconnect = (newToken: string) =>
    new Promise<void>((resolve_, reject_) => {
      connect(sessionServerUrl, sessionId, newToken, getState, dispatch, resolve_, reject_);
    });

  log.info('Posting connect to websocket worker');
  websocketworker.postMessage({
    action: 'connect',
    url: `${sessionServerUrl}/${sessionId}?token=${token}&cid=${clientId}&clientVersion=${version}`,
  });
  lastReceivedSeqNumber = -1;
  websocketWorkerSequence = -1;
  sendingSeqNumber = 0;
  outOfOrderEvents = [];
  queuedDuringInitialEvents = [];
  bufferedImages = [];

  window.addEventListener('unload', () => {
    // Signal to the collab server that this was an
    // intentional close of the session
    websocketworker.postMessage({ action: 'disconnect', reason: 1000 });
  });

  ZCanvas.initialized.then(() => {
    ZCanvas.addEventListener('outOfSync', () => {
      websocketworker.postMessage({ action: 'disconnect', reason: 3333 });
    });
  });

  handleClose = async (code) => {
    const { collaborationServerUrl, session } = getState();
    let { token: currentToken } = session;
    const { joinKey } = session;

    const getIdToken = async () => {
      const user = getFirebaseAuth().currentUser;
      try {
        return user?.getIdToken();
      } catch (e) {
        console.error(e);
      }
      return null;
    };

    const getNewToken = async () => {
      try {
        const endPoint = `${collaborationServerUrl}/v2/join/session/${joinKey}`;
        const firebaseToken = await getIdToken();
        const response = await rest.makeRequest<{ token: string }>({
          method: 'GET',
          url: endPoint,
          authorizationToken: { auth: firebaseToken },
        });
        const { token: newT } = response.data;
        return newT;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    };

    const errorCode = Number.parseInt(code, 10);
    log.debug('Socket close', errorCode);
    // max number of participants reached
    if (errorCode === 4503) {
      dispatch(actions.sessionEnd({ errorMessage: 'Maximum number of participants in a session reached.' }));
    } else if (errorCode === 4426) {
      dispatch(actions.sessionEnd({ errorMessage: 'Your client version is outdated!' }));
    } else if (errorCode !== 1000) {
      console.log(' - trying to reconnect');
      dispatch(actions.toggleShowReconnectDialog(true));
      // Reset any force follow state, updated state will be communicated by server if reconnected
      dispatch(actions.setForceFollow(false));
      try {
        await backOff(() => reconnect(currentToken), {
          delayFirstAttempt: true,
          startingDelay: 250,
          numOfAttempts: 150,
          timeMultiple: 1.2,
          maxDelay: 3000,
          retry: async () => {
            const sessionStatus = await getSessionStatus(collaborationServerUrl, sessionId, currentToken);
            if (sessionStatus === 401) {
              const nT = await getNewToken();
              if (nT) {
                currentToken = nT;
              } else {
                return false;
              }
            }
            const sessionDeleted = sessionStatus === 404;

            // Returning false here stops the reconnect attempts
            return !sessionDeleted && !!session && !!session.id;
          },
        });
        downloadSession(collaborationServerUrl, session.id, session.joinKey);
        setTimeout(() => {
          dispatch(actions.sessionSocketConnected());
          sendJoin(getName(getState().signatureText), getState().room);
        }, 100);
      } catch (e) {
        const { loadedFileInfo } = getState();
        if (loadedFileInfo?.id) {
          // Session is connected to a file. Try connecting to a new session based on the file
          dispatch(actions.tryConnectNew({ fileInfo: { id: loadedFileInfo?.id }, reconnecting: true }));
        } else {
          // Did not manage to reconnect in several tries. give up and show disconnect dialog
          numStartEvents = 0;
          numStartImages = 0;
          dispatch(actions.toggleShowReconnectDialog(false));
          dispatch(actions.sessionSocketDisconnection());
        }
      }
    }
  };
}

const middleware = createListenerMiddleware<RootState>();

middleware.startListening({
  actionCreator: actions.disconnectSessionSocket,
  effect: () => {
    log.debug('Got action [DISCONNECT_SESSION_SOCKET]');
    if (websocketworker) {
      websocketworker.postMessage({ action: 'disconnect', reason: 1000 });
    }
    numStartEvents = 0;
    numStartImages = 0;
  },
});

middleware.startListening({
  actionCreator: actions.connectSessionSocket,
  effect: (action, api) => {
    const state = api.getState();
    const { collaborationServerUrl, session } = state;

    const { id: sessionId, token } = session;
    if (!sessionId) {
      return;
    }
    const wsUrl =
      collaborationServerUrl.toLowerCase().indexOf('https') === 0
        ? `wss${collaborationServerUrl.substring('https'.length)}`
        : `ws${collaborationServerUrl.substring('http'.length)}`;

    connect(wsUrl, sessionId, token, api.getState, api.dispatch as AppDispatch);
  },
});

middleware.startListening({
  actionCreator: actions.sendBoardEvent,
  effect: (action) => {
    const msg = createBoardEventMsgBuffer({ seq: sendingSeqNumber, ...action.payload });
    websocketworker.postMessage({ action: 'msg', msg });
    sendingSeqNumber += 1;
  },
});

middleware.startListening({
  actionCreator: actions.sendImage,
  effect: (action) => {
    const msg = createImageMsgBuffer(action.payload);
    websocketworker.postMessage({ action: 'msg', msg });
  },
});

middleware.startListening({
  actionCreator: actions.undo,
  effect: (action) => {
    const msg = createUndoBoardEventMsgBuffer({ seq: sendingSeqNumber, ...action.payload });
    websocketworker.postMessage({ action: 'msg', msg });
    sendingSeqNumber += 1;
  },
});

middleware.startListening({
  actionCreator: actions.addActionListener,
  effect: (action, api) => {
    const pageAddedListener = () => {
      if (bufferedFollowPage !== -1 && api.getState().isFollower) {
        const pageIndex = ZCanvas.page.getIndexFromUuid(bufferedFollowPage);
        if (pageIndex !== -1) {
          ZCanvas.page.setCurrentIndex(pageIndex);
          bufferedFollowPage = -1;
        }
      }
    };
    ZCanvas.addEventListener('PAGE_ADDED', pageAddedListener);
  },
});

middleware.startListening({
  actionCreator: actions.sendPing,
  effect: (action) => {
    const msg = createPingBuffer(action.payload);
    websocketworker.postMessage({ action: 'msg', msg });
  },
});

middleware.startListening({
  actionCreator: actions.sendAnnouncePage,
  effect: (action, api) => {
    const { clients } = api.getState().session;
    if (!clients) {
      return;
    }
    const self = clients.find((c) => c.self);

    websocketworker.postMessage({
      action: 'msg',
      msg: createAnnouncePage({
        pageId: action.payload,
        isPresenter: self.isPresenter,
      }),
    });
  },
});

middleware.startListening({
  actionCreator: actions.sendRequestPresenter,
  effect: () => {
    websocketworker.postMessage({ action: 'msg', msg: createRequestPresenter() });
  },
});

middleware.startListening({
  actionCreator: actions.sendYieldPresenter,
  effect: () => {
    websocketworker.postMessage({ action: 'msg', msg: createYieldPresenter() });
  },
});

middleware.startListening({
  actionCreator: actions.sendForceFollow,
  effect: (action) => {
    websocketworker.postMessage({ action: 'msg', msg: createForceFollow(action.payload.on) });
  },
});

middleware.startListening({
  actionCreator: actions.sendSetReadOnly,
  effect: (action) => {
    websocketworker.postMessage({
      action: 'msg',
      msg: createSetReadOnly(action.payload.on),
    });
  },
});

middleware.startListening({
  actionCreator: actions.addTemplatePage,
  effect: (action) => {
    websocketworker.postMessage({
      action: 'msg',
      msg: createAddTemplatePage(action.payload),
    });
  },
});

middleware.startListening({
  actionCreator: actions.downloadSession,
  effect: ({ payload: { sessionId, sessionJoinKey } }, { getState }) => {
    const state = getState() as RootState;

    return downloadSession(state.collaborationServerUrl, sessionId, sessionJoinKey);
  },
});

export default middleware.middleware;
