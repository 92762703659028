import styled from 'styled-components';

export const FullWidthWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  pointer-events: none;
  animation: slide-in--search-field 300ms ease;

  @keyframes slide-in--search-field {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  gap: 10px;
  max-width: min(720px, 50%);
`;

export const VerticalStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FollowerInfo = styled.div`
  position: relative;
  padding: 10px;
  top: 10px;
  width: 620px;
  text-align: center;
  border-radius: 10px;
  background: #27b5f3;
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  animation: fade-in--search-field 300ms ease;

  @keyframes fade-in--search-field {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SearchFieldContainer = styled.div`
  pointer-events: all;
  position: relative;
  height: 48px;
  display: flex;
  flex-direction: row;
  user-select: none;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #27b5f3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  align-items: center;

  &.focused {
    border: 2px solid #279ae7;
    box-shadow: 0 4px 12px rgba(39, 154, 231, 0.24);
  }

  &.shaking {
    animation: shake--search-field 300ms ease-in-out 0s 2;
    border: 2px solid #279ae7;
    box-shadow: 0 4px 12px rgba(39, 154, 231, 0.24);
  }

  @keyframes shake--search-field {
    0% {
      transform: translate(0px);
    }
    25% {
      transform: translate(-25px);
    }
    75% {
      transform: translate(10px);
    }
    100% {
      transform: translate(0px);
    }
  }
`;

export const MoreOptionsContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 0 0 0.5px rgba(0, 0, 0, 0.08);
  margin-top: 8px;
  padding: 16px;
  transition: transform 0.2s;
  transform-origin: top;

  &.open {
    pointer-events: all;
    transform: scale(1, 1);
  }

  &.closed {
    pointer-events: none;
    transform: scale(1, 0);
  }
`;

export const MoreOptionsHeader = styled.div`
  width: 100%;
  font-size: 13px;
  color: #8c8c8c;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const MoreOptionsCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 11px;
  color: #8c8c8c;
  font-style: italic;
  &.no-items {
    margin-bottom: 8px;
  }
`;

export const MultiSelectTip = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  font-size: 11px;
  color: #8c8c8c;
`;

export const OptionContainer = styled.div`
  padding: 4px;
  margin-left: 2px;
  border-radius: 4px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  img {
    height: 32px;
    width: 32px;
  }
`;

export const ColorOption = styled.div`
  width: 32px;
  height: 32px;
  box-shadow: 0 0 0 3px #00000000, inset 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

export const ChosenFiltersContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const ChosenFilterContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.08);
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-left: 4px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  img {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
`;

export const ChosenFilterOverlay = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: rgba(210, 210, 210, 0.68) url(/icons/icn_close_small.svg) center center no-repeat;
  opacity: 0;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 1;
  }
`;

export const ChosenColorFilter = styled.div`
  position: absolute;
  margin: 4px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  box-shadow: 0 0 0 3px #00000000, inset 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

export const ChosenFilterOverflowIndicator = styled.div`
  width: 24px;
  height: 24px;
  margin: 4px;
  padding-right: 4px;
  text-align: center;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.08);
  font-size: 12px;
  line-height: 24px;
  font-style: italic;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.16);
  }
`;

export const SearchButton = styled.div`
  background: transparent url(/icons/icn_search_dropdown.svg) center center no-repeat;
  background-size: 32px;
  width: 48px;
  height: 32px;
  margin: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
`;

export const SearchField = styled.input.attrs(() => ({
  type: 'text',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  spellCheck: false,
  placeholder: 'Search All, Esc to close.',
}))`
  flex-grow: 1;
  display: block;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  border: none;
  color: #8c8c8c;
  outline: none;
  width: 100%;
  margin-left: 8px;

  &::placeholder {
    color: #8c8c8c;
    opacity: 0.5;
    font-style: italic;
    font-weight: 400;
    text-transform: none;
  }

  &:focus {
    color: #242424;
  }
`;

export const PagesTextField = styled.div`
  font-size: 13px;
  text-align: right;
  color: #8c8c8c;
  white-space: nowrap;
  flex-grow: 1;
`;

export const Delimiter = styled.div`
  margin: 0 8px;
  height: 24px;
  width: 1px;
  background-color: #00000028;
  flex-shrink: 0;
`;

export const ExitFilterButton = styled.div`
  margin-right: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: transparent url(/icons/icn_close_small.svg) center center no-repeat;
  border-radius: 4px;
  transition: background-color 300ms ease-out;
  flex-shrink: 0;

  &:hover {
    background-color: #00000014;
  }
`;

export const NextPageButton = styled(ExitFilterButton)`
  margin-right: 4px;
  background: transparent url(/icons/icn_page_back.svg) center center no-repeat;
  &.disabled {
    opacity: 0.3;
  }
`;

export const PreviousPageButton = styled(NextPageButton)`
  background: transparent url(/icons/icn_page_forward.svg) center center no-repeat;
`;

export const SelectResultButton = styled.div`
  pointer-events: all;
  white-space: nowrap;
  margin-top: 8px;
  width: 100px;
  height: 32px;
  background-color: #fff;
  color: #2287ca;
  font-size: 13px;
  font-weight: 600;
  border-radius: 16px;
  box-shadow: 0 4px 12px #00000029, 0 0 0 0.5px #00000014;
  text-align: center;
  padding: 6px 12px;
  cursor: pointer;
  animation: fade-in--select-result-button 300ms ease;

  @keyframes fade-in--select-result-button {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &:hover {
    background-color: #ebebeb;
  }
`;
