import { createAction, createReducer } from '@reduxjs/toolkit';

export type LoadedFileInfo = {
  fileName?: string;
  id?: string;
  success?: boolean;
  latestLocalActionId?: string;
  latestServerActionId?: string;
  linkUrl?: string;
  ownerId?: string;
  new?: true;
  template?: true;
};

export const setLoadedFileInfo = createAction<
  | { id: string }
  | { teamId?: string; new: true }
  | { templateId: string; teamId?: string; template: true }
  | { linkId: string; link: true; linkUrl: string }
  | null
>('setLoadedFileInfo');

export const setAutoSaveStatus = createAction<{
  success?: boolean;
  savedAt?: number;
  fileName: string;
  fileId?: string;
  actionId?: string;
  ownerId?: string;
}>('setAutoSaveStatus');
export const setLatestActionId = createAction<string>('setLatestActionId');
export const sendBoardEvent = createAction<{
  data: Uint8Array;
  actionId: string;
  prevActionId: string;
  continue: boolean;
}>('sendBoardEvent');

export default createReducer<LoadedFileInfo | null>(null, (builder) =>
  builder
    .addCase(setLoadedFileInfo, (state, action) => action.payload)
    .addCase(setAutoSaveStatus, (state, { payload }) => ({
      ...state,
      fileName: payload.fileName || state?.fileName,
      id: payload.fileId || state?.id,
      ownerId: payload.ownerId || state?.ownerId,
      latestServerActionId: payload.actionId || state?.latestServerActionId,
      success: payload.success,
      savedAt: payload.savedAt,
      link: undefined,
    }))
    .addCase(setLatestActionId, (state, action) => ({
      ...state,
      latestLocalActionId: action.payload,
    }))
    .addCase(sendBoardEvent, (state, action) => ({
      ...state,
      latestLocalActionId: action.payload.actionId,
    }))
);
