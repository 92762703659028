import styled from 'styled-components';

import { FloatingPanel } from 'client/components/Common/common-styles';
import { StickyNoteSideButton } from 'client/components/ItemStack/styled';

export const SideBarWrapper = styled.div.attrs((props) => ({
  style: props.hidden ? { opacity: 0 } : {},
}))`
  ${FloatingPanel};

  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  width: 56px;

  &.room {
    flex-direction: row;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: auto;
    height: 56px;
    border-radius: 7px 7px 0 0;

    > div {
      margin: 0 !important;
      display: flex;

      > div {
        display: flex;
      }
    }

    ${StickyNoteSideButton} {
      margin: 0 4px 0 4px;
    }

    .double-button {
      height: 40px;
      width: auto;

      ${StickyNoteSideButton} {
        width: 40px;
        height: 40px;

        &:nth-child(1) {
          transform: rotate(-90deg);
        }

        &:nth-child(2) {
          transform: rotate(90deg);
        }
      }
    }
  }
`;

export const SingleItemContainer = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid #00000019;
  border-radius: 7px;
  box-shadow: 0 4px 8px #00000026;
  font-size: 14px;
  text-align: left;
  cursor: initial;
  bottom: 0;
  left: 42px;
  display: flex;
  flex-direction: column;
  user-select: none;
  z-index: 5;

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #d2d2d2;
    border-width: 9px;
    margin-top: -9px;
  }
`;

export const ToolTrayContainer = styled.div<{ down?: boolean; showActivePen: boolean }>`
  position: absolute;
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0 4px 8px #00000029, 0 0 0 0.5px #00000014;
  font-size: 14px;
  text-align: left;
  cursor: initial;
  min-height: 60px;
  bottom: calc(${(props) => (!props.down ? '-8px' : 'auto')} - ${(props) => (props.showActivePen ? '57px' : '0px')});
  left: 56px;
  display: flex;
  flex-direction: column;
  user-select: none;
  z-index: 5;

  &:after,
  &:before {
    right: 100%;
    top: calc(
      ${(props) => (!props.down ? '100% - 28px' : '22px')} - ${(props) => (props.showActivePen ? '57px' : '0px')}
    );
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #d2d2d2;
    border-width: 9px;
    margin-top: -9px;
  }
`;

export const ToolTrayGrid = styled.div`
  padding: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  overflow: hidden;
  transition: max-height 400ms ease;
  transition: height 400ms ease;

  div:nth-child(3n) {
    margin-right: 0;
  }
`;

export const TrayTitle = styled.span`
  text-transform: capitalize;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin: 8px 8px 8px 12px;
  color: #242424;
`;

export const CloseTray = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 12px;
  background: url(/icons/icn_close_small.svg) center center no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  background-size: 12px;
`;

export const TrayDivider = styled.div`
  background: #000000;
  opacity: 0.2;
  height: 1px;

  &.stickers {
    margin: 0 -10px;
  }
`;

export const ActivePenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px auto;
`;

export const ActivePenIcon = styled.div`
  padding: 12px;
  margin-right: 10px;
  background: transparent url(/icons/icn_pen_active.svg) center center no-repeat;
`;

export const StackButton = styled.div`
  width: 40px;
  height: 40px;
  background: url(/icons/icn_myitems.svg) center center no-repeat;
  cursor: pointer;
  position: relative;
  margin: 2px 0;
  border-radius: 5px;
  transition: background-color 200ms ease-out;

  &:hover {
    background-color: #e6e6e6;

    > span {
      border-color: #e6e6e6;
    }
  }

  > span {
    position: absolute;
    left: 24px;
    background: #787878;
    min-width: 18px;
    height: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    border-radius: 8px;
    padding: 3px;
    border: 2px solid #fafafa;
    text-align: center;
    user-select: none;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  width: 32px;
  margin: 6px auto;
`;
