import React from 'react';
import { isMobileOnly } from 'react-device-detect';

import MobileTopBar, { MobilePrimaryButton } from 'client/components/Auth/MobileTopBar';
import * as Styled from 'client/components/Auth/styled';

interface Props {
  footer: JSX.Element;
  mainContent: JSX.Element;
  upperMainContent?: JSX.Element;
  lowerMainContent?: JSX.Element;
  topBarItems: JSX.Element;
  mobileTopBarButton: JSX.Element;
}

const Layout: React.FC<Props> = ({
  footer,
  lowerMainContent,
  mainContent,
  mobileTopBarButton,
  topBarItems,
  upperMainContent,
}) => {
  return (
    <>
      <Styled.FormWrapper>
        {isMobileOnly && (
          <MobileTopBar>
            <MobilePrimaryButton>{mobileTopBarButton}</MobilePrimaryButton>
          </MobileTopBar>
        )}

        {!isMobileOnly && <Styled.TopBar>{topBarItems}</Styled.TopBar>}

        <Styled.MainContainer>
          <Styled.SharedLinkInfo>{upperMainContent}</Styled.SharedLinkInfo>
          <Styled.BoardCodeForm>{mainContent}</Styled.BoardCodeForm>
          <Styled.HelpLinks>{lowerMainContent}</Styled.HelpLinks>
        </Styled.MainContainer>
        {footer}
      </Styled.FormWrapper>
    </>
  );
};

export default Layout;
