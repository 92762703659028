import styled from 'styled-components';

export const Thumbnail = styled.img`
  height: 80px;
  width: calc(80px * 1.77);
  margin: 0 auto 24px 0;
  box-shadow: 0 2px 6px #00000029;
  border: 0.5px solid #00000014;
  border-radius: 4px;
  background: url(/images/ill_thumbnail_placeholder.svg) center center no-repeat;
  background-size: contain;
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: #242424;
  display: block;
  margin-bottom: 8px;
`;

export const FileName = styled.span`
  color: #8c8c8c;
  font-size: 13px;
  display: block;
  margin: 0;
  padding: 0;
`;

export const InfoText = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-bottom: 16px;
`;

export const CodeWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;

  & + input {
    position: absolute;
    opacity: 0.01;
    color: white;
    border: none;
  }
`;

export const CodeCharInput = styled.div`
  width: 40px;
  height: 56px;
  font-size: 41px;
  font-weight: 400;

  background: url(/icons/icn_room_code_code.svg) center center no-repeat;

  &:nth-child(3) {
    margin-right: 16px;
  }

  &.has-char {
    background: transparent;
  }

  &.active {
    background: url(/icons/icn_room_code_code_next.svg) center center no-repeat;
  }
`;

export const Wrapper = styled.div`
  padding: 24px 16px;
`;

export const Error = styled.span`
  color: red;
  font-size: 14px;
  margin: 0 auto;
`;

export const OpenButton = styled.button.attrs({
  type: 'button',
})`
  border: none;
  outline: none;
  display: block;
  margin: 0;
  width: 128px;
  height: 56px;
  background: #279ae7;
  color: #fff;
  border-radius: 40px;
  cursor: pointer;

  &:hover {
    background: #2287ca;
  }

  &:disabled {
    opacity: 32%;
  }
`;
