import React from 'react';

import { useAppServerUrlWithTenant } from 'client/hooks/useAppServerUrlWithTenant';

export const AppServerUrl: React.FC<{ path?: string }> = ({ path }) => {
  const hostname = useAppServerUrlWithTenant();
  return (
    <b>
      {hostname}
      {path}
    </b>
  );
};
