import FontFaceObserver from 'fontfaceobserver';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { TextAlignments } from 'client/components/Common/text-alignments';
import { useActions } from 'client/hooks/useActions';

import { BackgroundColors, ForegroundColors } from './colors';
import { EditInfo, StickyNoteCanvas, StickyNoteWrapper } from './styled';

// The side length of the sticky note paper
export const STICKY_NOTE_SIDE = 300;
// The side length of the sticky note canvas.current. Larger than paper to accomodate scaling
const STICKY_NOTE_CANVAS_SIDE = 720;
export const STICKY_NOTE_SCALE_FACTOR = STICKY_NOTE_SIDE / STICKY_NOTE_CANVAS_SIDE;
// The default font size on the canvas.current. Divide by scale factor to get default font size on screen.
export const STICKY_NOTE_DEFAULT_FONT_SIZE = 192;

export const getStickyNoteTemplate = (content = '', signature = '') => ({
  type: 'StickyNote',
  content,
  backgroundColor: BackgroundColors[0].color,
  textColor: ForegroundColors[0],
  textSize: STICKY_NOTE_DEFAULT_FONT_SIZE,
  textAlign: TextAlignments.Left,
  signature,
});

interface Props {
  id?: string;
  color: string;
  textColor: string;
  textAlign: string;
  textSize: number;
  signature: string;
  content: string;
  preview?: boolean;
  isSent?: boolean;
  link?: string;
  desiredTextSize?: number;
  onAdjustTextSize?: (textSize: number, cappedSize: boolean) => void;
  canvasRef?: MutableRefObject<HTMLCanvasElement>;
}

const paddingX = 48;
const paddingY = 72;

const StickyNote: React.FC<Props> = ({
  canvasRef,
  color,
  content,
  desiredTextSize,
  id,
  isSent,
  onAdjustTextSize,
  preview,
  signature,
  textAlign,
  textColor,
  textSize,
}) => {
  const [edited, setEdited] = useState(false);

  const canvas = canvasRef ?? useRef<HTMLCanvasElement>();
  const { assignItemCanvas, currentStickyNoteEdit } = useActions();

  useEffect(() => {
    if (canvas.current) {
      setEdited(content?.length > 0);
      assignItemCanvas(id, canvas.current);
      updateCanvas();
      const font = new FontFaceObserver('Caveat');
      font.load().then(updateCanvas);
      if (window.history && window.history.pushState) {
        window.addEventListener(
          'popstate',
          (e) => {
            e.stopPropagation();
            currentStickyNoteEdit(null);
          },
          false
        );
      }
    }
  }, [canvas]);

  useEffect(() => {
    updateCanvas();
  });

  useEffect(() => {
    if (content?.length > 0 && !edited) {
      setEdited(true);
    }
  }, [content]);

  const handleToggleEditor = () => {
    currentStickyNoteEdit({ id });
    window.history.replaceState(null, document.title, window.location.href);
    window.history.pushState(null, document.title, window.location.href);
  };

  const updateCanvas = () => {
    if (canvas.current) {
      canvas.current.width = STICKY_NOTE_CANVAS_SIDE;
      canvas.current.height = STICKY_NOTE_CANVAS_SIDE;
      const ctx = canvas.current.getContext('2d');
      const renderWidth = STICKY_NOTE_CANVAS_SIDE - 2 * paddingX;
      const renderHeight = STICKY_NOTE_CANVAS_SIDE - 2 * paddingY;
      let renderTextSize = desiredTextSize ?? textSize;
      let renderLines;
      if (content?.length > 0) {
        const lines = content.split(/\r?\n/);
        const lineData: ({ chunk: string; whitespace: boolean } | { break: true })[] = [];
        lines.forEach((line, index, arr) => {
          const chunks = line.split(/(\s+)/);
          chunks.forEach((chunk) => {
            if (chunk.length > 0) {
              lineData.push({ chunk, whitespace: chunk[0].match(/\s/) !== null });
            }
          });
          if (index < arr.length - 1) {
            lineData.push({ break: true });
          }
        });

        // eslint-disable-next-line no-constant-condition
        while (true) {
          // Check if we will overflow and should try a smaller text size
          if (lines.length * renderTextSize < renderHeight) {
            // The content may fit depending on the line lengths
            renderLines = [];
            let line: { width: number; chunks: string[] } = { width: 0, chunks: [] };
            // Measure all words and gaps
            ctx.font = `${renderTextSize}px Caveat`;
            const spaceWidth = ctx.measureText(' ').width;
            let hasBreaked = false;
            for (let l = 0; l < lineData.length; l += 1) {
              const item = { ...lineData[l] };
              if ('break' in item && item.break) {
                // Check if we will overflow and should try a smaller text size
                if ((renderLines.length + 2) * renderTextSize > renderHeight) {
                  hasBreaked = true;
                  break;
                }
                // Push the line and start a new
                renderLines.push({ content: `${line.chunks.join('')}\n`, width: line.width });
                line = { width: 0, chunks: [] };
              } else if ('whitespace' in item && item.whitespace) {
                // Don't bother measuring whitespace, it is assumed to have fixed length
                line.width += item.chunk.length * spaceWidth;
                if (line.width > renderWidth) {
                  // If the whitespace overflows, cap the line width so alignment works well
                  line.width = renderWidth;
                }
                line.chunks.push(item.chunk);
              } else if ('whitespace' in item) {
                // A word, measure item
                const widths = item.chunk.split('').map((ch) => ctx.measureText(ch).width);
                let width = widths.reduce((a, b) => a + b, 0);
                while (line.width + width > renderWidth) {
                  // The word will not fit on the current line but must be moved to the next
                  // First check if we will overflow and should try a smaller text size
                  // Note that we need to fit two more lines
                  if ((renderLines.length + 2) * renderTextSize > renderHeight) {
                    hasBreaked = true;
                    break;
                  }
                  if (line.width > 0) {
                    // Push the line and start a new
                    renderLines.push({ content: `${line.chunks.join('')}\n`, width: line.width });
                    line = { width: 0, chunks: [] };
                  }
                  // Start a new line with as much as possible of the word
                  // Figure out how many characters we can take
                  let charsCount = 0;
                  let overflows = false;
                  const charsWidth = widths.reduce((accumulated, charWidth) => {
                    if (!overflows) {
                      if (accumulated + charWidth <= renderWidth) {
                        charsCount += 1;
                        return accumulated + charWidth;
                      }
                      overflows = true;
                    }
                    return accumulated;
                  }, 0);
                  if (overflows) {
                    // NOTE: When we break here we enforce smaller font size
                    // when a word is longer than a line.
                    // If we don't break, the word will be cut (without hyphenation)
                    hasBreaked = true;
                    break;
                  }
                  line.width += charsWidth;
                  line.chunks.push(item.chunk.substring(0, charsCount));
                  item.chunk = item.chunk.substring(charsCount);
                  widths.splice(0, charsCount);
                  width -= charsWidth;
                }
                if (hasBreaked) {
                  break;
                }
                // The word will fit on the current line, so add it
                line.width += width;
                line.chunks.push(item.chunk);
              }
            }
            // Flush the line
            renderLines.push({ content: `${line.chunks.join('')}\n`, width: line.width });
            if (!hasBreaked) {
              // We fit everything in
              break;
            }
          }
          // Reduce the size and try again
          renderTextSize *= 0.9;
        }
      }
      if (onAdjustTextSize) {
        onAdjustTextSize(renderTextSize, renderTextSize < (desiredTextSize ?? textSize));
      }
      ctx.lineWidth = 2;
      ctx.fillStyle = textColor;
      if (signature?.length > 0) {
        ctx.font = '72px Caveat';
        ctx.textBaseline = 'alphabetic';
        ctx.textAlign = 'right';
        ctx.fillText(signature, canvas.current.width - 40, canvas.current.height - 28);
      }
      if (renderLines) {
        ctx.font = `${renderTextSize}px Caveat`;
        ctx.textBaseline = 'bottom';
        ctx.textAlign = 'left';
        renderLines.forEach((line, index) => {
          let x = paddingX;
          switch (textAlign) {
            case TextAlignments.Center:
              x += (renderWidth - line.width) / 2;
              break;
            case TextAlignments.Right:
              x += renderWidth - line.width;
              break;
            default:
              break;
          }
          ctx.fillText(line.content, x, paddingY + (index + 1) * renderTextSize);
        });
      }
    }
  };

  return (
    <StickyNoteWrapper className={isMobile ? 'mobile' : ''} width={STICKY_NOTE_SIDE} height={STICKY_NOTE_SIDE}>
      {preview ? (
        <StickyNoteCanvas ref={canvas} color={color} />
      ) : (
        <StickyNoteCanvas
          id={`sticky-note-canvas-${id}`}
          fade={isSent}
          onClick={handleToggleEditor}
          ref={canvas}
          color={color}
        />
      )}
      {edited || preview ? null : <EditInfo>Click to edit</EditInfo>}
    </StickyNoteWrapper>
  );
};

export default StickyNote;
