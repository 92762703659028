import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { resetSession } from 'client/state/session/session';
import { toggleSessionEndedDialog } from 'client/state/views/showSessionEndedDialog';

export const sessionSocketConnected = createAction('sessionSocketConnected');
export const sessionSocketDisconnection = createAsyncThunk('sessionSocketDisconnection', (_, { dispatch }) => {
  dispatch(toggleSessionEndedDialog(true));
  dispatch(resetSession());
});

export default createReducer(false, (builder) =>
  builder.addCase(sessionSocketConnected, () => true).addCase(sessionSocketDisconnection.fulfilled, () => false)
);
