import { useMutation } from '@apollo/client';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

import * as analytics from 'client/common/analytics';
import { getFirebaseAuth } from 'client/common/firebase';
import { MUTATION_CREATE_BOARD_LINK, MUTATION_RENAME_FILE } from 'client/common/graphql';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import { ModalBackDrop } from 'client/components/Common/modal-styled';
import SmallCheckbox from 'client/components/Common/small-checkbox';
import EmailTextarea from 'client/components/Dashboard/my-boards/email-textarea';
import { useActions } from 'client/hooks/useActions';
import useEventListener from 'client/hooks/useEventListener';
import useLocalStorageState from 'client/hooks/useLocalStorageState';
import * as rest from 'client/services/rest';

import * as CreateStyled from './styled';

const truncateAndTrimString = (str: string) => {
  const trimmed = str.trim();
  const maxLength = 128;
  return trimmed.length > maxLength ? `${trimmed.substring(0, maxLength - 1)}...` : trimmed;
};

interface Props {
  editDefault: boolean;
  editMode: boolean;
  fileId: string;
  fileName: string;
  isMsTeamsApp: boolean;
  onCancel: () => void;
  onNext: () => void;
  sessionJoinKey: string;
}

const BoardInviteDialog: React.FC<Props> = ({
  editDefault,
  editMode,
  fileId,
  fileName,
  isMsTeamsApp,
  onCancel,
  onNext,
  sessionJoinKey,
}) => {
  const [name, setName] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [emailError, setEmailError] = useState<boolean>();
  const [nameError, setNameError] = useState(false);
  const [boardLink, setBoardLink] = useState(undefined);
  const [linkCopied, setLinkCopied] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [isSending, setIsSending] = useState(false);
  const filenameRef = useRef();
  const [nameEditMode, setNameEditMode] = useState(editMode && editDefault);
  const [showInviteAtCreation, setShowInviteAtCreation] = useLocalStorageState('showInviteAtCreation', true);

  const user = getFirebaseAuth().currentUser;

  const { disableClipBoarding } = useActions();

  analytics.selectPreferInviteAtCreate(showInviteAtCreation);

  const emailsChanged = useCallback((value: string[]) => {
    setEmails(value);
    setEmailError(false);
  }, []);

  useEffect(() => {
    disableClipBoarding('BoardInviteDialog', true);
    return () => {
      disableClipBoarding('BoardInviteDialog', false);
    };
  }, []);

  useEventListener<KeyboardEvent>(
    'keyup',
    (e) => {
      if (e.key === 'Enter') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        next();
      }
    },
    filenameRef.current
  );

  const [createBoardLink] = useMutation(MUTATION_CREATE_BOARD_LINK, {
    onCompleted: (data) => {
      if (!isSending) {
        setBoardLink(data.createFileLink.uri);
      }
    },
  });
  const [renameFile] = useMutation(MUTATION_RENAME_FILE);

  useEffect(() => {
    if (fileName) {
      setName(fileName);
      setCurrentName(fileName);
    }
  }, [fileName]);
  useEffect(() => {
    analytics.viewPanel('/board-invite-dialog');
  }, []);

  const next = async () => {
    const nameInvalid = !name || name.trim().length < 3;
    const url = `${window.INITIAL_STATE.collaborationServerUrl}/sessions/invite`;

    if (nameInvalid) {
      setNameError(true);
      return;
    }

    try {
      if (emails.length > 0) {
        setIsSending(true);

        let link = boardLink;
        if (!link) {
          const boardLinkData = await createBoardLink({ variables: { fileId } });
          link = boardLinkData.data.createFileLink.uri;
        }

        analytics.sendBoardInviteEmail(emails.length);
        const token = await user.getIdToken();
        await rest.makeRequest({
          method: 'POST',
          url,
          authorizationToken: { auth: token },
          data: {
            session_key: sessionJoinKey,
            emails,
            inviter: user.displayName,
            link,
            board_name: fileName,
          },
        });
      }

      const newName = truncateAndTrimString(name);
      if (newName !== currentName && newName.length > 0) {
        await renameFile({ variables: { id: fileId, fileName: newName } });
      }

      onNext();
    } catch (e) {
      console.log(e);
      onNext();
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop className="active" />
      <DialogStyled.ModalContainer className="active cover" style={{ overflow: 'visible', textAlign: 'left' }}>
        <CreateStyled.CreateDialogTitle>Invite to Board</CreateStyled.CreateDialogTitle>
        <CreateStyled.CreateDialogLabel>Board name</CreateStyled.CreateDialogLabel>
        <CreateStyled.FileNameContainer>
          {nameError && <CreateStyled.NameError>Your board requires a name</CreateStyled.NameError>}
          <CreateStyled.FileNameField
            className={!nameEditMode ? 'editDisabled' : nameError ? 'error' : ''}
            ref={filenameRef}
            value={name}
            maxLength={128}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(false);
            }}
          />
          {!nameEditMode && editMode && (
            <CreateStyled.ChangeButton onClick={() => setNameEditMode(true)}>Change</CreateStyled.ChangeButton>
          )}
        </CreateStyled.FileNameContainer>
        <CreateStyled.CreateDialogLabel>Invite collaborators to the board</CreateStyled.CreateDialogLabel>
        <div style={{ width: '466px', position: 'relative' }}>
          {emailError && <CreateStyled.EmailErrors>Invalid email address</CreateStyled.EmailErrors>}
          <EmailTextarea emailsChanged={emailsChanged} existingUsers={[user]} />
        </div>
        {!isMsTeamsApp && (
          <div style={{ visibility: emails.length > 0 ? 'hidden' : 'visible', marginTop: '56px' }}>
            <CreateStyled.CreateDialogLabel style={{ marginTop: 8 }}>
              Or copy invite link
            </CreateStyled.CreateDialogLabel>
            <CreateStyled.CopyField className={!boardLink ? 'copyDisabled' : ''}>
              {!boardLink && (
                <CreateStyled.CreateInviteButton
                  onClick={() => {
                    createBoardLink({ variables: { fileId } });
                  }}
                >
                  Create invite link
                </CreateStyled.CreateInviteButton>
              )}
              {boardLink}
              {boardLink && (
                <CreateStyled.CopyButton
                  onClick={() => {
                    navigator.clipboard.writeText(boardLink);
                    analytics.copyBoardLink();
                    setLinkCopied(true);
                  }}
                >
                  {linkCopied ? 'Copied' : 'Copy link'}
                </CreateStyled.CopyButton>
              )}
            </CreateStyled.CopyField>
            <CreateStyled.AccessLabel>Anyone who has access to the link can join the board</CreateStyled.AccessLabel>
          </div>
        )}
        <CreateStyled.FooterContainer>
          <SmallCheckbox
            checked={showInviteAtCreation}
            onChange={(e) => {
              e.persist();
              setShowInviteAtCreation(!showInviteAtCreation);
            }}
          >
            Show this dialog when creating a new board
          </SmallCheckbox>
          <DialogStyled.ButtonContainer className={'right-align'}>
            <DialogStyled.CancelButton onClick={onCancel}>Cancel</DialogStyled.CancelButton>
            <DialogStyled.NextButton
              className={isSending || emailError ? 'faded' : ''}
              onClick={() => {
                if (!isSending && !emailError) {
                  next();
                }
              }}
            >
              {emails.length > 0 ? 'Send' : 'Done'}
            </DialogStyled.NextButton>
          </DialogStyled.ButtonContainer>
        </CreateStyled.FooterContainer>
      </DialogStyled.ModalContainer>
    </>,
    document.getElementById('modal-portal')
  );
};
export default BoardInviteDialog;
