import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import Select from 'react-select';

import { ModalBackDrop } from 'client/components/Common/dialog-styled';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';
import { getFirebaseAuth } from 'client/common/firebase';
import * as rest from 'client/services/rest';
import {
  AutoSyncBinding,
  AutoSyncBindingProvider,
  AutoSyncMeetingUser,
  RoomUser,
} from 'client/components/Dashboard/admin/types';
import User from 'client/components/Common/user';
import { useFetch } from 'client/hooks/useFetch';

import * as Styled from './styled';

interface MeetingUserSelectProps {
  provider: AutoSyncBindingProvider;
  onSelected: (value: AutoSyncMeetingUser) => void;
}
const MeetingUserSelect: React.FC<MeetingUserSelectProps> = ({ provider, onSelected }) => {
  const [selected, setSelected] = useState<AutoSyncMeetingUser | null>(null);

  const meetingUsersList = useFetch<AutoSyncMeetingUser[]>(`i/${provider}/directory`, [], (data) => data);

  return (
    <Select
      value={selected}
      onChange={(value) => {
        setSelected(value);
        onSelected(value);
      }}
      styles={{
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: '8px',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          margin: '0',
        }),
      }}
      options={meetingUsersList.data}
      isLoading={meetingUsersList.loading}
      getOptionValue={(v) => v.id}
      getOptionLabel={(v) => v.name}
      formatOptionLabel={(meetingUser) => <User displayName={meetingUser?.name} image={meetingUser?.photo?.blobUrl} />}
      placeholder="Choose meeting user"
    />
  );
};

interface RoomUserSelectProps {
  onSelected: (value: RoomUser) => void;
}
const RoomUserSelect: React.FC<RoomUserSelectProps> = ({ onSelected }) => {
  const [selected, setSelected] = useState<RoomUser | null>(null);

  const roomUsersList = useFetch<RoomUser[]>('rooms', [], (data) => data);

  return (
    <Select
      value={selected}
      onChange={(value) => {
        setSelected(value);
        onSelected(value);
      }}
      styles={{
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: '8px',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          margin: '0',
        }),
      }}
      options={roomUsersList.data}
      isLoading={roomUsersList.loading}
      getOptionValue={(v) => v.id}
      getOptionLabel={(v) => v?.displayName ?? v?.email ?? v.id}
      formatOptionLabel={(roomUser) => (
        <User displayName={roomUser?.displayName ?? roomUser?.email ?? roomUser.id} room />
      )}
      placeholder="Choose room user"
    />
  );
};

interface Props {
  provider: AutoSyncBindingProvider;
  onBindingAdded: (binding: AutoSyncBinding) => void;
  onClose: () => void;
}

const AddAutoSyncDialog: React.FC<Props> = ({ provider, onBindingAdded, onClose }) => {
  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    tenant: state.tenant,
    collaborationServerUrl: state.collaborationServerUrl,
  }));

  const { disableClipBoarding } = useActions();

  const [selectedMeetingUserId, setSelectedMeetingUserId] = useState<string | null>(null);
  const [selectedRoomUserId, setSelectedRoomUserId] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(false);

  useHotkeys('esc', () => {
    onClose();
  });

  useEffect(() => {
    disableClipBoarding('AddAutoSyncDialog', true);
    return () => {
      disableClipBoarding('AddAutoSyncDialog', false);
    };
  }, []);

  const addBinding = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        setDisabled(true);
        const token = await getFirebaseAuth().currentUser.getIdToken();

        const response = await rest.makeRequest<AutoSyncBinding>({
          method: 'POST',
          url: `${collaborationServerUrl}/tenant/${tenant.id}/i/${provider}/autosync`,
          authorizationToken: { auth: token },
          data: { meetingUserId: selectedMeetingUserId, roomUserId: selectedRoomUserId },
        });

        onBindingAdded(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setDisabled(false);
      }
    },
    [collaborationServerUrl, onBindingAdded, selectedMeetingUserId, selectedRoomUserId]
  );

  return ReactDOM.createPortal(
    <>
      <ModalBackDrop onClick={onClose} />
      <Styled.ModalContainer>
        <Styled.DialogTitle>Add Auto Sync</Styled.DialogTitle>
        <Styled.DialogForm onSubmit={addBinding}>
          <div>
            <Styled.InputGroup>
              <Styled.Label>Choose Meeting user</Styled.Label>
              <MeetingUserSelect provider={provider} onSelected={(v) => setSelectedMeetingUserId(v.id)} />
            </Styled.InputGroup>
            <Styled.InputGroup>
              <Styled.Label>Choose Room user</Styled.Label>
              <RoomUserSelect onSelected={(v) => setSelectedRoomUserId(v.id)} />
            </Styled.InputGroup>
          </div>
          <Styled.DialogButtonContainer>
            <Styled.Button onClick={() => onClose()} disabled={disabled}>
              Cancel
            </Styled.Button>
            <Styled.PrimaryButton type="submit" disabled={disabled}>
              Add
            </Styled.PrimaryButton>
          </Styled.DialogButtonContainer>
        </Styled.DialogForm>
      </Styled.ModalContainer>
    </>,
    document.getElementById('modal-portal')
  );
};

export default AddAutoSyncDialog;
