import { useActions } from 'client/hooks/useActions';

export const useResetConnectionAndSession = () => {
  const {
    connectFailure,
    disconnectSessionSocket,
    resetSession,
    sessionSocketDisconnection,
    setFollower,
    setForceFollow,
    setShowFilterSearchField,
    setShowGridView,
    setUserRole,
    toggleSessionEndedDialog,
    toggleShowReconnectDialog,
  } = useActions();

  return () => {
    connectFailure();
    resetSession();
    disconnectSessionSocket();
    sessionSocketDisconnection();
    toggleShowReconnectDialog(false);
    toggleSessionEndedDialog(false);
    setShowFilterSearchField(false);
    setForceFollow(false);
    setFollower(false);
    setShowGridView(false);
    setUserRole(null);
  };
};
