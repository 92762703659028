import React, { useState } from 'react';

import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import SmallCheckbox from 'client/components/Common/small-checkbox';
import { CsvOption, csvOptions } from 'client/components/Common/types';

import * as Styled from './styled';

interface Props {
  onCancel: () => void;
  onExport: (chosenOptions: string[]) => void;
}

const ExportDialog: React.FC<Props> = ({ onCancel, onExport }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [chosenOptions, setChosenOptions] = useState([]);

  const addOption = (option: CsvOption, checked: boolean) => {
    if (option.key === 'coords') {
      addOption(
        csvOptions.find((o) => o.key === 'x'),
        checked
      );
      addOption(
        csvOptions.find((o) => o.key === 'y'),
        checked
      );
    } else if (checked) {
      setChosenOptions((opts) => [...opts, option.key]);
    } else {
      setChosenOptions((opts) => opts.filter((o) => o !== option.key));
    }
  };

  return (
    <Dialog noButtons onCancel={onCancel} cover>
      <DialogStyled.Title>Export as CSV</DialogStyled.Title>
      <DialogStyled.Body>
        <Styled.Body>
          Download a CSV file of all sticky notes to your computer for importing in sheets.
          <Styled.AdvancedOptionsContainer className={!showOptions ? 'collapsed' : ''}>
            <Styled.CollapseHeader onClick={() => setShowOptions(!showOptions)}>Advanced Options</Styled.CollapseHeader>
            <Styled.Options>
              {csvOptions.map(
                (option) =>
                  !option.hidden && (
                    <Styled.Option key={option.key}>
                      <SmallCheckbox
                        onChange={(e) => {
                          e.persist();
                          addOption(option, e.target.checked);
                        }}
                      >
                        {option.desc}
                      </SmallCheckbox>
                    </Styled.Option>
                  )
              )}
            </Styled.Options>
          </Styled.AdvancedOptionsContainer>
        </Styled.Body>
      </DialogStyled.Body>
      <DialogStyled.NextButton onClick={() => onExport(chosenOptions)}>Download</DialogStyled.NextButton>
    </Dialog>
  );
};

export default ExportDialog;
