import styled from 'styled-components';

export const ToolTrayContainer = styled.div`
  position: absolute;

  &.horizontal {
    left: 56px;
    bottom: auto;
  }

  &.vertical {
    left: auto;
    bottom: 56px;
  }

  > * {
    padding: 0 10px;
  }

  &.visible {
    opacity: 1;
  }

  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0 4px 8px #00000029, 0 0 0 0.5px #00000014;
  font-size: 14px;
  text-align: left;
  cursor: initial;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  user-select: none;
  z-index: 5;
  overflow: auto;
  font-weight: normal;
  color: #8c8c8c;
  line-height: normal;
  opacity: 0;
`;

const ScrollArea = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  flex-grow: 1;
  position: relative;
  max-width: 100vw;
  opacity: 1;
  pointer-events: initial;
  transition: opacity 150ms linear;
`;

export const StickerSearchField = styled.input.attrs({
  type: 'text',
})`
  width: 190px;
  border-radius: 6px;
  border-color: transparent;
  background: #f5f5f5;
  padding-left: 10px;
`;

export const AddCustomStickerButtonWrapper = styled.div`
  border-top: 1px solid #e5e5e5;
  padding: 10px;
`;

export const AddCustomStickerButton = styled.button.attrs({
  type: 'button',
})`
  border-radius: 6px;
  border-color: transparent;
  background: transparent;
  color: #2287ca;
  font-weight: 600;
  font-size: 13px;
  padding: 5 0px;
  position: relative;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: #eeeeee;
    -webkit-transition: background-color 200ms ease-out;
  }

  > label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    width: 100%;
    color: transparent;
    cursor: pointer;

    > input {
      pointer-events: none;
    }
  }
`;

export const StickerScrollArea = styled(ScrollArea)`
  width: 290px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 10px;
`;

export const StickerTopBar = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;

  > span {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: black;
    opacity: 1;
  }
`;

export const VotingTip = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2px;
  &:hover {
    background: #f4f4f4;
  }
`;

export const StickerCategoryButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  margin: 5px 0;
`;

export const StickerCategoryButton = styled.div`
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const StickerCategoryHeader = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 2px;
  text-transform: capitalize;
`;

export const StickerCategory = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
`;

export const StickerButton = styled.div`
  background: transparent center center no-repeat;
  width: 36px;
  height: 36px;
  background-size: 36px;
  border-radius: 8px;
  margin: 3px;
  cursor: pointer;

  img {
    height: 36px;
    width: 36px;
  }

  @media (hover) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
`;
