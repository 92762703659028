import React, { useEffect } from 'react';
import Favicon from 'react-favicon';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Calendar from 'dayjs/plugin/calendar';

import Loading from 'client/components/Auth/Loading';
import Login from 'client/components/Auth/Login';
import PasswordReminder from 'client/components/Auth/PasswordReminder';
import Protected from 'client/components/Auth/Protected';
import * as Styled from 'client/components/Auth/styled';
import BrowserBlockModal from 'client/components/BrowserBlockModal';
import { TenantLogo } from 'client/components/Common/TenantLogo';
import Dashboard from 'client/components/Dashboard';
import Home from 'client/components/Home';
import Join from 'client/components/Home/join';
import Room from 'client/components/Home/room';
import MobileBlock from 'client/components/MobileBlock';
import JoinUsingQRCode from 'client/components/QRCode/JoinUsingQRCode';
import Session from 'client/components/Session';
import BoardLink from 'client/components/Session/BoardLink';
import ErrorView from 'client/components/Teams/ErrorView';
import TeamsAuthRedirect from 'client/components/Teams/TeamsAuthRedirect';
import TeamsConfig from 'client/components/Teams/TeamsConfig';
import TeamsTab from 'client/components/Teams/TeamsTab';
import GlobalStyle from 'client/global-styles/styled';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useLocalStorage from 'client/hooks/useLocalStorage';
import AccountDisabled from 'client/components/Auth/AccountDisabled';
import StartScreen from 'client/components/Home/startscreen';
import { useSelector } from 'client/hooks/useSelector';
import { useAppVersion } from 'client/hooks/useAppVersion';

import Seo from './seo';
import { GraphQLCacheEvict } from './GraphQLCacheEvict';

dayjs.extend(RelativeTime);
dayjs.extend(Calendar);

const AppRoot: React.FC = () => {
  const auth = useAuth();
  const [intendedRedirect] = useLocalStorage('intendedRedirect');
  const { setTenantIdFromHostname } = useActions();
  const { appVersion } = useAppVersion();

  const { isTeamsRoom, room } = useSelector((state) => ({
    isTeamsRoom: state.isTeamsRoom,
    room: state.room,
  }));

  useEffect(() => {
    if (!auth.loading && !room) {
      setTenantIdFromHostname();
    }
  }, [auth.loading]);

  return (
    <>
      <GraphQLCacheEvict />
      <Favicon url="/images/favicon.png" />
      <GlobalStyle />
      {room && !isTeamsRoom && <StartScreen />}
      <Router>
        <Switch>
          <Route exact path="/">
            <Styled.Global />
            <TenantLogo />
            <Seo />
            {window.INITIAL_STATE.room ? (
              <Redirect to={intendedRedirect ?? '/room'} />
            ) : auth.user ? (
              <Redirect to={intendedRedirect ?? '/dashboard'} />
            ) : (
              <Login />
            )}
          </Route>
          <Route exact path="/login">
            <Styled.Global />
            <TenantLogo />
            <Seo />
            {auth.user ? <Redirect to={intendedRedirect ?? '/dashboard'} /> : <Login />}
          </Route>
          <Route exact path="/reset-password">
            <Styled.Global />
            <TenantLogo />
            <Seo />
            {auth.user ? <Redirect to={intendedRedirect ?? '/dashboard'} /> : <PasswordReminder />}
          </Route>
          <Route exact path="/account-disabled">
            <Styled.Global />
            <TenantLogo />
            <Seo />
            <AccountDisabled />
          </Route>
          <Route path="/code">
            <Styled.Global />
            <TenantLogo />
            <Seo title="Join FlatFrog Board by code" description="Join FlatFrog Board by code" canonical="code" />
            {auth.user ? <Redirect to={intendedRedirect ?? '/dashboard'} /> : <Home />}
          </Route>
          <Route path="/join">
            <Styled.Global />
            <TenantLogo />
            <Seo />
            <Join />
          </Route>
          <Route path="/room">
            <Styled.Global />
            <TenantLogo />
            <Room />
          </Route>
          <Route path="/unsupported-browser">
            <Seo />
            <BrowserBlockModal />
          </Route>
          <Route path="/unsupported-device">
            <Seo />
            <MobileBlock />
          </Route>
          <Protected path="/dashboard/:view?/:subview?">
            <Styled.GlobalDashboard />
            <Seo />
            <Dashboard />
          </Protected>
          <Route path="/session/:sessionJoinKey?">
            <Styled.GlobalDashboard />
            <Seo />
            <Session />
          </Route>
          <Route path="/boards/link/:linkId?">
            <Seo />
            <BoardLink />
          </Route>
          <Route path="/vc/teams/config">
            <Seo />
            <TeamsConfig />
          </Route>
          <Route path="/vc/teams/link">
            <Seo />
            <TeamsTab />
          </Route>
          <Route path="/vc/teams/authredirect">
            <Seo />
            <TeamsAuthRedirect />
          </Route>
          <Route path="/vc/teams/error">
            <Seo />
            <ErrorView />
          </Route>
          <Route path="/qrcode/:code?">
            <Styled.Global />
            <TenantLogo />
            <Seo
              title="Join FlatFrog Board by QR-code"
              description="Join FlatFrog Board by QR-code"
              canonical="qrcode"
            />
            <JoinUsingQRCode />
          </Route>
        </Switch>
      </Router>
      {auth.loading && <Loading />}
    </>
  );
};

export default AppRoot;
