import dayjs from 'dayjs';
import React, { useState, useRef } from 'react';

import useEventListener from 'client/hooks/useEventListener';
import { LoadedFileInfo } from 'client/state/session/loadedFileInfo';

import Dialog from './dialog';
import * as DialogStyled from './dialog-styled';

const truncateAndTrimString = (str: string) => {
  const trimmed = str.trim();
  const maxLength = 128;
  return trimmed.length > maxLength ? `${trimmed.substring(0, maxLength - 1)}...` : trimmed;
};

interface Props {
  fileName: string;
  onNext: (fileName: string) => void;
  onCancel: () => void;
  readOnly: boolean;
  loadedFileInfo: LoadedFileInfo;
  title: string;
  owner: string;
  workspace: string;
  createdDate?: number;
}

const BoardInfoDialog: React.FC<Props> = ({
  createdDate,
  fileName,
  loadedFileInfo,
  onCancel,
  onNext,
  owner,
  readOnly,
  title,
  workspace,
}) => {
  const [newName, setNewName] = useState(fileName || '');
  const filenameRef = useRef();

  useEventListener<KeyboardEvent>(
    'keyup',
    (e) => {
      if (e.key === 'Enter') {
        onNext(truncateAndTrimString(newName));
      }
    },
    filenameRef.current
  );

  let blobColor = '#e08f26';
  let saveText = 'Saving changes...';
  if (loadedFileInfo?.success === false) {
    blobColor = '#fc0f03';
    saveText = 'Error saving file';
  } else if (loadedFileInfo?.latestLocalActionId === loadedFileInfo?.latestServerActionId) {
    blobColor = '#26e058';
    saveText = 'All changes saved';
  }

  return (
    <Dialog
      onCancel={onCancel}
      cancelText="Cancel"
      nextText={'Done'}
      noCancelButton={readOnly}
      onNext={() => {
        onNext(truncateAndTrimString(newName));
      }}
      nextDisabled={!readOnly && (!newName || newName.trim().length < 3 || newName.trim() === fileName)}
      cancelDisabled={false}
      noCloseButton
      rightAlign
      cover
    >
      <DialogStyled.Title>{title}</DialogStyled.Title>
      <DialogStyled.FileNameLabel>Name</DialogStyled.FileNameLabel>
      <DialogStyled.FileNameField
        disabled={readOnly}
        value={newName}
        maxLength={128}
        ref={filenameRef}
        onChange={(e) => setNewName(e.target.value)}
      />
      <DialogStyled.AutoSaveField blobColor={blobColor}>
        <div>Autosave</div>
        <div>{saveText}</div>
      </DialogStyled.AutoSaveField>
      <DialogStyled.OwnerField>
        <div>Owner</div>
        <div>{owner}</div>
      </DialogStyled.OwnerField>
      <DialogStyled.CreatedField>
        <div>Created</div>
        <div>{createdDate ? dayjs(createdDate).format('MMMM D, YYYY') : ''}</div>
      </DialogStyled.CreatedField>
      <DialogStyled.CreatedField>
        <div>Workspace</div>
        <div>{workspace}</div>
      </DialogStyled.CreatedField>
    </Dialog>
  );
};

export default BoardInfoDialog;
