import styled, { css } from 'styled-components';

export const ListContainer = styled.div``;

export const ListContent = styled.div`
  background: rgba(255, 255, 255, 1) 0 0 no-repeat padding-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  min-height: 240px;
  overflow: hidden;
`;

export const ListLoading = styled(ListContent)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListCheckbox = styled.div`
  margin-left: 16px;
  height: 16px;
  align-self: center;
  input {
    margin: 0;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: -0.25px;
  color: #8c8c8c;
  text-transform: uppercase;
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const ListBodyItem = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  background: ${(p) => (p.selected ? '#eff7fc' : 'transparent')} 0% 0% no-repeat padding-box;
`;

export const ListBodyAddItem = styled(ListBodyItem)<{ selection?: boolean }>`
  padding: 12px 12px 12px ${(p) => (p.selection ? '48px' : '16px')};
  cursor: pointer;

  &:hover {
    background: rgba(39, 154, 231, 0.08) 0 0 no-repeat padding-box;
  }
`;

export const AddItemIcon = styled.img.attrs({
  src: '/icons/icn_plus_blue.svg',
  alt: ' ',
})`
  padding: 9px;
  background: rgba(39, 154, 231, 0.16) 0 0 no-repeat padding-box;
  border-radius: 16px;
`;

export const AddItemText = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: -0.32px;
  align-self: center;
  margin-left: 8px;
`;

export const ListItemContent = styled.div`
  padding: 12px 16px;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ListPagination = styled.div`
  margin-top: 16px;
`;

export const ListPaginationButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const ListPaginationButton = styled.button`
  display: flex;
  background: transparent;
  outline: none;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 0 4px;
  color: #2287ca;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &.current {
    background-color: #279ae7;
    color: white;
    &:hover {
      background-color: #279ae7;
    }
  }

  &:hover {
    background-color: rgba(39, 154, 231, 0.16);
  }

  &:disabled {
    opacity: 0.8;
    filter: grayscale();
    cursor: default;
  }
`;

export const ListPaginationEllipsis = styled.div`
  color: #8c8c8c;
  font-size: 13px;
  line-height: 32px;
  font-weight: 600;
  width: 32px;
  margin: 0 4px;
  text-align: center;
`;

export const ListPaginationText = styled.div`
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #8c8c8c;
  margin-top: 8px;
`;

export const ListPaginationLeftIcon = styled.img.attrs({
  src: '/icons/icn_pagination_left.svg',
  alt: ' ',
})``;

export const ListPaginationRightIcon = styled.img.attrs({
  src: '/icons/icn_pagination_right.svg',
  alt: ' ',
})``;

export const ListSortingIcon = styled.img.attrs({
  src: '/icons/icn_dropdown_arrow_gray.svg',
  alt: ' ',
})<{ visible?: boolean; reversed?: boolean }>`
  opacity: ${(p) => (p.visible ? '0.5275' : '0')};
  margin-left: 4px;
  margin-bottom: 1px;
  ${(p) =>
    p.reversed &&
    css`
      transform: scaleY(-1);
      margin-bottom: -1px;
    `}
`;
