import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import ZCanvas from '@flatfrog/ffbec';

import { useSelector } from 'client/hooks/useSelector';
import { Client } from 'client/state/session/session';
import NameBubble from 'client/components/Common/NameBubble';

import * as Styled from './styled';
import { PresenterMarker } from './styled';

const ParticipantList: React.FC = () => {
  const { forceFollow, session } = useSelector((state) => ({
    forceFollow: state.forceFollow,
    graphqlWsConnectedTime: state.graphqlWsConnectedTime,
    loadedFileInfo: state.loadedFileInfo,
    session: state.session,
  }));

  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    setClients([...(session?.clients ?? [])].sort((a, b) => a.joinedAt.getTime() - b.joinedAt.getTime()));

    ReactTooltip.rebuild();
  }, [session]);

  const presenter = clients.find((c) => c.isPresenter);

  const getPageNumberFromUuid = (uuid: string) => ZCanvas.page.getIndexFromUuid(uuid) + 1;

  return (
    clients.length > 0 && (
      <>
        <Styled.TitleContainer>
          <Styled.Title>Participants ({clients.length})</Styled.Title>
          <Styled.Title>Pages</Styled.Title>
        </Styled.TitleContainer>
        <Styled.ParticipantListWrapper>
          <Styled.ParticipantListInnerWrapper>
            {clients.map((c, idx) => (
              <Styled.ParticipantListItem key={`fly-${c.name}-${idx}`}>
                <NameBubble room={c.clientType === 'room'} displayName={c.name}>
                  {c.isPresenter && <PresenterMarker />}
                </NameBubble>
                <Styled.Name className={!c.atPage && 'loading'}>
                  {c.isPresenter ? (
                    <strong>{`${c.name}${c.self ? ' (you)' : ''}`}</strong>
                  ) : (
                    `${c.name}${c.self ? ' (you)' : ''}`
                  )}
                </Styled.Name>
                {c.clientType === 'phone' && <Styled.PhoneIcon className="phone" />}
                {forceFollow ? (
                  <Styled.Lock className="active list" />
                ) : (
                  <Styled.Page
                    className={`
                        ${presenter != null && c.atPage === presenter.atPage ? 'follow' : ''}
                        ${c.isPresenter ? 'presenter' : ''}
                      `}
                  >
                    {c.atPage ? (
                      getPageNumberFromUuid(c.atPage)
                    ) : (
                      <div className="lds-ellipsis">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    )}
                  </Styled.Page>
                )}
              </Styled.ParticipantListItem>
            ))}
          </Styled.ParticipantListInnerWrapper>
        </Styled.ParticipantListWrapper>
      </>
    )
  );
};

export default ParticipantList;
