import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import * as analytics from 'client/common/analytics';
import useAnimal from 'client/common/animals';
import isValidBrowser from 'client/common/browserCheck';
import Layout from 'client/common/helpers/Layout';
import { getParticipantColor } from 'client/common/participant-names';
import { CONNECTION_STATUS } from 'client/common/util';
import { MobilePrimaryButton } from 'client/components/Auth/MobileTopBar';
import * as Styled from 'client/components/Auth/styled';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import useCodeInput, { invalidCharacterMessage } from 'client/hooks/useCodeInput';
import { useResetConnectionAndSession } from 'client/hooks/useResetConnectionAndSession';
import { useSelector } from 'client/hooks/useSelector';

import BoardCodeInfo from './boardcodeinfo';
import CookieBanner from './cookiebanner';

const Home: React.FC = () => {
  const { hasAgreedToTermsAndPolicy, session } = useSelector((state) => ({
    hasAgreedToTermsAndPolicy: state.hasAgreedToTermsAndPolicy,
    session: state.session,
  }));

  const { agreeToTermsAndPolicy, connectFailure, setSignature, tryConnectWithCode } = useActions();

  const [animal, changeAnimal] = useAnimal();
  const { code, codeInput, handleCodeChange, hint } = useCodeInput();
  const [showBoardCodeInfo, setShowBoardCodeInfo] = useState(false);
  const history = useHistory();

  const resetConnectionAndSession = useResetConnectionAndSession();

  useEffect(() => {
    if (!isValidBrowser) {
      history.replace('/unsupported-browser');
    } else {
      analytics.viewPage('/code');
    }

    resetConnectionAndSession();
  }, []);

  useEffect(() => {
    if (session.failure) {
      analytics.invalidCode();
      if (code.length !== 6) {
        connectFailure();
      }
    }
  }, [session.failure, code]);

  useEffect(() => {
    setSignature(animal);
  }, [animal]);

  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting) {
      agreeToTermsAndPolicy(true);
      analytics.validCode();
      history.push('/session', { from: 'home' });
    }
  }, [session]);

  const { signInAsGuest } = useAuth();

  const handleSubmit = async (e: React.MouseEvent | React.KeyboardEvent) => {
    if (!('key' in e && e.key !== 'Enter') && code.length === 6) {
      await signInAsGuest();

      tryConnectWithCode(code);
    }
  };

  return (
    <Layout
      mobileTopBarButton={
        <MobilePrimaryButton>
          <Link id="login-link" to="/login">
            Sign In
          </Link>
        </MobilePrimaryButton>
      }
      topBarItems={
        <Styled.LoginButton>
          <Styled.PromoLabel>Get full experience of FlatFrog Board</Styled.PromoLabel>
          <Link id="login-link" to="/login">
            Sign In
          </Link>
        </Styled.LoginButton>
      }
      mainContent={
        <>
          <h1>
            Hi 👋, enter a Board Code to join as{' '}
            <Styled.Animal id="animal-toggle" onClick={changeAnimal} color={getParticipantColor(animal)}>
              {animal}
            </Styled.Animal>
          </h1>
          <Styled.CodeInputWrapper>
            <Styled.CodeInput
              ref={codeInput}
              id="code-input"
              autoFocus
              onChange={handleCodeChange}
              onKeyDown={handleSubmit}
              className={session.failure && 'error'}
            />
            <Styled.CodeEnterButton
              id="code-enter-button"
              className={hint !== 'valid' && 'hidden'}
              onClick={handleSubmit}
            />
            {hint === 'invalid character' && invalidCharacterMessage}
          </Styled.CodeInputWrapper>

          <span className="privacy">
            By joining a board, you agree to the{' '}
            <a target="_blank" href="/privacy-policy">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a target="_blank" href="/terms-of-service">
              Terms of Service
            </a>
          </span>
        </>
      }
      footer={
        <>
          {showBoardCodeInfo && <BoardCodeInfo onClose={() => setShowBoardCodeInfo(false)} />}
          <CookieBanner accepted={hasAgreedToTermsAndPolicy} accept={() => agreeToTermsAndPolicy(true)} />
        </>
      }
    />
  );
};

export default Home;
