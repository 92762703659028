import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { RootState } from 'client/store';

import { setTenant } from './tenant';

const getTenantIdFromHostname = (state: RootState) => {
  const { hostname } = window.location;

  const appURL = new URL(state.appServerUrl);

  if (hostname.endsWith(appURL.hostname)) {
    const [tenantId] = hostname.replace(appURL.hostname, '').split('.');

    return tenantId;
  }
  return null;
};

export const setTenantIdFromHostname = createAsyncThunk(
  'setTenantIdFromHostname',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const tenantId = getTenantIdFromHostname(state) ?? state.tenantId;

    if (tenantId) {
      dispatch(setTenant(tenantId));
    }
  }
);

export default createReducer(window.INITIAL_STATE.tenantId ?? null, {});
