import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { isIPad13, isMobile as _isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import slugid from 'slugid';
import classNames from 'classnames';

import * as analytics from 'client/common/analytics';
import {
  MUTATION_CREATE_ITEM,
  MUTATION_CREATE_ITEMS,
  MUTATION_DELETE_ITEMS,
  QUERY_MY_ITEMS,
} from 'client/common/graphql';
import SmallCheckbox from 'client/components/Common/small-checkbox';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { ItemComponent } from 'client/components/ItemStack/item';

import * as Styled from './styled';

const isMobile = _isMobile && !isIPad13;

interface Props {
  hidden?: boolean;
}

const ItemStack: React.FC<Props> = ({ hidden }) => {
  const { currentStickyNoteId, currentTextBlockId, localItems, session, showItemStack, signatureText } = useSelector(
    (state) => ({
      currentStickyNoteId: state.currentStickyNoteEdit,
      currentTextBlockId: state.currentTextBlockEdit,
      localItems: state.localItems,
      session: state.session,
      showItemStack: state.showItemStack,
      signatureText: state.signatureText,
    })
  );

  const {
    addItemsToBoard,
    addLocalItem,
    currentStickyNoteEdit,
    currentTextBlockEdit,
    deleteItemCanvas,
    deleteLocalItem,
    emptyLocalItems,
    setShowItemStack,
  } = useActions();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showMenuFlyout, setShowMenuFlyout] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const auth = useAuth();

  const query = useQuery(QUERY_MY_ITEMS, { nextFetchPolicy: 'network-only' });
  const [deleteItems] = useMutation(MUTATION_DELETE_ITEMS);
  const [createItem] = useMutation(MUTATION_CREATE_ITEM);
  const [createItems] = useMutation(MUTATION_CREATE_ITEMS);

  useEffect(() => {
    if (showItemStack) {
      analytics.viewPanel('/my-items');
    }
  }, [showItemStack]);

  useEffect(() => {
    setDisabled(!!(currentStickyNoteId || currentTextBlockId));
  }, [currentStickyNoteId, currentTextBlockId]);

  useEffect(() => {
    if (auth.user && localItems.length > 0) {
      const idsToSave = localItems.map((item) => item.id);

      const itemsToSave = localItems
        .map(({ content, textColor, backgroundColor, outlineColor, textSize, textAlign, link, type }) => ({
          content,
          textColor,
          backgroundColor,
          outlineColor,
          textSize,
          textAlign,
          link,
          type,
          signature: signatureText,
        }))
        .reverse();

      createItems({ variables: { input: itemsToSave } }).then(() => {
        emptyLocalItems();
        idsToSave.forEach(deleteItemCanvas);
      });
    }
  }, [auth.user]);

  const items = auth.user ? query?.data?.items ?? [] : localItems;

  return (
    <>
      <Styled.Wrapper
        className={classNames({
          connected: !!session?.id,
          'not-connected': !session?.id,
          show: showItemStack,
        })}
        hidden={hidden}
      >
        <Styled.HeaderArea>
          <Styled.AddItemButton
            className={classNames({ active: showMenuFlyout })}
            onClick={() => setShowMenuFlyout(!showMenuFlyout)}
            disabled={disabled}
          >
            <Styled.MenuHoverPositioner>
              <Styled.MenuContainer>
                <Styled.AddStickyNoteItem
                  onClick={() => {
                    currentStickyNoteEdit({ new: true, saveExpected: true });
                    analytics.viewPanel('/my-items/sticky-note-editor');
                  }}
                >
                  Sticky Note
                </Styled.AddStickyNoteItem>
                <Styled.AddTextItem
                  onClick={() => {
                    currentTextBlockEdit({ new: true, saveExpected: true });
                    analytics.viewPanel('/my-items/text-block-editor');
                  }}
                >
                  Text
                </Styled.AddTextItem>
              </Styled.MenuContainer>
            </Styled.MenuHoverPositioner>
          </Styled.AddItemButton>
          <div
            style={{
              margin: 'auto',
              color: '#242424',
              fontWeight: 500,
              fontSize: '16px',
              userSelect: 'none',
            }}
          >
            My Items
          </div>
          <Styled.CloseButton
            className="close"
            data-place="right"
            data-tip="Close"
            data-effect="solid"
            onClick={() => {
              setShowMenuFlyout(false);
              setShowItemStack(false);
              ReactTooltip.hide();
            }}
          />
        </Styled.HeaderArea>
        {items.length > 0 ? (
          <Styled.ScrollArea className={classNames({ show: showItemStack })}>
            <Styled.StackHeader>
              <SmallCheckbox
                id="item-stack-select-all"
                indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
                disabled={disabled}
                checked={items.length > 0 && selectedItems.length === items.length}
                onChange={(e) =>
                  e.target.checked ? setSelectedItems(items.map((item) => item.id)) : setSelectedItems([])
                }
              >
                <Styled.StackHeaderLabel>Select All</Styled.StackHeaderLabel>
              </SmallCheckbox>
              <Styled.StackHeaderButtonGroup>
                <Styled.DeleteButton
                  data-place="top"
                  data-tip="Delete"
                  data-effect="solid"
                  disabled={disabled || selectedItems.length === 0}
                  onClick={() => {
                    const count = selectedItems.length;
                    if (auth.user) {
                      deleteItems({ variables: { input: { ids: selectedItems } } });
                      selectedItems.forEach((id) => {
                        deleteItemCanvas(id);
                      });
                    } else {
                      selectedItems.forEach((id) => {
                        deleteLocalItem(id);
                        deleteItemCanvas(id);
                      });
                    }
                    setSelectedItems([]);
                    analytics.deleteFromMyItems(count);
                  }}
                />
                <Styled.DuplicateButton
                  data-place="top"
                  data-tip="Duplicate"
                  data-effect="solid"
                  disabled={disabled || selectedItems.length === 0}
                  onClick={() => {
                    analytics.duplicateFromMyItems(selectedItems.length);
                    selectedItems.forEach((id) => {
                      const itemToCopy = auth.user
                        ? query?.data?.items.find((item) => id === item.id)
                        : localItems.find((item) => id === item.id);
                      if (!itemToCopy) {
                        return;
                      }
                      const input = {
                        type: itemToCopy.__typename,
                        textColor: itemToCopy.textColor,
                        backgroundColor: itemToCopy.backgroundColor,
                        textSize: itemToCopy.textSize,
                        textAlign: itemToCopy.textAlign,
                        content: itemToCopy.content,
                        signature: itemToCopy.__typename === 'StickyNote' ? itemToCopy.signature : undefined,
                        outlineColor: itemToCopy.__typename === 'StickyNote' ? undefined : itemToCopy.outlineColor,
                      };

                      if (auth.user) {
                        createItem({ variables: { input } });
                      } else {
                        addLocalItem({ id: slugid.nice(), __typename: input.type, ...input });
                      }
                    });
                  }}
                />
                {session?.id ? (
                  <Styled.SendButton
                    className={classNames({ mobile: isMobile })}
                    disabled={disabled || selectedItems.length === 0}
                    onClick={() => {
                      const count = selectedItems.length;
                      addItemsToBoard(selectedItems);
                      setSelectedItems([]);
                      setShowMenuFlyout(false);
                      setShowItemStack(false);
                      analytics.addFromMyItems(count);
                    }}
                  >
                    Add to Board
                  </Styled.SendButton>
                ) : null}
              </Styled.StackHeaderButtonGroup>
            </Styled.StackHeader>
            <div>
              {items.map((item, index) => (
                <ItemComponent
                  key={item.id}
                  index={index}
                  disabled={disabled}
                  selected={selectedItems.includes(item.id)}
                  setSelected={(selected) =>
                    selected
                      ? setSelectedItems([item.id, ...selectedItems])
                      : setSelectedItems(selectedItems.filter((id) => id !== item.id))
                  }
                  {...item}
                />
              ))}
            </div>
          </Styled.ScrollArea>
        ) : (
          <Styled.ScrollArea
            className={classNames('empty', {
              show: showItemStack,
            })}
          >
            <img src="/images/ill_my_items_empty.svg" />
            <div style={{ fontSize: '14px', marginTop: '16px', userSelect: 'none' }}>My Items is Empty</div>
          </Styled.ScrollArea>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default ItemStack;
