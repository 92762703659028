import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

import { AppServerUrl } from 'client/components/Common/AppServerUrl';
import Pin from 'client/components/Pin';
import QrCode from 'client/components/Home/qrcode';

interface Props {
  hide: () => void;
}

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 100000;
`;

const Text = styled.div`
  font-family: 'Open Sans';
  color: #ffffff;
  font-weight: 400;
  font-size: 47px;
  text-align: center;
`;

const Container = styled.div`
  color: #fff;
  font-size: 106px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  font-size: 106px;
`;

export const OpenInstructions: React.FC<Props> = ({ hide }) =>
  ReactDOM.createPortal(
    <Backdrop
      onClick={(e) => {
        e.stopPropagation();
        hide();
      }}
    >
      <Container>
        <Text>
          Sign in to <AppServerUrl /> on your device
          <br /> and follow the instructions
        </Text>
        <Pin />
        <QrCode />
      </Container>
    </Backdrop>,
    document.getElementById('modal-portal')
  );
