import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import { PresenterMarker } from 'client/components/CollaborationSidePanel/styled';
import { useSelector } from 'client/hooks/useSelector';
import { Client } from 'client/state/session/session';
import NameBubble from 'client/components/Common/NameBubble';

import * as Styled from './participants-styled';

const MAX_DISPLAY_CLIENTS = 3;

interface Props {
  showCollaborationPanel: () => void;
}

const Participants: React.FC<Props> = ({ showCollaborationPanel }) => {
  const { room, session } = useSelector((state) => ({
    room: state.room,
    session: state.session,
  }));

  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    setClients(
      [...(session.clients ?? [])].sort((a, b) => {
        if (a.isPresenter && !b.isPresenter) {
          return -1;
        }
        if (!a.isPresenter && b.isPresenter) {
          return 1;
        }
        return a.joinedAt.getTime() - b.joinedAt.getTime();
      })
    );

    if (!room) {
      ReactTooltip.rebuild();
    }
  }, [session]);

  return (
    <Styled.ParticipantsWrapper className={classNames({ room })}>
      <Styled.Stack
        data-tip={`Expand Collaboration <span class="shortcut">C</span>`}
        data-html="true"
        onClick={(e) => {
          e.stopPropagation();
          ReactTooltip.hide();
          showCollaborationPanel();
        }}
      >
        {!room && <Styled.ExpandButton />}
        <Styled.Bubbles>
          {clients.slice(0, MAX_DISPLAY_CLIENTS).map((c) => (
            <NameBubble key={c.id} room={c.clientType === 'room'} displayName={c.name}>
              {c.isPresenter && <PresenterMarker />}
            </NameBubble>
          ))}
          {clients.length > MAX_DISPLAY_CLIENTS && (
            <Styled.PlusMore>+{clients.length - MAX_DISPLAY_CLIENTS}</Styled.PlusMore>
          )}
        </Styled.Bubbles>
      </Styled.Stack>
    </Styled.ParticipantsWrapper>
  );
};

export default Participants;
