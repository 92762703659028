import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import Users from 'client/components/Dashboard/admin/users';
import Rooms from 'client/components/Dashboard/admin/rooms';
import * as Styled from 'client/components/Dashboard/admin/styled';
import * as Assets from 'client/components/Dashboard/admin/assets';
import TeamsIntegration from 'client/components/Dashboard/admin/integrations/teams';

const Admin: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.SidebarColumn>
        <Styled.Sidebar>
          <Styled.SidebarSection>
            <Styled.SidebarItem exact to="/dashboard/admin">
              <Assets.AdminUsersIcon />
              Users
            </Styled.SidebarItem>
            <Styled.SidebarItem exact to="/dashboard/admin/rooms">
              <Assets.AdminRoomsIcon />
              Rooms
            </Styled.SidebarItem>
          </Styled.SidebarSection>
          <Styled.SidebarSection>
            <Styled.SidebarSectionHeader>Integrations</Styled.SidebarSectionHeader>
            <Styled.SidebarItem exact to="/dashboard/admin/i/teams">
              <Assets.AdminIntegrationTeamsIcon />
              Microsoft 365
            </Styled.SidebarItem>
          </Styled.SidebarSection>
        </Styled.Sidebar>
      </Styled.SidebarColumn>
      <Styled.MainColumn>
        <Switch>
          <Route exact path="/dashboard/admin">
            <Users />
          </Route>
          <Route exact path="/dashboard/admin/rooms">
            <Rooms />
          </Route>
          <Route exact path="/dashboard/admin/i/teams">
            <TeamsIntegration />
          </Route>
        </Switch>
      </Styled.MainColumn>
    </Styled.Container>
  );
};

export default Admin;
