import { isMobile } from 'react-device-detect';
import styled, { createGlobalStyle, css } from 'styled-components';

import device from 'client/common/deviceSize';

export const Global = createGlobalStyle`
  body {
    background: #F0F0F0 url(/images/FFB_Logo.svg) 64px 64px no-repeat;
    background-size: auto 56px;

    @media ${device.mobile} {
      background-image: none;
    }
  }

  a {
    color: #2287CA;
    text-decoration: none;

    &[href^="//"], &[target^="_blank"] {
      background: url(/icons/icn_link_to_new_window.svg) right center no-repeat;
      padding-right: 13px;
    }
  }
`;

export const GlobalDashboard = createGlobalStyle`
  body {
    background: #F0F0F0;
  }

  a {
    color: #2287CA;
    text-decoration: none;

    &[href^="//"], &[target^="_blank"] {
      background: url(/icons/icn_link_to_new_window.svg) right center no-repeat;
      padding-right: 1em;
    }
  }
`;

export const Animal = styled.span.attrs((props) => ({
  style: { color: props.color, borderColor: props.color },
}))`
  position: relative;
  transition: color 300ms ease-in-out, border-bottom-color 300ms ease-in-out;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    border-top-style: solid;
    border-top-width: 2px;
    opacity: 20%;
  }
`;

export const CodeEnterButton = styled.span`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: url(/icons/icn_returnkey.svg) center center no-repeat;
  transition: 150ms background-color ease-in-out, 300ms opacity ease-in-out;

  &.hidden {
    opacity: 0;
  }

  &:hover {
    background-color: #00000014;
    cursor: pointer;
  }
`;

export const CodeInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 32px auto 24px auto;
`;

export const CodeInput = styled.input.attrs({
  inputMode: 'numeric',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  maxLength: 7,
  spellCheck: false,
  placeholder: '——— ———',
})`
  border: 2px solid #279ae7;
  border-radius: 40px;
  height: 80px;
  width: 100%;
  font-size: 31px;
  font-weight: 600;
  letter-spacing: 6.2px;
  text-align: center;
  transition: border-color 150ms ease-in-out;
  color: #242424;
  display: block;
  outline: none;
  text-transform: uppercase;

  &::placeholder {
    color: #242424;
    opacity: 0.5;
  }

  &:focus {
    border-color: #279ae7;
    background: #fafafa;
  }

  &:not(:placeholder-shown):not(:focus) {
    border-color: #00000029;
    background: #fff;

    &.error {
      background: #df0b0b29;
      border-color: #df0b0b29;
    }
  }

  &:placeholder-shown {
    // opacity: 0.5;
  }

  &.error {
    background: #df0b0b29;
    border-color: #df0b0b29;
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const HelpLinks = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-size: 13px;
  font-weight: 600;
  margin-top: 32px;
  width: 680px;

  a {
    cursor: pointer;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const LargeJoinButton = styled.button.attrs({
  type: 'button',
})`
  border-radius: 40px;
  border: none;
  height: 80px;
  width: 380px;
  font-size: 31px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background: #279ae7;
  margin: 32px auto 24px auto;
  cursor: pointer;

  &:hover {
    background: #2287ca;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`;

export const SharedLinkInfo = styled.div`
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 32px;

  @media ${device.mobile} {
    display: none;
  }
`;

export const Logo = styled.div`
  background: url(/images/FFB_Logo.svg) center center no-repeat;
  background-size: auto 56px;
  flex: 1;
`;

export const Filler = styled.div`
  flex: 1;
`;

export const FormWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.mobile} {
    min-height: 100%;
  }
`;

export const BoardCodeForm = styled.div`
  background: url(/images/image_login-background.svg) center center no-repeat;
  background-size: cover;
  width: 680px;
  height: 350px;
  padding: 80px 0;

  text-align: center;
  user-select: none;

  @media ${device.mobile} {
    top: auto;
    left: auto;
    transform: none;
    padding: 12px;
    height: fit-content;
    width: 100%;
    bottom: auto;
    padding: 32px;
    background: transparent !important;
    position: relative;
    margin: auto 0 0 0;
  }

  h1 {
    color: #242424;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -0.65px;
    text-align: center;
    text-transform: none;
    padding: 0;
    margin: 0;
  }

  span.privacy {
    display: block;
    font-size: 13px;
  }
`;

export const PasswordHintToggle = styled.div`
  width: 32px;
  height: 32px;
  background: url(/icons/icn_password_show.svg) center center no-repeat;
  cursor: pointer;

  &.show {
    background: url(/icons/icn_password_hide.svg) center center no-repeat;
  }
`;

export const PasswordFieldWrapper = styled.div`
  position: relative;

  ${PasswordHintToggle} {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

export const SSO = styled.div`
  position: absolute;
  top: calc(100% + 16px);
  left: 0;
  right: 0;

  @media ${device.mobile} {
    position: static;
  }

  span.sso-help-text {
    color: #8c8c8c;
    font-size: 13px;
  }

  button.sso {
    color: inherit;
    background: #fff;
    width: 320px;
    margin: 8px auto 8px auto;
    box-shadow: 0 0 0 0.5px #00000014;
    border: none;
    max-width: 100%;

    > span {
      text-indent: 32px;
      display: inline-block;
      color: #242424;
      font-size: 16px;
      font-weight: 600;
      height: 24px;
    }

    > span.google {
      background: url(/icons/icn_googledrive@3x.png) left center no-repeat;
      background-size: 24px 24px;
    }

    > span.microsoft {
      background: url(/icons/icn_microsoft.png) left center no-repeat;
      background-size: 24px 24px;
    }

    &:hover {
      background-color: #fafafa;
    }
  }
`;

export const ScrollArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const FormIllustration = styled.img`
  margin-bottom: 24px;
`;

export const InfoText = styled.small`
  color: #242424;
  font-size: 13px;
  font-weight: normal;
  display: block;
  margin-top: 8px;
`;

export const EmailAddress = styled.div`
  color: #242424;
  font-size: 13px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 40px;
`;

export const textArea = css`
  display: block;
  text-align: left;
  font-size: 16px;
  color: #242424;
  padding: 16px;
  background: #fff;
  border: 2px solid #00000029;
  border-radius: 6px;
  width: 100%;
  margin: 8px 0;

  &:focus {
    background: #fafafa;
    border-color: #279ae7;
  }

  &.error {
    background: #df0b0b29;
    border-color: #df0b0b;
  }
`;

// Split up and make more modular, see change password
export const LoginForm = styled(BoardCodeForm)`
  background: url(/images/image_login-background-small.svg) center center no-repeat;
  background-size: cover;
  width: 400px;
  height: auto;
  padding: 40px;

  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  @media ${device.mobile} {
    width: 100%;
    top: auto;
    bottom: auto;
    padding: 32px;
    transform: none;
    left: auto;
    background: transparent;
    position: relative;
    margin: auto 0;
  }

  label {
    display: flex !important;

    > a {
      font-weight: normal;
      margin-left: auto;
    }
  }

  span.sign-up-help {
    font: normal normal normal 13px/18px 'Open Sans';
    color: #242424;

    &.terms {
      font-size: 10px;
    }
  }

  span.action {
    font: normal normal normal 13px/18px 'Open Sans';
    color: #2287ca;
    cursor: pointer;
  }

  &.sign-up {
    background: url(/images/image_login-background-long.svg) center center no-repeat;
    height: 526px;

    @media ${device.mobile} {
      height: auto;
    }

    button {
      margin-top: 40px;
    }
    button.sso {
      margin-top: 8px;
    }
  }

  h1 {
    text-align: center;
    font: normal normal 600 18px/26px 'Open Sans';
    color: #242424;
    padding: 0;
    margin: 0 0 24px 0;
  }

  .error {
    color: #df0b0b;
    font-size: 13px;
  }

  label {
    font: normal normal 600 13px/18px 'Open Sans';
    color: #8c8c8c;
    margin: 8px 0;
    display: block;
    text-align: left;

    &.error {
      color: #df0b0b;
    }
  }

  input {
    display: block;
    text-align: left;
    font-size: 16px;
    color: #242424;
    height: 48px;
    padding: 16px;
    background: #fff;
    border: 2px solid #00000029;
    border-radius: 6px;
    width: 100%;
    margin: 8px 0;

    &:focus {
      background: #fafafa;
      border-color: #279ae7;
    }

    &.error {
      background: #df0b0b29;
      border-color: #df0b0b;
    }
  }

  textarea {
    ${textArea};
  }

  button {
    background: #279ae7;
    height: 48px;
    display: block;
    border-radius: 6px;
    width: 100%;
    color: #fff;
    border: none;
    margin: 16px 0;
    cursor: pointer;

    &:hover {
      background: #2287ca;
    }
  }
`;

export const PromoLabel = styled.span`
  color: #242424;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 128px;
  flex-grow: 1;
`;

export const LoginButton = styled.div`
  position: absolute;
  top: 64px;
  right: 64px;

  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    background: #279ae7;
    color: #fff;
    border-radius: 28px;
    padding: 0 32px;
    height: 56px;
    min-width: 128px;
    border: none;
    outline: none;
    box-shadow: 0 8px 16px #279ae740;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: #2287ca;
    }
  }

  ${PromoLabel} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 24px);
  }

  @media ${device.mobile} {
    font-size: 13px;
    min-width: 100px;
  }
`;

export const TopBar = styled.div`
  display: flex;
  padding: 64px 64px 32px 64px;
  margin-bottom: auto;

  ${LoginButton} {
    position: relative;
    margin-left: auto;
    top: 0;
    right: 0;
  }

  @media ${device.mobile} {
    padding: 64px 10px 32px 10px;
  }
`;

export const ValidationError = styled.span`
  color: red;
  font-size: 14px;
`;

export const BoardCodeInfoTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #242424;
  padding: 0;
  margin: 24px 0 8px 0;
  text-align: center;
`;

export const BoardCodeInfoBody = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #242424;
  text-align: center;

  a {
    text-decoration: none;
    color: #2287ca;
    font-weight: 600;
  }
`;

export const BoardCodeInfoConfirm = styled.button.attrs({
  type: 'button',
})`
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  text-align: center;
  color: #2287ca;
  margin-top: 40px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;

export const CookieBanner = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #242424;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: ${isMobile ? '13px' : '16px'};
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;

  &.notaccepted {
    transform: translateY(0%);
  }

  @media ${device.mobile} {
    padding: 12px;
    height: 100px;
  }
`;

export const CookieBannerText = styled.div`
  @media only screen and (min-width: 728px) {
    margin-right: 193px;
  }
  margin-right: 20px;

  a {
    color: #fff;
    text-decoration: underline;
    &[href^="//"], &[target^="_blank"]
    {
      background: none;
      padding-right: 0;
    }
  }
`;

export const CookieBannerButton = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  border: none;
  padding: 13px 25px;
  border-radius: 6px;
  background: #505050;
  color: #ffffff;
  cursor: pointer;
`;
