// Used for the buttons under the thumbnails for example
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import useEventListener from 'client/hooks/useEventListener';

import { FloatingPanel } from './common-styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  height: 40px;
  min-width: 64px;
  background: #0000000a;
  justify-content: center;
  font: normal normal 600 16px/22px Open Sans;

  &:hover {
    background: #00000015;
  }

  &.disabled {
    background: transparent;
    box-shadow: none;
  }
`;

const Button = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:disabled {
    cursor: default;
    color: #242424;
  }
`;

const Menu = styled.div`
  ${FloatingPanel};

  background: #ffffff;
  box-shadow: 0 4px 12px #00000029;
  border: 0.5px solid rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  padding: 8px 0;
`;

const MenuPositioner = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  padding-top: 16px;
  cursor: initial;
  z-index: 6;

  &.up {
    bottom: calc(100% + 8px);
    ${Menu} {
      flex-direction: column-reverse;
    }
  }

  &.down {
    top: calc(100% - 8px);
    ${Menu} {
      flex-direction: column;
    }
  }
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 10px 24px 10px 24px;
  background: #fff;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: #f4f4f4;
  }
`;

const DropDown = styled.button.attrs({
  type: 'button',
})`
  position: relative;
  outline: none;
  cursor: pointer;
  border: none;

  background: url(/icons/icn_dropdown_arrow_gray.svg) center center no-repeat;

  &.open {
    ${MenuPositioner} {
      display: block;
    }
  }

  &:disabled {
    cursor: default;
    // TODO: This might be wrong in some places? Adapt with new prop then
    //   see grid view
    display: none;
  }
`;

interface Props {
  onDropDown?: () => void;
  disabled: boolean;
  actions: { label: string | JSX.Element; action: () => void }[];
  gray?: boolean;
  direction: 'up' | 'down';
}

const SmallComboButton: React.FC<Props> = ({ actions, children, direction, disabled, gray, onDropDown }) => {
  // The parent can override the drop down menu state with property "open".
  const [open, setOpen] = useState(false);
  const wrapper = useRef<HTMLDivElement>();

  useEventListener<CustomEvent<HTMLElement>>('closemenus', (e) => {
    if (!wrapper.current.contains(e.detail)) {
      setOpen(false);
    }
  });

  let dropDownAction = null;
  if (onDropDown) {
    dropDownAction = disabled ? () => {} : onDropDown;
  } else {
    dropDownAction = () => !disabled && setOpen(!open);
  }

  const menuItems = actions.map((action, index) => (
    <MenuItem key={`action-${index}`} onClick={action.action}>
      {action.label}
    </MenuItem>
  ));

  return (
    <Wrapper className={disabled ? ' disabled' : ''} ref={wrapper}>
      <Button
        disabled={disabled}
        className={(actions.length > 1 ? 'multiple' : '') + (gray ? ' gray' : '')}
        onClick={dropDownAction}
      >
        {children}
      </Button>
      <DropDown
        disabled={disabled}
        className={(open || open ? 'open' : '') + (gray ? ' gray' : '')}
        onClick={dropDownAction}
      >
        <MenuPositioner className={direction === 'down' ? direction : 'up'}>
          <Menu>{menuItems}</Menu>
        </MenuPositioner>
      </DropDown>
    </Wrapper>
  );
};

export default SmallComboButton;
