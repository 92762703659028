import { createAction, createReducer } from '@reduxjs/toolkit';

import { setForceFollow } from './forceFollow';

export const setFollower = createAction<boolean>('setFollower');

export default createReducer(false, (builder) =>
  builder
    .addCase(setFollower, (state, action) => action.payload)
    .addCase(setForceFollow.fulfilled, (state, action) => action.payload)
);
