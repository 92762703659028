import { createAsyncThunk } from '@reduxjs/toolkit';
import slug from 'slugid';

import { CLIPBOARD_MAGIC } from 'client/components/Common/types';
import { CONNECTION_STATUS } from 'client/common/util';
import * as analytics from 'client/common/analytics';
import { RootState } from 'client/store';
import {
  addLocalItem,
  ClipboardEntry,
  pastePapers,
  prepareAndPublishImage,
  setShowItemStack,
} from 'client/state/actions';
import { getFirebaseAuth } from 'client/common/firebase';
import { getTextBlockTemplate } from 'client/components/TextBlock';
import { client, MUTATION_CREATE_ITEM } from 'client/common/graphql';
import { getStickyNoteTemplate } from 'client/components/StickyNote';

export const handleInternalPaste = createAsyncThunk(
  'handleInternalPaste',
  (clipboard: ClipboardEntry, { dispatch }) => {
    let actionsAndInkImages = null;

    try {
      actionsAndInkImages = JSON.parse(clipboard.data);
    } catch (e) {
      console.log(e);
    }

    if (actionsAndInkImages) {
      dispatch(pastePapers({ actionsAndInkImages, cut: clipboard.action === 'cut' }));
      dispatch(setShowItemStack(false));
    }
  }
);

const handleImagePaste = createAsyncThunk('handleImagePaste', async (file: Blob, { dispatch, getState }) => {
  const session = (getState() as RootState).session;
  const supportedImageTypes = /^image\/(png|jpg|jpeg)/i;

  if (file !== null && file.type.match(supportedImageTypes) && session.status === CONNECTION_STATUS.connected) {
    const type = file.type;
    const fileName = `${slug.v4()}.${type.replace(supportedImageTypes, '$1')}`;

    await dispatch(
      prepareAndPublishImage({
        file: new File([file], fileName, { type }),
        options: { selectPaper: true },
      })
    );
    analytics.addImage('paste');
  }
});

export const handleImagesPaste = createAsyncThunk('handleImagesPaste', async (data: DataTransfer, { dispatch }) => {
  if (data.items) {
    for (const item of data.items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();

        await dispatch(handleImagePaste(file));
      }
    }
    data.items.clear();
  }
});

export const handleTextPaste = createAsyncThunk(
  'handleTextPaste',
  async (payload: { htmlText: string; plainText: string }, { dispatch }) => {
    const { htmlText, plainText } = payload;
    const user = getFirebaseAuth().currentUser;

    // Check for an HTML table. If found, add a sticky note for each table cell
    // Otherwise, add a plain text block
    if (htmlText && htmlText.length) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(htmlText, 'text/html');
      const tableCells = htmlDoc.querySelectorAll('td');

      if (tableCells.length === 0) {
        if (plainText && plainText.length) {
          const input = getTextBlockTemplate(plainText);

          if (user && !user.isAnonymous) {
            await client.mutate({
              mutation: MUTATION_CREATE_ITEM,
              variables: { input },
            });
          } else {
            dispatch(addLocalItem({ id: slug.nice(), __typename: 'TextBlock', ...input }));
          }
        }
      } else {
        for (let i = 0; i < tableCells.length; i += 1) {
          const cellNode = tableCells[i];
          if (cellNode.innerText !== '') {
            const input = getStickyNoteTemplate(cellNode.innerText);

            if (user && !user.isAnonymous) {
              await client.mutate({
                mutation: MUTATION_CREATE_ITEM,
                variables: { input },
              });
            } else {
              dispatch(addLocalItem({ id: slug.nice(), __typename: 'StickyNote', ...input }));
            }
          }
        }
      }
      dispatch(setShowItemStack(true));
    } else if (plainText && plainText.length) {
      const input = getTextBlockTemplate(plainText);
      if (user && !user.isAnonymous) {
        await client.mutate({
          mutation: MUTATION_CREATE_ITEM,
          variables: { input },
        });
      } else {
        dispatch(addLocalItem({ id: slug.nice(), __typename: 'TextBlock', ...input }));
      }
      dispatch(setShowItemStack(true));
    }
  }
);

async function getPlainText(item: ClipboardItem) {
  try {
    const blob = await item.getType('text/plain');
    return await blob.text();
  } catch {
    return null;
  }
}

async function getHtmlText(item: ClipboardItem) {
  try {
    const blob = await item.getType('text/html');
    return await blob.text();
  } catch {
    return null;
  }
}

export const triggerPasteEvent = createAsyncThunk('triggerPasteEvent', async (payload, { getState, dispatch }) => {
  // When a paste action is triggered, we need to read the clipboard directly unless we posted internally
  try {
    const items = await navigator.clipboard.read();
    const state = getState() as RootState;

    for (const item of items) {
      const plainText = await getPlainText(item);
      if (plainText === CLIPBOARD_MAGIC) {
        const clipboard = state.clipboard;

        if (clipboard) {
          await dispatch(handleInternalPaste(clipboard));
        }
      } else {
        // Look for images first
        const { types } = item;
        const index = types.findIndex((t) => /^image\/(png|jpg|jpeg)/.test(t));

        if (index === -1) {
          const htmlText = await getHtmlText(item);

          await dispatch(handleTextPaste({ htmlText, plainText }));
        } else {
          const blob = await item.getType(item.types[index]);

          await dispatch(handleImagePaste(blob));
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
});
