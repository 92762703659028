import { css } from 'styled-components';

export const FloatingPanel = css`
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 3px 6px #00000026, 0 0 0 1px #00000015;
  padding: 8px 6px;

  transition: opacity 150ms ease-in-out;
`;

export const smallerToolText = css`
  font-size: 12px;
  color: #ffffffaa;
`;

export const shortcutText = css`
  font-size: 12px;
  color: #ffffffaa;
  margin-left: 1em;
`;
