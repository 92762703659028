import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

import {
  CACHE_ID_ALLOWED,
  CACHE_ID_FILES,
  CACHE_ID_ITEMS,
  CACHE_ID_RECENTS,
  CACHE_ID_TEAMS,
  SUBSCRIPTION_FILES,
  SUBSCRIPTION_ITEMS,
  SUBSCRIPTION_TEAMS,
} from 'client/common/graphql';
import useSubscription from 'client/hooks/useSubscription';

export const GraphQLCacheEvict: React.FC = () => {
  const subscriptionData = useSubscription(SUBSCRIPTION_FILES);
  const subscriptionDataTeams = useSubscription(SUBSCRIPTION_TEAMS);
  const subscriptionDataItems = useSubscription(SUBSCRIPTION_ITEMS);

  const apolloClient = useApolloClient();

  const cacheEvict = (fieldName: string) => apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName });

  useEffect(() => {
    if (subscriptionData || subscriptionDataTeams) {
      cacheEvict(CACHE_ID_FILES);
      cacheEvict(CACHE_ID_RECENTS);
      cacheEvict(CACHE_ID_TEAMS);
      cacheEvict(CACHE_ID_ALLOWED);
      cacheEvict('searchFiles');
    }
  }, [subscriptionData, subscriptionDataTeams]);

  useEffect(() => {
    if (subscriptionDataItems) {
      cacheEvict(CACHE_ID_ITEMS);
    }
  }, [subscriptionDataItems]);

  return null;
};
