export const ForegroundColors = [
  '#242424',
  '#DE0A0D',
  '#DE970B',
  '#DDDE0C',
  '#44C20A',
  '#0987DB',
  '#0D29DB',
  '#DB08B3',
];

export const BackgroundColors = [
  { color: '#FFF8A6', name: 'Yellow', old: '#fffec7', FFZinkEnumMapping: 0 },
  { color: '#9FF5BA', name: 'Green', old: '#e1ffc7', FFZinkEnumMapping: 2 },
  { color: '#FFA6A6', name: 'Red', old: '#ffc7c7', FFZinkEnumMapping: 3 },
  { color: '#BFDFFF', name: 'Light Blue', old: '#c7f0ff', FFZinkEnumMapping: 1 },
  { color: '#FFE1A6', name: 'Orange', old: '#ffe3c7', FFZinkEnumMapping: 7 },
  { color: '#CBA6FF', name: 'Purple', old: '#ffc7ff', FFZinkEnumMapping: 8 },
  { color: '#FFB3D9', name: 'Pink', FFZinkEnumMapping: 9 },
  { color: '#95E6E4', name: 'Turquoise', FFZinkEnumMapping: 10 },
];

export const findBackgroundColor = (color: string) =>
  BackgroundColors.find(
    (bgColor) => bgColor.color === color || bgColor.name.replace(/\s/g, '').toLowerCase() === color.toLowerCase()
  );

export const findBackgroundColorByEnum = (color: number) =>
  BackgroundColors.find((bgColor) => bgColor.FFZinkEnumMapping === color);
