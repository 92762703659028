import React, { useEffect, useState } from 'react';
import ZCanvas from '@flatfrog/ffbec';

import Dialog from 'client/components/Common/dialog';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'client/hooks/useSelector';
import { useAppVersion } from 'client/hooks/useAppVersion';

import * as Styled from './styled';

interface Props {
  hide: () => void;
  isShown: boolean;
}

export const SettingsDialog: React.FC<Props> = ({ hide, isShown }) => {
  const { tenant, appServerUrl, room, revision } = useSelector((state) => ({
    tenant: state.tenant,
    appServerUrl: state.appServerUrl,
    room: state.room,
    revision: state.revision,
  }));

  const auth = useAuth();
  const { appVersion, appNextVersion } = useAppVersion();

  const [becVersion, setBecVersion] = useState<string | null>(null);
  const [firmwareVersion, setFirmwareVersion] = useState<string | null>(null);

  const [isPreinkingEnabled, setIsPreinkingEnabled] = useState<boolean>(null);

  useEffect(() => {
    setBecVersion(`${ZCanvas.becVersion()[0]} (${ZCanvas.becVersion()[1].split('-').pop().toUpperCase()})`);
    setFirmwareVersion(ZCanvas.connectedDeviceInfo().length > 0 ? ZCanvas.connectedDeviceInfo() : null);
    setIsPreinkingEnabled(window.ffbecandroidjni?.get_preinking_enabled());
  }, []);

  const hasPreinkingSupport = !!window.ffbecandroidjni;

  return (
    <Dialog noButtons={true} onCancel={hide} cover={false}>
      <>
        <DialogStyled.Title style={{ fontWeight: 600, textAlign: 'left' }}>About</DialogStyled.Title>
        <DialogStyled.Body style={{ display: 'block', textAlign: 'left' }}>
          <Styled.SectionTitle>FlatFrog Board{room ? ' Room' : ''}</Styled.SectionTitle>
          {appVersion && (
            <Styled.SectionBody>
              <Styled.SectionBodyPrequel>Version:</Styled.SectionBodyPrequel>
              {appVersion} ({revision.webApp.short.toUpperCase()})
              {appNextVersion && ` (upgradeable to ${appNextVersion})`}
            </Styled.SectionBody>
          )}
          {becVersion && (
            <Styled.SectionBody>
              <Styled.SectionBodyPrequel>BEC version:</Styled.SectionBodyPrequel>
              {becVersion}
            </Styled.SectionBody>
          )}
          {firmwareVersion && (
            <Styled.SectionBody>
              <Styled.SectionBodyPrequel>InGlass FW:</Styled.SectionBodyPrequel>
              {firmwareVersion}
            </Styled.SectionBody>
          )}
          {auth.user && (
            <Styled.SectionBody>
              <Styled.SectionBodyPrequel>{auth.user.displayName ? 'User:' : 'User ID:'}</Styled.SectionBodyPrequel>
              {auth.user.displayName ? `${auth.user.displayName} (ID: ${auth.user.uid})` : auth.user.uid}
            </Styled.SectionBody>
          )}
          {tenant && (
            <Styled.SectionBody>
              <Styled.SectionBodyPrequel>Tenant:</Styled.SectionBodyPrequel>
              {`${tenant.displayName} (ID: ${tenant.id})`}
            </Styled.SectionBody>
          )}
          {hasPreinkingSupport && (
            <>
              <Styled.SectionTitle>Settings</Styled.SectionTitle>
              <Styled.SectionBody>
                <Styled.SectionBodyPrequel>
                  Pre-inking: {isPreinkingEnabled ? 'enabled' : 'disabled'}
                </Styled.SectionBodyPrequel>
                <DialogStyled.ButtonContainer>
                  <DialogStyled.Button
                    onClick={() => {
                      window.ffbecandroidjni?.set_preinking_enabled(true);
                      setIsPreinkingEnabled(true);
                    }}
                  >
                    Enable
                  </DialogStyled.Button>
                  <DialogStyled.Button
                    onClick={() => {
                      window.ffbecandroidjni?.set_preinking_enabled(false);
                      setIsPreinkingEnabled(false);
                    }}
                  >
                    Disable
                  </DialogStyled.Button>
                </DialogStyled.ButtonContainer>
              </Styled.SectionBody>
            </>
          )}
          <Styled.SectionTitle>Legal Notices</Styled.SectionTitle>
          <Styled.SectionBody>
            <Styled.LegalAnchor target="_blank" href={`${appServerUrl}/terms-of-service`}>
              Terms&nbsp;of&nbsp;Service
            </Styled.LegalAnchor>
            <Styled.LegalAnchor target="_blank" href={`${appServerUrl}/privacy-policy`}>
              Privacy&nbsp;Policy
            </Styled.LegalAnchor>
            <Styled.LegalAnchor target="_blank" href={`${appServerUrl}/third-party-notice`}>
              Third&nbsp;Party&nbsp;Notice
            </Styled.LegalAnchor>
          </Styled.SectionBody>
        </DialogStyled.Body>
      </>
    </Dialog>
  );
};
