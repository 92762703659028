import { AnyAction, createAction, createAsyncThunk, createReducer, ThunkAction } from '@reduxjs/toolkit';
import React from 'react';
import { CreatedImage } from '@flatfrog/ffbec';

import * as analytics from 'client/common/analytics';
import { RootState } from 'client/store';
import { addNotification, setFollower } from 'client/state/actions';

export type Client = {
  id: string;
  userId: string;
  name: string;
  clientType: 'desktop' | 'tablet' | 'phone' | 'room' | 'uwp';
  self?: boolean;
  isPresenter?: boolean;
  joinedAt: Date;
  pingLabel?: CreatedImage;
  atPage?: string;
};

export type Session = {
  failure?: string;
  id: string;
  name: string;
  ownerId: string;
  joinKey: string;
  fileId: string;
  legacy: boolean;
  pin: string;
  config: {
    undoSteps: number;
  };
  token: string;
  status: string;
  clients: Client[];
};

export type ServerSession = Omit<Session, 'pin'> & { pin: { code: string } };

const setSessionInternal = createAction<Session>('setSession');

export const setSessionClients = createAction<Client[]>('setSessionClients');

export const addSessionClient = createAsyncThunk('addSessionClient', (client: Client, { dispatch }) => {
  if (!client.self) {
    dispatch(
      addNotification({
        args: [client.name],
        pluralize: (args) => `${args[0]} and ${args.length - 1} others has joined the board`,
        content: `${client.name ? client.name : 'A room'} has joined the board`,
        type: 'addSessionClient',
      })
    );
  }
  return client;
});

export const removeSessionClient = createAsyncThunk('removeSessionClient', (client: Client, { dispatch }) => {
  if (!client.self) {
    dispatch(
      addNotification({
        args: [client.name],
        pluralize: (args) => `${args[0]} and ${args.length - 1} others has left the board`,
        content: `${client.name ? client.name : 'A room'} has left the board`,
        type: 'removeSessionClient',
      })
    );
  }
  return client;
});

export const setConnectedStatus = createAction<string>('setConnectedStatus');
export const connectFailure = createAction<string>('connectFailure');
export const resetSession = createAction('resetSession');

export const setPresenter = createAsyncThunk(
  'setPresenter',
  (
    payload: { clientId: string; isPresenter?: boolean },
    { getState, dispatch }
  ): { clientId: string; isPresenter: boolean } => {
    const state = getState() as RootState;
    const { clientId, isPresenter = true } = payload;
    const { session, isFollower } = state;

    const self = session.clients?.find((c) => c.self);
    const newPresenter = session.clients?.find((c) => c.id === clientId);

    if (self?.id !== clientId && newPresenter) {
      let content;

      if (isPresenter) {
        content = (
          <>
            {newPresenter.name} started presenting
            {!isFollower && (
              <button className="follow" onClick={() => dispatch(setFollower(true))}>
                Follow
              </button>
            )}
          </>
        );
      } else {
        content = `${newPresenter.name ? newPresenter.name : 'A room'} stopped presenting`;
      }

      dispatch(
        addNotification({
          content,
          type: 'setPresenter',
        })
      );
    }
    return { clientId, isPresenter };
  }
);

export const setPageForClient = createAction('setPageForClient', (clientId: string, pageId: string) => ({
  payload: { clientId, pageId },
}));
export const setPingLabelForClient = createAction(
  'setPingLabelForClient',
  (clientId: string, pingLabel: CreatedImage) => ({
    payload: {
      clientId,
      pingLabel,
    },
  })
);

export const updateSessionPin = createAction<string>('updateSessionPin');

export const setSession =
  (session: ServerSession): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    if (session.id) {
      analytics.setSession(session.id);
    }
    dispatch(setSessionInternal({ ...session, pin: session.pin.code }));
  };

export default createReducer({} as Session, (builder) =>
  builder
    .addCase(setSessionInternal, (state, { payload }) => ({ ...state, ...payload }))
    .addCase(setSessionClients, (state, { payload }) => ({ ...state, clients: payload }))
    .addCase(addSessionClient.fulfilled, (state, { payload }) => ({
      ...state,
      clients: [...(state.clients || []).filter((client) => client.id !== payload.id), payload],
    }))
    .addCase(removeSessionClient.fulfilled, (state, { payload }) => ({
      ...state,
      clients: (state.clients || []).filter((client) => client.id !== payload.id),
    }))
    .addCase(setConnectedStatus, (state, { payload }) => ({ ...state, status: payload }))
    .addCase(connectFailure, (state, { payload }) => ({ ...state, failure: payload }))
    .addCase(resetSession, () => ({} as Session))
    .addCase(setPresenter.fulfilled, (state, { payload: { clientId, isPresenter } }) => ({
      ...state,
      clients: [...state.clients.map((client) => ({ ...client, isPresenter: client.id === clientId && isPresenter }))],
    }))
    .addCase(setPageForClient, (state, { payload }) => {
      if (!state.clients) {
        return state;
      }
      return {
        ...state,
        clients: [
          ...state.clients.map((client) =>
            client.id === payload.clientId
              ? {
                  ...client,
                  atPage: payload.pageId.toString(),
                }
              : client
          ),
        ],
      };
    })
    .addCase(setPingLabelForClient, (state, { payload }) => {
      if (!state.clients) {
        return state;
      }
      const label = payload.pingLabel;
      const clients = [
        ...state.clients.map((client) => {
          if (client.id === payload.clientId) {
            return { ...client, pingLabel: label };
          }
          return client;
        }),
      ];

      return {
        ...state,
        clients: [
          ...clients.map((client) => {
            if (client.pingLabel?.imageName === label.imageName) {
              return { ...client, pingLabel: label };
            }
            return client;
          }),
        ],
      };
    })
    .addCase(updateSessionPin, (state, { payload }) => ({
      ...state,
      pin: payload,
    }))
);
