import styled from 'styled-components';

import { MEDIUM_BREAK } from 'client/common/util';

export const StickyNoteCanvas = styled.canvas<{ color: string; fade?: boolean }>`
  background: ${(props) => props.color};
  opacity: ${(props) => (props.fade ? 0.5 : 1)};
  box-shadow: 0 3px 6px #00000029, inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

export const EditInfo = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  font-size: 16px;
  font-weight: 500;
  color: #616060;
  padding-top: 48px;
  opacity: 0.3;
  width: 100%;
  text-align: center;
  background: url('/icons/icn_pencils.svg') top center no-repeat;
  background-size: 32px;
  user-select: none;

  @media only screen and (max-width: ${MEDIUM_BREAK}px) {
    transform: translate(-50%, -50%) scale(0.7);
  }
`;

export const StickyNoteWrapper = styled.div<{ width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  display: block;
  margin: 10px auto;
  &:first-child {
    margin-top: 0;
  }

  ${StickyNoteCanvas} {
    max-width: ${(props) => props.width}px;
    max-height: ${(props) => props.height}px;
  }

  &.mobile {
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0;
    flex-grow: 1;
    height: 100%;
    text-align: center;
    display: flex;

    ${StickyNoteCanvas} {
      width: auto;
      height: auto;
      max-width: 100%;
      position: relative;
      max-height: 100%;
    }
  }

  &.to-be-sent {
    transition: transform 300ms ease-out, opacity 200ms ease-out;
    opacity: 0;
    position: fixed;
    transform: translateX(200%);
    z-index: 99;

    ${StickyNoteCanvas} {
      pointer-events: none;
    }
  }

  animation: scale--StickyNoteWrapper 300ms ease-in-out;

  @keyframes scale--StickyNoteWrapper {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      opacity: 100%;
    }

    100% {
      transform: scaleY(100%);
    }
  }
`;

export const SendBubble = styled.button.attrs({
  type: 'button',
})`
  display: block;
  outline: none;
  position: absolute;
  right: 0;
  top: 50%;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(50%, -50%);
  background: #fff url(/icons/icn_send.svg) center center no-repeat;
  background-size: 16px auto;
  box-shadow: 0 1px 2px #00000026;
  border: 1px solid #0000001a;
  transition: transform 100ms linear, box-shadow 100ms linear;

  &:hover {
    box-shadow: none;
    transform: translate(50%, calc(-50% + 2px));
  }
`;

export const RecallBubble = styled(SendBubble)`
  background-image: url(/icons/icn_recall.svg);
`;
