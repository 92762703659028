import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import useEventListener from 'client/hooks/useEventListener';

import * as Styles from './ContextMenu-styled';

type Item = {
  id?: string;
  title: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  hidden?: boolean;
  selected?: boolean;
};

type ItemGroup = Item[];

interface Props {
  items: ItemGroup[];
}

export const ContextMenu: React.FC<Props> = ({ items, children }) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>();

  useEventListener<CustomEvent<HTMLInputElement>>('closemenus', (e) => {
    if (!ref.current?.contains(e.detail)) {
      setActive(false);
    }
  });

  return (
    <Styles.ContextMenu
      ref={ref}
      className={classNames({ active })}
      onMouseLeave={() => setActive(false)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setActive(true);
      }}
    >
      {children}
      <Styles.MenuContainer>
        {items.map((group, i) => {
          const option = group.some((item) => 'selected' in item);

          return (
            <Styles.MenuItemGroup key={`group-${i}`}>
              {group
                .filter((item) => !item.hidden)
                .map(({ id, onClick, selected, title, disabled }, j) => (
                  <Styles.MenuItem
                    id={id}
                    disabled={disabled}
                    className={classNames({ selected, option })}
                    key={j}
                    onClick={() => {
                      onClick();
                      setActive(false);
                    }}
                  >
                    {title}
                  </Styles.MenuItem>
                ))}
            </Styles.MenuItemGroup>
          );
        })}
      </Styles.MenuContainer>
    </Styles.ContextMenu>
  );
};
