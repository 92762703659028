import * as microsoftTeams from '@microsoft/teams-js';
import React, { useEffect } from 'react';

import { useAuth } from 'client/hooks/useAuth';
import useLocalStorage from 'client/hooks/useLocalStorageState';

const TeamsAuthRedirect: React.FC = () => {
  const auth = useAuth();
  microsoftTeams.initialize();
  const [, setIntendedRedirect] = useLocalStorage('intendedRedirect', null);

  useEffect(() => {
    if (!auth.loading) {
      if (auth.user) {
        if (auth.user.displayName) {
          microsoftTeams.authentication.notifySuccess();
          setIntendedRedirect(null);
        }
      } else if (!auth.user) {
        microsoftTeams.authentication.notifyFailure();
        setIntendedRedirect(null);
      }
    }
  }, [auth.user, auth.loading, auth.user?.displayName]);

  return <div></div>;
};

export default TeamsAuthRedirect;
