import { createAction, createReducer } from '@reduxjs/toolkit';

import { TextAlignments } from 'client/components/Common/text-alignments';

export type LastTextBlockProps = {
  textColor: string;
  textAlign: TextAlignments;
  textSize: number;
  backgroundColor: string;
  outlineColor: string;
};

export const setTextBlockLastProps = createAction<LastTextBlockProps>('setTextBlockLastProps');

export default createReducer({} as LastTextBlockProps, (builder) =>
  builder.addCase(setTextBlockLastProps, (state, action) => ({ ...state, ...action.payload }))
);
