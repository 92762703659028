import React from 'react';
import HubspotForm from 'react-hubspot-form';
import styled from 'styled-components';

import { FloatingPanel } from 'client/components/Common/common-styles';
import Dialog from 'client/components/Common/dialog';
import { useActions } from 'client/hooks/useActions';

const ToggleButton = styled.div.attrs((props) => ({
  style: props.hidden ? { opacity: 0 } : {},
}))`
  ${FloatingPanel};
  position: fixed;
  left: 16px;
  bottom: 16px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #fff url(/icons/icn_feedback_smiley.svg) center center no-repeat;
  cursor: pointer;
`;

interface Props {
  hidden: boolean;
}

export const FeedbackDialogButton: React.FC<Props> = ({ hidden }) => {
  const { setShowFeedbackModal } = useActions();

  return (
    <ToggleButton
      data-tip="Give Feedback"
      data-place="right"
      hidden={hidden}
      onPointerUp={() => {
        setShowFeedbackModal(true);
      }}
    />
  );
};

const Title = styled.h2`
  font-size: 18px;
  font-weight: Semibold;
  margin: 0 0 16px 0;
  color: #242424;
`;

const FeedbackDialog: React.FC = () => {
  const { setShowFeedbackModal } = useActions();
  const hide = () => setShowFeedbackModal(false);

  return (
    <>
      <Dialog cover noButtons onCancel={hide}>
        <Title>Give Feedback</Title>
        <HubspotForm
          portalId="6409553"
          formId="45e560f3-e490-4577-9442-57d8016a5ac5"
          onSubmit={() => setTimeout(hide, 200)}
          onReady={() => console.log('Feedback form ready')}
          loading={<div>Loading...</div>}
        />
      </Dialog>
    </>
  );
};

export default FeedbackDialog;
