import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import getFileName from 'client/common/file-names';
import * as DialogStyled from 'client/components/Common/dialog-styled';
import SmallCheckbox from 'client/components/Common/small-checkbox';
import { useActions } from 'client/hooks/useActions';
import { useSelector } from 'client/hooks/useSelector';

import { ModalBackDrop, ButtonGroup, CheckboxPanel, DownloadProgress } from './styled';

const SessionEndedDialog: React.FC = () => {
  const { initialLoadingDone, isMsTeamsApp, loadedFileInfo, room, showSessionEndedDialog } = useSelector((state) => ({
    initialLoadingDone: state.initialLoadingDone,
    isMsTeamsApp: state.isMsTeamsApp,
    loadedFileInfo: state.loadedFileInfo,
    room: state.room,
    showSessionEndedDialog: state.showSessionEndedDialog,
  }));

  const { disconnectClient, downloadPdfFile, toggleSessionEndedDialog } = useActions();

  const [downloadFileOnClose, setDownloadFileOnClose] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const history = useHistory();

  const handlePdfProgress = ({ detail }: CustomEvent<{ page: number; totalPages: number }>) => {
    setDownloadProgress(detail.page / detail.totalPages);
    if (detail.page === detail.totalPages) {
      setTimeout(() => {
        // Close session when pdf is ready
        setIsDownloading(false);
        window.removeEventListener('export-pdf-progress', handlePdfProgress);
        toggleSessionEndedDialog(false);
        disconnectClient();
        window.onbeforeunload = null;
        window.location.href = '/';
      }, 1500);
    }
  };

  return showSessionEndedDialog.show ? (
    <>
      <ModalBackDrop className="active" />
      <DialogStyled.ModalContainer className="active cover" style={{ overflow: 'visible' }}>
        <DialogStyled.Title>{showSessionEndedDialog.errorMessage ?? 'The session was ended'}</DialogStyled.Title>
        {initialLoadingDone && !isMsTeamsApp && (
          <CheckboxPanel>
            <SmallCheckbox
              disabled={isDownloading}
              checked={downloadFileOnClose}
              onChange={() => setDownloadFileOnClose(!downloadFileOnClose)}
            >
              Download a PDF before leaving
            </SmallCheckbox>
          </CheckboxPanel>
        )}
        <ButtonGroup>
          <DialogStyled.NextButton
            disabled={isDownloading}
            id="sessionEndedDialog"
            onClick={() => {
              if (downloadFileOnClose && initialLoadingDone) {
                window.addEventListener('export-pdf-progress', handlePdfProgress);
                setIsDownloading(true);
                downloadPdfFile(getFileName('pdf', loadedFileInfo));
                return; // Session is closed later, when pdf download is finished
              }

              toggleSessionEndedDialog(false);
              disconnectClient();
              if (isMsTeamsApp) {
                history.replace('/vc/teams/error', { boardInfo: loadedFileInfo });
              } else if (room) {
                history.replace('/room');
              } else {
                history.replace('/dashboard');
              }
            }}
          >
            Leave
          </DialogStyled.NextButton>
        </ButtonGroup>
        {downloadProgress > 0 ? <DownloadProgress style={{ transform: `scaleX(${downloadProgress})` }} /> : null}
      </DialogStyled.ModalContainer>
    </>
  ) : null;
};
export default SessionEndedDialog;
