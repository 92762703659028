import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CONNECTION_STATUS } from 'client/common/util';
import { useActions } from 'client/hooks/useActions';
import { useAuth } from 'client/hooks/useAuth';
import { useResetConnectionAndSession } from 'client/hooks/useResetConnectionAndSession';
import { useSelector } from 'client/hooks/useSelector';

const Room: React.FC = () => {
  const { session } = useSelector((state) => ({
    session: state.session,
  }));

  const {
    connectRoomSocket,
    setIsRoom,
    tryConnectNew,
    setTenantByFirebaseId,
    setRoomLoadingMessage,
    setStartscreenVisiblity,
  } = useActions();

  const history = useHistory();
  const auth = useAuth();
  const location = useLocation<{ fromSession?: boolean }>();

  const [canReachCollabserver, setCanReachCollabserver] = useState(true);

  useEffect(() => {
    const collabServerCheckInterval = setInterval(() => {
      fetch(`${window.INITIAL_STATE.collaborationServerUrl}/`)
        .then((res) => {
          const success = res.status === 200;
          if (!success) {
            setRoomLoadingMessage("We're having trouble reaching the server...");
          }
          if (success && !canReachCollabserver) {
            connectRoomSocket();
            tryConnectNew({ fileInfo: { new: true } });
          }
          setCanReachCollabserver(success);
        })
        .catch(() => {
          setRoomLoadingMessage("We're having trouble reaching the server...");
          setCanReachCollabserver(false);
        });
    }, 2000);
    return () => clearInterval(collabServerCheckInterval);
  }, [canReachCollabserver]);

  const resetConnectionAndSession = useResetConnectionAndSession();

  useEffect(() => {
    setStartscreenVisiblity('visible');
    resetConnectionAndSession();
    setIsRoom(true);
  }, []);

  useEffect(() => {
    if (session?.status === CONNECTION_STATUS.waiting) {
      history.push('/session', { from: 'room' });
    }
  }, [session]);

  useEffect(() => {
    if (!auth.loading) {
      if (auth.user) {
        if (auth.user.room) {
          if (auth.user.tenantId) {
            setTenantByFirebaseId(auth.user.tenantId);
          }
          connectRoomSocket();
          tryConnectNew({ fileInfo: { new: true } });
        } else {
          auth.signOut();
        }
      } else {
        auth.signInRoom();
      }
      // props.agreeToTermsAndPolicy(true);
    }
  }, [auth.loading, auth.user]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (canReachCollabserver) {
        setRoomLoadingMessage('This is taking longer than usual...');
      }
    }, 10000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [canReachCollabserver]);

  useEffect(() => {
    if (location?.state?.fromSession) {
      setRoomLoadingMessage('Resetting...');
    }
  }, [location]);

  useEffect(
    () => () => {
      setRoomLoadingMessage('');
    },
    []
  );

  return null;
};

export default Room;
