import styled from 'styled-components';

export const Container = styled.div`
  width: 588px;
  height: 396px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: normal;
  align-items: center;
  text-align: left;
  z-index: 5;
  background: #f4f4f4;
  visibility: hidden;
  border-radius: 6px;

  &.active {
    visibility: visible;
  }
`;

export const AlignGroup = styled.div`
  margin-right: 44px;
`;

export const AlignButton = styled.button`
  display: inline-block;
  border: 1px solid #24242426;
  width: 32px;
  height: 32px;
  padding: 0;
  border-right: none;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
  background-color: #ffffff;
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: #24242426;
  }

  &.l {
    border-radius: 5px 0 0 5px;
    background-image: url(/icons/icn_text_align_left.svg);
  }

  &.c {
    background-image: url(/icons/icn_text_align_center.svg);
  }

  &.r {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #24242426;
    background-image: url(/icons/icn_text_align_right.svg);
  }
`;

export const StyleButton = styled.div.attrs<{
  active: boolean;
}>((props) => ({
  className: props.active ? 'active textColor' : 'textColor',
}))<{
  active: boolean;
  color: string;
  textColor: string;
  outlineColor: string;
}>`
  padding: 0;
  display: inline-block;
  margin-left: 8px;
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border: 2px solid ${(props) => props.outlineColor};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  box-shadow: none;

  &:after {
    content: 'Aa';
    font-size: 17px;
    position: absolute;
    top: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-family: Open Sans;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  transition: transform 60ms ease, box-shadow 0.3s ease-in-out;

  &:hover,
  &.active {
    box-shadow: 0 0 0 3px #00000029;
  }
`;

export const TextArea = styled.textarea<{ placeholderColor: string }>`
  overflow: hidden;
  max-width: 540px;
  max-height: 180px;
  resize: none;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 1.155;
  wrap: nowrap;
  &::placeholder {
    color: ${(props) => props.placeholderColor};
  }
`;
