/* eslint-disable camelcase */
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, StoreEnhancer } from 'redux';
import { createLogger } from 'redux-logger';

import roomSocketMiddleware from 'client/middleware/roomsocket';
import sessionSocketMiddleware from 'client/middleware/sessionsocket';
import 'client/middleware/items';
import reducers from 'client/state/reducers';

const persistState = require('redux-localstorage');

declare global {
  interface Window {
    becType?: string;
    // eslint-disable-next-line camelcase
    ffbecandroidjni?: {
      toggle_pip_window_state: () => void;
      close_pip_window: () => void;
      set_preinking_enabled: (enabled: boolean) => void;
      get_preinking_enabled: () => boolean;
    };
    electronApi?: {
      machineName: string;
      machineId: string;
      receive: (channel: string, callback: (data: string) => void) => void;
      send(message: string): void;
      sendSync(message: string): unknown;
      sendLogs(options: { roomId: string; token: string; url: string }): Promise<boolean>;
      exit(): void;
      // eslint-disable-next-line camelcase
      ffbec_addon?: object;
    };
    INITIAL_STATE: {
      revision: {
        webApp: {
          long: string;
          short: string;
          message: string;
          date: string;
        };
      };
      gcpEnv: string;
      env: string;
      featureFlags: {
        enableRoomPenUI: boolean;
        enableTeams: boolean;
        enableLineTool: boolean;
      };
      room: boolean;
      roomAuth: string;
      appServerUrl: string;
      collaborationServerUrl: string;
      tenantId?: string;
      firebaseConfig?: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
        measurementId: string;
      };
    };
  }
}

const middlewares = [sessionSocketMiddleware, roomSocketMiddleware];

if (window.INITIAL_STATE.env === 'development' || window.INITIAL_STATE.gcpEnv === 'test') {
  const loggerMiddleware = createLogger({ collapsed: true });

  middlewares.push(loggerMiddleware);
}

const persistStateEnhancer = persistState(
  ['signatureText', 'displaySignatureByDefault', 'hasAgreedToTermsAndPolicy', 'localItems', 'allowMobile'],
  {
    key: 'ffcc',
    slicer: (paths: (keyof RootState)[]) => (state: RootState) => {
      // We use a custom slicer here to only save the items marked with persist
      return Object.fromEntries(
        paths.map((key) => [key, key === 'signatureText' && state[key] === null ? '' : state[key]])
      );
    },
  }
) as StoreEnhancer;

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false, immutableCheck: false }).concat(middlewares),
  enhancers: [persistStateEnhancer],
});

const rootReducer = combineReducers({ ...reducers });

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
