import React, { useCallback, useState } from 'react';

import * as Styled from 'client/components/Dashboard/admin/integrations/autosync/styled';
import * as Assets from 'client/components/Dashboard/admin/assets';
import { useFetch } from 'client/hooks/useFetch';
import { AutoSyncBinding, AutoSyncBindingProvider } from 'client/components/Dashboard/admin/types';
import List from 'client/components/Dashboard/admin/list/list';
import User from 'client/components/Common/user';
import { ContextMenu } from 'client/components/Common/ContextMenu';
import AddAutoSyncDialog from 'client/components/Dashboard/admin/integrations/autosync/add-dialog';
import { getFirebaseAuth } from 'client/common/firebase';
import * as rest from 'client/services/rest';
import { useSelector } from 'client/hooks/useSelector';
import Dialog from 'client/components/Common/dialog';

interface Props {
  provider: AutoSyncBindingProvider;
}

const AutosyncBindings: React.FC<Props> = ({ provider }) => {
  const { tenant, collaborationServerUrl } = useSelector((state) => ({
    collaborationServerUrl: state.collaborationServerUrl,
    tenant: state.tenant,
  }));

  const autosyncBindings = useFetch<AutoSyncBinding[]>(`i/${provider}/autosync`, [], (data) => data);

  const [showAddAutoSyncDialog, setShowAddAutoSyncDialog] = useState(false);
  const [bindingToDelete, setBindingToDelete] = useState<AutoSyncBinding | null>(null);

  const deleteBinding = useCallback(async () => {
    const token = await getFirebaseAuth().currentUser.getIdToken();
    await rest.makeRequest({
      method: 'DELETE',
      url: `${collaborationServerUrl}/tenant/${tenant.id}/i/${provider}/autosync/${bindingToDelete.id}`,
      authorizationToken: { auth: token },
    });

    setBindingToDelete(undefined);
    await autosyncBindings.fetch();
  }, [collaborationServerUrl, tenant, bindingToDelete]);

  return (
    <>
      <Styled.SectionHeader>
        <Styled.SectionHeaderTitle>Auto-Sync</Styled.SectionHeaderTitle>
        <Styled.SectionHeaderSubtitle>
          Auto-Sync enables you to automatically open the meeting’s attached FlatFrog Board on a Room device when
          joining a Teams meeting.
        </Styled.SectionHeaderSubtitle>
      </Styled.SectionHeader>
      <List
        items={autosyncBindings.data}
        itemKey={(binding) => binding.id}
        itemColumns={[
          {
            id: 'meeting-user',
            title: 'Meeting User',
            header: <Styled.FlexBox weight={9} />,
            content: (binding) => (
              <Styled.FlexBox weight={9}>
                <User displayName={binding.meetingUser.name} image={binding.meetingUser.photo.blobUrl} />
              </Styled.FlexBox>
            ),
            sort: (items, asc) =>
              items.sort((a, b) => a.meetingUser.name.localeCompare(b.meetingUser.name) * (asc ? 1 : -1)),
          },
          {
            id: 'binding-icon',
            header: <Styled.FlexBox weight={2} />,
            content: () => (
              <Styled.FlexBox weight={2}>
                <Assets.AdminBindingIcon />
              </Styled.FlexBox>
            ),
          },
          {
            id: 'room-user',
            title: 'Room User',
            header: <Styled.FlexBox weight={11} />,
            content: (binding) => (
              <Styled.FlexBox weight={11}>
                <User displayName={binding.roomUser.name} room />
              </Styled.FlexBox>
            ),
            sort: (items, asc) => items.sort((a, b) => a.roomUser.name.localeCompare(b.roomUser.name) * (asc ? 1 : -1)),
          },
          {
            id: 'menu',
            header: <Styled.FlexBox weight={2} />,
            content: (binding) => (
              <Styled.FlexBox weight={2}>
                <ContextMenu
                  items={[
                    [
                      {
                        title: 'Remove',
                        disabled: autosyncBindings.loading,
                        onClick: () => setBindingToDelete(binding),
                      },
                    ],
                  ]}
                >
                  <Styled.ContextMenuButton />
                </ContextMenu>
              </Styled.FlexBox>
            ),
          },
        ]}
        loading={autosyncBindings.loading}
        itemNoun="auto syncs"
        onAddElementClick={() => setShowAddAutoSyncDialog(true)}
        addElementText="Add Auto Sync"
      />
      {showAddAutoSyncDialog && (
        <AddAutoSyncDialog
          provider={provider}
          onBindingAdded={() => {
            setShowAddAutoSyncDialog(false);
            autosyncBindings.fetch();
          }}
          onClose={() => setShowAddAutoSyncDialog(false)}
        />
      )}
      {bindingToDelete && (
        <Dialog
          cancelText="Cancel"
          nextText="Remove"
          onCancel={() => setBindingToDelete(undefined)}
          onNext={deleteBinding}
          cover
        >
          <Styled.DialogTitle>Remove Auto Sync</Styled.DialogTitle>
          <Styled.DialogBody>Are you sure you want to remove this Auto Sync?</Styled.DialogBody>
        </Dialog>
      )}
    </>
  );
};

export default AutosyncBindings;
