import React, { useEffect, useRef, useState } from 'react';
import { RgbColorPicker } from 'react-colorful';

import { AppServerUrl } from 'client/components/Common/AppServerUrl';
import QrCode from 'client/components/Home/qrcode';
import { useCurrentTime } from 'client/hooks/useCurrentTime';
import { useSelector } from 'client/hooks/useSelector';
import Pin from 'client/components/Pin';
import { OpenInstructions } from 'client/components/Home/OpenInstructions';
import { useActions } from 'client/hooks/useActions';
import { useStartScreenColor } from 'client/hooks/useStartScreenColor';
import { CONNECTION_STATUS } from 'client/common/util';
import * as analytics from 'client/common/analytics';
import { useAppVersion } from 'client/hooks/useAppVersion';

import * as Styled from './styled';

const StartScreen: React.FC = () => {
  const { session, startscreenVisibility, roomLoadingMessage } = useSelector((state) => ({
    session: state.session,
    startscreenVisibility: state.startscreenVisibility,
    roomLoadingMessage: state.roomLoadingMessage,
  }));

  const [showOpenInstructions, setShowOpenInstructions] = useState(false);
  const [currentDate, currentTime] = useCurrentTime();
  const [showColorPickerAndVersion, setShowColorPickerAndVersion] = useState(false);
  const [becType, setBecType] = useState('');

  const { appVersion, appNextVersion } = useAppVersion();

  const { color, setColor, resetColor } = useStartScreenColor();

  const { setStartscreenVisiblity } = useActions();

  useEffect(() => {
    if (startscreenVisibility === 'exiting') {
      setShowColorPickerAndVersion(false);
    }
  }, [startscreenVisibility]);

  useEffect(() => {
    window.electronApi?.receive('isFirstRun', (isFirstRun) => {
      console.log(`isFirstRun = ${isFirstRun}`);
      if (isFirstRun === 'true') {
        analytics.roomFirstRun();
      }
    });
    window.electronApi?.send('isFirstRun');
  }, []);

  useEffect(() => {
    let becTypeText = '';
    if (window.becType === 'win32') {
      becTypeText = 'Windows';
    } else if (window.becType === 'linux') {
      becTypeText = 'Linux';
    } else if (window.becType === 'android') {
      becTypeText = 'Android';
    } else if (window.becType === 'wasm') {
      becTypeText = 'WASM';
    }
    setBecType(becTypeText);
  }, [window.becType]);

  useEffect(() => {
    if (startscreenVisibility === 'visible' && session.clients?.length > 1) {
      setStartscreenVisiblity('exiting');
    }
  }, [startscreenVisibility, session]);

  const timerRef = useRef(null);

  const showColorPicker = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => setShowColorPickerAndVersion(false), 5000);

    setShowColorPickerAndVersion(true);
  };

  useEffect(() => () => clearTimeout(timerRef.current), []);

  if (startscreenVisibility === 'hidden') {
    return null;
  }

  const sessionConnected = session.status === CONNECTION_STATUS.connected;

  return (
    <Styled.StartScreenWrapper>
      {startscreenVisibility === 'visible' && <Styled.BlurLayer />}
      {showOpenInstructions ? (
        <OpenInstructions hide={() => setShowOpenInstructions(false)} />
      ) : (
        <Styled.ScrollLayer
          className={startscreenVisibility === 'exiting' ? 'exiting' : ''}
          onClick={(e) => {
            e.stopPropagation();
            if (sessionConnected) {
              setStartscreenVisiblity('exiting');
            }
          }}
          onAnimationEnd={(e) => {
            if (e.animationName === 'exit--scroll-layer') {
              setStartscreenVisiblity('hidden');
            }
          }}
        >
          <Styled.GradientLayer $color={color} />
          <Styled.UILayer>
            <Styled.TopLeftContainer>
              <Styled.Clock>{currentTime}</Styled.Clock>
              <Styled.Date>{currentDate}</Styled.Date>
            </Styled.TopLeftContainer>
            <Styled.TopRightContainer
              onClick={(e) => {
                e.stopPropagation();
                setShowOpenInstructions(true);
              }}
              visible={sessionConnected}
            >
              <Styled.BoardCode>
                <Pin />
              </Styled.BoardCode>
              <Styled.InstructionText>
                <Styled.JoinBoardText>
                  Open board or join room at <br /> <AppServerUrl />
                </Styled.JoinBoardText>
              </Styled.InstructionText>
              <Styled.BoardQRCode>
                <QrCode />
              </Styled.BoardQRCode>
            </Styled.TopRightContainer>
            <Styled.TapText>
              {sessionConnected ? (
                <>
                  Tap to <strong>start</strong> whiteboarding
                </>
              ) : (
                roomLoadingMessage
              )}
            </Styled.TapText>
            <Styled.Logo
              onClick={(e) => {
                e.stopPropagation();
                showColorPicker();
              }}
            >
              <Styled.VersionTextContainer $display={showColorPickerAndVersion}>
                <Styled.VersionText>
                  {appVersion}
                  {appNextVersion && '*'}
                </Styled.VersionText>
                <Styled.BecText>{becType}</Styled.BecText>
              </Styled.VersionTextContainer>
            </Styled.Logo>
            <Styled.BottomRightContainer>
              {window.electronApi ? (
                <>
                  <Styled.OffButton
                    onClick={(e) => {
                      e.stopPropagation();
                      window.electronApi?.exit();
                    }}
                  />
                  {appNextVersion && 'Restart to update'}
                </>
              ) : (
                <> </>
              )}
            </Styled.BottomRightContainer>
            {showColorPickerAndVersion && (
              <Styled.ColorPickerPopup>
                <RgbColorPicker
                  onClick={(e) => e.stopPropagation()}
                  color={color}
                  onChange={(c) => {
                    showColorPicker();
                    setColor(c);
                  }}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    resetColor();
                  }}
                >
                  Reset
                </button>
              </Styled.ColorPickerPopup>
            )}
          </Styled.UILayer>
        </Styled.ScrollLayer>
      )}
    </Styled.StartScreenWrapper>
  );
};

export default StartScreen;
