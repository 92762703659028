import { createAsyncThunk } from '@reduxjs/toolkit';
import ZCanvas, { Action, CreatedImage } from '@flatfrog/ffbec';
import { v4 as uuidv4 } from 'uuid';
import { isMobileOnly } from 'react-device-detect';
import { ZActions, ZShapes } from '@flatfrog/ffbec/js/zwasm';
import { toByteArray } from 'base64-js';
import ZImage from '@flatfrog/ffbec/js/zimage';
import { getNbrColorsForString } from '@flatfrog/ffbec/js/zutil';

import { IMAGE, packStickyNoteMetadata, packTextBlockMetadata, STICKER } from 'client/components/Common/types';
import { BackgroundColors, findBackgroundColor } from 'client/components/StickyNote/colors';
import { RootState } from 'client/store';
import {
  currentStickyNoteEdit,
  currentTextBlockEdit,
  deleteItemCanvas,
  deleteLocalItem,
  handleInternalPaste,
  Item,
  setZAction,
} from 'client/state/actions';
import { getFirebaseAuth } from 'client/common/firebase';
import { client, MUTATION_DELETE_ITEM, QUERY_MY_ITEMS } from 'client/common/graphql';
import { getPaperActionsAndInkImages } from 'client/common/helpers/ZCanvasHelpers';

function loadImage(image: HTMLImageElement, url: string) {
  return new Promise((resolve) => {
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.src = url;
  });
}

function getImageBytes(canvas: HTMLCanvasElement, type = 'image/png') {
  return new Promise<ArrayBuffer>((resolve) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('loadend', (e) => {
      resolve(e.target.result as ArrayBuffer);
    });

    canvas.toBlob((blob) => fileReader.readAsArrayBuffer(blob), type);
  });
}

export const addMultipleStickersToWhiteboardComponent = createAsyncThunk(
  'addMultipleStickersToWhiteboardComponent',
  async (
    payload: {
      file: ArrayBuffer | null;
      multiStickerOptions: { layer: number; position: { x: number; y: number } }[] | null;
      options: PaperOptions;
    },
    { dispatch }
  ) => {
    const { multiStickerOptions, file, options } = payload;

    const paperIds: number[] = [];

    const image =
      (options.imageId && ZCanvas.image.getById(options.imageId)) ?? (await ZCanvas.image.loadAsync({ data: file }));

    ZCanvas.beginCommandGroup();
    multiStickerOptions.forEach((stickerOptions) => {
      const { position, layer } = stickerOptions;
      const paperId = ZCanvas.paper.createSticker(
        position.x,
        position.y,
        position.x,
        position.y,
        80,
        80,
        image,
        null,
        0,
        true
      );
      ZCanvas.paper.setPaperLayer(paperId, layer);
      paperIds.push(paperId);
    });
    ZCanvas.endCommandGroup();

    if (options.selectPaper) {
      paperIds.forEach((paperId, index) => {
        ZCanvas.paper.select(paperId, index > 0);
      });
    }

    await dispatch(setZAction(ZActions.SELECT, ZShapes.FREEFORM, true));
  }
);

const IMAGE_SCALE_LIMIT = 4096 * 2160;

function getDownscaledSize(width: number, height: number, scaleLimit: number) {
  let newHeight = height;
  let newWidth = width;

  while (newHeight * newWidth > scaleLimit) {
    newHeight /= 2;
    newWidth /= 2;
  }
  return { width: newWidth, height: newHeight };
}

export const prepareAndPublishImage = createAsyncThunk(
  'prepareAndPublishImage',
  async (payload: { file: File; options: PaperOptions }, { dispatch }) => {
    const { file, options } = payload;

    try {
      const dataUrl = await new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result as string);
        };
        fileReader.onerror = () => {
          reject(new Error('Error reading'));
        };
        fileReader.readAsDataURL(file);
      });

      const img = new Image();
      await loadImage(img, dataUrl);

      let arrayBufferBlob: ArrayBuffer;
      if (img.width * img.height <= IMAGE_SCALE_LIMIT) {
        // Below treshold, just transform the data url to array buffer
        arrayBufferBlob = await fetch(dataUrl)
          .then((res) => res.blob())
          .then(
            (blob) =>
              new Promise<ArrayBuffer>((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                  resolve(e.target.result as ArrayBuffer);
                };
                fileReader.onerror = () => {
                  reject(new Error('Error reading'));
                };
                fileReader.readAsArrayBuffer(blob);
              })
          );
      } else {
        // Scale down to below threshold
        const newSize = getDownscaledSize(img.width, img.height, IMAGE_SCALE_LIMIT);
        const canvas = document.createElement('canvas');
        canvas.width = newSize.width;
        canvas.height = newSize.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        arrayBufferBlob = await getImageBytes(canvas, file.type);
      }

      await dispatch(
        addPaperToWhiteboardComponent({
          file: arrayBufferBlob,
          options: {
            type: IMAGE,
            ...options,
            file,
          },
        })
      );
    } catch (err) {
      console.log(err);
    }
  }
);

export const prepareAndPublishSticker = createAsyncThunk(
  'prepareAndPublishSticker',
  async (
    payload: {
      file?: File;
      url?: string | null;
      imageId?: number;
      multiStickerOptions?: { layer: number; position: { x: number; y: number } }[] | null;
      options: { selectPaper?: boolean; addedInBackground?: boolean; position?: { x: number; y: number } };
    },
    { dispatch }
  ) => {
    const { file, imageId, multiStickerOptions, options, url } = payload;

    if (imageId) {
      // Sticker with image that exists in the session
      try {
        if (multiStickerOptions) {
          await dispatch(
            addMultipleStickersToWhiteboardComponent({
              file: null,
              multiStickerOptions,
              options: {
                imageId,
                ...options,
              },
            })
          );
        } else {
          await dispatch(
            addPaperToWhiteboardComponent({
              file: null,
              options: {
                type: STICKER,
                imageId,
                ...options,
              },
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    } else if (url) {
      // Default sticker
      try {
        const arrayBufferBlob = await fetch(url)
          .then((res) => res.blob())
          .then(
            (blob) =>
              new Promise<ArrayBuffer>((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                  resolve(e.target.result as ArrayBuffer);
                };
                fileReader.onerror = () => {
                  reject(new Error('Error reading'));
                };
                fileReader.readAsArrayBuffer(blob);
              })
          );
        if (multiStickerOptions) {
          await dispatch(
            addMultipleStickersToWhiteboardComponent({
              file: arrayBufferBlob,
              multiStickerOptions,
              options,
            })
          );
        } else {
          await dispatch(
            addPaperToWhiteboardComponent({
              file: arrayBufferBlob,
              options: {
                type: STICKER,
                ...options,
              },
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    } else if (file) {
      // Custom sticker
      try {
        const dataUrl = await new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            resolve(e.target.result as string);
          };
          fileReader.onerror = () => {
            reject(new Error('Error reading'));
          };
          fileReader.readAsDataURL(file);
        });

        const img = new Image();
        await loadImage(img, dataUrl);

        let arrayBufferBlob;
        const stickerSideLength = 80;
        const longestSide = Math.max(img.width, img.height);
        const scale = stickerSideLength / longestSide;

        if (scale > 0.95 && scale < 1.05) {
          // Below treshold, just transform the data url to array buffer
          arrayBufferBlob = await fetch(dataUrl)
            .then((res) => res.blob())
            .then(
              (blob) =>
                new Promise<ArrayBuffer>((resolve, reject) => {
                  const fileReader = new FileReader();
                  fileReader.onload = (e) => {
                    resolve(e.target.result as ArrayBuffer);
                  };
                  fileReader.onerror = () => {
                    reject(new Error('Error reading'));
                  };
                  fileReader.readAsArrayBuffer(blob);
                })
            );
        } else {
          // Scale down to below threshold
          const canvas = document.createElement('canvas');
          canvas.width = img.width * scale;
          canvas.height = img.height * scale;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          arrayBufferBlob = await getImageBytes(canvas, file.type);
        }

        if (multiStickerOptions) {
          await dispatch(
            addMultipleStickersToWhiteboardComponent({
              file: arrayBufferBlob,
              multiStickerOptions,
              options: {
                ...options,
                file,
              },
            })
          );
        } else {
          await dispatch(
            addPaperToWhiteboardComponent({
              file: arrayBufferBlob,
              options: {
                type: STICKER,
                ...options,
                file,
              },
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
);

/*
 Sticky note is sent to board
 */
export const prepareAndPublishStickyNote = createAsyncThunk(
  'prepareAndPublishStickyNote',
  async (
    {
      file,
      options,
    }: {
      file: ArrayBuffer | null;
      options: {
        color: string;
        itemId?: { new: true } | string;
        metadata: string;
        selectPaper: boolean;
        entrance?: 'bottom';
      };
    },
    { dispatch }
  ) => {
    try {
      await dispatch(
        addPaperToWhiteboardComponent({
          file,
          options: {
            type: 'StickyNote',
            ...options,
          },
        })
      );
    } catch (err) {
      console.log(err);
    }
  }
);

/*
Text block is sent to board
 */
export const prepareAndPublishTextBlock = createAsyncThunk(
  'prepareAndPublishTextBlock',
  async (
    payload: {
      file: ArrayBuffer;
      options: {
        itemId?: string;
        metadata: string;
        selectPaper: boolean;
      };
    },
    { dispatch }
  ) => {
    try {
      await dispatch(
        addPaperToWhiteboardComponent({
          file: payload.file,
          options: {
            type: 'TextBlock',
            ...payload.options,
          },
        })
      );
    } catch (err) {
      console.log(err);
    }
  }
);

export const pastePapers = createAsyncThunk(
  'pastePapers',
  async (
    payload: {
      actionsAndInkImages: {
        actions: Action[];
        inkImages: Record<
          string,
          {
            Data: string;
          }
        >;
      };
      cut: boolean;
    },
    { getState }
  ) => {
    const { actionsAndInkImages, cut = false } = payload;

    if (actionsAndInkImages.actions.length === 0) {
      // No actions
      console.log('pastePapers: No actions and ink images to paste.');
      return;
    }

    const newPaperIds: number[] = [];

    // Slightly randomize initial speed direction in case the user pastes multiple times
    const initialAngle = (0.1 + 0.3 * Math.random()) * Math.PI;
    const initialSpeed = [Math.cos(initialAngle) * 60, Math.sin(initialAngle) * 60];

    const currentPageUuid = ZCanvas.page.getUuidFromIndex(ZCanvas.page.getCurrentIndex());

    ZCanvas.beginCommandGroup();

    try {
      const loadedInkImages: Record<string, CreatedImage> = {};

      for (const imageName of Object.keys(actionsAndInkImages.inkImages)) {
        const imageData = toByteArray(actionsAndInkImages.inkImages[imageName].Data);
        loadedInkImages[imageName] = await ZCanvas.image.loadAsync({ data: imageData, imageName });
      }

      console.assert(Object.keys(actionsAndInkImages.inkImages).length === Object.keys(loadedInkImages).length);

      const paperIdMapping: Record<string, string> = {};

      actionsAndInkImages.actions
        .filter((a) => a.Action === 'CreatePaperAction')
        .forEach((a) => {
          // Update initial offset
          const initialPosition = {
            x: a.PaperInfo.InitialPosition[0],
            y: 2160.0 - a.PaperInfo.InitialPosition[1],
          };
          const targetPosition = initialPosition;
          if (a.PaperInfo.PageId !== currentPageUuid || cut) {
            // Don't animate if it's pasted on another page, or if the original was cut
          } else {
            targetPosition.x += initialSpeed[0];
            targetPosition.y -= initialSpeed[1];
          }

          let paperId = null;
          switch (a.PaperInfo.PaperVariety) {
            case 'StickyNote': {
              const { FFZinkEnumMapping } = findBackgroundColor(a.PaperInfo.PaperColor);
              const image = loadedInkImages[a.PaperInfo.PaperForegroundFileName] as CreatedImage;
              paperId = ZCanvas.paper.createStickyNote(
                initialPosition.x,
                initialPosition.y,
                targetPosition.x,
                targetPosition.y,
                a.PaperInfo.Size[0],
                a.PaperInfo.Size[1],
                FFZinkEnumMapping,
                image,
                a.PaperInfo.PaperMetadata,
                a.PaperInfo.InitialRotation,
                a.PaperInfo.InitialScale,
                false,
                true
              );
              break;
            }
            case 'Image':
              paperId = ZCanvas.paper.createImage(
                initialPosition.x,
                initialPosition.y,
                targetPosition.x,
                targetPosition.y,
                a.PaperInfo.Size[0],
                a.PaperInfo.Size[1],
                ZCanvas.image.getByName(a.PaperInfo.ImageParameters.ImageFileName),
                a.PaperInfo.PaperMetadata,
                a.PaperInfo.InitialRotation,
                a.PaperInfo.InitialScale,
                false,
                true
              );
              break;
            case 'Sticker':
              paperId = ZCanvas.paper.createSticker(
                initialPosition.x,
                initialPosition.y,
                targetPosition.x,
                targetPosition.y,
                a.PaperInfo.Size[0],
                a.PaperInfo.Size[1],
                ZCanvas.image.getByName(a.PaperInfo.ImageParameters.ImageFileName),
                a.PaperInfo.PaperMetadata,
                a.PaperInfo.InitialRotation,
                false,
                true
              );
              break;
            case 'TextBox':
              paperId = ZCanvas.paper.createTextBox(
                initialPosition.x,
                initialPosition.y,
                targetPosition.x,
                targetPosition.y,
                a.PaperInfo.Size[0],
                a.PaperInfo.Size[1],
                ZCanvas.image.getByName(a.PaperInfo.ImageParameters.ImageFileName),
                a.PaperInfo.PaperMetadata,
                a.PaperInfo.InitialRotation,
                a.PaperInfo.InitialScale,
                false,
                true
              );
              break;
            default:
              break;
          }
          newPaperIds.push(paperId);
          paperIdMapping[a.PaperInfo.PaperId] = ZCanvas.paper.getUuid(paperId);
        });

      // NOTE: We wait for two BEC thread updates here.
      // Why? Well, the outgoing CreatePaperAction takes two updates to bubble out to the zhistory and app itself.
      // This should be investigated.. could be due to the Node ThreadSafeFunction in bec_addon.cpp, for instance.
      // Meanwile, this works.
      await ZCanvas.afterUpdate();
      await ZCanvas.afterUpdate();

      actionsAndInkImages.actions
        .filter((a) => a.Action !== 'CreatePaperAction')
        .forEach((a) => {
          let json = JSON.stringify({ ...a, ActionId: uuidv4() });
          for (const oldPaperId of Object.keys(paperIdMapping)) {
            json = json.replaceAll(oldPaperId, paperIdMapping[oldPaperId]);
          }
          ZCanvas.history.applyLocalJsonAction(json);
        });
    } catch (e) {
      console.error(`pastePapers: Exception occurred during the paste operation: ${e?.message}\n${e?.stack}`);
    }

    ZCanvas.endCommandGroup();

    ZCanvas.paper.deselectAll();

    const room = (getState() as RootState).room;

    if (!room) {
      newPaperIds.forEach((paperId) => {
        const parentId = ZCanvas.paper.getStackedOnPaperId(paperId);
        if (parentId === 0 || !newPaperIds.includes(parentId)) {
          ZCanvas.paper.select(paperId, true);
        } else {
          ZCanvas.paper.coselect(paperId);
        }
      });
    }
  }
);

export const editPaper = createAsyncThunk('editPaper', async (paperId: number, { dispatch }) => {
  const type = ZCanvas.paper.getType(paperId);
  const colour = ZCanvas.paper.getColour(paperId);
  const isStickyNote = type === 'StickyNote';
  const isTextBlock = type === 'TextBox';
  let metaData;
  if (isStickyNote || isTextBlock) {
    // Only these types are editable...
    // Update the metadata
    const md = ZCanvas.paper.getMetadata(paperId);
    let metadata;
    try {
      metadata = JSON.parse(md);
    } catch (err) {
      console.log('paper without metadata');
    }
    if (metadata) {
      if (isStickyNote) {
        metaData = {
          paperId,
          content: metadata.Content,
          textColor: metadata.TextColor,
          textSize: metadata.TextSize,
          textAlign: metadata.TextAlign,
          signature: metadata.SignatureText ? metadata.SignatureText : '',
          link: metadata.Link,
          backgroundColor: undefined,
        };
        if (colour) {
          const color = findBackgroundColor(colour);
          if (color) {
            metaData.backgroundColor = color.color;
          }
        }
      }
      if (isTextBlock) {
        metaData = {
          paperId,
          content: metadata.Content,
          backgroundColor: metadata.Color,
          outlineColor: metadata.OutlineColor,
          textSize: metadata.TextSize,
          textAlign: metadata.TextAlign,
          textColor: metadata.TextColor,
          link: metadata.Link,
        };
      }
    } else {
      // No metadata found. This is probably a legacy paper from file.
      metaData = {
        paperId,
        backgroundColor: undefined,
      };
      if (isStickyNote && colour) {
        const color = findBackgroundColor(colour);
        if (color) {
          metaData.backgroundColor = color.color;
        }
      }
    }
  }
  if (isStickyNote) {
    await dispatch(currentStickyNoteEdit(metaData));
  }
  if (isTextBlock) {
    await dispatch(currentTextBlockEdit(metaData));
  }
});

export type PaperOptions = {
  imageId?: number;
  setAsBackground?: boolean;
  metadata?: string;
  addedInBackground?: boolean;
  color?: string;
  type?: 'image' | 'sticker' | 'TextBlock' | 'StickyNote';
  position?: {
    x: number;
    y: number;
  };
  coordsInWritingArea?: boolean;
  maxSize?: { width: number; height: number };
  selectPaper?: boolean;
  file?: { size: number; name: string; type: string };
  entrance?: 'bottom' | 'left';
};

/*
 This is used if we are not mobile, we want to add the item to BEC directly
 */
export const addPaperToWhiteboardComponent = createAsyncThunk(
  'addPaperToWhiteboardComponent',
  async (payload: { file: ArrayBuffer; options: PaperOptions }, { getState, dispatch }) => {
    const { file, options } = payload;

    let entrance;

    switch (options.entrance) {
      case 'bottom':
        entrance = {
          x: window.innerWidth / 2 + 50 * Math.random(),
          y: window.innerHeight,
        };
        break;
      case 'left':
        entrance = {
          x: 0,
          y: window.innerHeight / 2 + 50 * Math.random(),
        };
        break;
      default:
        entrance = {
          x: window.innerWidth / 2 + 50 * Math.random(),
          y: window.innerHeight / 2 + 50 * Math.random(),
        };
    }

    const position = options.position ?? {
      x: window.innerWidth / 2 + 50 * Math.random(),
      y: window.innerHeight / 2 + 50 * Math.random(),
    };

    let paperId;

    if (options.type === 'StickyNote') {
      let color = findBackgroundColor(options.color);
      if (!color) {
        [color] = BackgroundColors;
      }
      const { FFZinkEnumMapping } = color;

      // We dont want any scaling based on active pen now
      const scale = 1;

      // Decoded 0 tends to no image provided initially
      let image: CreatedImage;
      if (file) {
        image = await ZCanvas.image.loadAsync({
          data: file,
        });
      }

      if (options.addedInBackground) {
        ZCanvas.beginCommandGroup();
      }

      paperId = ZCanvas.paper.createStickyNote(
        entrance.x,
        entrance.y,
        position.x,
        position.y,
        300,
        300,
        FFZinkEnumMapping,
        image,
        options.metadata,
        0,
        scale,
        true
      );

      if (options.addedInBackground) {
        ZCanvas.paper.setPaperLayer(paperId, 0);
        ZCanvas.endCommandGroup();
      }
    } else if (options.type === IMAGE) {
      if (!ZCanvas.getZcoreCreated()) {
        return;
      }
      const createdImage = await ZCanvas.image.loadAsync({ data: file });
      let scale = 1;
      if (options.maxSize) {
        scale = Math.min(
          1,
          Math.min(options.maxSize.width / createdImage.width, options.maxSize.height / createdImage.height)
        );
      }
      if (options.setAsBackground) {
        console.log('create image as background');
        paperId = ZCanvas.paper.createBackgroundImage(createdImage, createdImage.width, createdImage.height);
      } else {
        if (options.addedInBackground) {
          ZCanvas.beginCommandGroup();
        }

        if (!ZCanvas.getZcoreCreated()) {
          return;
        }

        paperId = ZCanvas.paper.createImage(
          entrance.x,
          entrance.y,
          position.x,
          position.y,
          createdImage.width,
          createdImage.height,
          createdImage,
          null,
          0,
          scale,
          true,
          options.coordsInWritingArea
        );
        if (options.addedInBackground) {
          ZCanvas.paper.setPaperLayer(paperId, 0);
          ZCanvas.endCommandGroup();
        }
      }
    } else if (options.type === 'TextBlock') {
      const decoded = await ZCanvas.image.loadAsync({ data: file });
      if (options.addedInBackground) {
        ZCanvas.beginCommandGroup();
      }
      paperId = ZCanvas.paper.createTextBox(
        entrance.x,
        entrance.y,
        position.x,
        position.y,
        decoded.width,
        decoded.height,
        decoded,
        options.metadata,
        0,
        1,
        true
      );
      if (options.addedInBackground) {
        ZCanvas.paper.setPaperLayer(paperId, 0);
        ZCanvas.endCommandGroup();
      }
    } else if (options.type === STICKER) {
      const image =
        (options.imageId && ZCanvas.image.getById(options.imageId)) ?? (await ZCanvas.image.loadAsync({ data: file }));
      if (options.addedInBackground) {
        ZCanvas.beginCommandGroup();
      }
      paperId = ZCanvas.paper.createSticker(
        entrance.x,
        entrance.y,
        position.x,
        position.y,
        80,
        80,
        image,
        null,
        0,
        true
      );
      if (options.addedInBackground) {
        ZCanvas.paper.setPaperLayer(paperId, 0);
        ZCanvas.endCommandGroup();
      }
    }

    if (options.selectPaper && !isMobileOnly) {
      ZCanvas.paper.select(paperId);
    }

    const room = (getState() as RootState).room;

    if (!room) {
      await dispatch(setZAction(ZActions.SELECT, ZShapes.FREEFORM, true));
    }
  }
);

export const addItemsToBoard = createAsyncThunk(
  'addItemsToBoard',
  async (itemIds: string[], { getState, dispatch }) => {
    const user = getFirebaseAuth().currentUser;
    const { localItems, itemCanvases } = getState() as RootState;

    let allItems: Item[];

    if (user && !user.isAnonymous) {
      const apolloResponse = await client.query({ query: QUERY_MY_ITEMS });
      const { data } = apolloResponse;
      allItems = data.items;
    } else {
      allItems = localItems;
    }
    for (let i = 0; i < itemIds.length; i += 1) {
      const itemId = itemIds[i];
      const itemToSend = allItems.find((item) => item.id === itemId);

      try {
        const canvasToSend = itemCanvases.find((c) => c.id === itemId).canvas;
        const arrayBuffer = ZImage.canvasToPngBlob(
          canvasToSend as HTMLCanvasElement,
          getNbrColorsForString(itemToSend.content)
        );
        switch (itemToSend.__typename) {
          case 'StickyNote':
            await dispatch(
              prepareAndPublishStickyNote({
                file: arrayBuffer,
                options: {
                  color: itemToSend.backgroundColor,
                  itemId: itemToSend.id,
                  metadata: packStickyNoteMetadata(itemToSend),
                  selectPaper: i === 0,
                },
              })
            );
            break;
          case 'TextBlock': {
            const metaData = {
              ...itemToSend,
              color: itemToSend.backgroundColor,
            };
            await dispatch(
              prepareAndPublishTextBlock({
                file: arrayBuffer,
                options: {
                  itemId: itemToSend.id,
                  metadata: packTextBlockMetadata(metaData),
                  selectPaper: i === 0,
                },
              })
            );
            break;
          }
          default:
            break;
        }

        if (user && !user.isAnonymous) {
          await client.mutate({
            mutation: MUTATION_DELETE_ITEM,
            variables: { input: { id: itemId } },
          });
        } else {
          await dispatch(deleteLocalItem(itemId));
        }
        await dispatch(deleteItemCanvas(itemId));
      } catch (error) {
        console.error(error);
      }
    }
  }
);

export const addPaperLinkAdornment = createAsyncThunk('addPaperLinkAdornment', async (paperId: number) => {
  const arrayBufferBlob = await fetch('/icons/icn_link_symbol.png')
    .then((res) => res.blob())
    .then(
      (blob) =>
        new Promise<ArrayBuffer>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            resolve(e.target.result as ArrayBuffer);
          };
          fileReader.onerror = () => {
            reject(new Error('Error reading'));
          };
          fileReader.readAsArrayBuffer(blob);
        })
    );
  const createdImage = await ZCanvas.image.loadAsync({ data: arrayBufferBlob });
  ZCanvas.paper.setBottomLeftAdornment(paperId, createdImage);
});

export const updateItemOnBoard = createAsyncThunk(
  'updateItemOnBoard',
  async (payload: {
    file: ArrayBuffer;
    options: {
      color: string;
      itemId: string;
      paperId: number;
      metadata: string;
      type: 'StickyNote' | 'TextBlock';
    };
  }) => {
    const { file, options } = payload;

    switch (options.type) {
      case 'StickyNote': {
        let newInkImage: CreatedImage;
        if (file) {
          newInkImage = await ZCanvas.image.loadAsync({ data: file });
        }
        ZCanvas.beginCommandGroup();
        try {
          if (options.color) {
            const color = findBackgroundColor(options.color);
            if (color) {
              const { FFZinkEnumMapping } = color;
              ZCanvas.paper.setColour(options.paperId, FFZinkEnumMapping);
            }
          }
          if (newInkImage) {
            ZCanvas.paper.setInkImage(options.paperId, newInkImage);
            ZCanvas.paper.reset(options.paperId);
          }
          if (options.metadata) {
            ZCanvas.paper.setMetadata(options.paperId, options.metadata);
          }
        } catch (err) {
          console.log(err);
        }
        ZCanvas.endCommandGroup();

        if (!ZCanvas.ffb.hasJsonObjectForInkImage(newInkImage.imageName)) {
          ZCanvas.history.addImage(newInkImage.imageName, newInkImage.data, options.paperId);
          ZCanvas.sendEvent([{ type: 'emitImage', imageName: newInkImage.imageName, imageData: newInkImage.data }]);
        }
        break;
      }
      case 'TextBlock': {
        let newBkgImage: CreatedImage;
        if (file) {
          newBkgImage = await ZCanvas.image.loadAsync({ data: file });
        }
        ZCanvas.beginCommandGroup();
        try {
          if (newBkgImage) {
            ZCanvas.paper.setBkgImage(options.paperId, newBkgImage);
            ZCanvas.paper.reset(options.paperId);
          }
          if (options.metadata) {
            ZCanvas.paper.setMetadata(options.paperId, options.metadata);
          }
        } catch (err) {
          console.log(err);
        }
        ZCanvas.endCommandGroup();
        break;
      }
      default:
        console.log('it was', options.type);
        break;
    }
  }
);

export const duplicatePapers = createAsyncThunk('duplicatePapers', async (paperIds: number[], { dispatch }) => {
  const actionsAndInkImages = getPaperActionsAndInkImages(paperIds);
  await dispatch(
    handleInternalPaste({
      action: 'copy',
      data: JSON.stringify(actionsAndInkImages),
    })
  );
});
